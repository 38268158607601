import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { SwapRouterSettingsType, SwapPoolType } from 'z/types';

export interface SwapState {
    updateFlag: boolean,
    swapRouterSettings: SwapRouterSettingsType | null,
    pools: SwapPoolType[],
    isLoadingPools: boolean,
    slippage: number,
}

const initialState: SwapState = {
    updateFlag: false,
    swapRouterSettings: null,
    pools: [],
    isLoadingPools: false,
    slippage: 1, // 1%
};

export const swapSlice = createSlice({
    name: 'swap',
    initialState,
    reducers: {
        updateSwapRedux: (state) => {
            state.updateFlag = !state.updateFlag;
        },
        setSwapCommonConfiguration: (state, action: PayloadAction<SwapRouterSettingsType | null>) => {
            state.swapRouterSettings = action.payload;
        },
        setSwapPools: (state, action: PayloadAction<SwapPoolType[]>) => {
            state.pools = action.payload;
        },
        setIsLoadingPools: (state, action: PayloadAction<boolean>) => {
            state.isLoadingPools = action.payload;
        },
        setSlippage: (state, action: PayloadAction<number>) => {
            state.slippage = action.payload;
        },
    },
});

export const {
    updateSwapRedux,
    setSwapCommonConfiguration,
    setSwapPools,
    setIsLoadingPools,
    setSlippage,
} = swapSlice.actions;
export const selectSwap = (state: RootState) => state.swap;

export const swapReducer = swapSlice.reducer;
