import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

interface AdminState {
    isAdmin: boolean;
    isSwapAdmin: boolean;
}

const initialState: AdminState = { isAdmin: false, isSwapAdmin: false };

export const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        setIsAdmin: (state: AdminState, action: PayloadAction<boolean>) => {
            state.isAdmin = action.payload;
        },
        setIsSwapAdmin: (state: AdminState, action: PayloadAction<boolean>) => {
            state.isSwapAdmin = action.payload;
        },
    },
});

export const { setIsAdmin, setIsSwapAdmin } = adminSlice.actions;
export const selectAdmin = (state: RootState) => state.admin;

export const adminReducer = adminSlice.reducer;
