import React, { ChangeEvent, useEffect, useState } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import { Collapse, Tooltip } from '@mui/material';
import { parseAmount } from '@multiversx/sdk-dapp/utils';
import BigNumber from 'bignumber.js';
import clsx from 'clsx';
import { Row, Col } from 'react-bootstrap';
import { BiRightArrowAlt, BiDownArrowAlt } from 'react-icons/bi';
import { selectNetstats } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { addLiquidityIntoPool, getMaxAmountThatCanBeAddedIntoSlip, getTokenLogo } from 'z/elrond';
import { SlipPoolType, SlipStateEnum, SwapPoolType } from 'z/types';
import {
    ERROR_NOT_ENOUGH_BALANCE,
    ERROR_NOT_ENOUGH_OURO_IN_SLIP,
    convertEsdtToWei,
    convertWeiToEsdt,
    formatNumber,
} from 'z/utils';
import { SlipLoadType } from '.';
import { StakeUnstakeFrLp } from './Components/StakeUnstakeFrLp';

export const SlipRow = ({
    slipPool,
    swapOuroPool,
    swapVegldPool,
    slipLoadData,
}: {
    slipPool: SlipPoolType;
    swapOuroPool: SwapPoolType | undefined;
    swapVegldPool: SwapPoolType | undefined;
    slipLoadData: SlipLoadType;
}) => {
    const [showControl, setShowControl] = useState<boolean>(false);

    function toggleShowControlButton() {
        setShowControl(!showControl);
    }

    const InfoTooltip = ({ title }: { title: string }) => {
        return (
            <Tooltip title={title} leaveDelay={200}>
                <span style={{ color: '#98A1C0' }}>
                    <InfoIcon fontSize="small" />
                </span>
            </Tooltip>
        );
    };

    const [accountAmount, setAccountAmount] = useState('0');
    const [maxAmountThatCanBeAddedIntoSlip, setMaxAmountThatCanBeAddedIntoSlip] = useState('0');

    const netstatsRedux = useAppSelector(selectNetstats);

    const setupAmount = async () => {
        setAccountAmount(netstatsRedux.walletTokensMap[slipPool.tokenIdentifier]?.balance || '0');

        const maxAmount = await getMaxAmountThatCanBeAddedIntoSlip(slipPool.tokenIdentifier);
        setMaxAmountThatCanBeAddedIntoSlip(maxAmount);
    };

    useEffect(() => {
        setupAmount();
    }, [netstatsRedux.walletTokensMap]);

    const [filledProcent, setFilledProcent] = useState('0');

    useEffect(() => {
        setFilledProcent(
            formatNumber(
                convertWeiToEsdt(swapOuroPool?.second_token_reserve, swapOuroPool?.second_token_decimals)
                    .multipliedBy(100)
                    .div(convertWeiToEsdt(swapVegldPool?.second_token_reserve, swapVegldPool?.second_token_decimals)),
                2,
            ),
        );
    }, [swapOuroPool, swapVegldPool]);

    const [addLiquidityAmount, setAddLiquidityAmount] = useState<string | undefined>();

    function handlerAddLiquidity() {
        if (swapOuroPool && addLiquidityAmount)
            addLiquidityIntoPool(
                swapOuroPool?.second_token_id,
                parseAmount(addLiquidityAmount, swapOuroPool.second_token_decimals),
            );
    }

    const handleSetAddLiquidityAmount = () =>
        setAddLiquidityAmount(
            BigNumber.min(
                convertWeiToEsdt(
                    maxAmountThatCanBeAddedIntoSlip,
                    swapOuroPool?.second_token_decimals,
                    swapOuroPool?.second_token_decimals,
                ),
                convertWeiToEsdt(
                    accountAmount,
                    swapOuroPool?.second_token_decimals,
                    swapOuroPool?.second_token_decimals,
                ),
            ).toFixed(),
        );

    const [amountError, setAmountError] = useState('');

    function handleChangeAmount(e: ChangeEvent<HTMLInputElement>): void {
        if (convertEsdtToWei(e.target.value, swapOuroPool?.second_token_decimals).comparedTo(accountAmount) > 0)
            setAmountError(ERROR_NOT_ENOUGH_BALANCE);
        else if (
            convertEsdtToWei(e.target.value, swapOuroPool?.second_token_decimals).comparedTo(
                maxAmountThatCanBeAddedIntoSlip,
            ) > 0
        )
            setAmountError(ERROR_NOT_ENOUGH_OURO_IN_SLIP);
        else setAmountError('');
        setAddLiquidityAmount(e.target.value);
    }

    return (
        <>
            <div className="slip-li-container">
                <div className="slip-li">
                    <div className="slip-li-header">
                        <div>
                            <img src={getTokenLogo(slipPool.tokenIdentifier)} alt="logo" width="70px" />
                        </div>

                        <div>
                            <div
                                className={clsx(
                                    'fee-badge selected',
                                    `${slipPool.pool_type.toLowerCase()}-color`,
                                    slipPool.status == SlipStateEnum.Active ? 'state-active' : 'state-inactive',
                                )}
                            >
                                {slipPool.status}
                            </div>
                        </div>
                    </div>

                    <div className="w-100">
                        <div className="mb-1" style={{ textAlign: 'right' }}>{`${
                            swapOuroPool?.second_token_ticker
                        } Capacity Left: ${formatNumber(
                            convertWeiToEsdt(
                                maxAmountThatCanBeAddedIntoSlip,
                                swapOuroPool?.second_token_decimals,
                                swapOuroPool?.second_token_decimals,
                            ),
                            0,
                        )} ${swapOuroPool?.second_token_ticker}`}</div>
                        <div className="progress w-100 slip-progress">
                            <div
                                className="progress-bar slip-progress-bar"
                                role="progressbar"
                                style={{
                                    width: `${filledProcent}%`,
                                }}
                            >
                                {filledProcent}% Filled
                            </div>
                        </div>

                        <div className="d-flex justify-content-end mt-1">
                            <span>
                                {`${formatNumber(
                                    convertWeiToEsdt(
                                        swapOuroPool?.second_token_reserve,
                                        swapOuroPool?.second_token_decimals,
                                        swapOuroPool?.second_token_decimals,
                                    ),
                                    0,
                                )} / ${formatNumber(
                                    convertWeiToEsdt(
                                        swapVegldPool?.second_token_reserve,
                                        swapVegldPool?.second_token_decimals,
                                        swapVegldPool?.second_token_decimals,
                                    ),
                                    0,
                                )} ${swapOuroPool?.second_token_ticker}`}
                            </span>
                        </div>
                    </div>

                    <div>
                        <button className="pool-collapse-button" onClick={toggleShowControlButton}>
                            {!showControl ? <BiRightArrowAlt /> : <BiDownArrowAlt />}
                        </button>
                    </div>
                </div>

                <Collapse in={showControl}>
                    <Row
                        style={{
                            rowGap: '10px',
                            marginTop: '30px',
                        }}
                    >
                        <Col md={9}>
                            <input
                                className="vesta_x_input"
                                type="number"
                                placeholder={`${swapOuroPool?.second_token_ticker} Amount`}
                                onChange={handleChangeAmount}
                                value={addLiquidityAmount}
                            />

                            <div className="d-flex justify-content-between mt-1">
                                <div className="input-token-error">{amountError}</div>
                                <div
                                    className="add-liquidity-input-token-balance-box"
                                    onClick={handleSetAddLiquidityAmount}
                                >
                                    <div className="">Balance:&nbsp;</div>
                                    <div
                                        style={{
                                            color: 'white',
                                        }}
                                    >
                                        {formatNumber(
                                            convertWeiToEsdt(
                                                accountAmount,
                                                swapOuroPool?.second_token_decimals,
                                                swapOuroPool?.second_token_decimals,
                                            ),
                                            swapOuroPool?.second_token_decimals,
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col md={3}>
                            <button
                                className="slip-but w-100"
                                onClick={handlerAddLiquidity}
                                disabled={slipLoadData.isSlipDisabled || slipPool.status !== SlipStateEnum.Active}
                            >
                                Make SLIP&nbsp;
                                <InfoTooltip title="Make SLIP using the amount of Token entered in the field to the left." />
                            </button>
                        </Col>
                    </Row>

                    <StakeUnstakeFrLp frozenLpIdentifier={slipPool.frozenLpTokenIdentifier} />
                </Collapse>
            </div>
        </>
    );
};
