import React, { useEffect, useState } from 'react';
import { useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';
import { Row, Col } from 'react-bootstrap';
import {
    AURYN_TOKEN_ID,
    EAURYN_TOKEN_ID,
    OURO_TOKEN_ID,
    VAURYN_TOKEN_ID,
    VEAURYN_TOKEN_ID,
    VOURO_TOKEN_ID,
} from 'config';
import { selectNetstats } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { snakeCoilViewBaseContext, getTokenSupplyFromApi } from 'z/elrond';
import {
    AUTOSTAKE_OURO_DISTRIBUTION_PERCENTAGE,
    OURO_SUPPLY_HARD_CAP,
    getOuroFactoryCommonContext,
} from 'z/elrond/ouro-factory';
import { useVestaCommonHooks } from 'z/hooks';
import { SnakeCoilBaseContext } from 'z/types';
import { OuroFactoryContextType } from 'z/types/ouro-factory';
import { convertToDollarString, convertWeiToEsdt, formatNumber, parseBigNumber } from 'z/utils';

export const AutostakeAprInfoRow = () => {
    const [snakeCoilBaseContext, setSnakeCoilBaseContext] = useState<SnakeCoilBaseContext>();
    const [ouroFactoryContext, setOuroFactoryContext] = useState<OuroFactoryContextType>();
    const [circulatingOuroSupply, setCirculatingOuroSupply] = useState<number>(0);
    const [circulatingAurynSupply, setCirculatingAurynSupply] = useState<number>(0);
    const [totalOuroSupply, setTotalOuroSupply] = useState<number>(0);
    const [totalVOuroSupply, setTotalVOuroSupply] = useState<number>(0);
    const [totalAurynSupply, setTotalAurynSupply] = useState<number>(0);
    const [totalVAurynSupply, setTotalVAurynSupply] = useState<number>(0);
    const [totalEliteAurynSupply, setTotalEliteAurynSupply] = useState<number>(0);
    const [totalVEliteAurynSupply, setTotalVEliteAurynSupply] = useState<number>(0);

    const netstatsRedux = useAppSelector(selectNetstats);

    const { getTokenPrice } = useVestaCommonHooks();
    const { hasPendingTransactions } = useGetPendingTransactions();

    useEffect(() => {
        if (hasPendingTransactions) return;

        (async () => {
            const _snakeCoilBaseContext = await snakeCoilViewBaseContext();
            setSnakeCoilBaseContext(_snakeCoilBaseContext);
        })();

        (async () => {
            const _ouroFactoryContext = await getOuroFactoryCommonContext();
            setOuroFactoryContext(_ouroFactoryContext);
        })();

        (async () => {
            const _ouroSupply = await getTokenSupplyFromApi(OURO_TOKEN_ID);
            const _vouroSupply = await getTokenSupplyFromApi(VOURO_TOKEN_ID);
            const _aurynSupply = await getTokenSupplyFromApi(AURYN_TOKEN_ID);
            const _vaurynSupply = await getTokenSupplyFromApi(VAURYN_TOKEN_ID);
            const _eliteAurynSupply = await getTokenSupplyFromApi(EAURYN_TOKEN_ID);
            const _veliteAurynSupply = await getTokenSupplyFromApi(VEAURYN_TOKEN_ID);

            setTotalOuroSupply(parseFloat(_ouroSupply?.supply ?? '0'));
            setTotalVOuroSupply(parseFloat(_vouroSupply?.supply ?? '0'));
            setTotalAurynSupply(parseFloat(_aurynSupply?.supply ?? '0'));
            setTotalVAurynSupply(parseFloat(_vaurynSupply?.supply ?? '0'));
            setTotalEliteAurynSupply(parseFloat(_eliteAurynSupply?.supply ?? '0'));
            setTotalVEliteAurynSupply(parseFloat(_veliteAurynSupply?.supply ?? '0'));
        })();
    }, [hasPendingTransactions]);

    useEffect(() => {
        if (!netstatsRedux || !snakeCoilBaseContext) return;

        const _circulatingOuroSupply =
            totalOuroSupply -
            convertWeiToEsdt(snakeCoilBaseContext.token_supplies[0]).toNumber() -
            convertWeiToEsdt(snakeCoilBaseContext.unbonding_token_supplies[0]).toNumber() -
            totalVOuroSupply;
        setCirculatingOuroSupply(_circulatingOuroSupply);
    }, [netstatsRedux, totalOuroSupply, totalVOuroSupply]);

    useEffect(() => {
        if (!netstatsRedux || !snakeCoilBaseContext) return;

        const _circulatingAurynSupply =
            totalAurynSupply -
            convertWeiToEsdt(snakeCoilBaseContext.token_supplies[2]).toNumber() -
            convertWeiToEsdt(snakeCoilBaseContext.unbonding_token_supplies[1]).toNumber() -
            totalVAurynSupply;
        setCirculatingAurynSupply(_circulatingAurynSupply);
    }, [netstatsRedux, totalAurynSupply, totalVAurynSupply]);

    const autostakePoolDpr =
        ouroFactoryContext && snakeCoilBaseContext
            ? (1000 * ouroFactoryContext.today_emission_amount) /
              parseBigNumber(snakeCoilBaseContext.token_supplies[0]).shiftedBy(-18).toNumber()
            : -1;

    return (
        <Row className="second-card-style mt-4 gy-4 pt-0">
            <div className="text-center mb-3" style={{ color: '#F1DC46', fontSize: '1.25rem' }}>
                Autostake APR - Annual Percentage Rate:{' '}
                {formatNumber(autostakePoolDpr * AUTOSTAKE_OURO_DISTRIBUTION_PERCENTAGE * 36.5, 2)}% DPR - Daily
                Permille Rate: {formatNumber(autostakePoolDpr * AUTOSTAKE_OURO_DISTRIBUTION_PERCENTAGE, 3)}‰
            </div>

            <Col lg={6}>
                <div
                    className="d-flex flex-column text-center position-relative align-items-center justify-content-center"
                    style={{ height: '125px', fontSize: '1.3rem' }}
                >
                    <span>
                        Total Value Locked:{' '}
                        {convertToDollarString(
                            snakeCoilBaseContext
                                ? convertWeiToEsdt(
                                      parseBigNumber(
                                          snakeCoilBaseContext?.token_supplies[
                                              snakeCoilBaseContext.tokens.indexOf(OURO_TOKEN_ID)
                                          ],
                                      ).multipliedBy(getTokenPrice(OURO_TOKEN_ID)),
                                  )
                                : 0,
                        )}
                    </span>
                    <span>
                        AURYNDEX™:{' '}
                        {snakeCoilBaseContext
                            ? formatNumber(
                                  parseBigNumber(snakeCoilBaseContext.token_supplies[0]).div(
                                      snakeCoilBaseContext.token_supplies[1],
                                  ),
                                  9,
                              )
                            : '???'}
                    </span>
                </div>

                <div className="second-card-style position-relative">
                    <div className="d-flex text-right">
                        <div className="w-50">Liquid OUROBOROS:</div>
                        <div className="w-50">
                            {formatNumber(circulatingOuroSupply, 4)} |{' '}
                            {formatNumber((circulatingOuroSupply / totalOuroSupply) * 1000, 3)}‰
                        </div>
                    </div>
                    <div className="d-flex text-right">
                        <div className="w-50">Autostaked OUROBOROS:</div>
                        <div className="w-50">
                            {formatNumber(
                                snakeCoilBaseContext
                                    ? convertWeiToEsdt(snakeCoilBaseContext?.token_supplies[0]).toNumber()
                                    : 0,
                                4,
                            )}{' '}
                            |{' '}
                            {formatNumber(
                                (snakeCoilBaseContext
                                    ? convertWeiToEsdt(snakeCoilBaseContext?.token_supplies[0])
                                    : new BigNumber(0)
                                )
                                    .div(totalOuroSupply)
                                    .times(1000)
                                    .toNumber(),
                                3,
                            )}
                            ‰
                        </div>
                    </div>
                    <div className="d-flex text-right">
                        <div className="w-50">Unbonding OUROBOROS:</div>
                        <div className="w-50">
                            {snakeCoilBaseContext &&
                                formatNumber(
                                    convertWeiToEsdt(parseBigNumber(snakeCoilBaseContext.unbonding_token_supplies[0])),
                                    4,
                                )}{' '}
                            |{' '}
                            {formatNumber(
                                (snakeCoilBaseContext
                                    ? convertWeiToEsdt(snakeCoilBaseContext?.unbonding_token_supplies[0])
                                    : new BigNumber(0)
                                )
                                    .div(totalOuroSupply)
                                    .times(1000)
                                    .toNumber(),
                                3,
                            )}
                            ‰
                        </div>
                    </div>
                    <div className="d-flex text-right">
                        <div className="w-50">Reserved OUROBOROS:</div>
                        <div className="w-50">0.0000 | 0.000‰</div>
                    </div>
                    <div className="d-flex text-right">
                        <div className="w-50">Vested OUROBOROS:</div>
                        <div className="w-50">
                            {formatNumber(totalVOuroSupply, 4)} |{' '}
                            {formatNumber((totalVOuroSupply / totalOuroSupply) * 1000, 3)}‰
                        </div>
                    </div>
                    <hr style={{ color: '#ffffff50' }} />
                    <div className="d-flex text-right">
                        <div className="w-50">Total OUROBOROS:</div>
                        <div className="w-50">
                            {formatNumber(totalOuroSupply, 4)} |{' '}
                            {formatNumber((totalOuroSupply / OURO_SUPPLY_HARD_CAP) * 1000, 3)}‰
                        </div>
                    </div>
                </div>
            </Col>

            <Col lg={6}>
                <div className="second-card-style">
                    <div className="d-flex text-right">
                        <div className="w-50">Liquid AURYN:</div>
                        <div className="w-50">
                            {formatNumber(circulatingAurynSupply, 4)} |{' '}
                            {formatNumber((circulatingAurynSupply / totalAurynSupply) * 1000, 3)}‰
                        </div>
                    </div>
                    <div className="d-flex text-right">
                        <div className="w-50">Staked AURYN:</div>
                        <div className="w-50">
                            {formatNumber(
                                snakeCoilBaseContext
                                    ? convertWeiToEsdt(snakeCoilBaseContext?.token_supplies[2]).toNumber()
                                    : 0,
                                4,
                            )}{' '}
                            |{' '}
                            {formatNumber(
                                (snakeCoilBaseContext
                                    ? convertWeiToEsdt(snakeCoilBaseContext?.token_supplies[2])
                                    : new BigNumber(0)
                                )
                                    .div(totalAurynSupply)
                                    .times(1000)
                                    .toNumber(),
                                3,
                            )}
                            ‰
                        </div>
                    </div>
                    <div className="d-flex text-right">
                        <div className="w-50">Unbonding AURYN:</div>
                        <div className="w-50">
                            {snakeCoilBaseContext &&
                                formatNumber(
                                    convertWeiToEsdt(parseBigNumber(snakeCoilBaseContext.unbonding_token_supplies[1])),
                                    4,
                                )}{' '}
                            |{' '}
                            {formatNumber(
                                (snakeCoilBaseContext
                                    ? convertWeiToEsdt(snakeCoilBaseContext?.unbonding_token_supplies[1])
                                    : new BigNumber(0)
                                )
                                    .div(totalAurynSupply)
                                    .times(1000)
                                    .toNumber(),
                                3,
                            )}
                            ‰
                        </div>
                    </div>
                    <div className="d-flex text-right">
                        <div className="w-50">Vested AURYN:</div>
                        <div className="w-50">
                            {formatNumber(totalVAurynSupply, 4)} |{' '}
                            {formatNumber((totalVAurynSupply / totalAurynSupply) * 1000, 3)}‰
                        </div>
                    </div>
                    <hr style={{ color: '#ffffff50' }} />
                    <div className="d-flex text-right">
                        <div className="w-50">Total AURYN:</div>
                        <div className="w-50">{formatNumber(totalAurynSupply, 4)}</div>
                    </div>
                </div>

                <div className="second-card-style mt-4">
                    <div className="d-flex text-right">
                        <div className="w-50">Liquid Elite-AURYN:</div>
                        <div className="w-50">
                            {formatNumber(totalEliteAurynSupply - totalVEliteAurynSupply, 4)} |{' '}
                            {formatNumber(
                                ((totalEliteAurynSupply - totalVEliteAurynSupply) / totalEliteAurynSupply) * 1000,
                                3,
                            )}
                            ‰
                        </div>
                    </div>
                    <div className="d-flex text-right">
                        <div className="w-50">Vested Elite-AURYN:</div>
                        <div className="w-50">
                            {formatNumber(totalVEliteAurynSupply, 4)} |{' '}
                            {formatNumber((totalVEliteAurynSupply / totalEliteAurynSupply) * 1000, 3)}‰
                        </div>
                    </div>
                    <hr style={{ color: '#ffffff50' }} />
                    <div className="d-flex text-right">
                        <div className="w-50">Total Elite-AURYN:</div>
                        <div className="w-50">{formatNumber(totalEliteAurynSupply, 4)}</div>
                    </div>
                </div>
            </Col>
        </Row>
    );
};
