import { useEffect } from 'react';
import { useGetAccount, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import { throttle } from 'lodash';
import { setUserDeb } from 'redux/reducers';
import {
    setOuroLiquidityVaultBaseContext,
    setOuroLiquidityVaultStateContext,
    setOuroLiquidityVaultTotalPremiumAndElitePowers,
    setOuroLiquidityVaultUserContext,
    setOuroVaultRewards,
    setOuroVaultUserElitePowers,
} from 'redux/reducers/ouro-liquidity-vault';
import { useAppDispatch } from 'redux/store';

const throttleFetchDefault = throttle(async (fetchFunction: () => Promise<void>) => fetchFunction(), 5000, {
    trailing: false,
});
const throttleFetchAfterTransaction = throttle(async (fetchFunction: () => Promise<void>) => fetchFunction(), 5000, {
    trailing: false,
});
const throttleFetchUserAfterTransaction = throttle(
    async (fetchFunction: () => Promise<void>) => fetchFunction(),
    5000,
    {
        trailing: false,
    },
);

export const useOuroLiquidityVaultHooks = () => {
    const { hasPendingTransactions } = useGetPendingTransactions();
    const { address } = useGetAccount();
    const dispatch = useAppDispatch();

    useEffect(() => {
        throttleFetchDefault(async () => {
            await setOuroLiquidityVaultBaseContext(dispatch);
        });
    }, []);

    useEffect(() => {
        throttleFetchAfterTransaction(async () => {
            if (hasPendingTransactions) return;

            await setOuroLiquidityVaultStateContext(dispatch);

            await setOuroLiquidityVaultTotalPremiumAndElitePowers(dispatch);
        });
    }, [hasPendingTransactions]);

    useEffect(() => {
        throttleFetchUserAfterTransaction(async () => {
            if (!address || hasPendingTransactions) return;

            await setOuroLiquidityVaultUserContext(address, dispatch);

            await setUserDeb(address, dispatch);

            await setOuroVaultRewards(address, dispatch);

            await setOuroVaultUserElitePowers(address, dispatch);
        });
    }, [address, hasPendingTransactions]);
};
