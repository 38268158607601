import img01 from 'assets/img/farm/01_Vesta_Bronze_Staked.svg';
import img02 from 'assets/img/farm/02_Vesta_Silver_Staked.svg';
import img03 from 'assets/img/farm/03_Vesta_Golden_Staked.svg';
import img04 from 'assets/img/farm/04_Vesta_Bronze_Unstaked.svg';
import img05 from 'assets/img/farm/05_Vesta_Silver_Unstaked.svg';
import img06 from 'assets/img/farm/06_Vesta_Golden_Unstaked.svg';
import img07 from 'assets/img/farm/07_USDC_Bronze_Staked.svg';
import img08 from 'assets/img/farm/08_USDC_Silver_Staked.svg';
import img09 from 'assets/img/farm/09_USDC_Golden_Staked.svg';
import img10 from 'assets/img/farm/10_USDC_Bronze_Unstaked.svg';
import img11 from 'assets/img/farm/11_USDC_Silver_Unstaked.svg';
import img12 from 'assets/img/farm/12_USDC_Golden_Unstaked.svg';
import img13 from 'assets/img/farm/13_Super_Bronze_Staked.svg';
import img14 from 'assets/img/farm/14_Super_Silver_Staked.svg';
import img15 from 'assets/img/farm/15_Super_Golden_Staked.svg';
import img16 from 'assets/img/farm/16_Super_Bronze_Unstaked.svg';
import img17 from 'assets/img/farm/17_Super_Silver_Unstaked.svg';
import img18 from 'assets/img/farm/18_Super_Golden_Unstaked.svg';
import img19 from 'assets/img/farm/19_Koson_Bronze_Staked.svg';
import img20 from 'assets/img/farm/20_Koson_Silver_Staked.svg';
import img21 from 'assets/img/farm/21_Koson_Golden_Staked.svg';
import img22 from 'assets/img/farm/22_Koson_Bronze_Unstaked.svg';
import img23 from 'assets/img/farm/23_Koson_Silver_Unstaked.svg';
import img24 from 'assets/img/farm/24_Koson_Golden_Unstaked.svg';
import img25 from 'assets/img/farm/25_CPA_Bronze_Staked.svg';
import img26 from 'assets/img/farm/26_CPA_Silver_Staked.svg';
import img27 from 'assets/img/farm/27_CPA_Golden_Staked.svg';
import img28 from 'assets/img/farm/28_CPA_Bronze_Unstaked.svg';
import img29 from 'assets/img/farm/29_CPA_Silver_Unstaked.svg';
import img30 from 'assets/img/farm/30_CPA_Golden_Unstaked.svg';
import img31 from 'assets/img/farm/31_Xapes_Bronze_Staked.svg';
import img32 from 'assets/img/farm/32_Xapes_Silver_Staked.svg';
import img33 from 'assets/img/farm/33_Xapes_Golden_Staked.svg';
import img34 from 'assets/img/farm/34_Xapes_Bronze_Unstaked.svg';
import img35 from 'assets/img/farm/35_Xapes_Silver_Unstaked.svg';
import img36 from 'assets/img/farm/36_Xapes_Golden_Unstaked.svg';
import img37 from 'assets/img/farm/37_XLH_Bronze_Staked.svg';
import img38 from 'assets/img/farm/38_XLH_Silver_Staked.svg';
import img39 from 'assets/img/farm/39_XLH_Golden_Staked.svg';
import img40 from 'assets/img/farm/40_XLH_Bronze_Unstaked.svg';
import img41 from 'assets/img/farm/41_XLH_Silver_Unstaked.svg';
import img42 from 'assets/img/farm/42_XLH_Golden_Unstaked.svg';
import img43 from 'assets/img/farm/43_ESTAR_Bronze_Staked.svg';
import img44 from 'assets/img/farm/44_ESTAR_Silver_Staked.svg';
import img45 from 'assets/img/farm/45_ESTAR_Golden_Staked.svg';
import img46 from 'assets/img/farm/46_ESTAR_Bronze_Unstaked.svg';
import img47 from 'assets/img/farm/47_ESTAR_Silver_Unstaked.svg';
import img48 from 'assets/img/farm/48_ESTAR_Golden_Unstaked.svg';
import unknownTokenImg from 'assets/img/token/unknown-token.png';
import { StakedLpTokenType } from "../z/types";

export function getFarmImage(farmTokenId: string, farmTokenType: StakedLpTokenType): string {
  if (farmTokenId == 'SVVEGLDVST-178ead') {
    if (farmTokenType == StakedLpTokenType.Bronze) return img01;
    if (farmTokenType == StakedLpTokenType.Silver) return img02;
    if (farmTokenType == StakedLpTokenType.Gold) return img03;
    if (farmTokenType == StakedLpTokenType.UnbondingBronze) return img04;
    if (farmTokenType == StakedLpTokenType.UnbondingSilver) return img05;
    if (farmTokenType == StakedLpTokenType.UnbondingGold) return img06;
  }
  if (farmTokenId == 'SVEGLDUSDC-521926') {
    if (farmTokenType == StakedLpTokenType.Bronze) return img07;
    if (farmTokenType == StakedLpTokenType.Silver) return img08;
    if (farmTokenType == StakedLpTokenType.Gold) return img09;
    if (farmTokenType == StakedLpTokenType.UnbondingBronze) return img10;
    if (farmTokenType == StakedLpTokenType.UnbondingSilver) return img11;
    if (farmTokenType == StakedLpTokenType.UnbondingGold) return img12;
  }
  if (farmTokenId == 'SVEGDSUPER-133ccd') {
    if (farmTokenType == StakedLpTokenType.Bronze) return img13;
    if (farmTokenType == StakedLpTokenType.Silver) return img14;
    if (farmTokenType == StakedLpTokenType.Gold) return img15;
    if (farmTokenType == StakedLpTokenType.UnbondingBronze) return img16;
    if (farmTokenType == StakedLpTokenType.UnbondingSilver) return img17;
    if (farmTokenType == StakedLpTokenType.UnbondingGold) return img18;
  }
  if (farmTokenId == 'SVEGDKOSON-052889') {
    if (farmTokenType == StakedLpTokenType.Bronze) return img19;
    if (farmTokenType == StakedLpTokenType.Silver) return img20;
    if (farmTokenType == StakedLpTokenType.Gold) return img21;
    if (farmTokenType == StakedLpTokenType.UnbondingBronze) return img22;
    if (farmTokenType == StakedLpTokenType.UnbondingSilver) return img23;
    if (farmTokenType == StakedLpTokenType.UnbondingGold) return img24;
  }
  if (farmTokenId == 'SVVEGLDCPA-afc042') {
    if (farmTokenType == StakedLpTokenType.Bronze) return img25;
    if (farmTokenType == StakedLpTokenType.Silver) return img26;
    if (farmTokenType == StakedLpTokenType.Gold) return img27;
    if (farmTokenType == StakedLpTokenType.UnbondingBronze) return img28;
    if (farmTokenType == StakedLpTokenType.UnbondingSilver) return img29;
    if (farmTokenType == StakedLpTokenType.UnbondingGold) return img30;
  }
  if (farmTokenId == 'SVEGDXAPES-34db52') {
    if (farmTokenType == StakedLpTokenType.Bronze) return img31;
    if (farmTokenType == StakedLpTokenType.Silver) return img32;
    if (farmTokenType == StakedLpTokenType.Gold) return img33;
    if (farmTokenType == StakedLpTokenType.UnbondingBronze) return img34;
    if (farmTokenType == StakedLpTokenType.UnbondingSilver) return img35;
    if (farmTokenType == StakedLpTokenType.UnbondingGold) return img36;
  }
  if (farmTokenId == 'SVVEGLDXLH-492c4b') {
    if (farmTokenType == StakedLpTokenType.Bronze) return img37;
    if (farmTokenType == StakedLpTokenType.Silver) return img38;
    if (farmTokenType == StakedLpTokenType.Gold) return img39;
    if (farmTokenType == StakedLpTokenType.UnbondingBronze) return img40;
    if (farmTokenType == StakedLpTokenType.UnbondingSilver) return img41;
    if (farmTokenType == StakedLpTokenType.UnbondingGold) return img42;
  }
  if (farmTokenId == 'SVEGDESTAR-cc4042') {
    if (farmTokenType == StakedLpTokenType.Bronze) return img43;
    if (farmTokenType == StakedLpTokenType.Silver) return img44;
    if (farmTokenType == StakedLpTokenType.Gold) return img45;
    if (farmTokenType == StakedLpTokenType.UnbondingBronze) return img46;
    if (farmTokenType == StakedLpTokenType.UnbondingSilver) return img47;
    if (farmTokenType == StakedLpTokenType.UnbondingGold) return img48;
  }

  return unknownTokenImg;
}
