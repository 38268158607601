import React, { ChangeEvent, useEffect, useState } from 'react';
import { TokenTransfer } from '@multiversx/sdk-core/out';
import {
    useGetAccount,
    useGetPendingTransactions,
} from '@multiversx/sdk-dapp/hooks';
import clsx from 'clsx';
import { Col, Row } from 'react-bootstrap';
import BLESS_VESTA_LOGO from 'assets/img/12_Blessed-Vesta_200x200.svg';
import { AURYN_TOKEN_ID, BVST_TOKEN_ID, EAURYN_TOKEN_ID } from 'config';
import { selectNetstats } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { getTokenLogo, snakeTransfer } from 'z/elrond';
import {
    formatNumber,
    convertEsdtToWei,
    convertWeiToEsdt,
    DEFAULT_DECIMALS,
    ERROR_NOT_ENOUGH_BALANCE,
    ERROR_CONNECT_WALLET,
    ERROR_TRANSACTION_ONGOING,
    isPositiveBigNumber,
    ERROR_INVALID_NUMBER,
    toastError,
    ZERO_STRING,
    isValidElrondAddress,
    ERROR_INVALID_MVX_ADDRESS,
} from 'z/utils';

enum TransferOptionEnum {
    Auryn = 'AURYN',
    Eauryn = 'EAURYN',
    Bvst = 'bVST',
}

export function SnakeTransfer() {
    const { address } = useGetAccount();
    const { hasPendingTransactions } = useGetPendingTransactions();

    const netstatsRedux = useAppSelector(selectNetstats);

    const [transferOption, setTransferOption] = useState(TransferOptionEnum.Auryn);
    const [destinationAddress, setDestinationAddress] = useState('');
    const [amountError, setAmountError] = useState('');
    const [transferAmount, setTransferAmount] = useState('');
    const [accountAmount, setAccountAmount] = useState('0');

    useEffect(() => {
        if (transferOption === TransferOptionEnum.Auryn) {
            setAccountAmount(netstatsRedux.walletTokensMap[AURYN_TOKEN_ID]?.balance || '0');
        } else if (transferOption === TransferOptionEnum.Eauryn) {
            setAccountAmount(netstatsRedux.walletTokensMap[EAURYN_TOKEN_ID]?.balance || '0');
        } else if (transferOption === TransferOptionEnum.Bvst) {
            setAccountAmount(netstatsRedux.walletTokensMap[BVST_TOKEN_ID]?.balance || '0');
        }
    }, [netstatsRedux, transferOption]);

    const handleChangeAmount = (e: ChangeEvent<HTMLInputElement>): void => {
        if (convertEsdtToWei(e.target.value, DEFAULT_DECIMALS).comparedTo(accountAmount) > 0)
            setAmountError(ERROR_NOT_ENOUGH_BALANCE);
        else setAmountError('');
        setTransferAmount(e.target.value);
    };

    const handleSetTransferAmount = () =>
        setTransferAmount(
            convertWeiToEsdt(accountAmount, DEFAULT_DECIMALS, DEFAULT_DECIMALS).toFixed(DEFAULT_DECIMALS),
        );

    const handlerTransfer = async (): Promise<void> => {
        const token_id =
            transferOption === TransferOptionEnum.Auryn
                ? AURYN_TOKEN_ID
                : transferOption === TransferOptionEnum.Eauryn
                ? EAURYN_TOKEN_ID
                : transferOption === TransferOptionEnum.Bvst
                ? BVST_TOKEN_ID
                : '';
        let error = '';
        if (!address) {
            error = ERROR_CONNECT_WALLET;
        } else if (hasPendingTransactions) {
            error = ERROR_TRANSACTION_ONGOING;
        } else if (!isPositiveBigNumber(transferAmount)) {
            error = ERROR_INVALID_NUMBER;
        } else if (
            convertEsdtToWei(transferAmount).comparedTo(
                netstatsRedux.walletTokensMap[token_id]?.balance ?? ZERO_STRING,
            ) > 0
        ) {
            error = ERROR_NOT_ENOUGH_BALANCE;
        } else if (!isValidElrondAddress(destinationAddress)) {
            error = ERROR_INVALID_MVX_ADDRESS;
        } else if (destinationAddress === address) {
            error = "You can't send to yourself";
        }

        if (error) {
            toastError(error);
            return;
        }

        const payments = [
            TokenTransfer.fungibleFromBigInteger(token_id, convertEsdtToWei(transferAmount, DEFAULT_DECIMALS)),
        ];
        await snakeTransfer(payments, destinationAddress, address);
    };

    return (
        <div className="container" style={{ marginTop: '20px' }}>
            <div className="d-flex justify-content-center mt-4">
                <div className="vesta_x_pool_card" style={{ width: '900px', minHeight: '100px' }}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex justify-content-center" style={{ marginLeft: 'calc(50% - 117px)' }}>
                            <span style={{ color: '#F1DC46', fontSize: '1.3rem' }}>Transfer restricted Tokens</span>
                        </div>
                    </div>

                    <Row className="container" style={{ marginTop: '10px' }}>
                        <Col className="d-flex justify-content-center mb-3" xs={4} lg={2}>
                            <button
                                className={clsx(
                                    'farm_card_individual_element_but d-flex flex-column justify-content-center align-items-center',
                                    transferOption === TransferOptionEnum.Auryn && 'selected',
                                )}
                                onClick={() => setTransferOption(TransferOptionEnum.Auryn)}
                            >
                                <span>AURYN</span>
                                <img className="farm_logo_element mb-1" src={getTokenLogo(AURYN_TOKEN_ID)} />
                                <span>Fee 5%</span>
                            </button>
                        </Col>
                        <Col className="d-flex justify-content-center mb-3" xs={4} lg={2}>
                            <button
                                className={clsx(
                                    'farm_card_individual_element_but d-flex flex-column justify-content-center align-items-center',
                                    transferOption === TransferOptionEnum.Eauryn && 'selected',
                                )}
                                onClick={() => setTransferOption(TransferOptionEnum.Eauryn)}
                            >
                                <span>EAURYN</span>
                                <img className="farm_logo_element mb-1" src={getTokenLogo(EAURYN_TOKEN_ID)} />
                                <span>Fee 10%</span>
                            </button>
                        </Col>
                        <Col className="d-flex justify-content-center mb-3" xs={4} lg={2}>
                            <button
                                className={clsx(
                                    'farm_card_individual_element_but d-flex flex-column justify-content-center align-items-center',
                                    transferOption === TransferOptionEnum.Bvst && 'selected',
                                )}
                                onClick={() => setTransferOption(TransferOptionEnum.Bvst)}
                            >
                                <span>bVST</span>
                                <img className="farm_logo_element mb-1" src={BLESS_VESTA_LOGO} />
                                <span>Fee 25%</span>
                            </button>
                        </Col>
                        <Col md={12} lg={6}>
                            <input
                                className="vesta_x_input mb-3"
                                placeholder={`Erd...`}
                                onChange={(e) => setDestinationAddress(e.target.value)}
                                value={destinationAddress}
                            />
                            <input
                                className="vesta_x_input"
                                type="number"
                                placeholder={`${transferOption} Amount`}
                                onChange={handleChangeAmount}
                                value={transferAmount}
                            />

                            <div className="d-flex justify-content-between mt-1">
                                <div className="input-token-error">{amountError}</div>
                                <div
                                    className="add-liquidity-input-token-balance-box"
                                    onClick={handleSetTransferAmount}
                                >
                                    <div className="">Balance:&nbsp;</div>
                                    <div
                                        style={{
                                            color: 'white',
                                        }}
                                    >
                                        {formatNumber(
                                            convertWeiToEsdt(accountAmount, DEFAULT_DECIMALS, DEFAULT_DECIMALS),
                                            DEFAULT_DECIMALS,
                                        )}
                                    </div>
                                </div>
                            </div>

                            <button
                                className="slip-but w-100 mt-3"
                                onClick={handlerTransfer}
                                disabled={hasPendingTransactions}
                            >
                                Transfer
                            </button>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}
