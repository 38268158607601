import React from 'react';
import Switch from '@mui/material/Switch';
import { Col, Row } from 'react-bootstrap';
import { BUSD_TOKEN_ID, USDC_TOKEN_ID, USDD_TOKEN_ID, USDT_TOKEN_ID, WDAI_TOKEN_ID } from 'config';
import { getTokenLogo } from 'z/elrond';

interface IInputTokenAmount {
    tokenId: string;
}
const InputTokenAmount = ({ tokenId }: IInputTokenAmount) => {
    return (
        <div className="d-flex gap-1 w-100">
            <div className="w-100">
                <input className="coil-input w-100" type="text" />
                <div className="d-flex justify-content-end">
                    <div className="balance-but">Balance: 0</div>
                </div>
            </div>
            <div>
                <img src={getTokenLogo(tokenId)} width={'40px'} />
            </div>
        </div>
    );
};

const FiveTokensStake = () => {
    return (
        <React.Fragment>
            <Row>
                <Col lg={3} />
                <Col
                    className="py-3"
                    lg={6}
                    style={{
                        border: '1px solid #2c1b36',
                        borderRadius: '10px',
                        color: '#98A1C0',
                    }}
                >
                    <InputTokenAmount tokenId={USDD_TOKEN_ID} />
                    <InputTokenAmount tokenId={USDC_TOKEN_ID} />
                    <InputTokenAmount tokenId={USDT_TOKEN_ID} />
                    <InputTokenAmount tokenId={BUSD_TOKEN_ID} />
                    <InputTokenAmount tokenId={WDAI_TOKEN_ID} />

                    <div className="d-flex justify-content-center align-items-center">
                        <button className="mt-3 assets-button">Add Liquidity & Stake to VAULT™</button>
                    </div>

                    <div className="d-flex justify-content-end align-items-center">
                        <div className="d-flex align-items-center">
                            <span>Off</span>
                            <Switch color="secondary" />
                            <span>On</span>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default FiveTokensStake;
