import { useEffect } from 'react';
import { Address } from '@multiversx/sdk-core/out';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { ADMIN_SC_ADDRESS } from 'config';
import { selectAdmin, setIsAdmin, setIsSwapAdmin } from 'redux/reducers';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { createSmartContract, swapRouterSmartContract } from 'z/elrond';
import adminAbiJson from '../elrond/abi/vesta-admin.abi.json';
import { useCommonMvxHooks } from './common-mvx';

export const adminSmartContract = createSmartContract(adminAbiJson, ADMIN_SC_ADDRESS);

export const useAdminHooks = () => {
    const { address } = useGetAccountInfo();
    const { queryContract } = useCommonMvxHooks();
    const dispatch = useAppDispatch();

    const { isAdmin, isSwapAdmin } = useAppSelector(selectAdmin);

    useEffect(() => {
        if (!address) return;
        (async () => {
            const _isAdmin = await getIsAdmin(address);
            dispatch(setIsAdmin(_isAdmin));
        })();

        (async () => {
            const _admins = await getSwapAdmins();
            dispatch(setIsSwapAdmin(_admins.includes(address)));
        })();
    }, [address]);

    const getIsAdmin = async (checkAddress: string): Promise<boolean> => {
        try {
            const addressArg = Address.isValid(checkAddress) ? checkAddress : Address.Zero().bech32();
            const interaction = adminSmartContract.methods.getIsAdmin([addressArg]);
            const result = await queryContract(interaction);

            return result;
        } catch (err) {
            console.error('getIsAdmin', err);
            return false;
        }
    };

    const getSwapAdmins = async (): Promise<string[]> => {
        try {
            const interaction = swapRouterSmartContract.methodsExplicit.getOwnersAndAdmins();
            const result = await queryContract(interaction);

            return result.map((value: any) => value.toString());
        } catch (err) {
            console.error('getSwapAdmins', err);
            return [];
        }
    };

    return {
        isAdmin,
        isSwapAdmin,
    };
};
