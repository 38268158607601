import { Address, AddressValue, BytesValue, TokenTransfer, VariadicValue } from '@multiversx/sdk-core/out';
import { MVX_CHAIN_ID } from 'config';
import {
    EsdtTokenPaymentType,
    NftStakingUserContextType,
    NftStakingUserFeeContextType,
    StakeEsdtFeeDetails,
} from 'z/types';
import { elrondDappSendTransactions, mvxQuery, parseEsdtTokenPayment } from './common';
import { nftStakingV2SmartContract } from './provider';
import { getNftStakingProofs } from './vesta-api';

const parseFeeDetails = (v: any): StakeEsdtFeeDetails => ({
    nonce: v.nonce.toNumber(),
    epoch_left: v.epoch_left.toNumber(),
    vst_amount: v.vst_amount.toString(),
});

export const getNftStakingUserContext = async (address: string): Promise<NftStakingUserContextType | undefined> => {
    try {
        const interaction = nftStakingV2SmartContract.methodsExplicit.getUserContext([
            new AddressValue(new Address(address)),
        ]);
        const value = await mvxQuery(interaction);

        return {
            snapshot: {
                bloodshed_score: value.snapshot.bloodshed_score.toString(),
                coding_division_score: value.snapshot.coding_division_score.toString(),
                midas_og_score: value.snapshot.midas_og_score.toString(),
                nosferatu_score: value.snapshot.nosferatu_score.toString(),
                share_score: value.snapshot.share_score.toString(),
                snake_score: value.snapshot.snake_score.toString(),
                subsidiary_score: value.snapshot.subsidiary_score.toString(),
                vault_booster_score: value.snapshot.vault_booster_score.toString(),
                vesta_x_dao_score: value.snapshot.vesta_x_dao_score.toString(),
                xbunnies_score: value.snapshot.xbunnies_score.toString(),
                is_snake_deb_disabled: value.snapshot.is_snake_deb_disabled,
                is_subsidiary_deb_disabled: value.snapshot.is_subsidiary_deb_disabled,
            },
            staked_bloodshed: value.staked_bloodshed.map((v: any) => parseEsdtTokenPayment(v)),
            staked_coding_division: value.staked_coding_division.map((v: any) => parseEsdtTokenPayment(v)),
            staked_midas_og: value.staked_midas_og.map((v: any) => parseEsdtTokenPayment(v)),
            staked_nosferatu: value.staked_nosferatu.map((v: any) => parseEsdtTokenPayment(v)),
            staked_snake: value.staked_snake.map((v: any) => parseEsdtTokenPayment(v)),
            staked_vault_booster: value.staked_vault_booster.map((v: any) => parseEsdtTokenPayment(v)),
            staked_vesta_x_dao: [],
            staked_xbunnies: value.staked_xbunnies.map((v: any) => parseEsdtTokenPayment(v)),
        };
    } catch (err) {
        console.error('getNftStakingUserContext', err);
    }
    return undefined;
};

export const getNftStakingUserFeeContext = async (
    address: string,
): Promise<NftStakingUserFeeContextType | undefined> => {
    try {
        const interaction = nftStakingV2SmartContract.methodsExplicit.getUserFeeContext([
            new AddressValue(new Address(address)),
        ]);

        const value = await mvxQuery(interaction);

        return {
            flat_fee: value.flat_fee.toString(),
            snake: value.snake.map(parseFeeDetails),
            coding_division: value.coding_division.map(parseFeeDetails),
            bloodshed: value.bloodshed.map(parseFeeDetails),
            nosferatu: value.nosferatu.map(parseFeeDetails),
            vault_booster: value.vault_booster.map(parseFeeDetails),
            xbunnies: value.xbunnies.map(parseFeeDetails),
        };
    } catch (err) {
        console.error('getNftStakingUserFeeContext', err);
    }
    return undefined;
};
export const nftStakingStake = async (transfers: TokenTransfer[], sender: string) => {
    const interaction = nftStakingV2SmartContract.methods
        .stake([])
        .withMultiESDTNFTTransfer(transfers)
        .withChainID(MVX_CHAIN_ID)
        .withSender(new Address(sender))
        .withGasLimit(50_000_000 + 1_000_000 * transfers.length);

    const transaction = interaction.check().buildTransaction();
    const txName = 'Stake';
    const { sessionId, error } = await elrondDappSendTransactions(transaction, txName);

    return { sessionId, error };
};

export const nftStakingUnstake = async (
    unstakePayments: EsdtTokenPaymentType[],
    payments: TokenTransfer[],
    sender: string,
) => {
    const interaction = nftStakingV2SmartContract.methods
        .unstake([unstakePayments])
        .withMultiESDTNFTTransfer(payments)
        .withChainID(MVX_CHAIN_ID)
        .withSender(new Address(sender))
        .withGasLimit(50_000_000 + 2_500_000 * unstakePayments.length);

    const transaction = interaction.check().buildTransaction();
    const txName = 'Unstake';
    const { sessionId, error } = await elrondDappSendTransactions(transaction, txName);

    return { sessionId, error };
};

export const nftStakingClaimRewards = async (payments: EsdtTokenPaymentType[], sender: string) => {
    const proofs = await getNftStakingProofs(sender);
    if (!proofs) return;

    const interaction = nftStakingV2SmartContract.methods
        .claimRewards([
            VariadicValue.fromItemsCounted(...proofs.map((proof) => new BytesValue(Buffer.from(proof, 'hex')))),
            ...payments,
        ])
        .withChainID(MVX_CHAIN_ID)
        .withSender(new Address(sender))
        .withGasLimit(20_000_000 + 1_200_000 * payments.length);

    const transaction = interaction.check().buildTransaction();
    const txName = 'Claim rewards';
    const { sessionId, error } = await elrondDappSendTransactions(transaction, txName);

    return { sessionId, error };
};

export const nftStakingDisableSnakeDeb = async (sender: string) => {
    const proofs = await getNftStakingProofs(sender);
    if (!proofs) return;

    const interaction = nftStakingV2SmartContract.methods
        .disableSnakeDeb([])
        .withChainID(MVX_CHAIN_ID)
        .withSender(new Address(sender))
        .withGasLimit(6_000_000);

    const transaction = interaction.check().buildTransaction();
    const txName = 'Disable Snake DEB';
    const { sessionId, error } = await elrondDappSendTransactions(transaction, txName);

    return { sessionId, error };
};

export const nftStakingEnableSnakeDeb = async (sender: string) => {
    const proofs = await getNftStakingProofs(sender);
    if (!proofs) return;

    const interaction = nftStakingV2SmartContract.methods
        .enableSnakeDeb([])
        .withChainID(MVX_CHAIN_ID)
        .withSender(new Address(sender))
        .withGasLimit(6_000_000);

    const transaction = interaction.check().buildTransaction();
    const txName = 'Enable Snake DEB';
    const { sessionId, error } = await elrondDappSendTransactions(transaction, txName);

    return { sessionId, error };
};

export const nftStakingDisableSubsidiaryDeb = async (sender: string) => {
    const proofs = await getNftStakingProofs(sender);
    if (!proofs) return;

    const interaction = nftStakingV2SmartContract.methods
        .disableSubsidiaryDeb([])
        .withChainID(MVX_CHAIN_ID)
        .withSender(new Address(sender))
        .withGasLimit(6_000_000);

    const transaction = interaction.check().buildTransaction();
    const txName = 'Disable Subsidiary DEB';
    const { sessionId, error } = await elrondDappSendTransactions(transaction, txName);

    return { sessionId, error };
};

export const nftStakingEnableSubsidiaryDeb = async (sender: string) => {
    const proofs = await getNftStakingProofs(sender);
    if (!proofs) return;

    const interaction = nftStakingV2SmartContract.methods
        .enableSubsidiaryDeb([])
        .withChainID(MVX_CHAIN_ID)
        .withSender(new Address(sender))
        .withGasLimit(6_000_000);

    const transaction = interaction.check().buildTransaction();
    const txName = 'Enable Subsidiary DEB';
    const { sessionId, error } = await elrondDappSendTransactions(transaction, txName);

    return { sessionId, error };
};
