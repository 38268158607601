import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { PageBack } from 'components/Elements';
import { routeNames } from 'routes';
import { useNftStakingHooks } from 'z/hooks/nft-staking';
import { SUBSIDIARY_STAKING_POOL_INFO } from './const';
import MultiItemsManage from './MultiItemsManage';

const SubsidiaryStakingRow = () => {
    const { stakingId } = useParams();
    const navigate = useNavigate();

    useNftStakingHooks();

    const stakingInfo = SUBSIDIARY_STAKING_POOL_INFO[Number(stakingId)];

    useEffect(() => {
        if (Number(stakingId) >= 0 && Number(stakingId) < 7) {
            // validated URL
            return;
        }
        navigate('/404');
    }, [stakingId]);

    return (
        <div className="container" style={{ marginTop: '50px', color: '#98a1c0' }}>
            <div className="d-flex justify-content-center">
                <div className="vesta-first-container" style={{ maxWidth: '1024px' }}>
                    <PageBack url={routeNames.subsidiaryStaking} />

                    <div className="text-center" style={{ fontSize: '18px', color: '#f1dc46' }}>
                        {stakingInfo.name} Staking
                    </div>

                    <MultiItemsManage />

                    {/* {stakingId == '1' && (
                        <div className="mt-4">
                            <BoostVaultsAndFarms />
                        </div>
                    )} */}
                </div>
            </div>
        </div>
    );
};

export default SubsidiaryStakingRow;
