import React, { useEffect, useState } from 'react';
import { useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';
import { TbExchange } from 'react-icons/tb';
import { GSC_TOKEN_ID, USDD_TOKEN_ID } from 'config';
import { SwapTokenSelect } from 'pages/Swap/SwapTokenSelect';
import { selectFarm, selectNetstats } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { getTokenDecimals } from 'z/elrond';
import { useStableCoinProtocol } from 'z/hooks/stable-coin-protocol';
import { convertWeiToEsdt, formatNumber } from 'z/utils';

export const StableCoinSwapComponent = ({ goldPrice }: { goldPrice: number }) => {
    const netstatsRedux = useAppSelector(selectNetstats);
    const farmRedux = useAppSelector(selectFarm);

    const { sendMintUsddTransaction, sendMintGscTransaction } = useStableCoinProtocol();

    const [inputToken, setInputToken] = useState(GSC_TOKEN_ID);
    const [outputToken, setOutputToken] = useState(USDD_TOKEN_ID);

    const [inputTokenAmount, setInputTokenAmount] = React.useState<string>('0');
    const [outputTokenAmount, setOutputTokenAmount] = React.useState<string>('0');

    const { hasPendingTransactions } = useGetPendingTransactions();

    function isNumber(text: string) {
        return /^-?\d*\.?\d+$/.test(text);
    }

    const getMintStablePercentage = () => {
        return 0.9 + 0.01 * Math.max(farmRedux.eliteAccountTier - 1, 0);
    };

    const getConversionRate = (from: 'PM' | 'STABLE') => {
        if (from === 'PM') {
            return new BigNumber(goldPrice).multipliedBy(getMintStablePercentage());
        } else {
            return new BigNumber(0.99);
        }
    };

    const getValidatedInputAmount = (e: string) => {
        const value = e.replace(',', '.');
        const separatorCount = (value.match(/[.]/g) || []).length;

        if (separatorCount > 1) {
            return;
        }

        if (value.endsWith('.') || value.endsWith(',')) {
            return value;
            return;
        }
        if (value === '' || value === '.' || value === ',' || !isNumber(value)) {
            return '0';
            return;
        }

        if (parseFloat(value) < 0) {
            return '0';
            return;
        }

        if (value.includes('.')) {
            const parts = value.split('.');
            if (parts[1].length > 18 || parts.length > 2) return;
        }

        const targetAmount = new BigNumber(value).shiftedBy(18);
        const tokenInMaxBalance = new BigNumber(netstatsRedux.walletTokensMap[inputToken]?.balance ?? '0');
        if (targetAmount.isGreaterThan(tokenInMaxBalance)) {
            return tokenInMaxBalance.shiftedBy(-18).toString();
        } else {
            return value;
        }
    };

    const handleTokenAmountChange = (e: string) => {
        const validInput = getValidatedInputAmount(e);
        if (validInput === undefined) return;
        setInputTokenAmount(validInput);

        if (inputToken === GSC_TOKEN_ID) {
            setOutputTokenAmount(new BigNumber(validInput).multipliedBy(getConversionRate('PM')).toString(10));
        } else {
            setOutputTokenAmount(
                new BigNumber(validInput).dividedBy(goldPrice).multipliedBy(getConversionRate('STABLE')).toString(10),
            );
        }
    };

    const handleTokenChange = (newTokenId: string, isTokenIn: boolean) => {
        if (isTokenIn) {
            setInputToken(newTokenId);
            setOutputToken(newTokenId === GSC_TOKEN_ID ? USDD_TOKEN_ID : GSC_TOKEN_ID);
        } else {
            setOutputToken(newTokenId);
            setInputToken(newTokenId === GSC_TOKEN_ID ? USDD_TOKEN_ID : GSC_TOKEN_ID);
        }
    };

    const handleSwapTokens = () => {
        const _inputToken = inputToken;
        setInputToken(outputToken);
        setOutputToken(_inputToken);
        handleTokenAmountChange('0');
    };

    const handleSwap = async () => {
        if (inputToken === GSC_TOKEN_ID) {
            await sendMintUsddTransaction(inputTokenAmount);
        } else {
            await sendMintGscTransaction(inputTokenAmount);
        }
    };

    useEffect(() => {
        if (hasPendingTransactions) {
            return;
        }
        handleTokenAmountChange('0');
    }, [hasPendingTransactions]);

    return (
        <div className="active-pool-li-container w-100 m-1">
            <div className="text-center mb-3" style={{ fontSize: '18px', color: '#f1dc46' }}>
                Mint {outputToken.split('-')[0]}
            </div>
            <div className="text-center mb-3">
                <div className="d-flex justify-content-center flex-column">
                    <div className="vesta_x_swap_input_box mb-1">
                        <div className="d-flex align-items-center">
                            <input
                                className="swap_input"
                                type="text"
                                value={inputTokenAmount}
                                onChange={(e) => handleTokenAmountChange(e.target.value)}
                            />
                            <SwapTokenSelect
                                tokenId={inputToken}
                                onChangeTokenId={(id) => handleTokenChange(id, true)}
                                oppositeTokenId={inputToken}
                                swapAvailabletokenIds={[GSC_TOKEN_ID, USDD_TOKEN_ID]}
                                disableSearch
                            />
                        </div>
                        <div className="d-flex justify-content-between mt-1">
                            <div
                                className="add-liquidity-input-token-balance-box"
                                onClick={() =>
                                    handleTokenAmountChange(
                                        new BigNumber(netstatsRedux.walletTokensMap[inputToken]?.balance ?? '0')
                                            .shiftedBy(-18)
                                            .toString(10),
                                    )
                                }
                            >
                                {inputToken && (
                                    <>
                                        <div className="">Balance:&nbsp;</div>
                                        <div style={{ color: 'white' }}>
                                            {formatNumber(
                                                convertWeiToEsdt(
                                                    new BigNumber(
                                                        netstatsRedux.walletTokensMap[inputToken]?.balance ?? '0',
                                                    ),
                                                    getTokenDecimals(inputToken),
                                                    getTokenDecimals(inputToken),
                                                ),
                                                getTokenDecimals(inputToken),
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center" style={{ marginTop: '-17px', zIndex: '2' }}>
                        <button className="exchange_but" onClick={handleSwapTokens} disabled={hasPendingTransactions}>
                            <TbExchange />
                        </button>
                    </div>
                    <div style={{ marginTop: '-17px' }}>
                        <div className="vesta_x_swap_input_box">
                            <div className="d-flex align-items-center">
                                <input className="swap_input" type="number" value={outputTokenAmount} disabled />
                                <SwapTokenSelect
                                    tokenId={outputToken}
                                    onChangeTokenId={(id) => handleTokenChange(id, false)}
                                    oppositeTokenId={outputToken}
                                    swapAvailabletokenIds={[GSC_TOKEN_ID, USDD_TOKEN_ID]}
                                />
                            </div>
                            <div className="d-flex justify-content-between mt-1">
                                <div className="add-liquidity-input-token-balance-box" style={{ cursor: 'auto' }}>
                                    {outputToken && (
                                        <>
                                            <div className="">Balance:&nbsp;</div>
                                            <div style={{ color: 'white' }}>
                                                {formatNumber(
                                                    convertWeiToEsdt(
                                                        new BigNumber(
                                                            netstatsRedux.walletTokensMap[outputToken]?.balance ?? '0',
                                                        ),
                                                        getTokenDecimals(inputToken),
                                                        getTokenDecimals(inputToken),
                                                    ),
                                                    getTokenDecimals(inputToken),
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mt-1">
                        Exchange 1 GSC for ~{getConversionRate('PM').toFixed(2)} USDD ({getMintStablePercentage() * 100}
                        % of gold price)
                        <br />
                        Exchange ~
                        {new BigNumber(goldPrice)
                            .multipliedBy(new BigNumber(1).dividedBy(getConversionRate('STABLE')))
                            .toFixed(2)}{' '}
                        USDD for 1 GSC (99% of gold price)
                    </div>
                    <div className="d-flex justify-content-center mt-2">
                        <button className="mt-3 add-liquidity-button" onClick={handleSwap}>
                            Mint {outputToken.split('-')[0]}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
