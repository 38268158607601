import React from 'react';
import { STAKING_SC_ADDRESSES } from 'config/staking';
import { StakingCard } from './StakingCard';

export const Staking = () => {
    return (
        <>
            <div className="container" style={{ marginTop: '50px' }}>
                <div className="d-flex justify-content-center">
                    <div className="vesta_x_staking_card">
                        <span
                            style={{
                                color: '#F1DC46',
                                marginLeft: '15px',
                                fontSize: '1.25rem',
                            }}
                        >
                            Staking
                        </span>
                        {
                            STAKING_SC_ADDRESSES.map((stakingScAddress: string, index: number) => (
                                <StakingCard scAddress={stakingScAddress} key={`s-r-${index}`} />
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    );
};
