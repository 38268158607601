import React from 'react';
import { Oval } from 'react-loader-spinner';

export const CustomLoading = () => {
  return (
    <div className="d-flex justify-content-center align-items-center mt-3" style={{ minHeight: '300px' }}>
      <Oval
        height={80}
        width={80}
        color="#F1DC46"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor="#F1DC46"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  );
};
