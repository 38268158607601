import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { eatGetTokenBalance } from 'z/elrond';

interface EliteAccountTrackerState {
    tokenBalances: Record<string, string>;
}

const initialState: EliteAccountTrackerState = { tokenBalances: {} };

export const eliteAccountTrackerSlice = createSlice({
    name: 'eliteAccountTracker',
    initialState,
    reducers: {
        setTokenBalance: (
            state: EliteAccountTrackerState,
            action: PayloadAction<{ tokenIdentifier: string; balance: string }>,
        ) => {
            state.tokenBalances[action.payload.tokenIdentifier] = action.payload.balance;
        },
    },
});

export const selectEliteAccountTracker = (state: RootState) => state.eliteAccountTracker;

export const eliteAccountTrackerReducer = eliteAccountTrackerSlice.reducer;

export const setEatTokenBalance = async (address: string, tokenIdentifier: string, dispatch: Dispatch) => {
    const balance = await eatGetTokenBalance(address, tokenIdentifier);
    dispatch(eliteAccountTrackerSlice.actions.setTokenBalance({ tokenIdentifier, balance }));
};
