import { EsdtTokenPaymentType } from './common';

export interface MemeLiquidityVaultBaseContextType {
    state: string;

    lp_token_ids: string[];
    raw_vesta_token_id: string;
    sft_token_id: string;
    sft_lock_period: number;

    auxiliary_standard_reward_token_ids: string[];
    premium_reward_token_ids: string[];
    elite_reward_token_ids: string[];
}

export interface MemeLiquidityVaultStatsContextType {
    lp_token_reserves: EsdtTokenPaymentType[];
    sft_reserves: string[];
    reward_rates: { lp_token_id: string; reward_rate: string }[];
    total_standard_vesta_power: string;
    total_premium_vesta_power: string;
    total_elite_vesta_power: string;
}

export interface MemeLiquidityVaultUserContextType {
    lp_token_staked_amounts: EsdtTokenPaymentType[];

    sft_staked_amounts: string[];

    standard_vesta_power: string;
    premium_vesta_power: string;
    elite_vesta_power: string;

    reward_amount: string;
    last_claimed_timestamp: number;

    deb: number;
    vlm: number;
    im: number;
    vm: number;

    auxiliary_standard_reward_payments: EsdtTokenPaymentType[];
    premium_reward_payments: EsdtTokenPaymentType[];
    elite_reward_payments: EsdtTokenPaymentType[];

    lp_token_fee_procents: EsdtTokenPaymentType[];
    total_standard_snake_power_fee_amount: string;
}

export enum MemeLiquidityVestaVaultSelectedHolding {
    VaultVesta = 'MemeLiquidityVaultVesta',
    VaultSvesta = 'MemeLiquidityVaultSvesta',
    WalletVesta = 'MemeLiquidityWalletVesta',
    WalletSvesta = 'MemeLiquidityWalletSvesta',

    VaultUsdcJaket = 'MemeLiquidityVaultUsdcJaket',
    VaultUsdcPadwn = 'MemeLiquidityVaultUsdcPadwn',

    VaultVegldJaket = 'MemeLiquidityVaultVegldJaket',
    VaultVegldPadwn = 'MemeLiquidityVaultVegldPadwn',
    VaultVegldRaccoon = 'MemeLiquidityVaultVegldRaccoon',

    WalletUsdcJaket = 'MemeLiquidityWalletUsdcJaket',
    WalletUsdcPadwn = 'MemeLiquidityWalletUsdcPadwn',

    WalletVegldJaket = 'MemeLiquidityWalletVegldJaket',
    WalletVegldPadwn = 'MemeLiquidityWalletVegldPadwn',
    WalletVegldRaccoon = 'MemeLiquidityWalletVegldRaccoon',

    VaultANCIENT = 'MemeLiquidityVaultANCIENT',
    WalletANCIENT = 'MemeLiquidityWalletANCIENT',
}
