import * as React from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';

export const PageNotFound = () => {
    const { pathname } = useLocation();
    
    return (
        <div className='d-flex flex-fill align-items-center justify-content-center container' style={{ marginTop: '50px', minHeight: '600px' }}>
            <div className='shadow-sm rounded p-4 border-0'>
                <div className='card-body text-center d-flex flex-column justify-content-center py-5' style={{ color: 'white', minWidth: '400px' }}>
                    <div className='dapp-icon icon-medium'>
                        <FontAwesomeIcon
                        icon={faSearch}
                        className="mx-auto text-muted fa-3x mb-2"
                        />
                    </div>
                    <span className="h4 empty-heading mt-3 mb-3">Page not found</span>
                    <span className="empty-details" style={{ color: 'gray' }}>{pathname}</span>
                </div>
            </div>
        </div>
    );
};
