import { IS_DEV, config } from "./elrond";

export const CAPITAL_VESTA_VAULT_SC_ADDRESS = config.contracts['vault-capital'].address;
export const AUXILIARY_VESTA_VAULT_SC_ADDRESS = config.contracts['vault-auxiliary'].address;
export const CRYPTO_VESTA_VAULT_SC_ADDRESS = config.contracts['vault-crypto'].address;
export const LIQUIDITY_VESTA_VALULT_SC_ADDRESS = config.contracts['vault-ouro'].address;
export const MEME_LIQUIDITY_VESTA_VALULT_SC_ADDRESS = config.contracts['vault-meme'].address;
export const VEGLD_VAULT_SC_ADDRESS = config.contracts['vault-vegld'].address;

export const SVST_MINT_LIMITS = [
  0,
  0,
  0,
  0,
  4, // tier 3
  8,
  12,
  16,
  20,
];

export const SVST_MULTIPLIERS = [
  0,
  1.197814,
  1.429536,
  1.699876,
  2.013983,
  2.377447,
  2.796290,
  3.276950,
  3.826255,
  4.451377,
  5.159780,
  5.959151,
  6.857312,
  7.862124,
  8.981362,
  10.22258,
  11.59300,
  13.09927,
  14.74739,
  16.54243,
  18.48842,
];

export enum InjectRewardType {
  StandardReward = 'Standard',
  EliteReward = 'Elite',
  PremiumReward = 'Premium',
}

export const SLIP_BOOST_LIQUIDITY_VAULT = 0.005;

// for vEGLD-vault
export const VEGLD_VST_PAIR_ADDRESS = IS_DEV ? '' : 'erd1qqqqqqqqqqqqqpgqyzaermw7mvtps23zq0gca625gd3pqrft0a0s4jw4ax';

// stable-liquidity-vault
export const STABLE_LIQUIDITY_VAULT_ADDRESS = config.contracts['vault-stable-liquidity'].address;
