import React, { useEffect, useState } from 'react';
import { TokenTransfer } from '@multiversx/sdk-core/out';
import { useGetAccount, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import { SendTransactionReturnType } from '@multiversx/sdk-dapp/types';
import BigNumber from 'bignumber.js';
import { Col, Row } from 'react-bootstrap';
import Modal from 'react-modal';
import { useNavigate, useLocation } from 'react-router-dom';

import { OURO_TOKEN_ID, TOKEN_INFO_MAP, VSUSDCOURO_TOKEN_ID, VUSDCOURO_TOKEN_ID } from 'config';
import { selectNetstats } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import {
    LiquidityTokenSelectorForVault,
    MemeLiquidityVaultUserContextType,
    MemeLiquidityVestaVaultSelectedHolding,
    OuroLiquidityVaultUserContextType,
    OuroLiquidityVestaVaultSelectedHolding,
} from 'z/types';
import {
    formatNumber,
    convertWeiToEsdt,
    DEFAULT_DECIMALS,
    BIG_NUMBER_ZERO,
    parseBigNumber,
    isLiquidityNativeVaultSelectedHolding,
    isLiquiditySecuredVaultSelectedHolding,
    isLiquidityNativeWalletSelectedHolding,
    isLiquiditySecuredWalletSelectedHolding,
    ERROR_CONNECT_WALLET,
    ERROR_TRANSACTION_ONGOING,
    toastError,
    convertEsdtToWei,
    ERROR_NOT_ENOUGH_BALANCE,
} from 'z/utils';
import { LiquidityVestaVaultLpSlider } from './LiquidityVestaVaultLpSlider';

enum VaultBalancesEnum {
    Native = 'Native',
    Secured = 'Secured',
}

interface LiquidityVestaVaultLiquidityParams {
    vaultUserContext?: OuroLiquidityVaultUserContextType | MemeLiquidityVaultUserContextType;
    selectedTokenId: string;
    onTokenSelected(
        token_id: string,
        holding_type: OuroLiquidityVestaVaultSelectedHolding | MemeLiquidityVestaVaultSelectedHolding,
    ): void;
    selectedHolding: OuroLiquidityVestaVaultSelectedHolding | MemeLiquidityVestaVaultSelectedHolding;
    setLiquidityBalance: React.Dispatch<React.SetStateAction<BigNumber>>;
    nativeTokenSelectorForVaults: {
        nativeTokenSelectorForVault: LiquidityTokenSelectorForVault[];
        securedTokenSelectorForVault: LiquidityTokenSelectorForVault[];
        nativeTokenSelectorForWallet: LiquidityTokenSelectorForVault[];
        securedTokenSelectorForWallet: LiquidityTokenSelectorForVault[];
    };
    tokenSliderTitles: {
        firstTokenSliderTitle: string;
        secondTokenSliderTitle: string;
    };
    stakeToken(
        payments: TokenTransfer[],
        sender: string,
    ): Promise<{
        sessionId: SendTransactionReturnType;
        error: SendTransactionReturnType;
    }>;
    unstakeToken(payments: TokenTransfer[]): Promise<{
        sessionId: SendTransactionReturnType;
        error: SendTransactionReturnType;
    }>;
}

export const LiquidityVestaVaultLiquidity = ({
    vaultUserContext,
    selectedTokenId,
    onTokenSelected,
    selectedHolding,
    setLiquidityBalance,
    nativeTokenSelectorForVaults: {
        nativeTokenSelectorForVault,
        securedTokenSelectorForVault,
        nativeTokenSelectorForWallet,
        securedTokenSelectorForWallet,
    },
    tokenSliderTitles: { firstTokenSliderTitle, secondTokenSliderTitle },
    stakeToken,
    unstakeToken,
}: LiquidityVestaVaultLiquidityParams) => {
    const { address } = useGetAccount();
    const { hasPendingTransactions } = useGetPendingTransactions();

    const netstatsRedux = useAppSelector(selectNetstats);

    const getVaultBalance = (_selectedTokenId: string, vaultBalances: VaultBalancesEnum): BigNumber => {
        const tokenSelectorForVault =
            vaultBalances === VaultBalancesEnum.Native ? nativeTokenSelectorForVault : securedTokenSelectorForVault;
        if (vaultUserContext && tokenSelectorForVault.find(({ token_id }) => token_id === _selectedTokenId)) {
            const tokenInVault = vaultUserContext.lp_token_staked_amounts.find(
                ({ token_identifier }) => token_identifier === _selectedTokenId,
            );
            if (tokenInVault) {
                return parseBigNumber(tokenInVault.amount);
            }
        }
        return BIG_NUMBER_ZERO;
    };

    const [nativeVaultBalance, setNativeVaultBalance] = useState(
        getVaultBalance(VUSDCOURO_TOKEN_ID, VaultBalancesEnum.Native),
    );
    const [securedVaultBalance, setSecuredVaultBalance] = useState(
        getVaultBalance(VSUSDCOURO_TOKEN_ID, VaultBalancesEnum.Secured),
    );
    const [nativeWalletBalance, setNativeWalletBalance] = useState(
        parseBigNumber(netstatsRedux.walletTokensMap[VUSDCOURO_TOKEN_ID]?.balance || '0'),
    );
    const [securedWalletBalance, setSecuredWalletBalance] = useState(
        parseBigNumber(netstatsRedux.walletTokensMap[VSUSDCOURO_TOKEN_ID]?.balance || '0'),
    );

    const [isModalOpen, setIsModalOpen] = useState(false);

    const getBalance = (): BigNumber | undefined => {
        if (isLiquidityNativeVaultSelectedHolding(selectedHolding)) {
            const _nativeVaultBalance = getVaultBalance(selectedTokenId, VaultBalancesEnum.Native);
            setNativeVaultBalance(_nativeVaultBalance);
            return _nativeVaultBalance;
        } else if (isLiquiditySecuredVaultSelectedHolding(selectedHolding)) {
            const _securedVaultBalance = getVaultBalance(selectedTokenId, VaultBalancesEnum.Secured);
            setSecuredVaultBalance(_securedVaultBalance);
            return _securedVaultBalance;
        } else if (isLiquidityNativeWalletSelectedHolding(selectedHolding)) {
            const _nativeWalletBalance = parseBigNumber(netstatsRedux.walletTokensMap[selectedTokenId]?.balance || '0');
            setNativeWalletBalance(_nativeWalletBalance);
            return _nativeWalletBalance;
        } else if (isLiquiditySecuredWalletSelectedHolding(selectedHolding)) {
            const _securedWalletBalance = parseBigNumber(
                netstatsRedux.walletTokensMap[selectedTokenId]?.balance || '0',
            );
            setSecuredWalletBalance(_securedWalletBalance);
            return _securedWalletBalance;
        }
        return undefined;
    };

    useEffect(() => {
        const _balance = getBalance();
        _balance !== undefined && setLiquidityBalance(_balance);
    }, [selectedHolding, vaultUserContext, netstatsRedux, selectedTokenId]);

    async function onStakeAll() {
        let error = '';
        if (!address) {
            error = ERROR_CONNECT_WALLET;
        } else if (hasPendingTransactions) {
            error = ERROR_TRANSACTION_ONGOING;
        }

        if (error) {
            toastError(error);
            return;
        }

        const payments = [...nativeTokenSelectorForWallet, ...securedTokenSelectorForWallet]
            .map(({ token_id }) =>
                TokenTransfer.fungibleFromBigInteger(
                    token_id,
                    convertEsdtToWei(netstatsRedux.walletTokensMap[token_id]?.balance || '0', 0),
                ),
            )
            .filter((token) => token.amountAsBigInteger.toNumber());

        if (!payments.length) {
            toastError(ERROR_NOT_ENOUGH_BALANCE);
            return;
        }

        await stakeToken(payments, address);
    }

    async function onUnstakeAll() {
        let error = '';
        if (!address) {
            error = ERROR_CONNECT_WALLET;
        } else if (hasPendingTransactions) {
            error = ERROR_TRANSACTION_ONGOING;
        }

        if (error) {
            toastError(error);
            return;
        }

        const payments = vaultUserContext
            ? vaultUserContext.lp_token_staked_amounts
                  .map(({ token_identifier, amount }) =>
                      TokenTransfer.fungibleFromBigInteger(token_identifier, convertEsdtToWei(amount, 0)),
                  )
                  .filter((token) => token.amountAsBigInteger.toNumber())
            : [];

        if (!payments.length) {
            toastError(ERROR_NOT_ENOUGH_BALANCE);
            return;
        }

        await unstakeToken(payments);
    }

    const navigate = useNavigate();
    const location = useLocation();

    const navigateLPView = () => {
        navigate(`${location.pathname}/LP`);
    };

    return (
        <Col md={12} lg={8} style={{ flex: '1 1' }}>
            <div className="vault-sft-staking-container h-100">
                <div className="d-flex justify-content-between align-items-center">
                    <button
                        className="assets-button"
                        disabled={hasPendingTransactions}
                        onClick={() => setIsModalOpen(true)}
                    >
                        Unstake All
                    </button>
                    <button className="assets-liquidity-button" onClick={() => navigateLPView()}>
                        Liquidity
                    </button>
                    <button className="assets-button" disabled={hasPendingTransactions} onClick={onStakeAll}>
                        Stake All
                    </button>
                </div>
                <Row>
                    <Col md={12} lg={6}>
                        <div className="second-card-style px-3">
                            <div className="token-stats-title mb-3">Vault Holdings</div>

                            <div>{firstTokenSliderTitle}</div>

                            <LiquidityVestaVaultLpSlider
                                nativeTokenSelectorForVault={nativeTokenSelectorForVault}
                                onTokenSelected={onTokenSelected}
                                selectedHolding={selectedHolding}
                                isDisabled={(holding_type: OuroLiquidityVestaVaultSelectedHolding) =>
                                    [
                                        OuroLiquidityVestaVaultSelectedHolding.VaultNativeOurobVST,
                                        OuroLiquidityVestaVaultSelectedHolding.VaultNativeOurosVST,
                                        OuroLiquidityVestaVaultSelectedHolding.VaultNativeOurofVST,
                                    ].includes(holding_type)
                                }
                            />

                            <div className="text-right">
                                {formatNumber(
                                    convertWeiToEsdt(nativeVaultBalance, DEFAULT_DECIMALS, DEFAULT_DECIMALS),
                                    DEFAULT_DECIMALS,
                                )}
                            </div>

                            <div>{secondTokenSliderTitle}</div>

                            <LiquidityVestaVaultLpSlider
                                nativeTokenSelectorForVault={securedTokenSelectorForVault}
                                onTokenSelected={onTokenSelected}
                                selectedHolding={selectedHolding}
                                isDisabled={(holding_type: OuroLiquidityVestaVaultSelectedHolding) =>
                                    holding_type === OuroLiquidityVestaVaultSelectedHolding.VaultSecuredOurobVST
                                }
                            />

                            <div className="text-right">
                                {formatNumber(
                                    convertWeiToEsdt(securedVaultBalance, DEFAULT_DECIMALS, DEFAULT_DECIMALS),
                                    DEFAULT_DECIMALS,
                                )}
                            </div>
                        </div>
                    </Col>
                    <Col md={12} lg={6}>
                        <div className="second-card-style px-3">
                            <div className="token-stats-title mb-3">Wallet Holdings</div>

                            <div>{firstTokenSliderTitle}</div>

                            <LiquidityVestaVaultLpSlider
                                nativeTokenSelectorForVault={nativeTokenSelectorForWallet}
                                onTokenSelected={onTokenSelected}
                                selectedHolding={selectedHolding}
                                isDisabled={(holding_type: OuroLiquidityVestaVaultSelectedHolding) =>
                                    [
                                        OuroLiquidityVestaVaultSelectedHolding.WalletNativeOurobVST,
                                        OuroLiquidityVestaVaultSelectedHolding.WalletNativeOurosVST,
                                        OuroLiquidityVestaVaultSelectedHolding.WalletNativeOurofVST,
                                    ].includes(holding_type)
                                }
                            />

                            <div className="text-right">
                                {formatNumber(
                                    convertWeiToEsdt(nativeWalletBalance, DEFAULT_DECIMALS, DEFAULT_DECIMALS),
                                    DEFAULT_DECIMALS,
                                )}
                            </div>

                            <div>{secondTokenSliderTitle}</div>

                            <LiquidityVestaVaultLpSlider
                                nativeTokenSelectorForVault={securedTokenSelectorForWallet}
                                onTokenSelected={onTokenSelected}
                                selectedHolding={selectedHolding}
                                isDisabled={(holding_type: OuroLiquidityVestaVaultSelectedHolding) =>
                                    holding_type === OuroLiquidityVestaVaultSelectedHolding.WalletSecuredOurobVST
                                }
                            />

                            <div className="text-right">
                                {formatNumber(
                                    convertWeiToEsdt(securedWalletBalance, DEFAULT_DECIMALS, DEFAULT_DECIMALS),
                                    DEFAULT_DECIMALS,
                                )}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            <Modal
                isOpen={isModalOpen}
                style={{
                    content: {
                        top: '30%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        transform: 'translate(-50%, -50%)',
                        width: '50vw',
                    },
                }}
                onRequestClose={() => setIsModalOpen(false)}
            >
                <div style={{ padding: '2rem' }}>
                    <div style={{ textAlign: 'center', color: '#F1DC46', fontSize: '1.25rem' }}>
                        {`Unstaking now costs ${formatNumber(
                            convertWeiToEsdt(
                                vaultUserContext?.total_standard_snake_power_fee_amount || '0',
                                TOKEN_INFO_MAP[OURO_TOKEN_ID].decimals,
                            ),
                        )} Standard Snake Power in Fees`}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '2rem' }}>
                        <button
                            className="assets-button"
                            disabled={hasPendingTransactions}
                            onClick={() => {
                                onUnstakeAll();
                                setIsModalOpen(false);
                            }}
                        >
                            Unstake All
                        </button>
                        <button
                            className="assets-button"
                            disabled={hasPendingTransactions}
                            onClick={() => setIsModalOpen(false)}
                        >
                            Dismiss
                        </button>
                    </div>
                </div>
            </Modal>
        </Col>
    );
};
