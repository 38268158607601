export const ELITE_ACCOUNT_TIER_NAMES: string[] = [
  '',
  '',
  'Partner',
  'Investor',
  'Entrepreneur',
  'Baron',
  'Mogul',
  'Magnate',
  'Demiurg',
];
