import React, { useState, FC } from 'react';
import clsx from 'clsx';
import Modal from 'react-modal';
import { AssetResource } from 'components/Elements';
import { ResponsiveNumInput } from './Elements';

interface propsInterface {
    className?: string;
    resourceType: string;
    resourceUrl: string;
    quantity?: number;
    selectedQuantity: number;
    updateSelectedQuantity: (e: number) => void;
    nonce: number;
}

export const NftResource: FC<propsInterface> = ({
    className,
    resourceType,
    resourceUrl,
    quantity,
    selectedQuantity,
    updateSelectedQuantity,
    nonce,
}) => {
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [proceedNftAmount, setProceedNftAmount] = useState<number>(selectedQuantity);

    function onClickNft() {
        if (selectedQuantity > 0) {
            setProceedNftAmount(0);
            return updateSelectedQuantity(0);
        }

        if (quantity === 1) return updateSelectedQuantity(1);

        onOpenModal();
    }

    function onOpenModal() {
        setModalIsOpen(true);
    }

    function onCloseModal() {
        setModalIsOpen(false);
    }

    function onSubmit() {
        if (updateSelectedQuantity) updateSelectedQuantity(proceedNftAmount);
        onCloseModal();
    }

    return (
        <div className={className ? className : ''}>
            <div
                className={clsx({
                    'nft-button': true,
                    hover: true,
                    selected: selectedQuantity > 0,
                })}
                onClick={onClickNft}
                style={{ cursor: 'pointer', textAlign: 'center' }}
            >
                <div className="nft-card w-100">
                    <AssetResource uri={resourceUrl} isVideo={resourceType == 'video'} />

                    {!(quantity == undefined || quantity == 1) && (
                        <div className="staked-amount-centered">
                            {selectedQuantity == 0 ? `x${quantity}` : `${selectedQuantity}/${quantity}`}
                        </div>
                    )}
                </div>
                <span>#{nonce}</span>
            </div>

            <Modal isOpen={modalIsOpen} onRequestClose={onCloseModal} style={customStyles} ariaHideApp={false}>
                <div style={{ minWidth: '400px', padding: '8px 16px' }}>
                    <div className="text-center mb-4" style={{ fontSize: '18px', color: '#f1dc46' }}>
                        Select SFT quantity
                    </div>

                    <div className="active-pool-li-container">
                        <div className="responsive-flex">
                            <div style={{ width: '100px', borderRadius: '10px' }}>
                                <AssetResource uri={resourceUrl} isVideo={resourceType == 'video'} />
                            </div>
                            <div style={{ fontSize: '28px', color: '#98A1C0' }}>X</div>
                            <ResponsiveNumInput
                                value={proceedNftAmount}
                                onChange={setProceedNftAmount}
                                min={1}
                                max={quantity}
                            />
                        </div>
                    </div>

                    <div className="d-flex w-100 mt-4">
                        <button className="w-50 add-liquidity-button" onClick={onCloseModal}>
                            Cancel
                        </button>
                        <button className="w-50 ms-2 add-liquidity-button" onClick={onSubmit}>
                            Select
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
