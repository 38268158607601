import React, { useEffect, useState } from 'react';
import { TokenTransfer } from '@multiversx/sdk-core/out';
import { useGetAccount, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { InfoTooltip, NftResource } from 'components';
import { ToggleStakeOrUnstake } from 'components/Elements';
import { BLOODSHED_COLLECTION, NOSFERATU_COLLECTION, OURO_TOKEN_ID, VST_TOKEN_ID, X_BUNNIES_COLLECTION } from 'config';
import { selectNetstats, selectNftStakingV2 } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { nftStakingStake, nftStakingUnstake } from 'z/elrond';
import { useNftStakingHooks } from 'z/hooks/nft-staking';
import {
    EsdtTokenPaymentType,
    NftStakingSnapshotKeysType,
    NftStakingStakePaymentType,
    NftStakingUserContextKeysType,
    NftStakingUserFeeContextKeysType,
    StakeEsdtFeeDetails,
    StakingPoolInfoType,
} from 'z/types';
import { ERROR_CONNECT_WALLET, ERROR_TRANSACTION_ONGOING, formatNumber, getNftImage, toastError } from 'z/utils';
import { getSftImage } from 'z/utils/sfts';
import { SUBSIDIARY_STAKING_POOL_INFO } from './const';
import NftsPagination from './NftsPagination';
import StakingPoolInfo from './StakingPoolInfo';
import { UnstakeFees } from './UnstakeFees';

const itemsPerPage = 100;

const MultiItemsManage = () => {
    const { stakingId } = useParams();
    const stakingInfo = SUBSIDIARY_STAKING_POOL_INFO[Number(stakingId)];

    const { hasPendingTransactions } = useGetPendingTransactions();
    const { address } = useGetAccount();
    useNftStakingHooks();
    const nftStakingRedux = useAppSelector(selectNftStakingV2);
    const netstatsRedux = useAppSelector(selectNetstats);

    const [isStake, setIsStake] = useState<boolean>(true);
    const [stakePayments, setStakePayments] = useState<NftStakingStakePaymentType[]>([]);
    const [unstakePayments, setUnstakePayments] = useState<NftStakingStakePaymentType[]>([]);
    const [selectedPage, setSelectedPage] = useState(1);

    useEffect(() => {
        setSelectedPage(1);
    }, [isStake]);

    useEffect(() => {
        if (netstatsRedux.walletNftsSftsMap && netstatsRedux.walletNftsSftsMap[stakingInfo.identifier]) {
            setStakePayments(
                netstatsRedux.walletNftsSftsMap[stakingInfo.identifier].map(({ collection, nonce, balance, type }) => {
                    return {
                        identifier: collection,
                        nonce,
                        balance: type === 'NonFungibleESDT' ? 1 : Number(balance),
                        selectedQuantity: 0,
                        url:
                            type === 'NonFungibleESDT'
                                ? getNftImage(collection, nonce)
                                : getSftImage(collection, nonce),
                    };
                }),
            );
        }
    }, [netstatsRedux]);

    useEffect(() => {
        const auxSelector = `staked_${stakingInfo.auxSelector}` as NftStakingUserContextKeysType;

        if (nftStakingRedux.userContext) {
            const stakedNfts = nftStakingRedux.userContext[auxSelector] as EsdtTokenPaymentType[];

            if (!stakedNfts.length) {
                return;
            }

            setUnstakePayments(
                stakedNfts.map(({ token_identifier, token_nonce, amount }) => ({
                    identifier: token_identifier,
                    nonce: token_nonce,
                    balance: parseInt(amount),
                    selectedQuantity: 0,
                    url: [BLOODSHED_COLLECTION, X_BUNNIES_COLLECTION, NOSFERATU_COLLECTION].includes(
                        stakingInfo.identifier,
                    )
                        ? getNftImage(token_identifier, token_nonce)
                        : getSftImage(token_identifier, token_nonce),
                })),
            );
        }
    }, [nftStakingRedux]);

    const getPoolInfo = (): StakingPoolInfoType[] => {
        const scoreSelector = `${stakingInfo.auxSelector}_score` as NftStakingSnapshotKeysType;
        const totalSnapshotScore: string | undefined =
            nftStakingRedux.totalSnapshot && (nftStakingRedux.totalSnapshot[scoreSelector] as string);
        const userScore = new BigNumber(
            nftStakingRedux.userSnapshot ? (nftStakingRedux.userSnapshot[scoreSelector] as string) : '0',
        );
        return [
            {
                key: 'Total score',
                value: formatNumber(totalSnapshotScore || '0', 5),
            },
            {
                key: 'Your score',
                value: formatNumber(userScore, 5),
            },
            {
                key: 'Your reward share',
                value: `${formatNumber(
                    userScore.multipliedBy(1000).div(new BigNumber(totalSnapshotScore || '1')),
                    3,
                )}‰`,
            },
            {
                key: `Your staked assets`,
                value: formatNumber(
                    unstakePayments.reduce((res, { balance }) => res + balance, 0),
                    0,
                ),
            },
            {
                key: `Your wallet assets`,
                value: formatNumber(
                    stakePayments.reduce((res, { balance }) => res + balance, 0),
                    0,
                ),
            },
        ];
    };

    const getVstFeeAmount = () => {
        if (!nftStakingRedux.userFeeContext) return '0';
        const userFeeContextDetails = nftStakingRedux.userFeeContext[
            stakingInfo.auxSelector as NftStakingUserFeeContextKeysType
        ] as StakeEsdtFeeDetails[];
        return unstakePayments
            .filter(({ selectedQuantity }) => !!selectedQuantity)
            .reduce((prev, { nonce, selectedQuantity }) => {
                const feeDetails = userFeeContextDetails?.find((el: StakeEsdtFeeDetails) => nonce === el.nonce);
                return BigNumber.sum(prev, new BigNumber(feeDetails?.vst_amount || '0').multipliedBy(selectedQuantity));
            }, new BigNumber(0))
            .toString();
    };

    const onStake = async (): Promise<void> => {
        const stakePaymentsAux = stakePayments
            .filter(({ selectedQuantity }) => !!selectedQuantity)
            .map(({ identifier, nonce, selectedQuantity }) =>
                TokenTransfer.metaEsdtFromBigInteger(identifier, nonce, selectedQuantity),
            );
        if (!address) {
            return toastError(ERROR_CONNECT_WALLET);
        } else if (hasPendingTransactions) {
            return toastError(ERROR_TRANSACTION_ONGOING);
        } else if (stakePaymentsAux.length > 100) {
            return toastError('Too many nfts selected');
        } else if (stakePaymentsAux.length === 0) {
            return toastError('Not enough SFTs/SFTs have been selected');
        }

        await nftStakingStake(stakePaymentsAux, address);
    };

    const onUnstake = async () => {
        const vstFeeAmount = getVstFeeAmount();
        const unstakePaymentsAux = unstakePayments
            .filter(({ selectedQuantity }) => !!selectedQuantity)
            .map(({ identifier, nonce, selectedQuantity }) => ({
                token_identifier: identifier,
                token_nonce: nonce,
                amount: selectedQuantity.toString(),
            }));
        if (!nftStakingRedux.userFeeContext?.flat_fee) {
            return toastError('Something went wrong');
        } else if (
            parseInt(netstatsRedux.walletTokensMap[OURO_TOKEN_ID]?.balance || '0') <
            parseInt(nftStakingRedux.userFeeContext.flat_fee)
        ) {
            return toastError('Not enough OURO for fee');
        } else if (parseInt(netstatsRedux.walletTokensMap[VST_TOKEN_ID]?.balance || '0') < parseInt(vstFeeAmount)) {
            return toastError('Not enough VST for fee');
        } else if (unstakePaymentsAux.length > 100) {
            return toastError('Too many nfts selected');
        } else if (unstakePaymentsAux.length === 0) {
            return toastError('Not enough SFTs/SFTs have been selected');
        }

        const payments: TokenTransfer[] = [
            TokenTransfer.metaEsdtFromBigInteger(
                OURO_TOKEN_ID,
                0,
                new BigNumber(nftStakingRedux.userFeeContext?.flat_fee || '0').multipliedBy(1.01).toFixed(0),
            ),
        ];

        const vstAmount = new BigNumber(vstFeeAmount);
        if (vstAmount.gt(0)) {
            payments.push(
                TokenTransfer.metaEsdtFromBigInteger(VST_TOKEN_ID, 0, vstAmount.multipliedBy(1.01).toFixed(0)),
            );
        }

        await nftStakingUnstake(unstakePaymentsAux, payments, address);
    };

    const selectAllAuxFn = (prevPayments: NftStakingStakePaymentType[], maxQuantity: boolean) =>
        prevPayments.map((payment, index) =>
            index >= (selectedPage - 1) * itemsPerPage && index < selectedPage * itemsPerPage
                ? { ...payment, selectedQuantity: maxQuantity ? payment.balance : 0 }
                : payment,
        );

    return (
        <>
            <Row style={{ marginTop: '16px', rowGap: '12px' }}>
                <Col md={4}>
                    <div className="active-pool-li-container h-100">
                        <img
                            src={stakingInfo.avatar}
                            alt="nft avatar"
                            width={'100%'}
                            style={{ borderRadius: '10px' }}
                        />
                    </div>
                </Col>

                <Col md={8}>
                    <div className="active-pool-li-container h-100 position-relative">
                        <div className="text-center" style={{ fontSize: '18px' }}>
                            Staking pool info
                        </div>

                        <StakingPoolInfo className="mt-4" data={getPoolInfo()} />

                        <InfoTooltip title="Staking pool info" />
                    </div>
                </Col>

                <Col md={4}>
                    <div className="active-pool-li-container h-100"></div>
                </Col>

                <Col md={8}>
                    <div className="active-pool-li-container h-100">
                        <ToggleStakeOrUnstake value={isStake} onChange={setIsStake} />

                        <div className="mt-3">
                            {itemsPerPage < (isStake ? stakePayments.length : unstakePayments.length) && (
                                <NftsPagination
                                    itemsLength={isStake ? stakePayments.length : unstakePayments.length}
                                    selectedPage={selectedPage}
                                    setSelectedPage={setSelectedPage}
                                    itemsPerPage={itemsPerPage}
                                />
                            )}
                            <div className="d-flex justify-content-center mt-3">
                                <button
                                    className="farm_but py-2"
                                    style={{ width: '10rem', marginRight: '1rem' }}
                                    disabled={hasPendingTransactions}
                                    onClick={() =>
                                        isStake
                                            ? setStakePayments((prevPayments) => selectAllAuxFn(prevPayments, true))
                                            : setUnstakePayments((prevPayments) => selectAllAuxFn(prevPayments, true))
                                    }
                                >
                                    Select all
                                </button>
                                <button
                                    className="farm_but py-2"
                                    style={{ width: '10rem' }}
                                    disabled={hasPendingTransactions}
                                    onClick={() =>
                                        isStake
                                            ? setStakePayments((prevPayments) => selectAllAuxFn(prevPayments, false))
                                            : setUnstakePayments((prevPayments) => selectAllAuxFn(prevPayments, false))
                                    }
                                >
                                    Deselect all
                                </button>
                            </div>
                        </div>

                        <div className="d-flex flex-wrap gap-2 mt-4 justify-content-center ">
                            {(isStake ? stakePayments : unstakePayments)
                                ?.filter(
                                    (_, index) =>
                                        index >= (selectedPage - 1) * itemsPerPage &&
                                        index < selectedPage * itemsPerPage,
                                )
                                .map(({ nonce, balance, selectedQuantity, url }) => (
                                    <NftResource
                                        key={`nft-resource-${nonce}`}
                                        resourceUrl={url}
                                        resourceType="image"
                                        selectedQuantity={selectedQuantity}
                                        quantity={balance}
                                        nonce={nonce}
                                        updateSelectedQuantity={(newSelectedQuantity: number) =>
                                            isStake
                                                ? setStakePayments((prevPayments) =>
                                                      prevPayments.map((payment) =>
                                                          payment.nonce === nonce
                                                              ? { ...payment, selectedQuantity: newSelectedQuantity }
                                                              : payment,
                                                      ),
                                                  )
                                                : setUnstakePayments((prevPayments) =>
                                                      prevPayments.map((payment) =>
                                                          payment.nonce === nonce
                                                              ? { ...payment, selectedQuantity: newSelectedQuantity }
                                                              : payment,
                                                      ),
                                                  )
                                        }
                                    />
                                ))}
                        </div>

                        {((isStake && !stakePayments.length) || (!isStake && !unstakePayments.length)) && (
                            <div className="mt-4">{`You have no eligible ${stakingInfo.type}s for ${
                                isStake ? '' : 'un'
                            }staking`}</div>
                        )}

                        <UnstakeFees
                            isStake={isStake}
                            flat_fee={nftStakingRedux.userFeeContext?.flat_fee || '0'}
                            vst_amount={getVstFeeAmount()}
                        />

                        <div className="d-flex justify-content-center mt-4">
                            <button
                                className="farm_but py-3"
                                style={{ width: '12rem' }}
                                disabled={hasPendingTransactions}
                                onClick={isStake ? onStake : onUnstake}
                            >
                                {isStake ? 'Stake' : 'Unstake'}
                            </button>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default MultiItemsManage;
