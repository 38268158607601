import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { AdminDecreasePreciousMetal } from './StableCoinAdminComponents/AdminDecreasePreciousMetal';
import { AdminIncreasePreciousMetal } from './StableCoinAdminComponents/AdminIncreasePreciousMetal';
import { AdminMintPreciousMetal } from './StableCoinAdminComponents/AdminMintPreciousMetal';
import { AdminMintStableCoin } from './StableCoinAdminComponents/AdminMintStableCoin';
import { AdminStableCoinStatistics } from './StableCoinAdminComponents/AdminStableCoinStatistics';

export const StableCoinAdmin = () => {
    return (
        <div className="container" style={{ marginTop: '50px' }}>
            <div className="d-flex justify-content-center mt-4">
                <div className="vesta_x_dashboard_card" style={{ minHeight: '40rem' }}>
                    <Row style={{ rowGap: '12px' }}>
                        <Col sm={12} className="d-flex justify-content-center">
                            <AdminStableCoinStatistics />
                        </Col>
                        <Col md={6} sm={12}>
                            <AdminMintStableCoin />
                        </Col>
                        <Col md={6} sm={12}>
                            <AdminMintPreciousMetal />
                        </Col>
                        <Col md={6} sm={12}>
                            <AdminIncreasePreciousMetal />
                        </Col>
                        <Col md={6} sm={12}>
                            <AdminDecreasePreciousMetal />
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};
