import React, { useState } from 'react';
import { NumberInput } from 'pages/StableCoin/NumberInput';
import { useStableCoinProtocol } from 'z/hooks/stable-coin-protocol';

export const AdminMintPreciousMetal = () => {
    const [inputTokenAmount, setInputTokenAmount] = useState('0');
    const { sendAdminMintGscTransaction } = useStableCoinProtocol();

    const handleMintAction = async () => {
        await sendAdminMintGscTransaction(inputTokenAmount);
    };
    return (
        <div className="active-pool-li-container mt-4">
            <div className="mb-1" style={{ fontSize: '18px', color: '#f1dc46' }}>
                Admin mint precious metals
            </div>
            <div>Burn stablecoins to mint precious metals without any fee.</div>
            <br />
            <br />
            <NumberInput onChange={setInputTokenAmount} />
            <div className="w-100 d-flex justify-content-center mt-3">
                <button className="mt-3 add-liquidity-button" onClick={handleMintAction}>
                    Burn USDD
                </button>
            </div>
        </div>
    );
};
