import React, { useEffect, useState } from 'react';
import { TokenTransfer } from '@multiversx/sdk-core/out';
import { useGetAccount, useGetPendingTransactions, useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import { NftType } from '@multiversx/sdk-dapp/types/tokens.types';
import { logout } from '@multiversx/sdk-dapp/utils';
import clsx from 'clsx';
import { AiOutlinePoweroff } from 'react-icons/ai';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { BsCheckLg } from 'react-icons/bs';
import { FiExternalLink } from 'react-icons/fi';
import { IoCopyOutline } from 'react-icons/io5';
import { RiAccountPinCircleLine } from 'react-icons/ri';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { NoTokenBox, SleepingVestaTokenCard, VestingTokenCard } from 'components/Elements';
import {
    AURYN_TOKEN_ID,
    AUXILIARY_VESTA_VAULT_SC_ADDRESS,
    BVST_TOKEN_ID,
    CAPITAL_VESTA_VAULT_SC_ADDRESS,
    CRYPTO_VESTA_VAULT_SC_ADDRESS,
    DEMI_FT_IDS,
    DEMI_METAESDT_IDS,
    DEMI_OURO_INDEX,
    DEMI_RAW_VESTA_INDEX,
    DEMI_SLEEPING_VESTA_INDEX,
    DEMI_VESTA_INDEX,
    DEMI_VESTED_AURYN_INDEX,
    DEMI_VESTED_EAURYN_INDEX,
    DEMI_VESTED_OURO_INDEX,
    EAURYN_TOKEN_ID,
    FVST_TOKEN_ID,
    GSC_TOKEN_ID,
    OURO_TOKEN_ID,
    RAWVST_TOKEN_ID,
    SSC_TOKEN_ID,
    SVST_TOKEN_ID,
    TOKEN_INFO_MAP,
    VAURYN_TOKEN_ID,
    VEAURYN_TOKEN_ID,
    VEGLD_TOKEN_ID,
    VOURO_TOKEN_ID,
    VST_TOKEN_ID,
} from 'config';
import { NetstatsState, selectNetstats, selectSwap } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { routeNames } from 'routes';
import {
    getAccountNftsByCollections,
    getTokenBalancesFromApi,
    getTokenLogo,
    mergeSleepingVesta,
    snakeCoilViewBaseContext,
    vaultViewStatsContext,
    vaultViewUserContext,
} from 'z/elrond';
import { useVestaCommonHooks } from 'z/hooks';
import { SnakeCoilBaseContext, TokenBalanceType, VaultStatsContextType, VaultUserContextType } from 'z/types';
import {
    BIG_NUMBER_ZERO,
    formatNumber,
    convertToDollarString,
    convertWeiToEsdt,
    ERROR_CONNECT_WALLET,
    ERROR_TRANSACTION_ONGOING,
    toastError,
    toastSuccess,
    DEFAULT_DECIMALS,
    parseBigNumber,
} from 'z/utils';
import { AssetsLiquidity } from './AssetsLiquidity';
import { DexLiquidity } from './DexLiquidity';
import { FarmLiquidity } from './FarmLiquidity';

const tabs = ['Demi', 'DEX Tokens'];
const tabRoutes = ['', 'dex'];
const SELECTED_METAESDT_INDEX_KEY = 'metaesdt_index';

function PrintWalletBalanceAndValue({
    netstatsRedux,
    getTokenPrice,
    tokenId,
}: {
    netstatsRedux: NetstatsState;
    getTokenPrice: any;
    tokenId: string;
}) {
    if (netstatsRedux.walletTokensMap[tokenId] && netstatsRedux.walletTokensMap[tokenId].balance) {
        const balance = netstatsRedux.walletTokensMap[tokenId].balance;
        const decimals = TOKEN_INFO_MAP[tokenId].decimals;
        return (
            <>
                {formatNumber(convertWeiToEsdt(balance).toNumber())}
                <br />(
                {convertToDollarString(
                    convertWeiToEsdt(balance, decimals, decimals).multipliedBy(getTokenPrice(tokenId)),
                )}
                )
            </>
        );
    } else {
        return (
            <>
                0.0000
                <br />
                (0$)
            </>
        );
    }
}

function printVestaVault(
    vaultUserContext: VaultUserContextType | undefined,
    getTokenPrice: any,
    tokenId: string,
): string {
    if (!vaultUserContext) {
        return '0 (0$)';
    }

    if (tokenId == VST_TOKEN_ID) {
        return `${formatNumber(
            convertWeiToEsdt(vaultUserContext.vesta_staked_amount).toNumber(),
        )} (${convertToDollarString(
            convertWeiToEsdt(vaultUserContext.vesta_staked_amount, DEFAULT_DECIMALS, DEFAULT_DECIMALS).multipliedBy(
                getTokenPrice(tokenId),
            ),
        )})`;
    } else if (tokenId == BVST_TOKEN_ID) {
        return `${formatNumber(
            convertWeiToEsdt(vaultUserContext.blessed_vesta_staked_amount).toNumber(),
        )} (${convertToDollarString(
            convertWeiToEsdt(
                vaultUserContext.blessed_vesta_staked_amount,
                DEFAULT_DECIMALS,
                DEFAULT_DECIMALS,
            ).multipliedBy(getTokenPrice(tokenId)),
        )})`;
    } else if (tokenId == SVST_TOKEN_ID) {
        return `${formatNumber(
            convertWeiToEsdt(vaultUserContext.svesta_staked_amount).toNumber(),
        )} (${convertToDollarString(
            convertWeiToEsdt(vaultUserContext.svesta_staked_amount, DEFAULT_DECIMALS, DEFAULT_DECIMALS).multipliedBy(
                getTokenPrice(tokenId),
            ),
        )})`;
    } else {
        throw Error('printVestaVault: Invalid tokenId');
    }
}

function printVestaPowerShares(
    vaultStatsContext: VaultStatsContextType | undefined,
    vaultUserContext: VaultUserContextType | undefined,
) {
    if (!vaultStatsContext || !vaultUserContext) {
        return '0‰';
    }

    const str1 = `${formatNumber(
        !parseBigNumber(vaultStatsContext.total_standard_vesta_power).isZero()
            ? parseBigNumber(vaultUserContext.standard_vesta_power)
                  .div(vaultStatsContext.total_standard_vesta_power)
                  .multipliedBy(1_000)
                  .toNumber()
            : 0,
        3,
    )}‰`;
    const str2 = `${formatNumber(
        !parseBigNumber(vaultStatsContext.total_premium_vesta_power).isZero()
            ? parseBigNumber(vaultUserContext.premium_vesta_power)
                  .div(vaultStatsContext.total_premium_vesta_power)
                  .multipliedBy(1_000)
                  .toNumber()
            : 0,
        3,
    )}‰`;
    const str3 = `${formatNumber(
        !parseBigNumber(vaultStatsContext.total_elite_vesta_power).isZero()
            ? parseBigNumber(vaultUserContext.elite_vesta_power)
                  .div(vaultStatsContext.total_elite_vesta_power)
                  .multipliedBy(1_000)
                  .toNumber()
            : 0,
        3,
    )}‰`;

    return (
        <>
            (<span style={{ color: '#a349a2' }}>{str1}</span>
            {', '}
            <span style={{ color: '#c8bfe7' }}>{str2}</span>
            {', '}
            <span style={{ color: '#efe3af' }}>{str3}</span>)
        </>
    );
}

export const Assets = () => {
    const isLoggedIn = useGetIsLoggedIn();
    const { address } = useGetAccount();
    const { hasPendingTransactions } = useGetPendingTransactions();
    const navigate = useNavigate();
    const { getTokenPrice } = useVestaCommonHooks();

    const netstatsRedux = useAppSelector(selectNetstats);
    const swapRedux = useAppSelector(selectSwap);
    const [updatedAddress, setUpdatedAddress] = useState<string>(address);
    const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
    const [copied, setCopied] = useState<boolean>(false);

    const { selectedTab } = useParams();
    const tabIndex = tabRoutes.indexOf(selectedTab ?? '');
    const tab = tabIndex >= 0 ? tabIndex : 0;
    function gotoTab(index: number) {
        if (hasPendingTransactions) return;
        navigate(routeNames.assets + (index > 0 ? '/' + tabRoutes[index] : ''));
    }

    const [searchParams, setSearchParams] = useSearchParams();
    const selectedMetaesdtIndex = Number(searchParams.get(SELECTED_METAESDT_INDEX_KEY) ?? '-1');
    function onChangeSelectedMetaesdtIndex(value: number) {
        setSearchParams({
            [SELECTED_METAESDT_INDEX_KEY]: value.toString(),
        });
    }

    const portfolioTokens = Object.values(netstatsRedux.walletTokensMap);

    const [demiFtTokens, setDemiFtTokens] = useState<TokenBalanceType[]>([]);
    const [demiMetas, setDemiMetas] = useState<NftType[][]>([]);
    const [demiMetaBalances, setDemiMetaBalancess] = useState<string[]>([]);

    const [selectedCardIndexes, setSelectedCardIndexes] = useState<number[]>([]);
    const [aurynIndex, setAurynIndex] = useState(parseBigNumber(0));

    const [walletTokensUsdValue, setWalletTokensUsdValue] = useState<number>(0);
    const [walletLiquidityUsdValue, setWalletLiquidityUsdValue] = useState<number>(0);
    const [
        walletFarmsUsdValue,
        // setWalletFarmsUsdValue
    ] = useState<number>(0);
    const [
        ,
        // walletLiquidityUsdValues
        setWalletLiquidityUsdValues,
    ] = useState<number[]>([]);
    const portfolioUsdValuesSum = walletTokensUsdValue + walletLiquidityUsdValue + walletFarmsUsdValue;

    // const walletLiquidityPools = swapRedux.pools.filter(pool => isPositiveBigNumber(pool.lp_token_balance));

    /* eslint-disable */
    const donut_chart_data = {
        labels: ['Tokens', 'Liquidity', 'Farms'],
        values: [
            portfolioUsdValuesSum ? (walletTokensUsdValue / portfolioUsdValuesSum) * 100 : 0,
            portfolioUsdValuesSum ? (walletLiquidityUsdValue / portfolioUsdValuesSum) * 100 : 0,
            portfolioUsdValuesSum ? (walletFarmsUsdValue / portfolioUsdValuesSum) * 100 : 0,
        ],
    };
    /* eslint-enable */

    // const options: any = {
    //   series: donut_chart_data.values,
    //   chart: {
    //     type: 'donut',
    //     background: '0'
    //   },
    //   labels: donut_chart_data.labels,
    //   dataLabels: {
    //     enabled: false,
    //   },
    //   stroke: {
    //     show: true,
    //     curve: 'smooth',
    //     lineCap: 'round',
    //     colors: ["#ffffff0f"],
    //     width: 1
    //   },
    //   plotOptions: {
    //     pie: {
    //       dataLabels: {
    //         enabled: false,
    //         offset: -5
    //       }
    //     }
    //   },
    //   legend: {
    //     show: false
    //   },
    //   responsive: [
    //     {
    //       breakpoint: 480,
    //       options: {
    //         chart: {
    //           width: 350,
    //         },
    //       },
    //     },
    //   ]
    // };

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    useEffect(() => {
        const width = windowSize[0];
        if (width < 992) {
            setUpdatedAddress(
                address.substring(0, 12) + '...' + address.substring(address.length - 12, address.length),
            );
        } else {
            setUpdatedAddress(address);
        }
    }, [windowSize[0]]);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/');
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (!address || hasPendingTransactions) return;

        (async () => {
            const _tokenBalances = await getTokenBalancesFromApi(address, DEMI_FT_IDS);
            setDemiFtTokens(_tokenBalances);
        })();

        (async () => {
            const _metas = await getAccountNftsByCollections(address, DEMI_METAESDT_IDS);
            setDemiMetas(_metas);

            const _balances: string[] = [];
            for (const row of _metas) {
                let sum = BIG_NUMBER_ZERO;
                for (const nft of row) {
                    sum = sum.plus(nft.balance);
                }
                _balances.push(sum.toFixed(0));
            }
            setDemiMetaBalancess(_balances);
        })();
    }, [address, hasPendingTransactions]);

    useEffect(() => {
        if (selectedMetaesdtIndex < 0) return;

        setSelectedCardIndexes([]);
    }, [selectedMetaesdtIndex, demiMetas]);

    useEffect(() => {
        if (portfolioTokens.length > 0) {
            let _walletTokensUsdValue = 0;
            for (const row of portfolioTokens) {
                _walletTokensUsdValue += convertWeiToEsdt(row.balance, row.decimals)
                    .multipliedBy(getTokenPrice(row.identifier, aurynIndex.toNumber()))
                    .toNumber();
            }
            setWalletTokensUsdValue(_walletTokensUsdValue);
        }
    }, [portfolioTokens, netstatsRedux.vegldInEgldPrice, aurynIndex]);

    useEffect(() => {
        if (swapRedux.pools.length > 0) {
            let _walletLiquidityUsdValue = 0;
            const _walletLiquidityUsdValues = [];
            for (const pool of swapRedux.pools) {
                const firstTokenPrice = getTokenPrice(pool.first_token_id);
                const value =
                    convertWeiToEsdt(pool.first_token_reserve, pool.first_token_decimals)
                        .multipliedBy(pool.lp_token_balance)
                        .div(pool.lp_token_supply)
                        .toNumber() *
                    firstTokenPrice *
                    2;
                _walletLiquidityUsdValue += value;
                _walletLiquidityUsdValues.push(value);
            }
            setWalletLiquidityUsdValue(_walletLiquidityUsdValue);
            setWalletLiquidityUsdValues(_walletLiquidityUsdValues);
        }
    }, [swapRedux.pools, netstatsRedux.vegldInEgldPrice]);

    const [capitalVaultStatsContext, setCapitalVaultStatsContext] = useState<VaultStatsContextType>();
    const [auxiliaryVaultStatsContext, setAuxiliaryVaultStatsContext] = useState<VaultStatsContextType>();
    const [cryptoVaultStatsContext, setCryptoVaultStatsContext] = useState<VaultStatsContextType>();
    const [capitalVaultUserContext, setCapitalVaultUserContext] = useState<VaultUserContextType>();
    const [auxiliaryVaultUserContext, setAuxiliaryVaultUserContext] = useState<VaultUserContextType>();
    const [cryptoVaultUserContext, setCryptoVaultUserContext] = useState<VaultUserContextType>();
    useEffect(() => {
        if (hasPendingTransactions) return;

        (async () => {
            const _vaultStatsContext = await vaultViewStatsContext(CAPITAL_VESTA_VAULT_SC_ADDRESS);
            setCapitalVaultStatsContext(_vaultStatsContext);
        })();
        (async () => {
            const _vaultStatsContext = await vaultViewStatsContext(AUXILIARY_VESTA_VAULT_SC_ADDRESS);
            setAuxiliaryVaultStatsContext(_vaultStatsContext);
        })();
        (async () => {
            const _vaultStatsContext = await vaultViewStatsContext(CRYPTO_VESTA_VAULT_SC_ADDRESS);
            setCryptoVaultStatsContext(_vaultStatsContext);
        })();
    }, []);
    useEffect(() => {
        if (!address || hasPendingTransactions) return;

        (async () => {
            const _vaultUserContext = await vaultViewUserContext(CAPITAL_VESTA_VAULT_SC_ADDRESS, address);
            setCapitalVaultUserContext(_vaultUserContext);
        })();
        (async () => {
            const _vaultUserContext = await vaultViewUserContext(AUXILIARY_VESTA_VAULT_SC_ADDRESS, address);
            setAuxiliaryVaultUserContext(_vaultUserContext);
        })();
        (async () => {
            const _vaultUserContext = await vaultViewUserContext(CRYPTO_VESTA_VAULT_SC_ADDRESS, address);
            setCryptoVaultUserContext(_vaultUserContext);
        })();
    }, [address]);

    const [snakeCoilBaseContext, setSnakeCoilBaseContext] = useState<SnakeCoilBaseContext>();
    useEffect(() => {
        (async () => {
            const _snakeCoilBaseContext = await snakeCoilViewBaseContext();
            setSnakeCoilBaseContext(_snakeCoilBaseContext);
        })();
    }, []);

    useEffect(() => {
        if (snakeCoilBaseContext) {
            setAurynIndex(
                parseBigNumber(snakeCoilBaseContext.token_supplies[0]).div(snakeCoilBaseContext.token_supplies[1]),
            );
        }
    }, [snakeCoilBaseContext]);

    const copyAddressToClipboard = () => {
        navigator.clipboard.writeText(address);
        toastSuccess('Address is copied to clipboard.');
        setCopied(true);

        setTimeout(() => {
            setCopied(false);
        }, 500);
    };

    const onLogout = () => {
        if (hasPendingTransactions) return;
        logout();
    };

    async function onMerge() {
        let error = '';
        if (!address) {
            error = ERROR_CONNECT_WALLET;
        } else if (hasPendingTransactions) {
            error = ERROR_TRANSACTION_ONGOING;
        } else if (selectedMetaesdtIndex != DEMI_SLEEPING_VESTA_INDEX) {
            error = 'Not available yet';
        } else if (selectedCardIndexes.length < 2) {
            error = 'Select 2 or more tokens';
        }

        if (error) {
            toastError(error);
            return;
        }

        const tokens = demiMetas[selectedMetaesdtIndex];
        const payments: TokenTransfer[] = [];
        selectedCardIndexes.map((index) => {
            payments.push(
                TokenTransfer.metaEsdtFromBigInteger(
                    tokens[index].collection,
                    tokens[index].nonce,
                    tokens[index].balance,
                ),
            );
        });

        await mergeSleepingVesta(payments, address);
    }

    return (
        <>
            <div className="container" style={{ marginTop: '50px' }}>
                <div className="d-flex justify-content-center">
                    <div className="tab-box-second">
                        <div className={clsx('tab-focus', tab === 0 ? 'tab-focus-left-0' : 'tab-focus-left-50')} />
                        {tabs.map((row, index) => {
                            return (
                                <div className="tab" key={`p-t-h-${index}`} onClick={() => gotoTab(index)}>
                                    {row}
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className="d-flex justify-content-center mt-4">
                    <div className="vesta_x_dashboard_card" style={{ minHeight: '40rem' }}>
                        <div className="d-flex justify-content-between align-items-center">
                            <span
                                style={{
                                    color: '#F1DC46',
                                    marginLeft: '15px',
                                    fontSize: '1.25rem',
                                }}
                            >
                                Your Wallet
                            </span>

                            <div className="logout-but" onClick={onLogout}>
                                <AiOutlinePoweroff />
                            </div>
                        </div>

                        <div className="account-address-box">
                            <div className="avatar">
                                <RiAccountPinCircleLine />
                            </div>
                            {updatedAddress}

                            <div className="d-flex" style={{ marginLeft: '10px', gap: '4px' }}>
                                <div className="mini-but" onClick={() => copyAddressToClipboard()}>
                                    {!copied ? <IoCopyOutline /> : <BsCheckLg />}
                                </div>
                                <a
                                    href={`https://explorer.multiversx.com/accounts/${address}`}
                                    target={'_blank'}
                                    rel="noreferrer"
                                >
                                    <div className="mini-but">
                                        <FiExternalLink />
                                    </div>
                                </a>
                            </div>
                        </div>

                        {tab == 0 && selectedMetaesdtIndex < 0 && (
                            <>
                                <div className="row mt-4 second-card-style">
                                    <div className="col-12 col-sm-4">
                                        <div className="assets-token-box">
                                            <div className="title-top">VEGLD</div>
                                            <div>
                                                <img src={getTokenLogo(VEGLD_TOKEN_ID)} />
                                                <div>
                                                    <PrintWalletBalanceAndValue
                                                        netstatsRedux={netstatsRedux}
                                                        getTokenPrice={getTokenPrice}
                                                        tokenId={VEGLD_TOKEN_ID}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4">
                                        <div className="assets-token-box">
                                            <div className="title-top">Gold Standard Coin</div>
                                            <div>
                                                <img src={getTokenLogo(GSC_TOKEN_ID)} />
                                                <div>
                                                    <PrintWalletBalanceAndValue
                                                        netstatsRedux={netstatsRedux}
                                                        getTokenPrice={getTokenPrice}
                                                        tokenId={GSC_TOKEN_ID}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4">
                                        <div className="assets-token-box">
                                            <div className="title-top">Silver Standard Coin</div>
                                            <div>
                                                <img src={getTokenLogo(SSC_TOKEN_ID)} />
                                                <div>
                                                    <PrintWalletBalanceAndValue
                                                        netstatsRedux={netstatsRedux}
                                                        getTokenPrice={getTokenPrice}
                                                        tokenId={SSC_TOKEN_ID}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row second-card-style">
                                    <div className="col-12 col-sm-4">
                                        <div className="assets-token-box">
                                            <div className="title-top">Raw VST</div>
                                            <div>
                                                <img src={getTokenLogo(RAWVST_TOKEN_ID)} />
                                                <div>
                                                    {demiFtTokens[DEMI_RAW_VESTA_INDEX]
                                                        ? formatNumber(
                                                              convertWeiToEsdt(
                                                                  demiFtTokens[DEMI_RAW_VESTA_INDEX].balance,
                                                                  demiFtTokens[DEMI_RAW_VESTA_INDEX].decimals,
                                                              ),
                                                          )
                                                        : '0'}{' '}
                                                    <br />(
                                                    {demiFtTokens[DEMI_RAW_VESTA_INDEX]
                                                        ? convertToDollarString(
                                                              convertWeiToEsdt(
                                                                  demiFtTokens[DEMI_RAW_VESTA_INDEX].balance,
                                                                  demiFtTokens[DEMI_RAW_VESTA_INDEX].decimals,
                                                              ).multipliedBy(getTokenPrice(RAWVST_TOKEN_ID)),
                                                          )
                                                        : '0$'}
                                                    )
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4">
                                        <div className="assets-token-box">
                                            <div className="title-top">VST</div>
                                            <div>
                                                <img src={getTokenLogo(VST_TOKEN_ID)} />
                                                <div>
                                                    {demiFtTokens[DEMI_VESTA_INDEX]
                                                        ? formatNumber(
                                                              convertWeiToEsdt(
                                                                  demiFtTokens[DEMI_VESTA_INDEX].balance,
                                                                  demiFtTokens[DEMI_VESTA_INDEX].decimals,
                                                              ),
                                                          )
                                                        : '0'}{' '}
                                                    <br />(
                                                    {demiFtTokens[DEMI_VESTA_INDEX]
                                                        ? convertToDollarString(
                                                              convertWeiToEsdt(
                                                                  demiFtTokens[DEMI_VESTA_INDEX].balance,
                                                                  demiFtTokens[DEMI_VESTA_INDEX].decimals,
                                                              ).multipliedBy(getTokenPrice(VST_TOKEN_ID)),
                                                          )
                                                        : '0$'}
                                                    )
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4">
                                        <div className="assets-token-box">
                                            <div className="title-top">Sleeping VST</div>
                                            <div>
                                                <img src={getTokenLogo(SVST_TOKEN_ID)} />
                                                <div>
                                                    {demiMetaBalances[DEMI_SLEEPING_VESTA_INDEX]
                                                        ? formatNumber(
                                                              convertWeiToEsdt(
                                                                  demiMetaBalances[DEMI_SLEEPING_VESTA_INDEX],
                                                              ),
                                                          )
                                                        : '0'}{' '}
                                                    <br />(
                                                    {demiMetaBalances[DEMI_SLEEPING_VESTA_INDEX]
                                                        ? convertToDollarString(
                                                              convertWeiToEsdt(
                                                                  demiMetaBalances[DEMI_SLEEPING_VESTA_INDEX],
                                                              ).multipliedBy(getTokenPrice(SVST_TOKEN_ID)),
                                                          )
                                                        : '0$'}
                                                    )
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row second-card-style">
                                    <div
                                        className="col-12 mb-3 text-center"
                                        style={{
                                            color: '#F1DC46',
                                            fontSize: '1.2rem',
                                        }}
                                    >
                                        AURYNDEX™ = {aurynIndex.toNumber() ? formatNumber(aurynIndex, 9) : '???'}
                                    </div>
                                    {/* <div
                            className='col-12 mb-3 text-center'
                            style={{
                                color: '#F1DC46',
                                fontSize: '1.2rem',
                            }}
                          >
                            Demiourgos Elite Account Tier: 4 (Baron) <br />
                            Boost 2.0x
                          </div> */}

                                    <div className="col-12 col-sm-4">
                                        <div className="token-stats-title">
                                            Total OURO{' '}
                                            {formatNumber(
                                                (demiFtTokens[DEMI_OURO_INDEX]
                                                    ? convertWeiToEsdt(demiFtTokens[DEMI_OURO_INDEX].balance).toNumber()
                                                    : 0) +
                                                    (demiMetaBalances[DEMI_VESTED_OURO_INDEX]
                                                        ? convertWeiToEsdt(
                                                              demiMetaBalances[DEMI_VESTED_OURO_INDEX],
                                                          ).toNumber()
                                                        : 0),
                                            )}{' '}
                                            (
                                            {demiFtTokens[DEMI_OURO_INDEX]
                                                ? convertToDollarString(
                                                      ((demiFtTokens[DEMI_OURO_INDEX]
                                                          ? convertWeiToEsdt(
                                                                demiFtTokens[DEMI_OURO_INDEX].balance,
                                                            ).toNumber()
                                                          : 0) +
                                                          (demiMetaBalances[DEMI_VESTED_OURO_INDEX]
                                                              ? convertWeiToEsdt(
                                                                    demiMetaBalances[DEMI_VESTED_OURO_INDEX],
                                                                ).toNumber()
                                                              : 0)) *
                                                          getTokenPrice(OURO_TOKEN_ID),
                                                  )
                                                : '0$'}
                                            )
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4">
                                        <div className="token-stats-title">
                                            Total AURYN{' '}
                                            {formatNumber(
                                                (netstatsRedux.walletTokensMap[AURYN_TOKEN_ID] &&
                                                netstatsRedux.walletTokensMap[AURYN_TOKEN_ID].balance
                                                    ? convertWeiToEsdt(
                                                          netstatsRedux.walletTokensMap[AURYN_TOKEN_ID].balance,
                                                      ).toNumber()
                                                    : 0) +
                                                    (demiMetaBalances[DEMI_VESTED_AURYN_INDEX]
                                                        ? convertWeiToEsdt(
                                                              demiMetaBalances[DEMI_VESTED_AURYN_INDEX],
                                                          ).toNumber()
                                                        : 0),
                                            )}{' '}
                                            (
                                            {convertToDollarString(
                                                ((netstatsRedux.walletTokensMap[AURYN_TOKEN_ID] &&
                                                netstatsRedux.walletTokensMap[AURYN_TOKEN_ID].balance
                                                    ? convertWeiToEsdt(
                                                          netstatsRedux.walletTokensMap[AURYN_TOKEN_ID].balance,
                                                      ).toNumber()
                                                    : 0) +
                                                    (demiMetaBalances[DEMI_VESTED_AURYN_INDEX]
                                                        ? convertWeiToEsdt(
                                                              demiMetaBalances[DEMI_VESTED_AURYN_INDEX],
                                                          ).toNumber()
                                                        : 0)) *
                                                    getTokenPrice(AURYN_TOKEN_ID, aurynIndex.toNumber()),
                                            )}
                                            )
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4">
                                        <div className="token-stats-title">
                                            Total Elite AURYN{' '}
                                            {formatNumber(
                                                (netstatsRedux.walletTokensMap[EAURYN_TOKEN_ID] &&
                                                netstatsRedux.walletTokensMap[EAURYN_TOKEN_ID].balance
                                                    ? convertWeiToEsdt(
                                                          netstatsRedux.walletTokensMap[EAURYN_TOKEN_ID].balance,
                                                      ).toNumber()
                                                    : 0) +
                                                    (demiMetaBalances[DEMI_VESTED_EAURYN_INDEX]
                                                        ? convertWeiToEsdt(
                                                              demiMetaBalances[DEMI_VESTED_EAURYN_INDEX],
                                                          ).toNumber()
                                                        : 0),
                                            )}{' '}
                                            (
                                            {convertToDollarString(
                                                ((netstatsRedux.walletTokensMap[EAURYN_TOKEN_ID] &&
                                                netstatsRedux.walletTokensMap[EAURYN_TOKEN_ID].balance
                                                    ? convertWeiToEsdt(
                                                          netstatsRedux.walletTokensMap[EAURYN_TOKEN_ID].balance,
                                                      ).toNumber()
                                                    : 0) +
                                                    (demiMetaBalances[DEMI_VESTED_EAURYN_INDEX]
                                                        ? convertWeiToEsdt(
                                                              demiMetaBalances[DEMI_VESTED_EAURYN_INDEX],
                                                          ).toNumber()
                                                        : 0)) *
                                                    getTokenPrice(EAURYN_TOKEN_ID, aurynIndex.toNumber()),
                                            )}
                                            )
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-4">
                                        <div className="assets-token-box mb-2">
                                            <div className="title-top">OURO</div>
                                            <div>
                                                <img src={getTokenLogo(OURO_TOKEN_ID)} />
                                                <div>
                                                    {demiFtTokens[DEMI_OURO_INDEX]
                                                        ? formatNumber(
                                                              convertWeiToEsdt(
                                                                  demiFtTokens[DEMI_OURO_INDEX].balance,
                                                                  demiFtTokens[DEMI_OURO_INDEX].decimals,
                                                              ),
                                                          )
                                                        : '0'}{' '}
                                                    <br />(
                                                    {demiFtTokens[DEMI_OURO_INDEX]
                                                        ? convertToDollarString(
                                                              convertWeiToEsdt(
                                                                  demiFtTokens[DEMI_OURO_INDEX].balance,
                                                              ).multipliedBy(getTokenPrice(OURO_TOKEN_ID)),
                                                          )
                                                        : '0$'}
                                                    )
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4">
                                        <div className="assets-token-box mb-2">
                                            <div className="title-top">AURYN</div>
                                            <div>
                                                <img src={getTokenLogo(AURYN_TOKEN_ID)} />
                                                <div>
                                                    {netstatsRedux.walletTokensMap[AURYN_TOKEN_ID] &&
                                                    netstatsRedux.walletTokensMap[AURYN_TOKEN_ID].balance
                                                        ? formatNumber(
                                                              convertWeiToEsdt(
                                                                  netstatsRedux.walletTokensMap[AURYN_TOKEN_ID].balance,
                                                              ),
                                                          )
                                                        : '0'}{' '}
                                                    <br />(
                                                    {netstatsRedux.walletTokensMap[AURYN_TOKEN_ID] &&
                                                    netstatsRedux.walletTokensMap[AURYN_TOKEN_ID].balance
                                                        ? convertToDollarString(
                                                              convertWeiToEsdt(
                                                                  netstatsRedux.walletTokensMap[AURYN_TOKEN_ID].balance,
                                                              ).multipliedBy(
                                                                  getTokenPrice(AURYN_TOKEN_ID, aurynIndex.toNumber()),
                                                              ),
                                                          )
                                                        : '0$'}
                                                    )
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4">
                                        <div className="assets-token-box mb-2">
                                            <div className="title-top">Elite AURYN</div>
                                            <div>
                                                <img src={getTokenLogo(EAURYN_TOKEN_ID)} />
                                                <div>
                                                    {netstatsRedux.walletTokensMap[EAURYN_TOKEN_ID] &&
                                                    netstatsRedux.walletTokensMap[EAURYN_TOKEN_ID].balance
                                                        ? formatNumber(
                                                              convertWeiToEsdt(
                                                                  netstatsRedux.walletTokensMap[EAURYN_TOKEN_ID]
                                                                      .balance,
                                                              ),
                                                          )
                                                        : '0'}{' '}
                                                    <br />(
                                                    {netstatsRedux.walletTokensMap[EAURYN_TOKEN_ID] &&
                                                    netstatsRedux.walletTokensMap[EAURYN_TOKEN_ID].balance
                                                        ? convertToDollarString(
                                                              convertWeiToEsdt(
                                                                  netstatsRedux.walletTokensMap[EAURYN_TOKEN_ID]
                                                                      .balance,
                                                              ).multipliedBy(
                                                                  getTokenPrice(EAURYN_TOKEN_ID, aurynIndex.toNumber()),
                                                              ),
                                                          )
                                                        : '0$'}
                                                    )
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-4">
                                        <div
                                            className="assets-token-box clickable"
                                            onClick={() => onChangeSelectedMetaesdtIndex(DEMI_VESTED_OURO_INDEX)}
                                        >
                                            <div className="title-top">Vested OURO</div>
                                            <div>
                                                <img src={getTokenLogo(DEMI_METAESDT_IDS[DEMI_VESTED_OURO_INDEX])} />
                                                <div>
                                                    {demiMetaBalances[DEMI_VESTED_OURO_INDEX]
                                                        ? formatNumber(
                                                              convertWeiToEsdt(
                                                                  demiMetaBalances[DEMI_VESTED_OURO_INDEX],
                                                              ),
                                                          )
                                                        : '0'}{' '}
                                                    <br />(
                                                    {demiMetaBalances[DEMI_VESTED_OURO_INDEX]
                                                        ? convertToDollarString(
                                                              convertWeiToEsdt(
                                                                  demiMetaBalances[DEMI_VESTED_OURO_INDEX],
                                                              ).multipliedBy(getTokenPrice(VOURO_TOKEN_ID)),
                                                          )
                                                        : '0$'}
                                                    )
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4">
                                        <div
                                            className="assets-token-box clickable"
                                            onClick={() => onChangeSelectedMetaesdtIndex(DEMI_VESTED_AURYN_INDEX)}
                                        >
                                            <div className="title-top">Vested AURYN</div>
                                            <div>
                                                <img src={getTokenLogo(DEMI_METAESDT_IDS[DEMI_VESTED_AURYN_INDEX])} />
                                                <div>
                                                    {demiMetaBalances[DEMI_VESTED_AURYN_INDEX]
                                                        ? formatNumber(
                                                              convertWeiToEsdt(
                                                                  demiMetaBalances[DEMI_VESTED_AURYN_INDEX],
                                                              ),
                                                          )
                                                        : '0'}{' '}
                                                    <br />(
                                                    {demiMetaBalances[DEMI_VESTED_AURYN_INDEX]
                                                        ? convertToDollarString(
                                                              convertWeiToEsdt(
                                                                  demiMetaBalances[DEMI_VESTED_AURYN_INDEX],
                                                              ).multipliedBy(
                                                                  getTokenPrice(VAURYN_TOKEN_ID, aurynIndex.toNumber()),
                                                              ),
                                                          )
                                                        : '0$'}
                                                    )
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-4">
                                        <div
                                            className="assets-token-box clickable"
                                            onClick={() => onChangeSelectedMetaesdtIndex(DEMI_VESTED_EAURYN_INDEX)}
                                        >
                                            <div className="title-top">Vested Elite AURYN</div>
                                            <div>
                                                <img src={getTokenLogo(DEMI_METAESDT_IDS[DEMI_VESTED_EAURYN_INDEX])} />
                                                <div>
                                                    {demiMetaBalances[DEMI_VESTED_EAURYN_INDEX]
                                                        ? formatNumber(
                                                              convertWeiToEsdt(
                                                                  demiMetaBalances[DEMI_VESTED_EAURYN_INDEX],
                                                              ),
                                                          )
                                                        : '0'}{' '}
                                                    <br />(
                                                    {demiMetaBalances[DEMI_VESTED_EAURYN_INDEX]
                                                        ? convertToDollarString(
                                                              convertWeiToEsdt(
                                                                  demiMetaBalances[DEMI_VESTED_EAURYN_INDEX],
                                                              ).multipliedBy(
                                                                  getTokenPrice(
                                                                      VEAURYN_TOKEN_ID,
                                                                      aurynIndex.toNumber(),
                                                                  ),
                                                              ),
                                                          )
                                                        : '0$'}
                                                    )
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="col-12 mb-3 text-center"
                                    style={{
                                        color: '#F1DC46',
                                        fontSize: '1.2rem',
                                    }}
                                >
                                    VAULTS™
                                </div>

                                <div className="row">
                                    <div className="col-12 col-sm-4">
                                        <div className="col-12 second-card-style">
                                            <div className="token-stats-title">Capital Vesta.VAULT™</div>
                                            <div className="text-center mb-1">
                                                {printVestaPowerShares(
                                                    capitalVaultStatsContext,
                                                    capitalVaultUserContext,
                                                )}
                                            </div>
                                            <div className="assets-token-box-second">
                                                <img src={getTokenLogo(BVST_TOKEN_ID)} />
                                                <div>
                                                    {printVestaVault(
                                                        capitalVaultUserContext,
                                                        getTokenPrice,
                                                        BVST_TOKEN_ID,
                                                    )}
                                                </div>
                                            </div>
                                            <div className="assets-token-box-second">
                                                <img src={getTokenLogo(VST_TOKEN_ID)} />
                                                <div>
                                                    {printVestaVault(
                                                        capitalVaultUserContext,
                                                        getTokenPrice,
                                                        VST_TOKEN_ID,
                                                    )}
                                                </div>
                                            </div>
                                            <div className="assets-token-box-second">
                                                <img src={getTokenLogo(SVST_TOKEN_ID)} />
                                                <div>
                                                    {printVestaVault(
                                                        capitalVaultUserContext,
                                                        getTokenPrice,
                                                        SVST_TOKEN_ID,
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 second-card-style">
                                            <div className="token-stats-title">Auxiliary Vesta.VAULT™</div>
                                            <div className="text-center mb-1">
                                                {printVestaPowerShares(
                                                    auxiliaryVaultStatsContext,
                                                    auxiliaryVaultUserContext,
                                                )}
                                            </div>
                                            <div className="assets-token-box-second">
                                                <img src={getTokenLogo(BVST_TOKEN_ID)} />
                                                <div>
                                                    {printVestaVault(
                                                        auxiliaryVaultUserContext,
                                                        getTokenPrice,
                                                        BVST_TOKEN_ID,
                                                    )}
                                                </div>
                                            </div>
                                            <div className="assets-token-box-second">
                                                <img src={getTokenLogo(VST_TOKEN_ID)} />
                                                <div>
                                                    {printVestaVault(
                                                        auxiliaryVaultUserContext,
                                                        getTokenPrice,
                                                        VST_TOKEN_ID,
                                                    )}
                                                </div>
                                            </div>
                                            <div className="assets-token-box-second">
                                                <img src={getTokenLogo(SVST_TOKEN_ID)} />
                                                <div>
                                                    {printVestaVault(
                                                        auxiliaryVaultUserContext,
                                                        getTokenPrice,
                                                        SVST_TOKEN_ID,
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-4">
                                        <div className="col-12 second-card-style">
                                            <div className="token-stats-title">Chimeric Vesta.VAULT™</div>
                                            <div className="text-center mb-1">{`0 (0‰)`}</div>
                                            <div className="assets-token-box-second size-2x">
                                                <img src={getTokenLogo(BVST_TOKEN_ID)} />
                                                <div>0</div>
                                            </div>
                                            <div className="assets-token-box-second size-2x mb-5">
                                                <img src={getTokenLogo(VST_TOKEN_ID)} />
                                                <div>0 (0$)</div>
                                            </div>
                                            <div className="assets-token-box-second size-2x mt-5">
                                                <img src={getTokenLogo(SVST_TOKEN_ID)} />
                                                <div>0 (0$)</div>
                                            </div>
                                            <div className="assets-token-box-second size-2x">
                                                <img src={getTokenLogo(FVST_TOKEN_ID)} />
                                                <div>0</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-4">
                                        <div className="col-12 second-card-style">
                                            <div className="token-stats-title">Crypto Vesta.VAULT™</div>
                                            <div className="text-center mb-1">
                                                {printVestaPowerShares(cryptoVaultStatsContext, cryptoVaultUserContext)}
                                            </div>
                                            <div className="assets-token-box-second size-2x">
                                                <img src={getTokenLogo(BVST_TOKEN_ID)} />
                                                <div>
                                                    {printVestaVault(
                                                        cryptoVaultUserContext,
                                                        getTokenPrice,
                                                        BVST_TOKEN_ID,
                                                    )}
                                                </div>
                                            </div>
                                            <div className="assets-token-box-second size-2x">
                                                <img src={getTokenLogo(VST_TOKEN_ID)} />
                                                <div>
                                                    {printVestaVault(
                                                        cryptoVaultUserContext,
                                                        getTokenPrice,
                                                        VST_TOKEN_ID,
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 second-card-style">
                                            <div className="token-stats-title">Blessed Vesta.VAULT™</div>
                                            <div className="text-center mb-1">{`0 (0‰)`}</div>
                                            <div className="assets-token-box-second size-3x">
                                                <img src={getTokenLogo(BVST_TOKEN_ID)} />
                                                <div>0</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        {tab == 0 && selectedMetaesdtIndex >= 0 && (
                            <>
                                <div
                                    className="mt-5 text-center"
                                    style={{
                                        color: '#F1DC46',
                                        fontSize: '1.25rem',
                                        marginBottom: '1.5rem',
                                    }}
                                >
                                    <div className="d-flex justify-content-start">
                                        <button
                                            className="go-back-button"
                                            onClick={() => {
                                                navigate(`${routeNames.assets}`);
                                            }}
                                        >
                                            <BiLeftArrowAlt />
                                        </button>
                                    </div>
                                    {((index) => {
                                        if (index === DEMI_VESTED_OURO_INDEX) {
                                            return 'Vested OURO';
                                        }
                                        if (index === DEMI_VESTED_AURYN_INDEX) {
                                            return 'Vested AURYN';
                                        }
                                        if (index === DEMI_VESTED_EAURYN_INDEX) {
                                            return 'Vested Elite AURYN';
                                        }
                                        if (index === DEMI_SLEEPING_VESTA_INDEX) {
                                            return 'Sleeping VST';
                                        }
                                        return '???';
                                    })(selectedMetaesdtIndex)}
                                </div>
                                <div className="row second-card-style">
                                    {demiMetas[selectedMetaesdtIndex] && demiMetas[selectedMetaesdtIndex].length > 0 ? (
                                        demiMetas[selectedMetaesdtIndex].map((token, index) => (
                                            <div className="col-lg-3" key={index}>
                                                {selectedMetaesdtIndex === DEMI_SLEEPING_VESTA_INDEX ? (
                                                    <SleepingVestaTokenCard
                                                        token={token}
                                                        selected={selectedCardIndexes.indexOf(index) >= 0}
                                                        toggleSelected={() =>
                                                            setSelectedCardIndexes(
                                                                selectedCardIndexes.indexOf(index) >= 0
                                                                    ? selectedCardIndexes.filter((v) => v != index)
                                                                    : [...selectedCardIndexes, index],
                                                            )
                                                        }
                                                    />
                                                ) : (
                                                    <VestingTokenCard
                                                        token={token}
                                                        // selected={selectedCardIndexes.indexOf(index) >= 0}
                                                        selected={false}
                                                        toggleSelected={() =>
                                                            setSelectedCardIndexes(
                                                                selectedCardIndexes.indexOf(index) >= 0
                                                                    ? selectedCardIndexes.filter((v) => v != index)
                                                                    : [...selectedCardIndexes, index],
                                                            )
                                                        }
                                                    />
                                                )}
                                            </div>
                                        ))
                                    ) : (
                                        <NoTokenBox />
                                    )}

                                    {selectedMetaesdtIndex == DEMI_SLEEPING_VESTA_INDEX && (
                                        <div className="d-flex justify-content-between align-items-center">
                                            <button
                                                className="mt-3 add-liquidity-button"
                                                disabled={hasPendingTransactions || selectedCardIndexes.length < 2}
                                                onClick={onMerge}
                                            >
                                                Merge
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}

                        {tab == 1 && <DexLiquidity aurynIndex={aurynIndex.toNumber()} />}

                        {tab == 2 && <AssetsLiquidity />}

                        {tab == 3 && <FarmLiquidity />}

                        {/* <Row className='gy-2'>
                          <Col className='d-flex align-items-center justify-content-center' md={12} lg={4}>
                              <Chart
                                  options={options}
                                  series={options.series}
                                  type="donut"
                                  style={{ width: '100%' }}
                              />
                          </Col>

                          <Col md={6} lg={4}>
                              <div className='dashboard_card_header'>
                                  Portfolio Breakdown
                              </div>
                              <div className='dashboard_card_body mt-1'>
                                  <div className='breakdown-info' style={{ borderLeft: '3px solid #008ffb' }}>
                                      <div className='d-flex flex-column'>
                                          <span className='text-white'>Tokens</span>
                                          <span>{formatNumber(portfolioUsdValuesSum ? walletTokensUsdValue / portfolioUsdValuesSum * 100 : 0)}%</span>
                                      </div>
                                      <div>
                                          ≃ ${formatNumber(walletTokensUsdValue)}
                                      </div>
                                  </div>

                                  <div className='breakdown-info' style={{ borderLeft: '3px solid rgb(0, 227, 150)' }}>
                                      <div className='d-flex flex-column'>
                                          <span className='text-white'>Liquidity</span>
                                          <span>{formatNumber(portfolioUsdValuesSum ? walletLiquidityUsdValue / portfolioUsdValuesSum * 100 : 0)}%</span>
                                      </div>
                                      <div>
                                        ≃ ${formatNumber(walletLiquidityUsdValue)}
                                      </div>
                                  </div>

                                  <div className='breakdown-info' style={{ borderLeft: '3px solid rgb(254, 176, 25)' }}>
                                      <div className='d-flex flex-column'>
                                          <span className='text-white'>Farms</span>
                                          <span>{formatNumber(portfolioUsdValuesSum ? walletFarmsUsdValue / portfolioUsdValuesSum * 100 : 0)}%</span>
                                      </div>
                                      <div>
                                        ≃ ${formatNumber(walletFarmsUsdValue)}
                                      </div>
                                  </div>

                                  <div className='breakdown-info' style={{ borderLeft: '3px solid rgb(255, 69, 96)' }}>
                                      <div className='d-flex flex-column'>
                                          <span className='text-white'>Locked Tokens</span>
                                          <span>{"< 1%"}</span>
                                      </div>
                                      <div>
                                          = $ 0.84
                                      </div>
                                  </div>

                                  <div className='breakdown-info' style={{ borderLeft: '3px solid rgb(119, 93, 208' }}>
                                      <div className='d-flex flex-column'>
                                          <span className='text-white'>Metastaking</span>
                                          <span>1.69%</span>
                                      </div>
                                      <div>
                                          = $ 1,998.91
                                      </div>
                                  </div>
                              </div>
                          </Col>

                          <Col md={6} lg={4}>
                              <div className='dashboard_card_header'>
                                  Charging Fees Rewards
                              </div>
                              <div className='dashboard_card_body mt-1'>
                                  <div className='breakdown-info'>
                                      <div className='d-flex align-items-center'>
                                          <img src={logos.vegld} width={'40px'} height={'40px'} alt={'vegld token logo'} />
                                          <div className='text-white' style={{ marginLeft: '8px' }}>VEGLD</div>
                                      </div>
                                      <div className='d-flex flex-column text-right'>
                                          <span>
                                              {"< 0.1 VEGLD"}
                                          </span>
                                          <span>
                                              = $0
                                          </span>
                                      </div>
                                  </div>

                                  <div className='breakdown-info'>
                                      <div className='d-flex align-items-center'>
                                          <img src={logos.mex} width={'40px'} height={'40px'} alt={'mex token logo'} />
                                          <div className='text-white' style={{ marginLeft: '8px' }}>MEX</div>
                                      </div>
                                      <div className='d-flex flex-column text-right'>
                                          <span>
                                              {"< 0.1 MEX"}
                                          </span>
                                          <span>
                                              = $0
                                          </span>
                                      </div>
                                  </div>

                                  <div className='breakdown-info'>
                                      <div className='d-flex align-items-center'>
                                          <img src={logos.ride} width={'40px'} height={'40px'} alt={'ride token logo'} />
                                          <div className='text-white' style={{ marginLeft: '8px' }}>RIDE</div>
                                      </div>
                                      <div className='d-flex flex-column text-right'>
                                          <span>
                                              {"< 0.1 RIDE"}
                                          </span>
                                          <span>
                                              = $0
                                          </span>
                                      </div>
                                  </div>

                                  <div className='breakdown-info'>
                                      <div className='d-flex align-items-center'>
                                          <img src={logos.koson} width={'40px'} height={'40px'} alt={'koson token logo'} />
                                          <div className='text-white' style={{ marginLeft: '8px' }}>KOSON</div>
                                      </div>
                                      <div className='d-flex flex-column text-right'>
                                          <span>
                                              {"< 0.1 KOSON"}
                                          </span>
                                          <span>
                                              = $0
                                          </span>
                                      </div>
                                  </div>
                              </div>
                          </Col>
                      </Row>

                      <div className="custom-accordion">
                          <Accordion flush defaultActiveKey="tokens">
                              <Accordion.Item eventKey={'tokens'}>
                                  <Accordion.Header>Tokens ({portfolioTokens.length})</Accordion.Header>
                                  <Accordion.Body>
                                      <div>
                                          {portfolioTokens.length > 0 ? portfolioTokens.map((row, index) => (
                                              <div className={clsx(index < portfolioTokens.length - 1 && 'portfolio-token-row', 'd-flex justify-content-between align-items-center portfolio-token-row-common')} key={`d-p-t-${index}`}>
                                                  <div className='d-flex align-items-center'>
                                                      <img
                                                          src={row.assets ? row.assets.svgUrl : getTokenLogo(row.identifier)}
                                                          width={'40px'}
                                                          height={'40px'}
                                                          alt={`${row.ticker} logo`}
                                                      />
                                                      <div className='text-white' style={{ marginLeft: '8px' }}>
                                                          {row.balance ? formatNumber(convertWeiToEsdt(row.balance, row.decimals)) : '0'}{' '}
                                                          <span className='text-secondary-color' style={{ fontSize: '.9rem' }}>{createTokenTicker(row.identifier)}</span>
                                                      </div>
                                                  </div>
                                                  <div>
                                                      {'≃ '}
                                                      <span style={{ color: '#f0f0f0' }}>
                                                        {'$'}
                                                        {row.balance && netstatsRedux.tokenPrices[row.identifier] ? formatNumber(convertWeiToEsdt(row.balance, row.decimals).multipliedBy(netstatsRedux.tokenPrices[row.identifier])) : '0'}
                                                      </span>
                                                  </div>
                                              </div>
                                          )) : 'No tokens'}
                                      </div>
                                  </Accordion.Body>
                              </Accordion.Item>

                              <Accordion.Item eventKey={'liquidity'}>
                                  <Accordion.Header>Liquidity ({walletLiquidityPools.length})</Accordion.Header>
                                  <Accordion.Body>
                                    {walletLiquidityPools.length > 0 ? walletLiquidityPools.filter(pool => isPositiveBigNumber(pool.lp_token_balance)).map((pool, index) => (
                                      <div className={clsx(index < walletLiquidityPools.length - 1 && 'portfolio-token-row', 'd-flex justify-content-between align-items-center portfolio-token-row-common')} key={index}>
                                          <div className='d-flex align-items-center'>
                                              <img
                                                  src={getTokenLogo(pool.lp_token_id)}
                                                  width={'40px'}
                                                  height={'40px'}
                                                  alt={`${pool.lp_token_id} logo`}
                                              />
                                              <div className='text-white' style={{ marginLeft: '8px' }}>
                                                  {pool.lp_token_balance ? formatNumber(convertWeiToEsdt(pool.lp_token_balance, pool.lp_token_decimals)) : '0'}{' '}
                                                  <span className='text-secondary-color' style={{ fontSize: '.9rem' }}>{createTokenTicker(pool.lp_token_id)}</span>
                                              </div>
                                          </div>
                                          <div>
                                              {'≃ '}
                                              <span style={{ color: '#f0f0f0' }}>
                                                {'$'}
                                                {walletLiquidityUsdValues[index] ? formatNumber(walletLiquidityUsdValues[index]) : '0'}
                                              </span>
                                          </div>
                                      </div>
                                      )) : 'No tokens'}
                                  </Accordion.Body>
                              </Accordion.Item>

                              <Accordion.Item eventKey={'farms'}>
                                  <Accordion.Header>Farms (0)</Accordion.Header>
                                  <Accordion.Body>
                                      No tokens
                                  </Accordion.Body>
                              </Accordion.Item>
                          </Accordion>
                      </div> */}
                    </div>
                </div>
            </div>
        </>
    );
};
