import { TokenConfigType, TransformedTokenConfigType } from 'z/types';
import { config } from './elrond';
import { FVST_TOKEN_ID, OLD_AURYN_TOKEN_ID, OLD_EAURYN_TOKEN_ID, OURO_TOKEN_ID, RAWVST_TOKEN_ID, SVST_TOKEN_ID, VAURYN_TOKEN_ID, VEAURYN_TOKEN_ID, VEGLD_TOKEN_ID, VOURO_TOKEN_ID, VST_TOKEN_ID } from './tokens';

const transformTokensData = (data: Record<string, TokenConfigType>): Record<string, TransformedTokenConfigType> => {
    const transformedData: Record<string, TransformedTokenConfigType> = {};

    Object.keys(data).forEach((key) => {
        const token = data[key];
        transformedData[token.identifier] = {
            name: token.name,
            decimals: token.decimals,
            logo: token.logo || `https://media.elrond.com/tokens/asset/${token.identifier}/logo.svg`,
        };
    });

    return transformedData;
};

const extractValidTokens = (data: Record<string, TokenConfigType>): string[] => {
    const validTokens: string[] = Object.values(data) // Convert the object to an array of its values (Token objects)
        .filter((token) => !token.identifier.includes('XXXXXX')) // Filter out tokens with "XXXXXX" in their identifier
        .map((token) => token.identifier); // Extract the identifier from each remaining token;

    return validTokens;
};

const extractBaseTokens = (data: Record<string, TokenConfigType>): string[] => {
    const baseTokens: string[] = Object.values(data)
        .filter((token) => token.isBaseToken)
        .map((token) => token.identifier);

    return baseTokens;
};

const extractVestingTokens = (data: Record<string, TokenConfigType>): string[] => {
    const vestingTokens: string[] = Object.values(data)
        .filter((token) => token.isVestingToken)
        .map((token) => token.identifier);

    return vestingTokens;
};

const extractDemiourgosEsdtTokens = (data: Record<string, TokenConfigType>): string[] => {
    const demiourgosTokens: string[] = Object.values(data)
        .filter((token) => token.isDemiourgosToken)
        .filter((token) => token.type === 'ESDT')
        .map((token) => token.identifier);

    return demiourgosTokens;
};

const extractDemiourgosMetaEsdtTokens = (data: Record<string, TokenConfigType>): string[] => {
    const demiourgosTokens: string[] = Object.values(data)
        .filter((token) => token.isDemiourgosToken)
        .filter((token) => token.type === 'META-ESDT')
        .map((token) => token.identifier);

    return demiourgosTokens;
};

export const TOKEN_INFO_MAP: Record<string, TransformedTokenConfigType> = transformTokensData(config.tokens);
export const WALLET_TOKEN_IDS: string[] = extractValidTokens(config.tokens);
export const VESTING_TOKEN_IDS = extractVestingTokens(config.tokens);
export const BASE_TOKENS = extractBaseTokens(config.tokens);
// export const DEMI_FT_IDS = extractDemiourgosEsdtTokens(config.tokens);
//export const DEMI_METAESDT_IDS = extractDemiourgosMetaEsdtTokens(config.tokens);
export const DEMI_FT_IDS = [
    VEGLD_TOKEN_ID, //
    RAWVST_TOKEN_ID, // Raw VST
    VST_TOKEN_ID, // VST
    FVST_TOKEN_ID, // Frozen VST
    OURO_TOKEN_ID, // OURO
];

export const DEMI_METAESDT_IDS = [
    SVST_TOKEN_ID, // Sleeping VST
    OLD_AURYN_TOKEN_ID, // AURYN
    OLD_EAURYN_TOKEN_ID, // Elite AURYN
    VOURO_TOKEN_ID, // Vested OURO
    VAURYN_TOKEN_ID, // Vested AURYN
    VEAURYN_TOKEN_ID, // Vested Elite AURYN
];


