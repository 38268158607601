export enum SlipStateEnum {
    Active = 'Active',
    Inactive = 'Inactive',
    Removed = 'Removed',
}

export enum SlipPoolTypeEnum {
    Bronze = 'Bronze',
    Silver = 'Silver',
    Gold = 'Gold',
}

export interface SlipPoolType {
    tokenIdentifier: string;
    frozenLpTokenIdentifier: string;
    vegldSwapPoolAddress: string;
    ouroSwapPoolAddress: string;
    status: SlipStateEnum;
    pool_type: SlipPoolTypeEnum;
}

export interface SlipContextType {
    vesta_redirect_lp_address: string;
    vesta_vault_lp_address: string;
    usdc_slip_token_id: string;
    usdd_slip_token_id: string;
    ouro_token_id: string;
    usdc_token_id: string;
    usdd_token_id: string;
}
