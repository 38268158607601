import React, { FC } from 'react';
import { useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { IoClose } from 'react-icons/io5';
import Modal from 'react-modal';
import { getTokenLogo } from 'z/elrond';
import { useVestaCommonHooks } from 'z/hooks';
import { createTokenTicker, TOKEN_SEARCH_INPUT_TEXT } from 'z/utils';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

interface PropsType {
    tokenId: string;
    onChangeTokenId: (e: string) => void;
    oppositeTokenId: string;
    disabled?: boolean;
    swapAvailabletokenIds?: string[];
    disableSearch?: boolean;
}

export const SwapTokenSelect: FC<PropsType> = ({
    tokenId,
    onChangeTokenId,
    oppositeTokenId,
    disabled = false,
    swapAvailabletokenIds,
    disableSearch = false,
}) => {
    const { getSwapAvailableTokenIds } = useVestaCommonHooks();

    //
    swapAvailabletokenIds = swapAvailabletokenIds ?? getSwapAvailableTokenIds();

    const [search, setSearch] = useState<string>('');
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function closeModal() {
        setIsOpen(false);
    }

    const handleSelectToken = (tokenid: string) => {
        onChangeTokenId(tokenid);
        setSearch('');
        closeModal();
    };

    const handleOpenModal = () => {
        setIsOpen(true);
    };

    return (
        <>
            <div>
                {tokenId ? (
                    <button className="selected-token-but" onClick={handleOpenModal} disabled={disabled}>
                        <div className="d-flex align-items-center gap-2">
                            <img src={getTokenLogo(tokenId)} alt="logo" width="30px" />
                            {createTokenTicker(tokenId)}
                        </div>
                        <BiChevronDown />
                    </button>
                ) : (
                    <button className="select-token-but" onClick={handleOpenModal} disabled={disabled}>
                        Select Token
                        <BiChevronDown />
                    </button>
                )}
            </div>

            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} ariaHideApp={false}>
                <div className="select-token-modal">
                    <div
                        style={{
                            padding: '0px 15px',
                            borderBottom: '1px solid rgba(255,255,255,0.06)',
                        }}
                    >
                        <div className="d-flex justify-content-between align-items-center">
                            <span
                                style={{
                                    color: '#F1DC46',
                                    marginLeft: '10px',
                                    fontSize: '1.25rem',
                                }}
                            >
                                Select a token
                            </span>

                            <div className="modal-close-but" onClick={closeModal}>
                                <IoClose />
                            </div>
                        </div>
                        {!disableSearch && (
                            <input
                                className="search-token mt-3"
                                placeholder={TOKEN_SEARCH_INPUT_TEXT}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        )}

                        <div className="mt-3 d-flex flex-wrap gap-2 mb-4">
                            {swapAvailabletokenIds &&
                                swapAvailabletokenIds
                                    .filter(
                                        (ti) =>
                                            ti != oppositeTokenId &&
                                            (createTokenTicker(ti) == 'VEGLD' || createTokenTicker(ti) == 'USDC'),
                                    )
                                    .map((ti, index) => {
                                        return (
                                            <div
                                                className="token-tag"
                                                key={`token-tag${index}`}
                                                onClick={() => handleSelectToken(ti)}
                                            >
                                                <img src={getTokenLogo(ti)} alt="logo" width="30px" />
                                                <span style={{ color: '#98A1C0', fontSize: '1.2rem' }}>
                                                    {createTokenTicker(ti)}
                                                </span>
                                            </div>
                                        );
                                    })}
                        </div>
                    </div>

                    <div style={{ padding: '0px 15px' }}>
                        <div className="mt-3 scrollbar token-list-box">
                            {swapAvailabletokenIds
                                .filter((ti) => {
                                    return (
                                        ti.toLowerCase().includes(search.toLocaleLowerCase()) && ti != oppositeTokenId
                                    );
                                })
                                .map((ti, index) => {
                                    return (
                                        <div className="token-li" key={index} onClick={() => handleSelectToken(ti)}>
                                            <img src={getTokenLogo(ti)} alt="logo" width="40px" />

                                            <div className="d-flex flex-column">
                                                <span style={{ color: 'white', fontSize: '1.2rem' }}>
                                                    {createTokenTicker(ti)}
                                                </span>
                                                <span style={{ color: '#98A1C0' }}>{ti}</span>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};
