import React, { FC } from 'react';
import { Row, Col } from 'react-bootstrap';
import { BiRightArrowAlt } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { TooltipLink } from 'components/Elements';
import { StakingPoolType } from 'z/types';

interface PropsType {
    data: StakingPoolType;
    url: string;
}

const StakingRowInfo: FC<PropsType> = ({ data, url }) => {
    const navigate = useNavigate();

    return (
        <>
            <div className="active-pool-li-container">
                <Row className="align-items-center" style={{ rowGap: '12px' }}>
                    <Col md={6}>
                        <div className="nft-staking-row-info-first-section ">
                            <img src={data.avatar} alt="nft avatar" width={'100px'} style={{ borderRadius: '10px' }} />

                            <div className="d-flex align-items-center justify-content-between w-100">
                                <div className="ms-4">
                                    <div style={{ fontSize: '18px' }}>{data.name}</div>
                                    <div className="mt-1" style={{ color: '#98A1C0A0' }}>
                                        {data.description ? data.description : ''}
                                    </div>
                                </div>
                                {data.docsUrl && <TooltipLink link={data.docsUrl} tooltip={data.docsUrl} />}
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="d-flex align-items-center justify-content-between">
                            <div>
                                {data.traitlist.map((row, id) => (
                                    <div key={id}>{row}</div>
                                ))}
                            </div>
                            <div className="active-pool-info">
                                <button
                                    className="pool-collapse-button"
                                    onClick={() => {
                                        navigate(url);
                                    }}
                                >
                                    <BiRightArrowAlt />
                                </button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default StakingRowInfo;
