import React, { useEffect, useState } from 'react';
import { useGetAccount, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';
import { Col, Row } from 'react-bootstrap';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

import { USDC_TOKEN_ID, TOKEN_INFO_MAP, VEGLD_TOKEN_ID } from 'config';
import { selectNetstats, selectSwap } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { routeNames } from 'routes';
import { memeLiquidityVaultViewUserContext } from 'z/elrond';
import { useVestaCommonHooks } from 'z/hooks';
import { LpListLiquidityTokenSelectorDisplay, MemeLiquidityVaultUserContextType } from 'z/types';
import {
    nativeMemeLiquidityTokenSelectorForVault,
    securedMemeLiquidityTokenSelectorForVault,
    nativeMemeLiquidityTokenSelectorForWallet,
    securedMemeLiquidityTokenSelectorForWallet,
    getLiquidityVaultLpDisplayList,
    getLiquidityWalletLpDisplayList,
    formatNumber,
    convertWeiToEsdt,
    BIG_NUMBER_ZERO,
} from 'z/utils';
import { LiquidityHoldingLpList } from './Components/LiquidityHoldingLpList';

export const MemeLiquidityLPView = () => {
    const navigate = useNavigate();
    const { address } = useGetAccount();
    const { hasPendingTransactions } = useGetPendingTransactions();
    const { getTokenPrice } = useVestaCommonHooks();

    const netstatsRedux = useAppSelector(selectNetstats);
    const swapRedux = useAppSelector(selectSwap);

    const [vaultUserContext, setVaultUserContext] = useState<MemeLiquidityVaultUserContextType>();
    const [vaultLpDisplayList, setVaultLpDisplayList] = useState<LpListLiquidityTokenSelectorDisplay[]>([]);
    const [walletLpDisplayList, setWalletLpDisplayList] = useState<LpListLiquidityTokenSelectorDisplay[]>([]);

    useEffect(() => {
        if (!address || hasPendingTransactions) return;

        memeLiquidityVaultViewUserContext(address).then((_vaultUserContext) => {
            setVaultUserContext(_vaultUserContext);
        });
    }, [address, hasPendingTransactions]);

    useEffect(() => {
        setVaultLpDisplayList(
            getLiquidityVaultLpDisplayList(
                [...nativeMemeLiquidityTokenSelectorForVault, ...securedMemeLiquidityTokenSelectorForVault],
                vaultUserContext,
                swapRedux.pools,
            ),
        );
    }, [swapRedux, vaultUserContext]);

    useEffect(() => {
        setWalletLpDisplayList(
            getLiquidityWalletLpDisplayList(
                [...nativeMemeLiquidityTokenSelectorForWallet, ...securedMemeLiquidityTokenSelectorForWallet],
                netstatsRedux,
                swapRedux.pools,
            ),
        );
    }, [swapRedux, netstatsRedux]);

    const getUsdcSum = (lpList: LpListLiquidityTokenSelectorDisplay[]) =>
        `${formatNumber(
            convertWeiToEsdt(
                lpList.reduce(
                    (res, lp) =>
                        BigNumber.sum(
                            lp.first_token_id === USDC_TOKEN_ID
                                ? lp.first_amount
                                : lp.first_amount
                                      .multipliedBy(getTokenPrice(lp.first_token_id))
                                      .shiftedBy(
                                          -TOKEN_INFO_MAP[VEGLD_TOKEN_ID].decimals +
                                              TOKEN_INFO_MAP[USDC_TOKEN_ID].decimals,
                                      ),
                            res,
                        ),
                    BIG_NUMBER_ZERO,
                ),
                TOKEN_INFO_MAP[USDC_TOKEN_ID].decimals,
                TOKEN_INFO_MAP[USDC_TOKEN_ID].decimals,
            ),
            TOKEN_INFO_MAP[USDC_TOKEN_ID].decimals,
        )} USDC in LPs`;

    return (
        <div className="container" style={{ marginTop: '50px' }}>
            <div className="vesta-first-container">
                <div className="d-flex justify-content-start">
                    <button
                        className="go-back-button"
                        onClick={() => {
                            navigate(`${routeNames.memeLiquidityVestaVault}`);
                        }}
                    >
                        <BiLeftArrowAlt />
                    </button>
                </div>

                <div className="d-flex justify-content-center align-items-center mb-4">
                    <span style={{ color: '#F1DC46', fontSize: '1.2rem' }}>Liquidity</span>
                </div>

                <Row>
                    <Col md={12} lg={6}>
                        <div className="second-card-style">
                            <div className="token-stats-title mb-3">Vault Holdings</div>
                            <div className="text-center" style={{ fontSize: '1rem', marginBottom: '1rem' }}>
                                {getUsdcSum(vaultLpDisplayList)}
                            </div>
                            <LiquidityHoldingLpList lpList={vaultLpDisplayList} revert={false} />
                        </div>
                    </Col>
                    <Col md={12} lg={6}>
                        <div className="second-card-style px-3">
                            <div className="token-stats-title mb-3">Wallet Holdings</div>
                            <div className="text-center" style={{ fontSize: '1rem', marginBottom: '1rem' }}>
                                {getUsdcSum(walletLpDisplayList)}
                            </div>
                            <LiquidityHoldingLpList lpList={walletLpDisplayList} revert={true} />
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
