import React, { FC } from 'react';
import { Info } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

interface IProps {
    link: string;
    tooltip: string;
    type?: number;
}

export const TooltipLink: FC<IProps> = ({ link, tooltip, type = 0 }) => {
    if (type == 1) {
        return (
            <a href={link} target="_blank" rel="noreferrer">
                <Tooltip
                    arrow
                    title={tooltip}
                    style={{
                        position: 'absolute',
                        top: '.2rem',
                        right: '.2rem',
                        fontSize: '18px',
                        color: '#98a1c0',
                    }}
                >
                    <Info />
                </Tooltip>
            </a>
        );
    }
    return (
        <a href={link} target="_blank" rel="noreferrer">
            <Tooltip
                arrow
                title={tooltip}
                style={{
                    fontSize: '18px',
                    color: '#98a1c0',
                }}
            >
                <Info />
            </Tooltip>
        </a>
    );
};
