import React from 'react';
import { useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { routeNames } from 'routes';
import { Stableswap } from './Stableswap';
import { SwapBox } from './Swap';

const tabs = ['Normal', 'Stable'];

export const Swap = ({
    selectedTab,
}: {
    selectedTab: number,
}) => {
    const { hasPendingTransactions } = useGetPendingTransactions();
    const navigate = useNavigate();

    function gotoTab(index: number) {
        if (hasPendingTransactions) return;
        if (index === 0) navigate(routeNames.swap);
        if (index === 1) navigate(routeNames.stableSwap);
    }

    return (
        <>
            <div className="container" style={{ marginTop: '50px' }}>
                <div className="d-flex justify-content-center">
                    <div className="tab-box">
                        <div
                            className={clsx(
                                'tab-focus',
                                selectedTab === 0
                                    ? 'tab-focus-left-0'
                                    : 'tab-focus-left-50',
                            )}
                        />
                        {tabs.map((row, index) => {
                            return (
                                <div
                                    className="tab"
                                    key={`p-r-t2-${index}`}
                                    onClick={() => gotoTab(index)}
                                >
                                    {row}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="d-flex justify-content-center mt-4">
                    {
                        selectedTab === 0 && <SwapBox />
                    }
                    {
                        selectedTab === 1 && <Stableswap />
                    }
                </div>
            </div>
        </>
    );
};
