import {
    Address,
    AddressValue,
    ArgSerializer,
    BigUIntValue,
    SmartContract,
    StringValue,
    TokenIdentifierValue,
    TokenPayment,
    Transaction,
    TypedValue,
    U32Value,
    U64Value,
} from '@multiversx/sdk-core/out';
import BigNumber from 'bignumber.js';
import {
    ADD_LIQUIDITY_GAS_LIMIT,
    CREATE_PAIR_GAS_LIMIT,
    ISSUE_LP_TOKEN_GAS_LIMIT,
    REMOVE_LIQUIDITY_GAS_LIMIT,
    SET_PAIR_STATE_TOKEN_GAS_LIMIT,
    SWAP_ROUTER_SC_ADDRESS,
    TOKEN_ISSUE_COST,
} from 'config';
import { StateEnum, SwapPoolType } from 'z/types';
import { convertEsdtToWei } from 'z/utils';
import { elrondDappSendTransactions, mvxSendTransaction } from './common';
import {
    swapPairSmartContract,
    swapRouterSmartContract,
} from './provider';
import { FeeReceiver } from './stableswap';

export async function elrondAddLiquidity(
    poolAddress: string,
    firstTokenId: string,
    secondTokenid: string,
    firstTokenAmount: BigNumber.Value,
    secondTokenAmount: BigNumber.Value,
    firstTokenAmountMin: BigNumber.Value,
    secondTokenAmountMin: BigNumber.Value,
    address: string,
    chainID: string,
) {
    const tokenPayments: TokenPayment[] = [
        TokenPayment.fungibleFromBigInteger(
            firstTokenId,
            firstTokenAmount
        ),
        TokenPayment.fungibleFromBigInteger(
            secondTokenid,
            secondTokenAmount
        ),
    ];
    const args: TypedValue[] = [
        new BigUIntValue(firstTokenAmountMin),
        new BigUIntValue(secondTokenAmountMin),
    ];

    swapPairSmartContract.setAddress(new Address(poolAddress)); // set SC address
    const txx: Transaction = swapPairSmartContract.methodsExplicit
        .addLiquidity(args)
        .withMultiESDTNFTTransfer(tokenPayments, new Address(address))
        .withGasLimit(ADD_LIQUIDITY_GAS_LIMIT)
        .withChainID(chainID)
        .withQuerent(new Address(address))
        .buildTransaction();
    const tx = {
        value: 0,
        data: txx.getData().toString(),
        receiver: address,
        gasLimit: ADD_LIQUIDITY_GAS_LIMIT
    };

    const txName = 'Add Liquidity';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);
    
    return { sessionId, error };
}

export async function elrondRemoveLiquidity(
    poolAddress: string,
    lpTokenId: string,
    lpTokenAmount: string,
    firstTokenAmountMin: string,
    secondTokenAmountMin: string,
    chainID: string,
) {
    const tokenPayments: TokenPayment = TokenPayment.fungibleFromBigInteger(
        lpTokenId,
        lpTokenAmount
    );
    const args: TypedValue[] = [
        new BigUIntValue(firstTokenAmountMin),
        new BigUIntValue(secondTokenAmountMin),
    ];

    swapPairSmartContract.setAddress(new Address(poolAddress)); // set SC address
    const txx: Transaction = swapPairSmartContract.methodsExplicit
        .removeLiquidity(args)
        .withSingleESDTTransfer(tokenPayments)
        .withGasLimit(REMOVE_LIQUIDITY_GAS_LIMIT)
        .withChainID(chainID)
        .buildTransaction();
    const tx = {
        value: 0,
        data: txx.getData().toString(),
        receiver: poolAddress,
        gasLimit: REMOVE_LIQUIDITY_GAS_LIMIT
    };

    const txName = 'Remove Liquidity';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);
    
    return { sessionId, error };
}

export async function elrondAddInitialLiquidity(
    selectedPool: SwapPoolType,
    firstTokenAmount: BigNumber.Value,
    secondTokenAmount: BigNumber.Value,
    address: string,
    chainID: string,
) {
    const tokenPayments: TokenPayment[] = [
        TokenPayment.fungibleFromBigInteger(
            selectedPool.first_token_id,
            convertEsdtToWei(firstTokenAmount, selectedPool.first_token_decimals),
        ),
        TokenPayment.fungibleFromBigInteger(
            selectedPool.second_token_id,
            convertEsdtToWei(secondTokenAmount, selectedPool.second_token_decimals),
        ),
    ];

    swapPairSmartContract.setAddress(new Address(selectedPool.pool_address)); // set SC address
    const txx: Transaction = swapPairSmartContract.methodsExplicit
        .addInitialLiquidity()
        .withMultiESDTNFTTransfer(tokenPayments, new Address(address))
        .withGasLimit(ADD_LIQUIDITY_GAS_LIMIT)
        .withChainID(chainID)
        .buildTransaction();
    const tx = {
        value: 0,
        data: txx.getData().toString(),
        receiver: address,
        gasLimit: ADD_LIQUIDITY_GAS_LIMIT
    };
    
    const txName = 'Add Initial Liquidity';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);
    
    return { sessionId, error };
}

export async function elrondCreatePair(
    firstTokenId: string,
    secondTokenId: string,
    chainID: string,
) {
    const args: TypedValue[] = [
        new TokenIdentifierValue(firstTokenId),
        new TokenIdentifierValue(secondTokenId),
    ];

    const txx: Transaction = swapRouterSmartContract.methodsExplicit
        .createPair(args)
        .withGasLimit(CREATE_PAIR_GAS_LIMIT)
        .withChainID(chainID)
        .buildTransaction();
    const tx = {
        value: 0,
        data: txx.getData().toString(),
        receiver: SWAP_ROUTER_SC_ADDRESS,
        gasLimit: CREATE_PAIR_GAS_LIMIT
    };
    
    const txName = 'Create Pair';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);
    
    return { sessionId, error };
}

export async function elrondPairInitConfiguration(
    firstTokenId: string,
    secondTokenId: string,
    totalFeePercentage: number,
    specialFeePercentage: number,
    feeTokenId: string,
    initialLiquidityAddress: string,
    admins: string[],
) {
    const args: TypedValue[] = [
        new TokenIdentifierValue(firstTokenId),
        new TokenIdentifierValue(secondTokenId),
        new U64Value(totalFeePercentage),
        new U64Value(specialFeePercentage),
        new TokenIdentifierValue(feeTokenId),
        new AddressValue(new Address(initialLiquidityAddress)),
    ];
    admins.forEach((admin: string) => args.push(new AddressValue(new Address(admin))));

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `pairInitConfiguration@${argumentsString}`;
    const tx = {
        value: 0,
        data,
        receiver: SWAP_ROUTER_SC_ADDRESS,
        gasLimit: CREATE_PAIR_GAS_LIMIT
    };
    
    const txName = 'Init Configuration';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);
    
    return { sessionId, error };
}

export async function elrondIssueLpToken(
    pairAddress: string,
    lpTokenDisplayName: string,
    lpTokenTicker: string,
    lpTokenDecimals: number,
    chainID: string,
) {
    const args: TypedValue[] = [
        new AddressValue(new Address(pairAddress)),
        new StringValue(lpTokenDisplayName),
        new StringValue(lpTokenTicker),
        new U32Value(lpTokenDecimals),
    ];

    const txx: Transaction = swapRouterSmartContract.methodsExplicit
        .issueLpToken(args)
        .withValue(TokenPayment.egldFromAmount(TOKEN_ISSUE_COST))
        .withGasLimit(ISSUE_LP_TOKEN_GAS_LIMIT)
        .withChainID(chainID)
        .buildTransaction();
    const tx = {
        value: TokenPayment.egldFromAmount(TOKEN_ISSUE_COST),
        data: txx.getData().toString(),
        receiver: SWAP_ROUTER_SC_ADDRESS,
        gasLimit: ISSUE_LP_TOKEN_GAS_LIMIT
    };
    
    const txName = 'Issue LP Token';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);
    
    return { sessionId, error };
}

export async function elrondSetLocalRoles(
    pairAddress: string,
    chainID: string,
) {
    const args: TypedValue[] = [
        new AddressValue(new Address(pairAddress)),
    ];

    const txx: Transaction = swapRouterSmartContract.methodsExplicit
        .setLocalRoles(args)
        .withGasLimit(ISSUE_LP_TOKEN_GAS_LIMIT)
        .withChainID(chainID)
        .buildTransaction();
    const tx = {
        value: 0,
        data: txx.getData().toString(),
        receiver: SWAP_ROUTER_SC_ADDRESS,
        gasLimit: ISSUE_LP_TOKEN_GAS_LIMIT
    };
    
    const txName = 'Set Local Roles';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);
    
    return { sessionId, error };
}

export async function elrondSetPoolState(
    pairAddress: string,
    state: StateEnum,
) {
    const args: TypedValue[] = [
        new AddressValue(new Address(pairAddress)),
    ];

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = (state == StateEnum.Active ? 'setPairActive' : state == StateEnum.ActiveNoSwaps ? 'setPairActiveNoSwaps' : 'setPairInactive') + '@' + argumentsString;

    const tx = {
        value: 0,
        data,
        receiver: SWAP_ROUTER_SC_ADDRESS,
        gasLimit: SET_PAIR_STATE_TOKEN_GAS_LIMIT,
    };
    
    const txName = 'Set Pool State';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);
    
    return { sessionId, error };
}

export class DexRouterContract {
    sender: string;
    smartContract: SmartContract;

    constructor(sender: string) {
        this.smartContract = swapRouterSmartContract;
        this.sender = sender;
    }

    async pairSetFeeReceivers(
        pairAddress: string,
        feeReceivers: FeeReceiver[],
    ) {
        const interaction = this.smartContract.methods.pairSetFeeReceivers([pairAddress, feeReceivers]);
        await mvxSendTransaction({
            interaction,
            gasLimit: 20_000_000,
            txName: 'Set FeeReceivers',
            sender: this.sender,
        });
    }
}
