import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Col, Row } from 'react-bootstrap';

import rVestaXLogo from 'assets/img/07_RawVesta_200x200.svg';
import vestaXLogo from 'assets/img/08_Vesta_200x200.svg';
import sVestaXLogo from 'assets/img/09_Sleeping-Vesta_200x200.svg';
import fVestaXLogo from 'assets/img/10_Frozen-Vesta_200x200.svg';
import cVestaXLogo from 'assets/img/11_Condenssed Vesta_200x200.svg';
import bVestaXLogo from 'assets/img/12_Blessed-Vesta_200x200.svg';

import { InfoTooltip } from 'components';
import { CustomSlider } from 'components/CustomSlider';
import {
    SVST_MINT_LIMITS,
    getSlipingVestaMultiplier,
    //   getSlipingVestaMultiplier,
} from 'config';
import { selectFarm } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { eatGetFvstMultiplier } from 'z/elrond';
import { VegldVaultStatsContextType, VegldVaultUserContextType } from 'z/types';
import { formatNumber, convertWeiToEsdt, DEFAULT_DECIMALS, parseBigNumber } from 'z/utils';

import 'react-alice-carousel/lib/alice-carousel.css';

const vestaRewardsSliderMarks = [
    { value: 0, label: '0' },
    { value: 25, label: '25%' },
    { value: 50, label: '50%' },
    { value: 75, label: '75%' },
    { value: 100, label: '100%' },
];

const sVestaSliderMarks = [
    { value: 0, label: '' },
    { value: 4, label: 'T3' },
    { value: 8, label: 'T4' },
    { value: 12, label: 'T5' },
    { value: 16, label: 'T6' },
    { value: 20, label: 'T7' },
];

interface VestaMinterParams {
    mintOption: number;
    setMintOption: Dispatch<SetStateAction<number>>;
    mintPercent: number;
    setMintPercent: Dispatch<SetStateAction<number>>;
    vestaSleepingYears: number;
    setVestaSleepingYears: Dispatch<SetStateAction<number>>;
    vaultStatsContext: VegldVaultStatsContextType | undefined;
    vaultUserContext: VegldVaultUserContextType | undefined;
    totalValueLockeded: string;
    onMintVesta(): Promise<void>;
    disabled?: boolean;
    powerName?: string;
}

export const VegldVaultVestaMinter = ({
    mintOption,
    setMintOption,
    mintPercent,
    setMintPercent,
    vestaSleepingYears,
    setVestaSleepingYears,
    vaultStatsContext,
    vaultUserContext,
    totalValueLockeded,
    onMintVesta,
    disabled,
    powerName = 'Minor vEGLD',
}: VestaMinterParams) => {
    const farmRedux = useAppSelector(selectFarm);

    const [claimOption, setClaimOption] = useState<number>(0); // 0 for RAWVST, 1 for CNDVST

    const [fvstMultiplier, setFvstMultiplier] = useState<number>(0);
    const [vestaTotalMultiplier, setVestaTotalMultiplier] = useState<number>(0);
    const [vm, setVm] = useState<number>(1);

    useEffect(() => {
        if (!vaultUserContext) {
            return;
        }

        setVestaTotalMultiplier(
            vaultUserContext.deb * vaultUserContext.im * vaultUserContext.tm * vaultUserContext.dm * vm,
        );
    }, [vaultUserContext, vm]);

    useEffect(() => {
        (async () => {
            if (farmRedux.eliteAccountTier > 3) {
                const _fvstMultiplier = await eatGetFvstMultiplier(farmRedux.eliteAccountTier);
                setFvstMultiplier(_fvstMultiplier);
            }
        })();
    }, [farmRedux]);

    useEffect(() => {
        let _vm = 1;
        if (mintOption === 1) {
            _vm = getSlipingVestaMultiplier(vestaSleepingYears);
        } else if (mintOption === 2) {
            _vm = fvstMultiplier;
        }

        setVm(_vm);
    }, [mintOption, vestaSleepingYears, fvstMultiplier]);

    const onChangeVestaSleepingYearsSlider = (v: number) => {
        setVestaSleepingYears(v);
    };

    function onSVestaSelected() {
        setMintOption(1);

        setVestaSleepingYears(SVST_MINT_LIMITS[farmRedux.eliteAccountTier]);

        // if (12 <= fvstMultiplier) {
        //   setVestaSleepingYears(12);
        // }
    }

    const onVestaSelected = () => {
        setMintOption(0);
        setVestaSleepingYears(0);
    };

    const onFVestaSelected = () => {
        setMintOption(2);
        setVestaSleepingYears(0);
    };

    function onRVestaSelected() {
        setClaimOption(0);
        setMintOption(0);
    }

    function onCVestaSelected() {
        setClaimOption(1);
        setMintOption(3);
    }

    function onYearSliderSelected() {
        setMintOption(1);
        setClaimOption(0);
    }

    function getHighlightedMultiplier(multipler: number = 1) {
        const strings = Number(multipler.toFixed(4)).toString().split('.');
        return (
            <div>
                <span style={{ fontSize: '1.5rem' }}>{strings[0]}</span>
                <span style={{ fontSize: '1rem' }}>{strings[1] ? `.${strings[1]}` : ''}</span>
                <span style={{ fontSize: '1.5rem' }}>x</span>
            </div>
        );
    }

    function getStyledNumber(strNum: string) {
        const strings = strNum.split('.');

        return (
            <div>
                <span style={{ fontSize: '1.8rem' }}>{strings[0]}</span>
                <span style={{ fontSize: '1.3rem' }}>{strings[1] ? `.${strings[1]}` : ''}</span>
            </div>
        );
    }

    return (
        <Row className="mt-4 p-0">
            <Col>
                <Row
                    className="m-0 py-3 px-1 gy-2 gx-2"
                    style={{
                        border: '1px solid #a349a2',
                        borderRadius: '10px',
                    }}
                >
                    <div className="col-12 text-center mb-3" style={{ color: '#a349a2' }}>
                        {`Vesta Rewards are governed by ${powerName} Power. Your share of all ${powerName} Power is ${
                            vaultStatsContext &&
                            vaultUserContext &&
                            parseBigNumber(vaultStatsContext.vegld_reserve).isGreaterThan(0)
                                ? formatNumber(
                                      parseBigNumber(vaultUserContext.vegld_staked_amount)
                                          .div(vaultStatsContext.vegld_reserve)
                                          .multipliedBy(1000),
                                      3,
                                  )
                                : '0.000'
                        }‰.`}
                    </div>

                    <Col xl={3} lg={4} md={12} style={{ flex: '1 1' }}>
                        <div className="farm_card_header position-relative h-50">
                            <div>Primal Vesta Earnings</div>

                            <div
                                className="d-flex align-items-center justify-content-between mt-4"
                                style={{ fontSize: '2.5rem' }}
                            >
                                <button
                                    className={clsx({
                                        'farm_card_individual_element_but d-flex flex-column justify-content-center align-items-center':
                                            true,
                                        selected: claimOption === 0,
                                    })}
                                    onClick={onRVestaSelected}
                                >
                                    <img src={rVestaXLogo} width={'40px'} />
                                </button>

                                <div className="d-flex align-items-center" style={{ fontSize: '1.8rem' }}>
                                    {getStyledNumber(
                                        formatNumber(
                                            convertWeiToEsdt(
                                                vaultUserContext && !disabled ? vaultUserContext.reward_amount : 0,
                                                DEFAULT_DECIMALS,
                                                DEFAULT_DECIMALS,
                                            ),
                                        ),
                                    )}
                                    &nbsp;
                                    <span style={{ fontSize: '1.5rem' }}>RAW</span>
                                </div>
                            </div>

                            <div
                                className="d-flex justify-content-between align-items-center mt-3"
                                style={{ fontSize: '2.5rem' }}
                            >
                                <button
                                    className={clsx({
                                        'farm_card_individual_element_but d-flex flex-column justify-content-center align-items-center':
                                            true,
                                        selected: claimOption === 1,
                                    })}
                                    onClick={onCVestaSelected}
                                >
                                    <img src={cVestaXLogo} width={'40px'} />
                                </button>

                                <div className="d-flex align-items-center" style={{ fontSize: '1.8rem' }}>
                                    {`0`}
                                    &nbsp;
                                    <span style={{ fontSize: '1.5rem' }}>CND</span>
                                </div>
                            </div>

                            <InfoTooltip title="The Primal Vesta Earnings are the Raw-Vesta and Condensed Vesta Amounts that have been earned so far. These amounts must be converted to one of the 4 Refined Vesta Variants in order to capitalize on the Farming and Staking Rewards." />
                        </div>

                        <div
                            className="farm_card_body mt-1 position-relative h-37"
                            style={{ height: '35%', borderRadius: '0px' }}
                        >
                            <div>To Claim:</div>

                            <div
                                className="d-flex align-items-center justify-content-center"
                                style={{ fontSize: '1.5rem' }}
                            >
                                {mintPercent}%
                            </div>

                            <div
                                className="d-flex align-items-center justify-content-center"
                                style={{ fontSize: '2.5rem' }}
                            >
                                {claimOption == 0
                                    ? formatNumber(
                                          convertWeiToEsdt(
                                              vaultUserContext ? vaultUserContext.reward_amount : 0,
                                              DEFAULT_DECIMALS,
                                              DEFAULT_DECIMALS,
                                          )
                                              .multipliedBy(mintPercent)
                                              .div(100),
                                      )
                                    : 0}
                                &nbsp;
                                <img src={claimOption == 0 ? rVestaXLogo : cVestaXLogo} width={'40px'} />
                            </div>

                            <CustomSlider
                                value={mintPercent}
                                marks={vestaRewardsSliderMarks}
                                onChange={setMintPercent}
                                disabled={disabled}
                            />

                            <InfoTooltip title="The Amount of Primal Vesta Variant (Raw or Condensed) that is primed for minting. Users can select individual percentages from their Primal Vesta Rewards as input for minting one of the 4 Refined Vesta Variants." />
                        </div>
                        <div className="farm_card_body mt-1 position-relative" style={{ height: '14%' }}>
                            <div
                                className="d-flex text-center justify-content-center align-items-center"
                                style={{ fontSize: '1.5rem', color: 'rgb(152, 161, 192)' }}
                            >
                                {`TVL ${totalValueLockeded}`}
                                <InfoTooltip title="Total Value Locked" />
                            </div>
                        </div>
                    </Col>

                    <Col xl={9} lg={8} md={12}>
                        <div className="farm_swap_card_body h-100">
                            <Row className="align-items-center gy-3">
                                <Col className="p-1" sm={12}>
                                    <Row className="g-4">
                                        <Col
                                            className="d-flex justify-content-center"
                                            xs={6}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={2}
                                        >
                                            <button
                                                className={clsx(
                                                    'farm_card_individual_element_but d-flex flex-column justify-content-center align-items-center',
                                                    mintOption === 0 && 'selected',
                                                )}
                                                onClick={onVestaSelected}
                                                disabled={claimOption == 1}
                                            >
                                                <img className="farm_logo_element mb-1" src={vestaXLogo} />
                                                <span>VESTA</span>
                                            </button>
                                        </Col>

                                        <Col
                                            className="d-flex justify-content-center"
                                            xs={6}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={2}
                                        >
                                            <button
                                                className={clsx(
                                                    'farm_card_individual_element_but d-flex flex-column justify-content-center align-items-center',
                                                    mintOption === 3 && 'selected',
                                                )}
                                                onClick={() => setMintOption(3)}
                                                disabled={claimOption == 0}
                                            >
                                                <img className="farm_logo_element mb-1" src={bVestaXLogo} />
                                                <span>bVESTA</span>
                                            </button>
                                        </Col>

                                        <Col
                                            className="d-flex justify-content-center"
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={4}
                                        >
                                            <div className="d-flex flex-column text-center gy-2">
                                                <div style={{ fontSize: '1.3rem', color: '#ffe72b' }}>
                                                    {'??? Liquidity Owner'}
                                                </div>
                                                <div>
                                                    VAULT™ Dominance Point:{' '}
                                                    <span style={{ color: '#ef4545' }}>???</span> ‰
                                                </div>
                                                <div>
                                                    Account Type: ??? (<span style={{ color: '#ef4545' }}>???</span> x)
                                                </div>
                                                <div>
                                                    Elite Tier Modifier (<span style={{ color: '#ef4545' }}>???</span>{' '}
                                                    x)
                                                </div>
                                                <div>
                                                    Account Dominance Point:{' '}
                                                    <span style={{ color: '#ef4545' }}>???</span> ‰
                                                </div>
                                                <div>
                                                    Current Account Liquidity:{' '}
                                                    <span style={{ color: '#a349a2' }}>
                                                        {vaultStatsContext &&
                                                        vaultUserContext &&
                                                        parseBigNumber(vaultStatsContext.vegld_reserve).isGreaterThan(0)
                                                            ? formatNumber(
                                                                  parseBigNumber(vaultUserContext.vegld_staked_amount)
                                                                      .div(vaultStatsContext.vegld_reserve)
                                                                      .multipliedBy(1000),
                                                                  3,
                                                              )
                                                            : '0.000'}
                                                    </span>{' '}
                                                    ‰
                                                </div>
                                                <div>
                                                    RAW/CND-VST Split: <span style={{ color: '#ef4545' }}>???</span> % /{' '}
                                                    <span style={{ color: '#ef4545' }}>???</span> %
                                                </div>
                                            </div>
                                        </Col>

                                        <Col
                                            className="d-flex justify-content-center"
                                            xs={6}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={2}
                                        >
                                            <button
                                                className={clsx({
                                                    'farm_card_individual_element_but d-flex flex-column justify-content-center align-items-center':
                                                        true,
                                                    selected: mintOption === 1,
                                                    farm_card_individual_element_but_red_out:
                                                        farmRedux.eliteAccountTier < 4,
                                                })}
                                                onClick={onSVestaSelected}
                                                disabled={claimOption == 1 || farmRedux.eliteAccountTier < 4}
                                            >
                                                <img className="farm_logo_element mb-1" src={sVestaXLogo} />
                                                <span>sVESTA</span>
                                            </button>
                                        </Col>

                                        <Col
                                            className="d-flex justify-content-center"
                                            xs={6}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={2}
                                        >
                                            <button
                                                className={clsx({
                                                    'farm_card_individual_element_but d-flex flex-column justify-content-center align-items-center':
                                                        true,
                                                    selected: mintOption === 2,
                                                    farm_card_individual_element_but_red_out:
                                                        farmRedux.eliteAccountTier < 4,
                                                })}
                                                onClick={onFVestaSelected}
                                                disabled={claimOption == 1 || farmRedux.eliteAccountTier < 4}
                                            >
                                                <img className="farm_logo_element mb-1" src={fVestaXLogo} />
                                                <span>fVESTA</span>
                                            </button>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col className="p-1" sm={12}>
                                    <button
                                        className={clsx(
                                            'farm_card_individual_element_block w-100',
                                            mintOption === 1 && 'selected',
                                        )}
                                        disabled={mintOption !== 1 || farmRedux.eliteAccountTier < 4}
                                        onClick={onYearSliderSelected}
                                    >
                                        <div className="d-flex justify-content-center">
                                            <span>{vestaSleepingYears} years</span>
                                        </div>
                                        <CustomSlider
                                            value={vestaSleepingYears}
                                            marks={sVestaSliderMarks}
                                            onChange={onChangeVestaSleepingYearsSlider}
                                            min={1}
                                            max={20}
                                            step={1}
                                            disabled={mintOption !== 1 || farmRedux.eliteAccountTier < 4}
                                        />
                                        <div className="d-flex justify-content-center">
                                            <span>sVESTA</span>
                                        </div>
                                    </button>
                                </Col>

                                <Col className="p-1" sm={12}>
                                    <div className="farm-multipler-box farm-final-result position-relative">
                                        <div
                                            className="d-flex flex-column align-items-center position-relative farm_card_individual_element_box"
                                            style={{ color: '#ffc90e' }}
                                        >
                                            <span>DEB</span>
                                            {getHighlightedMultiplier(vaultUserContext ? vaultUserContext.deb : 1)}

                                            <InfoTooltip title="Demiourgos Elite Boost, scales with Demiourgos Elite Account Tier" />
                                        </div>

                                        <div
                                            className="d-flex flex-column align-items-center position-relative farm_card_individual_element_box"
                                            style={{ color: '#7030a0' }}
                                        >
                                            <span>VLM</span>
                                            1x
                                            <InfoTooltip title="Vesta Loyalty Multiplier. Increases with the Vesta Loyalty Score (Scales with Demiourgos Elite Account Tier). Vesta Loyalty Score increases each day Vesta SFTs are kept in staking. Unstaking resets the Vesta Loyalty Score to zero, droping the VLM to 1x." />
                                        </div>

                                        <div
                                            className="d-flex flex-column align-items-center position-relative farm_card_individual_element_box"
                                            style={{ color: '#ff9090' }}
                                        >
                                            <span>TM</span>
                                            {getHighlightedMultiplier(vaultUserContext ? vaultUserContext.tm : 1)}

                                            <InfoTooltip title="Tier Multiplier (Red VESTA Multiplier)" />
                                        </div>

                                        <div
                                            className="d-flex flex-column align-items-center position-relative farm_card_individual_element_box"
                                            style={{ color: '#3b3b3b' }}
                                        >
                                            <span>D</span>
                                            1x
                                            <InfoTooltip title="Density Multiplier (Yellow VESTA Multiplier)" />
                                        </div>

                                        <div
                                            className="d-flex flex-column align-items-center position-relative farm_card_individual_element_box"
                                            style={{ color: '#c45911' }}
                                        >
                                            <span>BM</span>
                                            1x
                                            <InfoTooltip title="Bunny Multiplier" />
                                        </div>

                                        <div
                                            className="d-flex flex-column align-items-center position-relative farm_card_individual_element_box"
                                            style={{ color: '#00b050' }}
                                        >
                                            <span>IM</span>
                                            {getHighlightedMultiplier(vaultUserContext ? vaultUserContext.im : 1)}

                                            <InfoTooltip title="Individual Multiplier (Green Vesta Multiplier)" />
                                        </div>

                                        <div
                                            className="d-flex flex-column align-items-center position-relative farm_card_individual_element_box"
                                            style={{ color: '#1cc9f1' }}
                                        >
                                            <span>DM</span>
                                            {getHighlightedMultiplier(vaultUserContext ? vaultUserContext.dm : 1)}

                                            <InfoTooltip title="Diamond Multiplier" />
                                        </div>

                                        <div
                                            className="d-flex flex-column align-items-center position-relative farm_card_individual_element_box"
                                            style={{ color: '#0070c0' }}
                                        >
                                            <span>VM</span>
                                            {getHighlightedMultiplier(vm)}

                                            <InfoTooltip title="Vesta Multiplier (Blue Vesta Multiplier). The Multiplier given by the Vesta Variant chosen to be minted. Minting pure VESTA makes for a neutral multiplier of 1x. Selecting Sleeping Vesta with 1 to 20 years of sleep. Frozen Vesta represents a completely locked down VESTA Variant. It can be created only by Demiourgos Elite Accounts starting with Tier 3. In this case, the Vesta Multiplier is based on the Demiourgos Elite Account Tier." />
                                        </div>

                                        <div>=</div>

                                        <div className="d-flex flex-column align-items-center position-relative farm_card_individual_element_box">
                                            <span>VTM</span>
                                            {getHighlightedMultiplier(vaultUserContext ? vestaTotalMultiplier : 1)}

                                            <InfoTooltip title="The VTM or the Vesta Total Multiplier, determines the amount of Refined Vesta Variant that will be created from the selected amount of Primal Vesta Variant used as input. The higher it gets, the more that specific Variant of Refined Vesta will be minted" />
                                        </div>
                                    </div>
                                </Col>

                                <Col className="p-1" sm={12}>
                                    <div className="d-flex justify-content-between align-items-center gap-2">
                                        <img
                                            src={
                                                mintOption === 0
                                                    ? vestaXLogo
                                                    : mintOption === 1
                                                    ? sVestaXLogo
                                                    : mintOption === 2
                                                    ? fVestaXLogo
                                                    : bVestaXLogo
                                            }
                                            width={'40px'}
                                        />

                                        <div className="d-flex justify-content-between w-100 farm_card_individual_element h-100">
                                            <span>
                                                {mintOption === 0
                                                    ? 'VESTA'
                                                    : mintOption === 1
                                                    ? 'Sleeping VESTA'
                                                    : mintOption === 2
                                                    ? 'Frozen VESTA'
                                                    : 'Blessed VESTA'}
                                            </span>
                                            <span>
                                                {claimOption == 0
                                                    ? formatNumber(
                                                          convertWeiToEsdt(
                                                              vaultUserContext ? vaultUserContext.reward_amount : 0,
                                                              DEFAULT_DECIMALS,
                                                              DEFAULT_DECIMALS,
                                                          )
                                                              .multipliedBy(mintPercent)
                                                              .multipliedBy(vestaTotalMultiplier)
                                                              .div(100),
                                                      )
                                                    : 0}
                                            </span>
                                        </div>

                                        <button
                                            className="farm_but w-20 py-3"
                                            onClick={onMintVesta}
                                            disabled={
                                                // (mintOption != 2 || farmRedux.eliteAccountTier < 4) && // TODO: uncoment to enable minting fVesta
                                                disabled || (mintOption != 1 && mintOption != 0)
                                            }
                                        >
                                            Mint
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};
