import { useEffect, useState } from 'react';
import { TokenTransfer } from '@multiversx/sdk-core/out';
import { useGetAccount, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import { NftType } from '@multiversx/sdk-dapp/types/tokens.types';
import {
    selectNetstats,
    selectNftStakingV2,
    setUserDeb,
    setNftStakingRewards,
    setNftStakingTotalSnapshot,
    setNftStakingUserContextMap,
    setNftStakingUserFeeContext,
    setNftStakingUserSnapshot,
    setTotalStakedNftsSfts,
    setWalletNftsSftsMap,
} from 'redux/reducers';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { nftStakingV2SmartContract } from 'z/elrond';
import { NftStakingUserContextType } from 'z/types';
import { useCommonMvxHooks } from './common-mvx';

interface ReturnData {
    walletNftsSftsMap: Record<string, NftType[]>;
    userContext?: NftStakingUserContextType;
    sendInjectRewardsTransaction(injectProfile: number, transfers: TokenTransfer[]): Promise<void>;
}

export const useNftStakingHooks = (): ReturnData => {
    const { hasPendingTransactions } = useGetPendingTransactions();
    const { address } = useGetAccount();
    const { sendTransaction } = useCommonMvxHooks();
    const dispatch = useAppDispatch();

    const netstatsRedux = useAppSelector(selectNetstats);
    const nftStakingRedux = useAppSelector(selectNftStakingV2);

    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        if (hasPendingTransactions) return;

        if (isInitialized || !nftStakingRedux.totalSnapshot) {
            // TODO: Perhaps add a delay so the api has time to process the data
            setNftStakingTotalSnapshot(dispatch);
        }

        if (isInitialized || !nftStakingRedux.totalStakedNftsSftsMap) {
            setTotalStakedNftsSfts(dispatch);
        }
    }, [hasPendingTransactions]);

    useEffect(() => {
        if (!address || hasPendingTransactions) return;

        if (isInitialized || !netstatsRedux.walletNftsSftsMap) {
            setWalletNftsSftsMap(address, dispatch);
        }

        if (isInitialized || !nftStakingRedux.userContext) {
            setNftStakingUserContextMap(address, dispatch);
        }

        if (isInitialized || !nftStakingRedux.userFeeContext) {
            setNftStakingUserFeeContext(address, dispatch);
        }

        if (isInitialized || !nftStakingRedux.rewards) {
            setNftStakingRewards(address, dispatch);
        }

        if (isInitialized || !netstatsRedux.deb) {
            setUserDeb(address, dispatch);
        }

        if (isInitialized || !nftStakingRedux.userSnapshot) {
            setNftStakingUserSnapshot(address, dispatch);
        }

        setIsInitialized(true);
    }, [address, hasPendingTransactions]);

    const sendInjectRewardsTransaction = async (injectProfile: number, transfers: TokenTransfer[]) => {
        const interaction = nftStakingV2SmartContract.methods
            .injectRewards([injectProfile])
            .withMultiESDTNFTTransfer(transfers)
            .withGasLimit(12_000_000 + 1_500_000 * transfers.length);

        const txName = 'Inject Rewards';
        await sendTransaction(interaction, txName);
    };

    return {
        walletNftsSftsMap: netstatsRedux.walletNftsSftsMap || {},
        userContext: nftStakingRedux.userContext,
        sendInjectRewardsTransaction,
    };
};
