import {
    Address,
    AddressValue,
    ArgSerializer,
    BigUIntValue,
    Field,
    List,
    StringValue,
    Struct,
    TokenIdentifierValue,
    TokenTransfer,
    TypedValue,
    U32Value,
    U64Value,
} from '@multiversx/sdk-core/out';
import { MEME_LIQUIDITY_VESTA_VALULT_SC_ADDRESS } from 'config';
import {
    MemeLiquidityVaultBaseContextType,
    MemeLiquidityVaultStatsContextType,
    MemeLiquidityVaultUserContextType,
} from 'z/types';
import { elrondDappSendTransactions, mvxQuery, parseEsdtTokenPayment } from './common';
import { esdtTokenPaymentType, memeLiquidityVestaVaultSmartContract } from './provider';

export async function memeLiquidityVaultViewBaseContext(): Promise<MemeLiquidityVaultBaseContextType | undefined> {
    try {
        const value = await mvxQuery(memeLiquidityVestaVaultSmartContract.methods.viewBaseContext());
        const decoded = {
            state: value.state,
            lp_token_ids: value.lp_token_ids.map((v: any) => v.toString()),

            raw_vesta_token_id: value.raw_vesta_token_id.toString(),

            sft_token_id: value.sft_token_id.toString(),
            sft_lock_period: value.sft_lock_period.toNumber(),

            auxiliary_standard_reward_token_ids: value.auxiliary_standard_reward_token_ids.map((v: any) =>
                v.toString(),
            ),
            premium_reward_token_ids: value.premium_reward_token_ids.map((v: any) => v.toString()),
            elite_reward_token_ids: value.elite_reward_token_ids.map((v: any) => v.toString()),
        };

        return decoded;
    } catch (err) {
        console.error('getMemeLiquidityVaultViewBaseContext', err);
        return undefined;
    }
}

export async function memeLiquidityVaultViewStatsContext(): Promise<MemeLiquidityVaultStatsContextType | undefined> {
    try {
        const value = await mvxQuery(memeLiquidityVestaVaultSmartContract.methods.viewStatsContext());
        const decoded = {
            lp_token_reserves: value.lp_token_reserves.map(parseEsdtTokenPayment),
            sft_reserves: value.sft_reserves.map((v: any) => v.toFixed(0)),
            reward_rates: value.reward_rates.map((v: any) => ({
                lp_token_id: v.lp_token_id.toString(),
                reward_rate: v.reward_rate.toFixed(0),
            })),

            total_standard_vesta_power: value.total_standard_vesta_power.toFixed(0),
            total_premium_vesta_power: value.total_premium_vesta_power.toFixed(0),
            total_elite_vesta_power: value.total_elite_vesta_power.toFixed(0),
        };

        return decoded;
    } catch (err) {
        console.error('getMemeLiquidityVaultViewStatsContext', err);
        return undefined;
    }
}

export async function memeLiquidityVaultViewUserContext(
    address: string,
): Promise<MemeLiquidityVaultUserContextType | undefined> {
    try {
        const value = await mvxQuery(
            memeLiquidityVestaVaultSmartContract.methods.viewUserContext([new AddressValue(new Address(address))]),
        );
        const decoded = {
            lp_token_staked_amounts: value.lp_token_staked_amounts.map((v: any) => parseEsdtTokenPayment(v)),

            sft_staked_amounts: value.sft_staked_amounts.map((v: any) => v.toFixed(0)),

            standard_vesta_power: value.standard_vesta_power.toFixed(0),
            premium_vesta_power: value.premium_vesta_power.toFixed(0),
            elite_vesta_power: value.elite_vesta_power.toFixed(0),

            reward_amount: value.reward_amount.toFixed(0),
            last_claimed_timestamp: value.last_claimed_timestamp.toNumber(),

            deb: value.deb.toNumber() / 100_000,
            vlm: value.vlm.toNumber() / 1_000_000,
            im: value.im.toNumber() / 1_000_000,
            vm: value.vm.toNumber() / 1_000_000,

            auxiliary_standard_reward_payments: value.auxiliary_standard_reward_payments.map((v: any) =>
                parseEsdtTokenPayment(v),
            ),
            premium_reward_payments: value.premium_reward_payments.map((v: any) => parseEsdtTokenPayment(v)),
            elite_reward_payments: value.elite_reward_payments.map((v: any) => parseEsdtTokenPayment(v)),

            lp_token_fee_procents: value.lp_token_fee_procents.map((v: any) => parseEsdtTokenPayment(v)),
            total_standard_snake_power_fee_amount: value.total_standard_snake_power_fee_amount.toFixed(),
        };

        return decoded;
    } catch (err) {
        console.error('getMemeLiquidityVaultViewUserContext', err);
        return undefined;
    }
}

export async function memeLiquidityVaultStakeToken(payments: TokenTransfer[], sender: string) {
    const args: TypedValue[] = [
        new AddressValue(new Address(MEME_LIQUIDITY_VESTA_VALULT_SC_ADDRESS)),
        new U32Value(payments.length),
    ];
    payments.map((payment) => {
        args.push(new TokenIdentifierValue(payment.tokenIdentifier));
        args.push(new U64Value(payment.nonce));
        args.push(new BigUIntValue(payment.amountAsBigInteger));
    });
    args.push(new StringValue('stakeToken'));

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `MultiESDTNFTTransfer@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: sender,
        gasLimit: 100_000_000 + 10_000_000 * payments.length,
    };

    const txName = 'Stake';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function memeLiquidityVaultUnstakeToken(payments: TokenTransfer[]) {
    const pa: TypedValue[] = [];
    payments.map((payment) =>
        pa.push(
            new Struct(esdtTokenPaymentType, [
                new Field(new TokenIdentifierValue(payment.tokenIdentifier), 'token_identifier'),
                new Field(new U64Value(payment.nonce), 'token_nonce'),
                new Field(new BigUIntValue(payment.amountAsBigInteger), 'amount'),
            ]),
        ),
    );

    const args: TypedValue[] = [List.fromItems(pa)];

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `unstakeToken@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: MEME_LIQUIDITY_VESTA_VALULT_SC_ADDRESS,
        gasLimit: 100_000_000 + 10_000_000 * payments.length,
    };

    const txName = 'Unstake';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function vaultClaimAuxiliaryStandardReward() {
    const data = `claimAuxiliaryStandardRewards`;

    const tx = {
        value: 0,
        data,
        receiver: MEME_LIQUIDITY_VESTA_VALULT_SC_ADDRESS,
        gasLimit: 100_000_000,
    };

    const txName = 'Claim Reward';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

// export async function vaultMergeSvestas(
//   scAddress: string,
//   payments: TokenTransfer[],
// ) {
//   const pa: TypedValue[] = [];
//   payments.map((payment) => pa.push(new Struct(esdtTokenPaymentType, [
//     new Field(new TokenIdentifierValue(payment.tokenIdentifier), "token_identifier"),
//     new Field(new U64Value(payment.nonce), "token_nonce"),
//     new Field(new BigUIntValue(payment.amountAsBigInteger), "amount"),
//   ])));

//   const args: TypedValue[] = [
//     List.fromItems(pa),
//   ];

//   const { argumentsString } = new ArgSerializer().valuesToString(args);
//   const data = `mergeSvestas@${argumentsString}`;

//   const tx = {
//     value: 0,
//     data,
//     receiver: scAddress,
//     gasLimit: 15_000_000 + 5_000_000 * payments.length,
//   };

//   const txName = 'Merge sVST';
//   const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

//   return { sessionId, error };
// }

// export async function vaultClaimEliteReward(scAddress: string) {
//   const data = `claimEliteRewards`;

//   const tx = {
//     value: 0,
//     data,
//     receiver: scAddress,
//     gasLimit: 30_000_000,
//   };

//   const txName = 'Claim Elite Reward';
//   const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

//   return { sessionId, error };
// }

// export async function vaultClaimPremiumReward(scAddress: string) {
//   const data = `claimPremiumRewards`;

//   const tx = {
//     value: 0,
//     data,
//     receiver: scAddress,
//     gasLimit: 30_000_000,
//   };

//   const txName = 'Claim Premium Reward';
//   const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

//   return { sessionId, error };
// }
