import { Decimal128 } from 'mongodb';
import { EsdtTokenPaymentType } from './common';

export interface OuroLiquidityVaultBaseContextType {
    state: string;

    lp_token_ids: string[];
    xexchange_lp_token_ids: string[];
    raw_vesta_token_id: string;
    sft_token_id: string;
    sft_lock_period: number;
    slip_token_id: string;

    premium_reward_token_ids: string[];
    elite_reward_token_ids: string[];
}

export interface OuroLiquidityVaultStatsContextType {
    lp_token_reserves: EsdtTokenPaymentType[];
    sft_reserves: string[];
    reward_rates: { lp_token_id: string; reward_rate: string }[];
    total_slip_multiplier: string;
    total_standard_vesta_power: string;
    total_premium_vesta_power: string;
    total_elite_vesta_power: string;
    total_dex_vesta_power: string;
    total_xexchange_vesta_power: string;
}

export interface OuroLiquidityVaultUserContextType {
    lp_token_staked_amounts: EsdtTokenPaymentType[];

    sft_staked_amounts: string[];

    dex_vesta_power: string;
    xexchange_vesta_power: string;
    standard_vesta_power: string;
    premium_vesta_power: string;
    elite_vesta_power: string;
    slip_multiplier: string;

    reward_amount: string;
    last_claimed_timestamp: number;

    deb: number;
    vlm: number;
    im: number;
    vm: number;

    premium_reward_payments: EsdtTokenPaymentType[];
    elite_reward_payments: EsdtTokenPaymentType[];

    lp_token_fee_procents: EsdtTokenPaymentType[];
    total_standard_snake_power_fee_amount: string;
}

export interface OuroLiquidityVaultUserSnapshotType {
    address: string;
    deb: number;

    lp_token_staked_amounts: {
        token_identifier: string;
        token_nonce: Decimal128;
        amount: Decimal128;
    }[];

    sft_staked_amounts: string[];

    dex_vesta_power: string;
    xexchange_vesta_power: string;
    standard_vesta_power: string;
    premium_vesta_power: string;
    elite_vesta_power: string;
    slip_multiplier: string;

    reward_amount: string;
    last_claimed_timestamp: number;

    vlm: number;
    im: number;
    vm: number;

    staked_xbunnies_nonces?: number[];
}

export interface OuroLiquidityVaultUserElitePowersType {
    majorGoldenPower: string;
    majorSilverPower: string;
    majorBronzePower: string;
    minorGoldenPower: string;
    minorSilverPower: string;
    minorBronzePower: string;
}

export interface OuroLiquidityVaultElitePowersType {
    totalMajorGoldenPower: string;
    totalMajorSilverPower: string;
    totalMajorBronzePower: string;
    totalMinorGoldenPower: string;
    totalMinorSilverPower: string;
    totalMinorBronzePower: string;
}

export enum OuroLiquidityVestaVaultSelectedHolding {
    VaultVesta = 'OuroLiquidityVaultVesta',
    VaultSvesta = 'OuroLiquidityVaultSvesta',
    WalletVesta = 'OuroLiquidityWalletVesta',
    WalletSvesta = 'OuroLiquidityWalletSvesta',

    VaultNativeOuroUSDD = 'OuroLiquidityVaultNativeOuroUSDD',
    VaultNativeOuroUSDC = 'OuroLiquidityVaultNativeOuroUSDC',
    VaultNativeOuroVST = 'OuroLiquidityVaultNativeOuroVST',
    VaultNativeOurobVST = 'OuroLiquidityVaultNativeOurobVST',
    VaultNativeOurosVST = 'OuroLiquidityVaultNativeOurosVST',
    VaultNativeOurofVST = 'OuroLiquidityVaultNativeOurofVST',
    VaultNativeOuroEstar = 'OuroLiquidityVaultNativeOuroEstar',
    VaultNativeOuroKoson = 'OuroLiquidityVaultNativeOuroKoson',
    VaultNativeOuroXLH = 'OuroLiquidityVaultNativeOuroXLH',
    VaultNativeOuroGSC = 'OuroLiquidityVaultNativeOuroGSC',
    VaultNativeOuroSSC = 'OuroLiquidityVaultNativeOuroSSC',
    VaultNativeOuroWegld = 'OuroLiquidityVaultNativeOuroWegld',

    VaultSecuredOuroUSDD = 'OuroLiquidityVaultSecuredOuroUSDD',
    VaultSecuredOuroUSDC = 'OuroLiquidityVaultSecuredOuroUSDC',
    VaultSecuredOuroVST = 'OuroLiquidityVaultSecuredOuroVST',
    VaultSecuredOurobVST = 'OuroLiquidityVaultSecuredOurobVST',
    VaultSecuredOuroEstar = 'OuroLiquidityVaultSecuredOuroEstar',
    VaultSecuredOuroKoson = 'OuroLiquidityVaultSecuredOuroKoson',
    VaultSecuredOuroXLH = 'OuroLiquidityVaultSecuredOuroXLH',
    VaultSecuredOuroGSC = 'OuroLiquidityVaultSecuredOuroGSC',
    VaultSecuredOuroSSC = 'OuroLiquidityVaultSecuredOuroSSC',

    WalletNativeOuroUSDD = 'OuroLiquidityWalletNativeOuroUSDD',
    WalletNativeOuroUSDC = 'OuroLiquidityWalletNativeOuroUSDC',
    WalletNativeOuroVST = 'OuroLiquidityWalletNativeOuroVST',
    WalletNativeOurobVST = 'OuroLiquidityWalletNativeOurobVST',
    WalletNativeOurosVST = 'OuroLiquidityWalletNativeOurosVST',
    WalletNativeOurofVST = 'OuroLiquidityWalletNativeOurofVST',
    WalletNativeOuroEstar = 'OuroLiquidityWalletNativeOuroEstar',
    WalletNativeOuroKoson = 'OuroLiquidityWalletNativeOuroKoson',
    WalletNativeOuroXLH = 'OuroLiquidityWalletNativeOuroXLH',
    WalletNativeOuroGSC = 'OuroLiquidityWalletNativeOuroGSC',
    WalletNativeOuroSSC = 'OuroLiquidityWalletNativeOuroSSC',
    WalletNativeOuroWegld = 'OuroLiquidityWalletNativeOuroWegld',

    WalletSecuredOuroUSDD = 'OuroLiquidityWalletSecuredOuroUSDD',
    WalletSecuredOuroUSDC = 'OuroLiquidityWalletSecuredOuroUSDC',
    WalletSecuredOuroVST = 'OuroLiquidityWalletSecuredOuroVST',
    WalletSecuredOurobVST = 'OuroLiquidityWalletSecuredOurobVST',
    WalletSecuredOuroEstar = 'OuroLiquidityWalletSecuredOuroEstar',
    WalletSecuredOuroKoson = 'OuroLiquidityWalletSecuredOuroKoson',
    WalletSecuredOuroXLH = 'OuroLiquidityWalletSecuredOuroXLH',
    WalletSecuredOuroGSC = 'OuroLiquidityWalletSecuredOuroGSC',
    WalletSecuredOuroSSC = 'OuroLiquidityWalletSecuredOuroSSC',

    VaultSLIP = 'OuroLiquidityVaultSLIP',
    WalletSLIP = 'OuroLiquidityWalletSLIP',
}
