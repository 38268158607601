import { useEffect, useState } from 'react';
import { useGetAccount, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import { EAURYN_TOKEN_ID, VEAURYN_TOKEN_ID } from 'config';
import { selectEliteAccountTracker, setEatTokenBalance } from 'redux/reducers';
import { useAppDispatch, useAppSelector } from 'redux/store';

export const useEliteAccountTrackerHooks = () => {
    const { hasPendingTransactions } = useGetPendingTransactions();
    const { address } = useGetAccount();
    const dispatch = useAppDispatch();

    const eliteAccountTrackerRedux = useAppSelector(selectEliteAccountTracker);

    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        if (!address || hasPendingTransactions) return;

        if (isInitialized || !eliteAccountTrackerRedux.tokenBalances[EAURYN_TOKEN_ID]) {
            setEatTokenBalance(address, EAURYN_TOKEN_ID, dispatch);
        }

        if (isInitialized || !eliteAccountTrackerRedux.tokenBalances[VEAURYN_TOKEN_ID]) {
            setEatTokenBalance(address, VEAURYN_TOKEN_ID, dispatch);
        }

        setIsInitialized(true);
    }, [address, hasPendingTransactions]);
};
