import React, { FC } from 'react';
import { Mark } from '@mui/base/useSlider';
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

const AirbnbSlider = styled(Slider)(({ theme }) => ({
    color: '#98A1C0',
    height: 3,
    padding: '13px 0',
    '& .MuiSlider-thumb': {
        height: 20,
        width: 20,
        backgroundColor: '#100017',
        border: '1px solid currentColor',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: '0 0 0 8px rgba(249, 216, 94, 0.16)',
        },
        '& .airbnb-bar': {
            height: 9,
            width: 1,
            backgroundColor: 'currentColor',
            marginLeft: 1,
            marginRight: 1,
        },
    },
    '& .MuiSlider-track': {
        height: 6,
    },
    '& .MuiSlider-rail': {
        color: theme.palette.mode === 'dark' ? '#100017' : '#100017',
        // opacity: theme.palette.mode === 'dark' ? undefined : 1,
        opacity: 1,
        height: 6,
    },
    '& .MuiSlider-markLabel': {
        color: '#98A1C0',
    },
    '& .MuiSlider-markActive': {
        backgroundColor: '#98A1C0',
    },
}));

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AirbnbThumbComponentProps extends React.HTMLAttributes<unknown> { }

function AirbnbThumbComponent(props: AirbnbThumbComponentProps) {
    const { children, ...other } = props;
    return <SliderThumb {...other}>{children}</SliderThumb>;
}

interface propsInterface {
    onChange: (e: number) => void;
    marks?: boolean | Mark[]
    value: number,
    min?: number,
    max?: number,
    step?: number
    disabled?: boolean,
}


export const CustomSlider: FC<propsInterface> = ({
    onChange,
    marks,
    value,
    min,
    max,
    step,
    disabled = false,
}) => {
    return (
        <>
            <AirbnbSlider
                components={{ Thumb: AirbnbThumbComponent }}
                getAriaLabel={(index: number) =>
                    index === 0 ? 'Minimum price' : 'Maximum price'
                }
                defaultValue={0}
                marks={marks}
                value={value}
                onChange={(_: any, num: any) => onChange(num)}
                min={min}
                max={max}
                step={step}
                disabled={disabled}
            />
        </>
    );
};
