// SLIP config
export const CREATE_SLIP_POOL_GAS_LIMIT = 300_000_000;
export const PAUSE_SLIP_POOL_GAS_LIMIT = 10_000_000;
export const UNPAUSE_SLIP_POOL_GAS_LIMIT = 10_000_000;
export const REMOVE_SLIP_POOL_GAS_LIMIT = 5_000_000;
export const UNREMOVE_SLIP_POOL_GAS_LIMIT = 5_000_000;
export const INJECT_OURO_INTO_SLIP_GAS_LIMIT = 10_000_000;
export const EXTRACT_OURO_INTO_SLIP_GAS_LIMIT = 10_000_000;
export const SET_SLIP_REDIRECT_LP_ADDRESS_GAS_LIMIT = 5_000_000;
export const ADD_LIQUIDITY_SLIP_GAS_LIMIT = 100_000_000;
export const SET_SLIP_VAULT_LP_ADDRESS_GAS_LIMIT = 5_000_000;
export const SET_SLIP_ESDT_TRANSFER_ROLE_GAS_LIMIT = 60_000_000;
export const ISSUE_SLIP_TOKEN_GAS_LIMIT = 300_000_000;
export const MINT_SLIP_TOKEN_GAS_LIMIT = 10_000_000;
