import React from 'react';
import { Info } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

export const InfoTooltip = ({ title, type = 0 }: { children?: any; title: any; type?: number }) => {
    if (type == 3) {
        return (
            <Tooltip
                arrow
                title={title}
                style={{
                    // position: 'absolute',
                    // top: '.1rem',
                    // right: '.1rem',
                    marginLeft: '.2rem',
                    fontSize: '1.1rem',
                    color: '#98a1c0',
                }}
            >
                <Info />
            </Tooltip>
        );
    }
    if (type == 2) {
        // for SFT UM box
        return (
            <Tooltip
                arrow
                title={title}
                style={{
                    position: 'absolute',
                    top: '.1rem',
                    right: '.1rem',
                    fontSize: '1.1rem',
                    color: '#98a1c0',
                }}
            >
                <Info />
            </Tooltip>
        );
    }
    if (type == 1) {
        // multiplier
        return (
            <Tooltip
                arrow
                title={title}
                style={{
                    position: 'absolute',
                    top: '-.5rem',
                    right: 'auto',
                    fontSize: '1.1rem',
                    color: '#98a1c0',
                }}
            >
                <Info />
            </Tooltip>
        );
    }
    return (
        <Tooltip
            arrow
            title={title}
            style={{
                position: 'absolute',
                top: '.2rem',
                right: '.2rem',
                fontSize: '1.3rem',
                color: '#98a1c0',
            }}
        >
            <Info />
        </Tooltip>
    );
};
