import React from 'react';
import clsx from 'clsx';
import Slider from 'react-slick';

import { TOKEN_INFO_MAP } from 'config';
import {
    LiquidityTokenSelectorForVault,
    MemeLiquidityVestaVaultSelectedHolding,
    OuroLiquidityVestaVaultSelectedHolding,
} from 'z/types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface LiquidityVestaVaultLpSliderProps {
    nativeTokenSelectorForVault: LiquidityTokenSelectorForVault[];
    onTokenSelected(
        token_id: string,
        holding_type: OuroLiquidityVestaVaultSelectedHolding | MemeLiquidityVestaVaultSelectedHolding,
    ): void;
    selectedHolding: OuroLiquidityVestaVaultSelectedHolding | MemeLiquidityVestaVaultSelectedHolding;
    isDisabled(holding_type: OuroLiquidityVestaVaultSelectedHolding | MemeLiquidityVestaVaultSelectedHolding): boolean;
}


export const LiquidityVestaVaultLpSlider = ({
    nativeTokenSelectorForVault,
    onTokenSelected,
    selectedHolding,
    isDisabled,
}: LiquidityVestaVaultLpSliderProps) => {
    const settings = {
        className: 'center',
        centerMode: true,
        infinite: true,
        centerPadding: '0px',
        slidesToShow: Math.min(5, nativeTokenSelectorForVault.length),
        speed: 500,
    };

    return (
        <div className="px-4 py-2" style={{ width: `${Math.min(7 + 20 * nativeTokenSelectorForVault.length, 100)}%` }}>
            <Slider {...settings}>
                {nativeTokenSelectorForVault.map((row, id) => (
                    <button
                        className={clsx({
                            'vault-token-selector': true,
                            selected: selectedHolding == row.holding_type,
                        })}
                        onClick={() => onTokenSelected(row.token_id, row.holding_type)}
                        key={`vault-token-selector-${id}`}
                        disabled={isDisabled(row.holding_type)}
                    >
                        <img src={TOKEN_INFO_MAP[row.token_id].logo} alt={row.token_name} style={{ width: '100%' }} />
                    </button>
                ))}
            </Slider>
        </div>
    );
};
