import React, { ChangeEvent, useEffect, useState } from 'react';
import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import { Row, Col } from 'react-bootstrap';

import { MVX_EXPLORER_URL } from 'config';
import { selectNetstats } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { ERROR_NOT_ENOUGH_BALANCE, convertEsdtToWei, convertWeiToEsdt, formatNumber } from 'z/utils';

export enum ShowStakeOrUnstakeEnum {
    Stake = 'Stake',
    Unstake = 'Unstake',
}

const DECIMALS = 18;

interface StakeUnstakeFrLpParams {
    frozenLpIdentifier: string;
}

export const StakeUnstakeFrLp = ({ frozenLpIdentifier }: StakeUnstakeFrLpParams) => {
    const netstatsRedux = useAppSelector(selectNetstats);

    const [showStakeOrUnstake, setButtonType] = useState<ShowStakeOrUnstakeEnum>(ShowStakeOrUnstakeEnum.Stake);

    function onNext() {
        setButtonType(ShowStakeOrUnstakeEnum.Unstake);
    }

    function onPrev() {
        setButtonType(ShowStakeOrUnstakeEnum.Stake);
    }

    const handleFunds = async () => {
        switch (showStakeOrUnstake) {
            case ShowStakeOrUnstakeEnum.Stake:
            // TODO: stake frozen lp
            case ShowStakeOrUnstakeEnum.Unstake:
            // TODO: unstake frozen lp
        }
    };

    const { address } = useGetAccount();

    useEffect(() => {
        setAccountLpAmount(netstatsRedux.walletTokensMap[frozenLpIdentifier]?.balance || '0');
    }, [address, netstatsRedux.walletTokensMap]);

    const [accountLpAmount, setAccountLpAmount] = useState('0');
    const [vaultLpAmount] = useState('0');
    const [inputLpAmount, setInputLpAmount] = useState('');
    const [amountError, setAmountError] = useState('');

    const handleChangeAmount = (event: ChangeEvent<HTMLInputElement>) => {
        if (convertEsdtToWei(event.target.value, DECIMALS).comparedTo(accountLpAmount) > 0)
            setAmountError(ERROR_NOT_ENOUGH_BALANCE);
        else setAmountError('');

        setInputLpAmount(event.target.value);
    };

    const handleSetAddLiquidityAmount = () => {
        setInputLpAmount(
            convertWeiToEsdt(
                showStakeOrUnstake === ShowStakeOrUnstakeEnum.Stake ? accountLpAmount : vaultLpAmount,
                DECIMALS,
                DECIMALS,
            ).toFixed(),
        );
    };

    return (
        <Row
            style={{
                marginTop: '30px',
            }}
        >
            <Col md={9}>
                <input
                    className="vesta_x_input"
                    type="number"
                    placeholder={`Secured LP Amount`}
                    onChange={handleChangeAmount}
                    value={inputLpAmount}
                />

                <div className="d-flex justify-content-between mt-1">
                    <div>
                        <div className="input-token-error">{amountError}</div>
                        <div>
                            <a
                                href={`${MVX_EXPLORER_URL}/tokens/${frozenLpIdentifier}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {frozenLpIdentifier}
                            </a>
                        </div>
                    </div>
                    <div className="add-liquidity-input-token-balance-box" onClick={handleSetAddLiquidityAmount}>
                        <div className="">Balance:&nbsp;</div>
                        <div
                            style={{
                                color: 'white',
                            }}
                        >
                            {formatNumber(
                                convertWeiToEsdt(
                                    showStakeOrUnstake === ShowStakeOrUnstakeEnum.Stake
                                        ? accountLpAmount
                                        : vaultLpAmount,
                                    DECIMALS,
                                    DECIMALS,
                                ),
                                DECIMALS,
                            )}
                        </div>
                    </div>
                </div>
            </Col>
            <Col md={3}>
                <div className="d-flex w-100">
                    <button
                        className="slip-but"
                        style={{
                            width: '15%',
                        }}
                        onClick={onPrev}
                        disabled={showStakeOrUnstake === ShowStakeOrUnstakeEnum.Stake}
                    >
                        {'<'}
                    </button>
                    <button className="slip-but w-60" onClick={handleFunds} disabled={true}>
                        {showStakeOrUnstake}
                    </button>
                    <button
                        className="slip-but"
                        style={{
                            width: '15%',
                        }}
                        onClick={onNext}
                        disabled={showStakeOrUnstake === ShowStakeOrUnstakeEnum.Unstake}
                    >
                        {'>'}
                    </button>
                </div>
            </Col>
        </Row>
    );
};
