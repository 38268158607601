import React from 'react';
import {
  ExtensionLoginButton,
  WebWalletLoginButton,
  LedgerLoginButton,
  WalletConnectLoginButton,
} from '@multiversx/sdk-dapp/UI';
// import { useLocation } from 'react-router-dom';
import extensionLogo from 'assets/img/wallet/extension.svg';
import ledgerLogo from 'assets/img/wallet/ledger.svg';
import maiarLogo from 'assets/img/wallet/maiar.svg';
import webWalletLogo from 'assets/img/wallet/web-wallet.svg';
import { walletConnectV2ProjectId } from 'config';
import './Unlock.scss';
import { routeNames } from 'routes';

const UnlockContent = () => {
    // const { pathname } = useLocation();

    const commonProps = {
        nativeAuth: true, // optional
        callbackRoute: routeNames.assets,
    };

    return (
        <div className='home d-flex flex-fill align-items-center' style={{ marginTop: '30px' }}>
            <div data-testid='unlockPage' style={{width: '100%'}}>
                <div className='text-center info-item'>
                    <div className='card-body' style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                        <WalletConnectLoginButton
                            className='wallet-login'
                            {...commonProps}
                            {...(walletConnectV2ProjectId ? { isWalletConnectV2: true }: {})}
                        >
                            <div className='wallet-content'>
                                <div className='wallet-icon'>
                                    <img src={maiarLogo} alt='xPortal app' />
                                </div>
                                {'xPortal App'}
                            </div>
                        </WalletConnectLoginButton>

                        <ExtensionLoginButton
                            className='wallet-login'
                            {...commonProps}
                        >
                            <div className='wallet-content'>
                                <div className='wallet-icon'>
                                    <img src={extensionLogo} alt='Extension' />
                                </div>
                                {'Browser Wallet'}
                            </div>
                        </ExtensionLoginButton>

                        <LedgerLoginButton
                            className='wallet-login'
                            {...commonProps}
                        >
                            <div className='wallet-content'>
                                <div className='wallet-icon'>
                                    <img src={ledgerLogo} alt='Ledger' />
                                </div>
                                {'Ledger'}
                            </div>
                        </LedgerLoginButton>

                        <WebWalletLoginButton
                            className='wallet-login'
                            {...commonProps}
                        >
                            <div className='wallet-content'>
                                <div className='wallet-icon'>
                                    <img src={webWalletLogo} alt='Web Wallet' />
                                </div>
                            {'Web wallet'}
                            </div>
                        </WebWalletLoginButton>
                    </div>
                </div>
            </div>
        </div>
    );
};


export const Unlock = () => (
    // <AuthRedirectWrapper>
        <UnlockContent />
    // </AuthRedirectWrapper>
);
