import { config } from './elrond';

export const EGLD_PRICE_UPDATE_PERIOD = 30_000; // 30s

export const LIQUID_LOCKER_SC_ADDRESS = config.contracts['liquid-locker'].address;
export const LIQUID_LOCKER_LOCK_GAS_LIMIT = 20_000_000;
export const NUM_SHOW_DECIMALS = 6;

export const FAUCET_MINT_GAS_LIMIT = 20_000_000;

export const VEGLD_SC_ADDRESS = config.contracts['liquid-staking'].address;
export const ELITE_ACCOUNT_TRACKER_SC_ADDRESS = config.contracts['elite-account-tracker'].address;
export const SNAKE_COIL_SC_ADDRESS = config.contracts['snake-coil'].address;
export const SLIP_SC_ADDRESS = config.contracts['slip'].address;
export const SNAKE_TRANSFER_SC_ADDRESS = config.contracts['snake-transfer'].address;
export const FAUCET_SC_ADDRESS = config.contracts['faucet'].address;
export const OURO_FACTORY_SC_ADDRESS = config.contracts['ouro-factory'].address;
export const NFT_STAKING_V2_SC_ADDRESS = config.contracts['nft-staking'].address;
export const STABLE_COIN_PROTOCOL_SC_ADDRESS = config.contracts['stable-coin-protocol'].address;
export const ADMIN_SC_ADDRESS = config.contracts['admin'].address;

export const VESTA_API_URL =
    process.env.REACT_APP_ENV_IS_DEV === 'true' ? '/api' : config['default-network']['vesta-api'];

export const DEB_PRECISION = 100_000;

export const ISSUE_ESDT_PRICE = 50_000_000_000_000_000;
