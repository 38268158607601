import React, { FC } from 'react';
import { useEffect, useState } from 'react';
import Axios from 'axios';
import { BiChevronDown } from 'react-icons/bi';
import { IoClose } from 'react-icons/io5';
import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

interface propsInterface {
    value?: any;
    onChange?: (e: any) => void;
    disabled?: boolean;
}

export const TokenSelect: FC<propsInterface> = ({
    value,
    onChange,
    disabled,
}) => {
    const [search, setSearch] = useState<string>('');
    const [crypto, setCrypto] = useState<Array<any>>([]);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    // const [currentToken, setCurrentToken] = useState<any | undefined>(undefined);
    
    useEffect(() => {
        Axios.get(
            `https://api.coinstats.app/public/v1/coins?skip=0&limit=100¤cy=INR`,
        ).then((res) => {
            setCrypto(res.data.coins);
        });
    }, []);
    
    function closeModal() {
        setIsOpen(false);
    }
    
    const handleSelectToken = (tokenInfo: any) => {
        if (onChange) onChange(tokenInfo);
        
        setSearch('');
        closeModal();
    };
    
    const handleOpenModal = () => {
        if (disabled) {
            return;
        }
        setIsOpen(true);
    };

    return (
        <>
            <div>
                {value === undefined ? (
                    <button className="select-token-but" onClick={handleOpenModal}>
                        Select Token
                        {!disabled && <BiChevronDown />}
                    </button>
                ) : (
                    <button className="selected-token-but" onClick={handleOpenModal}>
                        <img src={value?.icon} alt="logo" width="30px" />
                        {value?.symbol}
                        {!disabled && <BiChevronDown />}
                    </button>
                )}
            </div>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                ariaHideApp={false}
            >
                <div className="select-token-modal">
                    <div
                        style={{
                            padding: '0px 15px',
                            borderBottom: '1px solid rgba(255,255,255,0.06)',
                        }}
                    >
                        <div className="d-flex justify-content-between align-items-center">
                            <span
                                style={{
                                    color: '#F1DC46',
                                    marginLeft: '10px',
                                    fontSize: '1.25rem',
                                }}
                            >
                                Select a token
                            </span>

                            <div className="modal-close-but" onClick={closeModal}>
                                <IoClose />
                            </div>
                        </div>
                        <input
                            className="search-token mt-3"
                            placeholder="🧐 Search name or paste address"
                            onChange={(e) => setSearch(e.target.value)}
                        />

                        <div className="mt-3 d-flex flex-wrap gap-2 mb-4">
                        {[0, 1, 2, 3, 4, 5].map((_, index) => {
                            return (
                                <div
                                    className="token-tag"
                                    key={`token-tag${index}`}
                                    onClick={() => handleSelectToken(crypto[index])}
                                >
                                    <img src={crypto[index]?.icon} alt="logo" width="30px" />
                                    <span style={{ color: '#98A1C0', fontSize: '1.2rem' }}>
                                        {crypto[index]?.symbol}
                                    </span>
                                </div>
                            );
                        })}
                        </div>
                    </div>

                    <div style={{ padding: '0px 15px' }}>
                        <div className="mt-3 scrollbar token-list-box">
                        {crypto
                            .filter((val) => {
                                return val.symbol
                                    .toLowerCase()
                                    .includes(search.toLocaleLowerCase());
                            })
                            .map((row, index) => {
                                return (
                                    <div
                                        className="token-li"
                                        key={index}
                                        onClick={() => handleSelectToken(row)}
                                    >
                                        <img src={row.icon} alt="logo" width="40px" />

                                        <div className="d-flex flex-column">
                                            <span style={{ color: 'white', fontSize: '1.2rem' }}>
                                                {row.name}
                                            </span>
                                            <span style={{ color: '#98A1C0' }}>{row.symbol}</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};
