import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FaTwitter, FaDiscord, FaYoutube, FaTelegramPlane } from 'react-icons/fa';
import demi_logo from 'assets/img/demiourgos-holdings-logo.png';
import imgVestaXLogo from 'assets/img/vesta-x-logo.png';

export const Footer = () => {
    return (
        <div className="footer">
            <div className='dark-bg-footer'>
                <div className="container">
                    <Row className='text-center align-items-center gy-4'>
                        <Col md={5} lg={4}>
                            <div>{"The first Joint-Stock Film Company in the world, founded by NFT owners on MultiversX™ Blockchain."}</div>
                        </Col>

                        <Col md={2} lg={4}>
                            <a href='https://demiourgos.holdings/' target='_blank' rel="noreferrer">
                                <img className='demi-footer-logo' src={demi_logo} alt='demiourgos holdings' />
                            </a>
                        </Col>

                        <Col md={5} lg={4}>
                            <div className='d-flex align-items-center gap-3 justify-content-center'>
                                <div className="d-flex gap-3">
                                    <a
                                        className="social-box"
                                        href="https://www.twitter.com/DemiourgosH"
                                        target="blank"
                                    >
                                        <FaTwitter />
                                    </a>
                                    <a
                                        className="social-box"
                                        href="https://t.me/DemiourgosCommunity"
                                        target="blank"
                                    >
                                        <FaTelegramPlane />
                                    </a>
                                    <a
                                        className="social-box"
                                        href="https://discord.gg/szjUw4jV"
                                        target="blank"
                                    >
                                        <FaDiscord />
                                    </a>
                                    <a
                                        className="social-box"
                                        href="https://www.youtube.com/c/CarpathianPictures"
                                        target="blank"
                                    >
                                        <FaYoutube />
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className="container" >
                <div
                    className="d-flex justify-content-center"
                    style={{ padding: '50px 0px' }}
                >
                    <div>
                        <img src={imgVestaXLogo} width={330} />
                    </div>
                </div>

                <div className='d-flex justify-content-center mb-3'>
                    <iframe
                        src={'https://egld.community/api/products/f2960174-cbb0-40b7-a8c7-2eb48d19ad81/upvotes/embed?theme=secondary&size=md'}
                        style={{
                            width: '163px',
                            height: '52px',
                            borderRadius: '8px',
                            border: 'nonce'
                        }}
                    />
                </div>

                <Row className='mb-5' style={{ fontSize: '12px', lineHeight: '20px' }}>
                    <Col md={6}>
                        <div className='contact-box'>
                            info@vestax.finance
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='copyright-box'>
                            © 2022-2023 | Built with ❤️ By{' '}
                            <a
                                href="https://demiourgos.holdings"
                                className="egns-lab"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Demiourgos Coding Division
                            </a>
                            {' & '}
                            <a
                                href="https://synchronic.software"
                                className="egns-lab"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Synchronic Software
                            </a>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
