import { SmartContract, TokenTransfer } from '@multiversx/sdk-core/out';
import { STABLE_LIQUIDITY_VAULT_ADDRESS } from 'config';
import { EsdtTokenPaymentType, VestingTypeEnum, BigNumber } from 'z/types';
import abiJson from './abi/stable-liquidity-vault.abi.json';
import { mvxQuery, mvxSendTransaction, parseEsdtTokenPayment, parseNumberArray, parseStringArray } from './common';
import { createSmartContract } from "./provider";

export interface StableLiquidityVaultBaseContext {
  staking_token_id: string,
  raw_vesta_token_id: string,
  sft_token_id: string,
  sft_lock_period: number,
  premium_reward_token_ids: string[],
  elite_reward_token_ids: string[],
}

export interface StableLiquidityVaultStatsContext {
  sft_reserves: BigNumber[],
  reward_rate: BigNumber,
  total_minor_power: BigNumber,
  total_major_power: BigNumber,
}

export interface StableLiquidityVaultUserContext {
  sft_staked_amounts: number[],

  minor_power: BigNumber,
  major_power: BigNumber,

  reward_amount: BigNumber,
  last_claimed_timestamp: number,
  user_last_stake_epoch: number,
  user_unstake_fee_percent: number,

  deb: number,
  vlm: number,
  im: number,
  vm: number,

  premium_reward_payments: EsdtTokenPaymentType[],
  elite_reward_payments: EsdtTokenPaymentType[],
}

export class StableLiquidityVaultContract {
    sender: string;
    smartContract: SmartContract;

    constructor(
        sender: string,
    ) {
        this.sender = sender;
        this.smartContract = createSmartContract(abiJson, STABLE_LIQUIDITY_VAULT_ADDRESS);
    }

    async viewBaseContext(): Promise<StableLiquidityVaultBaseContext | undefined> {
        try {
            const value = await mvxQuery(this.smartContract.methods.viewBaseContext());
            const decoded = {
                staking_token_id: value.staking_token_id.toString(),
                raw_vesta_token_id: value.raw_vesta_token_id.toString(),
                sft_token_id: value.sft_token_id.toString(),
                sft_lock_period: value.sft_lock_period.toNumber(),
                premium_reward_token_ids: parseStringArray(value.premium_reward_token_ids),
                elite_reward_token_ids: parseStringArray(value.elite_reward_token_ids),
            };

            return decoded;
        } catch (err) {
            console.error(`${StableLiquidityVaultContract.name}.viewBaseContext:`, err);
            return undefined;
        }
    }

    async viewStatsContext(): Promise<StableLiquidityVaultStatsContext | undefined> {
        try {
            const value = await mvxQuery(this.smartContract.methods.viewStatsContext());
            const decoded = {
                sft_reserves: value.sft_reserves,
                reward_rate: value.reward_rate,
                total_minor_power: value.total_minor_power,
                total_major_power: value.total_major_power,
            };

            return decoded;
        } catch (err) {
            console.error(`${StableLiquidityVaultContract.name}.viewStatsContext:`, err);
            return undefined;
        }
    }

    async viewUserContext(address: string): Promise<StableLiquidityVaultUserContext | undefined> {
        try {
            const value = await mvxQuery(this.smartContract.methods.viewUserContext([address]));
            const decoded = {
                sft_staked_amounts: parseNumberArray(value.sft_staked_amounts),

                minor_power: value.minor_power,
                major_power: value.major_power,

                reward_amount: value.reward_amount,
                last_claimed_timestamp: value.last_claimed_timestamp.toNumber(),
                user_last_stake_epoch: value.user_last_stake_epoch.toNumber(),
                user_unstake_fee_percent: value.user_unstake_fee_percent.toNumber() / 1_000_000,

                deb: value.deb.toNumber() / 100_000,
                vlm: value.vlm.toNumber() / 1_000_000,
                im: value.im.toNumber() / 1_000_000,
                vm: value.vm.toNumber() / 1_000_000,

                premium_reward_payments: value.premium_reward_payments.map((v: any) => parseEsdtTokenPayment(v)),
                elite_reward_payments: value.elite_reward_payments.map((v: any) => parseEsdtTokenPayment(v)),
            };

            return decoded;
        } catch (err) {
            console.error(`${StableLiquidityVaultContract.name}.viewUserContext:`, err);
            return undefined;
        }
    }

    async getVstFeeAmount(address: string, unstakeAmount: BigNumber): Promise<BigNumber | undefined> {
        try {
            const value = await mvxQuery(this.smartContract.methods.getVstFeeAmount([address, unstakeAmount]));
            const decoded = value as BigNumber;

            return decoded;
        } catch (err) {
            console.error(`${StableLiquidityVaultContract.name}.getVstFeeAmount:`, err);
            return undefined;
        }
    }

    async stakeToken(payments: TokenTransfer[]) {
        const interaction = this.smartContract.methods.stakeToken();
        await mvxSendTransaction({
            interaction,
            payments,
            gasLimit: 30_000_000,
            txName: 'Stake',
            sender: this.sender,
        });
    }

    async unstakeToken(amountOut: BigNumber) {
        const interaction = this.smartContract.methods.unstakeToken([amountOut]);
        await mvxSendTransaction({
            interaction,
            gasLimit: 30_000_000,
            txName: 'Unstake',
            sender: this.sender,
        });
    }

    async unstakeTokenWithFee(
        payments: TokenTransfer[],
        amountOut: BigNumber,
    ) {
        const interaction = this.smartContract.methods.unstakeTokenWithFee([amountOut]);
        await mvxSendTransaction({
            interaction,
            payments,
            gasLimit: 150_000_000,
            txName: 'Unstake With Fee',
            sender: this.sender,
        });
    }

    async stakeSft(payments: TokenTransfer[]) {
        const interaction = this.smartContract.methods.stakeSft();
        await mvxSendTransaction({
            interaction,
            payments,
            gasLimit: 30_000_000,
            txName: 'Stake SFT',
            sender: this.sender,
        });
    }

    async unstakeSft(sfts: TokenTransfer[]) {
        const transformed = sfts.map((v) => ({
            token_identifier: v.tokenIdentifier,
            token_nonce: v.nonce,
            amount: v.amountAsBigInteger,
        }));
        const interaction = this.smartContract.methods.unstakeSft([transformed]);
        await mvxSendTransaction({
            interaction,
            gasLimit: 30_000_000 * sfts.length,
            txName: 'Unstake SFT',
            sender: this.sender,
        });
    }

    async claimReward(vestingType: VestingTypeEnum, lockYears: number, mintPercent: number) {
        const interaction = this.smartContract.methods.claimReward([
            vestingType,
            lockYears,
            mintPercent * 10000, // convert to percent
        ]);
        await mvxSendTransaction({
            interaction,
            gasLimit: 30_000_000,
            txName: 'Claim Rewards',
            sender: this.sender,
        });
    }

    async claimPremiumRewards() {
        const interaction = this.smartContract.methods.claimPremiumRewards();
        await mvxSendTransaction({
            interaction,
            gasLimit: 30_000_000,
            txName: 'Claim Premium Rewards',
            sender: this.sender,
        });
    }

    async claimEliteRewards() {
        const interaction = this.smartContract.methods.claimEliteRewards();
        await mvxSendTransaction({
            interaction,
            gasLimit: 30_000_000,
            txName: 'Claim Elite Rewards',
            sender: this.sender,
        });
    }
}
