import { BinaryCodec, FieldDefinition, StructType, U32Type, U64Type } from "@multiversx/sdk-core/out";
import { NftType } from "@multiversx/sdk-dapp/types/tokens.types";
import { findFarmByAddress } from "config";
import { farmAbiRegistry } from "z/elrond";
import { NewLockedLpAttributesType } from "z/types";

export function decodeAttributes(metaEsdt: NftType, farm_address: string): NewLockedLpAttributesType {
  const farmFixInfo = findFarmByAddress(farm_address);
  if (metaEsdt.nonce > farmFixInfo!.last_nonce_with_old_attributes) {
    const newType = new StructType(
      "LockedLpAttributes",
      [
        new FieldDefinition("stake_epoch", "", new U64Type()),
        new FieldDefinition("locked_lp_type_id", "", new U32Type()),
        new FieldDefinition("unbonding_start_epoch", "", new U64Type()),
        new FieldDefinition("mint_timestamp", "", new U64Type()),
      ]
    );
    const decodedAttributes = new BinaryCodec().decodeTopLevel(Buffer.from(metaEsdt.attributes, "base64"), newType).valueOf();
    decodedAttributes.stake_epoch = decodedAttributes.stake_epoch.toNumber();
    decodedAttributes.locked_lp_type_id = decodedAttributes.locked_lp_type_id.toNumber();
    decodedAttributes.unbonding_start_epoch = decodedAttributes.unbonding_start_epoch.toNumber();
    decodedAttributes.mint_timestamp = decodedAttributes.mint_timestamp.toNumber();

    return decodedAttributes;
  }

  const oldType = farmAbiRegistry.getStruct("LockedLpAttributes");
  const decodedAttributes = new BinaryCodec().decodeTopLevel(Buffer.from(metaEsdt.attributes, "base64"), oldType).valueOf();
  decodedAttributes.stake_epoch = decodedAttributes.stake_epoch.toNumber();
  decodedAttributes.locked_lp_type_id = decodedAttributes.locked_lp_type_id.toNumber();
  decodedAttributes.unbonding_start_epoch = decodedAttributes.unbonding_start_epoch.toNumber();

  if (metaEsdt.nonce > farmFixInfo!.max_nonce_to_receive_rewards) {
    decodedAttributes.mint_timestamp = farmFixInfo!.reward_distribution_timestamp + 1;
  } else {
    decodedAttributes.mint_timestamp = farmFixInfo!.reward_distribution_timestamp - 1;
  }

  return decodedAttributes;


}