import React, { useState } from 'react';
import BigNumber from 'bignumber.js';
import { NumberInput } from 'pages/StableCoin/NumberInput';
import { useStableCoinProtocol } from 'z/hooks/stable-coin-protocol';

export const AdminDecreasePreciousMetal = () => {
    const [inputTokenAmount, setInputTokenAmount] = useState('0');
    const { sendAdminDecreasePreciousMetalCollateralTransaction } = useStableCoinProtocol();

    const handleWithdrawPreciousMetalsAction = async () => {
        await sendAdminDecreasePreciousMetalCollateralTransaction(
            new BigNumber(inputTokenAmount).shiftedBy(18).toString(10),
        );
    };
    return (
        <div className="active-pool-li-container mt-4">
            <div className="mb-1" style={{ fontSize: '18px', color: '#f1dc46' }}>
                Admin decrease precious metals backing
            </div>
            <div>
                Use this to withdraw Precious Metals based on price appreciation if the collateralization factor is
                above threshold.
            </div>
            <br />
            <NumberInput onChange={setInputTokenAmount} />
            <div className="w-100 d-flex justify-content-center mt-3">
                <button className="mt-3 add-liquidity-button" onClick={handleWithdrawPreciousMetalsAction}>
                    Decrease PM Backing
                </button>
            </div>
        </div>
    );
};
