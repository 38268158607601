import React, { useEffect, useState } from 'react';
import { TokenTransfer } from '@multiversx/sdk-core/out';
import { useGetAccount, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import { SendTransactionReturnType } from '@multiversx/sdk-dapp/types';
import BigNumber from 'bignumber.js';
import { Row } from 'react-bootstrap';

import { InfoTooltip } from 'components';
import { CustomSlider2 } from 'components/CustomSlider2';
import { TOKEN_INFO_MAP } from 'config';
import { AddLiquidityInputToken } from 'pages/Pool/AddLiquidityInputToken';
import { MemeLiquidityVestaVaultSelectedHolding, OuroLiquidityVestaVaultSelectedHolding } from 'z/types';
import {
    convertBigNumberToInputString,
    formatNumber,
    convertEsdtToWei,
    createTokenTicker,
    convertWeiToEsdt,
    ERROR_INVALID_NUMBER,
    ERROR_NOT_ENOUGH_BALANCE,
    isPositiveOrZeroBigNumber,
    ZERO_STRING,
    isLiquidityNativeVaultSelectedHolding,
    isLiquiditySecuredVaultSelectedHolding,
    isLiquidityNativeWalletSelectedHolding,
    isLiquiditySecuredWalletSelectedHolding,
    ERROR_CONNECT_WALLET,
    ERROR_TRANSACTION_ONGOING,
    isPositiveBigNumber,
    toastError,
} from 'z/utils';

enum ActionsEnum {
    Stake = 'Stake',
    Unstake = 'Unstake',
}

interface LiquidityVestaVaultStakeUnstakeParam {
    selectedTokenId: string;
    selectedHolding: OuroLiquidityVestaVaultSelectedHolding | MemeLiquidityVestaVaultSelectedHolding;
    liquidityBalance: BigNumber;
    fee: string;
    stakeToken(
        payments: TokenTransfer[],
        sender: string,
    ): Promise<{
        sessionId: SendTransactionReturnType;
        error: SendTransactionReturnType;
    }>;
    unstakeToken(payments: TokenTransfer[]): Promise<{
        sessionId: SendTransactionReturnType;
        error: SendTransactionReturnType;
    }>;
}

export const LiquidityVestaVaultStakeUnstake = ({
    selectedTokenId,
    selectedHolding,
    liquidityBalance,
    fee,
    stakeToken,
    unstakeToken,
}: LiquidityVestaVaultStakeUnstakeParam) => {
    const { address } = useGetAccount();
    const { hasPendingTransactions } = useGetPendingTransactions();

    const [actionType, setActionType] = useState(ActionsEnum.Unstake);

    useEffect(() => {
        if (
            isLiquidityNativeVaultSelectedHolding(selectedHolding) ||
            isLiquiditySecuredVaultSelectedHolding(selectedHolding) ||
            selectedHolding === OuroLiquidityVestaVaultSelectedHolding.VaultSLIP ||
            selectedHolding === MemeLiquidityVestaVaultSelectedHolding.VaultANCIENT
        ) {
            setActionType(ActionsEnum.Unstake);
        } else if (
            isLiquidityNativeWalletSelectedHolding(selectedHolding) ||
            isLiquiditySecuredWalletSelectedHolding(selectedHolding) ||
            selectedHolding === OuroLiquidityVestaVaultSelectedHolding.WalletSLIP ||
            selectedHolding === MemeLiquidityVestaVaultSelectedHolding.WalletANCIENT
        ) {
            setActionType(ActionsEnum.Stake);
        }
    }, [selectedHolding]);

    const [stakeAmount, setStakeAmount] = useState<string>(ZERO_STRING);
    const [stakeAmountError, setStakeAmountError] = useState<string>('');
    const [stakeSliderAmount, setStakeSliderAmount] = useState<number>(0);

    function onChangeStakeAmount(value: string) {
        const balance = liquidityBalance;

        let error = '';
        if (!isPositiveOrZeroBigNumber(value)) {
            error = ERROR_INVALID_NUMBER;
        } else if (
            address &&
            convertEsdtToWei(value, TOKEN_INFO_MAP[selectedTokenId].decimals).comparedTo(balance) > 0
        ) {
            error = ERROR_NOT_ENOUGH_BALANCE;
        }

        setStakeAmountError(error);
        setStakeAmount(value);

        if (balance.comparedTo(0) <= 0) {
            setStakeSliderAmount(0);
        } else {
            const _sliderAmount = Math.floor(
                convertEsdtToWei(value, TOKEN_INFO_MAP[selectedTokenId].decimals)
                    .div(balance)
                    .multipliedBy(100)
                    .toNumber(),
            );
            setStakeSliderAmount(Math.min(_sliderAmount, 100));
        }
    }

    function onMaxStakeAmount() {
        setStakeAmount(
            convertBigNumberToInputString(
                convertWeiToEsdt(
                    liquidityBalance,
                    TOKEN_INFO_MAP[selectedTokenId].decimals,
                    TOKEN_INFO_MAP[selectedTokenId].decimals,
                ),
                TOKEN_INFO_MAP[selectedTokenId].decimals,
            ),
        );
        setStakeSliderAmount(100);
    }

    useEffect(() => {
        setStakeAmount(ZERO_STRING);
        setStakeAmountError('');
        setStakeSliderAmount(0);
    }, [selectedHolding]);

    async function onStake() {
        const balance = liquidityBalance;

        let error = '';
        if (!address) {
            error = ERROR_CONNECT_WALLET;
        } else if (hasPendingTransactions) {
            error = ERROR_TRANSACTION_ONGOING;
        } else if (!isPositiveBigNumber(stakeAmount)) {
            error = ERROR_INVALID_NUMBER;
        } else if (convertEsdtToWei(stakeAmount, TOKEN_INFO_MAP[selectedTokenId].decimals).comparedTo(balance) > 0) {
            error = ERROR_NOT_ENOUGH_BALANCE;
        }

        if (error) {
            toastError(error);
            return;
        }

        const payments = [
            TokenTransfer.fungibleFromBigInteger(
                selectedTokenId,
                convertEsdtToWei(stakeAmount, TOKEN_INFO_MAP[selectedTokenId].decimals),
            ),
        ];
        await stakeToken(payments, address);
    }

    async function onUnstake() {
        let error = '';
        if (!address) {
            error = ERROR_CONNECT_WALLET;
        } else if (hasPendingTransactions) {
            error = ERROR_TRANSACTION_ONGOING;
        } else if (!isPositiveBigNumber(stakeAmount)) {
            error = ERROR_INVALID_NUMBER;
        } else {
            const balance = liquidityBalance;
            if (convertEsdtToWei(stakeAmount, TOKEN_INFO_MAP[selectedTokenId].decimals).comparedTo(balance) > 0) {
                error = ERROR_NOT_ENOUGH_BALANCE;
            }
        }

        if (error) {
            toastError(error);
            return;
        }

        const payments = [
            TokenTransfer.fungibleFromBigInteger(
                selectedTokenId,
                convertEsdtToWei(stakeAmount, TOKEN_INFO_MAP[selectedTokenId].decimals),
            ),
        ];
        await unstakeToken(payments);
    }

    return (
        <Row>
            <div className="col-12 col-lg-3"></div>
            <div
                className="col-12 col-lg-6 py-3"
                style={{
                    border: '1px solid #ffffff0f',
                    borderRadius: '10px',
                }}
            >
                <AddLiquidityInputToken
                    tokenAmount={stakeAmount}
                    onChangeTokenAmount={onChangeStakeAmount}
                    tokenId={selectedTokenId}
                    tokenTicker={createTokenTicker(selectedTokenId)}
                    tokenBalance={formatNumber(
                        convertWeiToEsdt(
                            liquidityBalance,
                            TOKEN_INFO_MAP[selectedTokenId].decimals,
                            TOKEN_INFO_MAP[selectedTokenId].decimals,
                        ),
                        TOKEN_INFO_MAP[selectedTokenId].decimals,
                    )}
                    onMaxTokenAmount={onMaxStakeAmount}
                    error={stakeAmountError}
                    feeInfo={actionType === ActionsEnum.Unstake ? `${fee}% Fees for unstaking now` : undefined}
                    info={
                        actionType === ActionsEnum.Unstake ? (
                            <div className="text-right position-relative mb-4">
                                <span
                                    style={{
                                        position: 'absolute',
                                        width: 'max-content',
                                        right: '22px',
                                    }}
                                >
                                    Info about fees for unstaking
                                </span>
                                <InfoTooltip
                                    title={
                                        <div>
                                            <div>Unstaking Fees:</div>

                                            <div className="mt-2">Native LP: </div>
                                            <div>3% 0 - 1 Days/Epochs </div>
                                            <div>2% 2 - 3 Days/Epochs </div>
                                            <div>1% 4 - 19 Days/Epochs </div>
                                            <div>0% 20 Days/Epochs or more </div>

                                            <div className="mt-2">
                                                Secured LPs (generated via SLIP) and SLIP token:{' '}
                                            </div>
                                            <div>0% Unstaking Fees</div>
                                        </div>
                                    }
                                />
                            </div>
                        ) : undefined
                    }
                />

                <div className="mt-2" style={{ padding: '10px 15px 10px 10px' }}>
                    <CustomSlider2
                        value={stakeSliderAmount}
                        step={1}
                        min={0}
                        max={100}
                        onChange={(v) => {
                            setStakeSliderAmount(v);

                            const balance = liquidityBalance;
                            setStakeAmount(
                                convertBigNumberToInputString(
                                    balance
                                        .multipliedBy(v)
                                        .div(100)
                                        .shiftedBy(-TOKEN_INFO_MAP[selectedTokenId].decimals),
                                    TOKEN_INFO_MAP[selectedTokenId].decimals,
                                ),
                            );
                        }}
                    />
                </div>

                <div className="d-flex justify-content-center align-items-center">
                    <button
                        className="mt-3 assets-button"
                        disabled={hasPendingTransactions}
                        onClick={() => (actionType === ActionsEnum.Stake ? onStake() : onUnstake())}
                    >
                        {actionType}
                    </button>
                </div>
            </div>
        </Row>
    );
};
