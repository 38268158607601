import React from 'react';
import BigNumber from 'bignumber.js';
import { useStableCoinProtocol } from 'z/hooks/stable-coin-protocol';
import { StableCoinPegDetails } from './StableCoinPegDetails';
import { StableCoinSwapComponent } from './StableCoinSwapComponent';

export const ORACLE_PRICE_DECIMALS_COUNT = 8;

const StableCoin = () => {
    const { context } = useStableCoinProtocol();

    return (
        <div className="container" style={{ marginTop: '50px', color: '#98a1c0' }}>
            <div className="d-flex justify-content-center">
                <div className="vesta-first-container w-75 mt-4" style={{ maxWidth: '1024px' }}>
                    <div className="mb-4">
                        <div className="d-flex flex-column flex-sm-row justify-content-center mb-3">
                            <StableCoinSwapComponent
                                goldPrice={new BigNumber(context?.gsc_oracle_price || '0')
                                    .shiftedBy(-ORACLE_PRICE_DECIMALS_COUNT)
                                    .toNumber()}
                            />
                        </div>
                    </div>
                    <div className="active-pool-li-container">
                        <StableCoinPegDetails />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StableCoin;
