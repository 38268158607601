import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { InfoTooltip } from 'components';
import { TOKEN_INFO_MAP, USDC_TOKEN_ID } from 'config';
import { useVestaCommonHooks } from 'z/hooks';
import { MemeLiquidityVaultStatsContextType, MemeLiquidityVaultUserContextType } from 'z/types';
import { formatNumber, convertToDollarString, convertWeiToEsdt, parseBigNumber, BIG_NUMBER_ZERO } from 'z/utils';

interface MemeLiquidityVestaVaultInfoProps {
    vaultStatsContext?: MemeLiquidityVaultStatsContextType;
    vaultUserContext?: MemeLiquidityVaultUserContextType;
}

export const MemeLiquidityVestaVaultInfo = ({
    vaultStatsContext,
    vaultUserContext,
}: MemeLiquidityVestaVaultInfoProps) => {
    const { getTokenPrice } = useVestaCommonHooks();

    return (
        <Row className="mt-4 p-0">
            <Col md={6} lg={4} className="d-flex flex-column">
                <div className="second-card-style position-relative d-flex justify-content-center align-items-center h-100">
                    <div className="text-center">
                        <div style={{ fontSize: '1.875rem' }}>{`TVL ${convertToDollarString(
                            vaultStatsContext
                                ? convertWeiToEsdt(
                                      vaultStatsContext.total_standard_vesta_power,
                                      TOKEN_INFO_MAP[USDC_TOKEN_ID].decimals,
                                      TOKEN_INFO_MAP[USDC_TOKEN_ID].decimals,
                                  )
                                      .multipliedBy(2)
                                      .multipliedBy(getTokenPrice(USDC_TOKEN_ID))
                                : BIG_NUMBER_ZERO,
                        )}`}</div>
                        <InfoTooltip title="Total Value Locked" />
                    </div>
                </div>
            </Col>
            <Col md={6} lg={8} className="d-flex flex-column" style={{ fontSize: '1rem' }}>
                <div className="second-card-style px-3 align-items-center d-flex" style={{ height: '100%' }}>
                    <div className="d-flex justify-content-between" style={{ color: '#a349a2', flex: 1 }}>
                        <div>Meme Power:</div>
                        <div>
                            {formatNumber(
                                vaultUserContext
                                    ? convertWeiToEsdt(
                                          vaultUserContext.standard_vesta_power,
                                          TOKEN_INFO_MAP[USDC_TOKEN_ID].decimals,
                                          TOKEN_INFO_MAP[USDC_TOKEN_ID].decimals,
                                      ).toNumber()
                                    : 0,
                                3,
                            )}{' '}
                            |{' '}
                            {formatNumber(
                                vaultStatsContext
                                    ? convertWeiToEsdt(
                                          vaultStatsContext.total_standard_vesta_power,
                                          TOKEN_INFO_MAP[USDC_TOKEN_ID].decimals,
                                          TOKEN_INFO_MAP[USDC_TOKEN_ID].decimals,
                                      ).toNumber()
                                    : 0,
                                3,
                            )}{' '}
                            (
                            {formatNumber(
                                vaultStatsContext &&
                                    vaultUserContext &&
                                    !parseBigNumber(vaultStatsContext.total_standard_vesta_power).isZero()
                                    ? parseBigNumber(vaultUserContext.standard_vesta_power)
                                          .div(vaultStatsContext.total_standard_vesta_power)
                                          .multipliedBy(1_000)
                                          .toNumber()
                                    : 0,
                                3,
                            )}
                            ‰)
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
};
