import { ResultsParser } from '@multiversx/sdk-core/out';
import { FarmRewardsType } from 'z/types';
import { parseBigNumber } from 'z/utils';
import {
  elrondProvider,
  rawVestaMinterSmartContract,
} from './provider';

export async function getRewardDistributionData(): Promise<FarmRewardsType[]> {
  try {
    const interaction = rawVestaMinterSmartContract.methodsExplicit.getRewardDistributionData();
    const query = interaction.check().buildQuery();
    const queryResponse = await elrondProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } =
      new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      throw Error(returnMessage);
    }

    const values = firstValue.valueOf();
    const decoded = values.map((value: any) => ({
      farm_address: value.farm_address.toString(),
      is_snapshotting: value.is_snapshotting,
      farm_weight_share_percentage: parseBigNumber(value.farm_weight_share_percentage).div(10 ** 16).toNumber(),
      raw_vst_estimated_amount: value.raw_vst_estimated_amount.toFixed(0),
    }));

    return decoded;
  } catch (err) {
    console.log(err);
  }

  return [];
}
