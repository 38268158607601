import React, { useEffect, useState } from 'react';
import { useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';
import { Row, Col } from 'react-bootstrap';
import { GSC_TOKEN_ID, USDD_TOKEN_ID } from 'config';
import { getTokenLogo, getTokenSupplyFromApi } from 'z/elrond';
import { useStableCoinProtocol } from 'z/hooks/stable-coin-protocol';
import { ORACLE_PRICE_DECIMALS_COUNT } from '.';

export const StableCoinPegDetails = () => {
    const [totalGscSupply, setTotalGscSupply] = useState('0');
    const { hasPendingTransactions } = useGetPendingTransactions();
    const { context } = useStableCoinProtocol();

    useEffect(() => {
        if (hasPendingTransactions) {
            return;
        }

        getTokenSupplyFromApi(GSC_TOKEN_ID).then((supply) => {
            setTotalGscSupply(supply?.supply ?? '0');
        });
    }, [hasPendingTransactions]);
    return (
        <Row style={{ rowGap: '12px' }}>
            <Col md={6}>
                <div className="bordered-box d-flex flex-column justify-content-center align-items-center h-100">
                    {/* <div>
                        <strong>USDD Supply</strong>
                    </div> */}
                    <div style={{ fontSize: '18px' }}>USDD Supply</div>
                    {/* <div className="d-flex align-items-center gap-2">
                        {new BigNumber(context?.usdd_supply || '0').shiftedBy(-18).toString(10)}
                        <img src={getTokenLogo(GSC_TOKEN_ID)} width={'22px'} alt="USDD token" />
                    </div> */}
                    <div style={{ fontSize: '22px', fontWeight: '500' }}>
                        {new BigNumber(context?.usdd_supply || '0').shiftedBy(-18).toNumber().toLocaleString()}{' '}
                        <img src={getTokenLogo(USDD_TOKEN_ID)} width={'22px'} alt="USDD token" />
                    </div>
                </div>
            </Col>
            <Col md={6}>
                <div className="bordered-box d-flex flex-column justify-content-center align-items-center h-100">
                    <div style={{ fontSize: '18px' }}>USDD Backing</div>
                    <div style={{ fontSize: '22px', fontWeight: '500' }}>
                        {new BigNumber(context?.collateralization_ratio || '0')
                            .dividedBy(context?.percentage_denomination || '1')
                            .multipliedBy(100)
                            .toFixed(2)}
                        %
                    </div>
                </div>
            </Col>

            <Col md={4}>
                <div className="bordered-box d-flex flex-column justify-content-center align-items-center h-100">
                    <div style={{ fontSize: '18px' }}>Gold Price</div>
                    <div style={{ fontSize: '22px', fontWeight: '500' }}>
                        {new BigNumber(context?.gsc_oracle_price ?? '0')
                            .shiftedBy(-ORACLE_PRICE_DECIMALS_COUNT)
                            .toString(10)}{' '}
                        $
                    </div>
                </div>
            </Col>

            <Col md={4}>
                <div className="bordered-box d-flex flex-column justify-content-center align-items-center">
                    <div>
                        <strong>GSC Supply</strong>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                        {totalGscSupply}
                        <img src={getTokenLogo(GSC_TOKEN_ID)} width={'22px'} alt="gsc token" />
                    </div>
                    <div>
                        {new BigNumber(totalGscSupply)
                            .multipliedBy(
                                new BigNumber(context?.gsc_oracle_price ?? '0').shiftedBy(-ORACLE_PRICE_DECIMALS_COUNT),
                            )
                            .toNumber()
                            .toLocaleString()}{' '}
                        $
                    </div>
                </div>
            </Col>

            <Col md={4}>
                <div className="bordered-box d-flex flex-column justify-content-center align-items-center h-100">
                    <div>
                        <strong>sGSC Supply</strong>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                        {new BigNumber(context?.sgsc_supply || '0').shiftedBy(-18).toString(10)}
                        <img src={getTokenLogo(GSC_TOKEN_ID)} width={'22px'} alt="gsc token" />
                    </div>
                    <div>
                        {' '}
                        {new BigNumber(context?.sgsc_supply || '0')
                            .shiftedBy(-18)
                            .multipliedBy(
                                new BigNumber(context?.gsc_oracle_price ?? '0').shiftedBy(-ORACLE_PRICE_DECIMALS_COUNT),
                            )
                            .toNumber()
                            .toLocaleString()}{' '}
                        $
                    </div>
                </div>
            </Col>
        </Row>
    );
};
