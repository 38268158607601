import React, { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { Col, Row } from 'react-bootstrap';

import { InfoTooltip } from 'components';
import { DEB_PRECISION, OURO_TOKEN_ID, TOKEN_INFO_MAP } from 'config';
import { selectNetstats } from 'redux/reducers';
import { selectOuroLiquidityVault } from 'redux/reducers/ouro-liquidity-vault';
import { useAppSelector } from 'redux/store';
import { getTokenBalancesFromApi } from 'z/elrond';
import { useVestaCommonHooks } from 'z/hooks';
import { OuroLiquidityVaultStatsContextType, OuroLiquidityVaultUserContextType } from 'z/types';
import { formatNumber, convertToDollarString, convertWeiToEsdt, BIG_NUMBER_ZERO } from 'z/utils';

interface OuroLiquidityVestaVaultInfoProps {
    vaultStatsContext?: OuroLiquidityVaultStatsContextType;
    vaultUserContext?: OuroLiquidityVaultUserContextType;
}

export const OuroLiquidityVestaVaultInfo = ({
    vaultStatsContext,
    vaultUserContext,
}: OuroLiquidityVestaVaultInfoProps) => {
    const { getTokenPrice } = useVestaCommonHooks();
    const [ouroRewardsAmount, setOuroRewardsAmount] = useState(BIG_NUMBER_ZERO);
    const [userStandardPower, setUserStandardPower] = useState(BIG_NUMBER_ZERO);
    const [totalStandardPower, setTotalStandardPower] = useState(BIG_NUMBER_ZERO);
    const [userPremiumPower, setUserPremiumPower] = useState(BIG_NUMBER_ZERO);
    const [totalPremiumPower, setTotalPremiumPower] = useState(BIG_NUMBER_ZERO);

    const [majorGoldenPower, setMajorGoldenPower] = useState(BIG_NUMBER_ZERO);
    const [totalMajorGoldenPower, setTotalMajorGoldenPower] = useState(BIG_NUMBER_ZERO);
    const [majorSilverPower, setMajorSilverPower] = useState(BIG_NUMBER_ZERO);
    const [totalMajorSilverPower, setTotalMajorSilverPower] = useState(BIG_NUMBER_ZERO);
    const [majorBronzePower, setMajorBronzePower] = useState(BIG_NUMBER_ZERO);
    const [totalMajorBronzePower, setTotalMajorBronzePower] = useState(BIG_NUMBER_ZERO);

    const [minorGoldenPower, setMinorGoldenPower] = useState(BIG_NUMBER_ZERO);
    const [totalMinorGoldenPower, setTotalMinorGoldenPower] = useState(BIG_NUMBER_ZERO);
    const [minorSilverPower, setMinorSilverPower] = useState(BIG_NUMBER_ZERO);
    const [totalMinorSilverPower, setTotalMinorSilverPower] = useState(BIG_NUMBER_ZERO);
    const [minorBronzePower, setMinorBronzePower] = useState(BIG_NUMBER_ZERO);
    const [totalMinorBronzePower, setTotalMinorBronzePower] = useState(BIG_NUMBER_ZERO);

    const netstatsRedux = useAppSelector(selectNetstats);
    const selectOuroLiquidityRedux = useAppSelector(selectOuroLiquidityVault);

    useEffect(() => {
        getTokenBalancesFromApi('erd1qqqqqqqqqqqqqpgql9zuu0r5pj9xcx94y08wujmwkn2hcuns27rsdcmzf0', [OURO_TOKEN_ID]).then(
            (res: any) => setOuroRewardsAmount(new BigNumber(res[0]?.balance || '0')),
        );
    }, []);

    useEffect(() => {
        if (vaultUserContext) {
            setUserStandardPower(convertWeiToEsdt(vaultUserContext.standard_vesta_power));
        }
    }, [vaultUserContext]);

    useEffect(() => {
        if (vaultUserContext) {
            setUserPremiumPower(
                convertWeiToEsdt(vaultUserContext.standard_vesta_power)
                    .multipliedBy(netstatsRedux.deb || DEB_PRECISION)
                    .div(DEB_PRECISION),
            );
        }
    }, [vaultUserContext, netstatsRedux.deb]);

    useEffect(() => {
        if (vaultStatsContext) {
            setTotalStandardPower(convertWeiToEsdt(vaultStatsContext.total_standard_vesta_power));
        }
    }, [vaultStatsContext]);

    useEffect(() => {
        if (selectOuroLiquidityRedux.totalPremiumPower) {
            setTotalPremiumPower(convertWeiToEsdt(selectOuroLiquidityRedux.totalPremiumPower));
        }
    }, [selectOuroLiquidityRedux.totalPremiumPower]);

    useEffect(() => {
        if (selectOuroLiquidityRedux.userElitePowers && selectOuroLiquidityRedux.userContext) {
            setMajorGoldenPower(new BigNumber(selectOuroLiquidityRedux.userElitePowers.majorGoldenPower));
            setMajorSilverPower(new BigNumber(selectOuroLiquidityRedux.userElitePowers.majorSilverPower));
            setMajorBronzePower(new BigNumber(selectOuroLiquidityRedux.userElitePowers.majorBronzePower));
            setMinorGoldenPower(new BigNumber(selectOuroLiquidityRedux.userElitePowers.minorGoldenPower));
            setMinorSilverPower(new BigNumber(selectOuroLiquidityRedux.userElitePowers.minorSilverPower));
            setMinorBronzePower(new BigNumber(selectOuroLiquidityRedux.userElitePowers.minorBronzePower));
        }
    }, [selectOuroLiquidityRedux.userElitePowers]);

    useEffect(() => {
        if (selectOuroLiquidityRedux.totalElitePowers) {
            setTotalMajorGoldenPower(new BigNumber(selectOuroLiquidityRedux.totalElitePowers.totalMajorGoldenPower));
            setTotalMajorSilverPower(new BigNumber(selectOuroLiquidityRedux.totalElitePowers.totalMajorSilverPower));
            setTotalMajorBronzePower(new BigNumber(selectOuroLiquidityRedux.totalElitePowers.totalMajorBronzePower));
            setTotalMinorGoldenPower(new BigNumber(selectOuroLiquidityRedux.totalElitePowers.totalMinorGoldenPower));
            setTotalMinorSilverPower(new BigNumber(selectOuroLiquidityRedux.totalElitePowers.totalMinorSilverPower));
            setTotalMinorBronzePower(new BigNumber(selectOuroLiquidityRedux.totalElitePowers.totalMinorBronzePower));
        }
    }, [selectOuroLiquidityRedux.totalElitePowers]);

    return (
        <Row className="mt-4 p-0">
            <Col md={6} lg={4} className="d-flex flex-column">
                <div className="second-card-style position-relative d-flex justify-content-center align-items-center h-100">
                    <div className="text-center">
                        <div style={{ fontSize: '1.875rem' }}>{`TVL ${convertToDollarString(
                            vaultStatsContext
                                ? convertWeiToEsdt(
                                      vaultStatsContext.total_standard_vesta_power,
                                      TOKEN_INFO_MAP[OURO_TOKEN_ID].decimals,
                                      TOKEN_INFO_MAP[OURO_TOKEN_ID].decimals,
                                  )
                                      .multipliedBy(2)
                                      .multipliedBy(getTokenPrice(OURO_TOKEN_ID))
                                : 0,
                        )}`}</div>
                        <InfoTooltip title="Total Value Locked" />

                        <div className="mt-2" style={{ color: '#98A1C0', fontSize: '18px' }}>
                            Charging OUROBOROS Rewards
                        </div>
                        <div style={{ color: '#98A1C0', fontSize: '18px' }}>{`${formatNumber(
                            convertWeiToEsdt(ouroRewardsAmount, TOKEN_INFO_MAP[OURO_TOKEN_ID].decimals),
                        )} OURO`}</div>
                    </div>
                </div>
            </Col>
            <Col md={6} lg={8} className="d-flex flex-column" style={{ fontSize: '1rem' }}>
                <div className="second-card-style px-3">
                    <div className="d-flex justify-content-between" style={{ color: '#a349a2' }}>
                        <div>Standard Snake Power:</div>
                        <div>
                            {formatNumber(userStandardPower, 3)} | {formatNumber(totalStandardPower, 3)} (
                            {formatNumber(userStandardPower.div(totalStandardPower).multipliedBy(1_000), 3)}
                            ‰)
                        </div>
                    </div>
                    <div className="d-flex justify-content-between" style={{ color: '#c8bfe7' }}>
                        <div>Premium Snake Power:</div>
                        <div>
                            {formatNumber(userPremiumPower, 3)} | {formatNumber(totalPremiumPower, 3)} (
                            {formatNumber(userPremiumPower.div(totalPremiumPower).multipliedBy(1_000), 3)}
                            ‰)
                        </div>
                    </div>
                    <div className="d-flex justify-content-between" style={{ color: '#efe3af' }}>
                        <div>MAJOR Elite Snake Power:</div>
                        <div>
                            {`${formatNumber(
                                BigNumber.sum(majorBronzePower, majorSilverPower, majorGoldenPower),
                                3,
                            )} | ${formatNumber(
                                BigNumber.sum(totalMajorBronzePower, totalMajorSilverPower, totalMajorGoldenPower),
                                3,
                            )} (
                            ${formatNumber(
                                BigNumber.sum(majorBronzePower, majorSilverPower, majorGoldenPower)
                                    .div(
                                        BigNumber.sum(
                                            totalMajorBronzePower,
                                            totalMajorSilverPower,
                                            totalMajorGoldenPower,
                                        ),
                                    )
                                    .multipliedBy(1_000),
                                3,
                            )}
                            ‰)`}
                        </div>
                    </div>
                    <div className="d-flex justify-content-between" style={{ color: '#f59a48' }}>
                        <div>&nbsp;&nbsp;&nbsp;&nbsp;Bronze Elite Snake Power:</div>
                        <div>
                            {`${formatNumber(majorBronzePower, 3)} | ${formatNumber(totalMajorBronzePower, 3)} (
                            ${formatNumber(majorBronzePower.div(totalMajorBronzePower).multipliedBy(1_000), 3)}
                            ‰)`}
                        </div>
                    </div>
                    <div className="d-flex justify-content-between" style={{ color: '#a7a7a7' }}>
                        <div>&nbsp;&nbsp;&nbsp;&nbsp;Silver Elite Snake Power:</div>
                        <div>
                            {`${formatNumber(majorSilverPower, 3)} | ${formatNumber(totalMajorSilverPower, 3)} (
                            ${formatNumber(majorSilverPower.div(totalMajorSilverPower).multipliedBy(1_000), 3)}
                            ‰)`}
                        </div>
                    </div>
                    <div className="d-flex justify-content-between" style={{ color: '#fdd500' }}>
                        <div>&nbsp;&nbsp;&nbsp;&nbsp;Golden Elite Snake Power:</div>
                        <div>
                            {`${formatNumber(majorGoldenPower, 3)} | ${formatNumber(totalMajorGoldenPower, 3)} (
                            ${formatNumber(majorGoldenPower.div(totalMajorGoldenPower).multipliedBy(1_000), 3)}
                            ‰)`}
                        </div>
                    </div>
                    <div className="d-flex justify-content-between" style={{ color: '#efe3af' }}>
                        <div>MINOR Elite Snake Power:</div>
                        <div>
                            {`${formatNumber(
                                BigNumber.sum(minorBronzePower, minorSilverPower, minorGoldenPower),
                                3,
                            )} | ${formatNumber(
                                BigNumber.sum(totalMinorBronzePower, totalMinorSilverPower, totalMinorGoldenPower),
                                3,
                            )} (
                            ${formatNumber(
                                BigNumber.sum(minorBronzePower, minorSilverPower, minorGoldenPower)
                                    .div(
                                        BigNumber.sum(
                                            totalMinorBronzePower,
                                            totalMinorSilverPower,
                                            totalMinorGoldenPower,
                                        ),
                                    )
                                    .multipliedBy(1_000),
                                3,
                            )}
                            ‰)`}
                        </div>
                    </div>
                    <div className="d-flex justify-content-between" style={{ color: '#f59a48' }}>
                        <div>&nbsp;&nbsp;&nbsp;&nbsp;Bronze Multiversal Elite Snake Power:</div>
                        <div>
                            {`${formatNumber(minorBronzePower, 3)} | ${formatNumber(totalMinorBronzePower, 3)} (
                            ${formatNumber(minorBronzePower.div(totalMinorBronzePower).multipliedBy(1_000), 3)}
                            ‰)`}
                        </div>
                    </div>
                    <div className="d-flex justify-content-between" style={{ color: '#a7a7a7' }}>
                        <div>&nbsp;&nbsp;&nbsp;&nbsp;Silver Multiversal Elite Snake Power:</div>
                        <div>
                            {`${formatNumber(minorSilverPower, 3)} | ${formatNumber(totalMinorSilverPower, 3)} (
                            ${formatNumber(minorSilverPower.div(totalMinorSilverPower).multipliedBy(1_000), 3)}
                            ‰)`}
                        </div>
                    </div>
                    <div className="d-flex justify-content-between" style={{ color: '#fdd500' }}>
                        <div>&nbsp;&nbsp;&nbsp;&nbsp;Golden Multiversal Elite Snake Power:</div>
                        <div>
                            {`${formatNumber(minorGoldenPower, 3)} | ${formatNumber(totalMinorGoldenPower, 3)} (
                            ${formatNumber(minorGoldenPower.div(totalMinorGoldenPower).multipliedBy(1_000), 3)}
                            ‰)`}
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
};
