import { NftType } from '@multiversx/sdk-dapp/types/tokens.types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NFT_STAKING_CONFIG, UNBONDING_TIMESTAMP } from 'config/nft-staking';
import { RootState } from 'redux/store';
import { EsdtTokenPaymentType } from 'z/types';
import { NftStakingPoolTypeEnum } from 'z/types/nft-staking';

export interface NftStakingState {
  allStakedNfts: NftType[];
  allEligibleWalletNfts: NftType[];
  userData: UserData;
  stakedSnakes: number;
  stakedShares: number;
}

const initialState: NftStakingState = {
  allStakedNfts: [],
  allEligibleWalletNfts: [],
  userData: {
    stake_snapshot: {
      address: '',
      staked_assets: [],
      unbonding_assets: [],
    },
    pending_rewards: [],
  },
  stakedShares: 0,
  stakedSnakes: 0,
};

export const nftStakingSlice = createSlice({
  name: 'nftStaking',
  initialState,
  reducers: {
    setAllStakedNfts: (state, action: PayloadAction<NftType[]>) => {
      state.allStakedNfts = action.payload;
    },
    setEligibleAccountNfts: (state, action: PayloadAction<NftType[]>) => {
      state.allEligibleWalletNfts = action.payload;
    },
    setUserStakingData: (state, action: PayloadAction<UserData>) => {
      state.userData = action.payload;
    },
    setStakedAssetsCount: (
      state,
      action: PayloadAction<{ snakes: number; shares: number }>,
    ) => {
      state.stakedSnakes = action.payload.snakes;
      state.stakedShares = action.payload.shares;
    },
  },
});

export const {
  setAllStakedNfts,
  setUserStakingData,
  setEligibleAccountNfts,
  setStakedAssetsCount,
} = nftStakingSlice.actions;

export const selectStakedNfts = (
  state: RootState,
  pool_type: NftStakingPoolTypeEnum,
): NftType[] => {
  const config = NFT_STAKING_CONFIG.find((cfg) => cfg.poolType === pool_type);
  if (!config) {
    return [];
  }
  const filtered =
    state.nftStaking.userData.stake_snapshot.staked_assets.filter(
      (nft) =>
        nft.collection === config.tokenIdentifier &&
        (config.nonce ? config.nonce === nft.nonce : true),
    );

  return filtered;
};

export const selectAllStakedNfts = (
  state: RootState,
  pool_type: NftStakingPoolTypeEnum,
): NftType[] => {
  const config = NFT_STAKING_CONFIG.find((cfg) => cfg.poolType === pool_type);
  if (!config) {
    return [];
  }
  const filtered = state.nftStaking.allStakedNfts.filter(
    (nft) =>
      nft.collection === config.tokenIdentifier &&
      (config.nonce ? config.nonce === nft.nonce : true),
  );

  return filtered;
};

export const selectHasUnbondedAssets = (state: RootState): boolean => {
  const unbondedAssets =
    state.nftStaking.userData.stake_snapshot.unbonding_assets.filter(
      (batch) => batch.timestamp * 1000 + UNBONDING_TIMESTAMP < Date.now(),
    );
  return unbondedAssets.length > 0;
};

export const selectUnbondingNfts = (
  state: RootState,
  pool_type: NftStakingPoolTypeEnum,
): UnbondingAssetsBatch[] => {
  const config = NFT_STAKING_CONFIG.find((cfg) => cfg.poolType === pool_type);
  if (!config) {
    return [];
  }
  return state.nftStaking.userData.stake_snapshot.unbonding_assets.filter(
    (batch) =>
      batch.timestamp * 1000 + UNBONDING_TIMESTAMP < Date.now() &&
      batch.items.find(
        (nft) =>
          nft.collection === config.tokenIdentifier &&
          (config.nonce ? config.nonce === nft.nonce : true),
      ) !== undefined,
  );
};

export const selectPendingRewards = (
  state: RootState,
): EsdtTokenPaymentType[] => {
  return state.nftStaking.userData.pending_rewards;
};

export const selectEligibleNfts = (
  state: RootState,
  poolType: NftStakingPoolTypeEnum | undefined,
) => {
  if (poolType === undefined) {
    return [];
  }
  const poolConfig = NFT_STAKING_CONFIG.find(
    (cfg) => cfg.poolType === poolType,
  );
  if (poolConfig === undefined) {
    return [];
  }
  return state.nftStaking.allEligibleWalletNfts.filter(
    (nft) =>
      nft.collection === poolConfig.tokenIdentifier &&
      (poolConfig.nonce !== undefined ? nft.nonce === poolConfig.nonce : true),
  );
};

export const selectUserScore = (
  state: RootState,
  pool_type: NftStakingPoolTypeEnum,
) => {
  if (pool_type === NftStakingPoolTypeEnum.SnakesSfts) {
    const nfts = selectStakedNfts(state, pool_type);
    return nfts.reduce(
      (crt, prev) =>
        (crt += (prev.nonce === 1 ? 100 : 1) * parseInt(prev.balance)),
      0,
    );
  }

  if (pool_type === NftStakingPoolTypeEnum.SharesSfts) {
    return state.nftStaking.userData.stake_snapshot.staked_assets.reduce(
      (crt, prev) =>
        (crt += (prev.nonce === 1 ? 500 : 1) * parseInt(prev.balance)),
      0,
    );
  }

  return 0;
};

export const selectGlobalScore = (
  state: RootState,
  pool_type: NftStakingPoolTypeEnum,
) => {
  if (pool_type === NftStakingPoolTypeEnum.SnakesSfts) {
    return state.nftStaking.stakedSnakes * 100;
  }

  if (pool_type === NftStakingPoolTypeEnum.SharesSfts) {
    return state.nftStaking.stakedSnakes * 500 + state.nftStaking.stakedShares;
  }

  return 0;
};

export const selectTotalUserSupply = (
  state: RootState,
  pool_type: NftStakingPoolTypeEnum,
) => {
  const config = NFT_STAKING_CONFIG.find((cfg) => cfg.poolType === pool_type);
  if (!config) {
    return 0;
  }
  const stakedNfts =
    state.nftStaking.userData.stake_snapshot.staked_assets.filter(
      (nft) =>
        nft.collection === config.tokenIdentifier &&
        (config.nonce ? config.nonce === nft.nonce : true),
    );
  const walletNfts = state.nftStaking.allEligibleWalletNfts.filter(
    (nft) =>
      nft.collection === config.tokenIdentifier &&
      (config.nonce ? config.nonce === nft.nonce : true),
  );

  return stakedNfts
    .concat(walletNfts)
    .reduce((crt, prev) => (crt += parseInt(prev.balance)), 0);
};

export const nftStakingReducer = nftStakingSlice.reducer;

export interface UnbondingAssetsBatch {
  timestamp: number;
  items: NftType[];
}

export interface SnapshotEntry {
  address: string;
  staked_assets: NftType[];
  unbonding_assets: UnbondingAssetsBatch[];
}

export interface UserData {
  stake_snapshot: SnapshotEntry;
  pending_rewards: EsdtTokenPaymentType[];
}
