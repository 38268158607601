import React from 'react';
import { useGetAccountInfo, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import { Col, Row } from 'react-bootstrap';

import { TOKEN_INFO_MAP } from 'config';
import { selectNetstats } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { vaultClaimAuxiliaryStandardReward } from 'z/elrond';
import { useVestaCommonHooks } from 'z/hooks';
import {
    MemeLiquidityVaultBaseContextType,
    MemeLiquidityVaultStatsContextType,
    MemeLiquidityVaultUserContextType,
} from 'z/types';
import {
    ERROR_CONNECT_WALLET,
    ERROR_SC_DATA_NOT_LOADED,
    ERROR_TRANSACTION_ONGOING,
    convertToDollarString,
    convertWeiToEsdt,
    formatNumber,
    parseBigNumber,
    printWalletBalanceAndPrice,
    toastError,
} from 'z/utils';

interface MemeLiquidityVestaAuxiliaryStandardRewardsParams {
    vaultUserContext?: MemeLiquidityVaultUserContextType;
    vaultStatsContext?: MemeLiquidityVaultStatsContextType;
    vaultBaseContext?: MemeLiquidityVaultBaseContextType;
}

export const MemeLiquidityVestaAuxiliaryStandardRewards = ({
    vaultUserContext,
    vaultStatsContext,
    vaultBaseContext,
}: MemeLiquidityVestaAuxiliaryStandardRewardsParams) => {
    const { address } = useGetAccountInfo();
    const { hasPendingTransactions } = useGetPendingTransactions();

    const netstatsRedux = useAppSelector(selectNetstats);

    const { getTokenPrice } = useVestaCommonHooks();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async function onClaimAuxiliaryStandardReward() {
        let error = '';
        if (!address) {
            error = ERROR_CONNECT_WALLET;
        } else if (hasPendingTransactions) {
            error = ERROR_TRANSACTION_ONGOING;
        } else if (!vaultUserContext) {
            error = ERROR_SC_DATA_NOT_LOADED;
        } else if (
            vaultUserContext.auxiliary_standard_reward_payments.reduce(
                (prev, reward) => prev && parseBigNumber(reward.amount).isZero(),
                true,
            )
        ) {
            error = 'No reward';
        }
        if (error) {
            toastError(error);
            return;
        }

        await vaultClaimAuxiliaryStandardReward();
    }

    return (
        <Row className="mt-4 p-0">
            <Col>
                <Row
                    className="m-0 py-3 px-1 g-2"
                    style={{
                        border: '1px solid #efe3af',
                        borderRadius: '10px',
                    }}
                >
                    <div className="col-12 text-center mb-3" style={{ color: '#efe3af' }}>
                        {`Snake Tokens (USDC, OURO) Rewards are governed by Meme Power. Your share of all Meme Power is ${
                            vaultUserContext &&
                            vaultStatsContext &&
                            parseBigNumber(vaultStatsContext.total_standard_vesta_power).isGreaterThan(0)
                                ? formatNumber(
                                      parseBigNumber(vaultUserContext.standard_vesta_power)
                                          .div(vaultStatsContext.total_standard_vesta_power)
                                          .multipliedBy(1000),
                                      3,
                                  )
                                : '0.000'
                        }‰.`}
                    </div>
                    <Col md={6} lg={4} className="d-flex flex-column px-1">
                        <div
                            className="second-card-style mb-0 d-flex flex-column align-items-between gap-2 px-3"
                            style={{ backgroundColor: '#1B0921' }}
                        >
                            {vaultBaseContext &&
                                vaultBaseContext.auxiliary_standard_reward_token_ids.map((rewardTokenId) => (
                                    <div
                                        className="d-flex align-items-center"
                                        key={`auxiliary-standard-reward-token-${rewardTokenId}`}
                                    >
                                        <img
                                            src={TOKEN_INFO_MAP[rewardTokenId].logo}
                                            style={{ height: '3rem', width: 'auto' }}
                                        />
                                        <div className="ms-2">
                                            {printWalletBalanceAndPrice(netstatsRedux, rewardTokenId, getTokenPrice)}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </Col>
                    <Col md={6} lg={8} className="d-flex flex-column px-1" style={{ fontSize: '1rem' }}>
                        <div
                            className="second-card-style mb-0 d-flex justify-content-between align-items-center px-3 h-100"
                            style={{ backgroundColor: '#1B0921' }}
                        >
                            <div className="d-flex flex-column align-items-between gap-2">
                                {vaultBaseContext &&
                                    vaultBaseContext.auxiliary_standard_reward_token_ids.map((rewardTokenId) => {
                                        const tokenRewatdPayment = (vaultUserContext &&
                                            vaultUserContext.auxiliary_standard_reward_payments.find(
                                                ({ token_identifier }) => token_identifier == rewardTokenId,
                                            )) || {
                                            token_identifier: rewardTokenId,
                                            token_nonce: 0,
                                            amount: 0,
                                        };

                                        return (
                                            <div
                                                className="d-flex align-items-center"
                                                key={`auxiliary-standard-user-reward-token-${rewardTokenId}`}
                                            >
                                                <img
                                                    src={TOKEN_INFO_MAP[rewardTokenId].logo}
                                                    style={{ height: '3rem', width: 'auto' }}
                                                />
                                                <div className="ms-2">
                                                    {formatNumber(
                                                        convertWeiToEsdt(
                                                            tokenRewatdPayment.amount,
                                                            TOKEN_INFO_MAP[tokenRewatdPayment.token_identifier]
                                                                .decimals,
                                                            TOKEN_INFO_MAP[tokenRewatdPayment.token_identifier]
                                                                .decimals,
                                                        ),
                                                        TOKEN_INFO_MAP[tokenRewatdPayment.token_identifier].decimals,
                                                    )}{' '}
                                                    (
                                                    {convertToDollarString(
                                                        convertWeiToEsdt(
                                                            tokenRewatdPayment.amount,
                                                            TOKEN_INFO_MAP[tokenRewatdPayment.token_identifier]
                                                                .decimals,
                                                            TOKEN_INFO_MAP[tokenRewatdPayment.token_identifier]
                                                                .decimals,
                                                        ).multipliedBy(
                                                            getTokenPrice(tokenRewatdPayment.token_identifier),
                                                        ),
                                                    )}
                                                    )
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                            <div>
                                <button
                                    className="farm_but py-2"
                                    style={{ width: '6rem' }}
                                    disabled={hasPendingTransactions}
                                    onClick={onClaimAuxiliaryStandardReward}
                                >
                                    Claim
                                </button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};
