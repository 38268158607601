import React, { useEffect, useState } from 'react';
import { TokenTransfer } from '@multiversx/sdk-core/out';
import {
    useGetAccount,
    useGetPendingTransactions,
} from '@multiversx/sdk-dapp/hooks';
import clsx from 'clsx';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import { NoTokenBox } from 'components/Elements';
import { VEGLD_TOKEN_ID } from 'config';
import { selectNetstats } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { routeNames } from 'routes';
import {
  getLockedTokenAmounts,
  getTokenBalanceFromApi,
  getTokenLogo,
  getUnlockedTokenAmounts,
  liquidLockerLock,
  liquidLockerUnbond,
  liquidLockerUnlock,
} from 'z/elrond';
import { UnlockedTokenType } from 'z/types';
import {
  formatNumber,
  convertEsdtToWei,
  createTokenTicker,
  convertWeiToEsdt,
  DEFAULT_DECIMALS,
  ERROR_CONNECT_WALLET,
  ERROR_INVALID_NUMBER,
  ERROR_NOT_ENOUGH_BALANCE,
  ERROR_TRANSACTION_ONGOING,
  isPositiveBigNumber,
  isPositiveOrZeroBigNumber,
  toastError,
  ZERO_STRING,
} from 'z/utils';

const tabs = ['Lock', 'Unlock', 'Unbond'];
const tabRoutes = ['', 'unlock', 'unbond'];

export function LiquidLocker() {
  const { address } = useGetAccount();
  const { hasPendingTransactions } = useGetPendingTransactions();
  const navigate = useNavigate();

  const netstatsRedux = useAppSelector(selectNetstats);
  // const swapRedux = useAppSelector(selectSwap);

  const { selectedTab } = useParams();
  const tab = selectedTab == tabRoutes[1] ? 1 : selectedTab == tabRoutes[2] ? 2 : 0;
  function gotoTab(index: number) {
      if (hasPendingTransactions) return;
      navigate(routeNames.liquidLocker + (index > 0 ? '/'+ tabRoutes[index] : ''));
  }

  const targetTokenId = VEGLD_TOKEN_ID;
  const [targetTokenBalance, setTargetTokenBalance] = useState<string>(ZERO_STRING);
  const [lockTokenAmount, setLockTokenAmount] = useState<string>(ZERO_STRING);
  const [lockTokenAmountError, setLockTokenAmountError] = useState<string>('');
  const [unlockTokenAmount, setUnlockTokenAmount] = useState<string>(ZERO_STRING);
  const [unlockTokenAmountError, setUnlockTokenAmountError] = useState<string>('');

  const [lockedTargetTokenAmount, setLockedTargetTokenAmount] = useState<string>(ZERO_STRING);
  const [unlockedTokens, setUnlockedTokens] = useState<UnlockedTokenType[]>([]);
  const [unbondable, setUnbondable] = useState<boolean>(false);

  useEffect(() => {
    if (!address || hasPendingTransactions) return;
    (async () => {
      const _tokenBalanceInfo = await getTokenBalanceFromApi(address, targetTokenId);
      setTargetTokenBalance(_tokenBalanceInfo? _tokenBalanceInfo.balance : ZERO_STRING);
    })();

    (async () => {
      const _lockedTokenPayments = await getLockedTokenAmounts(address);
      let _ltta = ZERO_STRING;
      for (const payment of _lockedTokenPayments) {
        if (payment.token_identifier == targetTokenId) {
          _ltta = payment.amount;
          break;
        }
      }
      setLockedTargetTokenAmount(_ltta);
    })();

    (async () => {
      const _unlockedTokens = await getUnlockedTokenAmounts(address);

      let _unbondable = false;
      const _unlockedTargetTokens = _unlockedTokens.filter(ut => {
        if (!_unbondable && netstatsRedux.elrondStats && ut.unbond_epoch <= netstatsRedux.elrondStats.epoch) {
          _unbondable = true;
        }
        return ut.token.token_identifier == targetTokenId;
      });
      
      setUnlockedTokens(_unlockedTargetTokens);
      setUnbondable(_unbondable);
    })();
  }, [address, hasPendingTransactions]);

  useEffect(() => {
    if (hasPendingTransactions) return;

    setLockTokenAmount(ZERO_STRING);
    setUnlockTokenAmount(ZERO_STRING);
  }, [hasPendingTransactions]);

  function onChangeLockTokenAmount(value: string) {
    let error = '';
    if (!address) {
      error = ERROR_CONNECT_WALLET;
    } else if (!isPositiveOrZeroBigNumber(value)) {
        error = ERROR_INVALID_NUMBER;
    } else if (convertEsdtToWei(value).comparedTo(targetTokenBalance) > 0) {
        error = ERROR_NOT_ENOUGH_BALANCE;
    }
    
    setLockTokenAmountError(error);
    setLockTokenAmount(value);
    
    return error;
  }

  function onMaxLockTokenAmount() {
    if (address) {
      onChangeLockTokenAmount(convertWeiToEsdt(targetTokenBalance).toFixed());
    }
  }

  function onChangeUnlockTokenAmount(value: string) {
    let error = '';
    if (!address) {
      error = ERROR_CONNECT_WALLET;
    } else if (!isPositiveOrZeroBigNumber(value)) {
        error = ERROR_INVALID_NUMBER;
    } else if (convertEsdtToWei(value).comparedTo(lockedTargetTokenAmount) > 0) {
        error = ERROR_NOT_ENOUGH_BALANCE;
    }
    
    setUnlockTokenAmountError(error);
    setUnlockTokenAmount(value);
    
    return error;
  }

  function onMaxUnlockTokenAmount() {
    if (address) {
      onChangeUnlockTokenAmount(convertWeiToEsdt(lockedTargetTokenAmount, DEFAULT_DECIMALS, DEFAULT_DECIMALS).toFixed());
    }
  }

  async function lock() {
    let error = '';
    if (!address) {
      error = ERROR_CONNECT_WALLET;
    } else if (!isPositiveBigNumber(lockTokenAmount)) {
      error = ERROR_INVALID_NUMBER;
    } else if (hasPendingTransactions) {
      error = ERROR_TRANSACTION_ONGOING;
    } else {
      error = lockTokenAmountError;
    }

    if (error) {
        toastError(error);
        return;
    }

    await liquidLockerLock(
      TokenTransfer.fungibleFromBigInteger(targetTokenId, convertEsdtToWei(lockTokenAmount)),
    );
  }

  async function unlock() {
    let error = '';
    if (!address) {
      error = ERROR_CONNECT_WALLET;
    } else if (!isPositiveBigNumber(unlockTokenAmount)) {
      error = ERROR_INVALID_NUMBER;
    } else if (hasPendingTransactions) {
      error = ERROR_TRANSACTION_ONGOING;
    } else {
      error = unlockTokenAmountError;
    }

    if (error) {
      toastError(error);
      return;
    }

    await liquidLockerUnlock(
      TokenTransfer.fungibleFromBigInteger(targetTokenId, convertEsdtToWei(unlockTokenAmount)),
    );
  }

  async function unbond() {
    let error = '';
    if (!address) {
      error = ERROR_CONNECT_WALLET;
    } else if (hasPendingTransactions) {
      error = ERROR_TRANSACTION_ONGOING;
    } else if (!unbondable) {
      error = 'No unbondable token';
    }

    if (error) {
      toastError(error);
      return;
    }

    await liquidLockerUnbond(
      targetTokenId,
    );
  }

  return (
    <>
      <div className='container' style={{ marginTop: '50px' }}>
        <div className="d-flex justify-content-center">
          <div className="tab-box">
            <div
              className={clsx(
                  'tab-focus',
                  tab === 0
                      ? 'tab-focus-left-0'
                      : tab === 1
                          ? 'tab-focus-left-33'
                          : 'tab-focus-left-66',
              )}
            />
            {tabs.map((row, index) => {
              return (
                <div
                    className="tab"
                    key={`p-t-h-${index}`}
                    onClick={() => gotoTab(index)}
                >
                    {row}
                </div>
              );
            })}
          </div>
        </div>

        <div className='d-flex justify-content-center mt-4'>
          <div className='vesta_x_pool_card' style={{ width: '500px' }}>
              <div className='d-flex justify-content-center align-items-center'>
                <span style={{ color: '#F1DC46', fontSize: '1.2rem' }}>
                  {`xLaunchpad ${tabs[tab]}`}
                </span>
              </div>

              <div className="row mt-4" style={{ rowGap: '20px' }}>
                <div className="col-12">
                  {
                    tab === 0 && (<>
                      <div className="d-flex flex-column gap-1 mt-2">
                        <div className="vesta_x_swap_input_box BToken_Input">
                          <div className="d-flex align-items-center">
                              <input
                                  className="swap_input"
                                  type="number"
                                  value={lockTokenAmount}
                                  onChange={(e) => onChangeLockTokenAmount(e.target.value)}
                              />
                              <button className="add-liquidity-selected-token-buttton">
                                  <img src={getTokenLogo(targetTokenId)} alt="logo" width="30px" />
                                  {createTokenTicker(targetTokenId)}
                              </button>
                          </div>
                            <div className="d-flex justify-content-between mt-1">
                                <div className="input-token-error">{lockTokenAmountError}</div>
                                <div
                                  className="add-liquidity-input-token-balance-box"
                                  onClick={onMaxLockTokenAmount}
                                >
                                    <div className="">Balance:&nbsp;</div>
                                    <div style={{ color: 'white' }}>
                                      {formatNumber(convertWeiToEsdt(targetTokenBalance))}
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>

                      <div className="swap-info-box" style={{ marginTop: '3px' }}>
                        <div className="swap-info mt-2">
                            <div className="swap-info-li mb-0">
                                <div>You have locked</div>
                                <div>
                                  {`${formatNumber(convertWeiToEsdt(lockedTargetTokenAmount))} ${createTokenTicker(targetTokenId)}`}
                                </div>
                            </div>
                        </div>
                      </div>

                      <div className="d-flex just-content-center">
                        <button
                          className="mt-4 add-liquidity-button"
                          onClick={lock}
                          disabled={hasPendingTransactions}
                        >
                          Lock
                        </button>
                      </div>
                    </>)
                  }

                  {
                    tab === 1 && (<>
                      <div className="d-flex flex-column gap-1 mt-2">
                        <div className="vesta_x_swap_input_box BToken_Input">
                          <div className="d-flex align-items-center">
                              <input
                                  className="swap_input"
                                  type="number"
                                  value={unlockTokenAmount}
                                  onChange={(e) => onChangeUnlockTokenAmount(e.target.value)}
                              />
                              <button className="add-liquidity-selected-token-buttton">
                                  <img src={getTokenLogo(targetTokenId)} alt="logo" width="30px" />
                                  {createTokenTicker(targetTokenId)}
                              </button>
                          </div>
                            <div className="d-flex justify-content-between mt-1">
                                <div className="input-token-error">{unlockTokenAmountError}</div>
                                <div
                                  className="add-liquidity-input-token-balance-box"
                                  onClick={onMaxUnlockTokenAmount}
                                >
                                    <div className="">Locked:&nbsp;</div>
                                    <div style={{ color: 'white' }}>
                                      {formatNumber(convertWeiToEsdt(lockedTargetTokenAmount))}
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>

                      <div className="swap-info-box" style={{ marginTop: '3px' }}>
                        <div className="swap-info mt-2">
                            <div className="swap-info-li mb-0">
                                <div>You have locked</div>
                                <div>
                                  {`${formatNumber(convertWeiToEsdt(lockedTargetTokenAmount))} ${createTokenTicker(targetTokenId)}`}
                                </div>
                            </div>
                        </div>
                      </div>

                      <div className="d-flex just-content-center">
                        <button
                          className="mt-4 add-liquidity-button"
                          onClick={unlock}
                          disabled={hasPendingTransactions}
                        >
                          Unlock
                        </button>
                      </div>
                    </>)
                  }

                  {
                    tab === 2 && (<>
                      <div
                          className="mt-4"
                          style={{ color: 'white', fontSize: '1.1rem' }}
                      >
                        Unlocking Tokens
                      </div>

                      <div className="swap-info-box-round mt-2">
                          {
                            unlockedTokens.length > 0 ? unlockedTokens.map((unlockedToken, index) => (
                              <div className="swap-info-li mb-0" key={index}>
                                <div>
                                  {`${formatNumber(convertWeiToEsdt(unlockedToken.token.amount))} ${createTokenTicker(unlockedToken.token.token_identifier)}`}
                                </div>
                                <div>{netstatsRedux.elrondStats ? ((v) => v > 0 ? `${v} days left` : 'Unlockable')(unlockedToken.unbond_epoch - netstatsRedux.elrondStats.epoch) : '-' }</div>
                              </div>
                            )) : <NoTokenBox />
                          }
                      </div>

                      <div className="d-flex just-content-center">
                        <button
                          className="mt-4 add-liquidity-button"
                          onClick={unbond}
                          disabled={hasPendingTransactions || !unbondable}
                        >
                          Unbond
                        </button>
                      </div>
                    </>)
                  }
                </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
}
