import { Address, Interaction, ResultsParser } from '@multiversx/sdk-core/out';
import { useGetAccountInfo, useGetNetworkConfig } from '@multiversx/sdk-dapp/hooks';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { SendTransactionReturnType } from '@multiversx/sdk-dapp/types';
import { elrondProvider } from 'z/elrond';

export const useCommonMvxHooks = () => {
    const { chainID } = useGetNetworkConfig();
    const { address } = useGetAccountInfo();

    const sendTransaction = async (
        transactionInteraction: Interaction,
        txName?: string,
    ): Promise<{ sessionId: SendTransactionReturnType; error: SendTransactionReturnType }> => {
        const tx = transactionInteraction
            .withSender(new Address(address))
            .withChainID(chainID)
            .check()
            .buildTransaction();

        return await sendTransactions({
            transactions: [tx],
            transactionsDisplayInfo: {
                processingMessage: `Processing ${txName} Request`,
                errorMessage: `Error occured during ${txName} Request`,
                successMessage: `${txName} Request Successful`,
            },
            redirectAfterSign: false,
        });
    };

    const queryContract = async (interaction: Interaction) => {
        const query = interaction.check().buildQuery();
        const queryResponse = await elrondProvider.queryContract(query);
        const endpointDefinition = interaction.getEndpoint();
        const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(
            queryResponse,
            endpointDefinition,
        );

        if (!firstValue || !returnCode.isSuccess()) {
            throw Error(returnMessage);
        }

        return firstValue.valueOf();
    };

    return { sendTransaction, queryContract };
};
