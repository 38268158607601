import { useEffect } from 'react';
import { useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import { throttle } from 'lodash';
import { ISSUE_ESDT_PRICE, OURO_TOKEN_ID, SLIP_SC_ADDRESS, USDD_TOKEN_ID } from 'config';
import { CREATE_SLIP_POOL_GAS_LIMIT } from 'config/slip';
import {
    selectSlip,
    setContext,
    setMaxBaseTokenAmountThatCanBeAddedIntoSlip,
    setOuroAmount,
    setSlipPools,
} from 'redux/reducers';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { createSmartContract, getTokenBalanceFromApi } from 'z/elrond';
import { SlipContextType, SlipPoolType, SlipPoolTypeEnum, SlipStateEnum } from 'z/types';
import slipAbiJson from '../elrond/abi/vesta-slip-sc.abi.json';
import { useCommonMvxHooks } from './common-mvx';

export const slipSmartContract = createSmartContract(slipAbiJson, SLIP_SC_ADDRESS);

const throttleFetchDefault = throttle(async (fetchFunction: () => Promise<void>) => fetchFunction(), 5000, {
    trailing: false,
});
const throttleFetchAfterTransaction = throttle(async (fetchFunction: () => Promise<void>) => fetchFunction(), 5000, {
    trailing: false,
});

export const useSlipHooks = () => {
    const { hasPendingTransactions } = useGetPendingTransactions();
    const { queryContract, sendTransaction } = useCommonMvxHooks();
    const dispatch = useAppDispatch();

    const { context, basePool, pools, ouroAmount, maxBaseTokenAmountThatCanBeAddedIntoSlip } =
        useAppSelector(selectSlip);

    useEffect(() => {
        throttleFetchDefault(async () => {
            if (!context) {
                const _context = await getSlipContext();
                dispatch(setContext(_context));
            }

            if (!pools.length) {
                const _slipPools = await getSlipPools();
                dispatch(setSlipPools(_slipPools));
            }
        });
    }, []);

    useEffect(() => {
        throttleFetchAfterTransaction(async () => {
            if (hasPendingTransactions) return;

            const res = await getTokenBalanceFromApi(SLIP_SC_ADDRESS, OURO_TOKEN_ID);
            if (res) {
                dispatch(setOuroAmount(res.balance));
            }

            const _maxBaseTokenAmountThatCanBeAddedIntoSlip = await getMaxBaseTokenAmountThatCanBeAddedIntoSlip();
            dispatch(setMaxBaseTokenAmountThatCanBeAddedIntoSlip(_maxBaseTokenAmountThatCanBeAddedIntoSlip));
        });
    }, [hasPendingTransactions]);

    const getSlipContext = async (): Promise<SlipContextType | undefined> => {
        try {
            const interaction = slipSmartContract.methods.getContext();
            const values = await queryContract(interaction);
            return {
                ...values,
                vesta_redirect_lp_address: values.vesta_redirect_lp_address.bech32(),
                vesta_vault_lp_address: values.vesta_vault_lp_address.bech32(),
            };
        } catch (err) {
            console.error('getSlipContext', err);
        }
    };

    const getSlipPools = async (): Promise<SlipPoolType[]> => {
        try {
            const interaction = slipSmartContract.methods.getPools();
            const values = await queryContract(interaction);
            const decoded = values.map(
                (value: {
                    token_id: string;
                    frozen_lp_token_id: string;
                    token_id_vegld_swap_pool_address: {
                        bech32(): string;
                    };
                    token_id_ouro_swap_pool_address: {
                        bech32(): string;
                    };
                    is_paused: boolean;
                    is_removed: boolean;
                    pool_type: { name: SlipPoolTypeEnum };
                }): SlipPoolType => ({
                    tokenIdentifier: value.token_id,
                    frozenLpTokenIdentifier: value.frozen_lp_token_id,
                    vegldSwapPoolAddress: value.token_id_vegld_swap_pool_address.bech32(),
                    ouroSwapPoolAddress: value.token_id_ouro_swap_pool_address.bech32(),
                    status: value.is_removed
                        ? SlipStateEnum.Removed
                        : value.is_paused
                        ? SlipStateEnum.Inactive
                        : SlipStateEnum.Active,
                    pool_type: value.pool_type.name,
                }),
            );

            return decoded;
        } catch (err) {
            console.error('getSlipPoolsError', err);
            return [];
        }
    };

    const getMaxBaseTokenAmountThatCanBeAddedIntoSlip = async (): Promise<string> => {
        try {
            const interaction = slipSmartContract.methods.getMaxAmountThatCanBeAdded([USDD_TOKEN_ID]);
            const value = await queryContract(interaction);
            return value.toFixed();
        } catch (err) {
            console.error('getMaxBaseTokenAmountThatCanBeAddedIntoSlip', err);
            return '0';
        }
    };

    const createSlipPool = async (
        tokenIdentifier: string,
        vegldPoolScAddress: string,
        ouroPoolScAddress: string,
        frozenTokenTicker: string,
        frozenTokenDisplayName: string,
        poolType: number,
        isOuroPair: boolean,
    ) => {
        const tx = slipSmartContract.methods
            .createPool([
                tokenIdentifier,
                vegldPoolScAddress,
                ouroPoolScAddress,
                frozenTokenTicker,
                frozenTokenDisplayName,
                18,
                poolType,
                isOuroPair,
            ])
            .withGasLimit(CREATE_SLIP_POOL_GAS_LIMIT)
            .withValue(ISSUE_ESDT_PRICE);

        const txName = 'Create slip pool';
        const { sessionId, error } = await sendTransaction(tx, txName);

        return { sessionId, error };
    };

    return {
        context,
        basePool,
        pools,
        ouroAmount,
        maxBaseTokenAmountThatCanBeAddedIntoSlip,
        createSlipPool,
    };
};
