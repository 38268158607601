import React, { ChangeEvent, useState } from 'react';
import { parseAmount } from '@multiversx/sdk-dapp/utils';
import { Row, Col } from 'react-bootstrap';

import { issueSlipToken, mintSlipToken } from 'z/elrond';

const DECIMALS = 6;

export const IssueSlipToken = () => {
    const [inputLpAmount, setInputLpAmount] = useState('');

    const handleChangeAmount = (event: ChangeEvent<HTMLInputElement>) => {
        setInputLpAmount(event.target.value);
    };

    const handleIssueSlipToken = () => {
        issueSlipToken();
    };

    const handleMintSlipToken = () => {
        mintSlipToken(parseAmount(inputLpAmount, DECIMALS));
    };

    return (
        <Row
            style={{
                marginTop: '30px',
            }}
        >
            <Col
                md={12}
                style={{
                    fontSize: '14px',
                    color: '#98A1C0',
                }}
            >
                Set Slip Token Amount to receive:
            </Col>

            <Col md={8}>
                <input
                    className="vesta_x_input text-right mt-1"
                    placeholder={`Amount out`}
                    value={inputLpAmount}
                    onChange={handleChangeAmount}
                />
            </Col>
            <Col md={2}>
                <button className="slip-but w-100" onClick={handleMintSlipToken}>
                    Mint
                </button>
            </Col>
            <Col md={2}>
                <button className="slip-but w-100" onClick={handleIssueSlipToken}>
                    Issue
                </button>
            </Col>
        </Row>
    );
};
