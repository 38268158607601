import React, { useState, FC } from 'react';
import { useGetAccount, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import Modal from 'react-modal';
import { InfoTooltip } from 'components';
import { DEB_PRECISION } from 'config';
import { selectNetstats } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { ERROR_CONNECT_WALLET, ERROR_TRANSACTION_ONGOING, toastError } from 'z/utils';

interface propsInterface {
    buttonTextPrefix: string;
    value: boolean;
    info: string;
    enableDeb(address: string): Promise<void>;
    disableDeb(address: string): Promise<void>;
}

export const DebSwitch: FC<propsInterface> = ({ buttonTextPrefix, value, info, enableDeb, disableDeb }) => {
    const { address } = useGetAccount();
    const { hasPendingTransactions } = useGetPendingTransactions();

    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const netstatsRedux = useAppSelector(selectNetstats);

    function onOpenModal() {
        setModalIsOpen(true);
    }

    function onCloseModal() {
        setModalIsOpen(false);
    }

    const onSubmit = async () => {
        if (!address) {
            return toastError(ERROR_CONNECT_WALLET);
        } else if (hasPendingTransactions) {
            return toastError(ERROR_TRANSACTION_ONGOING);
        }

        if (value) {
            await enableDeb(address);
        } else {
            await disableDeb(address);
        }
        onCloseModal();
    };

    return (
        <>
            <div className="bordered-box position-relative">
                <div className="text-center" style={{ fontSize: '16px' }}>
                    {`DEB: ${netstatsRedux.deb ? netstatsRedux.deb / DEB_PRECISION : 1}`}
                </div>
                <div className="d-flex justify-content-center mt-2">
                    {value ? (
                        <button className="farm_but py-3" style={{ width: '15rem' }} onClick={onOpenModal}>
                            {`Enable DEB for ${buttonTextPrefix}`}
                        </button>
                    ) : (
                        <button className="farm_but py-3" style={{ width: '15rem' }} onClick={onOpenModal}>
                            {`Disable DEB for ${buttonTextPrefix}`}
                        </button>
                    )}
                </div>
                <InfoTooltip title={info} />
                <div className="text-center mt-2 mb-2 text-white">{`${buttonTextPrefix} DEB ${
                    value ? `Disabled` : `Enabled`
                }`}</div>
            </div>

            <Modal isOpen={modalIsOpen} onRequestClose={onCloseModal} style={customStyles} ariaHideApp={false}>
                <div style={{ minWidth: '360px', padding: '8px 16px' }}>
                    <div className="text-center mb-4" style={{ fontSize: '18px', color: '#f1dc46' }}>
                        {value ? 'Enable' : 'Disable'} DEB
                    </div>
                    <div className="d-flex flex-column text-center mb-4" style={{ color: '#98A1C0' }}>
                        <span>Are you sure want to {value ? 'enable' : 'disable'} DEB?</span>
                    </div>
                    <div className="d-flex w-100">
                        <button className="w-50 add-liquidity-button" onClick={onCloseModal}>
                            Cancel
                        </button>
                        <button className="w-50 ms-2 add-liquidity-button" onClick={onSubmit}>
                            Yes
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
