import React, { useEffect, useState } from 'react';
import { BinaryCodec, FieldDefinition, StructType, TokenTransfer, U64Type } from '@multiversx/sdk-core/out';
import { useGetAccount, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import { NftType } from '@multiversx/sdk-dapp/types/tokens.types';
import clsx from 'clsx';
import { selectNetstats } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { claimUnlockedSleepingVesta } from 'z/elrond';
import { formatNumber, convertWeiToEsdt, ERROR_CONNECT_WALLET, ERROR_TRANSACTION_ONGOING, toastError } from 'z/utils';

const sleepingVestaAttributesType = new StructType(
  "SleepingVestaAttributes",
  [
    new FieldDefinition("expected_unlock_epoch", "", new U64Type()),
  ]
);

export function SleepingVestaTokenCard({
  token,
  selected,
  toggleSelected,
} : {
  token: NftType,
  selected: boolean,
  toggleSelected: () => void,
}) {
  const { address } = useGetAccount();
  const { hasPendingTransactions } = useGetPendingTransactions();

  const netstatsRedux = useAppSelector(selectNetstats);
  
  const decodedAttributes = new BinaryCodec().decodeTopLevel(Buffer.from(token.attributes, "base64"), sleepingVestaAttributesType).valueOf();
  const expected_unlock_epoch = decodedAttributes.expected_unlock_epoch.toNumber();
  const [isUnlockable, setIsUnlockable] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (netstatsRedux && netstatsRedux.elrondStats != undefined && netstatsRedux.elrondStats.epoch) {
        setIsUnlockable(expected_unlock_epoch <= netstatsRedux.elrondStats.epoch);
      }
    })();
  }, [netstatsRedux.elrondStats]);

  async function unlock() {
    let error = '';
    if (!address) {
      error = ERROR_CONNECT_WALLET;
    } else if (hasPendingTransactions) {
      error = ERROR_TRANSACTION_ONGOING;
    }

    if (error) {
      toastError(error);
      return;
    }

    await claimUnlockedSleepingVesta(
      TokenTransfer.metaEsdtFromBigInteger(token.collection, token.nonce, token.balance),
      address,
    );
  }
  
  return (
    <div
      className={clsx('vested-token-container clickable mb-3', selected && 'selected')}
      onClick={(e) => {
        if (e.currentTarget.tagName == 'button') {
          e.stopPropagation();
          return;
        }
        toggleSelected();
      }}
    >
      <div className='d-flex justify-content-between align-items-center flex-row'>
        <div className="vested-token">
          <div className="d-flex align-items-center gap-1">
            <span style={{ fontSize: '1rem', color: '#98A1C0' }}>
            {token.identifier}
            </span>
          </div>
          <span>
          {formatNumber(convertWeiToEsdt(token.balance, token.decimals))}
          </span>
        </div>
      </div>
      
      <div className="vested-token">
        Unlock In: {netstatsRedux.elrondStats ? expected_unlock_epoch - netstatsRedux.elrondStats.epoch > 0 ? `${expected_unlock_epoch - netstatsRedux.elrondStats.epoch} days` : 'Unlockable' : '-'}
      </div>

      {
        isUnlockable && (
          <div className='d-flex justify-content-between align-items-center'>
            <button
              className="mt-3 add-liquidity-button"
              disabled={hasPendingTransactions}
              onClick={unlock}
            >
              Unlock
            </button>
          </div>
        )
      }
    </div>
  );
}