import { IS_DEV } from './elrond';

export const OURO_VAULT_SWAP_POOLS = IS_DEV
    ? [
          {
              lp_token_id: 'VUSDDOURO-eca532',
              swap_pool_address: 'erd1qqqqqqqqqqqqqpgqskwa3u6wa9spj0gtfqrlz8gvhgezwjk3c5wsj0vz5p',
          },
          {
              lp_token_id: ' VUSDCOURO-a81f2f',
              swap_pool_address: 'erd1qqqqqqqqqqqqqpgqm5h8mec5zfcqmq6ws3gwv6q9tkg4spsmc5wszj5s48',
          },
          {
              lp_token_id: 'VUSDCOURO-a81f2f',
              swap_pool_address: 'erd1qqqqqqqqqqqqqpgqm5h8mec5zfcqmq6ws3gwv6q9tkg4spsmc5wszj5s48',
          },
          {
              lp_token_id: 'VSUSDCOURO-d577e0',
              swap_pool_address: 'erd1qqqqqqqqqqqqqpgqm5h8mec5zfcqmq6ws3gwv6q9tkg4spsmc5wszj5s48',
          },
          {
              lp_token_id: 'VOUROVST-a9a8b1',
              swap_pool_address: 'erd1qqqqqqqqqqqqqpgqyysg2jqumpz7pzejnhl8zff8gy5jr5wnc5wsj05247',
          },
          {
              lp_token_id: 'OUROGSCLP-42390b',
              swap_pool_address: 'erd1qqqqqqqqqqqqqpgqyd88ckyldntzr0j700dx75h6dz4nlqerc5wspmrecq',
          },
          {
              lp_token_id: 'VOUROSSC-3a1648',
              swap_pool_address: 'erd1qqqqqqqqqqqqqpgqqj867zx0harm6jmtz2ev69dhtgltffndc5ws8y4u0z',
          },
          {
              lp_token_id: 'VOUROXLH-52bbef',
              swap_pool_address: 'erd1qqqqqqqqqqqqqpgq4gncm2tm9we4crwaztutzkk8h4ukn0hlc5wsknqnu5',
          },
          {
              lp_token_id: 'OUROVST-52ce5d',
              swap_pool_address: 'erd1qqqqqqqqqqqqqpgqyysg2jqumpz7pzejnhl8zff8gy5jr5wnc5wsj05247',
          },
          {
              lp_token_id: 'VOUROXLH-bf9d4e',
              swap_pool_address: 'erd1qqqqqqqqqqqqqpgqm7s0erm2vr37ge4w84ndwvgme4mtnh7sc5wss60xqa',
          },
          {
              lp_token_id: 'VSGSCOURO-f5676f',
              swap_pool_address: 'erd1qqqqqqqqqqqqqpgqyd88ckyldntzr0j700dx75h6dz4nlqerc5wspmrecq',
          },
          {
              lp_token_id: 'VSSSCOURO-f4b7ff',
              swap_pool_address: 'erd1qqqqqqqqqqqqqpgqqj867zx0harm6jmtz2ev69dhtgltffndc5ws8y4u0z',
          },
      ]
    : [];
