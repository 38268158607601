import { Address, Interaction, ResultsParser, TokenTransfer } from "@multiversx/sdk-core/out";
import { sendTransactions } from "@multiversx/sdk-dapp/services";
import BigNumber from 'bignumber.js';
import { IS_DEV } from "config";
import { EsdtTokenPaymentType, SwapResultType } from "z/types";
import { elrondProvider } from "./provider";

// if tx is not a Transaction instance but a plain object, "transformAndSignTransactions" is applied to the object so you don't need to call "refreshAccount" for sync nonce
export async function elrondDappSendTransactions(txs: any, txName: string) {
    const { sessionId, error } = await sendTransactions({
        transactions: txs,
        transactionsDisplayInfo: {
            processingMessage: `Processing ${txName} Request`,
            errorMessage: `Error occured during ${txName} Request`,
            successMessage: `${txName} Request Successful`,
        },
        redirectAfterSign: false,
    });
    
    return { sessionId, error };
}

export async function mvxQuery(interaction: Interaction) {
    const query = interaction.check().buildQuery();
    const queryResponse = await elrondProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } =
    new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);
    
    if (!firstValue || !returnCode.isSuccess()) {
        throw Error(returnMessage);
    }
    
    return firstValue.valueOf();
}

export const COMMON_GAS_LIMIT = 300_000_000;
export async function mvxSendTransaction({
    interaction,
    payments,
    value,
    gasLimit,
    txName,
    sender,
}: {
    interaction: Interaction;
    payments?: TokenTransfer[];
    value?: BigNumber.Value;
    gasLimit?: number;
    txName: string;
    sender: string;
}) {
    try {
        if (payments) {
            if (payments.length == 0) {
                throw new Error('payments.length zero');
            }

            if (payments.length == 1 && payments[0].nonce == 0) {
                // Single ESDT Transfer
                interaction = interaction.withSingleESDTTransfer(payments[0]);
            } else {
                // Multi ESDT or NFT Transfer
                // these kinds of transfer need explicit sender and receiver (both of them should be sender address)

                interaction = interaction.withMultiESDTNFTTransfer(payments).withExplicitReceiver(new Address(sender));
            }
        }
        interaction = interaction.withSender(new Address(sender));

        if (value) {
            interaction = interaction.withValue(value);
        }
        if (gasLimit) {
            interaction = interaction.withGasLimit(gasLimit);
        } else {
            interaction = interaction.withGasLimit(COMMON_GAS_LIMIT);
        }
        interaction = interaction.withChainID(IS_DEV ? 'D' : '1');
        const tx = interaction.check().buildTransaction();

        const { sessionId, error } = await sendTransactions({
            transactions: [tx],
            transactionsDisplayInfo: {
                processingMessage: `Processing ${txName} Request`,
                errorMessage: `Error occured during ${txName} Request`,
                successMessage: `${txName} Request Successful`,
            },
            redirectAfterSign: false,
        });

        return { sessionId, error };
    } catch (err) {
        console.error(err);
    }
}

export function parseEsdtTokenPayment(value: any): EsdtTokenPaymentType {
    return {
        token_identifier: value.token_identifier.toString(),
        token_nonce: value.token_nonce.toNumber(),
        amount: value.amount.toFixed(0),
    };
}

export function parseSwapResult(value: any): SwapResultType {
    return {
        total_fee: value.total_fee.toFixed(0),
        special_fee: value.special_fee.toFixed(0),
        payment_in: parseEsdtTokenPayment(value.payment_in),
        payment_out: parseEsdtTokenPayment(value.payment_out),
        refund_amount_in: value.refund_amount_in.toFixed(0),
    };
}

export function parseNonceFromNumberToHexString(value: number): string {
    let hexStr = value.toString(16);
    for (let i = hexStr.length; ![2, 4, 8, 16, 32].includes(i); i++) {
        hexStr = '0' + hexStr;
    }
    return hexStr;
}

export function parseNumberArray(value: BigNumber[]): number[] {
    return value.map((v) => v.toNumber());
}

export function parseStringArray(value: any[]): string[] {
    return value.map((v) => v.toString());
}
