import { Interaction, TokenTransfer } from '@multiversx/sdk-core/out';
import { USDC_USDT_USDD_POOL_ADDRESS } from 'config';
import { BigNumber, EsdtTokenPaymentType, StateEnum, parseStateEnum } from 'z/types';
import { convertWeiToEsdt } from 'z/utils';
import abiJson from './abi/vesta-stableswap.abi.json';
import { mvxQuery, mvxSendTransaction, parseEsdtTokenPayment } from './common';
import { createSmartContract } from "./provider";
import { getTokenDecimals } from './utils';

export interface StableswapPool {
  pool_address: string,
  pool_state: StateEnum,
  token_ids: string[],
  token_multipliers: number[],
  token_balances: BigNumber[],
  xps: BigNumber[],
  
  initial_a: number,
  a: number,
  
  swap_fee: number,
  special_fee: number,
  liquidity_fee: number,
  fee_receivers: FeeReceiver[],
  
  lp_token_id: string,
  lp_token_supply: BigNumber,
}
export interface StableswapSwapResult {
  total_fee: number,
  special_fee: number,
  payment_in: EsdtTokenPaymentType,
  payment_out: EsdtTokenPaymentType,
}

export interface FeeReceiver {
  address: string,
  shares: number,
  method: string,
}

export function parseFeeReceiver(value: any): FeeReceiver {
  return {
    address: value.address.toString(),
    shares: value.shares.toNumber(),
    method: value.method.toString(),
  };
}

export const smartContract = createSmartContract(abiJson, USDC_USDT_USDD_POOL_ADDRESS);
export class StableswapContract {
    async getOwnersAndAdmins(): Promise<string[]> {
        try {
            const value = await mvxQuery(smartContract.methods.getOwnersAndAdmins());
            const decoded = value.map((v: any) => v.toString());

            return decoded;
        } catch (err) {
            console.error(`${StableswapContract.name}.getOwnersAndAdmins:`, err);
            return [];
        }
    }

    async viewPoolContext(): Promise<StableswapPool | undefined> {
        try {
            const value = await mvxQuery(smartContract.methods.viewPoolContext());
            const decoded = {
                pool_address: smartContract.getAddress().bech32(),
                pool_state: parseStateEnum(value.state.name),
                token_ids: value.token_ids.map((v: any) => v.toString()),
                token_multipliers: value.token_multipliers.map((v: any) => v.toNumber()),
                token_balances: value.token_balances.map((v: any) => v as BigNumber),
                xps: value.xps.map((v: any) => v as BigNumber),

                initial_a: value.initial_a.toNumber(),
                a: value.a.toNumber(),

                swap_fee: value.swap_fee.toNumber(),
                special_fee: value.special_fee.toNumber(),
                liquidity_fee: value.liquidity_fee.toNumber(),
                fee_receivers: value.fee_receivers.map((v: any) => parseFeeReceiver(v)),

                lp_token_id: value.lp_token_id.toString(),
                lp_token_supply: value.lp_token_supply as BigNumber,
            };

            return decoded;
        } catch (err) {
            console.error(`${StableswapContract.name}.viewPoolContext:`, err);
            return undefined;
        }
    }

    async estimateSwap(
        tokenIn: string,
        tokenOut: string,
        amountIn: BigNumber,
    ): Promise<StableswapSwapResult | undefined> {
        try {
            const value = await mvxQuery(smartContract.methods.estimateSwap([tokenIn, tokenOut, amountIn]));
            const decoded = {
                total_fee: value.total_fee.toNumber(),
                special_fee: value.special_fee.toNumber(),
                payment_in: parseEsdtTokenPayment(value.payment_in),
                payment_out: parseEsdtTokenPayment(value.payment_out),
            };

            return decoded;
        } catch (err) {
            console.error(`${StableswapContract.name}.estimateSwap:`, err);
            return undefined;
        }
    }

    async estimateAddLiquidity(amountsIn: BigNumber.Value[]): Promise<BigNumber | undefined> {
        try {
            const _sum = amountsIn.reduce((s: BigNumber, cur) => (s = s.plus(cur)), new BigNumber(0));
            if (_sum.isZero()) {
                return new BigNumber(0);
            }

            const value = await mvxQuery(smartContract.methods.estimateAddLiquidity([amountsIn]));
            const decoded = new BigNumber(parseEsdtTokenPayment(value).amount);

            return decoded;
        } catch (err) {
            console.error(`${StableswapContract.name}.estimateAddLiquidity:`, err);
            return undefined;
        }
    }

    async calcWithdrawOneToken(lpTokenAmount: BigNumber.Value, tokenOut: string): Promise<BigNumber | undefined> {
        try {
            // returns [amountOut, feeAmount]
            const value = await mvxQuery(smartContract.methods.calcWithdrawOneToken([lpTokenAmount, tokenOut]));
            const decoded = value[0] as BigNumber;

            return decoded;
        } catch (err) {
            console.error(`${StableswapContract.name}.calcWithdrawOneToken:`, err);
            return undefined;
        }
    }

    async setPoolState(state: StateEnum, sender: string) {
        let interaction: Interaction;
        if (state == StateEnum.Active) {
            interaction = smartContract.methods.setActive();
        } else if (state == StateEnum.ActiveNoSwaps) {
            interaction = smartContract.methods.setActiveNoSwaps();
        } else {
            interaction = smartContract.methods.setInactive();
        }

        await mvxSendTransaction({
            interaction,
            gasLimit: 20_000_000,
            txName: 'Set State',
            sender,
        });
    }

    async setFeeReceivers(feeReceivers: FeeReceiver[], sender: string) {
        const interaction = smartContract.methods.setFeeReceivers([feeReceivers]);
        await mvxSendTransaction({
            interaction,
            gasLimit: 20_000_000,
            txName: 'Set Fee Receivers',
            sender,
        });
    }

    async swap(paymentIn: TokenTransfer, tokenOut: string, minAmountOut: BigNumber, sender: string) {
        const interaction = smartContract.methods.swap([tokenOut, minAmountOut]);
        const payments = [paymentIn];
        await mvxSendTransaction({
            interaction,
            payments,
            gasLimit: 30_000_000,
            txName: 'Stable Swap',
            sender,
        });
    }

    async addLiquidity(paymentsIn: TokenTransfer[], minShares: BigNumber, sender: string) {
        const interaction = smartContract.methods.addLiquidity([minShares]);
        // exclude empty payments
        const payments: TokenTransfer[] = [];
        paymentsIn.forEach((p) => p.amountAsBigInteger.isGreaterThan(0) && payments.push(p));
        await mvxSendTransaction({
            interaction,
            payments,
            gasLimit: 30_000_000,
            txName: 'Add Liquidity',
            sender,
        });
    }

    async removeLiquidity(paymentIn: TokenTransfer, minAmountOuts: BigNumber[], sender: string) {
        const interaction = smartContract.methods.removeLiquidity([minAmountOuts]);
        const payments: TokenTransfer[] = [paymentIn];
        await mvxSendTransaction({
            interaction,
            payments,
            gasLimit: 30_000_000,
            txName: 'Remove Liquidity',
            sender,
        });
    }

    async removeLiquidityOneToken(paymentIn: TokenTransfer, tokenOut: string, minAmountOut: BigNumber, sender: string) {
        const interaction = smartContract.methods.removeLiquidityOneToken([tokenOut, minAmountOut]);
        const payments: TokenTransfer[] = [paymentIn];
        await mvxSendTransaction({
            interaction,
            payments,
            gasLimit: 30_000_000,
            txName: 'Remove Liquidity',
            sender,
        });
    }

    /* Utilities */

    /// compute usd value of LP token
    computeUsdValue(pool: StableswapPool, lpTokenAmount: BigNumber): BigNumber {
        const _virtualPrice = calculateVirtualPrice(
            new BigNumber(pool.a),
            computeXp(
                pool.token_balances,
                pool.token_ids.map((tokenId) => getTokenDecimals(tokenId)),
            ),
            pool.lp_token_supply,
        );
        return convertWeiToEsdt(lpTokenAmount.multipliedBy(_virtualPrice), DEFAULT_DECIMALS, DEFAULT_DECIMALS);
    }
}

// Curve Stableswap Model Utilities
/// Return precision-adjusted balances, adjusted to 18 decimals
const DEFAULT_DECIMALS = 18;
function computeXp(reserves: BigNumber[], tokenDecimals: number[]): BigNumber[] {
  return reserves.map((reserve, i) => 
    reserve.multipliedBy(new BigNumber(10).pow(DEFAULT_DECIMALS - tokenDecimals[i]))
  );
}

// maximum iterations of newton's method approximation
const MAX_ITERS = 20;
/**
 * Calculates the current virtual price of the exchange.
 */
const calculateVirtualPrice = (
    ampFactor: BigNumber,
    reserves: BigNumber[],
    lpTotalSupply: BigNumber
): BigNumber => {
    if (lpTotalSupply === undefined || lpTotalSupply.eq(0)) {
        // pool has no tokens
        return new BigNumber(0);
    }
    const price = computeD(ampFactor, reserves).div(lpTotalSupply);
    return price;
};

/**
 * Compute the StableSwap invariant
 * @param ampFactor Amplification coefficient (A)
 * @param amountA Swap balance of token A
 * @param amountB Swap balance of token B
 * Reference: https://github.com/curvefi/curve-contract/blob/7116b4a261580813ef057887c5009e22473ddb7d/tests/simulation.py#L31
 */
const computeD = (
  ampFactor: BigNumber,
  amounts: BigNumber[],
): BigNumber => {
  const nCoins = new BigNumber(amounts.length);
  const Ann = ampFactor.multipliedBy(nCoins); // A*n^n
  const S = amounts.reduce((total, a) => total.plus(a), new BigNumber(0)); // sum(x_i), a.k.a S
  if (S.eq(0)) {
    return new BigNumber(0);
  }

  let dPrev = new BigNumber(0);
  let d = S;

  for (
    let i = 0;
    d.minus(dPrev).abs().gt(1) && i< MAX_ITERS;
    i++
  ) {
    dPrev = d;
    let dP = d;
    amounts.map(a => {
        dP = dP.multipliedBy(d).idiv(a.multipliedBy(nCoins));
    });
    const dNumerator = d.multipliedBy(Ann.multipliedBy(S).plus(dP.multipliedBy(nCoins)));
    const dDenominator = d.multipliedBy(Ann.minus(1)).plus(dP.multipliedBy(nCoins.plus(1)));
    d = dNumerator.idiv(dDenominator);
  }

  return d;
};
