import React, { FC } from 'react';
import Check from '@mui/icons-material/Check';
import { Box, Stepper, Step, StepLabel, styled } from '@mui/material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#37b06f',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#37b06f',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const stepLabelStyle = {
    [`& .MuiStepLabel-label`]: {
        color: '#eaeaf0 !important',
        display: {
            xs: 'none',
            sm: 'block',
        }
    }
};

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
    ({ theme, ownerState }) => ({
        color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        ...(ownerState.active && {
            color: '#37b06f',
        }),
        '& .QontoStepIcon-completedIcon': {
            color: '#37b06f',
            zIndex: 1,
            fontSize: 18,
        },
        '& .QontoStepIcon-circle': {
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
        },
    }),
);

function QontoStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

interface PropsType {
    steps: Array<string>;
    activeStep: number;
}

export const CreatePoolStepper: FC<PropsType> = ({ steps, activeStep }) => {
    return (
        <>

            <div style={{ marginTop: '40px' }}>
                <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector />}>
                        {steps.map((label) => (
                            <Step key={label} sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}>
                                <StepLabel StepIconComponent={QontoStepIcon} sx={stepLabelStyle}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>
            </div>
        </>
    );
};