import React, { useState } from 'react';
import clsx from 'clsx';
import { AiOutlineWarning } from 'react-icons/ai';
import { IoSettingsOutline } from 'react-icons/io5';
import Modal from 'react-modal';
import { setSlippage } from 'redux/reducers';
import { selectSwap } from 'redux/reducers';
import { useAppSelector, useAppDispatch } from 'redux/store';
import { toastError } from 'z/utils';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

export const SlippageSetting = () => {
    const dispatch = useAppDispatch();
    const swapRedux = useAppSelector(selectSwap);

    const [modalIsOpen, setIsOpen] = useState(false);
    const [newSlippage, setNewSlippage] = useState<number>(swapRedux.slippage);
    const [newSlippageError, setNewSlippageError] = useState('');

    function onOpenModal() {
        setNewSlippageError('');
        setNewSlippage(swapRedux.slippage);
        setIsOpen(true);
    }

    function onCloseModal() {
        setIsOpen(false);
    }

    function onChangeSlippage(value: number) {
        let error = '';
        if (value >= 50 || value < 0) {
            error = "Enter a valid slippage percentage";
        } else {
            error = '';
        }

        setNewSlippageError(error);
        setNewSlippage(value);
    }

    function onConfirm() {
        if (newSlippageError) {
            toastError(newSlippageError);
            return;
        }

        dispatch(setSlippage(newSlippage));
        onCloseModal();
    }

    return (
        <>
            <div className="but-setting" onClick={onOpenModal}>
                <IoSettingsOutline />
            </div>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={onCloseModal}
                style={customStyles}
                ariaHideApp={false}
            >
                <div className="slippage-setting-modal">
                    <div
                        className="d-flex justify-content-center"
                        style={{ fontSize: "22px", color: "white" }}
                    >
                        Settings
                    </div>
                    
                    <div style={{ minHeight: '200px' }} className="mt-5">
                        <div className="slippage-title" >Slippage Tolerance</div>
                        <div className="d-flex justify-content-between mt-3">
                            <div className="d-flex gap-2">
                                <div
                                    className={clsx({ "slippage-but": true, "slippage-but-selected ": newSlippage === 0.1 })}
                                    onClick={() => onChangeSlippage(0.1)}
                                >
                                    0.1%
                                </div>
                                <div
                                    className={clsx({ "slippage-but": true, "slippage-but-selected ": newSlippage === 0.5 })}
                                    onClick={() => onChangeSlippage(0.5)}
                                >
                                    0.5%
                                </div>
                                <div
                                    className={clsx({ "slippage-but": true, "slippage-but-selected ": newSlippage === 1 })}
                                    onClick={() => onChangeSlippage(1)}
                                >
                                    1%
                                </div>
                            </div>
                            <div className="input-box" style={{ width: '30%' }}>
                                <input
                                    type="number"
                                    min="0"
                                    value={newSlippage}
                                    onChange={(e) => onChangeSlippage(Number(e.target.value))}
                                />
                                <span>%</span>
                            </div>
                        </div>
                        {
                            !!newSlippageError &&
                            <div className="mt-2 error-text">
                                <span style={{ fontSize: '16px' }}><AiOutlineWarning /></span>
                                {' '}{newSlippageError}
                            </div>
                        }
                    </div>

                    <div className="d-flex justify-content-center" style={{ marginTop: "30px" }}>
                        <div className="d-flex gap-2">
                            <div className="slippage-setting-modal-but" onClick={onConfirm}>Confirm</div>
                            <div className="slippage-setting-modal-but" onClick={onCloseModal}>Cancel</div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};