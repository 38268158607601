import { ArgSerializer, StringValue, TypedValue } from '@multiversx/sdk-core/out';
import {
    FAUCET_MINT_GAS_LIMIT, FAUCET_SC_ADDRESS,
} from 'config';
import { elrondDappSendTransactions, mvxQuery } from './common';
import { faucetSmartContract } from './provider';

export async function getFaucetTokensList() {
    try {
        const interaction = faucetSmartContract.methods.tokenId();
        const values = await mvxQuery(interaction);

        return values;
    } catch (err) {
        console.error('Faucet Tokens Error', err);
        return [];
    }
}

export async function getFaucetNftList() {
    try {
        const interaction = faucetSmartContract.methods.nftTokenIds();
        const values = await mvxQuery(interaction);

        return values;
    } catch (err) {
        console.error('Faucet Nft Tokens Error', err);
        return [];
    }
}

export async function getFaucetSftList(): Promise<string[]> {
    try {
        const interaction = faucetSmartContract.methods.getSftCollectionIdentifier();
        const values = await mvxQuery(interaction);

        return values;
    } catch (err) {
        console.error('Faucet SFT Collection Identifiers Error', err);
        return [];
    }
}

export async function mintTokens(token: string) {
    const args: TypedValue[] = [new StringValue(token)];

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `mintTokens@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: FAUCET_SC_ADDRESS,
        gasLimit: FAUCET_MINT_GAS_LIMIT,
    };

    const txName = 'mintTokens';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function getNftById(token: string) {
    const args: TypedValue[] = [new StringValue(token)];

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `getNftByTokenId@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: FAUCET_SC_ADDRESS,
        gasLimit: FAUCET_MINT_GAS_LIMIT,
    };

    const txName = 'getNftByTokenId';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function faucetMintSfts(token: string) {
    const args: TypedValue[] = [new StringValue(token)];

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `mintSftByIdentifier@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: FAUCET_SC_ADDRESS,
        gasLimit: FAUCET_MINT_GAS_LIMIT,
    };

    const txName = 'mintSftByIdentifier';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

