import {
    Address,
    AddressValue,
    ArgSerializer,
    BigUIntValue,
    Field,
    List,
    SmartContract,
    StringValue,
    Struct,
    TokenIdentifierValue,
    TokenTransfer,
    TypedValue,
    U32Value,
    U64Value,
    U8Value,
} from '@multiversx/sdk-core/out';
import { InjectRewardType, SVST_TOKEN_ID } from 'config';
import { VaultBaseContextType, VaultStatsContextType, VaultUserContextType, VestingTypeEnum } from 'z/types';
import { BIG_NUMBER_ZERO } from 'z/utils';
import { elrondDappSendTransactions, mvxQuery, mvxSendTransaction, parseEsdtTokenPayment } from './common';
import { esdtTokenPaymentType, vestaVaultSmartContract } from './provider';

export async function vaultViewBaseContext(scAddress: string): Promise<VaultBaseContextType | undefined> {
    try {
        vestaVaultSmartContract.setAddress(new Address(scAddress));
        const value = await mvxQuery(vestaVaultSmartContract.methods.viewBaseContext());
        const decoded = {
            state: value.state,
            vesta_token_id: value.vesta_token_id.toString(),
            blessed_vesta_token_id: value.blessed_vesta_token_id.toString(),
            svesta_token_id: value.svesta_token_id.toString(),
            sft_token_id: value.sft_token_id.toString(),
            raw_vesta_token_id: value.raw_vesta_token_id.toString(),
            sft_lock_period: value.sft_lock_period.toNumber(),

            premium_reward_token_ids: value.premium_reward_token_ids.map((v: any) => v.toString()),
            elite_reward_token_ids: value.elite_reward_token_ids.map((v: any) => v.toString()),
        };

        return decoded;
    } catch (err) {
        console.error('getVaultViewBaseContext', err);
        return undefined;
    }
}

export async function vaultViewStatsContext(scAddress: string): Promise<VaultStatsContextType | undefined> {
    try {
        vestaVaultSmartContract.setAddress(new Address(scAddress));
        const value = await mvxQuery(vestaVaultSmartContract.methods.viewStatsContext());
        const decoded = {
            vesta_reserve: value.vesta_reserve.toFixed(0),
            blessed_vesta_reserve: value.blessed_vesta_reserve.toFixed(0),
            svesta_reserve: value.svesta_reserve.toFixed(0),
            sft_reserves: value.sft_reserves.map((v: any) => v.toFixed(0)),
            reward_rate: value.reward_rate.toFixed(0),

            total_standard_vesta_power: value.total_standard_vesta_power.toFixed(0),
            total_premium_vesta_power: value.total_premium_vesta_power.toFixed(0),
            total_elite_vesta_power: value.total_elite_vesta_power.toFixed(0),
        };

        return decoded;
    } catch (err) {
        console.error('vaultViewStatsContext', err);
        return undefined;
    }
}

export async function vaultViewUserContext(
    scAddress: string,
    address: string,
): Promise<VaultUserContextType | undefined> {
    try {
        vestaVaultSmartContract.setAddress(new Address(scAddress));
        const value = await mvxQuery(vestaVaultSmartContract.methods.viewUserContext([address]));
        const svesta_staked_payments = value.svesta_staked_payments.map((v: any) => parseEsdtTokenPayment(v));
        let svesta_staked_amount = BIG_NUMBER_ZERO;
        for (const payment of svesta_staked_payments) {
            svesta_staked_amount = svesta_staked_amount.plus(payment.amount);
        }

        const decoded = {
            vesta_staked_amount: value.vesta_staked_amount.toFixed(0),
            blessed_vesta_staked_amount: value.blessed_vesta_staked_amount.toFixed(0),
            svesta_staked_payments,
            svesta_staked_amount: svesta_staked_amount.toFixed(0),
            sft_staked_amounts: value.sft_staked_amounts.map((v: any) => v.toFixed(0)),

            standard_vesta_power: value.standard_vesta_power.toFixed(0),
            premium_vesta_power: value.premium_vesta_power.toFixed(0),
            elite_vesta_power: value.elite_vesta_power.toFixed(0),

            reward_amount: value.reward_amount.toFixed(0),
            last_claimed_timestamp: value.last_claimed_timestamp.toNumber(),

            deb: value.deb.toNumber() / 100_000,
            vlm: value.vlm.toNumber() / 1_000_000,
            im: value.im.toNumber() / 1_000_000,
            vm: value.vm.toNumber() / 1_000_000,

            premium_reward_payments: value.premium_reward_payments.map((v: any) => parseEsdtTokenPayment(v)),
            elite_reward_payments: value.elite_reward_payments.map((v: any) => parseEsdtTokenPayment(v)),
        };

        return decoded;
    } catch (err) {
        console.error('vaultViewUserContext', err);
        return undefined;
    }
}

export async function vaultStakeToken(scAddress: string, payments: TokenTransfer[], sender: string) {
    const args: TypedValue[] = [new AddressValue(new Address(scAddress)), new U32Value(payments.length)];
    let gasLimit = 85_000_000;
    payments.map((payment) => {
        if (SVST_TOKEN_ID === payment.tokenIdentifier) gasLimit = 125_000_000;
        args.push(new TokenIdentifierValue(payment.tokenIdentifier));
        args.push(new U64Value(payment.nonce));
        args.push(new BigUIntValue(payment.amountAsBigInteger));
    });
    args.push(new StringValue('stakeToken'));

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `MultiESDTNFTTransfer@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: sender,
        gasLimit: gasLimit + 8_000_000 * payments.length,
    };

    const txName = 'Stake';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function vaultUnstakeToken(scAddress: string, payments: TokenTransfer[]) {
    const pa: TypedValue[] = [];
    payments.map((payment) =>
        pa.push(
            new Struct(esdtTokenPaymentType, [
                new Field(new TokenIdentifierValue(payment.tokenIdentifier), 'token_identifier'),
                new Field(new U64Value(payment.nonce), 'token_nonce'),
                new Field(new BigUIntValue(payment.amountAsBigInteger), 'amount'),
            ]),
        ),
    );

    const args: TypedValue[] = [List.fromItems(pa)];

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `unstakeToken@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: scAddress,
        gasLimit: 100_000_000 + 5_000_000 * payments.length,
    };

    const txName = 'Unstake';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function vaultClaimReward(
    scAddress: string,
    vestingType: VestingTypeEnum,
    lockYears: number,
    mintPercent: number,
) {
    const args: TypedValue[] = [
        new U32Value(vestingType),
        new U8Value(lockYears),
        new U64Value(Math.floor(mintPercent * 10000)),
    ];

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `claimReward@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: scAddress,
        gasLimit: 80_000_000,
    };

    const txName = 'Claim Reward';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function vaultStakeSft(scAddress: string, payments: TokenTransfer[], sender: string) {
    const args: TypedValue[] = [new AddressValue(new Address(scAddress)), new U32Value(payments.length)];
    payments.map((payment) => {
        args.push(new TokenIdentifierValue(payment.tokenIdentifier));
        args.push(new U64Value(payment.nonce));
        args.push(new BigUIntValue(payment.amountAsBigInteger));
    });
    args.push(new StringValue('stakeSft'));

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `MultiESDTNFTTransfer@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: sender,
        gasLimit: 40_000_000 + 3_000_000 * payments.length,
    };

    const txName = 'Stake SFT';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function vaultUnstakeSft(scAddress: string, payments: TokenTransfer[]) {
    const pa: TypedValue[] = [];
    payments.map((payment) =>
        pa.push(
            new Struct(esdtTokenPaymentType, [
                new Field(new TokenIdentifierValue(payment.tokenIdentifier), 'token_identifier'),
                new Field(new U64Value(payment.nonce), 'token_nonce'),
                new Field(new BigUIntValue(payment.amountAsBigInteger), 'amount'),
            ]),
        ),
    );

    const args: TypedValue[] = [List.fromItems(pa)];

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `unstakeSft@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: scAddress,
        gasLimit: 40_000_000 + 3_000_000 * payments.length,
    };

    const txName = 'Unstake SFT';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function vaultMergeSvestas(scAddress: string, payments: TokenTransfer[]) {
    const pa: TypedValue[] = [];
    payments.map((payment) =>
        pa.push(
            new Struct(esdtTokenPaymentType, [
                new Field(new TokenIdentifierValue(payment.tokenIdentifier), 'token_identifier'),
                new Field(new U64Value(payment.nonce), 'token_nonce'),
                new Field(new BigUIntValue(payment.amountAsBigInteger), 'amount'),
            ]),
        ),
    );

    const args: TypedValue[] = [List.fromItems(pa)];

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `mergeSvestas@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: scAddress,
        gasLimit: 100_000_000 + 10_000_000 * payments.length,
    };

    const txName = 'Merge sVST';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function vaultInjectReward(
    scAddress: string,
    injectRewardType: InjectRewardType,
    payments: TokenTransfer[],
    sender: string,
    gasLimit?: number,
) {
    const args: TypedValue[] = [new AddressValue(new Address(scAddress)), new U32Value(payments.length)];
    payments.map((payment) => {
        args.push(new TokenIdentifierValue(payment.tokenIdentifier));
        args.push(new U64Value(payment.nonce));
        args.push(new BigUIntValue(payment.amountAsBigInteger));
    });
    args.push(
        new StringValue(
            injectRewardType === InjectRewardType.StandardReward
                ? 'injectReward'
                : injectRewardType === InjectRewardType.PremiumReward
                ? 'injectPremiumRewards'
                : 'injectEliteRewards',
        ),
    );

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `MultiESDTNFTTransfer@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: sender,
        gasLimit: gasLimit || 50_000_000,
    };

    const txName = 'Inject Reward';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function vaultClaimEliteReward(scAddress: string) {
    const data = `claimEliteRewards`;

    const tx = {
        value: 0,
        data,
        receiver: scAddress,
        gasLimit: 45_000_000,
    };

    const txName = 'Claim Elite Reward';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function vaultClaimPremiumReward(scAddress: string) {
    const data = `claimPremiumRewards`;

    const tx = {
        value: 0,
        data,
        receiver: scAddress,
        gasLimit: 45_000_000,
    };

    const txName = 'Claim Premium Reward';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export class VaultContract {
    sender: string;
    smartContract: SmartContract;

    constructor(
        sender: string,
        scAddress: string,
    ) {
        this.sender = sender;
        this.smartContract = vestaVaultSmartContract;
        this.smartContract.setAddress(new Address(scAddress));
    }

    async addPremiumRewardTokenIds(
        tokenId: string,
    ) {
        const interaction = this.smartContract.methods.addPremiumRewardTokenIds([tokenId]);
        await mvxSendTransaction({
            interaction,
            gasLimit: 300_000_000,
            txName: 'Add Premium Reward Token',
            sender: this.sender,
        });
    }
}
