import React, { useState, useEffect } from 'react';
import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import {
    faucetMintSfts,
    getFaucetNftList,
    getFaucetSftList,
    getFaucetTokensList,
    getNftById,
    mintTokens,
} from 'z/elrond/faucet';

export const Faucet = () => {
    const { address } = useGetAccount();

    const [tokensList, setTokensList] = useState<string[]>([]);
    const [nftList, setNftList] = useState<string[]>([]);
    const [sftList, setSftList] = useState<string[]>([]);
    const [selectedToken, setSelectedToken] = useState<string>('');
    const [selectedNft, setSelectedNft] = useState<string>('');
    const [selectedSft, setSelectedSft] = useState<string>('');

    useEffect(() => {
        const loadData = async () => {
            const items = await getFaucetTokensList();
            setTokensList(items);

            const nftItems = await getFaucetNftList();
            setNftList(nftItems);

            const sftItems = await getFaucetSftList();
            setSftList(sftItems);
        };

        loadData();
    }, []);

    const handleSelectToken = (token: string) => {
        setSelectedToken(token);
    };

    const handleSelectedNft = (nft: string) => {
        setSelectedNft(nft);
    };

    const handleSelectedSft = (sft: string) => {
        setSelectedSft(sft);
    };

    async function mintSelectedToken() {
        if (selectedToken === 'select' || selectedToken === '') {
            window.alert('Please select a token');
            return;
        } else {
            mintTokens(selectedToken);
        }
    }

    async function getSelectedNft() {
        if (selectedNft === 'select' || selectedNft === '') {
            window.alert('Please select a nft');
            return;
        } else {
            getNftById(selectedNft);
        }
    }

    async function getSelectedSft() {
        if (selectedSft === 'select' || selectedSft === '') {
            window.alert('Please select a sft');
            return;
        } else {
            faucetMintSfts(selectedSft);
        }
    }

    return (
        <div
            className="d-flex flex-fill align-items-center justify-content-center container"
            style={{ marginTop: '50px', minHeight: '600px' }}
        >
            <div className="shadow-sm rounded p-4 border-0">
                <div
                    className="card-body text-center d-flex flex-column justify-content-center py-5"
                    style={{ color: 'white', minWidth: '400px' }}
                >
                    <div>
                        <select id="lang" onChange={(e) => handleSelectToken(e.currentTarget.value)}>
                            <option value="select">Select</option>
                            {tokensList.map((token, index) => (
                                <option key={`faucet-page-${token}-${index}`} value={token}>
                                    {token}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="d-flex just-content-center">
                        <button className="mt-4 add-liquidity-button" onClick={mintSelectedToken} disabled={!address}>
                            Mint Token
                        </button>
                    </div>
                </div>
                <div
                    className="card-body text-center d-flex flex-column justify-content-center py-5"
                    style={{ color: 'white', minWidth: '400px' }}
                >
                    <div>
                        <select id="lang" onChange={(e) => handleSelectedNft(e.currentTarget.value)}>
                            <option value="select">Select</option>
                            {nftList.map((token, index) => (
                                <option key={`faucet-page-${token}-${index}`} value={token}>
                                    {token}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="d-flex just-content-center">
                        <button className="mt-4 add-liquidity-button" onClick={getSelectedNft} disabled={!address}>
                            Get NFT
                        </button>
                    </div>
                </div>
                <div
                    className="card-body text-center d-flex flex-column justify-content-center py-5"
                    style={{ color: 'white', minWidth: '400px' }}
                >
                    <div>
                        <select id="lang" onChange={(e) => handleSelectedSft(e.currentTarget.value)}>
                            <option value="select">Select</option>
                            {sftList.map((token, index) => (
                                <option key={`faucet-page-${token}-${index}`} value={token}>
                                    {token}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="d-flex just-content-center">
                        <button className="mt-4 add-liquidity-button" onClick={getSelectedSft} disabled={!address}>
                            Get SFTs
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
