import React, { useEffect, useState } from 'react';

import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import { parseAmount } from '@multiversx/sdk-dapp/utils';
import { Row, Col } from 'react-bootstrap';
import { InfoTooltip } from 'components';
import { OURO_TOKEN_ID, USDC_TOKEN_ID, USDD_TOKEN_ID } from 'config';
import { selectNetstats, selectSlip, selectSwap } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import {
    addLpIntoSlipAndSendFrTokenToAddress,
    getTokenLogo,
    pauseSlipPool,
    removeSlipPool,
    unpauseSlipPool,
} from 'z/elrond';
import { SlipPoolType, SlipStateEnum, SwapPoolType } from 'z/types';
import { DEFAULT_DECIMALS, convertWeiToEsdt, formatNumber } from 'z/utils';

export const ExistingToken = () => {
    const slipRedux = useAppSelector(selectSlip);
    const swapRedux = useAppSelector(selectSwap);

    const handlePauseUnpause = (tokenIdentifier: string, status: SlipStateEnum) => {
        switch (status) {
            case SlipStateEnum.Active:
                return pauseSlipPool(tokenIdentifier);
            case SlipStateEnum.Inactive:
                return unpauseSlipPool(tokenIdentifier);
        }
    };

    const [poolPairs, setPoolPairs] = useState<
        {
            slipPool: SlipPoolType;
            swapPool: SwapPoolType | undefined;
        }[]
    >([]);

    useEffect(() => {
        if (slipRedux.basePool && slipRedux.pools && swapRedux.pools) {
            setPoolPairs([
                {
                    slipPool: slipRedux.basePool,
                    swapPool: swapRedux.pools.find(
                        ({ first_token_id, second_token_id }) =>
                            first_token_id === USDD_TOKEN_ID && second_token_id === OURO_TOKEN_ID,
                    ),
                },
                {
                    slipPool: slipRedux.pools.find(
                        ({ tokenIdentifier }) => tokenIdentifier === USDC_TOKEN_ID,
                    ) as SlipPoolType,
                    swapPool: swapRedux.pools.find(
                        ({ first_token_id, second_token_id }) =>
                            first_token_id === USDC_TOKEN_ID && second_token_id === OURO_TOKEN_ID,
                    ),
                },
                ...slipRedux.pools
                    .filter(
                        ({ status, tokenIdentifier }) =>
                            status !== SlipStateEnum.Removed && tokenIdentifier !== USDC_TOKEN_ID,
                    )
                    .map((slipPool: SlipPoolType) => {
                        const swapPool = swapRedux.pools.find(
                            ({ first_token_id, second_token_id }) =>
                                first_token_id === OURO_TOKEN_ID && second_token_id === slipPool.tokenIdentifier,
                        );
                        return {
                            slipPool,
                            swapPool,
                        };
                    }),
            ]);
        }
    }, [swapRedux, slipRedux]);

    const PoolRow = ({
        slipPool: { tokenIdentifier, status },
        swapPool,
    }: {
        slipPool: SlipPoolType;
        swapPool: SwapPoolType | undefined;
    }) => {
        const { address } = useGetAccount();
        const [lpAmount, setLpAmount] = useState('0');
        const [lpInputAmount, setLpInputAmount] = useState('');
        const [destinationAddress, setDestinationAddress] = useState('');

        const netstatsRedux = useAppSelector(selectNetstats);

        useEffect(() => {
            if (swapPool) {
                setLpAmount(netstatsRedux.walletTokensMap[swapPool.lp_token_id]?.balance || '0');
            }
        }, [address, swapPool, netstatsRedux.walletTokensMap]);

        function handleFreezeAndSend() {
            if (swapPool) {
                addLpIntoSlipAndSendFrTokenToAddress(
                    tokenIdentifier,
                    destinationAddress,
                    swapPool.lp_token_id,
                    parseAmount(lpInputAmount, DEFAULT_DECIMALS),
                );
            }
        }

        return (
            <Row
                style={{
                    rowGap: '10px',
                    marginTop: '30px',
                }}
            >
                <Col lg={4}>
                    <div className="d-flex align-items-center gap-3">
                        <div>
                            <img src={getTokenLogo(tokenIdentifier)} alt="logo" width="50px" />
                        </div>
                        <div className="ml-2">{tokenIdentifier}</div>
                    </div>
                </Col>
                <Col lg={8}>
                    <div
                        className="d-flex flex-row w-100"
                        style={{
                            rowGap: '5px',
                        }}
                    >
                        <button
                            className="slip-but w-50"
                            onClick={() => removeSlipPool(tokenIdentifier)}
                            disabled={status === SlipStateEnum.Active}
                        >
                            {`Remove`}
                        </button>
                        <button className="slip-but w-50" onClick={() => handlePauseUnpause(tokenIdentifier, status)}>
                            {status === SlipStateEnum.Active ? `Pause` : `Unpause`}
                        </button>
                    </div>
                </Col>

                <Col md={3}>
                    <input
                        className="vesta_x_input"
                        placeholder="LP Amount"
                        value={lpInputAmount}
                        type="number"
                        onChange={(e) => setLpInputAmount(e.target.value)}
                    />

                    <div className="d-flex justify-content-between mt-1">
                        <div className="input-token-error">{/* {LPAmountError} */}</div>
                        <div
                            className="add-liquidity-input-token-balance-box"
                            onClick={() => {
                                setLpInputAmount(
                                    convertWeiToEsdt(
                                        lpAmount,
                                        swapPool?.lp_token_decimals,
                                        swapPool?.lp_token_decimals,
                                    ).toFixed(),
                                );
                            }}
                        >
                            <div className="">Balance:&nbsp;</div>
                            <div
                                style={{
                                    color: 'white',
                                }}
                            >
                                {formatNumber(
                                    convertWeiToEsdt(
                                        lpAmount,
                                        swapPool?.lp_token_decimals,
                                        swapPool?.lp_token_decimals,
                                    ),
                                    swapPool?.lp_token_decimals,
                                )}
                            </div>
                        </div>
                    </div>
                </Col>

                <Col md={6}>
                    <input
                        className="vesta_x_input"
                        placeholder="Target ERD Address"
                        value={destinationAddress}
                        onChange={(e) => setDestinationAddress(e.target.value)}
                    />
                </Col>

                <Col md={3}>
                    <button className="slip-but w-100" onClick={handleFreezeAndSend}>
                        Freeze and send LP&nbsp;
                        <InfoTooltip title="Freeze the amount of LP Token entered in the field to the left and transfer it to the designated ERD Address" />
                    </button>
                </Col>
            </Row>
        );
    };

    return (
        <Col md={12}>
            <div className="slip-admin-panel-box">
                <div
                    className="text-center"
                    style={{
                        fontSize: '14px',
                        color: '#98A1C0',
                    }}
                >
                    Tokens that exist on SLIP
                </div>
                <hr className="my-3" />
                <Row className="align-items-center gy-4">
                    {poolPairs.map(({ slipPool, swapPool }, index: number) => {
                        return (
                            <PoolRow
                                key={`pool-row-${slipPool.tokenIdentifier}-${index}`}
                                slipPool={slipPool}
                                swapPool={swapPool}
                            />
                        );
                    })}
                </Row>
            </div>
        </Col>
    );
};
