import React, { useEffect, useState } from 'react';
import {
    useGetAccount,
    useGetLoginInfo,
    useGetNetworkConfig,
    useGetPendingTransactions,
} from '@multiversx/sdk-dapp/hooks';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { TbExchange } from 'react-icons/tb';
import { useSearchParams } from 'react-router-dom';
import { SlippageSetting } from 'components/SlippageSetting';
import { TOKEN_INFO_MAP } from 'config';
import { selectSwap } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import {
    elrondGetEquivalent,
    elrondMultiPairSwapFixedInput,
    elrondMultiPairSwapFixedOutput,
    getTokenBalanceFromApi,
    getTokenDecimals,
    routerEstimateSwapFixedInputs,
    routerEstimateSwapFixedOutputs,
} from 'z/elrond';
import {
    FocusedInputTokenType,
    SwapOperationsType,
    SwapPoolType,
    SwapResultType,
} from 'z/types';
import {
    applySlippage,
    formatNumber,
    convertEsdtToWei,
    convertWeiToEsdt,
    ERROR_INVALID_NUMBER,
    ERROR_NOT_ENOUGH_BALANCE,
    getSwapOperations,
    isPositiveOrZeroBigNumber,
    parseBigNumber,
    ERROR_CONNECT_WALLET,
    TEXT_SELECT_TOKEN,
    toastError,
    ZERO_STRING,
    isPositiveBigNumber,
    ERROR_TRANSACTION_ONGOING,
    LOADING_SWAP_PRICE_DEBOUNCE_PERIOD,
    createTokenTicker,
} from 'z/utils';
import { SwapTokenSelect } from './SwapTokenSelect';

function printFee(swapResult: SwapResultType, decimals: number): string {
    return formatNumber(convertWeiToEsdt(swapResult.total_fee, decimals).toNumber()) + ' ' + createTokenTicker(swapResult.payment_in.token_identifier);
}

// function printPriceImpact(swapResult: SwapResultType, pool: SwapPoolType): string {
//     const inDecimals = pool.first_token_id == swapResult.payment_in.token_identifier ? pool.first_token_decimals : pool.second_token_decimals;
//     const outDecimals = pool.first_token_id == swapResult.payment_in.token_identifier ? pool.second_token_decimals : pool.first_token_decimals;
//     const oldReserveIn = parseBigNumber(pool.first_token_id == swapResult.payment_in.token_identifier ? pool.first_token_reserve : pool.second_token_reserve);
//     const oldReserveOut = parseBigNumber(pool.first_token_id == swapResult.payment_in.token_identifier ? pool.second_token_reserve : pool.first_token_reserve);
//     const newReserveIn = oldReserveIn.plus(swapResult.payment_in.amount);
//     const newReserveOut = oldReserveOut.minus(swapResult.payment_out.amount);
//     const percent = Math.abs(convertWeiToEsdt(newReserveOut, outDecimals).multipliedBy(convertWeiToEsdt(oldReserveIn, inDecimals)).minus(convertWeiToEsdt(oldReserveOut, outDecimals).multipliedBy(convertWeiToEsdt(newReserveIn, inDecimals))).multipliedBy(100).dividedBy(convertWeiToEsdt(oldReserveIn, inDecimals).multipliedBy(convertWeiToEsdt(newReserveIn, inDecimals))).toNumber());
//     console.log(oldReserveIn.toString(), oldReserveOut.toString(), newReserveIn.toString(), newReserveOut.toString(), percent );
//     return percent < 0.001 ? '< 0.01%' : formatNumber(percent) + '%';
// }

function printPriceImpact(swapResult: SwapResultType, pool: SwapPoolType): string {
    if (!pool) return '';
    const isReversed = pool.first_token_id != swapResult.payment_in.token_identifier;
    const oldReserveIn = parseBigNumber(!isReversed ? pool.first_token_reserve : pool.second_token_reserve);
    const oldReserveOut = parseBigNumber(!isReversed ? pool.second_token_reserve : pool.first_token_reserve);
    const newReserveIn = oldReserveIn.plus(swapResult.payment_in.amount);
    const newReserveOut = oldReserveOut.minus(swapResult.payment_out.amount);
    const percent = Math.abs(oldReserveIn.multipliedBy(newReserveOut).minus(oldReserveOut.multipliedBy(newReserveIn)).multipliedBy(100).dividedBy(oldReserveOut.multipliedBy(newReserveIn)).toNumber());
    return percent < 0.001 ? '< 0.01%' : `${formatNumber(Math.min(percent, 99.99))}%`;
}

export const SwapBox = () => {
    const {
        chainID,
    } = useGetNetworkConfig();
    const { hasPendingTransactions } = useGetPendingTransactions();
    const { address } = useGetAccount();
    const { isLoggedIn } = useGetLoginInfo();
    const [searchParams, setSearchParams] = useSearchParams();
    const firstTokenId = searchParams.get('firstToken') ?? '';
    const secondTokenId = searchParams.get('secondToken') ?? '';

    const swapRedux = useAppSelector(selectSwap);
    const [firstTokenAmount, setFirstTokenAmount] = useState<string>(ZERO_STRING);
    const [secondTokenAmount, setSecondTokenAmount] = useState<string>(ZERO_STRING);
    const [firstTokenBalance, setFirstTokenBalance] = useState<string>(ZERO_STRING);
    const [secondTokenBalance, setSecondTokenBalance] = useState<string>(ZERO_STRING);
    const [firstTokenAmountError, setFirstTokenAmountError] = useState<string>('');
    const [secondTokenAmountError, setSecondTokenAmountError] = useState<string>('');
    const [swapOperations, setSwapOperations] = useState<SwapOperationsType>();
    const [focusedInputToken, setFocusedInputToken] = useState<FocusedInputTokenType>(FocusedInputTokenType.FirstToken);    // 0: fixed-in => out; 1: in => fixed-out
    const [showSwapDetails, setShowSwapDetails] = useState<boolean>(false);
    const [swapResults, setSwapResults] = useState<SwapResultType[]>([]);

    // query balance of input and output tokens
    useEffect(() => {
        if (hasPendingTransactions) return;
        if (address && firstTokenId) {
            (async () => {
                const _tokenBalanceInfo = await getTokenBalanceFromApi(address, firstTokenId);
                setFirstTokenBalance(_tokenBalanceInfo ? _tokenBalanceInfo.balance : ZERO_STRING);
            })();
        } else {
            setFirstTokenBalance(ZERO_STRING);
        }
    }, [address, hasPendingTransactions, firstTokenId]);

    useEffect(() => {
        if (hasPendingTransactions) return;
        if (address && secondTokenId) {
            (async () => {
                const _tokenBalanceInfo = await getTokenBalanceFromApi(address, secondTokenId);
                setSecondTokenBalance(_tokenBalanceInfo ? _tokenBalanceInfo.balance : ZERO_STRING);
            })();
        } else {
            setSecondTokenBalance(ZERO_STRING);
        }
    }, [address, hasPendingTransactions, secondTokenId]);

    const [exchangeRate, setExchangeRate] = useState<string>('');
    const [reverseExchangeRate, setReverseExchangeRate] = useState<string>('');
    const [minimumReceived, setMinimumReceived] = useState<string>(ZERO_STRING);

    //
    function resetInputs() {
        setFirstTokenAmount(ZERO_STRING);
        setSecondTokenAmount(ZERO_STRING);
        setMinimumReceived(ZERO_STRING);
        setFirstTokenAmountError('');
        setSecondTokenAmountError('');
        setSwapResults([]);
    }

    useEffect(() => {
        if (firstTokenId && secondTokenId && swapRedux.pools.length > 0) {
            const _swapOperations = getSwapOperations(swapRedux.pools, firstTokenId, secondTokenId);
            setSwapOperations(_swapOperations);

            if (!_swapOperations) {
                toastError('No Availbale Swap Route');
                onChangeSecondTokenId('');
            }
        } else {
            setSwapOperations(undefined);
        }
    }, [firstTokenId, secondTokenId, swapRedux.pools]);

    async function loadExchangeRate() {
        if (!(swapOperations && swapOperations.pairAddresses.length > 0)) return;
        if (hasPendingTransactions) return;
        if (!(firstTokenId && secondTokenId)) return;

        const amountOut = await elrondGetEquivalent(
            swapOperations.pairAddresses,
            swapOperations.tokenOuts,
            convertEsdtToWei(1, getTokenDecimals(firstTokenId)),
        );
        const _exchangeRate = convertWeiToEsdt(
            amountOut,
            getTokenDecimals(secondTokenId),
            getTokenDecimals(secondTokenId),
        );

        if (_exchangeRate.comparedTo(0) <= 0) {
            setExchangeRate('');
            setReverseExchangeRate('');
        } else {
            setExchangeRate(_exchangeRate.toFixed());
            setReverseExchangeRate(parseBigNumber(1).div(_exchangeRate).toFixed());
        }
    }

    useEffect(() => {
        if (hasPendingTransactions) return;

        resetInputs();
        loadExchangeRate();

        const delayDebounceFn = setInterval(() => {
            loadExchangeRate();
        }, LOADING_SWAP_PRICE_DEBOUNCE_PERIOD);
    
        return () => clearInterval(delayDebounceFn);
    }, [swapOperations, hasPendingTransactions]);

    useEffect(() => {
        if (focusedInputToken == FocusedInputTokenType.FirstToken) {
            onActiveChangeFirstTokenAmount(undefined);
        } else {
            onActiveChangeSecondTokenAmount(undefined);
        }
    }, [exchangeRate]);

    //
    function onChangeFirstTokenAmount(value: string) {
        let error = '';
        if (!firstTokenId) {
            error = TEXT_SELECT_TOKEN;
        } else if (!isPositiveOrZeroBigNumber(value)) {
            error = ERROR_INVALID_NUMBER;
        } else if (
            isLoggedIn
            && convertEsdtToWei(value, getTokenDecimals(firstTokenId)).comparedTo(firstTokenBalance) > 0
        ) {
            error = ERROR_NOT_ENOUGH_BALANCE;
        }

        setFirstTokenAmountError(error);
        setFirstTokenAmount(value);

        return error;
    }

    function onChangeSecondTokenAmount(value: string) {
        let error = '';
        if (!secondTokenId) {
            error = TEXT_SELECT_TOKEN;
        } else if (!isPositiveOrZeroBigNumber(value)) {
            error = ERROR_INVALID_NUMBER;
        }

        setSecondTokenAmountError(error);
        setSecondTokenAmount(value);

        return error;
    }

    //
    function onActiveChangeFirstTokenAmount(value: string | undefined) {
        if (value != null) {
            const error = onChangeFirstTokenAmount(value);

            if (!error || error == ERROR_NOT_ENOUGH_BALANCE) {
                setFocusedInputToken(FocusedInputTokenType.FirstToken); // fixed-in
                calculateFixedIn(value);
            }
        } else {
            calculateFixedIn(firstTokenAmount);
        }
    }

    function onActiveChangeSecondTokenAmount(value: string | undefined) {
        if (value != null) {
            const error = onChangeSecondTokenAmount(value);

            if (!error) {
                setFocusedInputToken(FocusedInputTokenType.SecondToken); // fixed-in
                calculateFixedOut(value);
            }
        } else {
            calculateFixedOut(secondTokenAmount);
        }
    }

    async function calculateFixedIn(amountIn: string) {
        if (parseBigNumber(amountIn).isZero()) {
            setSecondTokenAmount(ZERO_STRING);
            setMinimumReceived(ZERO_STRING);
            return;
        }

        if (!(swapOperations && swapOperations.pairAddresses.length > 0)) return;

        const _swapResults = await routerEstimateSwapFixedInputs(
            swapOperations.pairAddresses,
            swapOperations.tokenOuts,
            convertEsdtToWei(amountIn, getTokenDecimals(firstTokenId)),
        );
        setSwapResults(_swapResults);
        const amountOut = _swapResults[_swapResults.length - 1].payment_out.amount;

        onChangeSecondTokenAmount(
            amountOut ?
                convertWeiToEsdt(
                    amountOut,
                    getTokenDecimals(secondTokenId),
                    getTokenDecimals(secondTokenId)
                ).toFixed()
                : ZERO_STRING
        );
        setMinimumReceived(
            convertWeiToEsdt(
                applySlippage(amountOut, -swapRedux.slippage),
                getTokenDecimals(secondTokenId)
            ).toFixed()
        );
    }

    async function calculateFixedOut(amountOut: string) {
        if (parseBigNumber(amountOut).isZero()) {
            setFirstTokenAmount(ZERO_STRING);
            setMinimumReceived(ZERO_STRING);
            return;
        }

        if (!(swapOperations && swapOperations.pairAddresses.length > 0))
            return;

        // const amountIn = await elrondGetAmountIn(
        //     swapOperations.pairAddresses,
        //     swapOperations.tokenOuts,
        //     convertEsdtToWei(amountOut, getTokenDecimals(secondTokenId)),
        // );
        const _swapResults = await routerEstimateSwapFixedOutputs(
            swapOperations.pairAddresses,
            swapOperations.tokenOuts,
            convertEsdtToWei(amountOut, getTokenDecimals(secondTokenId)),
        );
        setSwapResults(_swapResults);
        const amountIn = _swapResults[0].payment_in.amount;

        onChangeFirstTokenAmount(
            convertWeiToEsdt(
                applySlippage(amountIn, swapRedux.slippage),
                getTokenDecimals(firstTokenId),
                getTokenDecimals(firstTokenId)
            ).toFixed()
        );
        setMinimumReceived(amountOut);
    }

    //
    function onChangeFirstTokenId(value: string) {
        setSearchParams({
            'firstToken': value,
            'secondToken': secondTokenId,
        });
    }

    function onChangeSecondTokenId(value: string) {
        setSearchParams({
            'firstToken': firstTokenId,
            'secondToken': value
        });
    }

    const onExchangeTokensPosition = () => {
        // both of tokens should be selected
        if (!firstTokenId || !secondTokenId) return;

        setSearchParams({
            'firstToken': secondTokenId,
            'secondToken': firstTokenId,
        });
    };

    function onMaxFirstTokenAmount() {
        if (isLoggedIn && firstTokenId && getTokenDecimals(firstTokenId)) {
            onActiveChangeFirstTokenAmount(
                convertWeiToEsdt(
                    firstTokenBalance,
                    getTokenDecimals(firstTokenId),
                    getTokenDecimals(firstTokenId),
                ).toFixed()
            );
        }
    }

    //
    async function onClickSwap() {
        let error = '';
        if (!isLoggedIn) {
            error = ERROR_CONNECT_WALLET;
        } else if (!firstTokenId || !secondTokenId) {
            error = TEXT_SELECT_TOKEN;
        } else if (!isPositiveBigNumber(firstTokenAmount)) {
            error = ERROR_INVALID_NUMBER;
        } else if (hasPendingTransactions) {
            error = ERROR_TRANSACTION_ONGOING;
        }

        if (error) {
            toastError(error);
            return;
        }
        if (firstTokenAmountError) {
            toastError(firstTokenAmountError);
            return;
        }
        if (secondTokenAmountError) {
            toastError(secondTokenAmountError);
            return;
        }
        if (!(swapOperations && swapOperations.pairAddresses.length > 0)) {
            toastError('Token and pool information is not loaded');
            return;
        }

        if (focusedInputToken === FocusedInputTokenType.FirstToken) {   // fixed-in
            await elrondMultiPairSwapFixedInput(
                swapOperations.pairAddresses,
                swapOperations.tokenOuts,
                firstTokenId,
                convertEsdtToWei(firstTokenAmount, getTokenDecimals(firstTokenId)).toFixed(0),
                convertEsdtToWei(minimumReceived, getTokenDecimals(secondTokenId)).toFixed(0),
                chainID,
            );
        } else {    // fixed-out
            await elrondMultiPairSwapFixedOutput(
                swapOperations.pairAddresses,
                swapOperations.tokenOuts,
                firstTokenId,
                convertEsdtToWei(firstTokenAmount, getTokenDecimals(firstTokenId)).toFixed(0),
                convertEsdtToWei(secondTokenAmount, getTokenDecimals(secondTokenId)).toFixed(0),
                chainID,
            );
        }
    }

    return (
        <>
            <div className="vesta_x_swap_card" style={{ maxWidth: '450px' }}>
                <div className="d-flex justify-content-between align-items-center">
                    <span
                        style={{
                            color: '#F1DC46',
                            marginLeft: '15px',
                            fontSize: '1.25rem',
                        }}
                    >
                        Swap
                    </span>

                    <SlippageSetting />
                </div>

                <div className="mt-3">
                    <div className="vesta_x_swap_input_box">
                        <div className="d-flex align-items-center">
                            <input
                                className="swap_input"
                                type="number"
                                value={firstTokenAmount}
                                onChange={(e) =>
                                    onActiveChangeFirstTokenAmount(e.target.value)
                                }
                            />
                            <SwapTokenSelect
                                tokenId={firstTokenId}
                                onChangeTokenId={onChangeFirstTokenId}
                                oppositeTokenId={secondTokenId}
                                disabled={hasPendingTransactions}
                            />
                        </div>
                        <div className="d-flex justify-content-between mt-1">
                            <div className="input-token-error">
                                {firstTokenAmountError}
                            </div>
                            <div
                                className="add-liquidity-input-token-balance-box"
                                onClick={onMaxFirstTokenAmount}
                            >
                                {
                                    firstTokenId && (<>
                                        <div className="">Balance:&nbsp;</div>
                                        <div style={{ color: 'white' }}>
                                            {
                                                formatNumber(
                                                    convertWeiToEsdt(
                                                        firstTokenBalance,
                                                        getTokenDecimals(firstTokenId),
                                                        getTokenDecimals(firstTokenId)
                                                    ),
                                                    getTokenDecimals(firstTokenId)
                                                )
                                            }
                                        </div>
                                    </>)
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="d-flex justify-content-center"
                    style={{ marginTop: '-17px', zIndex: '2' }}
                >
                    <button
                        className="exchange_but"
                        onClick={onExchangeTokensPosition}
                        disabled={hasPendingTransactions}
                    >
                        <TbExchange />
                    </button>
                </div>

                <div style={{ marginTop: '-17px' }}>
                    <div className="vesta_x_swap_input_box BToken_Input">
                        <div className="d-flex align-items-center">
                            <input
                                className="swap_input"
                                type="number"
                                value={secondTokenAmount}
                                onChange={(e) =>
                                    onActiveChangeSecondTokenAmount(e.target.value)
                                }
                            />
                            <SwapTokenSelect
                                tokenId={secondTokenId}
                                onChangeTokenId={onChangeSecondTokenId}
                                oppositeTokenId={firstTokenId}
                                disabled={hasPendingTransactions}
                            />
                        </div>
                        {
                            secondTokenId && (
                                <div className="d-flex justify-content-between mt-1">
                                    <div className="input-token-error">
                                        {secondTokenAmountError}
                                    </div>
                                    <span className="add-liquidity-input-token-balance-box">
                                        <div className="">Balance:&nbsp;</div>
                                        <div style={{ color: 'white' }}>
                                            {
                                                formatNumber(
                                                    convertWeiToEsdt(
                                                        secondTokenBalance,
                                                        getTokenDecimals(secondTokenId),
                                                        getTokenDecimals(secondTokenId),
                                                    ),
                                                    getTokenDecimals(secondTokenId),
                                                )
                                            }
                                        </div>
                                    </span>
                                </div>
                            )
                        }
                    </div>
                </div>

                <div className="swap-info-box" style={{ marginTop: '3px' }}>
                    <div className="swap-info mt-3">
                        <div className="swap-info-li">
                            <span>Exchange Route</span>
                            <span>
                                {swapOperations && [createTokenTicker(firstTokenId)].concat(swapOperations.tokenOuts.map(v => createTokenTicker(v))).join(' > ')}
                            </span>
                        </div>

                        <div className="swap-info-li">
                            <span>Exchange Rate</span>
                            <span>
                                {
                                    exchangeRate
                                        ? `1 ${createTokenTicker(firstTokenId)} ≃ ${formatNumber(exchangeRate, 6)} ${createTokenTicker(secondTokenId)}`
                                        : '-'
                                }
                            </span>
                        </div>

                        <div className="swap-info-li">
                            <span></span>
                            <span>
                                {
                                    reverseExchangeRate
                                        ? `${formatNumber(reverseExchangeRate, 6)} ${createTokenTicker(firstTokenId)} ≃ 1 ${createTokenTicker(secondTokenId)}`
                                        : '-'
                                }
                            </span>
                        </div>

                        <div className="swap-info-li">
                            <span>Slippage</span>
                            <span>{swapRedux.slippage + '%'}</span>
                        </div>

                        <div className="swap-info-li">
                            <span>
                                {focusedInputToken === FocusedInputTokenType.FirstToken ? 'Minimum Received' : 'Received'}
                            </span>
                            <span>
                                {
                                    `${formatNumber(minimumReceived)} ${createTokenTicker(secondTokenId)}`
                                }
                            </span>
                        </div>

                        {
                            showSwapDetails && (<>
                                <div className="border-top my-2"></div>

                                <div className="swap-info-li">
                                    <span>Fee</span>
                                    <span>{swapResults.length > 0 && swapResults.map((swapResult) => printFee(swapResult, TOKEN_INFO_MAP[swapResult.payment_in.token_identifier].decimals)).join(' + ')}</span>
                                </div>

                                <div className="swap-info-li">
                                    <span>Price Impact</span>
                                    <span>{swapResults.length > 0 && swapOperations && swapResults.map((swapResult, index) => printPriceImpact(swapResult, swapRedux.pools[swapOperations.poolIndexes[index]])).join(' + ')}</span>
                                </div>
                            </>)
                        }

                        <div
                            className="my-2 border-top pt-2 text-center cursor-pointer"
                            role="button"
                            onClick={() => {
                                setShowSwapDetails(!showSwapDetails);
                            }}
                        >
                            {
                                showSwapDetails ? (<>
                                    <span className="mr-1">Less details</span>
                                    <BiChevronUp />
                                </>) : (<>
                                    <span className="mr-1">More details</span>
                                    <BiChevronDown />
                                </>)
                            }
                        </div>
                    </div>
                </div>

                <div className="d-flex just-content-center">
                    <button
                        className="mt-3 add-liquidity-button"
                        onClick={onClickSwap}
                    >
                        Swap
                    </button>
                </div>
            </div>
        </>
    );
};
