import React, { FC } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { MVX_EXPLORER_URL } from 'config';

interface ElrondAddressLinkPropsType {
    address: string,
    precision?: number,
}

export const ElrondAddressLink: FC<ElrondAddressLinkPropsType> = ({
    address,
    precision = 6,
}) => {
    return (
        <>
            <a
                className="elrond-address-link"
                href={`${MVX_EXPLORER_URL}/accounts/${address}`}
                target="blank"
            >
                {address.substring(0, precision) + '...' + address.slice(-precision)}
                <FaExternalLinkAlt />
            </a>
        </>
    );
};
