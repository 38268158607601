import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { StableCoinProtocolContext } from 'z/types/stablecoin-protocol';

export interface StableCoinProtocolState {
    context: StableCoinProtocolContext;
}

const initialState: StableCoinProtocolState = {
    context: {
        total_collateral_usd_value: '0',
        total_issued_stable_coin_usd_value: '0',
        sgsc_supply: '0',
        sssc_supply: '0',
        spsc_supply: '0',
        usdd_supply: '0',
        eurd_supply: '0',
        total_mint_stablecoin_percentage: '0',
        total_mint_precious_metal_percentage: '0',
        percentage_denomination: '0',
        collateralization_ratio: '0',
        gsc_oracle_price: '0',
        ssc_oracle_price: '0',
        psc_oracle_price: '0',
    },
};

export const stableCoinProtocolSlice = createSlice({
    name: 'stableCoinProtocol',
    initialState,
    reducers: {
        setStableCoinProtocolContext: (state, action: PayloadAction<any>) => {
            state.context = action.payload;
        },
    },
});

export const { setStableCoinProtocolContext } = stableCoinProtocolSlice.actions;
export const selectStableCoinProtocol = (state: RootState) => state.stableCoinProtocol;

export const stableCoinProtocolReducer = stableCoinProtocolSlice.reducer;
