import React, { useState } from 'react';
import { NumberInput } from 'pages/StableCoin/NumberInput';
import { useStableCoinProtocol } from 'z/hooks/stable-coin-protocol';

export const AdminIncreasePreciousMetal = () => {
    const [inputTokenAmount, setInputTokenAmount] = useState('0');
    const { sendAdminIncreasePreciousMetalCollateralTransaction } = useStableCoinProtocol();
    const handleSendPreciousMetalsAction = async () => {
        await sendAdminIncreasePreciousMetalCollateralTransaction(inputTokenAmount);
    };
    return (
        <div className="active-pool-li-container mt-4">
            <div className="mb-1" style={{ fontSize: '18px', color: '#f1dc46' }}>
                Admin increase precious metals backing
            </div>
            <div>Use this to add Precious Metals to increase the collateralization factor.</div>
            <br />
            <NumberInput onChange={setInputTokenAmount} />
            <div className="w-100 d-flex justify-content-center mt-3">
                <button className="mt-3 add-liquidity-button" onClick={handleSendPreciousMetalsAction}>
                    Increase PM Backing
                </button>
            </div>
        </div>
    );
};
