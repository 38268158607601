import {
    JACKET_TOKEN_ID,
    PADAWAN_TOKEN_ID,
    RACCOON_TOKEN_ID,
    USDC_TOKEN_ID,
    VEGLD_TOKEN_ID,
    VUSDCJAKET_TOKEN_ID,
    VUSDCPADWN_TOKEN_ID,
    VVEGLDJKET_TOKEN_ID,
    VVEGLDPDWN_TOKEN_ID,
    VVEGLDRACCOON_TOKEN_ID,
} from 'config';

import { MemeLiquidityVestaVaultSelectedHolding } from 'z/types';

export const nativeMemeLiquidityTokenSelectorForVault = [
    {
        token_name: 'Based USDC JACKET',
        token_id: VUSDCJAKET_TOKEN_ID,
        holding_type: MemeLiquidityVestaVaultSelectedHolding.VaultUsdcJaket,
        first_token_id: USDC_TOKEN_ID,
        second_token_id: JACKET_TOKEN_ID,
    },
    {
        token_name: 'Based USDC PADAWAN',
        token_id: VUSDCPADWN_TOKEN_ID,
        holding_type: MemeLiquidityVestaVaultSelectedHolding.VaultUsdcPadwn,
        first_token_id: USDC_TOKEN_ID,
        second_token_id: PADAWAN_TOKEN_ID,
    },
];

export const securedMemeLiquidityTokenSelectorForVault = [
    {
        token_name: 'Based VEGLD JACKET',
        token_id: VVEGLDJKET_TOKEN_ID,
        holding_type: MemeLiquidityVestaVaultSelectedHolding.VaultVegldJaket,
        first_token_id: VEGLD_TOKEN_ID,
        second_token_id: JACKET_TOKEN_ID,
    },
    {
        token_name: 'Based VEGLD PADAWAN',
        token_id: VVEGLDPDWN_TOKEN_ID,
        holding_type: MemeLiquidityVestaVaultSelectedHolding.VaultVegldPadwn,
        first_token_id: VEGLD_TOKEN_ID,
        second_token_id: PADAWAN_TOKEN_ID,
    },
    {
        token_name: 'Based VEGLD RACCOON',
        token_id: VVEGLDRACCOON_TOKEN_ID,
        holding_type: MemeLiquidityVestaVaultSelectedHolding.VaultVegldRaccoon,
        first_token_id: VEGLD_TOKEN_ID,
        second_token_id: RACCOON_TOKEN_ID,
    },
];

export const nativeMemeLiquidityTokenSelectorForWallet = [
    {
        token_name: 'Based USDC JACKET',
        token_id: VUSDCJAKET_TOKEN_ID,
        holding_type: MemeLiquidityVestaVaultSelectedHolding.WalletUsdcJaket,
        first_token_id: USDC_TOKEN_ID,
        second_token_id: JACKET_TOKEN_ID,
    },
    {
        token_name: 'Based USDC PADAWAN',
        token_id: VUSDCPADWN_TOKEN_ID,
        holding_type: MemeLiquidityVestaVaultSelectedHolding.WalletUsdcPadwn,
        first_token_id: USDC_TOKEN_ID,
        second_token_id: PADAWAN_TOKEN_ID,
    },
];

export const securedMemeLiquidityTokenSelectorForWallet = [
    {
        token_name: 'Based VEGLD JACKET',
        token_id: VVEGLDJKET_TOKEN_ID,
        holding_type: MemeLiquidityVestaVaultSelectedHolding.WalletVegldJaket,
        first_token_id: VEGLD_TOKEN_ID,
        second_token_id: JACKET_TOKEN_ID,
    },
    {
        token_name: 'Based VEGLD PADAWAN',
        token_id: VVEGLDPDWN_TOKEN_ID,
        holding_type: MemeLiquidityVestaVaultSelectedHolding.WalletVegldPadwn,
        first_token_id: VEGLD_TOKEN_ID,
        second_token_id: PADAWAN_TOKEN_ID,
    },
    {
        token_name: 'Based VEGLD RACCOON',
        token_id: VVEGLDRACCOON_TOKEN_ID,
        holding_type: MemeLiquidityVestaVaultSelectedHolding.WalletVegldRaccoon,
        first_token_id: VEGLD_TOKEN_ID,
        second_token_id: RACCOON_TOKEN_ID,
    },
];
