import { NftType, TokenType } from '@multiversx/sdk-dapp/types/tokens.types';
import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { DEMI_METAESDT_IDS, WALLET_COLLECTION_IDS } from 'config';
import { RootState } from 'redux/store';
import { getAccountNftsByCollections, getDeb } from 'z/elrond';
import { ElrondStatsType } from 'z/types';
import { ZERO_STRING } from 'z/utils';

export interface NetstatsState {
    vegldInEgldPrice: string;
    elrondStats: ElrondStatsType | undefined;
    // portfolioTokens: TokenType[],
    walletTokensMap: Record<string, TokenType>;
    walletNftsSftsMap?: Record<string, NftType[]>;
    walletMetaEsdtMap?: Record<string, NftType[]>;
    deb?: number;
}

const initialState: NetstatsState = {
    vegldInEgldPrice: ZERO_STRING,
    elrondStats: undefined,
    // portfolioTokens: [],
    walletTokensMap: {},
};

export const netstatsSlice = createSlice({
    name: 'netstats',
    initialState,
    reducers: {
        setVegldInEgldPrice: (state, action: PayloadAction<string>) => {
            state.vegldInEgldPrice = action.payload;
        },
        setElrondStats: (state, action: PayloadAction<ElrondStatsType | undefined>) => {
            state.elrondStats = action.payload;
        },
        // setPortfolioTokens: (state, action: PayloadAction<TokenType[]>) => {
        //   state.portfolioTokens = action.payload;
        // },
        setWalletTokensMap: (state, action: PayloadAction<Record<string, TokenType>>) => {
            state.walletTokensMap = action.payload;
        },
        setWalletNftsSftsMap: (state, action: PayloadAction<Record<string, NftType[]>>) => {
            state.walletNftsSftsMap = action.payload;
        },
        setWalletMetaEsdtMap: (state, action: PayloadAction<Record<string, NftType[]>>) => {
            state.walletMetaEsdtMap = action.payload;
        },
        setUserDeb: (state: NetstatsState, action: PayloadAction<number | undefined>) => {
            state.deb = action.payload;
        },
    },
});

export const {
    setVegldInEgldPrice,
    setElrondStats,
    // setPortfolioTokens,
    setWalletTokensMap,
} = netstatsSlice.actions;

export const selectNetstats = (state: RootState) => state.netstats;

export const netstatsReducer = netstatsSlice.reducer;

export const setWalletNftsSftsMap = async (address: string, dispatch: Dispatch) => {
    const nftsSftsMap = await getAccountNftsByCollections(address, WALLET_COLLECTION_IDS);
    const walletNftsSftsMap: Record<string, NftType[]> = {};
    for (let index = 0; index < WALLET_COLLECTION_IDS.length; index++) {
        walletNftsSftsMap[WALLET_COLLECTION_IDS[index]] = nftsSftsMap[index].sort(({ nonce }, { nonce: nonce2 }) =>
            nonce > nonce2 ? 1 : -1,
        );
    }
    dispatch(netstatsSlice.actions.setWalletNftsSftsMap(walletNftsSftsMap));
};

export const setWalletMetaEsdtMap = async (address: string, dispatch: Dispatch) => {
    const metaEsdtMap = await getAccountNftsByCollections(address, DEMI_METAESDT_IDS);
    const walletMetaEsdtMap: Record<string, NftType[]> = {};
    for (let index = 0; index < DEMI_METAESDT_IDS.length; index++) {
        walletMetaEsdtMap[DEMI_METAESDT_IDS[index]] = metaEsdtMap[index];
    }
    dispatch(netstatsSlice.actions.setWalletMetaEsdtMap(walletMetaEsdtMap));
};

export const setUserDeb = async (address: string, dispatch: Dispatch) => {
    const _deb = await getDeb(address);
    dispatch(netstatsSlice.actions.setUserDeb(_deb));
};
