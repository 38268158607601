import React from 'react';
import clsx from 'clsx';
import {
  selectSwap,
} from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { getTokenLogo } from 'z/elrond';
import {
  formatNumber,
  createTokenTicker,
  convertWeiToEsdt,
} from 'z/utils';

export const AssetsLiquidity = () => {
  const swapRedux = useAppSelector(selectSwap);

  return (
    <div className='row mt-4'>
      <div className='col-12'>
        {swapRedux.pools.length > 0 ? swapRedux.pools.map((pool, index) => (
            <div className={clsx(index < swapRedux.pools.length - 1 && 'portfolio-token-row', 'd-flex justify-content-between align-items-center portfolio-token-row-common')} key={`d-p-t-${index}`}>
                <div className='d-flex align-items-center'>
                    <img
                        src={getTokenLogo(pool.first_token_id)}
                        width={'40px'}
                        height={'40px'}
                        alt={`${createTokenTicker(pool.first_token_id)} logo`}
                    />
                    <img
                        src={getTokenLogo(pool.second_token_id)}
                        width={'40px'}
                        height={'40px'}
                        alt={`${createTokenTicker(pool.second_token_id)} logo`}
                    />
                    <div className='text-white' style={{ marginLeft: '8px' }}>
                        {pool.lp_token_balance ? formatNumber(convertWeiToEsdt(pool.lp_token_balance, pool.lp_token_decimals, pool.lp_token_decimals)) : '0'}{' '}
                        <span className='text-secondary-color' style={{ fontSize: '.9rem' }}>{createTokenTicker(pool.lp_token_id)}</span>
                    </div>
                </div>
                {/* <div>
                    {'≃ '}
                    <span style={{ color: '#f0f0f0' }}>
                      {'$'}
                      {row.balance && netstatsRedux.tokenPrices[row.identifier] ? formatNumber(convertWeiToEsdt(row.balance, row.decimals).multipliedBy(netstatsRedux.tokenPrices[row.identifier])) : '0'}
                    </span>
                </div> */}
            </div>
        )) : 'No tokens'}
      </div>
    </div>
  );
};