import React, { useEffect, useState } from 'react';
import { TokenTransfer } from '@multiversx/sdk-core/out';
import { useGetAccountInfo, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';
import { Col, Row } from 'react-bootstrap';
import { AURYN_TOKEN_ID, EAURYN_TOKEN_ID, OURO_TOKEN_ID } from 'config';
import { selectNetstats } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { getTokenLogo, snakeCoil } from 'z/elrond';
import { DEFAULT_DECIMALS, convertEsdtToWei } from 'z/utils';

interface IAutostakeCoilRowProps {
    action: 'Coil OURO' | 'Coil AURYN' | 'Curl OURO' | 'Reserve OURO';
    exchangeRate: BigNumber;
}

export const AutostakeCoilRow = ({ action, exchangeRate }: IAutostakeCoilRowProps) => {
    const { address } = useGetAccountInfo();
    const { hasPendingTransactions } = useGetPendingTransactions();

    const netstatsRedux = useAppSelector(selectNetstats);

    const inputToken = ['Coil OURO', 'Curl OURO', 'Reserve OURO'].includes(action) ? OURO_TOKEN_ID : AURYN_TOKEN_ID;
    const outputToken =
        action === 'Coil OURO'
            ? AURYN_TOKEN_ID
            : action === 'Coil AURYN' || action === 'Curl OURO'
            ? EAURYN_TOKEN_ID
            : 'N/A';

    const [inputTokenAmount, setInputTokenAmount] = useState('0');

    useEffect(() => {
        if (hasPendingTransactions) return;
        setInputTokenAmount('0');
    }, [hasPendingTransactions, address, inputToken]);

    const handlePerformAction = async () => {
        if (parseFloat(inputTokenAmount) <= 0 || hasPendingTransactions) return;

        const payments = [
            TokenTransfer.fungibleFromBigInteger(inputToken, convertEsdtToWei(inputTokenAmount, DEFAULT_DECIMALS)),
        ];
        const isDoubleCoil = action === 'Curl OURO';
        await snakeCoil(
            payments,
            address,
            isDoubleCoil, // isDoubleCoil
        );
    };

    function isNumber(text: string) {
        return /^-?\d*\.?\d+$/.test(text);
    }

    const handleInputSelect = (e: string) => {
        const value = e.replace(',', '.');
        const separatorCount = (value.match(/[.]/g) || []).length;

        if (separatorCount > 1) {
            return;
        }

        if (value.endsWith('.') || value.endsWith(',')) {
            setInputTokenAmount(value);
            return;
        }
        if (value === '' || value === '.' || value === ',' || !isNumber(value)) {
            setInputTokenAmount('0');
            return;
        }

        if (parseFloat(value) < 0) {
            setInputTokenAmount('0');
            return;
        }

        if (value.includes('.')) {
            const parts = value.split('.');
            if (parts[1].length > 18 || parts.length > 2) return;
        }

        const targetAmount = new BigNumber(value).shiftedBy(18);
        const _userBalance = new BigNumber(netstatsRedux.walletTokensMap[inputToken]?.balance ?? '0');

        if (targetAmount.isGreaterThan(_userBalance)) {
            setInputTokenAmount(_userBalance.shiftedBy(-18).toString());
        } else {
            setInputTokenAmount(value);
        }
    };

    return (
        <Row
            className="second-card-style d-flex justify-content-between py-1 align-items-center"
            style={{ backgroundColor: '#1B0921', rowGap: '4px' }}
        >
            <Col lg={1}>
                <div className="d-flex">
                    <div className="coil-box">
                        <img src={getTokenLogo(inputToken)} width={'40px'} />
                        <div>{action.split(' ')[0]}</div>
                    </div>
                </div>
            </Col>

            <Col lg={10}>
                <div className="coil-row-container ">
                    <div className="d-flex gap-1 w-100">
                        <div className="w-100">
                            <input
                                className="coil-input w-100"
                                onChange={(e) => handleInputSelect(e.target.value)}
                                value={inputTokenAmount}
                                type="text"
                            />
                            <div className="text-right" style={{ fontSize: '0.8rem', marginRight: '10px' }}>
                                {inputToken}
                            </div>
                        </div>
                        <div>
                            <img src={getTokenLogo(inputToken)} width={'40px'} />
                        </div>
                    </div>

                    <div className="h-100">
                        <button
                            className="farm_but py-2 h-100"
                            onClick={() => handleInputSelect(netstatsRedux.walletTokensMap[inputToken]?.balance ?? '0')}
                        >
                            =
                            <br />
                            MAX
                        </button>
                    </div>

                    <div className="d-flex gap-1 w-100">
                        <div>
                            <img src={getTokenLogo(outputToken)} width={'40px'} />
                        </div>
                        <div className="w-100">
                            {/* <input
                                className="coil-input text-start disabled"
                                value={(parseFloat(inputTokenAmount) * exchangeRate)
                                    .toFixed(18)
                                    .replace(/(\.0*|(?<=\.\d*?)0*)$/, '')}
                                type="text"
                                disabled
                            /> */}
                            <input
                                className="coil-input text-start disabled"
                                value={new BigNumber(inputTokenAmount)
                                    .shiftedBy(18)
                                    .multipliedBy(exchangeRate)
                                    .shiftedBy(-18)
                                    .toFixed(18)
                                    .replace(/(\.0*|(?<=\.\d*?)0*)$/, '')}
                                type="text"
                                disabled
                            />
                            <div style={{ fontSize: '0.8rem' }}>{outputToken}</div>
                        </div>
                    </div>
                </div>
            </Col>

            <Col lg={1}>
                <div className="d-flex justify-content-end">
                    <button className="coil-but" onClick={handlePerformAction}>
                        <img src={getTokenLogo(inputToken)} width={'40px'} />
                        <div>{action.split(' ')[0]}</div>
                    </button>
                </div>
            </Col>
        </Row>
    );
};
