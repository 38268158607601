import {
    BLOODSHED_COLLECTION,
    CODING_DIVISION_COLLECTION,
    MIDAS_OG_COLLECTION,
    NOSFERATU_COLLECTION,
    SHARE_NONCE,
    SNAKE_COLLECTION,
    SNAKE_NONCE,
    VAULT_BOOSTER_COLLECTION,
    // VESTADAO_COLLECTION,
    X_BUNNIES_COLLECTION,
} from 'config';
import { routeNames } from 'routes';
import { StakingPoolType, SubsidiaryStakingPoolType } from 'z/types';

export const stakingPools: StakingPoolType[] = [
    {
        avatar: '/img/sfts/snake/snake-01.jpg',
        name: 'Snakes',
        traitlist: ['5% of Ouro Pie := 5% of DEmm + 1.05% of GAS'],
        docsUrl:
            'https://demiourgos-holdings-tm.gitbook.io/demiourgos-library/v/nft-staking/snake-nfts-sfts-shareholders',
    },
    {
        avatar: '/img/sfts/snake/snake-02.jpg',
        name: 'Shareholders',
        traitlist: ['10% of Ouro Pie := 10% of DEmm + 2.1% of GAS'],
        docsUrl:
            'https://demiourgos-holdings-tm.gitbook.io/demiourgos-library/v/nft-staking/snake-nfts-sfts-shareholders',
    },
    {
        avatar: '/img/nft-staking/Subsidiaries_Avatar.jpg',
        name: 'Subsidiaries',
        traitlist: ['15% of Ouro Pie := 15% of DEmm + 3.15% of GAS'],
    },
];

export const getStakingPoolUrls = (): string[] => [
    `${routeNames?.nftStaking}/0`,
    `${routeNames?.nftStaking}/1`,
    `${routeNames?.nftStaking}/subsidiary`,
];

export const SINGLE_NFT_STAKING_POOL_INFO = [
    {
        name: 'Snake',
        identifier: SNAKE_COLLECTION,
        nonce: SNAKE_NONCE,
    },
    {
        name: 'Shareholder',
        identifier: SNAKE_COLLECTION,
        nonce: SHARE_NONCE,
    },
];

export const SUBSIDIARY_STAKING_POOL_INFO: SubsidiaryStakingPoolType[] = [
    {
        avatar: '/img/nft-staking/Coding_Division_Avatar.png',
        name: 'Coding Division',
        identifier: CODING_DIVISION_COLLECTION,
        type: 'SFT',
        auxSelector: 'coding_division',
        docsUrl: 'https://demiourgos-holdings-tm.gitbook.io/demiourgos-library/v/nft-staking/coding-division-sfts',
    },
    // {
    //     avatar: '/img/sfts/vesta-dao/vesta-dao-01.jpg',
    //     name: 'Vesta X DAO',
    //     description: 'farm booster | vault booster',
    //     identifier: VESTADAO_COLLECTION,
    //     type: 'SFT',
    //     auxSelector: 'vesta_x_dao',
    //     docsUrl: 'https://demiourgos-holdings-tm.gitbook.io/demiourgos-library/v/nft-staking/vestax-tm-dao-sfts',
    // },
    {
        avatar: '/img/nft-staking/Bloodshed_Avatar.jpg',
        name: 'Bloodshed',
        identifier: BLOODSHED_COLLECTION,
        type: 'NFT',
        auxSelector: 'bloodshed',
        docsUrl: 'https://demiourgos-holdings-tm.gitbook.io/demiourgos-library/v/nft-staking/bloodshed-nfts',
    },
    {
        avatar: '/img/nft-staking/XBunny_Avatar.jpg',
        name: 'xBunnies',
        description: 'farm booster',
        identifier: X_BUNNIES_COLLECTION,
        type: 'NFT',
        auxSelector: 'xbunnies',
        docsUrl: 'https://demiourgos-holdings-tm.gitbook.io/demiourgos-library/v/nft-staking/xbunnies-nfts',
    },
    {
        avatar: '/img/nft-staking/Nosferatu_Avatar.jpg',
        name: 'Nosferatu',
        identifier: NOSFERATU_COLLECTION,
        type: 'NFT',
        auxSelector: 'nosferatu',
        docsUrl: 'https://demiourgos-holdings-tm.gitbook.io/demiourgos-library/v/nft-staking/nosferatu-nfts',
    },
    {
        avatar: '/img/sfts/vault-booster/vault-booster-01.jpg',
        name: 'Vault Boosters',
        description: 'vault booster',
        identifier: VAULT_BOOSTER_COLLECTION,
        type: 'SFT',
        auxSelector: 'vault_booster',
        docsUrl:
            'https://demiourgos-holdings-tm.gitbook.io/demiourgos-library/v/nft-staking/seasonal-vault-tm-booster-sfts',
    },
    {
        avatar: '/img/sfts/midas-og/midas-og-01.jpg',
        name: 'Midas OG',
        identifier: MIDAS_OG_COLLECTION,
        type: 'SFT',
        auxSelector: 'midas_og',
        docsUrl: 'https://demiourgos-holdings-tm.gitbook.io/demiourgos-library/v/nft-staking/midasog-sfts',
    },
];
