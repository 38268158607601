import { useEffect, useState } from 'react';
import { useGetAccount, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import { selectNetstats, setWalletMetaEsdtMap, setWalletNftsSftsMap } from 'redux/reducers';
import { useAppDispatch, useAppSelector } from 'redux/store';

export const useWalletAssetsHooks = () => {
    const { hasPendingTransactions } = useGetPendingTransactions();
    const { address } = useGetAccount();
    const dispatch = useAppDispatch();

    const netstatsRedux = useAppSelector(selectNetstats);

    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        if (!address || hasPendingTransactions) return;

        if (isInitialized || !netstatsRedux.walletNftsSftsMap) {
            setWalletNftsSftsMap(address, dispatch);
        }

        if (isInitialized || !netstatsRedux.walletMetaEsdtMap) {
            setWalletMetaEsdtMap(address, dispatch);
        }

        setIsInitialized(true);
    }, [address, hasPendingTransactions]);
};
