import { NftType } from '@multiversx/sdk-dapp/types/tokens.types';
import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { NFT_STAKING_V2_SC_ADDRESS, WALLET_COLLECTION_IDS } from 'config';
import { RootState } from 'redux/store';
import {
    getAccountNftsByCollections,
    getNftStakingRewards,
    getNftStakingTotalSnapshot,
    getNftStakingUserContext,
    getNftStakingUserFeeContext,
    getNftStakingUserSnapshot,
} from 'z/elrond';
import {
    NftStakingRewards,
    NftStakingSnapshotType,
    NftStakingUserContextType,
    NftStakingUserFeeContextType,
} from 'z/types';

interface NftStakingState {
    userContext?: NftStakingUserContextType;
    userFeeContext?: NftStakingUserFeeContextType;
    userSnapshot?: NftStakingSnapshotType;
    totalSnapshot?: NftStakingSnapshotType;
    totalStakedNftsSftsMap?: Record<string, NftType[]>;
    rewards?: NftStakingRewards;
}

const initialState: NftStakingState = {};

export const nftStakingV2Slice = createSlice({
    name: 'nftStakingV2',
    initialState,
    reducers: {
        setNftStakingUserContext: (
            state: NftStakingState,
            action: PayloadAction<NftStakingUserContextType | undefined>,
        ) => {
            state.userContext = action.payload;
        },
        setNftStakingUserFeeContext: (
            state: NftStakingState,
            action: PayloadAction<NftStakingUserFeeContextType | undefined>,
        ) => {
            state.userFeeContext = action.payload;
        },
        setNftStakingUserSnapshot: (
            state: NftStakingState,
            action: PayloadAction<NftStakingSnapshotType | undefined>,
        ) => {
            state.userSnapshot = action.payload;
        },
        setNftStakingTotalSnapshot: (
            state: NftStakingState,
            action: PayloadAction<NftStakingSnapshotType | undefined>,
        ) => {
            state.totalSnapshot = action.payload;
        },
        setTotalStakedNftsSftsMap: (state: NftStakingState, action: PayloadAction<Record<string, NftType[]>>) => {
            state.totalStakedNftsSftsMap = action.payload;
        },
        setRewards: (state: NftStakingState, action: PayloadAction<NftStakingRewards | undefined>) => {
            state.rewards = action.payload;
        },
    },
});

export const selectNftStakingV2 = (state: RootState) => state.nftStakingV2;
export const nftStakingV2Reducer = nftStakingV2Slice.reducer;

export const setNftStakingUserContextMap = async (address: string, dispatch: Dispatch) => {
    const nftStakingUserContext = await getNftStakingUserContext(address);
    if (nftStakingUserContext)
        dispatch(
            nftStakingV2Slice.actions.setNftStakingUserContext({
                ...nftStakingUserContext,
                staked_bloodshed: nftStakingUserContext.staked_bloodshed.sort(
                    ({ token_nonce }, { token_nonce: token_nonce2 }) => (token_nonce > token_nonce2 ? 1 : -1),
                ),
                staked_nosferatu: nftStakingUserContext.staked_nosferatu.sort(
                    ({ token_nonce }, { token_nonce: token_nonce2 }) => (token_nonce > token_nonce2 ? 1 : -1),
                ),
                staked_xbunnies: nftStakingUserContext.staked_xbunnies.sort(
                    ({ token_nonce }, { token_nonce: token_nonce2 }) => (token_nonce > token_nonce2 ? 1 : -1),
                ),
                staked_coding_division: nftStakingUserContext.staked_coding_division.sort(
                    ({ token_nonce }, { token_nonce: token_nonce2 }) => (token_nonce > token_nonce2 ? 1 : -1),
                ),
            }),
        );
};

export const setNftStakingUserFeeContext = async (address: string, dispatch: Dispatch) => {
    const _stakeNftsUserFeeContext = await getNftStakingUserFeeContext(address);
    dispatch(nftStakingV2Slice.actions.setNftStakingUserFeeContext(_stakeNftsUserFeeContext));
};

export const setNftStakingUserSnapshot = async (address: string, dispatch: Dispatch) => {
    const _userSnapshot = await getNftStakingUserSnapshot(address);
    dispatch(nftStakingV2Slice.actions.setNftStakingUserSnapshot(_userSnapshot));
};

export const setNftStakingTotalSnapshot = async (dispatch: Dispatch) => {
    const _totalSnapshot = await getNftStakingTotalSnapshot();
    dispatch(nftStakingV2Slice.actions.setNftStakingTotalSnapshot(_totalSnapshot));
};

export const setTotalStakedNftsSfts = async (dispatch: Dispatch) => {
    const _nftsSftsMap = await getAccountNftsByCollections(NFT_STAKING_V2_SC_ADDRESS, WALLET_COLLECTION_IDS);

    const _scNftsSftsMap: Record<string, NftType[]> = {};
    for (let index = 0; index < WALLET_COLLECTION_IDS.length; index++) {
        _scNftsSftsMap[WALLET_COLLECTION_IDS[index]] = _nftsSftsMap[index];
    }
    dispatch(nftStakingV2Slice.actions.setTotalStakedNftsSftsMap(_scNftsSftsMap));
};

export const setNftStakingRewards = async (address: string, dispatch: Dispatch) => {
    const _rewards = await getNftStakingRewards(address);
    dispatch(nftStakingV2Slice.actions.setRewards(_rewards));
};
