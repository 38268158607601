import { NftStakingPoolConfig, NftStakingPoolTypeEnum } from "../z/types/nft-staking";
import { config } from "./elrond";
import { SNAKE_COLLECTION } from './nfts-sfts';

export const NFT_STAKING_SC_ADDRESS = config.contracts['snake-nft-staking'].address;

export const NFT_STAKING_CONFIG: NftStakingPoolConfig[] = [
  {
    poolType: NftStakingPoolTypeEnum.CodingDivisionSfts,
    name: 'Coding Division',
    tokenIdentifier: 'DHCD-bc9963',
  },
  {
    poolType: NftStakingPoolTypeEnum.VestaXDAO,
    name: 'VestaX DAO',
    tokenIdentifier: 'VESTAXDAO-e6c48c',
  },
  // {
  //   poolType: NftStakingPoolTypeEnum.Bloodshed,
  //   name: 'Bloodshed NFT',
  //   tokenIdentifier: 'BLOODSHED-a62781',
  // },
  {
    poolType: NftStakingPoolTypeEnum.Nosferatu,
    name: 'Nosferatu NFT',
    tokenIdentifier: 'NOSFERATU-2b0485',
  },
  {
    poolType: NftStakingPoolTypeEnum.SnakesSfts,
    name: 'Snake Staking',
    tokenIdentifier: SNAKE_COLLECTION,
    nonce: 1,
  },
  {
    poolType: NftStakingPoolTypeEnum.SharesSfts,
    name: 'Shareholder Staking',
    tokenIdentifier: SNAKE_COLLECTION,
    nonce: 2,
  },
  {
    poolType: NftStakingPoolTypeEnum.XBunnies,
    name: 'XBunnies NFT',
    tokenIdentifier: 'DHXBUNNIES-862129',
  },
];

export const NFT_STAKING_NFT_SUPPLIES = [
  {
    poolType: NftStakingPoolTypeEnum.CodingDivisionSfts,
    supply: 5010,
  },
  {
    poolType: NftStakingPoolTypeEnum.VestaXDAO,
    supply: 7002,
  },
  {
    poolType: NftStakingPoolTypeEnum.Bloodshed,
    supply: 8861,
  },
  {
    poolType: NftStakingPoolTypeEnum.Nosferatu,
    supply: 1500,
  },
  {
    poolType: NftStakingPoolTypeEnum.SnakesSfts,
    supply: 1000,
  },
  {
    poolType: NftStakingPoolTypeEnum.SharesSfts,
    supply: 1000000,
  },
  {
    poolType: NftStakingPoolTypeEnum.XBunnies,
    supply: 1120,
  },
  // {
  //   poolType: NftStakingPoolTypeEnum.DHVaultBoosters,
  //   supply: 300,
  // },
];

export const getNftStakingEligibleCollections = (): string[] => {
  return Array.from(
    new Set(
      NFT_STAKING_CONFIG.filter(
        (cfg) => cfg.poolType === NftStakingPoolTypeEnum.SnakesSfts,
      ).map((poolInfo: NftStakingPoolConfig) => poolInfo.tokenIdentifier),
    ),
  );
};

// export const UNBONDING_TIMESTAMP = 3 * 24 * 3600 * 1000;
export const UNBONDING_TIMESTAMP = 3600;
