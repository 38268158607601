import React, { useState } from 'react';
import BigNumber from 'bignumber.js';
import { Row } from 'react-bootstrap';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { DebSwitch } from 'components';
import { PageBack, TooltipLink } from 'components/Elements';
import { DEB_PRECISION } from 'config';
import { selectNetstats, selectNftStakingV2 } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { routeNames } from 'routes';
import { nftStakingDisableSubsidiaryDeb, nftStakingEnableSubsidiaryDeb } from 'z/elrond';
import { useNftStakingHooks } from 'z/hooks/nft-staking';
import { NftStakingSnapshotKeysType, StakingPoolInfoType } from 'z/types';
import { formatNumber } from 'z/utils';
import { SUBSIDIARY_STAKING_POOL_INFO } from './const';
import StakingPoolInfo from './StakingPoolInfo';
import StakingRowInfo from './StakingRowInfo';

const SubsidiaryStaking = () => {
    useNftStakingHooks();
    const netstatsRedux = useAppSelector(selectNetstats);
    const nftStakingRedux = useAppSelector(selectNftStakingV2);

    const [showStats, setShowStats] = useState<boolean>(true);

    const getPoolInfo = (): StakingPoolInfoType[] => {
        const subsidiary_score = new BigNumber(nftStakingRedux.userSnapshot?.subsidiary_score || '0');
        return [
            {
                key: 'Total score',
                value: formatNumber(nftStakingRedux.totalSnapshot?.subsidiary_score || '0', 5),
            },
            {
                key: 'Your score',
                value: formatNumber(subsidiary_score, 5),
            },
            {
                key: 'Your reward share',
                value: `${formatNumber(
                    subsidiary_score
                        .multipliedBy(1000)
                        .div(new BigNumber(nftStakingRedux.totalSnapshot?.subsidiary_score || '1')),
                    3,
                )}‰`,
            },
            {
                key: 'bVST Minting Power',
                value: `${formatNumber(
                    nftStakingRedux.userContext?.snapshot.is_subsidiary_deb_disabled
                        ? subsidiary_score
                              .multipliedBy(netstatsRedux.deb ? netstatsRedux.deb - DEB_PRECISION : DEB_PRECISION)
                              .div(DEB_PRECISION)
                        : '0',
                    3,
                )}/Day`,
            },
        ];
    };

    return (
        <div className="container" style={{ marginTop: '50px', color: '#98a1c0' }}>
            <div className="d-flex justify-content-center">
                <div className="vesta-first-container mt-4" style={{ maxWidth: '1024px' }}>
                    <PageBack url={routeNames.nftStaking} />

                    <div className="text-center mb-3" style={{ fontSize: '18px', color: '#f1dc46' }}>
                        Subsidiary Staking
                    </div>

                    <Row>
                        <div className="col-12 col-sm-4 mt-4">
                            <div className="active-pool-li-container h-100">
                                <div className="text-center mb-4" style={{ fontSize: '18px' }}>
                                    DEB™ Conversion
                                </div>

                                <DebSwitch
                                    buttonTextPrefix="Subsidiary"
                                    value={!!nftStakingRedux.userContext?.snapshot.is_subsidiary_deb_disabled}
                                    info={'Deb'}
                                    enableDeb={async (sender: string) => {
                                        await nftStakingEnableSubsidiaryDeb(sender);
                                    }}
                                    disableDeb={async (sender: string) => {
                                        await nftStakingDisableSubsidiaryDeb(sender);
                                    }}
                                />
                            </div>
                        </div>

                        <div className="col-12 col-sm-8 mt-4">
                            <div className="active-pool-li-container h-100">
                                <div
                                    className="my-2 cursor-pointer"
                                    role="button"
                                    onClick={() => {
                                        setShowStats(!showStats);
                                    }}
                                >
                                    {showStats ? (
                                        <>
                                            <BiChevronUp />
                                            <span className="ms-1">Hide Stats</span>
                                        </>
                                    ) : (
                                        <>
                                            <BiChevronDown />
                                            <span className="ms-1">Show Stats</span>
                                        </>
                                    )}
                                </div>
                                <div className="border-top mt-2 mb-3" />
                                {showStats && (
                                    <div className="position-relative">
                                        <div className="text-center" style={{ fontSize: '18px' }}>
                                            Subsidiary Staking Stats
                                        </div>

                                        <StakingPoolInfo className="mt-4" data={getPoolInfo()} />

                                        <TooltipLink link={''} tooltip={'Staking pool info'} type={1} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </Row>

                    <div className="d-flex flex-column mt-4" style={{ rowGap: '16px' }}>
                        {SUBSIDIARY_STAKING_POOL_INFO.map((row, id) => {
                            const scoreSelector = `${row.auxSelector}_score` as NftStakingSnapshotKeysType;
                            const totalSnapshotScore: string =
                                (nftStakingRedux.totalSnapshot &&
                                    (nftStakingRedux.totalSnapshot[scoreSelector] as string)) ||
                                '0';
                            return (
                                <StakingRowInfo
                                    key={`staking-row-info-${id}`}
                                    data={{
                                        ...row,
                                        traitlist: [
                                            'Earns AUYRN',
                                            `${formatNumber(
                                                new BigNumber(totalSnapshotScore)
                                                    .multipliedBy(new BigNumber(100))
                                                    .div(nftStakingRedux.totalSnapshot?.subsidiary_score || '0'),
                                                0,
                                            )}% of reward share`,
                                        ],
                                    }}
                                    url={`${routeNames.subsidiaryStaking}/${id}`}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubsidiaryStaking;
