import React, { useEffect, useState } from 'react';
import { useGetAccountInfo, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import { find } from 'lodash';
import { Col, Row } from 'react-bootstrap';

import { DEB_PRECISION, IGNIS_TOKEN_ID } from 'config';
import { selectNetstats } from 'redux/reducers';
import { selectOuroLiquidityVault } from 'redux/reducers/ouro-liquidity-vault';
import { useAppSelector } from 'redux/store';
import { getTokenDecimals, getTokenLogo, liquidityVaultClaimReward } from 'z/elrond';
import { useVestaCommonHooks } from 'z/hooks';
import {
    BIG_NUMBER_ZERO,
    ERROR_CONNECT_WALLET,
    ERROR_TRANSACTION_ONGOING,
    convertToDollarString,
    convertWeiToEsdt,
    formatNumber,
    printWalletBalanceAndPrice,
    toastError,
} from 'z/utils';

export const LiquidityVestaVaultPremiumRewards = () => {
    const { address } = useGetAccountInfo();
    const { hasPendingTransactions } = useGetPendingTransactions();

    const [userPremiumPower, setUserPremiumPower] = useState(BIG_NUMBER_ZERO);
    const [totalPremiumPower, setTotalPremiumPower] = useState(BIG_NUMBER_ZERO);

    const [ignisReward, setIgnisReward] = useState<{ tokenId: string; amount: string } | undefined>();

    const netstatsRedux = useAppSelector(selectNetstats);
    const ouroLiquidityVaultRedux = useAppSelector(selectOuroLiquidityVault);

    const { getTokenPrice } = useVestaCommonHooks();

    useEffect(() => {
        if (ouroLiquidityVaultRedux.rewards) {
            setIgnisReward(find(ouroLiquidityVaultRedux.rewards.tokens, ({ tokenId }) => tokenId === IGNIS_TOKEN_ID));
        }
    }, [ouroLiquidityVaultRedux.rewards]);

    useEffect(() => {
        if (ouroLiquidityVaultRedux.userContext) {
            setUserPremiumPower(
                convertWeiToEsdt(ouroLiquidityVaultRedux.userContext.standard_vesta_power)
                    .multipliedBy(netstatsRedux.deb || DEB_PRECISION)
                    .div(DEB_PRECISION),
            );
        }
    }, [ouroLiquidityVaultRedux.userContext, netstatsRedux.deb]);

    useEffect(() => {
        if (ouroLiquidityVaultRedux.totalPremiumPower) {
            setTotalPremiumPower(convertWeiToEsdt(ouroLiquidityVaultRedux.totalPremiumPower));
        }
    }, [ouroLiquidityVaultRedux.totalPremiumPower]);

    async function onClaimRewards() {
        const rewards = ouroLiquidityVaultRedux.rewards?.tokens?.map((el) => ({
            token_identifier: el.tokenId,
            token_nonce: 0,
            amount: el.amount,
        }));

        if (!address) {
            return toastError(ERROR_CONNECT_WALLET);
        } else if (hasPendingTransactions) {
            return toastError(ERROR_TRANSACTION_ONGOING);
        } else if (!rewards) {
            return toastError('No pending rewards');
        }

        await liquidityVaultClaimReward(rewards, address);
    }

    const printTokenPricePrice = (token: { tokenId: string; amount: string } | undefined) => {
        if (token) {
            const { tokenId } = token;
            const decimals = getTokenDecimals(tokenId);
            const amount = convertWeiToEsdt(token.amount, decimals, decimals);
            if (!amount.isZero()) {
                return `${formatNumber(amount)} (${convertToDollarString(
                    amount.multipliedBy(getTokenPrice(tokenId)),
                )})`;
            }
        }

        return '0 (0$)';
    };

    return (
        <Row className="mt-4 p-0 g-2">
            <Col>
                <Row
                    className="m-0 py-3 px-1 g-2"
                    style={{
                        border: '1px solid #c8bfe7',
                        borderRadius: '10px',
                    }}
                >
                    <div className="col-12 text-center mb-3" style={{ color: '#c8bfe7' }}>
                        {`IGNIS Rewards are governed by Premium Snake Power. Your share of all Premium Snake Power is 
                        ${formatNumber(userPremiumPower.div(totalPremiumPower).multipliedBy(1_000), 3)} ‰`}
                    </div>
                    <Col md={6} lg={4} className="d-flex flex-column px-1">
                        <div
                            className="second-card-style mb-0 d-flex flex-column align-items-between gap-2 px-3"
                            style={{ backgroundColor: '#1B0921' }}
                        >
                            <div className="d-flex align-items-center">
                                <img src={getTokenLogo(IGNIS_TOKEN_ID)} style={{ height: '3rem', width: 'auto' }} />
                                <div className="ms-2">
                                    {printWalletBalanceAndPrice(netstatsRedux, IGNIS_TOKEN_ID, getTokenPrice)}
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} lg={8} className="d-flex flex-column px-1" style={{ fontSize: '1rem' }}>
                        <div
                            className="second-card-style mb-0 d-flex justify-content-between align-items-center px-3"
                            style={{ backgroundColor: '#1B0921' }}
                        >
                            <div className="d-flex flex-column align-items-between gap-2">
                                <div className="d-flex align-items-center">
                                    <img src={getTokenLogo(IGNIS_TOKEN_ID)} style={{ height: '3rem', width: 'auto' }} />
                                    <div className="ms-2">{printTokenPricePrice(ignisReward)}</div>
                                </div>
                            </div>
                            <div>
                                <button
                                    className="farm_but py-2"
                                    style={{ width: '6rem' }}
                                    disabled={hasPendingTransactions}
                                    onClick={onClaimRewards}
                                >
                                    Claim
                                </button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};
