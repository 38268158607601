import React, { FC } from 'react';
import clsx from 'clsx';

import { getTokenLogo } from 'z/elrond';
import { TEXT_SELECT_TOKEN } from 'z/utils';

interface PropsType {
    tokenAmount: string;
    onChangeTokenAmount: (e: string) => void;
    tokenId: string;
    tokenTicker: string;
    tokenBalance: string;
    onMaxTokenAmount: () => void;
    error: string;
    hideToken?: boolean;
    showBTokenType?: boolean;
    disabled?: boolean;
    info?: any;
    feeInfo?: string;
}

export const AddLiquidityInputToken: FC<PropsType> = ({
    tokenAmount,
    onChangeTokenAmount,
    tokenId,
    tokenTicker,
    tokenBalance,
    onMaxTokenAmount,
    error,
    hideToken = false,
    showBTokenType = false,
    disabled = false,
    info,
    feeInfo,
}) => {
    return (
        <div className={clsx('vesta_x_swap_input_box', showBTokenType ? 'BToken_Input' : '')}>
            {!!info && info}
            <div className="d-flex align-items-center">
                <input
                    className="swap_input"
                    type="number"
                    value={tokenAmount}
                    onChange={(e) => onChangeTokenAmount(e.target.value)}
                />
                <div>
                    {!hideToken &&
                        (tokenTicker ? (
                            <button className="add-liquidity-selected-token-buttton">
                                <img src={getTokenLogo(tokenId)} alt="logo" width="30px" />
                                {tokenTicker}
                            </button>
                        ) : (
                            <button className="add-liquidity-select-token-buttton">{TEXT_SELECT_TOKEN}</button>
                        ))}
                </div>
            </div>
            {tokenId && (
                <div className="d-flex justify-content-between mt-1">
                    <div className="input-token-error">{error}</div>
                    <div
                        className="add-liquidity-input-token-balance-box"
                        onClick={() => {
                            if (disabled) return;
                            onMaxTokenAmount();
                        }}
                    >
                        <div className="">Balance:&nbsp;</div>
                        <div style={{ color: 'white' }}>{tokenBalance}</div>
                    </div>
                </div>
            )}
            {feeInfo && (
                <div className="text-right" style={{ color: '#98A1C0', marginRight: '1rem' }}>
                    {feeInfo}
                </div>
            )}
        </div>
    );
};
