import {
  Address,
  AddressValue,
  ArgSerializer,
  BigUIntValue,
  Field,
  List,
  ResultsParser,
  StringValue,
  Struct,
  TokenIdentifierValue,
  TokenTransfer,
  TypedValue,
  U64Value,
} from '@multiversx/sdk-core/out';
import {
  LIQUID_LOCKER_LOCK_GAS_LIMIT,
  LIQUID_LOCKER_SC_ADDRESS,
} from 'config';
import { EsdtTokenPaymentType, UnlockedTokenType } from 'z/types';
import { elrondDappSendTransactions, parseEsdtTokenPayment } from './common';
import {
  elrondProvider,
  esdtTokenPaymentType,
  liquidLockerSmartContract,
} from './provider';

export async function getLockedTokenAmounts(address: string): Promise<EsdtTokenPaymentType[]> {
  try {
    const args: TypedValue[] = [
      new AddressValue(new Address(address)),
    ];
    const interaction = liquidLockerSmartContract.methodsExplicit.lockedTokenAmounts(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await elrondProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } =
      new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      throw Error(returnMessage);
    }

    const values = firstValue.valueOf();
    const decoded = values.map((value: any) => parseEsdtTokenPayment(value));

    return decoded;
  } catch (err) {
    console.log(err);
  }

  return [];
}

export async function getUnlockedTokenAmounts(address: string): Promise<UnlockedTokenType[]> {
  try {
    const args: TypedValue[] = [
      new AddressValue(new Address(address)),
    ];
    const interaction = liquidLockerSmartContract.methodsExplicit.unlockedTokenAmounts(args);
    const query = interaction.check().buildQuery();
    const queryResponse = await elrondProvider.queryContract(query);
    const endpointDefinition = interaction.getEndpoint();
    const { firstValue, returnCode, returnMessage } =
      new ResultsParser().parseQueryResponse(queryResponse, endpointDefinition);

    if (!firstValue || !returnCode.isSuccess()) {
      throw Error(returnMessage);
    }

    const values = firstValue.valueOf();
    const decoded = values.map((value: any) => ({
      token: parseEsdtTokenPayment(value.token),
      unbond_epoch: value.unbond_epoch.toNumber(),
    }));

    return decoded;
  } catch (err) {
    console.log(err);
  }

  return [];
}

export async function liquidLockerLock(
  payment: TokenTransfer,
) {
  const args: TypedValue[] = [
    new TokenIdentifierValue(payment.tokenIdentifier),
    new BigUIntValue(payment.amountAsBigInteger),
    new StringValue('lock'),
  ];

  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = `ESDTTransfer@${argumentsString}`;

  const tx = {
    value: 0,
    data,
    receiver: LIQUID_LOCKER_SC_ADDRESS,
    gasLimit: LIQUID_LOCKER_LOCK_GAS_LIMIT,
  };
  
  const txName = 'Lock';
  const { sessionId, error } = await elrondDappSendTransactions(tx, txName);
  
  return { sessionId, error };
}

export async function liquidLockerUnlock(
  payment: TokenTransfer,
) {
  const struct = new Struct(esdtTokenPaymentType, [
    new Field(new TokenIdentifierValue(payment.tokenIdentifier), "token_identifier"),
    new Field(new U64Value(payment.nonce), "token_nonce"),
    new Field(new BigUIntValue(payment.amountAsBigInteger), "amount"),
  ]);
  const args: TypedValue[] = [
    List.fromItems([struct])
  ];

  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = `unlock@${argumentsString}`;

  const tx = {
    value: 0,
    data,
    receiver: LIQUID_LOCKER_SC_ADDRESS,
    gasLimit: LIQUID_LOCKER_LOCK_GAS_LIMIT,
  };
  
  const txName = 'Unlock';
  const { sessionId, error } = await elrondDappSendTransactions(tx, txName);
  
  return { sessionId, error };
}

export async function liquidLockerUnbond(
  tokenId: string,
) {
  const args: TypedValue[] = [
    List.fromItems([
      new TokenIdentifierValue(tokenId),
    ])
  ];

  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = `unbond@${argumentsString}`;

  const tx = {
    value: 0,
    data,
    receiver: LIQUID_LOCKER_SC_ADDRESS,
    gasLimit: LIQUID_LOCKER_LOCK_GAS_LIMIT,
  };
  
  const txName = 'Unbond';
  const { sessionId, error } = await elrondDappSendTransactions(tx, txName);
  
  return { sessionId, error };
}
