import {
    CollectionType,
    NftType,
    TokenType,
} from '@multiversx/sdk-dapp/types/tokens.types';
import axios from 'axios';
import imgEGLD from 'assets/img/token/EGLD.svg';
import { MVX_API_URL, WEGLD_TOKEN_ID } from 'config';
import {
    ElrondStatsType,
    NonFungibleTokenBalanceType,
    TokenBalanceType,
    TokenInfoType,
    TokenSupplyType,
} from 'z/types';
import {
    convertSecondsToPrintDateTime,
    createTokenTicker,
} from 'z/utils';

export const EMPTY_TOKEN_TYPE: TokenType = {
    identifier: '',
    ticker: '',
    decimals: 18,
    name: '',
    owner: '',
    minted: '',
    burnt: '',
    supply: '',
    circulatingSupply: '',
    canBurn: false,
    canChangeOwner: false,
    canFreeze: false,
    canMint: false,
    canPause: false,
    canUpgrade: false,
    canWipe: false,
    isPaused: false,
    transactions: 0,
    accounts: 0,
    price: 0,
};

export const EGLD_TOKEN_INFO: TokenType = {
    ...EMPTY_TOKEN_TYPE,
    identifier: 'EGLD',
    ticker: 'EGLD',
    name: 'EGLD',
    decimals: 18,
    assets: {
        svgUrl: imgEGLD,
        pngUrl: imgEGLD,
    },
};

export async function getTokenBalanceFromApi(
    address: string,
    tokenId: string,
): Promise<TokenBalanceType | undefined> {
    try {
        const queryUrl = `${MVX_API_URL}/accounts/${address}/tokens/${tokenId}`;
        const { data } = await axios.get<TokenBalanceType>(
            queryUrl,
        );

        return data;
    } catch {}
    // } catch (err) {
    //     console.error('getTokenBalanceFromApi failed:', err);
    // }

    return undefined;
}

// this function will return the same number of TokenBalanceType with the length of tokenIds
export async function getTokenBalancesFromApi(
    address: string,
    tokenIds: string[],
): Promise<TokenBalanceType[]> {
    try {
        const queryUrl = `${MVX_API_URL}/accounts/${address}/tokens?identifiers=${tokenIds.join(
            ',',
        )}`;
        const { data } = await axios.get<
            TokenBalanceType[]
        >(queryUrl);
        // data.reverse(); // because api returns array in reverse order

        const items: TokenBalanceType[] = [];
        for (const tokenId of tokenIds) {
            let j;
            for (j = 0; j < data.length; j++) {
                if (data[j].identifier === tokenId) {
                    items.push(data[j]);
                    break;
                }
            }
            if (j === data.length) {
                items.push({
                    identifier: tokenId,
                    ticker: createTokenTicker(tokenId),
                    decimals: -1, // intentional
                    balance: '0',
                });
            }
        }

        return items;
    } catch (err) {
        console.error(
            'getTokenBalancesFromApi failed:',
            err,
        );
    }

    return [];
}

export async function getEgldPriceFromApi(): Promise<
    number | undefined
> {
    try {
        const queryUrl = `${MVX_API_URL}/tokens/${WEGLD_TOKEN_ID}`;
        const { data } = await axios.get<TokenInfoType>(
            queryUrl,
        );

        return data.price;
    } catch (err) {
        console.error('getEgldPriceFromApi failed:', err);
    }

    return undefined;
}

export async function getElrondStatsFromApi(): Promise<
    ElrondStatsType | undefined
> {
    try {
        const queryUrl = `${MVX_API_URL}/stats`;
        const { data } = await axios.get<ElrondStatsType>(
            queryUrl,
        );

        if (data) {
            data.leftTime = convertSecondsToPrintDateTime(
                (data.roundsPerEpoch - data.roundsPassed) *
                    6,
            );
            data.passedTimePercentage =
                (data.roundsPassed / data.roundsPerEpoch) *
                100;
        }

        return data;
    } catch (err) {
        console.error('getElrondStatsFromApi failed:', err);
    }

    return undefined;
}

export async function getTokenInfosByTokenIdFromApi(
    tokenIds: string[],
): Promise<TokenInfoType[]> {
    try {
        if (tokenIds.length === 0) {
            return [];
        }

        const queryUrl = `${MVX_API_URL}/tokens?identifiers=${tokenIds.join(
            ',',
        )}`;
        const { data } = await axios.get<TokenInfoType[]>(
            queryUrl,
        );
        for (const item of data) {
            item.ticker = createTokenTicker(
                item.identifier,
            );
        }

        return data;
    } catch (err) {
        console.error(
            'getTokenInfosByTokenIdFromApi failed:',
            err,
        );
    }

    return [];
}

export async function getTokenInfosBySearchFromApi(
    searchText: string,
): Promise<TokenInfoType[]> {
    try {
        const queryUrl = `${MVX_API_URL}/tokens?search=${searchText}`;
        const { data } = await axios.get<TokenInfoType[]>(
            queryUrl,
        );
        for (const item of data) {
            item.ticker = createTokenTicker(
                item.identifier,
            );
        }

        return data;
    } catch (err) {
        console.error(
            'getTokenInfosBySearchFromApi failed:',
            err,
        );
    }

    return [];
}

//
export async function getAccountTokensFromApi(
    address: string,
    tokenIds: string[],
    filled: boolean = false,
): Promise<TokenType[]> {
    try {
        const queryUrl = `${MVX_API_URL}/accounts/${address}/tokens?size=50&identifiers=${tokenIds.join(
            ',',
        )}`;
        const { data } = await axios.get<TokenType[]>(
            queryUrl,
        );

        if (!filled) {
            return data.map((item) => ({
                ...item,
                ticker: createTokenTicker(item.identifier),
            }));
        }

        // if there is no token info in the response, empty token info will be added
        const items: TokenType[] = [];
        for (const tokenId of tokenIds) {
            let j;
            for (j = 0; j < data.length; j++) {
                if (data[j].identifier === tokenId) {
                    items.push({
                        ...data[j],
                        ticker: createTokenTicker(
                            data[j].identifier,
                        ),
                    });
                    break;
                }
            }
            // insert empty token info if the token info is not retrieved
            if (j === data.length) {
                items.push({
                    ...EMPTY_TOKEN_TYPE,
                    identifier: tokenId,
                    ticker: createTokenTicker(tokenId),
                    name: createTokenTicker(tokenId),
                });
            }
        }

        return items;
    } catch (err) {
        console.error('getAccountTokensFromApi:', err);
    }

    return [];
}

export async function getTokenSupplyFromApi(
    tokenId: string,
): Promise<TokenSupplyType | null> {
    try {
        const queryUrl = `${MVX_API_URL}/tokens/${tokenId}/supply?denominated=true`;
        const { data } = await axios.get<TokenSupplyType>(
            queryUrl,
        );
        return data;
    } catch (err) {
        console.error('getTokenSupplyFromApi failed:', err);
    }

    return null;
}

export async function getNFTBalances(
    address: string,
    tokenIds: string[],
): Promise<NonFungibleTokenBalanceType[]> {
    try {
        const queryUrl = `${MVX_API_URL}/accounts/${address}/nfts?collections=${tokenIds.join(
            ',',
        )}`;
        const { data } = await axios.get<
            NonFungibleTokenBalanceType[]
        >(queryUrl);
        // data.reverse(); // because api returns array in reverse order

        let items: NonFungibleTokenBalanceType[] = [];
        for (const tokenId of tokenIds) {
            const sameToken = data.filter(
                (d) => d.collection === tokenId,
            );
            if (sameToken.length > 0) {
                items = items.concat(sameToken);
            } else {
                items.push({
                    collection: tokenId,
                    ticker: createTokenTicker(tokenId),
                    decimals: -1, // intentional
                    nonce: -1,
                    balance: '0',
                    attributes: '',
                });
            }
        }
        return items;
    } catch (err) {
        console.error('getNFTBalances failed:', err);
    }

    return [];
}

export async function getNftsFromApi(tokenIds: string[], size: number = 10000): Promise<NftType[]> {
    let returnedItems: NftType[] = [];
    try {
        for (let i = 0; i < tokenIds.length; i += 300) {
            const queryUrl = `${MVX_API_URL}/nfts?identifiers=${tokenIds.slice(i, i + 300).join(',')}&size=${size}`;
            const { data } = await axios.get<NftType[]>(queryUrl);
            returnedItems = returnedItems.concat(data);
        }
        return returnedItems;
    } catch (err) {
        console.error('getNftsFromApi:', err);
    }

    return [];
}

export async function getAccountNftsByIds(address: string, nftIds: string[]): Promise<NftType[]> {
    try {
        const url = `${MVX_API_URL}/accounts/${address}/nfts?identifiers=${nftIds.join(',')}`;
        const { data } = await axios.get<NftType[]>(url);

        return data;
    } catch (err) {
        console.error('getAccountNftsByIds:', err);
    }

    return [];
}
export async function getAccountNftsByCollection(address: string, collection: string): Promise<NftType[]> {
    try {
        const url = `${MVX_API_URL}/accounts/${address}/nfts?collections=${collection}`;
        const { data } = await axios.get<NftType[]>(url);

        return data;
    } catch (err) {
        console.error('getAccountNftsByCollection falied:', err);
    }

    return [];
}

export async function getAccountNftsByCollections(
    address: string,
    collections: string[],
    size: number = 10000,
): Promise<NftType[][]> {
    try {
        const url = `${MVX_API_URL}/accounts/${address}/nfts?collections=${collections.join(',')}&size=${size}`;
        const { data } = await axios.get<NftType[]>(url);

        const results: NftType[][] = [];
        for (const collection of collections) {
            const nfts = [];
            for (const nft of data) {
                if (nft.collection == collection) {
                    nfts.push(nft);
                }
            }
            results.push(nfts);
        }

        return results;
    } catch (err) {
        console.error('getAccountNftsByCollection falied:', err);
    }

    return [];
}

export async function getTokenFromApi(
    tokenId: string,
): Promise<TokenType | undefined> {
    try {
        const queryUrl = `${MVX_API_URL}/tokens/${tokenId}`;
        const { data } = await axios.get<TokenType>(
            queryUrl,
        );
        if (data && data.ticker) {
            data.ticker = data.ticker.split('-')[0];
        }

        return data;
    } catch (err) {
        console.error('getTokenFromApi failed:', err);
    }

    return undefined;
}

export async function getCollectionFromApi(
    tokenId: string,
): Promise<CollectionType | undefined> {
    try {
        const queryUrl = `${MVX_API_URL}/collections/${tokenId}`;
        const { data } = await axios.get<CollectionType>(
            queryUrl,
        );
        if (data && data.ticker) {
            data.ticker = data.ticker.split('-')[0];
        }

        return data;
    } catch (err) {
        console.error('getTokenFromApi failed:', err);
    }

    return undefined;
}
