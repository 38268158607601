import React from 'react';
import clsx from 'clsx';
// import { DEMI_FT_IDS } from 'config';
import {
  selectNetstats,
} from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { getTokenLogo } from 'z/elrond';
import { useVestaCommonHooks } from 'z/hooks';
import {
  formatNumber,
  convertToDollarString,
  createTokenTicker,
  convertWeiToEsdt,
} from 'z/utils';

export const DexLiquidity = ({aurynIndex} : {aurynIndex: number}) => {
  const { getTokenPrice } = useVestaCommonHooks();
  
  const netstatsRedux = useAppSelector(selectNetstats);

  const portfolioTokens = Object.values(netstatsRedux.walletTokensMap);

  return (
    <div className='row mt-4'>
      <div className='col-12'>
        {portfolioTokens.length > 0 ? portfolioTokens.map((row, index) => (
            <div className={clsx(index < portfolioTokens.length - 1 && 'portfolio-token-row', 'd-flex justify-content-between align-items-center portfolio-token-row-common')} key={`d-p-t-${index}`}>
                <div className='d-flex align-items-center'>
                    <img
                        src={row.assets ? row.assets.svgUrl : getTokenLogo(row.identifier)}
                        width={'40px'}
                        height={'40px'}
                        alt={`${row.ticker} logo`}
                    />
                    <div className='d-flex flex-column ms-2'>
                      <div className='text-white'>
                          {row.balance ? formatNumber(convertWeiToEsdt(row.balance, row.decimals, row.decimals)) : '0'}{' '}
                          <span className='text-secondary-color' style={{ fontSize: '.9rem' }}>{createTokenTicker(row.identifier)}</span>
                      </div>
                      <div>
                        {'≃ '}
                        <span style={{ fontSize: '.9rem' }}>
                          {convertToDollarString(convertWeiToEsdt(row.balance, row.decimals, row.decimals).multipliedBy(getTokenPrice(row.identifier, aurynIndex)))}
                        </span>
                      </div>
                    </div>
                </div>
                
                <div>
                  <span style={{ color: '#f0f0f0' }}>1 {createTokenTicker(row.identifier)}</span>
                    {' ≃ '}
                    <span style={{ color: '#f0f0f0' }}>
                      {convertToDollarString(getTokenPrice(row.identifier, aurynIndex))}
                    </span>
                </div>
            </div>
        )) : 'No tokens'}
      </div>
    </div>
  );
};
