import {
    Address,
    AddressValue,
    ArgSerializer,
    BigUIntValue,
    BytesValue,
    Field,
    List,
    StringValue,
    Struct,
    TokenIdentifierValue,
    TokenTransfer,
    TypedValue,
    U32Value,
    U64Value,
    VariadicValue,
} from '@multiversx/sdk-core/out';
import { find } from 'lodash';
import { EAURYN_TOKEN_ID, LIQUIDITY_VESTA_VALULT_SC_ADDRESS, MVX_CHAIN_ID } from 'config';
import {
    EsdtTokenPaymentType,
    OuroLiquidityVaultBaseContextType,
    OuroLiquidityVaultStatsContextType,
    OuroLiquidityVaultUserContextType,
} from 'z/types';
import { elrondDappSendTransactions, mvxQuery, parseEsdtTokenPayment } from './common';
import { esdtTokenPaymentType, liquidityVestaVaultSmartContract } from './provider';
import { getOuroVaultProofs } from './vesta-api';

export async function liquidityVaultViewBaseContext(): Promise<OuroLiquidityVaultBaseContextType | undefined> {
    try {
        const value = await mvxQuery(liquidityVestaVaultSmartContract.methods.viewBaseContext());
        const decoded = {
            state: value.state,
            lp_token_ids: value.lp_token_ids.map((v: any) => v.toString()),
            xexchange_lp_token_ids: value.xexchange_lp_token_ids.map((v: any) => v.toString()),

            slip_token_id: value.slip_token_id.toString(),
            usdd_slip_token_id: value.usdd_slip_token_id.toString(),
            raw_vesta_token_id: value.raw_vesta_token_id.toString(),

            sft_token_id: value.sft_token_id.toString(),
            sft_lock_period: value.sft_lock_period.toNumber(),

            premium_reward_token_ids: value.premium_reward_token_ids.map((v: any) => v.toString()),
            elite_reward_token_ids: value.elite_reward_token_ids.map((v: any) => v.toString()),
        };

        return decoded;
    } catch (err) {
        console.error('getLiquidityVaultViewBaseContext', err);
        return undefined;
    }
}

export async function liquidityVaultViewStatsContext(): Promise<OuroLiquidityVaultStatsContextType | undefined> {
    try {
        const value = await mvxQuery(liquidityVestaVaultSmartContract.methods.viewStatsContext());
        const decoded = {
            lp_token_reserves: value.lp_token_reserves.map(parseEsdtTokenPayment),
            sft_reserves: value.sft_reserves.map((v: any) => v.toFixed(0)),
            reward_rates: value.reward_rates.map((v: any) => ({
                lp_token_id: v.lp_token_id.toString(),
                reward_rate: v.reward_rate.toFixed(0),
            })),

            total_slip_multiplier: value.total_slip_multiplier.toFixed(0),
            total_standard_vesta_power: value.total_standard_vesta_power.toFixed(0),
            total_premium_vesta_power: value.total_premium_vesta_power.toFixed(0),
            total_elite_vesta_power: value.total_elite_vesta_power.toFixed(0),
            total_dex_vesta_power: value.total_dex_vesta_power.toFixed(0),
            total_xexchange_vesta_power: value.total_xexchange_vesta_power.toFixed(0),
        };
        return decoded;
    } catch (err) {
        console.error('vaultLiquidityViewStatsContext', err);
        return undefined;
    }
}

export async function liquidityVaultViewUserContext(
    address: string,
): Promise<OuroLiquidityVaultUserContextType | undefined> {
    try {
        const value = await mvxQuery(
            liquidityVestaVaultSmartContract.methods.viewUserContext([new AddressValue(new Address(address))]),
        );
        const decoded = {
            lp_token_staked_amounts: value.lp_token_staked_amounts.map((v: any) => parseEsdtTokenPayment(v)),

            sft_staked_amounts: value.sft_staked_amounts.map((v: any) => v.toFixed(0)),

            dex_vesta_power: value.dex_vesta_power.toFixed(0),
            xexchange_vesta_power: value.xexchange_vesta_power.toFixed(0),
            standard_vesta_power: value.standard_vesta_power.toFixed(0),
            premium_vesta_power: value.premium_vesta_power.toFixed(0),
            elite_vesta_power: value.elite_vesta_power.toFixed(0),
            slip_multiplier: value.slip_multiplier.toFixed(0),

            reward_amount: value.reward_amount.toFixed(0),
            last_claimed_timestamp: value.last_claimed_timestamp.toNumber(),

            deb: value.deb.toNumber() / 100_000,
            vlm: value.vlm.toNumber() / 1_000_000,
            im: value.im.toNumber() / 1_000_000,
            vm: value.vm.toNumber() / 1_000_000,

            premium_reward_payments: value.premium_reward_payments.map((v: any) => parseEsdtTokenPayment(v)),
            elite_reward_payments: value.elite_reward_payments.map((v: any) => parseEsdtTokenPayment(v)),

            lp_token_fee_procents: value.lp_token_fee_procents.map((v: any) => parseEsdtTokenPayment(v)),
            total_standard_snake_power_fee_amount: value.total_standard_snake_power_fee_amount.toFixed(),
        };

        return decoded;
    } catch (err) {
        console.error('vaultLiquidityViewUserContext', err);
        return undefined;
    }
}

export async function liquidityVaultStakeToken(payments: TokenTransfer[], sender: string) {
    const args: TypedValue[] = [
        new AddressValue(new Address(LIQUIDITY_VESTA_VALULT_SC_ADDRESS)),
        new U32Value(payments.length),
    ];
    payments.map((payment) => {
        args.push(new TokenIdentifierValue(payment.tokenIdentifier));
        args.push(new U64Value(payment.nonce));
        args.push(new BigUIntValue(payment.amountAsBigInteger));
    });
    args.push(new StringValue('stakeToken'));

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `MultiESDTNFTTransfer@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: sender,
        gasLimit: 100_000_000 + 10_000_000 * payments.length,
    };

    const txName = 'Stake';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function liquidityVaultUnstakeToken(payments: TokenTransfer[]) {
    const pa: TypedValue[] = [];
    payments.map((payment) =>
        pa.push(
            new Struct(esdtTokenPaymentType, [
                new Field(new TokenIdentifierValue(payment.tokenIdentifier), 'token_identifier'),
                new Field(new U64Value(payment.nonce), 'token_nonce'),
                new Field(new BigUIntValue(payment.amountAsBigInteger), 'amount'),
            ]),
        ),
    );

    const args: TypedValue[] = [List.fromItems(pa)];

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `unstakeToken@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: LIQUIDITY_VESTA_VALULT_SC_ADDRESS,
        gasLimit: 100_000_000 + 10_000_000 * payments.length,
    };

    const txName = 'Unstake';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function liquidityVaultClaimReward(payments: EsdtTokenPaymentType[], sender: string) {
    const proofs = await getOuroVaultProofs(sender);
    if (!proofs) return;

    const eaurynReward = find(payments, ({ token_identifier }) => token_identifier === EAURYN_TOKEN_ID);

    const interaction = liquidityVestaVaultSmartContract.methods
        .claimRewards([
            VariadicValue.fromItemsCounted(...proofs.map((proof) => new BytesValue(Buffer.from(proof, 'hex')))),
            ...payments,
        ])
        .withChainID(MVX_CHAIN_ID)
        .withSender(new Address(sender))
        .withGasLimit(!eaurynReward ? 20_000_000 + 1_200_000 * payments.length : 200_000_000);

    const transaction = interaction.check().buildTransaction();
    const txName = 'Claim rewards';
    const { sessionId, error } = await elrondDappSendTransactions(transaction, txName);

    return { sessionId, error };
}
