// import { fallbackNetworkConfigurations } from "@multiversx/sdk-dapp/constants";
import { EnvironmentsEnum } from '@multiversx/sdk-dapp/types';
import devnetConfig from './config-devnet.json';
import mainnetConfig from './config-mainnet.json';

export const IS_DEV = process.env.REACT_APP_ENV_MVX_IS_DEV?.trim() === 'true';

// Generate your own WalletConnect 2 ProjectId here: https://cloud.walletconnect.com/app
export const walletConnectV2ProjectId = IS_DEV
    ? 'e4908212d173a37edd54aa1ec1f1c80d'
    : '9291145b1abc47b6a444559a36f7b7b4';
export const transactionSize = 15;
export const MVX_ACCESS_TIMEOUT = 10_000; // 10s

export const config = IS_DEV ? devnetConfig : mainnetConfig;

export const Environment = IS_DEV ? EnvironmentsEnum.devnet : EnvironmentsEnum.mainnet;

export const MVX_GATEWAY_URL = process.env.REACT_APP_ENV_MVX_MAINNET_GATEWAY ?? config['default-network'].gateway;
export const MVX_API_URL = process.env.REACT_APP_ENV_MVX_MAINNET_API ?? config['default-network'].api;
export const MVX_EXPLORER_URL = process.env.REACT_APP_ENV_MVX_MAINNET_EXPLORER ?? config['default-network'].explorer;
export const MVX_CHAIN_ID = config['default-network'].chain;

//
export const ESDT_MODULE_ADDRESS = 'erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqzllls8a5w6u';
export const TOKEN_ISSUE_COST = 0.05; // 0.05 EGLD
export const ZERO_ADDRESS = 'erd1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq6gq4hu';
