import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { TokenTransfer } from '@multiversx/sdk-core/out';
import { useGetAccount, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import clsx from 'clsx';
import { Col, Row } from 'react-bootstrap';
import Countdown from 'react-countdown';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { InfoTooltip } from 'components';
import { CountdownCompleted, countdownRenderer } from 'components/CountdownComponents';
import { CustomSlider2 } from 'components/CustomSlider2';
import {
    SVST_MINT_LIMITS,
    VESTADAO_COLLECTION,
    STABLESWAP_LP_TOKEN,
    VST_TOKEN_ID,
} from 'config';
import { AddLiquidityInputToken } from 'pages/Pool/AddLiquidityInputToken';
import { selectFarm, selectNetstats } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { routeNames } from 'routes';
import {
    getAccountNftsByCollection,
    getTokenBalanceFromApi,
    getTokenLogo,
    snakeCoilViewBaseContext,
} from 'z/elrond';
import {
    StableLiquidityVaultBaseContext,
    StableLiquidityVaultContract,
    StableLiquidityVaultStatsContext,
    StableLiquidityVaultUserContext,
} from 'z/elrond/stable-liquidity-vault';
import { useVestaCommonHooks } from 'z/hooks';
import {
    BigNumber,
    VestingTypeEnum,
} from 'z/types';
import {
    BIG_NUMBER_ZERO,
    convertBigNumberToInputString,
    formatNumber,
    convertEsdtToWei,
    convertSecondsToLocalDateTime,
    convertToDollarString,
    createTokenTicker,
    convertWeiToEsdt,
    DEFAULT_DECIMALS,
    ERROR_CONNECT_WALLET,
    ERROR_INVALID_NUMBER,
    ERROR_NOT_ENOUGH_BALANCE,
    ERROR_SC_DATA_NOT_LOADED,
    ERROR_TRANSACTION_ONGOING,
    isPositiveBigNumber,
    isPositiveOrZeroBigNumber,
    parseBigNumber,
    toastError,
    ZERO_STRING,
} from 'z/utils';
import { printPaymentAndPrice, printWalletBalanceAndPrice } from '../common';
import { StableLiquidityVaultVestaMinter } from '../Components/StableLiquidityVaultVestaMinter';
import FiveTokensStake from './FiveTokensStake';

const SFT_TIER_COUNT = 3;

enum VestaVaultSelectedHolding {
    VaultVesta,
    VaultBVesta,
    VaultSVesta,
    WalletVesta,
    WalletSVesta,
    WalletBVesta,
    WalletMaximize,
}

export const StableLiquidityVault = () => {
    const { address } = useGetAccount();
    const { hasPendingTransactions } = useGetPendingTransactions();
    const navigate = useNavigate();
    const { getTokenPrice } = useVestaCommonHooks();

    const netstatsRedux = useAppSelector(selectNetstats);
    const farmRedux = useAppSelector(selectFarm);

    const [stakedSftCounts, setStakedSftCounts] = useState<number[]>(Array(SFT_TIER_COUNT).fill(0));
    const [selectedSftTier, setSelectedSftTier] = useState<number>(0);
    const [sftBalances, setSftBalances] = useState<number[]>(Array(SFT_TIER_COUNT).fill(0));
    const [targetSftCount, setTargetSftCount] = useState<number>(0);

    const [walletStakingTokenAmount, setWalletStakingTokenAmount] = useState<string>(ZERO_STRING);
    const [selectedHolding, setSelectedHolding] = useState<VestaVaultSelectedHolding>(
        VestaVaultSelectedHolding.VaultVesta,
    );
    const [selectedTokenId, setSelectedTokenId] = useState<string>(STABLESWAP_LP_TOKEN);

    const [mintOption, setMintOption] = useState<number>(0); // 0 for sVESTA, 1 for fVESTA
    const [mintPercent, setMintPercent] = useState<number>(0);
    const [vestaSleepingYears, setVestaSleepingYears] = useState<number>(1);

    const [vaultBaseContext, setVaultBaseContext] = useState<StableLiquidityVaultBaseContext>();
    const [vaultStatsContext, setVaultStatsContext] = useState<StableLiquidityVaultStatsContext>();
    const [vaultUserContext, setVaultUserContext] = useState<StableLiquidityVaultUserContext>();

    const [SftCountdown, setSftCountdown] = useState<any>();

    const [unstakeWithVstFee, setUnstakeWithVstFee] = useState<boolean>(false);

    function onClickSftTier(index: number) {
        setSelectedSftTier(index);
    }

    useEffect(() => {
        setTargetSftCount(stakedSftCounts[selectedSftTier]);
    }, [selectedSftTier, sftBalances, stakedSftCounts]);

    function onChangeTargetSftCount(value: number) {
        if (value < 0) return;
        if (value > sftBalances[selectedSftTier] + stakedSftCounts[selectedSftTier]) return;

        setTargetSftCount(value);
    }

    //
    const [stakeAmount, setStakeAmount] = useState<string>(ZERO_STRING);
    const [stakeAmountError, setStakeAmountError] = useState<string>('');
    const [stakeSliderAmount, setStakeSliderAmount] = useState<number>(0);

    function onChangeStakeAmount(value: string) {
        let balance = BIG_NUMBER_ZERO;
        if (selectedHolding == VestaVaultSelectedHolding.VaultVesta) {
            if (vaultUserContext) {
                balance = vaultUserContext.minor_power;
            }
        } else if (selectedHolding == VestaVaultSelectedHolding.WalletVesta) {
            balance = parseBigNumber(walletStakingTokenAmount);
        }

        let error = '';
        if (!isPositiveOrZeroBigNumber(value)) {
            error = ERROR_INVALID_NUMBER;
        } else if (address && convertEsdtToWei(value, DEFAULT_DECIMALS).comparedTo(balance) > 0) {
            error = ERROR_NOT_ENOUGH_BALANCE;
        }

        setStakeAmountError(error);
        setStakeAmount(value);

        if (balance.comparedTo(0) <= 0) {
            setStakeSliderAmount(0);
        } else {
            const _sliderAmount = Math.floor(
                convertEsdtToWei(value, DEFAULT_DECIMALS).div(balance).multipliedBy(100).toNumber(),
            );
            setStakeSliderAmount(Math.min(_sliderAmount, 100));
        }
    }

    function onMaxStakeAmount() {
        if (selectedHolding == VestaVaultSelectedHolding.VaultVesta) {
            setStakeAmount(
                vaultUserContext
                    ? convertBigNumberToInputString(
                          convertWeiToEsdt(vaultUserContext.minor_power, DEFAULT_DECIMALS, DEFAULT_DECIMALS),
                          DEFAULT_DECIMALS,
                      )
                    : ZERO_STRING,
            );
        } else if (selectedHolding == VestaVaultSelectedHolding.WalletVesta) {
            setStakeAmount(
                convertBigNumberToInputString(
                    convertWeiToEsdt(walletStakingTokenAmount, DEFAULT_DECIMALS, DEFAULT_DECIMALS),
                    DEFAULT_DECIMALS,
                ),
            );
        }
    }

    useEffect(() => {
        setStakeAmount(ZERO_STRING);
        setStakeAmountError('');
        setStakeSliderAmount(0);
    }, [selectedHolding]);

    useEffect(() => {
        (async () => {
            const stableVaultContract = new StableLiquidityVaultContract(address);
            const _vaultBaseContext = await stableVaultContract.viewBaseContext();
            setVaultBaseContext(_vaultBaseContext);
        })();
    }, []);

    useEffect(() => {
        if (hasPendingTransactions) return;

        (async () => {
            const stableVaultContract = new StableLiquidityVaultContract(address);
            const _vaultStatsContext = await stableVaultContract.viewStatsContext();
            setVaultStatsContext(_vaultStatsContext);
        })();
    }, [hasPendingTransactions]);

    useEffect(() => {
        if (!address || hasPendingTransactions) return;

        (async () => {
            const stableVaultContract = new StableLiquidityVaultContract(address);
            const _vaultUserContext = await stableVaultContract.viewUserContext(address);
            setVaultUserContext(_vaultUserContext);

            if (_vaultUserContext) {
                setStakedSftCounts(_vaultUserContext.sft_staked_amounts.map((v) => Number(v)));
            }
        })();

        (async () => {
            const _balance = await getTokenBalanceFromApi(address, STABLESWAP_LP_TOKEN);
            setWalletStakingTokenAmount(_balance ? _balance.balance : ZERO_STRING);
        })();

        (async () => {
            const _sfts = await getAccountNftsByCollection(address, VESTADAO_COLLECTION);
            let goldCount = 0;
            let silverCount = 0;
            let bronzeCount = 0;
            for (const sft of _sfts) {
                if (sft.nonce == 1) goldCount += Number(sft.balance);
                else if (sft.nonce == 2) silverCount += Number(sft.balance);
                else if (sft.nonce == 3) bronzeCount += Number(sft.balance);
            }
            setSftBalances([goldCount, silverCount, bronzeCount]);
        })();
    }, [address, hasPendingTransactions]);

    useEffect(() => {
        if (vaultBaseContext && vaultUserContext) {
            const unstakeTime = (vaultUserContext.last_claimed_timestamp + vaultBaseContext.sft_lock_period) * 1000;
            const CountdownWrapper =
                unstakeTime < Date.now()
                    ? () => <CountdownCompleted />
                    : () => <Countdown renderer={countdownRenderer} date={unstakeTime} autoStart />;
            const MemoCountdown = React.memo(CountdownWrapper);
            setSftCountdown(MemoCountdown);
        }
    }, [vaultBaseContext, vaultUserContext]);

    // get AurynIndex
    const [aurynIndex, setAurynIndex] = useState(0);
    useEffect(() => {
        (async () => {
            const _snakeCoilBaseContext = await snakeCoilViewBaseContext();
            if (_snakeCoilBaseContext) {
                setAurynIndex(
                    parseBigNumber(_snakeCoilBaseContext.token_supplies[0])
                        .div(_snakeCoilBaseContext.token_supplies[1])
                        .toNumber(),
                );
            } else {
                setAurynIndex(0);
            }
        })();
    }, []);

    const [unstakeFeeAmount, setUnstakeFeeAmount] = useState<string>(ZERO_STRING);
    useEffect(() => {
        if (parseBigNumber(stakeAmount).isZero()) {
            setUnstakeFeeAmount(ZERO_STRING);
            return;
        }

        if (unstakeWithVstFee) {
            new StableLiquidityVaultContract(address)
                .getVstFeeAmount(address, convertEsdtToWei(stakeAmount))
                .then((v) => v && setUnstakeFeeAmount(v.toFixed()));
        } else {
            if (vaultUserContext) {
                const feeAmountInStakingToken = convertEsdtToWei(stakeAmount).multipliedBy(vaultUserContext.user_unstake_fee_percent * 1.01).toFixed(0); // consider slippage
                setUnstakeFeeAmount(feeAmountInStakingToken);
            }
        }
    }, [unstakeWithVstFee, stakeAmount, vaultUserContext]);

    async function onSftStakeOrUnstake() {
        let error = '';
        if (!address) {
            error = ERROR_CONNECT_WALLET;
        } else if (hasPendingTransactions) {
            error = ERROR_TRANSACTION_ONGOING;
        } else if (!vaultBaseContext || !vaultUserContext) {
            error = ERROR_SC_DATA_NOT_LOADED;
        }
        if (error) {
            toastError(error);
            return;
        }
        if (!vaultBaseContext || !vaultUserContext) return;

        const nonce = (selectedSftTier % 3) + 1;
        if (targetSftCount > stakedSftCounts[selectedSftTier]) {
            // Stake SFT
            const payments: TokenTransfer[] = [
                TokenTransfer.metaEsdtFromBigInteger(
                    VESTADAO_COLLECTION,
                    nonce,
                    targetSftCount - stakedSftCounts[selectedSftTier],
                ),
            ];

            const stableVaultContract = new StableLiquidityVaultContract(address);
            await stableVaultContract.stakeSft(payments);
        } else if (targetSftCount < stakedSftCounts[selectedSftTier]) {
            // Unstake SFT
            const unstakeTimestamp =
                (vaultUserContext.last_claimed_timestamp + vaultBaseContext.sft_lock_period) * 1000;
            if (Date.now() < unstakeTimestamp) {
                toastError(`You can unstake after ${convertSecondsToLocalDateTime(unstakeTimestamp)}.`);
                return;
            }

            const sfts: TokenTransfer[] = [
                TokenTransfer.metaEsdtFromBigInteger(
                    VESTADAO_COLLECTION,
                    nonce,
                    stakedSftCounts[selectedSftTier] - targetSftCount,
                ),
            ];

            const stableVaultContract = new StableLiquidityVaultContract(address);
            await stableVaultContract.unstakeSft(sfts);
        }
    }

    async function onMintVesta() {
        let error = '';
        if (!address) {
            error = ERROR_CONNECT_WALLET;
        } else if (hasPendingTransactions) {
            error = ERROR_TRANSACTION_ONGOING;
        } else if (mintOption === 1) {
            if (vestaSleepingYears > SVST_MINT_LIMITS[farmRedux.eliteAccountTier]) {
                error = `You can mint ${SVST_MINT_LIMITS[farmRedux.eliteAccountTier]} locked years sVST at most`;
            }
        }

        if (error) {
            toastError(error);
            return;
        }

        const vestingType =
            mintOption === 2
                ? VestingTypeEnum.FrozenVesta
                : mintOption === 1
                ? VestingTypeEnum.SleepingVesta
                : VestingTypeEnum.Vesta;
        const lockYears = mintOption === 1 ? vestaSleepingYears : 0;

        const stableVaultContract = new StableLiquidityVaultContract(address);
        await stableVaultContract.claimReward(vestingType, lockYears, mintPercent);
    }

    async function onStakeStakingToken() {
        const balance = parseBigNumber(walletStakingTokenAmount);

        let error = '';
        if (!address) {
            error = ERROR_CONNECT_WALLET;
        } else if (hasPendingTransactions) {
            error = ERROR_TRANSACTION_ONGOING;
        } else if (!isPositiveBigNumber(stakeAmount)) {
            error = ERROR_INVALID_NUMBER;
        } else if (convertEsdtToWei(stakeAmount, DEFAULT_DECIMALS).comparedTo(balance) > 0) {
            error = ERROR_NOT_ENOUGH_BALANCE;
        }

        if (error) {
            toastError(error);
            return;
        }

        const payments = [
            TokenTransfer.fungibleFromBigInteger(STABLESWAP_LP_TOKEN, convertEsdtToWei(stakeAmount, DEFAULT_DECIMALS)),
        ];
        const stableVaultContract = new StableLiquidityVaultContract(address);
        await stableVaultContract.stakeToken(payments);
    }

    async function onUnstakeStakingToken() {
        let error = '';
        if (!address) {
            error = ERROR_CONNECT_WALLET;
        } else if (hasPendingTransactions) {
            error = ERROR_TRANSACTION_ONGOING;
        } else if (!isPositiveBigNumber(stakeAmount)) {
            error = ERROR_INVALID_NUMBER;
        } else if (!vaultBaseContext || !vaultUserContext) {
            error = ERROR_SC_DATA_NOT_LOADED;
        } else {
            const balance = parseBigNumber(vaultUserContext.minor_power);
            if (convertEsdtToWei(stakeAmount, DEFAULT_DECIMALS).comparedTo(balance) > 0) {
                error = ERROR_NOT_ENOUGH_BALANCE;
            }
        }

        if (error) {
            toastError(error);
            return;
        }

        const stableVaultContract = new StableLiquidityVaultContract(address);
        if (unstakeWithVstFee) {
            const vstBalance = parseBigNumber(
                netstatsRedux.walletTokensMap[VST_TOKEN_ID]
                    ? netstatsRedux.walletTokensMap[VST_TOKEN_ID].balance ??
                          ZERO_STRING
                    : ZERO_STRING,
            );
            if (vstBalance.isLessThan(unstakeFeeAmount)) {
                toastError(`You need ${convertWeiToEsdt(unstakeFeeAmount)} VST to pay unstaking fee.`);
                return;
            }

            await stableVaultContract.unstakeTokenWithFee(
                [TokenTransfer.fungibleFromBigInteger(VST_TOKEN_ID, unstakeFeeAmount)],
                convertEsdtToWei(stakeAmount, DEFAULT_DECIMALS)
            );
        } else {
            await stableVaultContract.unstakeToken(convertEsdtToWei(stakeAmount, DEFAULT_DECIMALS));
        }
    }

    async function onClaimPremiumReward() {
        let error = '';
        if (!address) {
            error = ERROR_CONNECT_WALLET;
        } else if (hasPendingTransactions) {
            error = ERROR_TRANSACTION_ONGOING;
        } else if (!vaultUserContext) {
            error = ERROR_SC_DATA_NOT_LOADED;
        } else if (vaultUserContext.premium_reward_payments.length == 0) {
            error = 'No reward';
        } else {
            const sum = vaultUserContext.premium_reward_payments.reduce(
                (s, cur) => (s = s.plus(cur.amount)),
                new BigNumber(0),
            );
            if (sum.isZero()) {
                error = 'No reward';
            }
        }
        if (error) {
            toastError(error);
            return;
        }

        const stableVaultContract = new StableLiquidityVaultContract(address);
        await stableVaultContract.claimPremiumRewards();
    }

    async function onClaimEliteReward() {
        let error = '';
        if (!address) {
            error = ERROR_CONNECT_WALLET;
        } else if (hasPendingTransactions) {
            error = ERROR_TRANSACTION_ONGOING;
        } else if (!vaultUserContext) {
            error = ERROR_SC_DATA_NOT_LOADED;
        } else if (vaultUserContext.elite_reward_payments.length == 0) {
            error = 'No reward';
        } else {
            const sum = vaultUserContext.elite_reward_payments.reduce(
                (s, cur) => (s = s.plus(cur.amount)),
                new BigNumber(0),
            );
            if (sum.isZero()) {
                error = 'No reward';
            }
        }
        if (error) {
            toastError(error);
            return;
        }

        const stableVaultContract = new StableLiquidityVaultContract(address);
        await stableVaultContract.claimEliteRewards();
    }

    function onTokenSelected(token_id: string, holding_type: VestaVaultSelectedHolding) {
        setSelectedTokenId(token_id);
        setSelectedHolding(holding_type);
    }

    const nativeTokenSelectorForVault = [
        {
            token_name: 'PENTASTABLE',
            token_id: STABLESWAP_LP_TOKEN,
            holding_type: VestaVaultSelectedHolding.VaultVesta,
        },
    ];
    const nativeTokenSelectorForWallet = [
        {
            token_name: 'PENTASTABLE',
            token_id: STABLESWAP_LP_TOKEN,
            holding_type: VestaVaultSelectedHolding.WalletVesta,
        },
    ];

    return (
        <>
            <div className="container" style={{ marginTop: '50px' }}>
                <div className="mt-4">
                    <div className="vault-container-card" style={{ minHeight: '40rem' }}>
                        <div className="d-flex justify-content-start">
                            <button
                                className="go-back-button"
                                onClick={() => {
                                    navigate(`${routeNames.vaults}`);
                                }}
                            >
                                <BiLeftArrowAlt />
                            </button>
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                            <span style={{ color: '#F1DC46', fontSize: '1.2rem' }}>Stable-Coin Vault™</span>
                        </div>

                        <Row className="mt-4 p-0">
                            <Col md={6} lg={4} className="d-flex flex-column">
                                <div className="second-card-style position-relative d-flex justify-content-center align-items-center h-100">
                                    <div style={{ fontSize: '1.875rem' }}>
                                        TVL{' '}
                                        {convertToDollarString(
                                            vaultStatsContext
                                                ? convertWeiToEsdt(
                                                      vaultStatsContext.total_minor_power,
                                                      DEFAULT_DECIMALS,
                                                      DEFAULT_DECIMALS,
                                                  )
                                                : 0,
                                        )}
                                    </div>
                                    <InfoTooltip title="Total Value Locked" />
                                </div>
                            </Col>
                            <Col md={6} lg={8} className="d-flex flex-column mt-2" style={{ fontSize: '1rem' }}>
                                <div className="second-card-style px-3 h-100 d-flex flex-column justify-content-between">
                                    <div className="d-flex justify-content-between" style={{ color: '#a349a2' }}>
                                        <div>Minor Stable-Coin Power:</div>
                                        <div>
                                            {formatNumber(
                                                vaultUserContext
                                                    ? convertWeiToEsdt(vaultUserContext.minor_power).toNumber()
                                                    : 0,
                                                3,
                                            )}{' '}
                                            |{' '}
                                            {formatNumber(
                                                vaultStatsContext
                                                    ? convertWeiToEsdt(vaultStatsContext.total_minor_power).toNumber()
                                                    : 0,
                                                3,
                                            )}{' '}
                                            (
                                            {formatNumber(
                                                vaultStatsContext &&
                                                    vaultUserContext &&
                                                    !vaultStatsContext.total_minor_power.isZero()
                                                    ? vaultUserContext.minor_power
                                                          .div(vaultStatsContext.total_minor_power)
                                                          .multipliedBy(1_000)
                                                          .toNumber()
                                                    : 0,
                                                3,
                                            )}
                                            ‰)
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between" style={{ color: '#efe3af' }}>
                                        <div>Major Stable-Coin Power:</div>
                                        <div>
                                            {formatNumber(
                                                vaultUserContext
                                                    ? convertWeiToEsdt(vaultUserContext.major_power).toNumber()
                                                    : 0,
                                                3,
                                            )}{' '}
                                            |{' '}
                                            {formatNumber(
                                                vaultStatsContext
                                                    ? convertWeiToEsdt(vaultStatsContext.total_major_power).toNumber()
                                                    : 0,
                                                3,
                                            )}{' '}
                                            (
                                            {formatNumber(
                                                vaultStatsContext &&
                                                    vaultUserContext &&
                                                    !parseBigNumber(vaultStatsContext.total_major_power).isZero()
                                                    ? parseBigNumber(vaultUserContext.major_power)
                                                          .div(vaultStatsContext.total_major_power)
                                                          .multipliedBy(1_000)
                                                          .toNumber()
                                                    : 0,
                                                3,
                                            )}
                                            ‰)
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="mt-2 p-0 gy-4" style={{ fontSize: '1.1rem', color: '#98A1C0' }}>
                            <Col md={12} lg={4} className="d-flex flex-column">
                                <div className="vault-sft-staking-container">
                                    {/* <Row style={{ rowGap: '6px' }}> */}
                                    <Col className="p-1" xs={12}>
                                        <div className="text-center" style={{ fontSize: '1.2rem', color: '#F1DC46' }}>
                                            Vesta SFT Staking
                                        </div>
                                    </Col>
                                    <Col className="p-1" xs={12}>
                                        <div className="d-flex gap-2">
                                            <div
                                                className={
                                                    'sft-tag d-flex justify-content-center align-items-center w-20'
                                                }
                                            >
                                                Gold
                                            </div>
                                            <div
                                                className={
                                                    'sft-tag d-flex justify-content-center align-items-center w-20'
                                                }
                                            >
                                                Silver
                                            </div>
                                            <div
                                                className={
                                                    'sft-tag d-flex justify-content-center align-items-center w-20'
                                                }
                                            >
                                                Bronze
                                            </div>
                                            <div
                                                className="farm_card_individual_element d-flex justify-content-center align-items-center position-relative"
                                                style={{ width: '40%' }}
                                            >
                                                {SftCountdown ? <SftCountdown /> : <CountdownCompleted />}

                                                <InfoTooltip
                                                    title="Time until SFTs can be unstaked. Cooldown only starts after a reward claim event. When 'Unlockable' is shown, SFTs can be unstaked."
                                                    type={2}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex gap-2 mt-2">
                                            <div
                                                className={clsx(
                                                    'sft-box d-flex justify-content-center align-items-center w-20 gold-color',
                                                    selectedSftTier == 0 && 'selected',
                                                )}
                                                onClick={() => onClickSftTier(0)}
                                            >
                                                {stakedSftCounts[0]}
                                            </div>
                                            <div
                                                className={clsx(
                                                    'sft-box d-flex justify-content-center align-items-center w-20 silver-color',
                                                    selectedSftTier == 1 && 'selected',
                                                )}
                                                onClick={() => onClickSftTier(1)}
                                            >
                                                {stakedSftCounts[1]}
                                            </div>
                                            <div
                                                className={clsx(
                                                    'sft-box d-flex justify-content-center align-items-center w-20 bronze-color',
                                                    selectedSftTier == 2 && 'selected',
                                                )}
                                                onClick={() => onClickSftTier(2)}
                                            >
                                                {stakedSftCounts[2]}
                                            </div>
                                            <div
                                                className="sft-tag d-flex justify-content-center align-items-center w-20 position-relative"
                                                style={{ color: '#00b050', fontWeight: '500' }}
                                            >
                                                IM
                                                <InfoTooltip
                                                    title="Individual Multiplier (Green Vesta Multiplier)"
                                                    type={2}
                                                />
                                            </div>
                                            <div
                                                className="sft-tag d-flex justify-content-center align-items-center w-20"
                                                style={{ color: '#00b050', fontWeight: '500' }}
                                            >
                                                {formatNumber(vaultUserContext ? vaultUserContext.im : 1)}x
                                            </div>
                                        </div>
                                    </Col>

                                    <Col className="p-1" xs={12}>
                                        <div className="d-flex gap-2">
                                            <div className="w-60 sft-staking-spin-container d-flex justify-content-between align-items-center">
                                                <button
                                                    className="spin-but"
                                                    onClick={() => onChangeTargetSftCount(targetSftCount - 1)}
                                                >
                                                    {' '}
                                                    -{' '}
                                                </button>
                                                <span>
                                                    {targetSftCount}/
                                                    {stakedSftCounts[selectedSftTier] + sftBalances[selectedSftTier]}
                                                </span>
                                                <button
                                                    className="spin-but"
                                                    onClick={() => onChangeTargetSftCount(targetSftCount + 1)}
                                                >
                                                    {' '}
                                                    +{' '}
                                                </button>
                                            </div>

                                            <div className="w-40">
                                                <button className="farm_but w-100 h-100" onClick={onSftStakeOrUnstake}>
                                                    {targetSftCount > stakedSftCounts[selectedSftTier]
                                                        ? 'Stake'
                                                        : targetSftCount < stakedSftCounts[selectedSftTier]
                                                        ? 'Unstake'
                                                        : '-'}
                                                </button>
                                            </div>
                                        </div>
                                    </Col>
                                    {/* </Row> */}
                                </div>
                            </Col>

                            <Col md={12} lg={4}>
                                <div className="second-card-style px-3">
                                    <div className="token-stats-title mb-3">Vault Holdings</div>

                                    <div className="d-flex justify-content-start">
                                        {nativeTokenSelectorForVault.map((row, id) => {
                                            return (
                                                <button
                                                    className={clsx({
                                                        'vault-token-selector': true,
                                                        selected: selectedHolding == row.holding_type,
                                                    })}
                                                    key={`vault-token-selector${id}`}
                                                    onClick={() => onTokenSelected(row.token_id, row.holding_type)}
                                                >
                                                    <img src={getTokenLogo(row.token_id)} width={'48px'} />
                                                </button>
                                            );
                                        })}
                                    </div>

                                    <div className="text-right">
                                        {formatNumber(
                                            convertWeiToEsdt(
                                                vaultUserContext?.minor_power || '0',
                                                DEFAULT_DECIMALS,
                                                DEFAULT_DECIMALS,
                                            ),
                                            DEFAULT_DECIMALS,
                                        )}
                                    </div>
                                </div>
                            </Col>

                            <Col md={12} lg={4}>
                                <div className="second-card-style px-3">
                                    <div className="token-stats-title mb-3">Wallet Holdings</div>

                                    <div className="d-flex justify-content-start">
                                        {nativeTokenSelectorForWallet.map((row, id) => {
                                            return (
                                                <button
                                                    className={clsx({
                                                        'vault-token-selector': true,
                                                        selected: selectedHolding == row.holding_type,
                                                    })}
                                                    key={`wallet-token-selector${id}`}
                                                    onClick={() => onTokenSelected(row.token_id, row.holding_type)}
                                                >
                                                    <img src={getTokenLogo(row.token_id)} width={'48px'} />
                                                </button>
                                            );
                                        })}

                                        {/* <button
                                            className={clsx({
                                                'vault-token-selector': true,
                                                selected: selectedHolding == VestaVaultSelectedHolding.WalletMaximize,
                                            })}
                                            onClick={() => setSelectedHolding(VestaVaultSelectedHolding.WalletMaximize)}
                                        >
                                            <img src={getTokenLogo(USDD_TOKEN_ID)} width={'48px'} />
                                            <img src={getTokenLogo(USDC_TOKEN_ID)} width={'48px'} />
                                            <img src={getTokenLogo(USDT_TOKEN_ID)} width={'48px'} />
                                            <img src={getTokenLogo(BUSD_TOKEN_ID)} width={'48px'} />
                                            <img src={getTokenLogo(WDAI_TOKEN_ID)} width={'48px'} />
                                        </button> */}
                                    </div>

                                    <div className="text-right">
                                        {formatNumber(
                                            convertWeiToEsdt(walletStakingTokenAmount, DEFAULT_DECIMALS, DEFAULT_DECIMALS),
                                            DEFAULT_DECIMALS,
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <div style={{ height: '2rem' }}></div>
                        {selectedHolding == VestaVaultSelectedHolding.WalletMaximize && <FiveTokensStake />}
                        {(selectedHolding == VestaVaultSelectedHolding.VaultVesta ||
                            selectedHolding == VestaVaultSelectedHolding.VaultBVesta) && (
                            <Row>
                                <div className="col-12 col-lg-3"></div>
                                <div
                                    className="col-12 col-lg-6 py-3"
                                    style={{
                                        border: '1px solid #2c1b36',
                                        borderRadius: '10px',
                                        // backgroundColor: '#1B0921',
                                    }}
                                >
                                    <AddLiquidityInputToken
                                        tokenAmount={stakeAmount}
                                        onChangeTokenAmount={onChangeStakeAmount}
                                        tokenId={selectedTokenId}
                                        tokenTicker={createTokenTicker(selectedTokenId)}
                                        tokenBalance={formatNumber(
                                            convertWeiToEsdt(
                                                vaultUserContext ? vaultUserContext.minor_power : 0,
                                                DEFAULT_DECIMALS,
                                                DEFAULT_DECIMALS,
                                            ),
                                            DEFAULT_DECIMALS,
                                        )}
                                        onMaxTokenAmount={onMaxStakeAmount}
                                        error={stakeAmountError}
                                        feeInfo={selectedHolding == VestaVaultSelectedHolding.VaultVesta && vaultUserContext ? `${vaultUserContext.user_unstake_fee_percent * 100}% Fees for unstaking now` : undefined}
                                        info={
                                            selectedHolding == VestaVaultSelectedHolding.VaultVesta ? (
                                                <div className="text-right position-relative mb-4">
                                                    <span
                                                        style={{
                                                            position: 'absolute',
                                                            width: 'max-content',
                                                            right: '22px',
                                                        }}
                                                    >
                                                        Info about fees for unstaking
                                                    </span>
                                                    <InfoTooltip
                                                        title={
                                                            <div>
                                                                <div>Unstaking Fee:</div>

                                                                <div className="mt-2">vEGLD or VST: </div>
                                                                <div>1% 0 - 1 Days/Epochs </div>
                                                                <div>0.8% 2 - 3 Days/Epochs </div>
                                                                <div>0.4% 4 - 20 Days/Epochs </div>
                                                                <div>0% 21 Days/Epochs or more </div>
                                                            </div>
                                                        }
                                                    />
                                                </div>
                                            ) : undefined
                                        }
                                    />

                                    <div className="mt-2" style={{ padding: '10px 15px 10px 10px' }}>
                                        <CustomSlider2
                                            value={stakeSliderAmount}
                                            step={1}
                                            min={0}
                                            max={100}
                                            onChange={(v) => {
                                                setStakeSliderAmount(v);

                                                let balance = BIG_NUMBER_ZERO;
                                                if (vaultUserContext) {
                                                    balance = convertWeiToEsdt(
                                                        vaultUserContext.minor_power,
                                                        DEFAULT_DECIMALS,
                                                        DEFAULT_DECIMALS,
                                                    );
                                                }
                                                setStakeAmount(
                                                    convertBigNumberToInputString(
                                                        balance.multipliedBy(v).div(100),
                                                        DEFAULT_DECIMALS,
                                                    ),
                                                );
                                            }}
                                        />
                                    </div>

                                    <div className="d-flex justify-content-center align-items-center">
                                        <button
                                            className="mt-3 assets-button mx-1"
                                            disabled={hasPendingTransactions}
                                            onClick={onUnstakeStakingToken}
                                        >
                                            Unstake
                                        </button>

                                        {/* <RemoveLiquidity /> */}
                                    </div>

                                    <FormGroup className="d-flex justify-content-center align-items-center">
                                        <FormControlLabel
                                            sx={{
                                                color: '#98a1c0',
                                            }}
                                            control={
                                                <Checkbox
                                                    checked={unstakeWithVstFee}
                                                    onChange={(e, checked) => setUnstakeWithVstFee(checked)}
                                                    sx={{
                                                        color: '#fafafa',
                                                    }}
                                                />
                                            }
                                            label="Pay unstaking fee in VST"
                                        />
                                    </FormGroup>
                                </div>
                            </Row>
                        )}

                        {(selectedHolding == VestaVaultSelectedHolding.WalletVesta ||
                            selectedHolding == VestaVaultSelectedHolding.WalletBVesta) && (
                            <Row>
                                <div className="col-12 col-lg-3"></div>
                                <div
                                    className="col-12 col-lg-6 py-3"
                                    style={{
                                        border: '1px solid #ffffff0f',
                                        borderRadius: '10px',
                                        // backgroundColor: '#1B0921',
                                    }}
                                >
                                    <AddLiquidityInputToken
                                        tokenAmount={stakeAmount}
                                        onChangeTokenAmount={onChangeStakeAmount}
                                        tokenId={selectedTokenId}
                                        tokenTicker={createTokenTicker(selectedTokenId)}
                                        tokenBalance={formatNumber(
                                            convertWeiToEsdt(walletStakingTokenAmount, DEFAULT_DECIMALS, DEFAULT_DECIMALS),
                                            DEFAULT_DECIMALS,
                                        )}
                                        onMaxTokenAmount={onMaxStakeAmount}
                                        error={stakeAmountError}
                                    />

                                    <div className="mt-2" style={{ padding: '10px 15px 10px 10px' }}>
                                        <CustomSlider2
                                            value={stakeSliderAmount}
                                            step={1}
                                            min={0}
                                            max={100}
                                            onChange={(v) => {
                                                setStakeSliderAmount(v);

                                                const balance = convertWeiToEsdt(
                                                    walletStakingTokenAmount,
                                                    DEFAULT_DECIMALS,
                                                    DEFAULT_DECIMALS,
                                                );
                                                setStakeAmount(
                                                    convertBigNumberToInputString(
                                                        balance.multipliedBy(v).div(100),
                                                        DEFAULT_DECIMALS,
                                                    ),
                                                );
                                            }}
                                        />
                                    </div>

                                    <div className="d-flex justify-content-center align-items-center">
                                        <button
                                            className="mt-3 assets-button mx-1"
                                            disabled={hasPendingTransactions}
                                            onClick={onStakeStakingToken}
                                        >
                                            Stake
                                        </button>
                                        {/* <RemoveLiquidity /> */}
                                    </div>
                                </div>
                            </Row>
                        )}

                        <StableLiquidityVaultVestaMinter
                            mintOption={mintOption}
                            setMintOption={setMintOption}
                            mintPercent={mintPercent}
                            setMintPercent={setMintPercent}
                            vestaSleepingYears={vestaSleepingYears}
                            setVestaSleepingYears={setVestaSleepingYears}
                            vaultStatsContext={vaultStatsContext}
                            vaultUserContext={vaultUserContext}
                            totalValueLockeded={convertToDollarString(
                                vaultStatsContext
                                    ? convertWeiToEsdt(
                                          vaultStatsContext.total_minor_power,
                                          DEFAULT_DECIMALS,
                                          DEFAULT_DECIMALS,
                                      ).multipliedBy(getTokenPrice(STABLESWAP_LP_TOKEN))
                                    : 0,
                            )}
                            onMintVesta={onMintVesta}
                            powerName="Minor Stable-Coin"
                        />

                        <Row className="mt-4 p-0">
                            <Col>
                                <Row
                                    className="m-0 py-3 px-1"
                                    style={{
                                        border: '1px solid #a349a2',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <div className="col-12 text-center mb-3" style={{ color: '#a349a2' }}>
                                        Stable-Coin Rewards are governed by Minor Stable-Coin Power. Your share of all
                                        Minor Stable-Coin Power is{' '}
                                        {vaultStatsContext &&
                                        vaultUserContext &&
                                        parseBigNumber(vaultStatsContext.total_minor_power).isGreaterThan(0)
                                            ? formatNumber(
                                                  parseBigNumber(vaultUserContext.minor_power)
                                                      .div(vaultStatsContext.total_minor_power)
                                                      .multipliedBy(1000),
                                                  3,
                                              )
                                            : '0.000'}
                                        ‰.
                                    </div>
                                    <Col md={6} lg={4} className="d-flex flex-column px-1">
                                        <div
                                            className="second-card-style mb-0 d-flex flex-column align-items-between gap-2 px-3"
                                            style={{ backgroundColor: '#1B0921' }}
                                        >
                                            {
                                                vaultUserContext && vaultUserContext.premium_reward_payments.map((_payment, index) => <div key={index} className="d-flex align-items-center">
                                                    <img
                                                        src={getTokenLogo(_payment.token_identifier)}
                                                        style={{ height: '3rem', width: 'auto' }}
                                                    />
                                                    <div className="ms-2">
                                                        {printWalletBalanceAndPrice(
                                                            netstatsRedux,
                                                            _payment.token_identifier,
                                                            getTokenPrice(_payment.token_identifier),
                                                        )}
                                                    </div>
                                                </div>)
                                            }
                                        </div>
                                    </Col>
                                    <Col md={6} lg={8} className="d-flex flex-column px-1" style={{ fontSize: '1rem' }}>
                                        <div
                                            className="second-card-style mb-0 d-flex justify-content-between align-items-center px-3"
                                            style={{ backgroundColor: '#1B0921' }}
                                        >
                                            <div className="d-flex flex-column align-items-between gap-2">
                                                {
                                                    vaultUserContext && vaultUserContext.premium_reward_payments.map((_payment, index) => <div key={index} className="d-flex align-items-center">
                                                        <img
                                                            src={getTokenLogo(_payment.token_identifier)}
                                                            style={{ height: '3rem', width: 'auto' }}
                                                        />
                                                        <div className="ms-2">
                                                            {printPaymentAndPrice(_payment, getTokenPrice(_payment.token_identifier))}
                                                        </div>
                                                    </div>)
                                                }
                                            </div>
                                            <div>
                                                <button
                                                    className="farm_but py-2"
                                                    style={{ width: '6rem' }}
                                                    disabled={hasPendingTransactions}
                                                    onClick={onClaimPremiumReward}
                                                >
                                                    Claim
                                                </button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className="mt-4 p-0">
                            <Col>
                                <Row
                                    className="m-0 py-3 px-1"
                                    style={{
                                        border: '1px solid #efe3af',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <div className="col-12 text-center mb-3" style={{ color: '#efe3af' }}>
                                        Stable-Coin Rewards are governed by Major Stable-Coin Power. Your share of all
                                        Major Stable-Coin Power is{' '}
                                        {vaultStatsContext &&
                                        vaultUserContext &&
                                        parseBigNumber(vaultStatsContext.total_major_power).isGreaterThan(0)
                                            ? formatNumber(
                                                  parseBigNumber(vaultUserContext.major_power)
                                                      .div(vaultStatsContext.total_major_power)
                                                      .multipliedBy(1000),
                                                  3,
                                              )
                                            : '0.000'}
                                        ‰.
                                    </div>
                                    <Col md={6} lg={4} className="d-flex flex-column px-1">
                                        <div
                                            className="second-card-style mb-0 d-flex flex-column align-items-between gap-2 px-3"
                                            style={{ backgroundColor: '#1B0921' }}
                                        >
                                            {
                                                vaultUserContext && vaultUserContext.elite_reward_payments.map((_payment, index) => <div key={index} className="d-flex align-items-center">
                                                    <img
                                                        src={getTokenLogo(_payment.token_identifier)}
                                                        style={{ height: '3rem', width: 'auto' }}
                                                    />
                                                    <div className="ms-2">
                                                        {printWalletBalanceAndPrice(
                                                            netstatsRedux,
                                                            _payment.token_identifier,
                                                            getTokenPrice(_payment.token_identifier, aurynIndex),
                                                        )}
                                                    </div>
                                                </div>)
                                            }
                                        </div>
                                    </Col>
                                    <Col md={6} lg={8} className="d-flex flex-column px-1" style={{ fontSize: '1rem' }}>
                                        <div
                                            className="second-card-style mb-0 d-flex justify-content-between align-items-center px-3"
                                            style={{ backgroundColor: '#1B0921' }}
                                        >
                                            <div className="d-flex flex-column align-items-between gap-2">
                                                {
                                                    vaultUserContext && vaultUserContext.elite_reward_payments.map((_payment, index) => <div key={index} className="d-flex align-items-center">
                                                        <img
                                                            src={getTokenLogo(_payment.token_identifier)}
                                                            style={{ height: '3rem', width: 'auto' }}
                                                        />
                                                        <div className="ms-2">
                                                            {printPaymentAndPrice(_payment, getTokenPrice(_payment.token_identifier, aurynIndex))}
                                                        </div>
                                                    </div>)
                                                }
                                            </div>
                                            <div>
                                                <button
                                                    className="farm_but py-2"
                                                    style={{ width: '6rem' }}
                                                    disabled={hasPendingTransactions}
                                                    onClick={onClaimEliteReward}
                                                >
                                                    Claim
                                                </button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    );
};
