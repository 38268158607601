import React, { useState } from 'react';
import BigNumber from 'bignumber.js';
import { Col, Row } from 'react-bootstrap';
import Slider from 'react-slick';

import { TOKEN_INFO_MAP } from 'config';
import { selectNetstats } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { getTokenLogo } from 'z/elrond';
import { MemeLiquidityVaultUserContextType, OuroLiquidityVaultUserContextType } from 'z/types';
import { formatNumber, parseBigNumber, BIG_NUMBER_ZERO, convertWeiToEsdt } from 'z/utils';

enum SliderComponentTypeEnum {
    Vault = 'Vault',
    Wallet = 'Wallet',
}

interface LiquidityVestaVaultBoosterTokenParams {
    vaultUserContext?: OuroLiquidityVaultUserContextType | MemeLiquidityVaultUserContextType;
    onTokenSelected(token_id: string, holding_type: number | string): void;
    setLiquidityBalance: React.Dispatch<React.SetStateAction<BigNumber>>;
    tokenIdentifiers: string[];
    vaultMultiplier: BigNumber;
    vestaVaultSelectedHoldingVaultEnum: number | string;
    vestaVaultSelectedHoldingWalletEnum: number | string;
    isDisabled?: boolean;
}

const settings = {
    infinite: false,
    slidesToShow: 1,
    speed: 500,
};

export const LiquidityVestaVaultBoosterToken = ({
    vaultUserContext,
    onTokenSelected,
    setLiquidityBalance,
    tokenIdentifiers,
    vaultMultiplier,
    vestaVaultSelectedHoldingVaultEnum,
    vestaVaultSelectedHoldingWalletEnum,
    isDisabled,
}: LiquidityVestaVaultBoosterTokenParams) => {
    const netstatsRedux = useAppSelector(selectNetstats);

    const [selectedTokenIdIndex, setSelectedTokenIdIndex] = useState(0);

    const getVaultBalance = (): BigNumber => {
        if (vaultUserContext) {
            const tokenInVault = vaultUserContext.lp_token_staked_amounts.find(
                ({ token_identifier }) => token_identifier === tokenIdentifiers[selectedTokenIdIndex],
            );
            if (tokenInVault) {
                return parseBigNumber(tokenInVault.amount);
            }
        }
        return BIG_NUMBER_ZERO;
    };

    const getSliderComponent = (type: SliderComponentTypeEnum) => {
        const isVault = type === SliderComponentTypeEnum.Vault;
        const balance = isVault
            ? getVaultBalance()
            : parseBigNumber(netstatsRedux.walletTokensMap[tokenIdentifiers[selectedTokenIdIndex]]?.balance || '0');

        return (
            <div className="d-flex align-items-center justify-content-center">
                <button
                    className="vault-token-selector clickable text-center"
                    style={{
                        width: '60px',
                        border: '1px solid rgba(255, 255, 255, 0.06)',
                    }}
                    disabled={isDisabled}
                    onClick={() => {
                        if (!isDisabled) {
                            onTokenSelected(
                                tokenIdentifiers[selectedTokenIdIndex],
                                isVault ? vestaVaultSelectedHoldingVaultEnum : vestaVaultSelectedHoldingWalletEnum,
                            );
                            setLiquidityBalance(balance);
                        }
                    }}
                >
                    {type}
                </button>
                <div>
                    :&nbsp;&nbsp;
                    {formatNumber(
                        convertWeiToEsdt(
                            balance,
                            TOKEN_INFO_MAP[tokenIdentifiers[selectedTokenIdIndex]].decimals,
                            TOKEN_INFO_MAP[tokenIdentifiers[selectedTokenIdIndex]].decimals,
                        ),
                        6,
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className="vault-sft-staking-container">
            <Row>
                <Col sm={4} lg={3}>
                    <div
                        className="d-flex justify-content-center pointer-container"
                        onClick={() => setSelectedTokenIdIndex((selectedTokenIdIndex + 1) % tokenIdentifiers.length)}
                    >
                        <img
                            src={getTokenLogo(tokenIdentifiers[selectedTokenIdIndex])}
                            width={'70px'}
                            height={'70px'}
                        />
                    </div>
                </Col>
                <Col sm={8} lg={9}>
                    <div style={{ padding: '0px 30px' }}>
                        <Slider {...settings}>
                            {getSliderComponent(SliderComponentTypeEnum.Vault)}
                            {getSliderComponent(SliderComponentTypeEnum.Wallet)}
                        </Slider>

                        <div className="d-flex align-items-center justify-content-center">
                            <div className="text-center" style={{ width: '60px', padding: '4px 6px', margin: '4px' }}>
                                Boost
                            </div>
                            <div>
                                :&nbsp;&nbsp;
                                {vaultMultiplier.toString()}x
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};
