import {
  Address,
  AddressValue,
  ArgSerializer,
  BigUIntValue,
  StringValue,
  TokenIdentifierValue,
  TokenTransfer,
  TypedValue,
  U32Value,
  U64Value,
} from "@multiversx/sdk-core/out";
import { EAURYN_TOKEN_ID, SNAKE_TRANSFER_SC_ADDRESS } from "config";
import { elrondDappSendTransactions } from "./common";

export async function snakeTransfer(
  payments: TokenTransfer[],
  receiver: string,
  sender: string
) {
  const args: TypedValue[] = [
    new AddressValue(new Address(SNAKE_TRANSFER_SC_ADDRESS)),
    new U32Value(payments.length),
  ];
  let gasLimit = 50_000_000;
  payments.map(payment => {
    if (payment.tokenIdentifier === EAURYN_TOKEN_ID) gasLimit = 200_000_000;
    args.push(new TokenIdentifierValue(payment.tokenIdentifier));
    args.push(new U64Value(payment.nonce));
    args.push(new BigUIntValue(payment.amountAsBigInteger));
  });
  args.push(new StringValue('transfer'));
  args.push(new AddressValue(new Address(receiver)));
  

  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = `MultiESDTNFTTransfer@${argumentsString}`;

  const tx = {
    value: 0,
    data,
    receiver: sender,
    gasLimit,
  };
  
  const txName = 'Transfer';
  const { sessionId, error } = await elrondDappSendTransactions(tx, txName);
  
  return { sessionId, error };
}
