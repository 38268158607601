import React from 'react';

import { TOKEN_INFO_MAP } from 'config';
import { LpListLiquidityTokenSelectorDisplay } from 'z/types';
import { convertWeiToEsdt, formatNumber } from 'z/utils';

interface LiquidityHoldingLpListProps {
    lpList: LpListLiquidityTokenSelectorDisplay[];
    revert: boolean;
}

export const LiquidityHoldingLpList = ({ lpList, revert }: LiquidityHoldingLpListProps) => {
    return (
        <div className="d-flex flex-column gap-3">
            {lpList.map(({ first_token_id, second_token_id, token_id, lp_amount, first_amount, second_amount }, id) => {
                return (
                    <div
                        key={`lp-${id}`}
                        className="d-flex align-items-center justify-content-end"
                        style={{ flexDirection: revert ? 'row-reverse' : 'row' }}
                    >
                        <div className={`${revert ? 'text-left' : 'text-right'} mx-3`} style={{ fontSize: '0.875rem' }}>
                            <div>
                                {formatNumber(
                                    convertWeiToEsdt(
                                        lp_amount,
                                        TOKEN_INFO_MAP[token_id].decimals,
                                        TOKEN_INFO_MAP[token_id].decimals,
                                    ),
                                    TOKEN_INFO_MAP[token_id].decimals,
                                )}
                            </div>
                            <div
                                className="d-flex align-items-center gap-1 justify-content-end"
                                style={{ flexDirection: revert ? 'row-reverse' : 'row' }}
                            >
                                <span>
                                    {formatNumber(
                                        convertWeiToEsdt(
                                            first_amount,
                                            TOKEN_INFO_MAP[first_token_id].decimals,
                                            TOKEN_INFO_MAP[first_token_id].decimals,
                                        ),
                                        TOKEN_INFO_MAP[first_token_id].decimals,
                                    )}
                                </span>
                                <img src={TOKEN_INFO_MAP[first_token_id].logo} width={'30px'} />
                            </div>
                            <div
                                className="d-flex align-items-center gap-1 justify-content-end"
                                style={{ flexDirection: revert ? 'row-reverse' : 'row' }}
                            >
                                <span>
                                    {formatNumber(
                                        convertWeiToEsdt(
                                            second_amount,
                                            TOKEN_INFO_MAP[second_token_id].decimals,
                                            TOKEN_INFO_MAP[second_token_id].decimals,
                                        ),
                                        TOKEN_INFO_MAP[second_token_id].decimals,
                                    )}
                                </span>
                                <img src={TOKEN_INFO_MAP[second_token_id].logo} width={'30px'} />
                            </div>
                        </div>
                        <img src={TOKEN_INFO_MAP[token_id].logo} width={'70px'} />
                    </div>
                );
            })}
        </div>
    );
};
