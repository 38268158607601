import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { USDD_TOKEN_ID } from 'config';
import { RootState } from 'redux/store';
import { SlipContextType, SlipPoolType, SlipPoolTypeEnum } from 'z/types';

export interface SlipState {
    basePool?: SlipPoolType;
    pools: SlipPoolType[];
    ouroAmount: string;
    maxBaseTokenAmountThatCanBeAddedIntoSlip: string;
    context?: SlipContextType;
}

const initialState: SlipState = {
    basePool: undefined,
    pools: [],
    ouroAmount: '0',
    maxBaseTokenAmountThatCanBeAddedIntoSlip: '0',
};

export const slipSlice = createSlice({
    name: 'slip',
    initialState,
    reducers: {
        setContext: (state, action: PayloadAction<SlipContextType | undefined>) => {
            state.context = action.payload;
        },
        setSlipPools: (state, action: PayloadAction<SlipPoolType[]>) => {
            state.basePool = action.payload.find((pool) => pool.tokenIdentifier === USDD_TOKEN_ID);
            state.pools = action.payload
                .filter((pool) => pool.tokenIdentifier !== USDD_TOKEN_ID)
                .sort(({ pool_type }, { pool_type: pool_type2 }) =>
                    pool_type === SlipPoolTypeEnum.Gold
                        ? -1
                        : pool_type === SlipPoolTypeEnum.Silver && pool_type2 === SlipPoolTypeEnum.Bronze
                        ? -1
                        : 1,
                );
        },
        setOuroAmount: (state, action: PayloadAction<string>) => {
            state.ouroAmount = action.payload;
        },
        setMaxBaseTokenAmountThatCanBeAddedIntoSlip: (state, action: PayloadAction<string>) => {
            state.maxBaseTokenAmountThatCanBeAddedIntoSlip = action.payload;
        },
    },
});

export const { setSlipPools, setOuroAmount, setMaxBaseTokenAmountThatCanBeAddedIntoSlip, setContext } =
    slipSlice.actions;
export const selectSlip = (state: RootState) => state.slip;

export const slipReducer = slipSlice.reducer;
