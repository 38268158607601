import unknownTokenImg from 'assets/img/token/unknown-token.png';
import { TOKEN_INFO_MAP } from 'config';
import { DEFAULT_DECIMALS } from 'z/utils';

export function getTokenDecimals(tokenId: string): number {
    if (TOKEN_INFO_MAP[tokenId]) {
        return TOKEN_INFO_MAP[tokenId].decimals;
    }

    return DEFAULT_DECIMALS;
}

export const getTokenLogo = (tokenId?: string): string => {
    if (tokenId && TOKEN_INFO_MAP[tokenId]) return TOKEN_INFO_MAP[tokenId].logo;

    // mainnet
    // if (!IS_DEV) {
    //     return `https://media.elrond.com/tokens/asset/${tokenId}/logo.svg`;
    // }

    return unknownTokenImg;
};

export const getTokenName = (tokenId: string): string => {
    if (TOKEN_INFO_MAP[tokenId]) {
        return TOKEN_INFO_MAP[tokenId].name;
    }

    return 'Unknown token';
};
