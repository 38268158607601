/* eslint-disable import/order */
import React from 'react';
import { TransactionsToastList, SignTransactionsModals, NotificationModal } from '@multiversx/sdk-dapp/UI';
import { DappProvider } from '@multiversx/sdk-dapp/wrappers';
import { Route, Routes, BrowserRouter as Router, Navigate } from 'react-router-dom';
import { Layout } from 'components';
import { MVX_ACCESS_TIMEOUT, Environment, walletConnectV2ProjectId, IS_DEV } from 'config';
import { Assets } from 'pages/Assets';
import { CreatePool } from 'pages/CreatePool';
import { Farm } from 'pages/Farm';
import { FarmLiquidity } from 'pages/Farm/FarmLiquidity';
import { LiquidLocker } from 'pages/LiquidLocker';
import { PageNotFound } from 'pages/PageNotFound';
import { Pool } from 'pages/Pool';
import { Vaults } from 'pages/Vaults';
import { AutostakePool } from 'pages/Vaults/AutostakePool';
import { AuxiliaryVestaVault } from 'pages/Vaults/AuxiliaryVestaVault';
import { CapitalVestaVault } from 'pages/Vaults/CapitalVestaVault';
import { CryptoVestaVault } from 'pages/Vaults/CryptoVestaVault';
import { LiquidityVestaVault } from 'pages/Vaults/LiquidityVestaVault';
import { MemeLiquidityLPView } from 'pages/Vaults/MemeLiquidityLPView';
import { MemeLiquidityVestaVault } from 'pages/Vaults/MemeLiquidityVestaVault';
import { OuroLiquidityLPView } from 'pages/Vaults/OuroLiquidityLPView';
import { VaultAdmin } from 'pages/Vaults/VaultAdmin';
import { VegldVault } from 'pages/Vaults/VegldVault';
import { ReduxProvider } from 'redux/ReduxProvider';
import { Faucet } from 'pages/Faucet';
import { routeNames } from 'routes';
import { routes } from 'routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { MVX_TOAST_LIFETIME } from 'z/utils';
import NftStakingV2 from 'pages/NftStakingV2';
import SingleCollectionManage from 'pages/NftStakingV2/SingleCollectionManage';
import SubsidiaryStaking from 'pages/NftStakingV2/SubsidiaryStaking';
import SubsidiaryStakingRow from 'pages/NftStakingV2/SubsidiaryStakingRow';
import { EliteAccount } from 'pages/EliteAccount';
import { SnakeTransfer } from 'pages/Components/SnakeTransfer';
import UpdateEliteAccountModal from 'components/UpdateEliteAccountModal';
import StableCoin from 'pages/StableCoin';
import NftAdmin from 'pages/Admin/NftAdmin';
import { SlipAdmin } from 'pages/Pool/Slip';
import { useAdminHooks } from 'z/hooks/admin';
import { StableCoinAdmin } from 'pages/Admin/StableCoinAdmin';
import { Swap } from 'pages/Swap';
import { StablePool } from 'pages/StablePool';
import { StableLiquidityVault } from 'pages/Vaults/StableLiquidityVault/StableLiquidityVault';

// const CreatePool = React.lazy(() => import('./pages/CreatePool'));

// const LazyComponentWrapper: FC<PropsWithChildren> = ({ children }) => (
//     <React.Suspense fallback={<CustomLoading />}>
//         {children}
//     </React.Suspense>
// );

export const App = () => {
    const { isAdmin, isSwapAdmin } = useAdminHooks();

    return (
        <Router>
            <DappProvider
                environment={Environment}
                customNetworkConfig={{
                    name: 'vestax-dex',
                    apiTimeout: MVX_ACCESS_TIMEOUT,
                    walletConnectV2ProjectId,
                }}
                dappConfig={{ shouldUseWebViewProvider: true }}
            >
                <ReduxProvider>
                    <Layout>
                        <TransactionsToastList successfulToastLifetime={MVX_TOAST_LIFETIME} />
                        <NotificationModal />
                        <SignTransactionsModals className="custom-class-for-modals" />
                        <Routes>
                            <Route path={routeNames.home} element={<Navigate to={routeNames.swap} />} />
                            {routes.map((route, index) => (
                                <Route path={route.path} key={'route-key-' + index} element={<route.component />} />
                            ))}
                            <Route path={`${routeNames.swap}`} element={<Swap selectedTab={0} />} />
                            <Route path={`${routeNames.stableSwap}`} element={<Swap selectedTab={1} />} />
                            <Route path={routeNames.pools} element={<Pool />} />
                            <Route path={`${routeNames.pools}/:selectedTab`} element={<Pool />} />
                            {isSwapAdmin && <Route path={`${routeNames.createPool}`} element={<CreatePool />} />}
                            <Route path={routeNames.stablePools} element={<StablePool />} />
                            <Route path={`${routeNames.stablePools}/:selectedTab`} element={<StablePool />} />
                            <Route path={`${routeNames.farms}`} element={<Farm selectedTab={0} />} />
                            <Route path={`${routeNames.farms}/:paramFarmId`} element={<FarmLiquidity />} />
                            <Route path={`${routeNames.myfarms}`} element={<Farm selectedTab={1} />} />

                            <Route path={routeNames.assets} element={<Assets />} />
                            <Route path={`${routeNames.assets}/:selectedTab`} element={<Assets />} />

                            <Route path={routeNames.liquidLocker} element={<LiquidLocker />} />
                            <Route path={`${routeNames.liquidLocker}/:selectedTab`} element={<LiquidLocker />} />

                            <Route path={routeNames.vaults} element={<Vaults />} />
                            <Route path={routeNames.capitalVestaVault} element={<CapitalVestaVault />} />
                            <Route path={routeNames.auxVestaVault} element={<AuxiliaryVestaVault />} />
                            <Route path={routeNames.cryptoVestaVault} element={<CryptoVestaVault />} />
                            <Route path={routeNames.liquidityVestaVault} element={<LiquidityVestaVault />} />
                            <Route path={routeNames.memeLiquidityVestaVault} element={<MemeLiquidityVestaVault />} />
                            <Route path={`${routeNames.liquidityVestaVault}/LP`} element={<OuroLiquidityLPView />} />
                            <Route
                                path={`${routeNames.memeLiquidityVestaVault}/LP`}
                                element={<MemeLiquidityLPView />}
                            />
                            <Route path={routeNames.vegldVault} element={<VegldVault />} />
                            <Route path={routeNames.stableLiquidityVault} element={<StableLiquidityVault />} />
                            <Route path={routeNames.autostakePool} element={<AutostakePool />} />
                            {isAdmin && <Route path={routeNames.vaultAdmin} element={<VaultAdmin />} />}

                            <Route path={routeNames.transfer} element={<SnakeTransfer />} />
                            <Route path={routeNames.eliteAccount} element={<EliteAccount />} />
                            <Route path={routeNames.nftStaking} element={<NftStakingV2 />} />
                            <Route path={`${routeNames.subsidiaryStaking}`} element={<SubsidiaryStaking />} />
                            <Route path={routeNames.stableCoin} element={<StableCoin />} />
                            <Route
                                path={`${routeNames.subsidiaryStaking}/:stakingId`}
                                element={<SubsidiaryStakingRow />}
                            />
                            <Route path={`${routeNames.nftStaking}/:stakingId`} element={<SingleCollectionManage />} />
                            {IS_DEV && (
                                <>
                                    <Route path={routeNames.faucet} element={<Faucet />} />
                                </>
                            )}

                            {isAdmin && (
                                <>
                                    <Route path={routeNames.nftAdmin} element={<NftAdmin />} />
                                    <Route path={routeNames.slipAdmin} element={<SlipAdmin />} />
                                    <Route path={routeNames.stableCoinAdmin} element={<StableCoinAdmin />} />
                                </>
                            )}

                            <Route path="*" element={<PageNotFound />} />
                        </Routes>
                        <UpdateEliteAccountModal />
                    </Layout>
                </ReduxProvider>
            </DappProvider>
        </Router>
    );
};
