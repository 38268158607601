import React, { useEffect, useState } from 'react';
import { useGetAccountInfo, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';
import { Row } from 'react-bootstrap';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { EAURYN_TOKEN_ID } from 'config';
import { selectFarm, selectNetstats } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { routeNames } from 'routes';
import {
    snakeCoilViewBaseContext,
    viewAurynUncoilPositions,
    viewEliteAurynUncoilPositions,
    viewMaxEAurynUncoilAmount,
} from 'z/elrond';
import { SnakeCoilBaseContext, SnakeUncoilPosition } from 'z/types';
import { AutostakeAprInfoRow } from './AutostakeAprInfoRow';
import { AutostakeCoilRow } from './AutostakeCoilRow';
import { AutostakeUncoilRow } from './AutostakeUncoilRow';
import { WalletBalanceRow } from './WalletBalanceRow';

export const AutostakePool = () => {
    const navigate = useNavigate();
    const [unlockingAurynPositions, setUnlockingAurynPositions] = React.useState<SnakeUncoilPosition[]>([]);
    const [unlockingEAurynPositions, setUnlockingEAurynPositions] = React.useState<SnakeUncoilPosition[]>([]);
    const [snakeCoilBaseContext, setSnakeCoilBaseContext] = useState<SnakeCoilBaseContext>();

    const { address } = useGetAccountInfo();
    const { hasPendingTransactions } = useGetPendingTransactions();
    const [selectedUncoilToken, setSelectedUncoilToken] = useState<'AURYN' | 'EAURYN'>('AURYN');
    const [maxEaurynUncoilPositions, setMaxEaurynUncoilPositions] = useState(0);
    const [maxEaurynUncoilAmount, setMaxEaurynUncoilAmount] = useState(new BigNumber(0));

    const farmRedux = useAppSelector(selectFarm);
    const netstatsRedux = useAppSelector(selectNetstats);

    useEffect(() => {
        if (hasPendingTransactions) return;

        (async () => {
            const _snakeCoilBaseContext = await snakeCoilViewBaseContext();
            setSnakeCoilBaseContext(_snakeCoilBaseContext);

            if (!address) return;
            const _aurynUncoilPositions = await viewAurynUncoilPositions(address);
            setUnlockingAurynPositions(_aurynUncoilPositions);

            const _eaurynUncoilPositions = await viewEliteAurynUncoilPositions(address);
            setUnlockingEAurynPositions(_eaurynUncoilPositions);
        })();
    }, [address, hasPendingTransactions]);

    useEffect(() => {
        (async () => {
            if (hasPendingTransactions || !address) return;

            const _maxEaurynUncoilAmount = await viewMaxEAurynUncoilAmount(address);
            setMaxEaurynUncoilAmount(
                BigNumber.min(_maxEaurynUncoilAmount, netstatsRedux.walletTokensMap[EAURYN_TOKEN_ID]?.balance ?? '0'),
            );
        })();
    }, [address, hasPendingTransactions, netstatsRedux]);

    useEffect(() => {
        setMaxEaurynUncoilPositions(Math.max(1, farmRedux.eliteAccountTier - 1));
    }, [address, farmRedux.eliteAccountTier]);

    return (
        <>
            <div className="container" style={{ marginTop: '50px' }}>
                <div className="mt-4">
                    <div
                        className="vault-container-card"
                        style={{ minHeight: '40rem', color: '#98a1c0', fontSize: '1.1rem' }}
                    >
                        <div className="d-flex justify-content-start">
                            <button
                                className="go-back-button"
                                onClick={() => {
                                    navigate(`${routeNames.vaults}`);
                                }}
                            >
                                <BiLeftArrowAlt />
                            </button>
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                            <span style={{ color: '#F1DC46', fontSize: '1.2rem', fontWeight: 'bold' }}>
                                AUTOSTAKING POOL
                            </span>
                        </div>

                        {/* <div>TODO: Description here</div> */}

                        <WalletBalanceRow />

                        <AutostakeAprInfoRow />

                        <Row className="second-card-style d-flex flex-column mt-4 pt-0" style={{ rowGap: '8px' }}>
                            <div className="text-center my-3" style={{ color: '#F1DC46', fontSize: '1.25rem' }}>
                                Coil / Curl / Reserve
                            </div>

                            <AutostakeCoilRow
                                action="Coil OURO"
                                exchangeRate={
                                    snakeCoilBaseContext
                                        ? new BigNumber(1).dividedBy(
                                              new BigNumber(snakeCoilBaseContext.token_supplies[0]).dividedBy(
                                                  new BigNumber(snakeCoilBaseContext.token_supplies[1]),
                                              ),
                                          )
                                        : new BigNumber(0)
                                }
                            />
                            <AutostakeCoilRow
                                action="Curl OURO"
                                exchangeRate={
                                    snakeCoilBaseContext
                                        ? new BigNumber(1).dividedBy(
                                              new BigNumber(snakeCoilBaseContext.token_supplies[0]).dividedBy(
                                                  new BigNumber(snakeCoilBaseContext.token_supplies[1]),
                                              ),
                                          )
                                        : new BigNumber(0)
                                }
                            />
                            <AutostakeCoilRow action="Coil AURYN" exchangeRate={new BigNumber(1)} />
                        </Row>

                        <Row className="second-card-style d-flex flex-column mt-4 pt-0" style={{ rowGap: '8px' }}>
                            <div className="d-flex justify-content-center my-3" style={{ fontSize: '1.3rem' }}>
                                <button className="coil-but py-2 px-4" onClick={() => setSelectedUncoilToken('AURYN')}>
                                    Uncoil AURYN
                                </button>
                                <button
                                    className="coil-but py-2 px-4"
                                    style={{ marginLeft: '20px' }}
                                    onClick={() => setSelectedUncoilToken('EAURYN')}
                                >
                                    Uncoil Elite-AURYN
                                </button>
                            </div>

                            {(selectedUncoilToken === 'AURYN' ? unlockingAurynPositions : unlockingEAurynPositions).map(
                                (position, index) => (
                                    <AutostakeUncoilRow
                                        key={`uncoil-row-${index}`}
                                        index={index}
                                        disabled
                                        amount={position.uncoil_amount}
                                        claimTimestamp={position.uncoil_timestamp}
                                        eliteAccountTier={farmRedux.eliteAccountTier}
                                        selectedToken={selectedUncoilToken}
                                    />
                                ),
                            )}
                            {((selectedUncoilToken === 'AURYN' && unlockingAurynPositions.length < 7) ||
                                (selectedUncoilToken === 'EAURYN' &&
                                    unlockingEAurynPositions.length < maxEaurynUncoilPositions)) && (
                                <AutostakeUncoilRow
                                    index={
                                        selectedUncoilToken === 'AURYN'
                                            ? unlockingAurynPositions.length
                                            : unlockingEAurynPositions.length
                                    }
                                    exchangeRate={
                                        selectedUncoilToken === 'AURYN'
                                            ? snakeCoilBaseContext
                                                ? new BigNumber(snakeCoilBaseContext.token_supplies[0]).dividedBy(
                                                      new BigNumber(snakeCoilBaseContext.token_supplies[1]),
                                                  )
                                                : new BigNumber(0)
                                            : new BigNumber(1)
                                    }
                                    eliteAccountTier={farmRedux.eliteAccountTier}
                                    selectedToken={selectedUncoilToken}
                                    maxEliteAurynUncoilAmount={maxEaurynUncoilAmount}
                                />
                            )}

                            <div className="d-flex justify-content-center mt-3">
                                {selectedUncoilToken === 'AURYN' &&
                                    'You can have at most 7 uncoiling positions at a time, with fees ranging from 2‰ to 14‰.'}
                                {selectedUncoilToken === 'EAURYN' &&
                                    `You can have at most ${maxEaurynUncoilPositions} uncoiling positions at a time.`}{' '}
                                More info
                                <a
                                    href={
                                        selectedUncoilToken === 'AURYN'
                                            ? 'https://demiourgos-holdings-tm.gitbook.io/demiourgos-library/v/snake-tokenomics/snake-tokens-specifications/auryn-uncoil'
                                            : 'https://demiourgos-holdings-tm.gitbook.io/demiourgos-library/v/snake-tokenomics/snake-tokens-specifications/elite-auryn-uncoil'
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ paddingLeft: '5px' }}
                                >
                                    here.
                                </a>
                            </div>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    );
};
