import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
    farmReducer,
    netstatsReducer,
    nftStakingReducer,
    swapReducer,
    slipReducer,
    nftStakingV2Reducer,
    eliteAccountTrackerReducer,
    adminReducer,
} from './reducers';
import { ouroLiquidityVaultReducer } from './reducers/ouro-liquidity-vault';
import { stableCoinProtocolReducer } from './reducers/stablecoin-protocol';

export const store = configureStore({
    reducer: {
        netstats: netstatsReducer,
        swap: swapReducer,
        farm: farmReducer,
        stableCoinProtocol: stableCoinProtocolReducer,
        nftStaking: nftStakingReducer,
        nftStakingV2: nftStakingV2Reducer,
        slip: slipReducer,
        eliteAccountTracker: eliteAccountTrackerReducer,
        admin: adminReducer,
        ouroLiquidityVault: ouroLiquidityVaultReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
