import React, { useEffect, useState } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse } from '@mui/material';
import { useGetAccount, useGetLoginInfo } from '@multiversx/sdk-dapp/hooks';
import clsx from 'clsx';
import Modal from 'react-modal';
import { ElrondAddressLink } from 'components/Elements/ElrondAddressLink';
import { FEE_DENOMINATOR } from 'config';
import { getTokenDecimals, getTokenLogo } from 'z/elrond';
import { FeeReceiver, StableswapContract, StableswapPool } from 'z/elrond/stableswap';
import { StateEnum } from 'z/types';
import {
    formatNumber,
    convertToDollarString,
    createTokenTicker,
    convertWeiToEsdt,
    ERROR_CONNECT_WALLET,
    ERROR_INVALID_INPUTS,
    ERROR_INVALID_MVX_ADDRESS,
    ERROR_INVALID_NUMBER,
    isValidElrondAddress,
    parseNumberOrUndefined,
    toastError,
    TOTAL_PERCENTAGE,
    DEFAULT_DECIMALS,
} from 'z/utils';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

export const StablePoolRow = ({
    pool,
}: {
    pool: StableswapPool,
}) => {
    const { isLoggedIn } = useGetLoginInfo();
    const { address } = useGetAccount();

    const sharesSum = pool.fee_receivers.reduce((sum, current) => sum + current.shares, 0);

    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [feeCollectorAddresses, setFeeCollectorAddresses] = useState<string[]>([]);
    const [feeCollectorAddressesError, setFeeCollectorAddressesError] = useState<string[]>([]);
    const [feeCollectorPercentages, setFeeCollectorPercentages] = useState<number[]>([]);
    const [feeCollectorPercentagesError, setFeeCollectorPercentagesError] = useState<string[]>([]);
    const [feeCollectorMethods, setFeeCollectorMethods] = useState<string[]>([]);
    const [feeCollectorMethodsError, setFeeCollectorMethodsError] = useState<string[]>([]);

    const contract = new StableswapContract();

    const [isSetFeeDistributionModalOpen, setIsSetFeeDistributionModalOpen] = React.useState(false);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const modalPercentageSum = feeCollectorPercentages.reduce((sum, current) => sum + current, 0);

    useEffect(() => {
        if (!address) {
            setIsAdmin(false);
        } else {
            (async () => {
                const _admins = await contract.getOwnersAndAdmins();
                const _isAdmin = _admins.indexOf(address) >= 0;
                setIsAdmin(_isAdmin);
            })();
        }
    }, [address]);

    function onOpenSetFeeDistributionModalOpen() {
        setFeeCollectorAddresses(pool.fee_receivers.map((v) => v.address));
        setFeeCollectorPercentages(pool.fee_receivers.map((v) => v.shares));
        setFeeCollectorMethods(pool.fee_receivers.map((v) => v.method));
        setIsSetFeeDistributionModalOpen(true);
    }

    function clearSetFeeDistributionModal() {
        setFeeCollectorAddresses([]);
        setFeeCollectorPercentages([]);
        setFeeCollectorMethods([]);
        setFeeCollectorAddressesError([]);
        setFeeCollectorPercentagesError([]);
        setFeeCollectorMethodsError([]);
    }

    function onCloseSetFeeDistributionModal() {
        clearSetFeeDistributionModal();
        setIsSetFeeDistributionModalOpen(false);
    }

    function toggleShowDetailsButton() {
        setShowDetails(!showDetails);
    }

    async function setPoolState(state: StateEnum) {
        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_WALLET);
            return;
        }

        await contract.setPoolState(state, address);
    }

    function onAddRow() {
        setFeeCollectorAddresses(feeCollectorAddresses.concat(['']));
        setFeeCollectorPercentages(feeCollectorPercentages.concat([0]));
        setFeeCollectorMethods(feeCollectorMethods.concat(['']));

        setFeeCollectorAddressesError(feeCollectorAddressesError.concat(['']));
        setFeeCollectorPercentagesError(feeCollectorPercentagesError.concat(['']));
        setFeeCollectorMethodsError(feeCollectorMethodsError.concat(['']));
    }

    function onRemoveRow() {
        setFeeCollectorAddresses(feeCollectorAddresses.slice(0, -1));
        setFeeCollectorPercentages(feeCollectorPercentages.slice(0, -1));
        setFeeCollectorMethods(feeCollectorMethods.slice(0, -1));

        setFeeCollectorAddressesError(feeCollectorAddressesError.slice(0, -1));
        setFeeCollectorPercentagesError(feeCollectorPercentagesError.slice(0, -1));
        setFeeCollectorMethodsError(feeCollectorMethodsError.slice(0, -1));
    }

    function onChangeFeeCollectorAddress(index: number, value: string) {
        let error = '';
        if (!isValidElrondAddress(value)) {
            error = ERROR_INVALID_MVX_ADDRESS;
        }

        setFeeCollectorAddresses(feeCollectorAddresses.map((v, i) => i === index ? value : v));
        setFeeCollectorAddressesError(feeCollectorAddressesError.map((v, i) => i === index ? error : v));
    }

    function onChangeFeeCollectorPercentage(index: number, value: string) {
        const valueAsNumber = parseNumberOrUndefined(value);
        let error = '';
        if (valueAsNumber == null) {
            error = ERROR_INVALID_NUMBER;
        } else  if (valueAsNumber <= 0) {
            error = ERROR_INVALID_NUMBER;
        }

        setFeeCollectorPercentages(feeCollectorPercentages.map((v, i) => i === index ? valueAsNumber ?? 0 : v));
        setFeeCollectorPercentagesError(feeCollectorPercentagesError.map((v, i) => i === index ? error : v));
    }

    function onChangeFeeCollectorMethod(index: number, value: string) {
        const error = '';
        setFeeCollectorMethods(feeCollectorMethods.map((v, i) => i === index ? value : v));
        setFeeCollectorMethodsError(feeCollectorMethodsError.map((v, i) => i === index ? error : v));
    }

    async function onSetFeeCollectorsMap() {
        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_WALLET);
            return;
        }
        if (!(feeCollectorPercentages.length > 0 && feeCollectorAddressesError.every((v) => !v && isValidElrondAddress(v)) && feeCollectorPercentagesError.every((v) => !v) && feeCollectorPercentages.every((v) => v > 0))) {
            toastError(ERROR_INVALID_INPUTS);
            return;
        }
        if (!feeCollectorPercentages.every((v) => v === Math.floor(v))) {
            toastError('Values must be integer');
            return;
        }

        const _feeReceivers: FeeReceiver[] = feeCollectorAddresses.map((fAddress, i) => ({
            address: fAddress,
            shares: feeCollectorPercentages[i],
            method: feeCollectorMethods[i],
        }));
        await contract.setFeeReceivers(_feeReceivers, address);
    }

    const poolName = `${createTokenTicker(pool.token_ids[0])}-${createTokenTicker(pool.token_ids[1])}-${createTokenTicker(pool.token_ids[2])}`;
    return (
        <>
            <div className="active-pool-li-container">
                <div className='active-pool-li'>
                    <div className="d-flex align-items-center pool-row-item-1">
                        {
                            pool.token_ids.map((tokenId, i) => <img
                                key={i}
                                src={getTokenLogo(tokenId)}
                                alt="logo"
                                width="32rem"
                            />)
                        }

                        <div className="d-flex flex-column justify-content-between ms-2">
                            <div style={{ color: 'white', fontSize: '1.2rem' }}>
                            {poolName}
                            </div>
                            <div className="d-flex justify-content-start align-items-center gap-1">
                                <div className="fee-badge">
                                    {pool.swap_fee / FEE_DENOMINATOR}%
                                </div>
                                <div
                                    className={clsx(
                                        'fee-badge',
                                        pool.pool_state == StateEnum.Active
                                            ? 'state-active'
                                            : pool.pool_state == StateEnum.ActiveNoSwaps
                                                ? 'state-active-no-swaps'
                                                : 'state-inactive',
                                    )}
                                >
                                    {pool.pool_state}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="active-pool-info pool-row-item-2">
                        <span>Liquidity</span>
                        <span>
                            {
                                convertToDollarString(
                                    contract.computeUsdValue(pool, pool.lp_token_supply)
                                )
                            }
                        </span>
                    </div>

                    {
                        pool.token_ids.map((tokenId, i) => <div key={i} className="active-pool-info" style={{ width: '15%' }}>
                            <div className="d-flex align-items-center gap-1">
                                <img
                                    src={getTokenLogo(tokenId)}
                                    alt="logo"
                                    width="18px"
                                />
                                <span style={{ fontSize: '1rem', color: '#98A1C0' }}>
                                    {createTokenTicker(tokenId)}
                                </span>
                            </div>
                            <span>
                                {
                                    formatNumber(
                                        convertWeiToEsdt(pool.token_balances[i], getTokenDecimals(tokenId)),
                                        0
                                    )
                                }
                            </span>
                        </div>)
                    }

                    {
                        isAdmin && (
                            <div className="active-pool-info">
                                <button
                                    className="pool-collapse-button"
                                    onClick={toggleShowDetailsButton}
                                >
                                    {showDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </button>
                            </div>
                        )
                    }
                </div>

                {
                    isAdmin && (
                        <Collapse in={showDetails}>
                            <div className='row mt-3'>
                                <div className='col-sm-12 col-lg-6'>
                                    <div className="pool-row-label-container">
                                        <span>Pool Address:</span> <ElrondAddressLink address={pool.pool_address} />
                                    </div>
                                    <div className="pool-row-label-container">
                                        <span>First Token:</span> {pool.token_ids[0]}
                                    </div>
                                    <div className="pool-row-label-container">
                                        <span>Second Token:</span> {pool.token_ids[1]}
                                    </div>
                                    <div className="pool-row-label-container">
                                        <span>Third Token:</span> {pool.token_ids[2]}
                                    </div>
                                    <div className="pool-row-label-container">
                                        <span>LP Token:</span> {pool.lp_token_id}
                                    </div>
                                    <div className="pool-row-label-container">
                                        <span>LP Token Decimals:</span> {DEFAULT_DECIMALS}
                                    </div>
                                    <div className="pool-row-label-container">
                                        <span>LP Supply:</span> {formatNumber(convertWeiToEsdt(pool.lp_token_supply))}
                                    </div>
                                    <div className="pool-row-label-container">
                                        <span>Total Fee:</span> {pool.swap_fee / FEE_DENOMINATOR} %
                                    </div>
                                    <div className="pool-row-label-container">
                                        <span>Special Fee:</span> {pool.special_fee / FEE_DENOMINATOR} %
                                    </div>
                                </div>
                                <div className='col-sm-12 col-lg-6'>
                                    <div className='d-flex justify-content-start gap-4 mt-2'>
                                        <div style={{ color: '#939da7', fontSize: '1rem', width: '120px' }}>Pool State:</div>
                                        <div className='d-flex flex-column justify-content-between gap-2'>
                                            <button
                                                className={clsx('pool-state-button', pool.pool_state == StateEnum.Active && 'selected')}
                                                onClick={() => setPoolState(StateEnum.Active)}
                                            >
                                                Set Active
                                            </button>
                                            <button
                                                className={clsx('pool-state-button', pool.pool_state == StateEnum.ActiveNoSwaps && 'selected')}
                                                onClick={() => setPoolState(StateEnum.ActiveNoSwaps)}
                                            >
                                                Set ActiveNoSwaps
                                            </button>
                                            <button
                                                className={clsx('pool-state-button', pool.pool_state == StateEnum.Inactive && 'selected')}
                                                onClick={() => setPoolState(StateEnum.Inactive)}
                                            >
                                                Set Inactive
                                            </button>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-start gap-4 mt-5'>
                                        <div style={{ color: '#939da7', fontSize: '1rem', width: '120px' }}>Fee Distribution:</div>
                                        <div className='w-100'>
                                            <div className='row mb-1' style={{ color: 'white' }}>
                                                <div className='col-8'>Total Fee:</div>
                                                <div className='col-4'>{`${pool.swap_fee / TOTAL_PERCENTAGE} %`}</div>
                                            </div>
                                            <div className='row mb-3' style={{ color: 'white' }}>
                                                <div className='col-8'>Special Fee:</div>
                                                <div className='col-4'>{`${pool.special_fee / TOTAL_PERCENTAGE} %`}</div>
                                            </div>
                                            <table className='table'>
                                                <thead>
                                                    <tr style={{ color: '#f1dc46', fontSize: '.9rem' }}>
                                                        <th scope="col">Address</th>
                                                        <th scope="col">Shares (Percent)</th>
                                                        <th scope="col">Method</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        pool.fee_receivers.map((row, i) => (
                                                            <tr style={{ color: 'white' }} key={i}>
                                                                <td className='d-flex justify-content-start'>{<ElrondAddressLink address={row.address} />}</td>
                                                                <td>{`${row.shares} (${formatNumber(pool.special_fee * row.shares / sharesSum / FEE_DENOMINATOR, 3)} %)`}</td>
                                                                <td>{row.method}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                            
                                            <button
                                                className='pool-state-button mt-3'
                                                onClick={onOpenSetFeeDistributionModalOpen}
                                            >
                                                Set Fee Distribution
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Collapse >
                    )
                }
            </div>
            

            <Modal
                isOpen={isSetFeeDistributionModalOpen}
                onRequestClose={onCloseSetFeeDistributionModal}
                style={customStyles}
                ariaHideApp={false}
            >
                <div className="px-4">
                    <div className='text-center mt-4 mb-4' style={{
                        fontSize: '1.8rem',
                        fontWeight: 300,
                        color: '#F1DC46',
                    }}>Set Fee Distribution</div>

                    <div className='row mb-3 pl-3' style={{ color: 'white' }}>
                        <div>Total Fee: {`${pool.swap_fee / TOTAL_PERCENTAGE} %`}</div>
                        <div>Special Fee: {`${pool.special_fee / TOTAL_PERCENTAGE} %`}</div>
                    </div>

                    <div style={{ minHeight: '300px', minWidth: '400px' }}>
                        <div
                            className='row text-center'
                            style={{
                                fontSize: '.9rem',
                                fontWeight: 300,
                                color: '#F1DC46',
                            }}
                        >
                            <div className='col-6'>Address</div>
                            <div className='col-2'>Shares</div>
                            <div className='col-2'>Method</div>
                        </div>
                        {
                            feeCollectorAddresses.map((_v, index) => (
                                <div className="row" key={index}>
                                    <div className='col-6'>
                                        <div className="cp-init-input-container my-1">
                                            <input
                                                className="cp-init-input"
                                                style={{ fontSize: '1rem' }}
                                                value={feeCollectorAddresses[index]}
                                                onChange={(e) => onChangeFeeCollectorAddress(index, e.target.value)}
                                            />
                                        </div>
                                        <div className="cp-input-token-error ms-3">
                                            {feeCollectorAddressesError[index]}
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className="cp-init-input-container my-1">
                                            <input
                                                type="number"
                                                className="cp-init-input text-right"
                                                style={{ fontSize: '1.1rem' }}
                                                value={feeCollectorPercentages[index]}
                                                onChange={(e) => onChangeFeeCollectorPercentage(index, e.target.value)}
                                            />
                                        </div>
                                        <div className="cp-input-token-error ms-3">
                                            {feeCollectorPercentagesError[index]}
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <div className="cp-init-input-container my-1">
                                            <input
                                                className="cp-init-input"
                                                style={{ fontSize: '1.1rem' }}
                                                value={feeCollectorMethods[index]}
                                                onChange={(e) => onChangeFeeCollectorMethod(index, e.target.value)}
                                            />
                                        </div>
                                        <div className="cp-input-token-error ms-3">
                                            {feeCollectorMethodsError[index]}
                                        </div>
                                    </div>
                                    <div className='col-2 d-flex align-items-center'>
                                        <div style={{ color: 'white' }}>
                                            {
                                                modalPercentageSum > 0 ?
                                                    `${formatNumber(pool.special_fee * feeCollectorPercentages[index] / modalPercentageSum / TOTAL_PERCENTAGE, 3)} %`
                                                    : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className='d-flex justify-content-center gap-2 mt-4'>
                        <button
                            className='pool-state-button'
                            onClick={onAddRow}
                        >
                            Add Row
                        </button>
                        <button
                            className='pool-state-button'
                            onClick={onRemoveRow}
                        >
                            Remove Row
                        </button>
                        <button
                            className='pool-state-button'
                            onClick={onSetFeeCollectorsMap}
                        >
                            Set Fees
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
};
