export enum StakingStateEnum {
    Inactive = 'Inactive',
    Active = 'Active',
}
export function parseStakingStateEnum(val: string): StakingStateEnum {
    return StakingStateEnum[val as Exclude<keyof typeof StakingStateEnum, 'parse'>];
}

export interface StakingBaseContext {
    state: StakingStateEnum,
    stake_token: string,
    apr: number,
    min_stake_amount: string,
    max_stake_amount: string,
    admins: string[],

    //
    total_users: number,
    total_staked_amount: string,
}

export interface StakingUserContext {
    staked_amount: string,
    current_reward_amount: string,
    last_reward_update_timestamp: number,
}
