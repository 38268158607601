import React, { useEffect, useState } from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Collapse } from '@mui/material';
import { TokenTransfer } from '@multiversx/sdk-core/out';
import { useGetAccount, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import { NftType } from '@multiversx/sdk-dapp/types/tokens.types';
import clsx from 'clsx';
import { FEE_DENOMINATOR } from 'config';
import { selectNetstats } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { decodeVestedAttributes, snakeUnlock } from 'z/elrond';
import { formatNumber, convertWeiToEsdt, ERROR_CONNECT_WALLET, ERROR_TRANSACTION_ONGOING, toastError } from 'z/utils';

export function VestingTokenCard({
  token,
  selected,
  toggleSelected,
} : {
  token: NftType,
  selected: boolean,
  toggleSelected: () => void,
}) {
  const { address } = useGetAccount();
  const { hasPendingTransactions } = useGetPendingTransactions();
  
  const netstatsRedux = useAppSelector(selectNetstats);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const unlockMilestones = decodeVestedAttributes(token.attributes);
  const [isUnlockable, setIsUnlockable] = useState<boolean>(false);

  function toggleShowDetailsButton() {
    setShowDetails(!showDetails);
  }

  useEffect(() => {
    (async () => {
      if (netstatsRedux && netstatsRedux.elrondStats != undefined && netstatsRedux.elrondStats.epoch && unlockMilestones.length > 0) {
        let _isUnlockable = false;
        for (const um of unlockMilestones) {
          if (um.unlock_epoch <= netstatsRedux.elrondStats.epoch) {
            _isUnlockable = true;
            break;
          }
        }
        setIsUnlockable(_isUnlockable);
      }
    })();
  }, [netstatsRedux.elrondStats]);

  async function unlock() {
    let error = '';
    if (!address) {
      error = ERROR_CONNECT_WALLET;
    } else if (hasPendingTransactions) {
      error = ERROR_TRANSACTION_ONGOING;
    }

    if (error) {
      toastError(error);
      return;
    }

    await snakeUnlock(
      TokenTransfer.metaEsdtFromBigInteger(token.collection, token.nonce, token.balance),
      address,
    );
  }

  return (
    <div
      className={clsx('vested-token-container clickable mb-3', selected && 'selected')}
      onClick={(e) => {
        if (e.currentTarget.tagName == 'button') {
          e.stopPropagation();
          return;
        }
        toggleSelected();
      }}
    >
      <div className='d-flex justify-content-between align-items-center flex-row'>
          <div className="vested-token">
              <div className="d-flex align-items-center gap-1">
                  <span style={{ fontSize: '1rem', color: '#98A1C0' }}>
                      {token.identifier}
                  </span>
              </div>
              <span>
                  {formatNumber(convertWeiToEsdt(token.balance, token.decimals))}
              </span>
          </div>

          <div className="vested-token">
              <button
                  className="pool-collapse-button"
                  onClick={toggleShowDetailsButton}
              >
                  {showDetails ? <ExpandLess /> : <ExpandMore />}
              </button>
          </div>
      </div>

      {
        isUnlockable && (
          <div className='d-flex justify-content-between align-items-center'>
            <button
              className="mt-3 add-liquidity-button"
              disabled={hasPendingTransactions}
              onClick={unlock}
            >
              Unlock
            </button>
          </div>
        )
      }

      <Collapse in={showDetails}>
          <div className='mt-4'>
              <div
                style={{
                  color: '#F1DC46',
                  textAlign: 'center',
                  fontSize: '1rem',
                }}
              >
                Unlock Schedule
              </div>
              {
                  unlockMilestones.map((um, index) => {
                      let unlockTimeText = '-';
                      if (netstatsRedux.elrondStats) {
                          if (um.unlock_epoch <= netstatsRedux.elrondStats.epoch) {
                              unlockTimeText = 'Unlockable';
                          } else {
                              const unlockDate = new Date(Date.now() + (um.unlock_epoch - netstatsRedux.elrondStats.epoch) * 3600 * 24 * 1000);
                              unlockTimeText = unlockDate.toLocaleDateString();
                          }
                      }
                      
                      return (
                          <div className="presale-label-row" key={index}>
                              <span>{formatNumber(um.unlock_percent / FEE_DENOMINATOR)}%</span>
                              <span>{unlockTimeText}</span>
                          </div>
                      );
                  })
              }
          </div>
      </Collapse>
    </div>
  );
}