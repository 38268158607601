import BigNumber from 'bignumber.js';

export const BIG_NUMBER_ROUNDING_MODE = BigNumber.ROUND_FLOOR;
export const BIG_NUMBER_ZERO = new BigNumber(0);
export const BIG_NUMBER_ONE = new BigNumber(1);

export const parseBigNumber = (value: BigNumber.Value | null): BigNumber => {
  try {
    if (value != null) {
      return new BigNumber(value);
    }
  } catch (err) {
  }
  return new BigNumber(0);
};

export const isPositiveOrZeroBigNumber = (value: BigNumber.Value): boolean => {
  try {
    if (typeof value === typeof '' && !value) {
      return false;
    }
    return new BigNumber(value).comparedTo(0) >= 0;
  } catch (err) {
    return false;
  }
};

export const isPositiveBigNumber = (value: BigNumber.Value): boolean => {
  try {
    return new BigNumber(value).comparedTo(0) > 0;
  } catch (err) {
    return false;
  }
};

//
// const localDecimalSeparator = 0.1.toLocaleString().replace(/\d/g, '');
// const bgFormatter = {
//   decimalSeparator: localDecimalSeparator,
//   groupSeparator: localDecimalSeparator == '.' ? ',' : '.',
//   groupSize: 3
// };

export const formatNumber = (
  value: BigNumber.Value,
  precision?: number,
  // removeTrailingZero: boolean = true,
): string => {
  precision = precision != undefined ? precision : 4;
  let bv = parseBigNumber(value);
  bv = bv.isNaN() ? new BigNumber(0) : bv;
  const v = bv.toFormat(precision, BIG_NUMBER_ROUNDING_MODE);
  if (bv.comparedTo(0) <= 0) {
    return v;
  }
  if (bv.comparedTo(0.1 ** precision) < 0) {
    if (precision === 0) return '0';
    return `<0.${'0'.repeat(precision - 1)}1`;
  }
  
  // remove trailing zeros
  // if (removeTrailingZero && v.indexOf(localDecimalSeparator) >= 0) {
  //   v = v.replace(/\.?0+$/, '');
  // }
  
  return v;
};

const convertBigNumberToInputStringFormatter = {
  decimalSeparator: '.',
  groupSeparator: '',
  groupSize: 3
};

export const convertBigNumberToInputString = (
  value: BigNumber.Value,
  decimals: number,
): string => {
  let bv = parseBigNumber(value);
  bv = bv.isNaN() ? new BigNumber(0) : bv;
  let v = bv.toFormat(decimals, BIG_NUMBER_ROUNDING_MODE, convertBigNumberToInputStringFormatter);
  
  // remove trailing zeros
  if (v.indexOf('.') >= 0) {
    v = v.replace(/\.?0+$/, '');
  }
  
  return v;
};

export function parseNumberOrUndefined(value: string): number | undefined {
  try {
    return Number(value);
  } catch (err) {
    return undefined;
  }
}

export function applyPrecision(value: number, precision: number) {
  const k = Math.pow(10, precision);
  return Math.floor(value * k) / k;
}

export function convertToShortString(value: BigNumber.Value): string {
  let v = parseBigNumber(value).toNumber();
  let suffix = '';
  
  if (v > 1_000_000_000) {
    v = applyPrecision(v / 1_000_000_000, 3);
    suffix = 'B';
  } else if (v > 1_000_000) {
    v = applyPrecision(v / 1_000_000, 3);
    suffix = 'M';
  } else if (v > 1_000) {
    v = applyPrecision(v / 1_000, 3);
    suffix = 'K';
  } else {
    v = Math.floor(v);
  }
  
  return v.toString() + suffix;
}

export function convertToDollarString(value: BigNumber.Value): string {
  let bv = parseBigNumber(value);
  bv = bv.isNaN() ? new BigNumber(0) : bv;
  const nv = bv.toNumber();
  let v: string;
  if (nv >= 1) {
    v = bv.toFormat(2, BIG_NUMBER_ROUNDING_MODE) + '$';
  } else if (nv >= 0.00001) {
    v = bv.multipliedBy(100).toFormat(3, BIG_NUMBER_ROUNDING_MODE) + '¢';
  } else if (nv == 0) {
    v = '0$';
  } else {
    v = "< 0.001¢";
  }
  
  return v;
}

/// apply Math.floor on BigNumber
export function floorBN(value: BigNumber): BigNumber {
  return new BigNumber(value.toFixed(0));
}
