import React, { useEffect, useState } from 'react';
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import {
    useGetAccount,
    useGetPendingTransactions,
} from '@multiversx/sdk-dapp/hooks';
import { TokenType } from '@multiversx/sdk-dapp/types/tokens.types';
import { Loader } from '@multiversx/sdk-dapp/UI';
import Modal from 'react-modal';
import { FEE_DENOMINATOR } from 'config';
import {
    getTokenBalanceFromApi,
    getTokenFromApi,
    getTokenLogo,
} from 'z/elrond';
import {
    stakingClaimRewards,
    stakingStake,
    stakingUnstake,
    stakingViewBaseContext,
    stakingViewUserContext,
} from 'z/elrond/staking';
import {
    StakingBaseContext,
    StakingUserContext,
} from 'z/types';
import {
    formatNumber,
    convertEsdtToWei,
    convertWeiToEsdt,
    ERROR_CONNECT_WALLET,
    ERROR_INVALID_NUMBER,
    ERROR_NOT_ENOUGH_BALANCE,
    ERROR_SC_DATA_NOT_LOADED,
    ERROR_TRANSACTION_ONGOING,
    isPositiveOrZeroBigNumber,
    parseBigNumber,
    toastError,
    ZERO_STRING,
} from 'z/utils';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const AirbnbSlider = styled(Slider)(({ theme }) => ({
    color: '#F1DC46',
    height: 3,
    padding: '13px 0',
    '& .MuiSlider-thumb': {
        height: 20,
        width: 20,
        backgroundColor: '#1B0921',
        border: '1px solid currentColor',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: '0 0 0 8px rgba(249, 216, 94, 0.16)',
        },
        '& .airbnb-bar': {
            height: 9,
            width: 1,
            backgroundColor: 'currentColor',
            marginLeft: 1,
            marginRight: 1,
        },
    },
    '& .MuiSlider-track': {
        height: 6,
    },
    '& .MuiSlider-rail': {
        color: theme.palette.mode === 'dark' ? '#1B0921' : '#1B0921',
        // opacity: theme.palette.mode === 'dark' ? undefined : 1,
        opacity: 1,
        height: 6,
    },
    '& .MuiSlider-markLabel': {
        color: '#98A1C0',
    },
    '& .MuiSlider-markActive': {
        backgroundColor: '#F1DC46',
    },
}));

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AirbnbThumbComponentProps extends React.HTMLAttributes<unknown> {}

function AirbnbThumbComponent(props: AirbnbThumbComponentProps) {
    const { children, ...other } = props;
    return <SliderThumb {...other}>{children}</SliderThumb>;
}

const marks = [
    {
        value: 0,
        label: '0',
    },
    {
        value: 25,
        label: '25%',
    },
    {
        value: 50,
        label: '50%',
    },
    {
        value: 75,
        label: '75%',
    },
    {
        value: 100,
        label: '100%',
    },
];

export const StakingCard: React.FC<{
    scAddress: string,
}> = ({ 
    scAddress,
}) => {
    const { hasPendingTransactions } = useGetPendingTransactions();
    const { address } = useGetAccount();

    const [stakeModalIsOpen, setStakeModalIsOpen] = useState(false);
    const [unstakeModalIsOpen, setUnstakeModalIsOpen] = useState(false);
    const [stakeAmount, setStakeAmount] = useState<string>(ZERO_STRING);
    const [stakeAmountError, setStakeAmountError] = useState<string>('');
    const [unstakeAmount, setUnstakeAmount] = useState<string>(ZERO_STRING);
    const [unstakeAmountError, setUnstakeAmountError] = useState<string>('');

    const [stakingBaseContext, setStakingBaseContext] = useState<StakingBaseContext>();
    const [stakingUserContext, setStakingUserContext] = useState<StakingUserContext>();
    const [stakeToken, setStakeToken] = useState<TokenType>();
    const [stakeTokenBalance, setStakeTokenBalance] = useState<string>(ZERO_STRING);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    
    function openStakeModal() {
        if (!address) {
            toastError(ERROR_CONNECT_WALLET);
            return;
        }

        setStakeAmount(ZERO_STRING);
        setStakeModalIsOpen(true);
    }

    function closeStakeModal() {
        setStakeModalIsOpen(false);
    }

    function openUnstakeModal() {
        if (!address) {
            toastError(ERROR_CONNECT_WALLET);
            return;
        }

        setUnstakeAmount(ZERO_STRING);
        setUnstakeModalIsOpen(true);
    }

    function closeUnstakeModal() {
        setUnstakeModalIsOpen(false);
    }
    
    function onChangeStakeAmount(value: string) {
        let error = '';
        if (!address) {
            error = ERROR_CONNECT_WALLET;
        } else if (!isPositiveOrZeroBigNumber(value)) {
            error = ERROR_INVALID_NUMBER;
        } else if (!stakingBaseContext || !stakeToken || !stakeTokenBalance) {
            error = ERROR_SC_DATA_NOT_LOADED;
        } else if (
            convertEsdtToWei(value, stakeToken.decimals).comparedTo(stakeTokenBalance) > 0
        ) {
            error = ERROR_NOT_ENOUGH_BALANCE;
        }

        setStakeAmountError(error);
        setStakeAmount(value);
    }

    function onMaxStakeTokenAmount() {
        if (!stakeToken || !stakeTokenBalance) {
            return;
        }

        onChangeStakeAmount(
            convertWeiToEsdt(
                stakeTokenBalance,
                stakeToken.decimals,
                stakeToken.decimals
            ).toFixed()
        );
    }

    const onStakeSliderChange = (value: any) => {
        if (!stakeToken || !stakeTokenBalance) {
            return;
        }
        onChangeStakeAmount(
            convertWeiToEsdt(
                parseBigNumber(stakeTokenBalance).multipliedBy(value).div(100),
                stakeToken.decimals,
                stakeToken.decimals
            ).toFixed()
        );
    };

    function onChangeUnstakeAmount(value: string) {
        let error = '';
        if (!address) {
            error = ERROR_CONNECT_WALLET;
        } else if (!isPositiveOrZeroBigNumber(value)) {
            error = ERROR_INVALID_NUMBER;
        } else if (!stakingBaseContext || !stakeToken || !stakingUserContext) {
            error = ERROR_SC_DATA_NOT_LOADED;
        } else if (
            convertEsdtToWei(value, stakeToken.decimals).comparedTo(stakingUserContext.staked_amount) > 0
        ) {
            error = 'Not enough staked token';
        }

        setUnstakeAmountError(error);
        setUnstakeAmount(value);
    }

    function onMaxUnstakeTokenAmount() {
        if (!stakeToken || !stakingUserContext) {
            return;
        }

        onChangeUnstakeAmount(
            convertWeiToEsdt(
                stakingUserContext.staked_amount,
                stakeToken.decimals,
                stakeToken.decimals
            ).toFixed()
        );
    }

    const onUnstakeSliderChange = (value: any) => {
        if (!stakeToken || !stakingUserContext) {
            return;
        }
        onChangeUnstakeAmount(
            convertWeiToEsdt(
                parseBigNumber(stakingUserContext.staked_amount).multipliedBy(value).div(100),
                stakeToken.decimals,
                stakeToken.decimals
            ).toFixed()
        );
    };

    useEffect(() => {
        if (hasPendingTransactions) return;

        (async () => {
            setIsLoading(true);

            const _stakingBaseContext = await stakingViewBaseContext(scAddress);
            setStakingBaseContext(_stakingBaseContext);

            if (!stakeToken && _stakingBaseContext) {
                const _stakeToken = await getTokenFromApi(_stakingBaseContext.stake_token);
                setStakeToken(_stakeToken) ;
            }

            setIsLoading(false);
        })();
    }, [hasPendingTransactions]);

    useEffect(() => {
        if (hasPendingTransactions || !address) return;

        (async () => {
            const _stakingUserContext = await stakingViewUserContext(scAddress, address);
            setStakingUserContext(_stakingUserContext);
        })();
    }, [hasPendingTransactions, address]);

    useEffect(() => {
        if (hasPendingTransactions || !address || !stakeToken) return;

        (async () => {
            const _tokenBalanceInfo = await getTokenBalanceFromApi(address, stakeToken.identifier);
            setStakeTokenBalance(_tokenBalanceInfo? _tokenBalanceInfo.balance : ZERO_STRING);
        })();
    }, [hasPendingTransactions, address, stakeToken]);

    useEffect(() => {
        if (!hasPendingTransactions) {
            closeStakeModal();
            closeUnstakeModal();
        }
    }, [hasPendingTransactions, address]);

    async function onStake() {
        if (!address) {
            toastError(ERROR_CONNECT_WALLET);
            return;
        }
        if (!stakingBaseContext || !stakeToken || !stakeTokenBalance) {
            toastError(ERROR_SC_DATA_NOT_LOADED);
            return;
        }
        if (hasPendingTransactions) {
            toastError(ERROR_TRANSACTION_ONGOING);
            return;
        }

        await stakingStake(
            scAddress,
            stakeToken.identifier,
            convertEsdtToWei(stakeAmount, stakeToken.decimals).toFixed(0),
        );
    }

    async function onUnstake() {
        if (!address) {
            toastError(ERROR_CONNECT_WALLET);
            return;
        }
        if (!stakingBaseContext || !stakeToken || !stakingUserContext) {
            toastError(ERROR_SC_DATA_NOT_LOADED);
            return;
        }
        if (hasPendingTransactions) {
            toastError(ERROR_TRANSACTION_ONGOING);
            return;
        }

        await stakingUnstake(
            scAddress,
            convertEsdtToWei(unstakeAmount, stakeToken.decimals).toFixed(0),
        );
    }

    async function onClaim() {
        if (!address) {
            toastError(ERROR_CONNECT_WALLET);
            return;
        }
        if (!stakingBaseContext || !stakeToken || !stakeTokenBalance) {
            toastError(ERROR_SC_DATA_NOT_LOADED);
            return;
        }
        if (hasPendingTransactions) {
            toastError(ERROR_TRANSACTION_ONGOING);
            return;
        }

        await stakingClaimRewards(scAddress);
    }

    if (isLoading) {
        return <Loader />;
    }

    return (
        stakingBaseContext && stakeToken ? (
            <div className="active-pool-li-container">
                <div className='active-pool-li'>
                    <div className="d-flex align-items-center">
                        <img src={getTokenLogo(stakingBaseContext.stake_token)} alt="logo" width="50px" />
                        <div className="stake-info">
                            <span>Total Staked</span>
                            <span>{formatNumber(convertWeiToEsdt(stakingBaseContext.total_staked_amount, stakeToken.decimals))}</span>
                        </div>
                    </div>

                    <div className="active-pool-info" style={{ marginLeft: '10px' }}>
                        <span>APR</span>
                        <span>{stakingBaseContext.apr / FEE_DENOMINATOR} %</span>
                    </div>

                    <div className="active-pool-info" style={{ marginLeft: '10px' }}>
                        <span>My Staked {stakeToken.ticker}</span>
                        <span>{stakingUserContext ? formatNumber(convertWeiToEsdt(stakingUserContext.staked_amount, stakeToken.decimals)) + ' ' + stakeToken.ticker : '-'}</span>
                    </div>

                    <div className="active-pool-info" style={{ marginLeft: '10px' }}>
                        <span>My Earned {stakeToken.ticker}</span>
                        <span>{stakingUserContext ? formatNumber(convertWeiToEsdt(stakingUserContext.current_reward_amount, stakeToken.decimals)) + ' ' + stakeToken.ticker : '-'}</span>
                    </div>

                    <div className="d-flex gap-2 justify-content-center">
                        <button
                            className="vesta_x_but"
                            disabled={hasPendingTransactions}
                            onClick={openStakeModal}
                        >
                            Stake
                        </button>
                        <button
                            className="vesta_x_but"
                            disabled={hasPendingTransactions}
                            onClick={openUnstakeModal}
                        >
                            Unstake
                        </button>
                        <button
                            className="vesta_x_but"
                            disabled={hasPendingTransactions}
                            onClick={onClaim}
                        >
                            Claim
                        </button>
                    </div>
                </div>

                <Modal
                    isOpen={stakeModalIsOpen}
                    onRequestClose={closeStakeModal}
                    style={customStyles}
                    ariaHideApp={false}
                >
                    <div className="select-token-modal" style={{ margin: '10px' }}>
                        <div className="d-flex align-items-center mb-3">
                            <img src={getTokenLogo(stakingBaseContext.stake_token)} alt="logo" width="50px" />
                            <span
                                style={{
                                    color: '#F1DC46',
                                    marginLeft: '10px',
                                    fontSize: '1.2rem',
                                }}
                            >
                                Stake {stakeToken.ticker}
                            </span>
                        </div>

                        <div className="vesta_x_swap_input_box">
                            <div className="d-flex align-items-center">
                                <input
                                    className="swap_input"
                                    type="string"
                                    value={stakeAmount}
                                    onChange={(e) =>
                                        onChangeStakeAmount(e.target.value)
                                    }
                                />
                            </div>

                            <div className="d-flex justify-content-between mt-1">
                                <div className="input-token-error">
                                    {stakeAmountError}
                                </div>
                                <span className="add-liquidity-input-token-balance-box" onClick={onMaxStakeTokenAmount}>
                                    <div className="">Balance:&nbsp;&nbsp;</div>
                                    <div style={{ color: 'white' }}>
                                        {
                                            address && stakeToken && stakeTokenBalance
                                                ? formatNumber(
                                                    convertWeiToEsdt(
                                                        stakeTokenBalance,
                                                        stakeToken.decimals
                                                    )
                                                ) : ZERO_STRING
                                        }
                                    </div>
                                </span>
                            </div>
                        </div>

                        <div className="mt-2" style={{ padding: '10px 15px 10px 10px' }}>
                            <AirbnbSlider
                                components={{ Thumb: AirbnbThumbComponent }}
                                getAriaLabel={(index: number) =>
                                    index === 0 ? 'Minimum price' : 'Maximum price'
                                }
                                defaultValue={0}
                                marks={marks}
                                value={
                                    parseBigNumber(stakeTokenBalance).isZero() ? 0
                                    : convertEsdtToWei(stakeAmount, stakeToken.decimals).multipliedBy(100).div(stakeTokenBalance).toNumber()
                                }
                                onChange={(e: any, num: any) => onStakeSliderChange(num)}
                            />
                        </div>

                        <div
                            className="mt-4 vesta_x_but"
                            style={{ border: '1px solid rgba(255, 255, 255, 0.2)' }}
                            onClick={onStake}
                        >
                            Stake
                        </div>
                    </div>
                </Modal>

                <Modal
                    isOpen={unstakeModalIsOpen}
                    onRequestClose={closeUnstakeModal}
                    style={customStyles}
                    ariaHideApp={false}
                >
                    <div className="select-token-modal" style={{ margin: '10px' }}>
                        <div className="d-flex align-items-center mb-3">
                            <img src={getTokenLogo(stakingBaseContext.stake_token)} alt="logo" width="50px" />
                            <span
                                style={{
                                    color: '#F1DC46',
                                    marginLeft: '10px',
                                    fontSize: '1.2rem',
                                }}
                            >
                                Unstake {stakeToken.ticker}
                            </span>
                        </div>

                        <div className="vesta_x_swap_input_box">
                            <div className="d-flex align-items-center">
                                <input
                                    className="swap_input"
                                    type="string"
                                    value={unstakeAmount}
                                    onChange={(e) =>
                                        onChangeUnstakeAmount(e.target.value)
                                    }
                                />
                            </div>

                            <div className="d-flex justify-content-between mt-1">
                                <div className="input-token-error">
                                    {unstakeAmountError}
                                </div>
                                <span className="add-liquidity-input-token-balance-box" onClick={onMaxUnstakeTokenAmount}>
                                    <div className="">Staked:&nbsp;&nbsp;</div>
                                    <div style={{ color: 'white' }}>
                                        {
                                            address && stakeToken && stakingUserContext
                                                ? formatNumber(
                                                    convertWeiToEsdt(
                                                        stakingUserContext.staked_amount,
                                                        stakeToken.decimals
                                                    )
                                                ) : ZERO_STRING
                                        }
                                    </div>
                                </span>
                            </div>
                        </div>

                        <div className="mt-2" style={{ padding: '10px 15px 10px 10px' }}>
                            <AirbnbSlider
                                components={{ Thumb: AirbnbThumbComponent }}
                                getAriaLabel={(index: number) =>
                                    index === 0 ? 'Minimum price' : 'Maximum price'
                                }
                                defaultValue={0}
                                marks={marks}
                                value={
                                    !stakingUserContext || parseBigNumber(stakingUserContext.staked_amount).isZero() ? 0
                                    : convertEsdtToWei(unstakeAmount, stakeToken.decimals).multipliedBy(100).div(stakingUserContext.staked_amount).toNumber()
                                }
                                onChange={(e: any, num: any) => onUnstakeSliderChange(num)}
                            />
                        </div>

                        <div
                            className="mt-4 vesta_x_but"
                            style={{ border: '1px solid rgba(255, 255, 255, 0.2)' }}
                            onClick={onUnstake}
                        >
                            Unstake
                        </div>
                    </div>
                </Modal>
            </div>
        ) : (
            <div className="active-pool-li-container">
                <div className='active-pool-li'>
                    No data available...
                </div>
            </div>
        )
    );
};
