import React, { useEffect, useState } from 'react';
import { TokenTransfer } from '@multiversx/sdk-core/out';
import { useGetAccount, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import clsx from 'clsx';
import { Col, Row } from 'react-bootstrap';
import Countdown from 'react-countdown';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { InfoTooltip } from 'components';
import { CountdownCompleted, countdownRenderer } from 'components/CountdownComponents';
import { CustomSlider2 } from 'components/CustomSlider2';
import {
    SVST_MINT_LIMITS,
    TOKEN_INFO_MAP,
    VESTADAO_COLLECTION,
    VST_TOKEN_ID,
    CRYPTO_VESTA_VAULT_SC_ADDRESS,
    WETH_TOKEN_ID,
    WBTC_TOKEN_ID,
    BVST_TOKEN_ID,
} from 'config';
import { AddLiquidityInputToken } from 'pages/Pool/AddLiquidityInputToken';
import { NetstatsState, selectFarm, selectNetstats } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { routeNames } from 'routes';
import {
    getAccountNftsByCollection,
    getTokenDecimals,
    getTokenLogo,
    vaultClaimEliteReward,
    vaultClaimPremiumReward,
    vaultClaimReward,
    vaultStakeSft,
    vaultStakeToken,
    vaultUnstakeSft,
    vaultUnstakeToken,
    vaultViewBaseContext,
    vaultViewStatsContext,
    vaultViewUserContext,
} from 'z/elrond';
import { useVestaCommonHooks } from 'z/hooks';
import { VaultBaseContextType, VaultStatsContextType, VaultUserContextType, VestingTypeEnum } from 'z/types';
import {
    BIG_NUMBER_ZERO,
    convertBigNumberToInputString,
    formatNumber,
    convertEsdtToWei,
    convertSecondsToLocalDateTime,
    convertToDollarString,
    createTokenTicker,
    convertWeiToEsdt,
    DEFAULT_DECIMALS,
    ERROR_CONNECT_WALLET,
    ERROR_INVALID_NUMBER,
    ERROR_NOT_ENOUGH_BALANCE,
    ERROR_SC_DATA_NOT_LOADED,
    ERROR_TRANSACTION_ONGOING,
    isPositiveBigNumber,
    isPositiveOrZeroBigNumber,
    parseBigNumber,
    toastError,
    ZERO_STRING,
} from 'z/utils';
import { VestaMinter } from './Components/VestaMinter';

const SFT_TIER_COUNT = 3;

enum VestaVaultSelectedHolding {
    VaultVesta,
    VaultBVesta,
    VaultSVesta,
    WalletVesta,
    WalletSVesta,
    WalletBVesta,
}

function printPremiumRewardAndPrice(
    vaultUserContext: VaultUserContextType | undefined,
    index: number,
    getTokenPrice: any,
) {
    if (vaultUserContext && vaultUserContext.premium_reward_payments.length > index) {
        const tokenId = vaultUserContext.premium_reward_payments[index].token_identifier;
        const decimals = TOKEN_INFO_MAP[tokenId].decimals;
        const amount = convertWeiToEsdt(vaultUserContext.premium_reward_payments[index].amount, decimals, decimals);
        return `${formatNumber(amount, decimals)} (${convertToDollarString(
            amount.multipliedBy(getTokenPrice(tokenId)),
        )})`;
    }

    return '0 (0$)';
}

function printWalletBalanceAndPrice(netstatsRedux: NetstatsState, tokenId: string, getTokenPrice: any): string {
    const token = netstatsRedux.walletTokensMap[tokenId];
    const decimals = TOKEN_INFO_MAP[tokenId].decimals;
    const tokenBalance = token ? convertWeiToEsdt(token.balance, decimals, decimals) : BIG_NUMBER_ZERO;
    return `${formatNumber(tokenBalance, decimals)} (${convertToDollarString(
        tokenBalance.multipliedBy(getTokenPrice(tokenId)),
    )})`;
}

export const CryptoVestaVault = () => {
    const { address } = useGetAccount();
    const { hasPendingTransactions } = useGetPendingTransactions();
    const navigate = useNavigate();
    const { getTokenPrice } = useVestaCommonHooks();

    const netstatsRedux = useAppSelector(selectNetstats);
    const farmRedux = useAppSelector(selectFarm);

    const [stakedSftCounts, setStakedSftCounts] = useState<number[]>(Array(SFT_TIER_COUNT).fill(0));
    const [selectedSftTier, setSelectedSftTier] = useState<number>(0);
    const [sftBalances, setSftBalances] = useState<number[]>(Array(SFT_TIER_COUNT).fill(0));
    const [targetSftCount, setTargetSftCount] = useState<number>(0);

    const [walletVestaAmount, setWalletVestaAmount] = useState<string>(ZERO_STRING);
    const [walletBlessedVestaAmount, setWalletBlessedVestaAmount] = useState<string>(ZERO_STRING);
    const [selectedHolding, setSelectedHolding] = useState<VestaVaultSelectedHolding>(
        VestaVaultSelectedHolding.VaultVesta,
    );
    const [selectedTokenId, setSelectedTokenId] = useState<string>(VST_TOKEN_ID);

    const [mintOption, setMintOption] = useState<number>(0); // 0 for sVESTA, 1 for fVESTA
    const [mintPercent, setMintPercent] = useState<number>(0);
    const [vestaSleepingYears, setVestaSleepingYears] = useState<number>(1);

    const [vaultBaseContext, setVaultBaseContext] = useState<VaultBaseContextType>();
    const [vaultStatsContext, setVaultStatsContext] = useState<VaultStatsContextType>();
    const [vaultUserContext, setVaultUserContext] = useState<VaultUserContextType>();

    const [SftCountdown, setSftCountdown] = useState<any>();

    function onClickSftTier(index: number) {
        setSelectedSftTier(index);
    }

    useEffect(() => {
        setTargetSftCount(stakedSftCounts[selectedSftTier]);
    }, [selectedSftTier, sftBalances, stakedSftCounts]);

    function onChangeTargetSftCount(value: number) {
        if (value < 0) return;
        if (value > sftBalances[selectedSftTier] + stakedSftCounts[selectedSftTier]) return;

        setTargetSftCount(value);
    }

    //
    const [stakeAmount, setStakeAmount] = useState<string>(ZERO_STRING);
    const [stakeAmountError, setStakeAmountError] = useState<string>('');
    const [stakeSliderAmount, setStakeSliderAmount] = useState<number>(0);

    function onChangeStakeAmount(value: string) {
        let balance = BIG_NUMBER_ZERO;
        if (selectedHolding == VestaVaultSelectedHolding.VaultVesta) {
            if (vaultUserContext) {
                balance = parseBigNumber(vaultUserContext.vesta_staked_amount);
            }
        } else if (selectedHolding == VestaVaultSelectedHolding.VaultBVesta) {
            if (vaultUserContext) {
                balance = parseBigNumber(vaultUserContext.blessed_vesta_staked_amount);
            }
        } else if (selectedHolding == VestaVaultSelectedHolding.WalletVesta) {
            balance = parseBigNumber(walletVestaAmount);
        } else if (selectedHolding == VestaVaultSelectedHolding.WalletBVesta) {
            balance = parseBigNumber(walletBlessedVestaAmount);
        }

        let error = '';
        if (!isPositiveOrZeroBigNumber(value)) {
            error = ERROR_INVALID_NUMBER;
        } else if (address && convertEsdtToWei(value, DEFAULT_DECIMALS).comparedTo(balance) > 0) {
            error = ERROR_NOT_ENOUGH_BALANCE;
        }

        setStakeAmountError(error);
        setStakeAmount(value);

        if (balance.comparedTo(0) <= 0) {
            setStakeSliderAmount(0);
        } else {
            const _sliderAmount = Math.floor(
                convertEsdtToWei(value, DEFAULT_DECIMALS).div(balance).multipliedBy(100).toNumber(),
            );
            setStakeSliderAmount(Math.min(_sliderAmount, 100));
        }
    }

    function onMaxStakeAmount() {
        if (selectedHolding == VestaVaultSelectedHolding.VaultVesta) {
            const decimals = getTokenDecimals(VST_TOKEN_ID);
            setStakeAmount(
                vaultUserContext
                    ? convertBigNumberToInputString(
                          convertWeiToEsdt(vaultUserContext.vesta_staked_amount, decimals, decimals),
                          decimals,
                      )
                    : ZERO_STRING,
            );
        } else if (selectedHolding == VestaVaultSelectedHolding.VaultBVesta) {
            const decimals = getTokenDecimals(BVST_TOKEN_ID);
            setStakeAmount(
                vaultUserContext
                    ? convertBigNumberToInputString(
                          convertWeiToEsdt(vaultUserContext.blessed_vesta_staked_amount, decimals, decimals),
                          decimals,
                      )
                    : ZERO_STRING,
            );
        } else if (selectedHolding == VestaVaultSelectedHolding.WalletVesta) {
            const decimals = getTokenDecimals(VST_TOKEN_ID);
            setStakeAmount(
                convertBigNumberToInputString(convertWeiToEsdt(walletVestaAmount, decimals, decimals), decimals),
            );
        } else if (selectedHolding == VestaVaultSelectedHolding.WalletBVesta) {
            const decimals = getTokenDecimals(BVST_TOKEN_ID);
            setStakeAmount(
                convertBigNumberToInputString(convertWeiToEsdt(walletBlessedVestaAmount, decimals, decimals), decimals),
            );
        }
    }

    useEffect(() => {
        setStakeAmount(ZERO_STRING);
        setStakeAmountError('');
        setStakeSliderAmount(0);
    }, [selectedHolding]);

    useEffect(() => {
        (async () => {
            const _vaultBaseContext = await vaultViewBaseContext(CRYPTO_VESTA_VAULT_SC_ADDRESS);
            setVaultBaseContext(_vaultBaseContext);
        })();
    }, []);

    useEffect(() => {
        if (hasPendingTransactions) return;

        (async () => {
            const _vaultStatsContext = await vaultViewStatsContext(CRYPTO_VESTA_VAULT_SC_ADDRESS);
            setVaultStatsContext(_vaultStatsContext);
        })();
    }, [hasPendingTransactions]);

    useEffect(() => {
        setWalletVestaAmount(netstatsRedux.walletTokensMap[VST_TOKEN_ID]?.balance || ZERO_STRING);
        setWalletBlessedVestaAmount(netstatsRedux.walletTokensMap[BVST_TOKEN_ID]?.balance || ZERO_STRING);
    }, [netstatsRedux.walletTokensMap]);

    useEffect(() => {
        if (!address || hasPendingTransactions) return;

        (async () => {
            const _vaultUserContext = await vaultViewUserContext(CRYPTO_VESTA_VAULT_SC_ADDRESS, address);
            setVaultUserContext(_vaultUserContext);

            if (_vaultUserContext) {
                setStakedSftCounts(_vaultUserContext.sft_staked_amounts.map((v) => Number(v)));
            }
        })();

        (async () => {
            const _sfts = await getAccountNftsByCollection(address, VESTADAO_COLLECTION);
            let goldCount = 0;
            let silverCount = 0;
            let bronzeCount = 0;
            for (const sft of _sfts) {
                if (sft.nonce == 1) goldCount += Number(sft.balance);
                else if (sft.nonce == 2) silverCount += Number(sft.balance);
                else if (sft.nonce == 3) bronzeCount += Number(sft.balance);
            }
            setSftBalances([goldCount, silverCount, bronzeCount]);
        })();
    }, [address, hasPendingTransactions]);

    useEffect(() => {
        if (vaultBaseContext && vaultUserContext) {
            const unstakeTime = (vaultUserContext.last_claimed_timestamp + vaultBaseContext.sft_lock_period) * 1000;
            const CountdownWrapper =
                unstakeTime < Date.now()
                    ? () => <CountdownCompleted />
                    : () => <Countdown renderer={countdownRenderer} date={unstakeTime} autoStart />;
            const MemoCountdown = React.memo(CountdownWrapper);
            setSftCountdown(MemoCountdown);
        }
    }, [vaultBaseContext, vaultUserContext]);

    async function onSftStakeOrUnstake() {
        let error = '';
        if (!address) {
            error = ERROR_CONNECT_WALLET;
        } else if (hasPendingTransactions) {
            error = ERROR_TRANSACTION_ONGOING;
        } else if (!vaultBaseContext || !vaultUserContext) {
            error = ERROR_SC_DATA_NOT_LOADED;
        }
        if (error) {
            toastError(error);
            return;
        }
        if (!vaultBaseContext || !vaultUserContext) return;

        const nonce = (selectedSftTier % 3) + 1;
        if (targetSftCount > stakedSftCounts[selectedSftTier]) {
            // Stake SFT
            const payments: TokenTransfer[] = [
                TokenTransfer.metaEsdtFromBigInteger(
                    vaultBaseContext.sft_token_id,
                    nonce,
                    targetSftCount - stakedSftCounts[selectedSftTier],
                ),
            ];

            await vaultStakeSft(CRYPTO_VESTA_VAULT_SC_ADDRESS, payments, address);
        } else if (targetSftCount < stakedSftCounts[selectedSftTier]) {
            // Unstake SFT
            const unstakeTimestamp =
                (vaultUserContext.last_claimed_timestamp + vaultBaseContext.sft_lock_period) * 1000;
            if (Date.now() < unstakeTimestamp) {
                toastError(`You can unstake after ${convertSecondsToLocalDateTime(unstakeTimestamp)}.`);
                return;
            }

            const payments: TokenTransfer[] = [
                TokenTransfer.metaEsdtFromBigInteger(
                    vaultBaseContext.sft_token_id,
                    nonce,
                    stakedSftCounts[selectedSftTier] - targetSftCount,
                ),
            ];

            await vaultUnstakeSft(CRYPTO_VESTA_VAULT_SC_ADDRESS, payments);
        }
    }

    async function onMintVesta() {
        let error = '';
        if (!address) {
            error = ERROR_CONNECT_WALLET;
        } else if (hasPendingTransactions) {
            error = ERROR_TRANSACTION_ONGOING;
        } else if (mintOption === 1) {
            if (vestaSleepingYears > SVST_MINT_LIMITS[farmRedux.eliteAccountTier]) {
                error = `You can mint ${SVST_MINT_LIMITS[farmRedux.eliteAccountTier]} locked years sVST at most`;
            }
        }

        if (error) {
            toastError(error);
            return;
        }

        const vestingType =
            mintOption === 2
                ? VestingTypeEnum.FrozenVesta
                : mintOption === 1
                ? VestingTypeEnum.SleepingVesta
                : VestingTypeEnum.Vesta;
        const lockYears = mintOption === 1 ? vestaSleepingYears : 0;

        await vaultClaimReward(CRYPTO_VESTA_VAULT_SC_ADDRESS, vestingType, lockYears, mintPercent);
    }

    async function onStakeVesta() {
        let balance = BIG_NUMBER_ZERO,
            token_id = '';
        if (selectedHolding == VestaVaultSelectedHolding.WalletVesta) {
            balance = parseBigNumber(walletVestaAmount);
            token_id = VST_TOKEN_ID;
        } else if (selectedHolding == VestaVaultSelectedHolding.WalletBVesta) {
            balance = parseBigNumber(walletBlessedVestaAmount);
            token_id = BVST_TOKEN_ID;
        }

        let error = '';
        if (!address) {
            error = ERROR_CONNECT_WALLET;
        } else if (hasPendingTransactions) {
            error = ERROR_TRANSACTION_ONGOING;
        } else if (!isPositiveBigNumber(stakeAmount)) {
            error = ERROR_INVALID_NUMBER;
        } else if (convertEsdtToWei(stakeAmount, getTokenDecimals(token_id)).comparedTo(balance) > 0) {
            error = ERROR_NOT_ENOUGH_BALANCE;
        }

        if (error) {
            toastError(error);
            return;
        }

        const payments = [
            TokenTransfer.fungibleFromBigInteger(token_id, convertEsdtToWei(stakeAmount, getTokenDecimals(token_id))),
        ];
        await vaultStakeToken(CRYPTO_VESTA_VAULT_SC_ADDRESS, payments, address);
    }

    async function onUnstakeVesta() {
        let error = '',
            balance = BIG_NUMBER_ZERO,
            decimals = DEFAULT_DECIMALS,
            token_id = '';
        if (!address) {
            error = ERROR_CONNECT_WALLET;
        } else if (hasPendingTransactions) {
            error = ERROR_TRANSACTION_ONGOING;
        } else if (!isPositiveBigNumber(stakeAmount)) {
            error = ERROR_INVALID_NUMBER;
        } else if (!vaultBaseContext || !vaultUserContext) {
            error = ERROR_SC_DATA_NOT_LOADED;
        } else {
            if (selectedHolding == VestaVaultSelectedHolding.VaultVesta) {
                token_id = VST_TOKEN_ID;
                decimals = getTokenDecimals(VST_TOKEN_ID);
                balance = parseBigNumber(vaultUserContext.vesta_staked_amount);
            } else if (selectedHolding == VestaVaultSelectedHolding.VaultBVesta) {
                token_id = BVST_TOKEN_ID;
                decimals = getTokenDecimals(BVST_TOKEN_ID);
                balance = parseBigNumber(vaultUserContext.blessed_vesta_staked_amount);
            }
            if (convertEsdtToWei(stakeAmount, decimals).comparedTo(balance) > 0) {
                error = ERROR_NOT_ENOUGH_BALANCE;
            }
        }

        if (error) {
            toastError(error);
            return;
        }

        const payments = [TokenTransfer.fungibleFromBigInteger(token_id, convertEsdtToWei(stakeAmount, decimals))];
        await vaultUnstakeToken(CRYPTO_VESTA_VAULT_SC_ADDRESS, payments);
    }

    async function onClaimEliteReward() {
        let error = '';
        if (!address) {
            error = ERROR_CONNECT_WALLET;
        } else if (hasPendingTransactions) {
            error = ERROR_TRANSACTION_ONGOING;
        } else if (!vaultUserContext) {
            error = ERROR_SC_DATA_NOT_LOADED;
        } else if (
            vaultUserContext.elite_reward_payments.length == 0 ||
            parseBigNumber(vaultUserContext.elite_reward_payments[0].amount).isZero()
        ) {
            error = 'No reward';
        }
        if (error) {
            toastError(error);
            return;
        }

        await vaultClaimEliteReward(CRYPTO_VESTA_VAULT_SC_ADDRESS);
    }

    async function onClaimPremiumReward() {
        let error = '';
        if (!address) {
            error = ERROR_CONNECT_WALLET;
        } else if (hasPendingTransactions) {
            error = ERROR_TRANSACTION_ONGOING;
        } else if (!vaultUserContext) {
            error = ERROR_SC_DATA_NOT_LOADED;
        } else if (vaultUserContext.premium_reward_payments.length != 3) {
            let sum = BIG_NUMBER_ZERO;
            for (const payment of vaultUserContext.premium_reward_payments) {
                sum = sum.plus(payment.amount);
            }
            if (sum.isZero()) {
                error = 'No reward';
            }
        }
        if (error) {
            toastError(error);
            return;
        }

        await vaultClaimPremiumReward(CRYPTO_VESTA_VAULT_SC_ADDRESS);
    }

    function onTokenSelected(token_id: string, holding_type: VestaVaultSelectedHolding) {
        setSelectedTokenId(token_id);
        setSelectedHolding(holding_type);
    }

    const nativeTokenSelectorForVault = [
        {
            token_name: 'Vesta',
            token_id: VST_TOKEN_ID,
            holding_type: VestaVaultSelectedHolding.VaultVesta,
        },
        {
            token_name: 'Blessed Vesta',
            token_id: BVST_TOKEN_ID,
            holding_type: VestaVaultSelectedHolding.VaultBVesta,
        },
    ];

    const nativeTokenSelectorForWallet = [
        {
            token_name: 'Vesta',
            token_id: VST_TOKEN_ID,
            holding_type: VestaVaultSelectedHolding.WalletVesta,
        },
        {
            token_name: 'Blessed Vesta',
            token_id: BVST_TOKEN_ID,
            holding_type: VestaVaultSelectedHolding.WalletBVesta,
        },
    ];

    return (
        <>
            <div className="container" style={{ marginTop: '50px' }}>
                <div className="mt-4">
                    <div className="vault-container-card" style={{ minHeight: '40rem' }}>
                        <div className="d-flex justify-content-start">
                            <button
                                className="go-back-button"
                                onClick={() => {
                                    navigate(`${routeNames.vaults}`);
                                }}
                            >
                                <BiLeftArrowAlt />
                            </button>
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                            <span style={{ color: '#F1DC46', fontSize: '1.2rem' }}>Crypto Vesta.Vault™</span>
                        </div>
                        <Row className="mt-4 p-0">
                            <Col md={6} lg={4} className="d-flex flex-column">
                                <div className="second-card-style position-relative d-flex justify-content-center align-items-center h-100">
                                    <div style={{ fontSize: '1.875rem' }}>
                                        TVL{' '}
                                        {convertToDollarString(
                                            vaultStatsContext
                                                ? convertWeiToEsdt(
                                                      vaultStatsContext.total_standard_vesta_power,
                                                      DEFAULT_DECIMALS,
                                                      DEFAULT_DECIMALS,
                                                  ).multipliedBy(getTokenPrice(VST_TOKEN_ID))
                                                : 0,
                                        )}
                                    </div>
                                    <InfoTooltip title="Total Value Locked" />
                                </div>
                            </Col>
                            <Col md={6} lg={8} className="d-flex flex-column" style={{ fontSize: '1rem' }}>
                                <div className="second-card-style px-3">
                                    <div className="d-flex justify-content-between" style={{ color: '#a349a2' }}>
                                        <div>Standard Vesta Power:</div>
                                        <div>
                                            {formatNumber(
                                                vaultUserContext
                                                    ? convertWeiToEsdt(vaultUserContext.standard_vesta_power).toNumber()
                                                    : 0,
                                                3,
                                            )}{' '}
                                            |{' '}
                                            {formatNumber(
                                                vaultStatsContext
                                                    ? convertWeiToEsdt(
                                                          vaultStatsContext.total_standard_vesta_power,
                                                      ).toNumber()
                                                    : 0,
                                                3,
                                            )}{' '}
                                            (
                                            {formatNumber(
                                                vaultStatsContext &&
                                                    vaultUserContext &&
                                                    !parseBigNumber(
                                                        vaultStatsContext.total_standard_vesta_power,
                                                    ).isZero()
                                                    ? parseBigNumber(vaultUserContext.standard_vesta_power)
                                                          .div(vaultStatsContext.total_standard_vesta_power)
                                                          .multipliedBy(1_000)
                                                          .toNumber()
                                                    : 0,
                                                3,
                                            )}
                                            ‰)
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between" style={{ color: '#c8bfe7' }}>
                                        <div>Premium Vesta Power:</div>
                                        <div>
                                            {formatNumber(
                                                vaultUserContext
                                                    ? convertWeiToEsdt(vaultUserContext.premium_vesta_power).toNumber()
                                                    : 0,
                                                3,
                                            )}{' '}
                                            |{' '}
                                            {formatNumber(
                                                vaultStatsContext
                                                    ? convertWeiToEsdt(
                                                          vaultStatsContext.total_premium_vesta_power,
                                                      ).toNumber()
                                                    : 0,
                                                3,
                                            )}{' '}
                                            (
                                            {formatNumber(
                                                vaultStatsContext &&
                                                    vaultUserContext &&
                                                    !parseBigNumber(
                                                        vaultStatsContext.total_premium_vesta_power,
                                                    ).isZero()
                                                    ? parseBigNumber(vaultUserContext.premium_vesta_power)
                                                          .div(vaultStatsContext.total_premium_vesta_power)
                                                          .multipliedBy(1_000)
                                                          .toNumber()
                                                    : 0,
                                                3,
                                            )}
                                            ‰)
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between" style={{ color: '#efe3af' }}>
                                        <div>Elite Vesta Power:</div>
                                        <div>
                                            {formatNumber(
                                                vaultUserContext
                                                    ? convertWeiToEsdt(vaultUserContext.elite_vesta_power).toNumber()
                                                    : 0,
                                                3,
                                            )}{' '}
                                            |{' '}
                                            {formatNumber(
                                                vaultStatsContext
                                                    ? convertWeiToEsdt(
                                                          vaultStatsContext.total_elite_vesta_power,
                                                      ).toNumber()
                                                    : 0,
                                                3,
                                            )}{' '}
                                            (
                                            {formatNumber(
                                                vaultStatsContext &&
                                                    vaultUserContext &&
                                                    !parseBigNumber(vaultStatsContext.total_elite_vesta_power).isZero()
                                                    ? parseBigNumber(vaultUserContext.elite_vesta_power)
                                                          .div(vaultStatsContext.total_elite_vesta_power)
                                                          .multipliedBy(1_000)
                                                          .toNumber()
                                                    : 0,
                                                3,
                                            )}
                                            ‰)
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-2 p-0 gy-4" style={{ fontSize: '1.1rem', color: '#98A1C0' }}>
                            <Col md={12} lg={4} className="d-flex flex-column">
                                <div className="vault-sft-staking-container">
                                    {/* <Row style={{ rowGap: '6px' }}> */}
                                    <Col className="p-1" xs={12}>
                                        <div className="text-center" style={{ fontSize: '1.2rem', color: '#F1DC46' }}>
                                            Vesta SFT Staking
                                        </div>
                                    </Col>
                                    <Col className="p-1" xs={12}>
                                        <div className="d-flex gap-2">
                                            <div
                                                className={
                                                    'sft-tag d-flex justify-content-center align-items-center w-20'
                                                }
                                            >
                                                Gold
                                            </div>
                                            <div
                                                className={
                                                    'sft-tag d-flex justify-content-center align-items-center w-20'
                                                }
                                            >
                                                Silver
                                            </div>
                                            <div
                                                className={
                                                    'sft-tag d-flex justify-content-center align-items-center w-20'
                                                }
                                            >
                                                Bronze
                                            </div>
                                            <div
                                                className="farm_card_individual_element d-flex justify-content-center align-items-center position-relative"
                                                style={{ width: '40%' }}
                                            >
                                                {SftCountdown ? <SftCountdown /> : <CountdownCompleted />}

                                                <InfoTooltip
                                                    title="Time until SFTs can be unstaked. Cooldown only starts after a reward claim event. When 'Unlockable' is shown, SFTs can be unstaked."
                                                    type={2}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex gap-2 mt-2">
                                            <div
                                                className={clsx(
                                                    'sft-box d-flex justify-content-center align-items-center w-20 gold-color',
                                                    selectedSftTier == 0 && 'selected',
                                                )}
                                                onClick={() => onClickSftTier(0)}
                                            >
                                                {stakedSftCounts[0]}
                                            </div>
                                            <div
                                                className={clsx(
                                                    'sft-box d-flex justify-content-center align-items-center w-20 silver-color',
                                                    selectedSftTier == 1 && 'selected',
                                                )}
                                                onClick={() => onClickSftTier(1)}
                                            >
                                                {stakedSftCounts[1]}
                                            </div>
                                            <div
                                                className={clsx(
                                                    'sft-box d-flex justify-content-center align-items-center w-20 bronze-color',
                                                    selectedSftTier == 2 && 'selected',
                                                )}
                                                onClick={() => onClickSftTier(2)}
                                            >
                                                {stakedSftCounts[2]}
                                            </div>
                                            <div
                                                className="sft-tag d-flex justify-content-center align-items-center w-20 position-relative"
                                                style={{ color: '#00b050', fontWeight: '500' }}
                                            >
                                                IM
                                                <InfoTooltip
                                                    title="Individual Multiplier (Green Vesta Multiplier)"
                                                    type={2}
                                                />
                                            </div>
                                            <div
                                                className="sft-tag d-flex justify-content-center align-items-center w-20"
                                                style={{ color: '#00b050', fontWeight: '500' }}
                                            >
                                                {formatNumber(vaultUserContext ? vaultUserContext.im : 1)}x
                                            </div>
                                        </div>
                                    </Col>

                                    <Col className="p-1" xs={12}>
                                        <div className="d-flex gap-2">
                                            <div className="w-60 sft-staking-spin-container d-flex justify-content-between align-items-center">
                                                <button
                                                    className="spin-but"
                                                    onClick={() => onChangeTargetSftCount(targetSftCount - 1)}
                                                >
                                                    {' '}
                                                    -{' '}
                                                </button>
                                                <span>
                                                    {targetSftCount}/
                                                    {stakedSftCounts[selectedSftTier] + sftBalances[selectedSftTier]}
                                                </span>
                                                <button
                                                    className="spin-but"
                                                    onClick={() => onChangeTargetSftCount(targetSftCount + 1)}
                                                >
                                                    {' '}
                                                    +{' '}
                                                </button>
                                            </div>

                                            <div className="w-40">
                                                <button className="farm_but w-100 h-100" onClick={onSftStakeOrUnstake}>
                                                    {targetSftCount > stakedSftCounts[selectedSftTier]
                                                        ? 'Stake'
                                                        : targetSftCount < stakedSftCounts[selectedSftTier]
                                                        ? 'Unstake'
                                                        : '-'}
                                                </button>
                                            </div>
                                        </div>
                                    </Col>
                                    {/* </Row> */}
                                </div>
                            </Col>

                            <Col md={12} lg={4}>
                                <div className="second-card-style px-3">
                                    <div className="token-stats-title mb-3">Vault Holdings</div>

                                    <div className="d-flex justify-content-start">
                                        {nativeTokenSelectorForVault.map((row, id) => {
                                            return (
                                                <button
                                                    className={clsx({
                                                        'vault-token-selector': true,
                                                        selected: selectedHolding == row.holding_type,
                                                    })}
                                                    key={`vault-token-selector${id}`}
                                                    onClick={() => onTokenSelected(row.token_id, row.holding_type)}
                                                >
                                                    <img src={getTokenLogo(row.token_id)} width={'48px'} />
                                                </button>
                                            );
                                        })}
                                    </div>

                                    <div className="text-right">
                                        {vaultUserContext
                                            ? selectedHolding == VestaVaultSelectedHolding.VaultVesta
                                                ? formatNumber(
                                                      convertWeiToEsdt(
                                                          vaultUserContext.vesta_staked_amount,
                                                          getTokenDecimals(VST_TOKEN_ID),
                                                          getTokenDecimals(VST_TOKEN_ID),
                                                      ),
                                                      getTokenDecimals(VST_TOKEN_ID),
                                                  )
                                                : VestaVaultSelectedHolding.VaultBVesta
                                                ? formatNumber(
                                                      convertWeiToEsdt(
                                                          vaultUserContext.blessed_vesta_staked_amount,
                                                          getTokenDecimals(BVST_TOKEN_ID),
                                                          getTokenDecimals(BVST_TOKEN_ID),
                                                      ),
                                                      getTokenDecimals(BVST_TOKEN_ID),
                                                  )
                                                : '0'
                                            : '0'}
                                    </div>
                                </div>
                            </Col>

                            <Col md={12} lg={4}>
                                <div className="second-card-style px-3">
                                    <div className="token-stats-title mb-3">Wallet Holdings</div>

                                    <div className="d-flex justify-content-start">
                                        {nativeTokenSelectorForWallet.map((row, id) => {
                                            return (
                                                <button
                                                    className={clsx({
                                                        'vault-token-selector': true,
                                                        selected: selectedHolding == row.holding_type,
                                                    })}
                                                    key={`wallet-token-selector${id}`}
                                                    onClick={() => onTokenSelected(row.token_id, row.holding_type)}
                                                >
                                                    <img src={getTokenLogo(row.token_id)} width={'48px'} />
                                                </button>
                                            );
                                        })}
                                    </div>

                                    <div className="text-right">
                                        {selectedHolding == VestaVaultSelectedHolding.WalletVesta
                                            ? formatNumber(
                                                  convertWeiToEsdt(
                                                      walletVestaAmount,
                                                      getTokenDecimals(VST_TOKEN_ID),
                                                      getTokenDecimals(VST_TOKEN_ID),
                                                  ),
                                                  getTokenDecimals(VST_TOKEN_ID),
                                              )
                                            : VestaVaultSelectedHolding.WalletBVesta
                                            ? formatNumber(
                                                  convertWeiToEsdt(
                                                      walletBlessedVestaAmount,
                                                      getTokenDecimals(BVST_TOKEN_ID),
                                                      getTokenDecimals(BVST_TOKEN_ID),
                                                  ),
                                                  getTokenDecimals(BVST_TOKEN_ID),
                                              )
                                            : '0'}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        ;
                        {(selectedHolding == VestaVaultSelectedHolding.VaultVesta ||
                            selectedHolding == VestaVaultSelectedHolding.VaultBVesta) && (
                            <Row>
                                <div className="col-12 col-lg-3"></div>
                                <div
                                    className="col-12 col-lg-6 py-3"
                                    style={{
                                        border: '1px solid #ffffff0f',
                                        borderRadius: '10px',
                                        // backgroundColor: '#1B0921',
                                    }}
                                >
                                    <AddLiquidityInputToken
                                        tokenAmount={stakeAmount}
                                        onChangeTokenAmount={onChangeStakeAmount}
                                        tokenId={selectedTokenId}
                                        tokenTicker={createTokenTicker(selectedTokenId)}
                                        tokenBalance={
                                            vaultUserContext
                                                ? selectedHolding == VestaVaultSelectedHolding.VaultVesta
                                                    ? formatNumber(
                                                          convertWeiToEsdt(
                                                              vaultUserContext.vesta_staked_amount,
                                                              getTokenDecimals(VST_TOKEN_ID),
                                                              getTokenDecimals(VST_TOKEN_ID),
                                                          ),
                                                          getTokenDecimals(VST_TOKEN_ID),
                                                      )
                                                    : VestaVaultSelectedHolding.VaultBVesta
                                                    ? formatNumber(
                                                          convertWeiToEsdt(
                                                              vaultUserContext.blessed_vesta_staked_amount,
                                                              getTokenDecimals(BVST_TOKEN_ID),
                                                              getTokenDecimals(BVST_TOKEN_ID),
                                                          ),
                                                          getTokenDecimals(BVST_TOKEN_ID),
                                                      )
                                                    : '0'
                                                : '0'
                                        }
                                        onMaxTokenAmount={onMaxStakeAmount}
                                        error={stakeAmountError}
                                    />

                                    <div className="mt-2" style={{ padding: '10px 15px 10px 10px' }}>
                                        <CustomSlider2
                                            value={stakeSliderAmount}
                                            step={1}
                                            min={0}
                                            max={100}
                                            onChange={(v) => {
                                                setStakeSliderAmount(v);

                                                let balance = BIG_NUMBER_ZERO,
                                                    decimals = DEFAULT_DECIMALS;
                                                if (vaultUserContext) {
                                                    if (selectedHolding == VestaVaultSelectedHolding.VaultVesta) {
                                                        decimals = getTokenDecimals(VST_TOKEN_ID);
                                                        balance = convertWeiToEsdt(
                                                            vaultUserContext.vesta_staked_amount,
                                                            decimals,
                                                            decimals,
                                                        );
                                                    } else if (
                                                        selectedHolding == VestaVaultSelectedHolding.VaultBVesta
                                                    ) {
                                                        decimals = getTokenDecimals(BVST_TOKEN_ID);
                                                        balance = convertWeiToEsdt(
                                                            vaultUserContext.blessed_vesta_staked_amount,
                                                            decimals,
                                                            decimals,
                                                        );
                                                    }
                                                }
                                                setStakeAmount(
                                                    convertBigNumberToInputString(
                                                        balance.multipliedBy(v).div(100),
                                                        decimals,
                                                    ),
                                                );
                                            }}
                                        />
                                    </div>

                                    <div className="d-flex justify-content-center align-items-center">
                                        <button
                                            className="mt-3 assets-button"
                                            disabled={hasPendingTransactions}
                                            onClick={onUnstakeVesta}
                                        >
                                            Unstake
                                        </button>
                                    </div>
                                </div>
                            </Row>
                        )}
                        {(selectedHolding == VestaVaultSelectedHolding.WalletVesta ||
                            selectedHolding == VestaVaultSelectedHolding.WalletBVesta) && (
                            <Row>
                                <div className="col-12 col-lg-3"></div>
                                <div
                                    className="col-12 col-lg-6 py-3"
                                    style={{
                                        border: '1px solid #ffffff0f',
                                        borderRadius: '10px',
                                        // backgroundColor: '#1B0921',
                                    }}
                                >
                                    <AddLiquidityInputToken
                                        tokenAmount={stakeAmount}
                                        onChangeTokenAmount={onChangeStakeAmount}
                                        tokenId={selectedTokenId}
                                        tokenTicker={createTokenTicker(selectedTokenId)}
                                        tokenBalance={
                                            selectedHolding == VestaVaultSelectedHolding.WalletVesta
                                                ? formatNumber(
                                                      convertWeiToEsdt(
                                                          walletVestaAmount,
                                                          getTokenDecimals(VST_TOKEN_ID),
                                                          getTokenDecimals(VST_TOKEN_ID),
                                                      ),
                                                      getTokenDecimals(VST_TOKEN_ID),
                                                  )
                                                : VestaVaultSelectedHolding.WalletBVesta
                                                ? formatNumber(
                                                      convertWeiToEsdt(
                                                          walletBlessedVestaAmount,
                                                          getTokenDecimals(BVST_TOKEN_ID),
                                                          getTokenDecimals(BVST_TOKEN_ID),
                                                      ),
                                                      getTokenDecimals(BVST_TOKEN_ID),
                                                  )
                                                : '0'
                                        }
                                        onMaxTokenAmount={onMaxStakeAmount}
                                        error={stakeAmountError}
                                    />

                                    <div className="mt-2" style={{ padding: '10px 15px 10px 10px' }}>
                                        <CustomSlider2
                                            value={stakeSliderAmount}
                                            step={1}
                                            min={0}
                                            max={100}
                                            onChange={(v) => {
                                                setStakeSliderAmount(v);

                                                let balance = BIG_NUMBER_ZERO;
                                                let decimals = DEFAULT_DECIMALS;
                                                if (selectedHolding == VestaVaultSelectedHolding.WalletVesta) {
                                                    decimals = getTokenDecimals(VST_TOKEN_ID);
                                                    balance = convertWeiToEsdt(walletVestaAmount, decimals, decimals);
                                                } else if (selectedHolding == VestaVaultSelectedHolding.WalletBVesta) {
                                                    decimals = getTokenDecimals(BVST_TOKEN_ID);
                                                    balance = convertWeiToEsdt(
                                                        walletBlessedVestaAmount,
                                                        decimals,
                                                        decimals,
                                                    );
                                                }
                                                setStakeAmount(
                                                    convertBigNumberToInputString(
                                                        balance.multipliedBy(v).div(100),
                                                        decimals,
                                                    ),
                                                );
                                            }}
                                        />
                                    </div>

                                    <div className="d-flex justify-content-center align-items-center">
                                        <button
                                            className="mt-3 assets-button"
                                            disabled={hasPendingTransactions}
                                            onClick={onStakeVesta}
                                        >
                                            Stake
                                        </button>
                                    </div>
                                </div>
                            </Row>
                        )}
                        <VestaMinter
                            mintOption={mintOption}
                            setMintOption={setMintOption}
                            mintPercent={mintPercent}
                            setMintPercent={setMintPercent}
                            vestaSleepingYears={vestaSleepingYears}
                            setVestaSleepingYears={setVestaSleepingYears}
                            vaultStatsContext={vaultStatsContext}
                            vaultUserContext={vaultUserContext}
                            setVM={(vm: number) => vaultUserContext && setVaultUserContext({ ...vaultUserContext, vm })}
                            totalValueLockeded={convertToDollarString(
                                vaultStatsContext
                                    ? convertWeiToEsdt(
                                          vaultStatsContext.total_standard_vesta_power,
                                          DEFAULT_DECIMALS,
                                          DEFAULT_DECIMALS,
                                      ).multipliedBy(getTokenPrice(VST_TOKEN_ID))
                                    : 0,
                            )}
                            onMintVesta={onMintVesta}
                        />
                        <Row className="mt-4 p-0">
                            <Col>
                                <Row
                                    className="m-0 py-3 px-1"
                                    style={{
                                        border: '1px solid #c8bfe7',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <div className="col-12 text-center mb-3" style={{ color: '#c8bfe7' }}>
                                        DEX Fee Rewards are governed by Premium Vesta Power. Your share of all Premium
                                        Vesta Power is{' '}
                                        {vaultStatsContext &&
                                        vaultUserContext &&
                                        parseBigNumber(vaultStatsContext.total_premium_vesta_power).isGreaterThan(0)
                                            ? formatNumber(
                                                  parseBigNumber(vaultUserContext.premium_vesta_power)
                                                      .div(vaultStatsContext.total_premium_vesta_power)
                                                      .multipliedBy(1000),
                                                  3,
                                              )
                                            : '0.000'}
                                        ‰.
                                    </div>
                                    <Col md={6} lg={4} className="d-flex flex-column px-1">
                                        <div
                                            className="second-card-style mb-0 d-flex flex-column align-items-between gap-2 px-3"
                                            style={{ backgroundColor: '#1B0921' }}
                                        >
                                            <div className="d-flex align-items-center">
                                                <img
                                                    src={getTokenLogo(WETH_TOKEN_ID)}
                                                    style={{ height: '3rem', width: 'auto' }}
                                                />
                                                <div className="ms-2">
                                                    {printWalletBalanceAndPrice(
                                                        netstatsRedux,
                                                        WETH_TOKEN_ID,
                                                        getTokenPrice,
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6} lg={8} className="d-flex flex-column px-1" style={{ fontSize: '1rem' }}>
                                        <div
                                            className="second-card-style mb-0 d-flex justify-content-between align-items-center px-3"
                                            style={{ backgroundColor: '#1B0921' }}
                                        >
                                            <div className="d-flex flex-column align-items-between gap-2">
                                                <div className="d-flex align-items-center">
                                                    <img
                                                        src={getTokenLogo(WETH_TOKEN_ID)}
                                                        style={{ height: '3rem', width: 'auto' }}
                                                    />
                                                    <div className="ms-2">
                                                        {printPremiumRewardAndPrice(vaultUserContext, 0, getTokenPrice)}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <button
                                                    className="farm_but py-2"
                                                    style={{ width: '6rem' }}
                                                    disabled={hasPendingTransactions}
                                                    onClick={onClaimPremiumReward}
                                                >
                                                    Claim
                                                </button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="mt-4 p-0">
                            <Col>
                                <Row
                                    className="m-0 py-3 px-1"
                                    style={{
                                        border: '1px solid #efe3af',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <div className="col-12 text-center mb-3" style={{ color: '#efe3af' }}>
                                        wBTC Rewards are governed by Elite Vesta Power. Your share of all Elite Vesta
                                        Power is{' '}
                                        {vaultStatsContext &&
                                        vaultUserContext &&
                                        parseBigNumber(vaultStatsContext.total_elite_vesta_power).isGreaterThan(0)
                                            ? formatNumber(
                                                  parseBigNumber(vaultUserContext.elite_vesta_power)
                                                      .div(vaultStatsContext.total_elite_vesta_power)
                                                      .multipliedBy(1000),
                                                  3,
                                              )
                                            : '0.000'}
                                        ‰.
                                    </div>
                                    <Col md={6} lg={4} className="d-flex flex-column px-1">
                                        <div
                                            className="second-card-style mb-0 d-flex flex-column align-items-between gap-2 px-3"
                                            style={{ backgroundColor: '#1B0921' }}
                                        >
                                            <div className="d-flex align-items-center">
                                                <img
                                                    src={getTokenLogo(WBTC_TOKEN_ID)}
                                                    style={{ height: '3rem', width: 'auto' }}
                                                />
                                                <div className="ms-2">
                                                    {printWalletBalanceAndPrice(
                                                        netstatsRedux,
                                                        WBTC_TOKEN_ID,
                                                        getTokenPrice,
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6} lg={8} className="d-flex flex-column px-1" style={{ fontSize: '1rem' }}>
                                        <div
                                            className="second-card-style mb-0 d-flex justify-content-between align-items-center px-3 h-100"
                                            style={{ backgroundColor: '#1B0921' }}
                                        >
                                            <div className="d-flex flex-column align-items-between gap-2">
                                                <div className="d-flex align-items-center">
                                                    <img
                                                        src={getTokenLogo(WBTC_TOKEN_ID)}
                                                        style={{ height: '3rem', width: 'auto' }}
                                                    />
                                                    <div className="ms-2">
                                                        {vaultUserContext &&
                                                        vaultUserContext.elite_reward_payments.length > 0
                                                            ? formatNumber(
                                                                  convertWeiToEsdt(
                                                                      vaultUserContext.elite_reward_payments[0].amount,
                                                                      TOKEN_INFO_MAP[WBTC_TOKEN_ID].decimals,
                                                                      TOKEN_INFO_MAP[WBTC_TOKEN_ID].decimals,
                                                                  ),
                                                                  TOKEN_INFO_MAP[WBTC_TOKEN_ID].decimals,
                                                              )
                                                            : '0'}{' '}
                                                        (
                                                        {vaultUserContext &&
                                                        vaultUserContext.elite_reward_payments.length > 0
                                                            ? convertToDollarString(
                                                                  convertWeiToEsdt(
                                                                      vaultUserContext.elite_reward_payments[0].amount,
                                                                      TOKEN_INFO_MAP[WBTC_TOKEN_ID].decimals,
                                                                      TOKEN_INFO_MAP[WBTC_TOKEN_ID].decimals,
                                                                  ).multipliedBy(getTokenPrice(WBTC_TOKEN_ID)),
                                                              )
                                                            : '0$'}
                                                        )
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <button
                                                    className="farm_but py-2"
                                                    style={{ width: '6rem' }}
                                                    disabled={hasPendingTransactions}
                                                    onClick={onClaimEliteReward}
                                                >
                                                    Claim
                                                </button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    );
};
