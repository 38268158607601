import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { FarmRewardsType, NonFungibleTokenInfoMapType } from 'z/types';

export interface FarmState {
  nonFungibleTokenStats: NonFungibleTokenInfoMapType,
  farmRewards: FarmRewardsType[],
  eliteAccountTier: number,
  deb: number,
}

const initialState: FarmState = {
  nonFungibleTokenStats: {},
  farmRewards: [],
  eliteAccountTier: 0,
  deb: 1,
};

export const farmSlice = createSlice({
  name: 'farm',
  initialState,
  reducers: {
    setNonFungibleTokenStats: (state, action: PayloadAction<NonFungibleTokenInfoMapType>) => {
      state.nonFungibleTokenStats = action.payload;
    },
    setFarmRewards: (state, action: PayloadAction<FarmRewardsType[]>) => {
      state.farmRewards = action.payload;
    },
    setEliteAccountTier: (state, action: PayloadAction<number>) => {
      state.eliteAccountTier = action.payload;
    },
    setDeb: (state, action: PayloadAction<number>) => {
      state.deb = action.payload;
    },
  },
});

export const { setNonFungibleTokenStats, setFarmRewards, setEliteAccountTier, setDeb } = farmSlice.actions;
export const selectFarm = (state: RootState) => state.farm;

export const farmReducer = farmSlice.reducer;
