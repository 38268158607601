import {
  Address,
  BigUIntType,
  ContractFunction,
  EndpointDefinition,
  EndpointModifiers,
  EndpointParameterDefinition,
  Interaction,
  ResultsParser,
  SmartContract,
  TypedValue,
} from "@multiversx/sdk-core/out";
import { ApiNetworkProvider } from "@multiversx/sdk-network-providers/out";
import {
  MVX_API_URL,
  VEGLD_SC_ADDRESS,
} from "config";
import {
  ZERO_STRING,
} from "z/utils";

export async function getVegldInEgldPrice(): Promise<string> {
  try {
    const contractAddress = new Address(VEGLD_SC_ADDRESS);
    const contract = new SmartContract({ address: contractAddress });
    const func = new ContractFunction('getVegldPrice');
    const args: TypedValue[] = [];
    const query = new Interaction(contract, func, args).buildQuery();
    
    const mainnetNetworkProvider = new ApiNetworkProvider(MVX_API_URL);
    const queryResponse = await mainnetNetworkProvider.queryContract(query);
    const { firstValue, returnCode, returnMessage } =
      new ResultsParser().parseQueryResponse(
        queryResponse,
        new EndpointDefinition(
          'getVegldPrice',
          [],
          [
            new EndpointParameterDefinition(
              'output',
              '',
              new BigUIntType(),
            ),
          ],
          new EndpointModifiers('', []),
        ),
      );
    
    if (!firstValue || !returnCode.isSuccess()) {
      throw Error(returnMessage);
    }
    
    const value = firstValue.valueOf();
    const decoded = value.toFixed();
    
    return decoded;
  } catch (err) {
    console.error('getVegldInEgldPrice:', err);
    return ZERO_STRING;
  }
}
