import React, { useEffect, useState } from 'react';
import { useGetAccount, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import { BiRightArrowAlt } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
// import imgBVST from 'assets/img/token/BVST.svg';
import memeLiquidityLogo from 'assets/img/vault/MemeLiquidityVault.svg';
import ouroLiquidityLogo from 'assets/img/vault/OuroLiquidity_625x625.svg';
import { InfoTooltip } from 'components';
import {
    AUXILIARY_VESTA_VAULT_SC_ADDRESS,
    CRYPTO_VESTA_VAULT_SC_ADDRESS,
    GSC_TOKEN_ID,
    SVST_TOKEN_ID,
    TOKEN_INFO_MAP,
    CAPITAL_VESTA_VAULT_SC_ADDRESS,
    VST_TOKEN_ID,
    OURO_TOKEN_ID,
    USDC_TOKEN_ID,
    VEGLD_TOKEN_ID,
    IGNIS_TOKEN_ID,
    WBTC_TOKEN_ID,
    WETH_TOKEN_ID,
    SSC_TOKEN_ID,
    RAWVST_TOKEN_ID,
    AURYN_TOKEN_ID,
    EAURYN_TOKEN_ID,
    BVST_TOKEN_ID,
    STABLESWAP_LP_TOKEN,
} from 'config';
import { routeNames } from 'routes';
import {
    getTokenLogo,
    getTokenSupplyFromApi,
    liquidityVaultViewStatsContext,
    liquidityVaultViewUserContext,
    memeLiquidityVaultViewStatsContext,
    memeLiquidityVaultViewUserContext,
    snakeCoilViewBaseContext,
    vaultViewStatsContext,
    vaultViewUserContext,
    vegldVaultViewStatsContext,
    vegldVaultViewUserContext,
} from 'z/elrond';
import {
    OURO_SUPPLY_HARD_CAP,
    AUTOSTAKE_OURO_DISTRIBUTION_PERCENTAGE,
    getOuroFactoryCommonContext,
} from 'z/elrond/ouro-factory';
import {
    StableLiquidityVaultContract,
    StableLiquidityVaultStatsContext,
    StableLiquidityVaultUserContext,
} from 'z/elrond/stable-liquidity-vault';
import { useVestaCommonHooks } from 'z/hooks';
import {
    EsdtTokenPaymentType,
    MemeLiquidityVaultStatsContextType,
    MemeLiquidityVaultUserContextType,
    OuroLiquidityVaultStatsContextType,
    OuroLiquidityVaultUserContextType,
    SnakeCoilBaseContext,
    VaultStatsContextType,
    VaultUserContextType,
    VegldVaultStatsContextType,
    VegldVaultUserContextType,
} from 'z/types';
import { OuroFactoryContextType } from 'z/types/ouro-factory';
import { formatNumber, convertToDollarString, convertWeiToEsdt, parseBigNumber, DEFAULT_DECIMALS } from 'z/utils';

const VST_DAILY_YIELD_AMOUNT = 200;
const GSC_DAILY_YIELD_AMOUNT = 22.22222;
const SVST_MULTIPLIER = 18.488;

export function Vaults() {
    const { address } = useGetAccount();
    const { hasPendingTransactions } = useGetPendingTransactions();
    const navigate = useNavigate();
    const { getTokenPrice } = useVestaCommonHooks();

    const [snakeCoilBaseContext, setSnakeCoilBaseContext] = useState<SnakeCoilBaseContext>();
    const [ouroFactoryContext, setOuroFactoryContext] = useState<OuroFactoryContextType>();
    const [totalOuroSupply, setTotalOuroSupply] = useState<string>();
    const [totalAurynSupply, setTotalAurynSupply] = useState<string>();
    const [totalEliteAurynSupply, setTotalEliteAurynSupply] = useState<string>();

    const [vaultStatsContext, setVaultStatsContext] = useState<VaultStatsContextType>();
    const [vaultUserContext, setVaultUserContext] = useState<VaultUserContextType>();

    const [auxiliaryVaultStatsContext, setAuxiliaryVaultStatsContext] = useState<VaultStatsContextType>();
    const [auxiliaryVaultUserContext, setAuxiliaryVaultUserContext] = useState<VaultUserContextType>();

    const [cryptoVaultStatsContext, setCryptoVaultStatsContext] = useState<VaultStatsContextType>();
    const [cryptoVaultUserContext, setCryptoVaultUserContext] = useState<VaultUserContextType>();

    const [liquidityVaultStatsContext, setLiquidityVaultStatsContext] = useState<OuroLiquidityVaultStatsContextType>();
    const [ouroLiquidityVaultUserContext, setOuroLiquidityVaultUserContext] =
        useState<OuroLiquidityVaultUserContextType>();

    const [memeLiquidityVaultStatsContext, setMemeLiquidityVaultStatsContext] =
        useState<MemeLiquidityVaultStatsContextType>();
    const [memeLiquidityVaultUserContext, setMemeLiquidityVaultUserContext] =
        useState<MemeLiquidityVaultUserContextType>();

    const [vegldVaultStatsContext, setVegldVaultStatsContext] = useState<VegldVaultStatsContextType>();
    const [vegldVaultUserContext, setVegldVaultUserContext] = useState<VegldVaultUserContextType>();

    const [stableVaultStatsContext, setStableVaultStatsContext] = useState<StableLiquidityVaultStatsContext>();
    const [stableVaultUserContext, setStableVaultUserContext] = useState<StableLiquidityVaultUserContext>();

    useEffect(() => {
        if (hasPendingTransactions) return;

        (async () => {
            const _snakeCoilBaseContext = await snakeCoilViewBaseContext();
            setSnakeCoilBaseContext(_snakeCoilBaseContext);
        })();

        (async () => {
            const _ouroFactoryContext = await getOuroFactoryCommonContext();
            setOuroFactoryContext(_ouroFactoryContext);
        })();

        (async () => {
            const _ouroSupply = await getTokenSupplyFromApi(OURO_TOKEN_ID);
            const _aurynSupply = await getTokenSupplyFromApi(AURYN_TOKEN_ID);
            const _eliteAurynSupply = await getTokenSupplyFromApi(EAURYN_TOKEN_ID);

            setTotalOuroSupply(_ouroSupply?.supply ?? '0');
            setTotalAurynSupply(_aurynSupply?.supply ?? '0');
            setTotalEliteAurynSupply(_eliteAurynSupply?.supply ?? '0');
        })();
    }, [hasPendingTransactions]);

    useEffect(() => {
        if (hasPendingTransactions) return;

        (async () => {
            const _vaultStatsContext = await vaultViewStatsContext(CAPITAL_VESTA_VAULT_SC_ADDRESS);
            setVaultStatsContext(_vaultStatsContext);
        })();
        (async () => {
            const _vaultStatsContext = await vaultViewStatsContext(AUXILIARY_VESTA_VAULT_SC_ADDRESS);
            setAuxiliaryVaultStatsContext(_vaultStatsContext);
        })();
        (async () => {
            const _vaultStatsContext = await vaultViewStatsContext(CRYPTO_VESTA_VAULT_SC_ADDRESS);
            setCryptoVaultStatsContext(_vaultStatsContext);
        })();
        (async () => {
            const _vaultStatsContext = await liquidityVaultViewStatsContext();
            setLiquidityVaultStatsContext(_vaultStatsContext);
        })();
        (async () => {
            const _vaultStatsContext = await memeLiquidityVaultViewStatsContext();
            setMemeLiquidityVaultStatsContext(_vaultStatsContext);
        })();
        (async () => {
            const _vaultStatsContext = await vegldVaultViewStatsContext();
            setVegldVaultStatsContext(_vaultStatsContext);
        })();
        (async () => {
            const stableLiquidityVaultContract = new StableLiquidityVaultContract(address);
            const _vaultStatsContext = await stableLiquidityVaultContract.viewStatsContext();
            setStableVaultStatsContext(_vaultStatsContext);
        })();
    }, [hasPendingTransactions]);

    useEffect(() => {
        if (!address || hasPendingTransactions) return;

        (async () => {
            const _vaultUserContext = await vaultViewUserContext(CAPITAL_VESTA_VAULT_SC_ADDRESS, address);
            setVaultUserContext(_vaultUserContext);
        })();
        (async () => {
            const _vaultUserContext = await vaultViewUserContext(AUXILIARY_VESTA_VAULT_SC_ADDRESS, address);
            setAuxiliaryVaultUserContext(_vaultUserContext);
        })();
        (async () => {
            const _vaultUserContext = await vaultViewUserContext(CRYPTO_VESTA_VAULT_SC_ADDRESS, address);
            setCryptoVaultUserContext(_vaultUserContext);
        })();
        (async () => {
            const _vaultUserContext = await liquidityVaultViewUserContext(address);
            setOuroLiquidityVaultUserContext(_vaultUserContext);
        })();
        (async () => {
            const _vaultUserContext = await memeLiquidityVaultViewUserContext(address);
            setMemeLiquidityVaultUserContext(_vaultUserContext);
        })();
        (async () => {
            const _vaultUserContext = await vegldVaultViewUserContext(address);
            setVegldVaultUserContext(_vaultUserContext);
        })();
        (async () => {
            const stableLiquidityVaultContract = new StableLiquidityVaultContract(address);
            const _vaultUserContext = await stableLiquidityVaultContract.viewUserContext(address);
            setStableVaultUserContext(_vaultUserContext);
        })();
    }, [address, hasPendingTransactions]);

    const vstPrice = getTokenPrice(VST_TOKEN_ID);
    const gscPrice = getTokenPrice(GSC_TOKEN_ID);
    const vstDpr = vaultStatsContext
        ? (VST_DAILY_YIELD_AMOUNT * 1000) / convertWeiToEsdt(vaultStatsContext.total_standard_vesta_power).toNumber()
        : 0;
    const gscDpr = vaultStatsContext
        ? (GSC_DAILY_YIELD_AMOUNT * 1000 * gscPrice) /
          convertWeiToEsdt(vaultStatsContext.total_standard_vesta_power).multipliedBy(vstPrice).toNumber()
        : 0;

    const getRewardAmount = (rewards: EsdtTokenPaymentType[] | undefined, tokenId: string) => {
        return (rewards && rewards.find(({ token_identifier }) => token_identifier === tokenId)?.amount) || 0;
    };

    /////////////////////////////////////////
    const auxVstDpr =
        auxiliaryVaultStatsContext && !parseBigNumber(auxiliaryVaultStatsContext.total_standard_vesta_power).isZero()
            ? (VST_DAILY_YIELD_AMOUNT * 1000) /
              convertWeiToEsdt(auxiliaryVaultStatsContext.total_standard_vesta_power).toNumber()
            : 0;
    const auxSscDpr =
        auxiliaryVaultStatsContext && !parseBigNumber(auxiliaryVaultStatsContext.total_standard_vesta_power).isZero()
            ? (GSC_DAILY_YIELD_AMOUNT * 1000 * gscPrice) /
              convertWeiToEsdt(auxiliaryVaultStatsContext.total_standard_vesta_power).multipliedBy(vstPrice).toNumber()
            : 0;

    /////////////////////////////////////////
    const cryptoVstDpr =
        cryptoVaultStatsContext && !parseBigNumber(cryptoVaultStatsContext.total_standard_vesta_power).isZero()
            ? (VST_DAILY_YIELD_AMOUNT * 1000) /
              convertWeiToEsdt(cryptoVaultStatsContext.total_standard_vesta_power).toNumber()
            : 0;

    const autostakePoolDpr =
        ouroFactoryContext && snakeCoilBaseContext
            ? (1000 * ouroFactoryContext.today_emission_amount) /
              convertWeiToEsdt(parseBigNumber(snakeCoilBaseContext.token_supplies[0])).toNumber()
            : -1;

    /////////////////////////////////////////
    // const liquidityVstDpr =
    //     liquidityVaultStatsContext && !parseBigNumber(liquidityVaultStatsContext.total_standard_vesta_power).isZero()
    //         ? (VST_DAILY_YIELD_AMOUNT * 1000) /
    //           convertWeiToEsdt(liquidityVaultStatsContext.total_standard_vesta_power).toNumber()
    //         : 0;

    return (
        <>
            <div className="container" style={{ marginTop: '50px' }}>
                <div className="d-flex justify-content-center mt-4">
                    <div className="vesta-first-container">
                        <div className="d-flex justify-content-center align-items-center mt-4">
                            <span style={{ color: '#F1DC46', fontSize: '1.2rem' }}>Vaults</span>
                        </div>

                        <div className="vault-row-container mt-5">
                            <div className="vault-row">
                                <div className="row-part-w20 row-part-with-border vault-image-box p-2 mx-1">
                                    <img src={getTokenLogo(OURO_TOKEN_ID)} alt="logo" />
                                    <img src={getTokenLogo(AURYN_TOKEN_ID)} alt="logo" />
                                    <img
                                        src={getTokenLogo(AURYN_TOKEN_ID)}
                                        alt="logo"
                                        style={{ visibility: 'hidden' }}
                                    />
                                </div>

                                <div
                                    className="row-part-w20 row-part-with-border px-3 pt-3 pb-2 mx-1 d-flex flex-column justify-content-between position-relative"
                                    style={{ fontSize: '.9rem' }}
                                >
                                    <div className="text-center mb-1" style={{ fontSize: '1rem' }}>
                                        Autostake Pool
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Locked OURO:</div>
                                        <div>
                                            {formatNumber(
                                                snakeCoilBaseContext
                                                    ? convertWeiToEsdt(
                                                          snakeCoilBaseContext?.token_supplies[0],
                                                      ).toNumber()
                                                    : 0,
                                                4,
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Locked Auryn:</div>
                                        <div>
                                            {formatNumber(
                                                snakeCoilBaseContext
                                                    ? convertWeiToEsdt(
                                                          snakeCoilBaseContext?.token_supplies[2],
                                                      ).toNumber()
                                                    : 0,
                                                4,
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">TVL:</div>
                                        <div>
                                            {convertToDollarString(
                                                snakeCoilBaseContext
                                                    ? convertWeiToEsdt(
                                                          parseBigNumber(
                                                              snakeCoilBaseContext?.token_supplies[0],
                                                          ).multipliedBy(getTokenPrice(OURO_TOKEN_ID)),
                                                      )
                                                    : 0,
                                            )}
                                        </div>
                                    </div>

                                    <InfoTooltip title="Capital Vesta.VAULT™: Stake Vesta, Blessed-Vesta, Sleeping-Vesta and earn daily a share of 200 Raw/Condensed Vesta used for Refined Vesta Variants generation , 10% of DEX Fees for Vesta.VAULTS™ in OURO/vEGLD/USDC, and 35% of Seasonal GSC Rewards." />
                                </div>

                                <div
                                    className="row-part-w30 row-part-with-border px-3 pt-4 pb-2 mx-1 d-flex flex-column justify-content-between gap-1 position-relative"
                                    style={{ fontSize: '.9rem' }}
                                >
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Auryndex™:</div>
                                        <div>
                                            {snakeCoilBaseContext
                                                ? formatNumber(
                                                      parseBigNumber(snakeCoilBaseContext.token_supplies[0]).div(
                                                          snakeCoilBaseContext.token_supplies[1],
                                                      ),
                                                      9,
                                                  )
                                                : '???'}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Autostake DPR/APR:</div>
                                        <div>
                                            {formatNumber(autostakePoolDpr * AUTOSTAKE_OURO_DISTRIBUTION_PERCENTAGE, 3)}
                                            ‰ /{' '}
                                            {formatNumber(
                                                autostakePoolDpr * AUTOSTAKE_OURO_DISTRIBUTION_PERCENTAGE * 36.5,
                                                2,
                                            )}
                                            %
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Unbonding OURO:</div>
                                        <div>
                                            {snakeCoilBaseContext &&
                                                formatNumber(
                                                    convertWeiToEsdt(
                                                        parseBigNumber(
                                                            snakeCoilBaseContext.unbonding_token_supplies[0],
                                                        ),
                                                    ),
                                                    4,
                                                )}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Unbonding AURYN:</div>
                                        <div>
                                            {snakeCoilBaseContext &&
                                                formatNumber(
                                                    convertWeiToEsdt(
                                                        parseBigNumber(
                                                            snakeCoilBaseContext.unbonding_token_supplies[1],
                                                        ),
                                                    ),
                                                    4,
                                                )}
                                        </div>
                                    </div>

                                    <InfoTooltip title="APR = Annual Percentual Rate. DPR = Daily Promille Rate." />
                                </div>

                                <div
                                    className="row-part-w20 row-part-with-border px-3 pt-4 pb-2 mx-1 d-flex flex-column justify-content-between gap-1 position-relative"
                                    style={{ fontSize: '.9rem' }}
                                >
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">OURO Emission:</div>
                                        <div>
                                            {formatNumber(
                                                ouroFactoryContext
                                                    ? (parseFloat(totalOuroSupply ?? '0') / OURO_SUPPLY_HARD_CAP) * 100
                                                    : 0,
                                                2,
                                            )}
                                            %
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Total OURO:</div>
                                        <div>
                                            <div>{formatNumber(parseBigNumber(totalOuroSupply ?? '0'), 4)}</div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Total AURYN:</div>
                                        <div>
                                            <div>{formatNumber(parseBigNumber(totalAurynSupply ?? '0'), 4)}</div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Total ELITE-AURYN:</div>
                                        <div>{formatNumber(parseBigNumber(totalEliteAurynSupply ?? '0'), 4)}</div>
                                    </div>

                                    <InfoTooltip title="Current earnings are displayed here." />
                                </div>

                                <div className="d-flex justify-content-center align-items-center mx-2">
                                    <button
                                        className="pool-collapse-button"
                                        onClick={() => {
                                            navigate(routeNames.autostakePool);
                                        }}
                                    >
                                        <BiRightArrowAlt />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="vault-row-container mt-5">
                            <div className="vault-row">
                                <div className="row-part-w20 row-part-with-border vault-image-box p-2 mx-1">
                                    <img src={getTokenLogo(BVST_TOKEN_ID)} alt="logo" />
                                    <img src={getTokenLogo(VST_TOKEN_ID)} alt="logo" />
                                    <img src={getTokenLogo(SVST_TOKEN_ID)} alt="logo" />
                                </div>

                                <div
                                    className="row-part-w20 row-part-with-border px-3 pt-3 pb-2 mx-1 d-flex flex-column justify-content-between position-relative"
                                    style={{ fontSize: '.9rem' }}
                                >
                                    <div className="text-center mb-1" style={{ fontSize: '1rem' }}>
                                        Capital Vesta.Vault™
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Locked Vesta:</div>
                                        <div>
                                            {formatNumber(
                                                vaultStatsContext
                                                    ? convertWeiToEsdt(vaultStatsContext.vesta_reserve).toNumber()
                                                    : 0,
                                                3,
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Locked sVesta:</div>
                                        <div>
                                            {formatNumber(
                                                vaultStatsContext
                                                    ? convertWeiToEsdt(vaultStatsContext.svesta_reserve).toNumber()
                                                    : 0,
                                                3,
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">TVL:</div>
                                        <div>
                                            {convertToDollarString(
                                                vaultStatsContext
                                                    ? convertWeiToEsdt(
                                                          parseBigNumber(vaultStatsContext.vesta_reserve)
                                                              .plus(vaultStatsContext.svesta_reserve)
                                                              .multipliedBy(getTokenPrice(VST_TOKEN_ID)),
                                                      )
                                                    : 0,
                                            )}
                                        </div>
                                    </div>

                                    <InfoTooltip title="Capital Vesta.VAULT™: Stake Vesta, Blessed-Vesta, Sleeping-Vesta and earn daily a share of 200 Raw/Condensed Vesta used for Refined Vesta Variants generation , 10% of DEX Fees for Vesta.VAULTS™ in OURO/vEGLD/USDC, and 35% of Seasonal GSC Rewards." />
                                </div>

                                <div
                                    className="row-part-w30 row-part-with-border px-3 pt-4 pb-2 mx-1 d-flex flex-column justify-content-between gap-1 position-relative"
                                    style={{ fontSize: '.9rem' }}
                                >
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Vesta DPR/APR:</div>
                                        <div>
                                            {formatNumber(vstDpr)}‰ / {formatNumber(vstDpr * 36.5)}%
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">sVesta DPR/APR:</div>
                                        <div>
                                            {formatNumber(vstDpr * SVST_MULTIPLIER)}‰ /{' '}
                                            {formatNumber(vstDpr * 36.5 * SVST_MULTIPLIER)}%
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">DEX Fees DPR/APR:</div>
                                        <div>? ‰ / ? %</div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">GSC DPR/APR:</div>
                                        <div>
                                            {formatNumber(gscDpr)}‰ / {formatNumber(gscDpr * 36.5)}%
                                        </div>
                                    </div>

                                    <InfoTooltip title="APR = Annual Percentual Rate. DPR = Daily Promille Rate." />
                                </div>

                                <div
                                    className="row-part-w20 row-part-with-border px-3 pt-4 pb-2 mx-1 d-flex flex-column justify-content-between gap-1 position-relative"
                                    style={{ fontSize: '.9rem' }}
                                >
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Earned Primal Vesta:</div>
                                        <div>
                                            {formatNumber(
                                                vaultUserContext
                                                    ? convertWeiToEsdt(
                                                          vaultUserContext.reward_amount,
                                                          TOKEN_INFO_MAP[RAWVST_TOKEN_ID].decimals,
                                                          TOKEN_INFO_MAP[RAWVST_TOKEN_ID].decimals,
                                                      )
                                                    : 0,
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Earned vEGLD:</div>
                                        <div>
                                            {formatNumber(
                                                convertWeiToEsdt(
                                                    getRewardAmount(
                                                        auxiliaryVaultUserContext?.premium_reward_payments,
                                                        VEGLD_TOKEN_ID,
                                                    ),
                                                    TOKEN_INFO_MAP[VEGLD_TOKEN_ID].decimals,
                                                    TOKEN_INFO_MAP[VEGLD_TOKEN_ID].decimals,
                                                ),
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Earned USDC:</div>
                                        <div>
                                            {formatNumber(
                                                convertWeiToEsdt(
                                                    getRewardAmount(
                                                        auxiliaryVaultUserContext?.premium_reward_payments,
                                                        USDC_TOKEN_ID,
                                                    ),
                                                    TOKEN_INFO_MAP[USDC_TOKEN_ID].decimals,
                                                    TOKEN_INFO_MAP[USDC_TOKEN_ID].decimals,
                                                ),
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Earned GSC:</div>
                                        <div>
                                            {formatNumber(
                                                convertWeiToEsdt(
                                                    getRewardAmount(
                                                        vaultUserContext?.elite_reward_payments,
                                                        GSC_TOKEN_ID,
                                                    ),
                                                    TOKEN_INFO_MAP[GSC_TOKEN_ID].decimals,
                                                    TOKEN_INFO_MAP[GSC_TOKEN_ID].decimals,
                                                ),
                                            )}
                                        </div>
                                    </div>

                                    <InfoTooltip title="Current earnings are displayed here." />
                                </div>

                                <div className="d-flex justify-content-center align-items-center mx-2">
                                    <button
                                        className="pool-collapse-button"
                                        onClick={() => {
                                            navigate(routeNames.capitalVestaVault);
                                        }}
                                    >
                                        <BiRightArrowAlt />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="vault-row-container mt-5">
                            <div className="vault-row">
                                <div className="row-part-w20 row-part-with-border vault-image-box p-2 mx-1">
                                    <img src={getTokenLogo(BVST_TOKEN_ID)} alt="logo" />
                                    <img src={getTokenLogo(VST_TOKEN_ID)} alt="logo" />
                                    <img src={getTokenLogo(SVST_TOKEN_ID)} alt="logo" />
                                </div>

                                <div
                                    className="row-part-w20 row-part-with-border px-3 pt-3 pb-2 mx-1 d-flex flex-column justify-content-between position-relative"
                                    style={{ fontSize: '.9rem' }}
                                >
                                    <div className="text-center mb-1" style={{ fontSize: '1rem' }}>
                                        Auxiliary Vesta.Vault™
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Locked Vesta:</div>
                                        <div>
                                            {formatNumber(
                                                auxiliaryVaultStatsContext
                                                    ? convertWeiToEsdt(
                                                          auxiliaryVaultStatsContext.vesta_reserve,
                                                      ).toNumber()
                                                    : 0,
                                                3,
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Locked sVesta:</div>
                                        <div>
                                            {formatNumber(
                                                auxiliaryVaultStatsContext
                                                    ? convertWeiToEsdt(
                                                          auxiliaryVaultStatsContext.svesta_reserve,
                                                      ).toNumber()
                                                    : 0,
                                                3,
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">TVL:</div>
                                        <div>
                                            {convertToDollarString(
                                                auxiliaryVaultStatsContext
                                                    ? convertWeiToEsdt(
                                                          parseBigNumber(auxiliaryVaultStatsContext.vesta_reserve)
                                                              .plus(auxiliaryVaultStatsContext.svesta_reserve)
                                                              .multipliedBy(getTokenPrice(VST_TOKEN_ID)),
                                                      )
                                                    : 0,
                                            )}
                                        </div>
                                    </div>

                                    <InfoTooltip title="Auxiliary Vesta.VAULT™: Stake Vesta, Blessed-Vesta, Sleeping-Vesta and earn daily a share of 200 Raw/Condensed Vesta used for Refined Vesta Variants generation, 10% of DEX Fees for Vesta.VAULTS™ in OURO/vEGLD/USDC, and 35% of Seasonal SSC Rewards." />
                                </div>

                                <div
                                    className="row-part-w30 row-part-with-border px-3 pt-4 pb-2 mx-1 d-flex flex-column justify-content-between gap-1 position-relative"
                                    style={{ fontSize: '.9rem' }}
                                >
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Vesta DPR/APR:</div>
                                        <div>
                                            {formatNumber(auxVstDpr)}‰ / {formatNumber(auxVstDpr * 36.5)}%
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">sVesta DPR/APR:</div>
                                        <div>
                                            {formatNumber(auxVstDpr * SVST_MULTIPLIER)}‰ /{' '}
                                            {formatNumber(auxVstDpr * 36.5 * SVST_MULTIPLIER)}%
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">DEX Fees DPR/APR:</div>
                                        <div>? ‰ / ? %</div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">SSC DPR/APR:</div>
                                        <div>
                                            {formatNumber(auxSscDpr)}‰ / {formatNumber(auxSscDpr * 36.5)}%
                                        </div>
                                    </div>

                                    <InfoTooltip title="APR = Annual Percentual Rate. DPR = Daily Promille Rate." />
                                </div>

                                <div
                                    className="row-part-w20 row-part-with-border px-3 pt-4 pb-2 mx-1 d-flex flex-column justify-content-between gap-1 position-relative"
                                    style={{ fontSize: '.9rem' }}
                                >
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Earned Primal Vesta:</div>
                                        <div>
                                            {formatNumber(
                                                auxiliaryVaultUserContext
                                                    ? convertWeiToEsdt(
                                                          auxiliaryVaultUserContext.reward_amount,
                                                          TOKEN_INFO_MAP[RAWVST_TOKEN_ID].decimals,
                                                          TOKEN_INFO_MAP[RAWVST_TOKEN_ID].decimals,
                                                      )
                                                    : 0,
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Earned vEGLD:</div>
                                        <div>
                                            {formatNumber(
                                                convertWeiToEsdt(
                                                    getRewardAmount(
                                                        auxiliaryVaultUserContext?.premium_reward_payments,
                                                        VEGLD_TOKEN_ID,
                                                    ),
                                                    TOKEN_INFO_MAP[VEGLD_TOKEN_ID].decimals,
                                                    TOKEN_INFO_MAP[VEGLD_TOKEN_ID].decimals,
                                                ),
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Earned USDC:</div>
                                        <div>
                                            {formatNumber(
                                                convertWeiToEsdt(
                                                    getRewardAmount(
                                                        auxiliaryVaultUserContext?.premium_reward_payments,
                                                        USDC_TOKEN_ID,
                                                    ),
                                                    TOKEN_INFO_MAP[USDC_TOKEN_ID].decimals,
                                                    TOKEN_INFO_MAP[USDC_TOKEN_ID].decimals,
                                                ),
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Earned SSC:</div>
                                        <div>
                                            {formatNumber(
                                                convertWeiToEsdt(
                                                    getRewardAmount(
                                                        auxiliaryVaultUserContext?.elite_reward_payments,
                                                        SSC_TOKEN_ID,
                                                    ),
                                                    TOKEN_INFO_MAP[SSC_TOKEN_ID].decimals,
                                                    TOKEN_INFO_MAP[SSC_TOKEN_ID].decimals,
                                                ),
                                            )}
                                        </div>
                                    </div>

                                    <InfoTooltip title="Current earnings are displayed here." />
                                </div>

                                <div className="d-flex justify-content-center align-items-center mx-2">
                                    <button
                                        className="pool-collapse-button"
                                        onClick={() => {
                                            navigate(routeNames.auxVestaVault);
                                        }}
                                    >
                                        <BiRightArrowAlt />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="vault-row-container mt-5">
                            <div className="vault-row">
                                <div className="row-part-w20 row-part-with-border vault-image-box p-2 mx-1">
                                    <img src={getTokenLogo(BVST_TOKEN_ID)} alt="logo" />
                                    <img src={getTokenLogo(VST_TOKEN_ID)} alt="logo" />
                                    <img
                                        src={getTokenLogo(SVST_TOKEN_ID)}
                                        alt="logo"
                                        style={{ visibility: 'hidden' }}
                                    />
                                </div>

                                <div
                                    className="row-part-w20 row-part-with-border px-3 pt-3 pb-2 mx-1 d-flex flex-column justify-content-between position-relative"
                                    style={{ fontSize: '.9rem' }}
                                >
                                    <div className="text-center mb-1" style={{ fontSize: '1rem' }}>
                                        Crypto Vesta.Vault™
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Locked Vesta:</div>
                                        <div>
                                            {formatNumber(
                                                cryptoVaultStatsContext
                                                    ? convertWeiToEsdt(cryptoVaultStatsContext.vesta_reserve).toNumber()
                                                    : 0,
                                                3,
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">TVL:</div>
                                        <div>
                                            {convertToDollarString(
                                                cryptoVaultStatsContext
                                                    ? convertWeiToEsdt(
                                                          parseBigNumber(cryptoVaultStatsContext.vesta_reserve)
                                                              .plus(cryptoVaultStatsContext.svesta_reserve)
                                                              .multipliedBy(getTokenPrice(VST_TOKEN_ID)),
                                                      )
                                                    : 0,
                                            )}
                                        </div>
                                    </div>

                                    <InfoTooltip title="Crypto Vesta.VAULT™: Stake Vesta and Blessed-Vesta and earn daily a share of 200 Raw/Condensed Vesta used for Refined Vesta Variants generation, 75% of Seasonal wETH Rewards and 75% of Seasonal wBTC Rewards." />
                                </div>

                                <div
                                    className="row-part-w30 row-part-with-border px-3 pt-4 pb-2 mx-1 d-flex flex-column justify-content-between gap-1 position-relative"
                                    style={{ fontSize: '.9rem' }}
                                >
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Vesta DPR/APR:</div>
                                        <div>
                                            {formatNumber(cryptoVstDpr)}‰ / {formatNumber(cryptoVstDpr * 36.5)}%
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">DEX Fees DPR/APR:</div>
                                        <div>? ‰ / ? %</div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">wBTC DPR/APR:</div>
                                        {/* <div>{formatNumber(auxSscDpr)}‰ / {formatNumber(auxSscDpr * 36.5)}%</div> */}
                                        <div>? ‰ / ? %</div>
                                    </div>

                                    <InfoTooltip title="APR = Annual Percentual Rate. DPR = Daily Promille Rate." />
                                </div>

                                <div
                                    className="row-part-w20 row-part-with-border px-3 pt-4 pb-2 mx-1 d-flex flex-column justify-content-between gap-1 position-relative"
                                    style={{ fontSize: '.9rem' }}
                                >
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Earned Primal Vesta:</div>
                                        <div>
                                            {formatNumber(
                                                cryptoVaultUserContext
                                                    ? convertWeiToEsdt(
                                                          cryptoVaultUserContext.reward_amount,
                                                          TOKEN_INFO_MAP[RAWVST_TOKEN_ID].decimals,
                                                          TOKEN_INFO_MAP[RAWVST_TOKEN_ID].decimals,
                                                      )
                                                    : 0,
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Earned wETH:</div>
                                        <div>
                                            {formatNumber(
                                                convertWeiToEsdt(
                                                    getRewardAmount(
                                                        cryptoVaultUserContext?.premium_reward_payments,
                                                        WETH_TOKEN_ID,
                                                    ),
                                                    TOKEN_INFO_MAP[WETH_TOKEN_ID].decimals,
                                                    TOKEN_INFO_MAP[WETH_TOKEN_ID].decimals,
                                                ),
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Earned wBTC:</div>
                                        <div>
                                            {formatNumber(
                                                convertWeiToEsdt(
                                                    getRewardAmount(
                                                        cryptoVaultUserContext?.elite_reward_payments,
                                                        WBTC_TOKEN_ID,
                                                    ),
                                                    TOKEN_INFO_MAP[WBTC_TOKEN_ID].decimals,
                                                    TOKEN_INFO_MAP[WBTC_TOKEN_ID].decimals,
                                                ),
                                            )}
                                        </div>
                                    </div>

                                    <InfoTooltip title="Current earnings are displayed here." />
                                </div>

                                <div className="d-flex justify-content-center align-items-center mx-2">
                                    <button
                                        className="pool-collapse-button"
                                        onClick={() => {
                                            navigate(routeNames.cryptoVestaVault);
                                        }}
                                    >
                                        <BiRightArrowAlt />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="vault-row-container mt-5">
                            <div className="vault-row">
                                <div className="row-part-w20 row-part-with-border vault-image-box p-2 mx-1">
                                    <img src={ouroLiquidityLogo} alt="logo" />
                                </div>

                                <div
                                    className="row-part-w20 row-part-with-border px-3 pt-3 pb-2 mx-1 d-flex flex-column justify-content-between position-relative"
                                    style={{ fontSize: '.9rem' }}
                                >
                                    <div className="text-center mb-1" style={{ fontSize: '1rem' }}>
                                        OURO Liquidity.Vault™
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Locked OURO:</div>
                                        <div>
                                            {formatNumber(
                                                liquidityVaultStatsContext
                                                    ? convertWeiToEsdt(
                                                          liquidityVaultStatsContext.total_standard_vesta_power,
                                                      ).toNumber()
                                                    : 0,
                                                3,
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">TVL:</div>
                                        <div>
                                            {convertToDollarString(
                                                liquidityVaultStatsContext
                                                    ? convertWeiToEsdt(
                                                          parseBigNumber(
                                                              liquidityVaultStatsContext.total_standard_vesta_power,
                                                          )
                                                              .multipliedBy(2)
                                                              .multipliedBy(getTokenPrice(OURO_TOKEN_ID)),
                                                      )
                                                    : 0,
                                            )}
                                        </div>
                                    </div>

                                    <InfoTooltip title="Crypto Vesta.VAULT™: Stake Vesta and Blessed-Vesta and earn daily a share of 200 Raw/Condensed Vesta used for Refined Vesta Variants generation, 75% of Seasonal wETH Rewards and 75% of Seasonal wBTC Rewards." />
                                </div>

                                <div
                                    className="row-part-w30 row-part-with-border px-3 pt-4 pb-2 mx-1 d-flex flex-column justify-content-between gap-1 position-relative"
                                    style={{ fontSize: '.9rem' }}
                                >
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Vesta DPR/APR:</div>
                                        <div>
                                            ? ‰ / ? %
                                            {/* {formatNumber(liquidityVstDpr)}‰ / {formatNumber(liquidityVstDpr * 36.5)}% */}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">IGNIS DPR/APR:</div>
                                        <div>? ‰ / ? %</div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">OURO DPR/APR:</div>
                                        {/* <div>{formatNumber(auxSscDpr)}‰ / {formatNumber(auxSscDpr * 36.5)}%</div> */}
                                        <div>? ‰ / ? %</div>
                                    </div>

                                    <InfoTooltip title="APR = Annual Percentual Rate. DPR = Daily Promille Rate." />
                                </div>

                                <div
                                    className="row-part-w20 row-part-with-border px-3 pt-4 pb-2 mx-1 d-flex flex-column justify-content-between gap-1 position-relative"
                                    style={{ fontSize: '.9rem' }}
                                >
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Earned Primal Vesta:</div>
                                        <div>
                                            {formatNumber(
                                                ouroLiquidityVaultUserContext
                                                    ? convertWeiToEsdt(
                                                          ouroLiquidityVaultUserContext.reward_amount,
                                                          TOKEN_INFO_MAP[RAWVST_TOKEN_ID].decimals,
                                                          TOKEN_INFO_MAP[RAWVST_TOKEN_ID].decimals,
                                                      )
                                                    : 0,
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Earned IGNIS:</div>
                                        <div>
                                            {formatNumber(
                                                convertWeiToEsdt(
                                                    getRewardAmount(
                                                        ouroLiquidityVaultUserContext?.premium_reward_payments,
                                                        IGNIS_TOKEN_ID,
                                                    ),
                                                    TOKEN_INFO_MAP[IGNIS_TOKEN_ID].decimals,
                                                    TOKEN_INFO_MAP[IGNIS_TOKEN_ID].decimals,
                                                ),
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Earned OURO:</div>
                                        <div>
                                            {formatNumber(
                                                convertWeiToEsdt(
                                                    getRewardAmount(
                                                        ouroLiquidityVaultUserContext?.elite_reward_payments,
                                                        OURO_TOKEN_ID,
                                                    ),
                                                    TOKEN_INFO_MAP[OURO_TOKEN_ID].decimals,
                                                    TOKEN_INFO_MAP[OURO_TOKEN_ID].decimals,
                                                ),
                                            )}
                                        </div>
                                    </div>

                                    <InfoTooltip title="Current earnings are displayed here." />
                                </div>

                                <div className="d-flex justify-content-center align-items-center mx-2">
                                    <button
                                        className="pool-collapse-button"
                                        onClick={() => {
                                            navigate(routeNames.liquidityVestaVault);
                                        }}
                                    >
                                        <BiRightArrowAlt />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="vault-row-container mt-5">
                            <div className="vault-row">
                                <div className="row-part-w20 row-part-with-border vault-image-box p-2 mx-1">
                                    <img src={memeLiquidityLogo} alt="logo" />
                                </div>

                                <div
                                    className="row-part-w20 row-part-with-border px-3 pt-3 pb-2 mx-1 d-flex flex-column justify-content-between position-relative"
                                    style={{ fontSize: '.9rem' }}
                                >
                                    <div className="text-center mb-1" style={{ fontSize: '1rem' }}>
                                        MEME Liquidity.Vault™
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Locked $:</div>
                                        <div>
                                            {formatNumber(
                                                memeLiquidityVaultStatsContext
                                                    ? convertWeiToEsdt(
                                                          memeLiquidityVaultStatsContext.total_standard_vesta_power,
                                                          TOKEN_INFO_MAP[USDC_TOKEN_ID].decimals,
                                                      ).toNumber()
                                                    : 0,
                                                3,
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">TVL:</div>
                                        <div>
                                            {convertToDollarString(
                                                memeLiquidityVaultStatsContext
                                                    ? convertWeiToEsdt(
                                                          parseBigNumber(
                                                              memeLiquidityVaultStatsContext.total_standard_vesta_power,
                                                          ).multipliedBy(2),
                                                          TOKEN_INFO_MAP[USDC_TOKEN_ID].decimals,
                                                      )
                                                    : 0,
                                            )}
                                        </div>
                                    </div>

                                    <InfoTooltip title="Crypto Vesta.VAULT™: Stake Vesta and Blessed-Vesta and earn daily a share of 200 Raw/Condensed Vesta used for Refined Vesta Variants generation, 75% of Seasonal wETH Rewards and 75% of Seasonal wBTC Rewards." />
                                </div>

                                <div
                                    className="row-part-w30 row-part-with-border px-3 pt-4 pb-2 mx-1 d-flex flex-column justify-content-between gap-1 position-relative"
                                    style={{ fontSize: '.9rem' }}
                                >
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Vesta DPR/APR:</div>
                                        <div>
                                            ? ‰ / ? %
                                            {/* {formatNumber(liquidityVstDpr)}‰ / {formatNumber(liquidityVstDpr * 36.5)}% */}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">USDC DPR/APR:</div>
                                        <div>? ‰ / ? %</div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">VEGLD DPR/APR:</div>
                                        {/* <div>{formatNumber(auxSscDpr)}‰ / {formatNumber(auxSscDpr * 36.5)}%</div> */}
                                        <div>? ‰ / ? %</div>
                                    </div>

                                    <InfoTooltip title="APR = Annual Percentual Rate. DPR = Daily Promille Rate." />
                                </div>

                                <div
                                    className="row-part-w20 row-part-with-border px-3 pt-4 pb-2 mx-1 d-flex flex-column justify-content-between gap-1 position-relative"
                                    style={{ fontSize: '.9rem' }}
                                >
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Earned Primal Vesta:</div>
                                        <div>
                                            {formatNumber(
                                                memeLiquidityVaultUserContext
                                                    ? convertWeiToEsdt(
                                                          memeLiquidityVaultUserContext.reward_amount,
                                                          TOKEN_INFO_MAP[RAWVST_TOKEN_ID].decimals,
                                                          TOKEN_INFO_MAP[RAWVST_TOKEN_ID].decimals,
                                                      )
                                                    : 0,
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Earned USDC:</div>
                                        <div>
                                            {formatNumber(
                                                convertWeiToEsdt(
                                                    getRewardAmount(
                                                        memeLiquidityVaultUserContext?.auxiliary_standard_reward_payments,
                                                        USDC_TOKEN_ID,
                                                    ),
                                                    TOKEN_INFO_MAP[USDC_TOKEN_ID].decimals,
                                                    TOKEN_INFO_MAP[USDC_TOKEN_ID].decimals,
                                                ),
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Earned VEGLD:</div>
                                        <div>
                                            {formatNumber(
                                                convertWeiToEsdt(
                                                    getRewardAmount(
                                                        memeLiquidityVaultUserContext?.auxiliary_standard_reward_payments,
                                                        VEGLD_TOKEN_ID,
                                                    ),
                                                    TOKEN_INFO_MAP[VEGLD_TOKEN_ID].decimals,
                                                    TOKEN_INFO_MAP[VEGLD_TOKEN_ID].decimals,
                                                ),
                                            )}
                                        </div>
                                    </div>

                                    <InfoTooltip title="Current earnings are displayed here." />
                                </div>

                                <div className="d-flex justify-content-center align-items-center mx-2">
                                    <button
                                        className="pool-collapse-button"
                                        onClick={() => {
                                            navigate(routeNames.memeLiquidityVestaVault);
                                        }}
                                    >
                                        <BiRightArrowAlt />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="vault-row-container mt-5">
                            <div className="vault-row">
                                <div className="row-part-w20 row-part-with-border vault-image-box p-2 mx-1">
                                    <img src={getTokenLogo(VEGLD_TOKEN_ID)} alt="logo" />
                                </div>

                                <div
                                    className="row-part-w20 row-part-with-border px-3 pt-3 pb-2 mx-1 d-flex flex-column justify-content-between position-relative"
                                    style={{ fontSize: '.9rem' }}
                                >
                                    <div className="text-center mb-1" style={{ fontSize: '1rem' }}>
                                        vEGLD.Vault™
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Locked :</div>
                                        <div>
                                            {formatNumber(
                                                vegldVaultStatsContext
                                                    ? convertWeiToEsdt(
                                                          vegldVaultStatsContext.vegld_reserve,
                                                          DEFAULT_DECIMALS,
                                                      ).toNumber()
                                                    : 0,
                                                3,
                                            )}{' '}
                                            vEGLD
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">TVL:</div>
                                        <div>
                                            {convertToDollarString(
                                                vegldVaultStatsContext
                                                    ? convertWeiToEsdt(
                                                          vegldVaultStatsContext.vegld_reserve,
                                                          DEFAULT_DECIMALS,
                                                      ).multipliedBy(getTokenPrice(VEGLD_TOKEN_ID))
                                                    : 0,
                                            )}
                                        </div>
                                    </div>

                                    <InfoTooltip title="Crypto Vesta.VAULT™: Stake Vesta and Blessed-Vesta and earn daily a share of 200 Raw/Condensed Vesta used for Refined Vesta Variants generation, 75% of Seasonal wETH Rewards and 75% of Seasonal wBTC Rewards." />
                                </div>

                                <div
                                    className="row-part-w30 row-part-with-border px-3 pt-4 pb-2 mx-1 d-flex flex-column justify-content-between gap-1 position-relative"
                                    style={{ fontSize: '.9rem' }}
                                >
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Vesta DPR/APR:</div>
                                        <div>
                                            ? ‰ / ? %
                                            {/* {formatNumber(liquidityVstDpr)}‰ / {formatNumber(liquidityVstDpr * 36.5)}% */}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">AURYN DPR/APR:</div>
                                        <div>? ‰ / ? %</div>
                                    </div>
                                    <div className="d-flex justify-content-between" style={{ visibility: 'hidden' }}>
                                        <div className="text-secondary-color">VEGLD DPR/APR:</div>
                                        <div>? ‰ / ? %</div>
                                    </div>

                                    <InfoTooltip title="APR = Annual Percentual Rate. DPR = Daily Promille Rate." />
                                </div>

                                <div
                                    className="row-part-w20 row-part-with-border px-3 pt-4 pb-2 mx-1 d-flex flex-column justify-content-between gap-1 position-relative"
                                    style={{ fontSize: '.9rem' }}
                                >
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Earned Primal Vesta:</div>
                                        <div>
                                            {formatNumber(
                                                vegldVaultUserContext
                                                    ? convertWeiToEsdt(
                                                          vegldVaultUserContext.reward_amount,
                                                          DEFAULT_DECIMALS,
                                                          DEFAULT_DECIMALS,
                                                      )
                                                    : 0,
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Earned AURYN:</div>
                                        <div>
                                            {formatNumber(
                                                vegldVaultUserContext
                                                    ? convertWeiToEsdt(
                                                          vegldVaultUserContext.major_reward_amount,
                                                          DEFAULT_DECIMALS,
                                                          DEFAULT_DECIMALS,
                                                      )
                                                    : 0,
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between" style={{ visibility: 'hidden' }}>
                                        <div className="text-secondary-color">Earned VEGLD:</div>
                                        <div>0.000</div>
                                    </div>

                                    <InfoTooltip title="Current earnings are displayed here." />
                                </div>

                                <div className="d-flex justify-content-center align-items-center mx-2">
                                    <button
                                        className="pool-collapse-button"
                                        onClick={() => {
                                            navigate(routeNames.vegldVault);
                                        }}
                                    >
                                        <BiRightArrowAlt />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="vault-row-container mt-5">
                            <div className="vault-row">
                                <div className="row-part-w20 row-part-with-border vault-image-box p-2 mx-1">
                                    <img src={getTokenLogo(STABLESWAP_LP_TOKEN)} alt="logo" />
                                </div>

                                <div
                                    className="row-part-w20 row-part-with-border px-3 pt-3 pb-2 mx-1 d-flex flex-column position-relative"
                                    style={{ fontSize: '.9rem' }}
                                >
                                    <div className="text-center mb-1" style={{ fontSize: '1rem' }}>
                                        Stable-Liquidity Vault™
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Locked:</div>
                                        <div>
                                            {formatNumber(
                                                stableVaultStatsContext
                                                    ? convertWeiToEsdt(
                                                        stableVaultStatsContext.total_minor_power,
                                                        DEFAULT_DECIMALS,
                                                    ).toNumber()
                                                    : 0,
                                                3,
                                            )}{' '}
                                            USDD
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">TVL:</div>
                                        <div>
                                            {convertToDollarString(
                                                stableVaultStatsContext
                                                    ? convertWeiToEsdt(
                                                        stableVaultStatsContext.total_minor_power,
                                                        DEFAULT_DECIMALS,
                                                    ).multipliedBy(3)
                                                    : 0,
                                            )}
                                        </div>
                                    </div>

                                    <InfoTooltip title="Stable-Liquidity Vault™: Stake Stable-Liquidity LPs and earn a share of 500 Primal Vesta Rewards, 10% of OURO Pie Distribution as OURO and AURYN daily; and Stable-Liquidity Rewards with each Swap Tx in the StableSwap Stable-Liquidity Pool" />
                                </div>

                                <div
                                    className="row-part-w30 row-part-with-border px-3 pt-4 pb-2 mx-1 d-flex flex-column gap-1 position-relative"
                                    style={{ fontSize: '.9rem' }}
                                >
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Vesta DPR/APR:</div>
                                        <div>
                                            ? ‰ / ? %
                                            {/* {formatNumber(liquidityVstDpr)}‰ / {formatNumber(liquidityVstDpr * 36.5)}% */}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">AURYN DPR/APR:</div>
                                        <div>? ‰ / ? %</div>
                                    </div>
                                    <div
                                        className="d-flex justify-content-between"
                                        style={{ visibility: 'hidden' }}
                                    >
                                        <div className="text-secondary-color">VEGLD DPR/APR:</div>
                                        <div>? ‰ / ? %</div>
                                    </div>

                                    <InfoTooltip title="APR = Annual Percentual Rate. DPR = Daily Promille Rate." />
                                </div>

                                <div
                                    className="row-part-w20 row-part-with-border px-3 pt-4 pb-2 mx-1 d-flex flex-column  gap-1 position-relative"
                                    style={{ fontSize: '.9rem' }}
                                >
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Earned Primal Vesta:</div>
                                        <div>
                                            {formatNumber(
                                                stableVaultUserContext
                                                    ? convertWeiToEsdt(
                                                        stableVaultUserContext.reward_amount,
                                                        DEFAULT_DECIMALS,
                                                        DEFAULT_DECIMALS,
                                                    ) : 0,
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Earned USDC:</div>
                                        <div>
                                            {formatNumber(
                                                stableVaultUserContext
                                                    ? convertWeiToEsdt(
                                                        stableVaultUserContext.premium_reward_payments[0].amount,
                                                        6,
                                                        6,
                                                    )
                                                    : 0,
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Earned USDT:</div>
                                        <div>
                                            {formatNumber(
                                                stableVaultUserContext
                                                    ? convertWeiToEsdt(
                                                        stableVaultUserContext.premium_reward_payments[1].amount,
                                                        6,
                                                        6,
                                                    )
                                                    : 0,
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Earned USDD:</div>
                                        <div>
                                            {formatNumber(
                                                stableVaultUserContext
                                                    ? convertWeiToEsdt(
                                                        stableVaultUserContext.premium_reward_payments[2].amount,
                                                        DEFAULT_DECIMALS,
                                                        DEFAULT_DECIMALS,
                                                    )
                                                    : 0,
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Earned OURO:</div>
                                        <div>
                                            {formatNumber(
                                                stableVaultUserContext
                                                    ? convertWeiToEsdt(
                                                        stableVaultUserContext.elite_reward_payments[0].amount,
                                                        DEFAULT_DECIMALS,
                                                        DEFAULT_DECIMALS,
                                                    )
                                                    : 0,
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="text-secondary-color">Earned AURYN:</div>
                                        <div>
                                            {formatNumber(
                                                stableVaultUserContext
                                                    ? convertWeiToEsdt(
                                                        stableVaultUserContext.elite_reward_payments[1].amount,
                                                        DEFAULT_DECIMALS,
                                                        DEFAULT_DECIMALS,
                                                    )
                                                    : 0,
                                            )}
                                        </div>
                                    </div>
                                    <InfoTooltip title="Current earnings are displayed here." />
                                </div>

                                <div className="d-flex justify-content-center align-items-center mx-2">
                                    <button
                                        className="pool-collapse-button"
                                        onClick={() => {
                                            navigate(routeNames.stableLiquidityVault);
                                        }}
                                    >
                                        <BiRightArrowAlt />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
