import { TOKEN_INFO_MAP } from "config";
import { NetstatsState } from "redux/reducers";
import { EsdtTokenPaymentType } from "z/types";
import { convertToDollarString, convertWeiToEsdt, formatNumber } from "z/utils";

export function printPaymentAndPrice(
  payment: EsdtTokenPaymentType,
  tokenPrice: number,
) {
  const decimals = TOKEN_INFO_MAP[payment.token_identifier].decimals;
  const amount = convertWeiToEsdt(payment.amount, decimals, decimals);
  return `${formatNumber(amount, decimals)} (${convertToDollarString(amount.multipliedBy(tokenPrice))})`;
}

export function printWalletBalanceAndPrice(netstatsRedux: NetstatsState, tokenId: string, tokenPrice: number): string {
  const token = netstatsRedux.walletTokensMap[tokenId];
  if (token) {
    const tokenBalance = convertWeiToEsdt(token.balance, token.decimals, token.decimals);
    return `${formatNumber(tokenBalance, token.decimals)} (${convertToDollarString(tokenBalance.multipliedBy(tokenPrice))})`;
  }
  
  return '0 (0$)';
}
