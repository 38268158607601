import { Address, AddressValue, ArgSerializer, BigUIntValue, StringValue, TokenIdentifierValue, TypedValue } from '@multiversx/sdk-core/out';
import {
    OURO_TOKEN_ID,
    SLIP_SC_ADDRESS,
} from 'config';
import {
    ADD_LIQUIDITY_SLIP_GAS_LIMIT,
    CREATE_SLIP_POOL_GAS_LIMIT,
    EXTRACT_OURO_INTO_SLIP_GAS_LIMIT,
    INJECT_OURO_INTO_SLIP_GAS_LIMIT,
    ISSUE_SLIP_TOKEN_GAS_LIMIT,
    MINT_SLIP_TOKEN_GAS_LIMIT,
    PAUSE_SLIP_POOL_GAS_LIMIT,
    REMOVE_SLIP_POOL_GAS_LIMIT,
    SET_SLIP_ESDT_TRANSFER_ROLE_GAS_LIMIT,
    SET_SLIP_REDIRECT_LP_ADDRESS_GAS_LIMIT,
    SET_SLIP_VAULT_LP_ADDRESS_GAS_LIMIT,
    UNPAUSE_SLIP_POOL_GAS_LIMIT,
    UNREMOVE_SLIP_POOL_GAS_LIMIT,
} from 'config/slip';
import { elrondDappSendTransactions, mvxQuery } from './common';
import { slipSmartContract, swapPairSmartContract } from './provider';


export async function getMaxAmountThatCanBeAddedIntoSlip(tokenIdentifier: string): Promise<string> {
    try {
        const args: TypedValue[] = [new TokenIdentifierValue(tokenIdentifier)];
        const interaction = slipSmartContract.methods.getMaxAmountThatCanBeAdded(args);
        const value = await mvxQuery(interaction);
        const decoded = value.toFixed();
        return decoded;
    } catch (err) {
        console.error('getMaxAmountThatCanBeAddedIntoSlip', err);
    }

    return '0';
}

export async function viewSwapPool(address: string): Promise<{
    firstTokenId: string;
    secondTokenId: string;
}> {
    try {
        swapPairSmartContract.setAddress(new Address(address));
        const interaction = swapPairSmartContract.methodsExplicit.viewPool();

        const value = await mvxQuery(interaction);
        const decoded = {
            firstTokenId: value.first_token_id,
            secondTokenId: value.second_token_id,
        };
        return decoded;
    } catch (err) {
        console.error('viewSwapPool', err);
    }
    return { firstTokenId: '', secondTokenId: '' };
}

export async function pauseSlipPool(tokenIdentifier: string) {
    const args: TypedValue[] = [new TokenIdentifierValue(tokenIdentifier)];

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `pausePool@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: SLIP_SC_ADDRESS,
        gasLimit: PAUSE_SLIP_POOL_GAS_LIMIT,
    };

    const txName = 'Pause slip pool';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function unpauseSlipPool(tokenIdentifier: string) {
    const args: TypedValue[] = [new TokenIdentifierValue(tokenIdentifier)];

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `unpausePool@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: SLIP_SC_ADDRESS,
        gasLimit: UNPAUSE_SLIP_POOL_GAS_LIMIT,
    };

    const txName = 'Unpause slip pool';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function removeSlipPool(tokenIdentifier: string) {
    const args: TypedValue[] = [new TokenIdentifierValue(tokenIdentifier)];

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `removePool@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: SLIP_SC_ADDRESS,
        gasLimit: REMOVE_SLIP_POOL_GAS_LIMIT,
    };

    const txName = 'Remove slip pool';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function unremoveSlipPool(tokenIdentifier: string) {
    const args: TypedValue[] = [new TokenIdentifierValue(tokenIdentifier)];

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `unremovePool@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: SLIP_SC_ADDRESS,
        gasLimit: UNREMOVE_SLIP_POOL_GAS_LIMIT,
    };

    const txName = 'Unremove slip pool';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function injectOuroIntoSlip(amountIn: string) {
    const args: TypedValue[] = [
        new TokenIdentifierValue(OURO_TOKEN_ID),
        new BigUIntValue(amountIn),
        new StringValue('injectOURO'),
    ];

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `ESDTTransfer@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: SLIP_SC_ADDRESS,
        gasLimit: INJECT_OURO_INTO_SLIP_GAS_LIMIT,
    };

    const txName = 'Inject OURO into slip';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function extractTokenFromSlip(tokenId: string, amountIn: string) {
    const args: TypedValue[] = [new TokenIdentifierValue(tokenId), new BigUIntValue(amountIn)];

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `extract@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: SLIP_SC_ADDRESS,
        gasLimit: EXTRACT_OURO_INTO_SLIP_GAS_LIMIT,
    };

    const txName = 'Extract OURO into slip';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function setSlipVestaRedirectLpAddress(vestaRedirectLpAddress: string) {
    const args: TypedValue[] = [new AddressValue(new Address(vestaRedirectLpAddress))];

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `setVestaRedirectLpAddress@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: SLIP_SC_ADDRESS,
        gasLimit: SET_SLIP_REDIRECT_LP_ADDRESS_GAS_LIMIT,
    };

    const txName = 'Extract OURO into slip';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function addLiquidityIntoPool(tokenIdentifier: string, amountIn: string) {
    const args: TypedValue[] = [
        new TokenIdentifierValue(tokenIdentifier),
        new BigUIntValue(amountIn),
        new StringValue('addLiquidity'),
    ];

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `ESDTTransfer@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: SLIP_SC_ADDRESS,
        gasLimit: ADD_LIQUIDITY_SLIP_GAS_LIMIT,
    };

    const txName = 'Add liquidity into slip';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function addLpIntoSlipAndSendFrTokenToAddress(
    poolTokenIdentifier: string,
    address: string,
    tokenIdentifier: string,
    amountIn: string,
) {
    const args: TypedValue[] = [
        new TokenIdentifierValue(tokenIdentifier),
        new BigUIntValue(amountIn),
        new StringValue('addLpAndSendFrTokenToAddress'),
        new TokenIdentifierValue(poolTokenIdentifier),
        new AddressValue(new Address(address)),
    ];

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `ESDTTransfer@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: SLIP_SC_ADDRESS,
        gasLimit: ADD_LIQUIDITY_SLIP_GAS_LIMIT,
    };

    const txName = 'Transform lp into fr lp';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function setSlipVestaVaultLpAddress(vestaRedirectLpAddress: string, tokens_length_in_slip: number) {
    const args: TypedValue[] = [new AddressValue(new Address(vestaRedirectLpAddress))];

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `setVestaVaultLpAddress@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: SLIP_SC_ADDRESS,
        gasLimit: SET_SLIP_VAULT_LP_ADDRESS_GAS_LIMIT + SET_SLIP_ESDT_TRANSFER_ROLE_GAS_LIMIT * tokens_length_in_slip,
    };

    const txName = 'Extract OURO into slip';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function issueSlipToken(
) {
    const data = `issueSlipToken`;

    const tx = {
        value: 0.05 * 10 ** 18,
        data,
        receiver: SLIP_SC_ADDRESS,
        gasLimit: ISSUE_SLIP_TOKEN_GAS_LIMIT,
    };

    const txName = 'issueSlipToken';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function mintSlipToken(amount_out: string) {
    const args: TypedValue[] = [new BigUIntValue(amount_out)];

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `mintSlipToken@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: SLIP_SC_ADDRESS,
        gasLimit: MINT_SLIP_TOKEN_GAS_LIMIT,
    };

    const txName = 'mintSlipToken';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

