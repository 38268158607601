import React, { useEffect, useState } from 'react';
import { Slider } from '@mui/material';
import { TokenTransfer } from '@multiversx/sdk-core/out';
import {
    useGetAccount,
    useGetPendingTransactions,
} from '@multiversx/sdk-dapp/hooks';
import clsx from 'clsx';
import {
  FVST_TOKEN_ID,
  VESTA_TOKEN_IDS,
  VST_TOKEN_ID,
  getSlipingVestaMultiplier,
} from 'config';
import {
  claimWithRawVesta,
  getTokenBalanceFromApi,
  getTokenLogo,
} from 'z/elrond';
import { VestingTypeEnum } from 'z/types';
import {
  formatNumber,
  convertEsdtToWei,
  createTokenTicker,
  convertWeiToEsdt,
  ERROR_CONNECT_WALLET,
  ERROR_INVALID_NUMBER,
  ERROR_NOT_ENOUGH_BALANCE,
  ERROR_TRANSACTION_ONGOING,
  isPositiveBigNumber,
  isPositiveOrZeroBigNumber,
  parseNumberOrUndefined,
  toastError,
  ZERO_STRING,
} from 'z/utils';

export function Vesta() {
  const { address } = useGetAccount();
  const { hasPendingTransactions } = useGetPendingTransactions();
  // const navigate = useNavigate();

  // const swapRedux = useAppSelector(selectSwap);

  const [mintType, setMintType] = useState<VestingTypeEnum>(VestingTypeEnum.Vesta);  // 1 for VESTA, 2 for sVESTA, 3 for fVESTA
  const [vestaSleepingYears, setVestaSleepingYears] = useState<number>(12);
  const [rawVestaBalance, setRawVestaBalance] = useState<string>(ZERO_STRING);
  const [rawVestaAmount, setRawVestaAmount] = useState<string>(ZERO_STRING);
  const [rawVestaAmountError, setRawVestaAmountError] = useState<string>('');
  const rawVestaTokenId = VESTA_TOKEN_IDS[0];
  const mintTokenId = VESTA_TOKEN_IDS[mintType];

  const parsedRVA = parseNumberOrUndefined(rawVestaAmount) ?? 0;
  const mintAmount = parsedRVA * (mintType == VestingTypeEnum.SleepingVesta ? getSlipingVestaMultiplier(vestaSleepingYears) : 1);

  useEffect(() => {
    if (!address || hasPendingTransactions) return;
    (async () => {
      const _tokenBalanceInfo = await getTokenBalanceFromApi(address, rawVestaTokenId);
      setRawVestaBalance(_tokenBalanceInfo? _tokenBalanceInfo.balance : ZERO_STRING);
    })();
  }, [address, hasPendingTransactions]);

  function onChangeRawVestaAmount(value: string) {
    let error = '';
    if (!address) {
      error = ERROR_CONNECT_WALLET;
    } else if (!isPositiveOrZeroBigNumber(value)) {
        error = ERROR_INVALID_NUMBER;
    } else if (convertEsdtToWei(value).comparedTo(rawVestaBalance) > 0) {
        error = ERROR_NOT_ENOUGH_BALANCE;
    }
    
    setRawVestaAmountError(error);
    setRawVestaAmount(value);
    
    return error;
  }

  function onMaxRawVestaAmount() {
    if (address) {
      onChangeRawVestaAmount(convertWeiToEsdt(rawVestaBalance).toFixed());
    }
  }

  const onChangeVestaSleepingYearsSlider = (e: number) => {
    setVestaSleepingYears(e);
  };

  async function mintVesta() {
    let error = '';
    if (!address) {
      error = ERROR_CONNECT_WALLET;
    } else if (!isPositiveBigNumber(rawVestaAmount)) {
      error = ERROR_INVALID_NUMBER;
    } else if (hasPendingTransactions) {
      error = ERROR_TRANSACTION_ONGOING;
    } else {
      error = rawVestaAmountError;
    }

    if (error) {
        toastError(error);
        return;
    }

    await claimWithRawVesta(
      TokenTransfer.fungibleFromBigInteger(rawVestaTokenId, convertEsdtToWei(rawVestaAmount)),
      mintType,
      vestaSleepingYears,
    );
  }

  return (
      <>
          <div className='container' style={{ marginTop: '50px' }}>

                    <div className='d-flex justify-content-center mt-4'>
                      <div className='vesta_x_pool_card' style={{ width: '600px' }}>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className="d-flex justify-content-center" style={{ marginLeft: 'calc(50% - 50px)' }}>
                                    <span style={{ color: '#F1DC46', fontSize: '1.2rem' }}>
                                        Vesta Minter
                                    </span>
                                </div>
                            </div>

                            <div className="row mt-4" style={{ rowGap: '20px' }}>
                                <div className="col-12">
                                    <span style={{ color: 'white', fontSize: '1.1rem' }}>
                                      Select Token Type
                                    </span>

                                    <div className="token-pair-box mt-2 justify-content-between align-items-center gap-4">
                                      
                                        <button
                                          className={clsx("farm_card_individual_element_but d-flex flex-column justify-content-center align-items-center", mintType === VestingTypeEnum.Vesta && 'selected')}
                                          onClick={() => setMintType(VestingTypeEnum.Vesta)}
                                        >
                                          <img className="farm_logo_element mb-1" src={getTokenLogo(VST_TOKEN_ID)} />
                                          <span>VESTA</span>
                                        </button>

                                        <button
                                          className={clsx("farm_card_individual_element w-100", mintType === VestingTypeEnum.SleepingVesta && 'selected')}
                                          onClick={() => setMintType(VestingTypeEnum.SleepingVesta)}
                                        >
                                          <div className="d-flex justify-content-center">
                                            <span>{vestaSleepingYears} years</span>
                                          </div>
                                          <Slider
                                            value={vestaSleepingYears}
                                            marks={false}
                                            onChange={(e, value) => onChangeVestaSleepingYearsSlider(value as number)}
                                            min={1}
                                            max={20}
                                            step={1}
                                            disabled={mintType !== VestingTypeEnum.SleepingVesta}
                                          />
                                          <div className="d-flex justify-content-center">
                                            <span>sVESTA</span>
                                          </div>
                                        </button>

                                        <button
                                          className={clsx("farm_card_individual_element_but d-flex flex-column justify-content-center align-items-center", mintType === VestingTypeEnum.FrozenVesta && 'selected')}
                                          // onClick={() => setMintType(VestingTypeEnum.FrozenVesta)}
                                          disabled={true}
                                        >
                                          <img className="farm_logo_element mb-1" src={getTokenLogo(FVST_TOKEN_ID)} />
                                          <span>fVESTA</span>
                                        </button>

                                    </div>

                                    <div
                                        className="mt-4"
                                        style={{ color: 'white', fontSize: '1.1rem' }}
                                    >
                                      Mint Amount
                                    </div>

                                    <div className="d-flex flex-column gap-1 mt-2">
                                      <div className="vesta_x_swap_input_box BToken_Input">
                                        <div className="d-flex align-items-center">
                                            <input
                                                className="swap_input"
                                                type="number"
                                                value={rawVestaAmount}
                                                onChange={(e) => onChangeRawVestaAmount(e.target.value)}
                                            />
                                            <button className="add-liquidity-selected-token-buttton">
                                                <img src={getTokenLogo(rawVestaTokenId)} alt="logo" width="30px" />
                                                {createTokenTicker(rawVestaTokenId)}
                                            </button>
                                        </div>
                                          <div className="d-flex justify-content-between mt-1">
                                              <div className="input-token-error">{rawVestaAmountError}</div>
                                              <div
                                                className="add-liquidity-input-token-balance-box"
                                                onClick={onMaxRawVestaAmount}
                                              >
                                                  <div className="">Balance:&nbsp;</div>
                                                  <div style={{ color: 'white' }}>
                                                    {formatNumber(convertWeiToEsdt(rawVestaBalance))}
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                    </div>

                                    <div className="swap-info-box" style={{ marginTop: '3px' }}>
                                      <div className="swap-info mt-2">
                                          <div className="swap-info-li mb-0">
                                              <div>You will receive</div>
                                              <div>
                                                {`${mintAmount == undefined ? '-' : formatNumber(mintAmount)} ${createTokenTicker(mintTokenId)}`}
                                              </div>
                                          </div>
                                      </div>
                                    </div>

                                    <div className="d-flex just-content-center">
                                        <button
                                          className="mt-4 add-liquidity-button"
                                          onClick={mintVesta}
                                          disabled={hasPendingTransactions}
                                        >
                                          Mint
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
          </div>
      </>
  );
}
