import { MVX_EXPLORER_URL, SFT_INFO_MAP } from 'config';
import { SftConfigType } from 'z/types';

export const getSftInfo = (collectionIdentifier: string, nonce: number): SftConfigType => {
    const collectionInfo = SFT_INFO_MAP[collectionIdentifier];
    if (!collectionInfo) {
        throw Error(`getSftImage: Sft collection info not found (${collectionIdentifier})`);
    }
    const sftInfo = collectionInfo.sfts.find((sft) => sft.nonce === nonce);
    if (!sftInfo) {
        throw Error(`getSftImage: Sft info not found (${collectionIdentifier})`);
    }
    return sftInfo;
};

export const getSftImage = (collectionIdentifier: string, nonce: number): string => {
    const sftInfo = getSftInfo(collectionIdentifier, nonce);
    return sftInfo.imageUrl;
};

export const getSftExplorerUrl = (collectionIdentifier: string, nonce: number): string => {
    const hexNonce = nonce.toString(16);
    return `${MVX_EXPLORER_URL}/nfts/${collectionIdentifier}-${hexNonce.length % 2 ? '0' : ''}${nonce}`;
};

export const getSftSupply = (collectionIdentifier: string, nonce: number): number => {
    const sftInfo = getSftInfo(collectionIdentifier, nonce);
    return sftInfo.supply;
};