import React, { FC, useEffect, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { IoClose } from 'react-icons/io5';
import Modal from 'react-modal';
import { BASE_TOKENS } from 'config';
import {
    getTokenInfosBySearchFromApi,
    getTokenInfosByTokenIdFromApi,
    getTokenLogo,
} from 'z/elrond';
import { SimpleTokenInfoType } from 'z/types';
import {
    DEBOUNCE_PERIOD,
    TOKEN_SEARCH_INPUT_TEXT,
} from 'z/utils';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

interface PropsType {
    tokenId: string,
    onChangeTokenId?: (e: string) => void,
    isBaseToken?: boolean,
}

export const CreatePoolSelectToken: FC<PropsType> = ({
    tokenId,
    onChangeTokenId,
    isBaseToken = false,
}) => {
    //
    const [tokenInfos, setTokenInfos] = useState<SimpleTokenInfoType[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function searchTokenInfos() {
        (async () => {
            const _tokenInfos = await getTokenInfosBySearchFromApi(searchText);
            setTokenInfos(_tokenInfos);
        })();
    }

    useEffect(() => {
        if (isBaseToken) {
            (async () => {
                const _tokenInfos = await getTokenInfosByTokenIdFromApi(BASE_TOKENS);
                setTokenInfos(_tokenInfos);
            })();
        } else {
            const delayDebounceFn = setTimeout(() => {
                searchTokenInfos();
            }, DEBOUNCE_PERIOD);
        
            return () => clearTimeout(delayDebounceFn);
        }
    }, [searchText]);
    
    function closeModal() {
        setIsOpen(false);
    }
    
    const handleSelectToken = (tokenInfo: any) => {
        if (onChangeTokenId) {
            onChangeTokenId(tokenInfo);
        }
        setSearchText('');
        closeModal();
    };
    
    const handleOpenModal = () => {
        setIsOpen(true);
    };
    
    return (
        <>
            {
                tokenId ? (
                    <button className="cp-select-token-button selected" onClick={handleOpenModal}>
                        <div className="d-flex align-items-center gap-2">
                            <img src={getTokenLogo(tokenId)} alt="logo" width="30px" />
                            {tokenId}
                        </div>
                        <BiChevronDown />
                    </button>
                ) : (
                    <button className="cp-select-token-button" onClick={handleOpenModal}>
                        Select Token
                        <BiChevronDown />
                    </button>
                )
            }
                        
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                ariaHideApp={false}
            >
                <div className="select-token-modal">
                    <div
                        style={{
                            padding: '0px 15px',
                            borderBottom: '1px solid rgba(255,255,255,0.06)',
                        }}
                    >
                        <div className="d-flex justify-content-between align-items-center">
                            <span
                                style={{
                                    color: '#F1DC46',
                                    marginLeft: '10px',
                                    fontSize: '1.25rem',
                                }}
                            >
                                Select a token
                            </span>
                            
                            <div className="modal-close-but" onClick={closeModal}>
                                <IoClose />
                            </div>
                        </div>

                        {
                            !isBaseToken && (
                                <input
                                    className="search-token my-3"
                                    placeholder={TOKEN_SEARCH_INPUT_TEXT}
                                    onChange={(e) => {
                                        setSearchText(e.target.value);
                                    }}
                                    onKeyDown={(e => {
                                        if (e.key === 'Enter') {
                                            searchTokenInfos();
                                        }
                                    })}
                                />
                            )
                        }
                    </div>
                                    
                    <div style={{ padding: '0px 15px' }}>
                        <div className="mt-3 scrollbar token-list-box">
                            {
                                tokenInfos.map((tokenInfo, index) => {
                                    return (
                                        <div
                                            className="token-li"
                                            key={index}
                                            onClick={() => handleSelectToken(tokenInfo.identifier)}
                                        >
                                            <img
                                                src={
                                                    tokenInfo.assets
                                                        ? tokenInfo.assets.svgUrl
                                                        : getTokenLogo(tokenInfo.identifier)
                                                }
                                                alt="logo"
                                                width="40px"
                                            />
                                            
                                            <div className="d-flex flex-column">
                                                <span style={{ color: 'white', fontSize: '1.2rem' }}>
                                                    {tokenInfo.name}
                                                </span>
                                                <span style={{ color: '#98A1C0' }}>
                                                    {tokenInfo.identifier}
                                                </span>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};