import { AbiRegistry, Address, AddressValue, ArgSerializer, BigUIntValue, BinaryCodec, StringValue, TokenIdentifierValue, TokenTransfer, TypedValue, U64Value } from "@multiversx/sdk-core/out";
import { MINT_VESTA_GAS_LIMIT, SNAKE_VESTING_SC_ADDRESS } from "config";
import { UnlockMilestoneType } from "z/types";
import abiVesting from './abi/snake-vesting.abi.json';
import { elrondDappSendTransactions } from "./common";

export function decodeVestedAttributes(encoded: string): UnlockMilestoneType[] {
  try {
    const abiRegistry = AbiRegistry.create(abiVesting);
    const structType = abiRegistry.getStruct("UnlockSchedule");
    const decoded = new BinaryCodec().decodeTopLevel(Buffer.from(encoded, "base64"), structType).valueOf();
    const ums = decoded.unlock_milestones.map((um: any) => ({
      unlock_epoch: um.unlock_epoch.toNumber(),
      unlock_percent: um.unlock_percent.toNumber(),
    }));

    return ums;
  } catch (e) {
    // console.error(e);

    return [];
  }
}

export async function snakeUnlock(
  payment: TokenTransfer,
  sender: string,
) {
  const args: TypedValue[] = [
    new TokenIdentifierValue(payment.tokenIdentifier),
    new U64Value(payment.nonce),
    new BigUIntValue(payment.amountAsBigInteger),
    new AddressValue(new Address(SNAKE_VESTING_SC_ADDRESS)),
    new StringValue('unlock'),
  ];

  const { argumentsString } = new ArgSerializer().valuesToString(args);
  const data = `ESDTNFTTransfer@${argumentsString}`;

  const tx = {
    value: 0,
    data,
    receiver: sender,
    gasLimit: MINT_VESTA_GAS_LIMIT,
  };
  
  const txName = 'Unlock';
  const { sessionId, error } = await elrondDappSendTransactions(tx, txName);
  
  return { sessionId, error };
}