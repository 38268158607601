import {
    Address,
    AddressValue,
    ArgSerializer,
    BigUIntValue,
    BooleanValue,
    Interaction,
    StringValue,
    TokenIdentifierValue,
    TokenTransfer,
    Transaction,
    TypedValue,
    U32Value,
    U64Value,
    U8Value,
} from '@multiversx/sdk-core/out';
import BigNumber from 'bignumber.js';
import { AURYN_TOKEN_ID, SNAKE_COIL_SC_ADDRESS } from 'config';
import { SnakeCoilBaseContext, SnakeUncoilPosition } from 'z/types';
import { elrondDappSendTransactions, mvxQuery } from './common';
import { snakeCoilSmartContract } from './provider';

const OURO_TIER = 0;
const AURYN_TIER = 1;

export async function snakeCoil(payments: TokenTransfer[], sender: string, isDoubleCoil: boolean) {
    const args: TypedValue[] = [new AddressValue(new Address(SNAKE_COIL_SC_ADDRESS)), new U32Value(payments.length)];
    let gasLimit = 10_000_000;
    payments.map((payment) => {
        if (payment.tokenIdentifier === AURYN_TOKEN_ID || isDoubleCoil) {
            gasLimit = 125_000_000;
        }
        args.push(new TokenIdentifierValue(payment.tokenIdentifier));
        args.push(new U64Value(payment.nonce));
        args.push(new BigUIntValue(payment.amountAsBigInteger));
    });
    args.push(new StringValue('coil'));
    args.push(new BooleanValue(isDoubleCoil));

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `MultiESDTNFTTransfer@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: sender,
        gasLimit: gasLimit + 4_000_000 * payments.length,
    };

    const txName = 'Coil';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function snakeUncoil(payments: TokenTransfer[], sender: string) {
    const args: TypedValue[] = [new AddressValue(new Address(SNAKE_COIL_SC_ADDRESS)), new U32Value(payments.length)];
    payments.map((payment) => {
        args.push(new TokenIdentifierValue(payment.tokenIdentifier));
        args.push(new U64Value(payment.nonce));
        args.push(new BigUIntValue(payment.amountAsBigInteger));
    });
    args.push(new StringValue('uncoil'));

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `MultiESDTNFTTransfer@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: sender,
        gasLimit: 135_000_000,
    };

    const txName = 'Uncoil';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function claimUncoiled(selectedToken: string, chainID: string) {
    let tier = 0;

    if (selectedToken === 'AURYN') {
        tier = OURO_TIER;
    } else if (selectedToken === 'EAURYN') {
        tier = AURYN_TIER;
    } else {
        return { sessionId: '', error: 'Invalid token' };
    }

    const args: TypedValue[] = [new U8Value(tier)];

    const txx: Transaction = snakeCoilSmartContract.methodsExplicit
        .claim(args)
        .withGasLimit(25_000_000)
        .withChainID(chainID)
        .buildTransaction();

    const tx = {
        value: 0,
        data: txx.getData().toString(),
        receiver: SNAKE_COIL_SC_ADDRESS,
        gasLimit: 25_000_000,
    };

    const txName = 'Claim Uncoiled';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function snakeCoilViewBaseContext(): Promise<SnakeCoilBaseContext | undefined> {
    try {
        const value = await mvxQuery(snakeCoilSmartContract.methods.viewBaseContext());
        const decoded = {
            tokens: value.tokens.map((v: any) => v.toString()),
            token_supplies: value.token_supplies.map((v: any) => v.toFixed(0)),
            auryn_token_id: value.auryn_token_id.toString(),
            eauryn_token_id: value.eauryn_token_id.toString(),

            is_coil_enabled: value.is_coil_enabled,
            is_uncoil_enabled: value.is_uncoil_enabled,
            unbonding_token_supplies: value.unbonding_token_supplies.map((v: any) => v.toFixed(0)),
        };

        return decoded;
    } catch (err) {
        console.error('snakeCoilViewBaseContext:', err);
        return undefined;
    }
}

async function viewUncoilPositions(interaction: Interaction): Promise<SnakeUncoilPosition[]> {
    try {
        const value = await mvxQuery(interaction);
        const decoded = value.map((v: any) => {
            return {
                uncoil_token: v.uncoil_token === 0 ? 'OURO' : 'AURYN',
                uncoil_amount: v.uncoil_amount.toString(10),
                uncoil_timestamp: v.uncoil_timestamp.toNumber(),
            };
        });

        return decoded;
    } catch (err) {
        console.error('viewUncoilPositions:', err);
        return [];
    }
}

export async function viewAurynUncoilPositions(address: string): Promise<SnakeUncoilPosition[]> {
    return await viewUncoilPositions(snakeCoilSmartContract.methods.getAurynUncoilPositions([address]));
}

export async function viewEliteAurynUncoilPositions(address: string): Promise<SnakeUncoilPosition[]> {
    return await viewUncoilPositions(snakeCoilSmartContract.methods.getEaurynUncoilPositions([address]));
}

export async function viewMaxEAurynUncoilAmount(address: string): Promise<BigNumber> {
    try {
        const value = await mvxQuery(snakeCoilSmartContract.methods.getMaxEliteUncoilAmount([address]));
        return value;
    } catch (err) {
        console.error('viewMaxEAurynUncoilAmount:', err);
        return new BigNumber(0);
    }
}
