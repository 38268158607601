import React from 'react';
import { useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { FARMS } from 'config';
import { FarmLiquidity } from 'pages/Assets/FarmLiquidity';
import {
    // selectFarm,
    selectSwap,
} from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { routeNames } from 'routes';
import { FarmRowInfo } from './FarmRowInfo';

const tabs = ['Farms', 'My Staked Liq.'];

export const Farm = ({
    selectedTab,
}: {
    selectedTab: number,
}) => {
    const { hasPendingTransactions } = useGetPendingTransactions();
    const navigate = useNavigate();

    const swapRedux = useAppSelector(selectSwap);
    // const farmRedux = useAppSelector(selectFarm);

    function gotoTab(index: number) {
        if (hasPendingTransactions) return;
        if (index === 0) navigate(routeNames.farms);
        if (index === 1) navigate(routeNames.myfarms);
    }

    return (
        <div className='container' style={{ marginTop: '50px' }}>
            <div className="d-flex justify-content-center">
                <div className="tab-box">
                    <div
                        className={clsx(
                            'tab-focus',
                            selectedTab === 0
                                ? 'tab-focus-left-0'
                                : 'tab-focus-left-50',
                        )}
                    />
                    {tabs.map((row, index) => {
                        return (
                            <div
                                className="tab"
                                key={`p-r-t2-${index}`}
                                onClick={() => gotoTab(index)}
                            >
                                {row}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="d-flex justify-content-center mt-4">
                <div className='vesta-first-container'>
                    {
                        selectedTab === 0 && (
                            <div className="mt-4 d-flex flex-column gap-3">
                                {FARMS.length > 0 &&
                                    FARMS.map((farm, index) => {
                                        for (const pool of swapRedux.pools) {
                                            if (pool.pool_address == farm.pool_address) {
                                                return (
                                                    // <FarmRow
                                                    //     key={`f-r-${index}`}
                                                    //     pool={pool}
                                                    //     farm={farm}
                                                    // />
                                                    <FarmRowInfo
                                                        key={`f-r-${index}`}
                                                        pool={pool}
                                                        farmId={index}
                                                        farm={farm}
                                                    />
                                                );
                                            }
                                        }
                                    })}
                            </div>
                        )
                    }
                    {
                        selectedTab === 1 && (
                            <FarmLiquidity />
                        )
                    }
                </div>
            </div>
        </div>
    );
};