import {
    BVST_TOKEN_ID,
    ESTAR_TOKEN_ID,
    FVST_TOKEN_ID,
    GSC_TOKEN_ID,
    KOSON_TOKEN_ID,
    OURO_TOKEN_ID,
    OUROESTAR_TOKEN_ID,
    OUROVST_TOKEN_ID,
    OUROWEGLD_TOKEN_ID,
    SSC_TOKEN_ID,
    SVST_TOKEN_ID,
    USDC_TOKEN_ID,
    USDD_TOKEN_ID,
    VOUROBVST_TOKEN_ID,
    VOUROFVST_TOKEN_ID,
    VOUROGSC_TOKEN_ID,
    VOUROKSON_TOKEN_ID,
    VOUROSSC_TOKEN_ID,
    VOUROSVST_TOKEN_ID,
    VOUROXLH_TOKEN_ID,
    VSOUROBVST_TOKEN_ID,
    VSOUROESTR_TOKEN_ID,
    VSOUROGSC_TOKEN_ID,
    VSOUROKSON_TOKEN_ID,
    VSOUROSSC_TOKEN_ID,
    VSOUROVST_TOKEN_ID,
    VSOUROXLH_TOKEN_ID,
    VST_TOKEN_ID,
    VSUSDCOURO_TOKEN_ID,
    VSUSDDOURO_TOKEN_ID,
    VUSDCOURO_TOKEN_ID,
    VUSDDOURO_TOKEN_ID,
    WEGLD_TOKEN_ID,
    XLH_TOKEN_ID,
} from 'config';

import { OuroLiquidityVestaVaultSelectedHolding } from 'z/types';

export const nativeOuroLiquidityTokenSelectorForVault = [
    {
        token_name: 'Native OURO USDD',
        token_id: VUSDDOURO_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.VaultNativeOuroUSDD,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: USDD_TOKEN_ID,
    },
    {
        token_name: 'Native OURO USDC',
        token_id: VUSDCOURO_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.VaultNativeOuroUSDC,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: USDC_TOKEN_ID,
    },
    {
        token_name: 'Native OURO VST',
        token_id: OUROVST_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.VaultNativeOuroVST,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: VST_TOKEN_ID,
    },
    {
        token_name: 'Native OURO bVST',
        token_id: VOUROBVST_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.VaultNativeOurobVST,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: BVST_TOKEN_ID,
    },
    {
        token_name: 'Native OURO sVST',
        token_id: VOUROSVST_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.VaultNativeOurosVST,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: SVST_TOKEN_ID,
    },
    {
        token_name: 'Native OURO fVST',
        token_id: VOUROFVST_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.VaultNativeOurofVST,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: FVST_TOKEN_ID,
    },
    {
        token_name: 'Native Ouro XLH',
        token_id: VOUROXLH_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.VaultNativeOuroXLH,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: XLH_TOKEN_ID,
    },
    {
        token_name: 'Native OURO Estar',
        token_id: OUROESTAR_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.VaultNativeOuroEstar,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: ESTAR_TOKEN_ID,
    },
    {
        token_name: 'Native OURO Koson',
        token_id: VOUROKSON_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.VaultNativeOuroKoson,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: KOSON_TOKEN_ID,
    },
    {
        token_name: 'Native OURO GSC',
        token_id: VOUROGSC_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.VaultNativeOuroGSC,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: GSC_TOKEN_ID,
    },
    {
        token_name: 'Native OURO SSC',
        token_id: VOUROSSC_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.VaultNativeOuroSSC,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: SSC_TOKEN_ID,
    },
    {
        token_name: 'Native OURO WEGLD',
        token_id: OUROWEGLD_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.VaultNativeOuroWegld,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: WEGLD_TOKEN_ID,
    },
];

export const securedOuroLiquidityTokenSelectorForVault = [
    {
        token_name: 'Secured OURO USDD',
        token_id: VSUSDDOURO_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.VaultSecuredOuroUSDD,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: USDD_TOKEN_ID,
    },
    {
        token_name: 'Secured OURO USDC',
        token_id: VSUSDCOURO_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.VaultSecuredOuroUSDC,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: USDC_TOKEN_ID,
    },
    {
        token_name: 'Secured OURO VST',
        token_id: VSOUROVST_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.VaultSecuredOuroVST,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: VST_TOKEN_ID,
    },
    {
        token_name: 'Secured OURO bVST',
        token_id: VSOUROBVST_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.VaultSecuredOurobVST,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: BVST_TOKEN_ID,
    },
    {
        token_name: 'Secured Ouro XLH',
        token_id: VSOUROXLH_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.VaultSecuredOuroXLH,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: XLH_TOKEN_ID,
    },
    {
        token_name: 'Secured OURO Estar',
        token_id: VSOUROESTR_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.VaultSecuredOuroEstar,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: ESTAR_TOKEN_ID,
    },
    {
        token_name: 'Secured OURO Koson',
        token_id: VSOUROKSON_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.VaultSecuredOuroKoson,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: KOSON_TOKEN_ID,
    },
    {
        token_name: 'Secured OURO GSC',
        token_id: VSOUROGSC_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.VaultSecuredOuroGSC,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: GSC_TOKEN_ID,
    },
    {
        token_name: 'Secured OURO SSC',
        token_id: VSOUROSSC_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.VaultSecuredOuroSSC,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: SSC_TOKEN_ID,
    },
];

export const nativeOuroLiquidityTokenSelectorForWallet = [
    {
        token_name: 'Native OURO USDD',
        token_id: VUSDDOURO_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.WalletNativeOuroUSDD,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: USDD_TOKEN_ID,
    },
    {
        token_name: 'Native OURO USDC',
        token_id: VUSDCOURO_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.WalletNativeOuroUSDC,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: USDC_TOKEN_ID,
    },
    {
        token_name: 'Native OURO VST',
        token_id: OUROVST_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.WalletNativeOuroVST,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: VST_TOKEN_ID,
    },
    {
        token_name: 'Native OURO bVST',
        token_id: VOUROBVST_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.WalletNativeOurobVST,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: BVST_TOKEN_ID,
    },
    {
        token_name: 'Native OURO sVST',
        token_id: VOUROSVST_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.WalletNativeOurosVST,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: SVST_TOKEN_ID,
    },
    {
        token_name: 'Native OURO fVST',
        token_id: VOUROFVST_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.WalletNativeOurofVST,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: FVST_TOKEN_ID,
    },
    {
        token_name: 'Native Ouro XLH',
        token_id: VOUROXLH_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.WalletNativeOuroXLH,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: XLH_TOKEN_ID,
    },
    {
        token_name: 'Native OURO Estar',
        token_id: OUROESTAR_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.WalletNativeOuroEstar,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: ESTAR_TOKEN_ID,
    },
    {
        token_name: 'Native OURO Koson',
        token_id: VOUROKSON_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.WalletNativeOuroKoson,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: KOSON_TOKEN_ID,
    },
    {
        token_name: 'Native OURO GSC',
        token_id: VOUROGSC_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.WalletNativeOuroGSC,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: GSC_TOKEN_ID,
    },
    {
        token_name: 'Native OURO SSC',
        token_id: VOUROSSC_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.WalletNativeOuroSSC,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: SSC_TOKEN_ID,
    },
    {
        token_name: 'Native OURO WEGLD',
        token_id: OUROWEGLD_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.WalletNativeOuroWegld,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: WEGLD_TOKEN_ID,
    },
];

export const securedOuroLiquidityTokenSelectorForWallet = [
    {
        token_name: 'Secured OURO USDD',
        token_id: VSUSDDOURO_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.WalletSecuredOuroUSDD,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: USDD_TOKEN_ID,
    },
    {
        token_name: 'Secured OURO USDC',
        token_id: VSUSDCOURO_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.WalletSecuredOuroUSDC,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: USDC_TOKEN_ID,
    },
    {
        token_name: 'Secured OURO VST',
        token_id: VSOUROVST_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.WalletSecuredOuroVST,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: VST_TOKEN_ID,
    },
    {
        token_name: 'Secured OURO bVST',
        token_id: VSOUROBVST_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.WalletSecuredOurobVST,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: BVST_TOKEN_ID,
    },
    {
        token_name: 'Secured Ouro XLH',
        token_id: VSOUROXLH_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.WalletSecuredOuroXLH,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: XLH_TOKEN_ID,
    },
    {
        token_name: 'Secured OURO Estar',
        token_id: VSOUROESTR_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.WalletSecuredOuroEstar,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: ESTAR_TOKEN_ID,
    },
    {
        token_name: 'Secured OURO Koson',
        token_id: VSOUROKSON_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.WalletSecuredOuroKoson,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: KOSON_TOKEN_ID,
    },
    {
        token_name: 'Secured OURO GSC',
        token_id: VSOUROGSC_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.WalletSecuredOuroGSC,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: GSC_TOKEN_ID,
    },
    {
        token_name: 'Secured OURO SSC',
        token_id: VSOUROSSC_TOKEN_ID,
        holding_type: OuroLiquidityVestaVaultSelectedHolding.WalletSecuredOuroSSC,
        first_token_id: OURO_TOKEN_ID,
        second_token_id: SSC_TOKEN_ID,
    },
];
