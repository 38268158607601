import { NftType } from '@multiversx/sdk-dapp/types/tokens.types';
import { EsdtTokenPaymentType } from './common';

export interface StakedNftType {
    token_identifier: string;
    nonce: number;
    amount: number;
}

export interface NftStakingStateType {
    tabName: string;
    poolInfo: NftStakingPoolType[];
}

export interface NftStakingPoolType {
    configuration: NftStakingPoolConfig;
    publicStats: PoolStats;
    userStats?: PoolStats;
}

export interface NftStakingPoolConfig {
    poolType: NftStakingPoolTypeEnum;
    name: string;
    tokenIdentifier: string;
    nonce?: number;
}

// export interface NftStakingPoolStats {
//   globalScores: PoolStats;
//   userScores?: {
//     staked: any[];
//     score: number;
//   };
// }

export enum NftStakingPoolTypeEnum {
    Invalid = 0,
    All = 1,
    CodingDivisionSfts = 2,
    Bloodshed = 3,
    Nosferatu = 4,
    VestaXDAO = 5,
    SnakesSfts = 6,
    SharesSfts = 7,
    XBunnies = 8,
}

export interface PoolStats {
    staked: number;
    supply: number;
    score: number;
}

export interface NftStakingGlobalPoolStats {
    poolType: NftStakingPoolTypeEnum;
    poolScore: number;
}

export interface NftStakingUserPoolStats {
    poolType: NftStakingPoolTypeEnum;
    poolScore: number;
    rawPoolScore: number;
    secondaryPoolScore: number;
    secondaryRawPoolScore: number;
    stakedNfts: NftType[];
}

export interface NftStakingGeneralUserData {
    pendingRewards: EsdtTokenPaymentType[];
    unbondingAssets: NftStakingUnbondingBatch[];
    userDeb: number;
}

export interface NftStakingUnbondingBatch {
    timestamp: number;
    assets: NftType[];
}

// SC structs:
export interface NftStakingUserData {
    generalData: NftStakingGeneralUserData;
    poolData: NftStakingUserPoolStats[];
}
