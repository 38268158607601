import {
    Address,
    AddressValue,
    ArgSerializer,
    BigUIntValue,
    ResultsParser,
    StringValue,
    TokenIdentifierValue,
    TypedValue,
} from '@multiversx/sdk-core/out';
import BigNumber from 'bignumber.js';
import { STAKING_COMMON_GAS_LIMIT } from 'config';
import {
    StakingBaseContext,
    parseStakingStateEnum,
    StakingUserContext,
} from 'z/types';
import {
    toastError,
} from 'z/utils';
import { elrondDappSendTransactions } from './common';
import { createStakingSmartContract, elrondProvider } from './provider';

export async function stakingViewBaseContext(scAddress: string): Promise<StakingBaseContext | undefined> {
    try {
        const stakingSmartContract = createStakingSmartContract(scAddress);
        const interaction = stakingSmartContract.methodsExplicit.viewBaseContext();
        const query = interaction.check().buildQuery();
        const queryResponse = await elrondProvider.queryContract(query);
        const endpointDefinition = interaction.getEndpoint();
        const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(
            queryResponse,
            endpointDefinition,
        );
        
        if (!firstValue || !returnCode.isSuccess()) {
            toastError(returnMessage);
            return undefined;
        }
        
        const value = firstValue.valueOf();
        const decoded = {
            state: parseStakingStateEnum(value.state.name),
            stake_token: value.stake_token.toString(),
            apr: value.apr.toNumber(),
            min_stake_amount: value.min_stake_amount.toFixed(0),
            max_stake_amount: value.max_stake_amount.toFixed(0),
            admins: value.admins.map((v: any) => v.toString()),

            //
            total_users: value.total_users.toNumber(),
            total_staked_amount: value.total_staked_amount.toFixed(0),
        };

        return decoded;
    } catch (err) {
        console.error(err);
    }

    return undefined;
}

export async function stakingViewUserContext(scAddress: string, userAddress: string): Promise<StakingUserContext | undefined> {
    try {
        const args: TypedValue[] = [
            new AddressValue(new Address(userAddress)),
        ];
        const stakingSmartContract = createStakingSmartContract(scAddress);
        const interaction = stakingSmartContract.methodsExplicit.viewUserContext(args);
        const query = interaction.check().buildQuery();
        const queryResponse = await elrondProvider.queryContract(query);
        const endpointDefinition = interaction.getEndpoint();
        const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(
            queryResponse,
            endpointDefinition,
        );
        
        if (!firstValue || !returnCode.isSuccess()) {
            toastError(returnMessage);
            return undefined;
        }
        
        const value = firstValue.valueOf();
        const decoded = {
            staked_amount: value.staked_amount.toFixed(0),
            current_reward_amount: value.current_reward_amount.toFixed(0),
            last_reward_update_timestamp: value.last_reward_update_timestamp.toNumber(),
        };

        return decoded;
    } catch (err) {
        console.error(err);
    }

    return undefined;
}

export async function stakingStake(
    scAddress: string,
    tokenIn: string,
    amountIn: BigNumber.Value,
) {
    const args: TypedValue[] = [
        new TokenIdentifierValue(tokenIn),
        new BigUIntValue(amountIn),
        new StringValue('stake')
    ];

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `ESDTTransfer@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: scAddress,
        gasLimit: STAKING_COMMON_GAS_LIMIT
    };

    const txName = 'Stake';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);
    
    return { sessionId, error };
}
                    
export async function stakingUnstake(
    scAddress: string,
    amount: BigNumber.Value,
) {
    const args: TypedValue[] = [
        new BigUIntValue(amount),
    ];

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `unstake@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: scAddress,
        gasLimit: STAKING_COMMON_GAS_LIMIT
    };

    const txName = 'Unstake';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);
    
    return { sessionId, error };
}

export async function stakingClaimRewards(
    scAddress: string,
) {
    const data = `claimRewards`;

    const tx = {
        value: 0,
        data,
        receiver: scAddress,
        gasLimit: STAKING_COMMON_GAS_LIMIT
    };

    const txName = 'Claim';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);
    
    return { sessionId, error };
}
