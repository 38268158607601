import React, { useState } from 'react';
import { Collapse } from '@mui/material';
import { TokenTransfer } from '@multiversx/sdk-core/out';
import { Row, Col } from 'react-bootstrap';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import {
    AURYN_TOKEN_ID,
    EAURYN_TOKEN_ID,
    OURO_TOKEN_ID,
    SLIP_TOKEN_ID,
    USDC_TOKEN_ID,
    USDD_TOKEN_ID,
    VEGLD_TOKEN_ID,
    VST_TOKEN_ID,
} from 'config';
import { selectNetstats } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { getTokenDecimals, getTokenLogo } from 'z/elrond';
import { useNftStakingHooks } from 'z/hooks/nft-staking';
import { convertWeiToEsdt, formatNumber, toastError } from 'z/utils';
import { EsdtTokenSelect } from './EsdtTokenSelect';

const nftMenu = [
    {
        name: 'Subsidiary via (SS)',
        injectProfile: 1,
    },
    {
        name: 'Snake via [SS]',
        injectProfile: 2,
    },
    {
        name: 'Shareholder via <SS>',
        injectProfile: 3,
    },
    {
        name: 'Coding Division via CD SFTs',
        subMenu: [
            {
                name: 'Coding Division Split',
                injectProfile: 4,
            },
            {
                name: 'Coding Division POT',
                injectProfile: 5,
            },
        ],
    },
    {
        name: 'VestaX.DAO',
        subMenu: [
            {
                name: 'Minor VestaX.DAO Split',
                injectProfile: 0,
            },
            {
                name: 'Major VestaX.DAO Split',
                injectProfile: 0,
            },
            {
                name: 'VestaX.Finance™ POT',
                injectProfile: 0,
            },
        ],
    },
    {
        name: 'Nosferatu',
        subMenu: [
            {
                name: 'Nosferatu SPLIT',
                injectProfile: 0,
            },
            {
                name: 'Nosferatu POT',
                injectProfile: 0,
            },
        ],
    },
    {
        name: 'Bloodshed SPLIT',
        injectProfile: 0,
    },
    {
        name: 'Recycling SPLIT',
        injectProfile: 0,
    },
];

const initialTokens = [
    OURO_TOKEN_ID,
    USDD_TOKEN_ID,
    AURYN_TOKEN_ID,
    EAURYN_TOKEN_ID,
    VST_TOKEN_ID,
    VEGLD_TOKEN_ID,
    USDC_TOKEN_ID,
    SLIP_TOKEN_ID,
];

const NftAdmin = () => {
    const [curNftType, setCurNftType] = useState<IMenuInfo>(nftMenu[0] as IMenuInfo);
    const [tokens, setTokens] = useState<string[]>(initialTokens);
    const [rewardAmounts, setRewardAmounts] = useState<Record<string, string>>({});

    const { sendInjectRewardsTransaction } = useNftStakingHooks();

    const netstatsRedux = useAppSelector(selectNetstats);

    const handleMenuClicked = (menu: IMenuInfo) => {
        setCurNftType(menu);
    };

    const handleSelectToken = (tokenId: string) => {
        if (tokens.includes(tokenId)) {
            toastError('Token is already existed.');
            return;
        }

        setTokens([...tokens, tokenId]);
    };

    const onChangeInputValue = (token_id: string, value: string) => {
        const maxAmount = convertWeiToEsdt(
            netstatsRedux.walletTokensMap[token_id]?.balance || '0',
            getTokenDecimals(token_id),
        );
        if (parseFloat(value) > maxAmount.toNumber()) {
            return setRewardAmounts((prev) => ({
                ...prev,
                [token_id]: maxAmount.toString(),
            }));
        }
        setRewardAmounts((prev) => ({ ...prev, [token_id]: value }));
    };

    const handleInjectRewards = async () => {
        const rewards = [];
        for (const rewardKey of Object.keys(rewardAmounts)) {
            if (rewardAmounts[rewardKey]) {
                rewards.push(
                    TokenTransfer.fungibleFromAmount(rewardKey, rewardAmounts[rewardKey], getTokenDecimals(rewardKey)),
                );
            }
        }

        await sendInjectRewardsTransaction(curNftType.injectProfile, rewards);
    };

    return (
        <div className="container" style={{ marginTop: '50px' }}>
            <div className="d-flex justify-content-center mt-4">
                <div className="vesta_x_dashboard_card" style={{ minHeight: '40rem' }}>
                    <Row style={{ rowGap: '12px' }}>
                        <Col md={4}>
                            <div className="active-pool-li-container d-flex flex-column gap-3 h-100">
                                {nftMenu.map((menu, menuId) => {
                                    if (menu.subMenu) {
                                        return (
                                            <CollapseMenu
                                                key={menuId}
                                                name={menu.name}
                                                subMenu={menu.subMenu}
                                                onClick={handleMenuClicked}
                                            />
                                        );
                                    }
                                    return (
                                        <div
                                            className="nft-staking-pool-but"
                                            key={menuId}
                                            onClick={() => handleMenuClicked(menu)}
                                        >
                                            {menu.name}
                                        </div>
                                    );
                                })}
                            </div>
                        </Col>
                        <Col md={8}>
                            <div className="active-pool-li-container h-100 d-flex flex-column">
                                <div className="nft-staking-pool-stats-box" style={{ flexGrow: '1' }}>
                                    <div className="d-flex justify-content-center">
                                        <button
                                            className="nft-staking-pool-but px-4 py-3"
                                            onClick={() => handleInjectRewards()}
                                        >
                                            Inject to {curNftType.name}
                                        </button>
                                    </div>

                                    <Row style={{ rowGap: '16px', margin: '24px 0' }}>
                                        {tokens.map((token_id, id) => (
                                            <Col sm={6} key={`token-${id}`}>
                                                <TokenInput
                                                    token_id={token_id}
                                                    balance={convertWeiToEsdt(
                                                        netstatsRedux.walletTokensMap[token_id]?.balance || '0',
                                                        getTokenDecimals(token_id),
                                                    ).toString()}
                                                    onChangeInputValue={onChangeInputValue}
                                                    value={rewardAmounts[token_id] || '0'}
                                                />
                                            </Col>
                                        ))}
                                    </Row>

                                    <div className="d-flex justify-content-end">
                                        <EsdtTokenSelect onChangeTokenId={handleSelectToken} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

interface IMenuInfo {
    name: string;
    injectProfile: number;
}

interface ICollapseMenuProps {
    name: string;
    subMenu: IMenuInfo[];
    onClick: (arg0: IMenuInfo) => void;
}

const CollapseMenu = (props: ICollapseMenuProps) => {
    const [isOpened, setIsOpened] = useState<boolean>(false);

    return (
        <div className="nft-staking-pool-stats-box d-flex flex-column">
            <div className="text-center" onClick={() => setIsOpened(!isOpened)} style={{ cursor: 'pointer' }}>
                {props.name}
                {isOpened ? <BiChevronUp /> : <BiChevronDown />}
            </div>
            <Collapse in={isOpened}>
                <div className="d-flex flex-column gap-2 mt-2">
                    {props.subMenu.map((subMenu, subMenuId) => {
                        return (
                            <div
                                className="nft-staking-pool-but"
                                key={subMenuId}
                                onClick={() => props.onClick(subMenu)}
                            >
                                {subMenu.name}
                            </div>
                        );
                    })}
                </div>
            </Collapse>
        </div>
    );
};

interface ITokenInputProps {
    token_id: string;
    balance: string;
    onChangeInputValue(token_id: string, value: string): void;
    value: string;
}
const TokenInput = ({ token_id, balance, onChangeInputValue, value }: ITokenInputProps) => {
    return (
        <div className="d-flex align-items-center gap-2 w-100">
            <img src={getTokenLogo(token_id)} alt="" width={'68px'} />

            <div className="w-100">
                <input
                    className="vesta_x_input text-right w-100"
                    onChange={(e) => {
                        onChangeInputValue(token_id, e.target.value);
                    }}
                    value={value}
                />
                <div
                    style={{ fontSize: '11px', textAlign: 'right' }}
                    onClick={() => onChangeInputValue(token_id, balance)}
                >{`Balance: ${formatNumber(balance, getTokenDecimals(token_id))}`}</div>
            </div>
        </div>
    );
};

export default NftAdmin;
