import React, { FC, useEffect, useState } from 'react';
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import {
    useGetLoginInfo,
    useGetNetworkConfig,
    useGetPendingTransactions,
} from '@multiversx/sdk-dapp/hooks';
import { IoClose } from 'react-icons/io5';
import Modal from 'react-modal';

import { selectSwap } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { elrondRemoveLiquidity, getTokenLogo } from 'z/elrond';
import { StateEnum, SwapPoolType } from 'z/types';
import {
    applySlippage,
    BIG_NUMBER_ROUNDING_MODE,
    formatNumber,
    convertEsdtToWei,
    convertWeiToEsdt,
    ERROR_CONNECT_WALLET,
    ERROR_INVALID_NUMBER,
    ERROR_NOT_ENOUGH_BALANCE,
    ERROR_POOL_INACTIVE,
    ERROR_TRANSACTION_ONGOING,
    isPositiveOrZeroBigNumber,
    parseBigNumber,
    toastError,
    ZERO_STRING,
} from 'z/utils';
import { AddLiquidityInputToken } from './AddLiquidityInputToken';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const AirbnbSlider = styled(Slider)(({ theme }) => ({
    color: '#F1DC46',
    height: 3,
    padding: '13px 0',
    '& .MuiSlider-thumb': {
        height: 20,
        width: 20,
        backgroundColor: '#1B0921',
        border: '1px solid currentColor',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: '0 0 0 8px rgba(249, 216, 94, 0.16)',
        },
        '& .airbnb-bar': {
            height: 9,
            width: 1,
            backgroundColor: 'currentColor',
            marginLeft: 1,
            marginRight: 1,
        },
    },
    '& .MuiSlider-track': {
        height: 6,
    },
    '& .MuiSlider-rail': {
        color: theme.palette.mode === 'dark' ? '#1B0921' : '#1B0921',
        // opacity: theme.palette.mode === 'dark' ? undefined : 1,
        opacity: 1,
        height: 6,
    },
    '& .MuiSlider-markLabel': {
        color: '#98A1C0',
    },
    '& .MuiSlider-mark': {
        size: 'lg',
    },
    '& .MuiSlider-markActive': {
        backgroundColor: '#F1DC46',
    },
}));

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AirbnbThumbComponentProps extends React.HTMLAttributes<unknown> {}

function AirbnbThumbComponent(props: AirbnbThumbComponentProps) {
    const { children, ...other } = props;
    return <SliderThumb {...other}>{children}</SliderThumb>;
}

const marks = [
    {
        value: 0,
        label: '0',
    },
    {
        value: 25,
        label: '25%',
    },
    {
        value: 50,
        label: '50%',
    },
    {
        value: 75,
        label: '75%',
    },
    {
        value: 100,
        label: '100%',
    },
];

interface PropsType {
    pool: SwapPoolType;
}

export const MyLiquidityRow: FC<PropsType> = ({ pool }) => {
    const { isLoggedIn } = useGetLoginInfo();
    const { chainID } = useGetNetworkConfig();
    const { hasPendingTransactions } = useGetPendingTransactions();
    const swapRedux = useAppSelector(selectSwap);

    const poolShare = parseBigNumber(pool.lp_token_balance)
        .multipliedBy(100)
        .div(pool.lp_token_supply);
    const firstTokenAmount = poolShare
        .multipliedBy(pool.first_token_reserve)
        .div(100);
    const secondTokenAmount = poolShare
        .multipliedBy(pool.second_token_reserve)
        .div(100);

    const [modalIsOpen, setIsOpen] = useState<boolean>(false);
    const [removeLpAmount, setRemoveLpAmount] = useState<string>(ZERO_STRING);

    function closeModal() {
        setIsOpen(false);
    }

    const handleSliderChange = (value: string) => {
        onChangeAmount(convertWeiToEsdt(pool.lp_token_balance, pool.lp_token_decimals, pool.lp_token_decimals).multipliedBy(value).div(100).toFixed());
    };

    function onChangeAmount(value: string) {
        if (!isPositiveOrZeroBigNumber(value)) {
            toastError(ERROR_INVALID_NUMBER);
            return;
        }
        if (convertEsdtToWei(value, pool.lp_token_decimals).comparedTo(pool.lp_token_balance) > 0) {
            toastError(ERROR_NOT_ENOUGH_BALANCE);
            return;
        }

        setRemoveLpAmount(value);
    }

    function onMaxTokenAmount() {
        onChangeAmount(convertWeiToEsdt(pool.lp_token_balance, pool.lp_token_decimals, pool.lp_token_decimals).toFixed());
    }

    async function onRemoveLiquidity() {
        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_WALLET);
            return;
        }
        if (pool.pool_state == StateEnum.Inactive) {
            toastError(ERROR_POOL_INACTIVE);
            return;
        }
        if (hasPendingTransactions) {
            toastError(ERROR_TRANSACTION_ONGOING);
            return;
        }

        await elrondRemoveLiquidity(
            pool.pool_address,
            pool.lp_token_id,
            convertEsdtToWei(removeLpAmount, pool.lp_token_decimals).toFixed(),
            applySlippage(
                firstTokenAmount.multipliedBy(convertEsdtToWei(removeLpAmount, pool.lp_token_decimals)).div(pool.lp_token_balance),
                -swapRedux.slippage
            ).toFixed(0, BIG_NUMBER_ROUNDING_MODE),
            applySlippage(
                secondTokenAmount.multipliedBy(convertEsdtToWei(removeLpAmount, pool.lp_token_decimals)).div(pool.lp_token_balance),
                -swapRedux.slippage
            ).toFixed(0, BIG_NUMBER_ROUNDING_MODE),
            chainID,
        );

        // onChangeAmount(ZERO_STRING);
        // closeModal();
    }

    useEffect(() => {
        if (hasPendingTransactions) return;
        onChangeAmount(ZERO_STRING);
        closeModal();
    }, [hasPendingTransactions]);

    return (
        <>
            <div className="active-pool-li">
                <div className="d-flex align-items-center">
                    <img
                        src={getTokenLogo(
                            pool.first_token_id,
                        )}
                        alt="logo"
                        width="50px"
                    />
                    <img
                        src={getTokenLogo(
                            pool.second_token_id,
                        )}
                        alt="logo"
                        width="50px"
                    />

                    <div
                        style={{
                            color: 'white',
                            fontSize: '1.2rem',
                            marginLeft: '10px',
                            marginRight: '10px',
                        }}
                    >
                        {pool.first_token_ticker} /{' '}
                        {pool.second_token_ticker}
                    </div>
                </div>

                <div className="active-pool-info">
                    <span>LP</span>
                    <span>
                        {formatNumber(
                            convertWeiToEsdt(
                                pool.lp_token_balance,
                                pool.lp_token_decimals,
                            ),
                        )}
                    </span>
                </div>

                <div className="active-pool-info">
                    <span>Pool Share</span>
                    <span>{formatNumber(poolShare)}%</span>
                </div>

                <div className="active-pool-info">
                    <div className="d-flex align-items-center gap-1">
                        <img
                            src={getTokenLogo(
                                pool.first_token_id,
                            )}
                            alt="logo"
                            width="18px"
                        />
                        <span
                            style={{
                                fontSize: '1rem',
                                color: '#98A1C0',
                            }}
                        >
                            {pool.first_token_ticker}
                        </span>
                    </div>
                    <span>
                        {formatNumber(
                            convertWeiToEsdt(
                                firstTokenAmount,
                                pool.first_token_decimals,
                            ),
                        )}
                    </span>
                </div>

                <div className="active-pool-info">
                    <div className="d-flex align-items-center gap-1">
                        <img
                            src={getTokenLogo(
                                pool.second_token_id,
                            )}
                            alt="logo"
                            width="18px"
                        />
                        <span
                            style={{
                                fontSize: '1rem',
                                color: '#98A1C0',
                            }}
                        >
                            {pool.second_token_ticker}
                        </span>
                    </div>
                    <span>
                        {formatNumber(
                            convertWeiToEsdt(
                                secondTokenAmount,
                                pool.second_token_decimals,
                            ),
                        )}
                    </span>
                </div>

                <div className="d-flex justify-content-center">
                    <button
                        className="vesta_x_but"
                        onClick={() => setIsOpen(true)}
                        disabled={hasPendingTransactions}
                    >
                        Remove LP
                    </button>
                </div>
            </div>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                ariaHideApp={false}
            >
                <div
                    className="select-token-modal"
                    style={{ padding: '0px 10px' }}
                >
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <img
                                src={getTokenLogo(
                                    pool.lp_token_id,
                                )}
                                alt="logo"
                                width="50px"
                            />
                            <span
                                style={{
                                    color: '#F1DC46',
                                    marginLeft: '10px',
                                    fontSize: '1.1rem',
                                }}
                            >
                                {`Remove liquidity from ${pool.first_token_ticker}-${pool.second_token_ticker} Pool`}
                            </span>
                        </div>
                        <div
                            className="modal-close-but"
                            onClick={closeModal}
                        >
                            <IoClose />
                        </div>
                    </div>

                    <AddLiquidityInputToken
                        tokenAmount={removeLpAmount}
                        onChangeTokenAmount={onChangeAmount}
                        tokenId={''}
                        tokenTicker={''}
                        tokenBalance={convertWeiToEsdt(
                            pool.lp_token_balance,
                            pool.lp_token_decimals,
                            pool.lp_token_decimals,
                        ).toFixed()}
                        onMaxTokenAmount={onMaxTokenAmount}
                        error={''}
                        hideToken={true}
                    />

                    <div
                        className="mt-2"
                        style={{
                            padding: '10px 15px 10px 10px',
                        }}
                    >
                        <AirbnbSlider
                            components={{
                                Thumb: AirbnbThumbComponent,
                            }}
                            getAriaLabel={(index: number) =>
                                index === 0
                                    ? 'Minimum Amount'
                                    : 'Maximum Amount'
                            }
                            defaultValue={0}
                            marks={marks}
                            step={1}
                            value={convertEsdtToWei(
                                removeLpAmount,
                                pool.lp_token_decimals,
                            )
                                .multipliedBy(100)
                                .div(pool.lp_token_balance)
                                .toNumber()}
                            onChange={(
                                e: any,
                                percentage: any,
                            ) =>
                                handleSliderChange(
                                    percentage,
                                )
                            }
                            disabled={
                                hasPendingTransactions
                            }
                        />
                    </div>

                    <div
                        className="mt-4 mb-1 ms-1"
                        style={{
                            color: 'white',
                            fontSize: '1rem',
                        }}
                    >
                        You will receive:
                    </div>
                    <div className="swap-info">
                        <div className="swap-info-li">
                            <div className="d-flex align-items-center gap-2">
                                <img
                                    src={getTokenLogo(
                                        pool.first_token_id,
                                    )}
                                    alt="logo"
                                    width="22px"
                                />
                                <span>
                                    {
                                        pool.first_token_ticker
                                    }
                                </span>
                            </div>
                            <div>
                                {formatNumber(
                                    applySlippage(
                                        convertWeiToEsdt(
                                            firstTokenAmount,
                                            pool.first_token_decimals,
                                            pool.first_token_decimals,
                                        )
                                            .multipliedBy(
                                                convertEsdtToWei(
                                                    removeLpAmount,
                                                    pool.lp_token_decimals,
                                                ),
                                            )
                                            .div(
                                                pool.lp_token_balance,
                                            ),
                                        -swapRedux.slippage,
                                    ),
                                )}
                            </div>
                        </div>

                        <div className="swap-info-li">
                            <div className="d-flex align-items-center gap-2">
                                <img
                                    src={getTokenLogo(
                                        pool.second_token_id,
                                    )}
                                    alt="logo"
                                    width="22px"
                                />
                                <span>
                                    {
                                        pool.second_token_ticker
                                    }
                                </span>
                            </div>
                            <div>
                                {formatNumber(
                                    applySlippage(
                                        convertWeiToEsdt(
                                            secondTokenAmount,
                                            pool.second_token_decimals,
                                            pool.second_token_decimals,
                                        )
                                            .multipliedBy(
                                                convertEsdtToWei(
                                                    removeLpAmount,
                                                    pool.lp_token_decimals,
                                                ),
                                            )
                                            .div(
                                                pool.lp_token_balance,
                                            ),
                                        -swapRedux.slippage,
                                    ),
                                )}
                            </div>
                        </div>
                    </div>

                    <div
                        className="mt-4 vesta_x_but"
                        style={{
                            border: '1px solid rgba(255, 255, 255, 0.2)',
                        }}
                        onClick={onRemoveLiquidity}
                    >
                        Remove LP
                    </div>
                </div>
            </Modal>
        </>
    );
};
