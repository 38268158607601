import { PoolType } from 'z/types';
import { config } from './elrond';
import { USDC_TOKEN_ID, USDD_TOKEN_ID, USDT_TOKEN_ID } from './tokens';

export const SWAP_ROUTER_SC_ADDRESS = config.contracts['dex-router'].address;
export const ADD_LIQUIDITY_GAS_LIMIT = 20_000_000;
export const REMOVE_LIQUIDITY_GAS_LIMIT = 20_000_000;
export const MULTI_PAIR_SWAP_GAS_LIMIT = 150_000_000;

export const CREATE_PAIR_GAS_LIMIT = 20_000_000;
export const ISSUE_LP_TOKEN_GAS_LIMIT = 100_000_000;
export const SET_PAIR_STATE_TOKEN_GAS_LIMIT = 50_000_000;

export const DEFAULT_TOTAL_FEE = 2; // 2%
export const DEFAULT_SPECIAL_FEE = 1.7;
export const MAX_FEE = 5; // 5%

export const FEE_DENOMINATOR = 10000;

const data: PoolType[] = config.contracts.pools as PoolType[];

const transformPoolsData = (pools: PoolType[]): string[][] => {
  return pools.map(pool => [pool.firstToken, pool.secondToken]);
};

export const POOL_SWAP_ORDER = transformPoolsData(data);

export function findLpTokenByAddress(searchAddress: string): string | undefined {
  const foundPool = data.find(pool => pool.address === searchAddress);
  return foundPool?.lpToken;
}

// stableswap
export const USDC_USDT_USDD_POOL_ADDRESS = config.contracts.stable_pool.address;
export const STABLESWAP_TOKENS = [
  USDC_TOKEN_ID,
  USDT_TOKEN_ID,
  USDD_TOKEN_ID,
];
export const STABLESWAP_LP_TOKEN = config.contracts.stable_pool.lpToken;
