import React, { useEffect, useState } from 'react';

import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import { parseAmount } from '@multiversx/sdk-dapp/utils';
import { Row, Col } from 'react-bootstrap';

import { OURO_TOKEN_ID } from 'config';
import { selectNetstats } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import {
    extractTokenFromSlip,
    injectOuroIntoSlip,
    setSlipVestaRedirectLpAddress,
    setSlipVestaVaultLpAddress,
} from 'z/elrond';
import { useSlipHooks } from 'z/hooks/slip';
import { convertWeiToEsdt, formatNumber } from 'z/utils';

enum ShowDepositOrWithdrawEnum {
    Deposit = 'Deposit',
    Withdraw = 'Withdraw',
}

export const ManageFunds = () => {
    const [poolsLength, setPoolLength] = useState(0);

    const netstatsRedux = useAppSelector(selectNetstats);
    const { pools, context, ouroAmount: slipOuroAmount } = useSlipHooks();

    useEffect(() => {
        setPoolLength(pools.length + 1);
    }, [pools]);

    const { address } = useGetAccount();
    const [accountOuroAmount, setAccountOuroAmount] = useState('0');

    useEffect(() => {
        setAccountOuroAmount(netstatsRedux.walletTokensMap[OURO_TOKEN_ID]?.balance || '0');
    }, [address, netstatsRedux.walletTokensMap]);

    const [newVestaRedirectLpAddress, setNewVestaRedirectLpAddress] = useState('');
    const [newVestaVaultLpAddress, setNewVestaVaultLpAddress] = useState('');
    const [amount, setAmount] = useState('0');

    useEffect(() => {
        if (context) {
            setNewVestaRedirectLpAddress(context.vesta_redirect_lp_address);
            setNewVestaVaultLpAddress(context.vesta_vault_lp_address);
        }
    }, [context]);

    const [showDepositOrWithdraw, setButtonType] = useState<ShowDepositOrWithdrawEnum>(
        ShowDepositOrWithdrawEnum.Deposit,
    );

    function onNext() {
        setButtonType(ShowDepositOrWithdrawEnum.Withdraw);
    }

    function onPrev() {
        setButtonType(ShowDepositOrWithdrawEnum.Deposit);
    }

    const handleFunds = async () => {
        switch (showDepositOrWithdraw) {
            case ShowDepositOrWithdrawEnum.Deposit:
                return await injectOuroIntoSlip(parseAmount(amount, 18));
            case ShowDepositOrWithdrawEnum.Withdraw:
                return await extractTokenFromSlip(OURO_TOKEN_ID, parseAmount(amount, 18));
        }
    };

    return (
        <Col md={12}>
            <div className="slip-admin-panel-box">
                <Row className="align-items-center gy-4">
                    <Col
                        md={12}
                        style={{
                            fontSize: '14px',
                            color: '#98A1C0',
                        }}
                    >
                        Set Current Slip Target ERD:
                    </Col>

                    <Col md={8}>
                        <input
                            className="vesta_x_input text-right mt-1"
                            placeholder={`erd1xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx`}
                            value={newVestaRedirectLpAddress}
                            onChange={(e) => setNewVestaRedirectLpAddress(e.target.value)}
                        />
                    </Col>
                    <Col md={4}>
                        <button
                            className="slip-but w-100"
                            onClick={() => setSlipVestaRedirectLpAddress(newVestaRedirectLpAddress)}
                        >
                            Set
                        </button>
                    </Col>

                    <Col
                        md={12}
                        style={{
                            fontSize: '14px',
                            color: '#98A1C0',
                        }}
                    >
                        Set Vesta Vault ERD:
                    </Col>

                    <Col md={8}>
                        <input
                            className="vesta_x_input text-right mt-1"
                            placeholder={`erd1xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx`}
                            value={newVestaVaultLpAddress}
                            onChange={(e) => setNewVestaVaultLpAddress(e.target.value)}
                        />
                    </Col>
                    <Col md={4}>
                        <button
                            className="slip-but w-100"
                            onClick={() => setSlipVestaVaultLpAddress(newVestaVaultLpAddress, poolsLength)}
                        >
                            Set
                        </button>
                    </Col>

                    <Col md={8}>
                        <input
                            className="vesta_x_input text-right mt-1"
                            type="number"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                        />

                        <div className="d-flex justify-content-between mt-1">
                            <div className="input-token-error">{/* {amountError} */}</div>
                            <div
                                className="add-liquidity-input-token-balance-box"
                                onClick={() =>
                                    setAmount(
                                        convertWeiToEsdt(
                                            showDepositOrWithdraw === ShowDepositOrWithdrawEnum.Deposit
                                                ? accountOuroAmount
                                                : slipOuroAmount,
                                            18,
                                            18,
                                        ).toFixed(),
                                    )
                                }
                            >
                                <div className="">Balance:&nbsp;</div>
                                <div
                                    style={{
                                        color: 'white',
                                    }}
                                >
                                    {formatNumber(
                                        convertWeiToEsdt(
                                            showDepositOrWithdraw === ShowDepositOrWithdrawEnum.Deposit
                                                ? accountOuroAmount
                                                : slipOuroAmount,
                                            18,
                                            18,
                                        ),
                                        18,
                                    )}
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col md={4}>
                        <div className="d-flex w-100">
                            <button
                                className="slip-but"
                                style={{ width: '15%' }}
                                onClick={onPrev}
                                disabled={showDepositOrWithdraw === ShowDepositOrWithdrawEnum.Deposit}
                            >
                                {'<'}
                            </button>
                            <button className="slip-but w-60" onClick={handleFunds}>
                                {showDepositOrWithdraw}
                            </button>
                            <button
                                className="slip-but"
                                style={{ width: '15%' }}
                                onClick={onNext}
                                disabled={showDepositOrWithdraw === ShowDepositOrWithdrawEnum.Withdraw}
                            >
                                {'>'}
                            </button>
                        </div>
                    </Col>
                </Row>
            </div>
        </Col>
    );
};
