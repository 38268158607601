export interface FarmType {
    index: number;
    //TODO: remove obsolete code usage using the following 4 fields
    reward_distribution_timestamp: number;
    reward_distribution_epoch: number;
    max_nonce_to_receive_rewards: number;
    last_nonce_with_old_attributes: number;
    pool_address: string;
    pool_lp_token: string;
    farm_address: string;
    staked_lp_token: string;
    pool_density: number;
    tier: string;
}

export interface NonFungibleTokenBalanceApiType {
    collection: string;
    ticker: string;
    balance: string;
    decimals: number;
    nonce: number;
    attributes: string;
}

export interface NonFungibleTokenBalanceType extends NonFungibleTokenBalanceApiType {
    stake_epoch?: number,
    unbonding_start_epoch?: number,
    stake_token_type?: StakedLpTokenType
}

export type NonFungibleTokenInfoMapType = Record<
    string,
    NonFungibleTokenBalanceType[]
>;

export interface TokenSupplyType {
    supply: string;
    circulatingSupply: string;
    minted: string;
    burnt: string;
    initialMinted: string;
}

export enum StakedLpTokenType {
    Bronze = 1,
    Silver = 2,
    Gold = 3,
    UnbondingBronze = 4,
    UnbondingSilver = 5,
    UnbondingGold = 6
}

export interface LpTokenContextType {
    token_nonce: number,
    amount: string,
    token_type_id: number,
    stake_epoch: number,
    unbonding_epoch: number,
    mint_timestamp: number,
    unbonding_claim_epoch: number,
    can_be_unstaked: boolean,
    can_be_claimed: boolean,
    fast_unbonding_fee_perc: number,
}

export interface WeightCalculationContextType {
    lp_contract_address: string,
    total_issued_vlp: string,
    total_issued_vlp_weight: string,
    total_bronze_lps: string,
    total_silver_lps: string,
    total_gold_lps: string,
    total_unbonding_bronze_lps: string,
    total_unbonding_silver_lps: string,
    total_unbonding_gold_lps: string,
}

export enum VestingTypeEnum {
    Invalid,
    Vesta,
    SleepingVesta,
    FrozenVesta
}

export interface FarmParametersType {
    is_tier_x: boolean,
    diamond_multiplier: number,
    tier_multiplier: number,
    pool_weight: number,
    liquidity_multiplier: number,
    personal_multiplier: number,
    total_multiplier: number,
}

export interface StakedNFTsUIStatisticsType{
    total_staked_lm_nfts_bronze: number,
    total_staked_lm_nfts_silver: number,
    total_staked_lm_nfts_gold: number,
    total_staked_pm_nfts_bronze_by_user: number,
    total_staked_pm_nfts_silver_by_user: number,
    total_staked_pm_nfts_gold_by_user: number,
    total_pm_staked_bronze_by_everyone: number,
    total_pm_staked_silver_by_everyone: number,
    total_pm_staked_gold_by_everyone: number,
    unstake_timestamp: number,
    can_unstake: boolean,
}

export interface LockedLpAttributesType {
    stake_epoch: number,
    locked_lp_type_id: number,
    unbonding_start_epoch: number,
}

export interface NewLockedLpAttributesType extends LockedLpAttributesType {
    stake_epoch: number,
    locked_lp_type_id: number,
    unbonding_start_epoch: number,
    mint_timestamp: number,
}

export interface FarmTokenType {
    collection: string,
    nonce: number,
    identifier: string,
    decimals: number,
    amount: string,
    attributes: NewLockedLpAttributesType,
}
