import React, { useEffect, useState } from 'react';
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { TokenTransfer } from '@multiversx/sdk-core/out';
import {
    useGetAccount,
    useGetPendingTransactions,
} from '@multiversx/sdk-dapp/hooks';
import clsx from 'clsx';
import { IoClose } from 'react-icons/io5';
import Modal from 'react-modal';

import { selectSwap } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { getTokenDecimals, getTokenLogo } from 'z/elrond';
import { StableswapContract, StableswapPool } from 'z/elrond/stableswap';
import { BigNumber } from 'z/types';
import {
    applySlippage,
    formatNumber,
    convertEsdtToWei,
    convertWeiToEsdt,
    ERROR_CONNECT_WALLET,
    ERROR_INVALID_NUMBER,
    ERROR_NOT_ENOUGH_BALANCE,
    ERROR_TRANSACTION_ONGOING,
    isPositiveOrZeroBigNumber,
    toastError,
    ZERO_STRING,
    DEFAULT_DECIMALS,
    createTokenTicker,
    floorBN,
} from 'z/utils';
import { AddLiquidityInputToken } from './AddLiquidityInputToken';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const AirbnbSlider = styled(Slider)(({ theme }) => ({
    color: '#F1DC46',
    height: 3,
    padding: '13px 0',
    '& .MuiSlider-thumb': {
        height: 20,
        width: 20,
        backgroundColor: '#1B0921',
        border: '1px solid currentColor',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: '0 0 0 8px rgba(249, 216, 94, 0.16)',
        },
        '& .airbnb-bar': {
            height: 9,
            width: 1,
            backgroundColor: 'currentColor',
            marginLeft: 1,
            marginRight: 1,
        },
    },
    '& .MuiSlider-track': {
        height: 6,
    },
    '& .MuiSlider-rail': {
        color: theme.palette.mode === 'dark' ? '#1B0921' : '#1B0921',
        // opacity: theme.palette.mode === 'dark' ? undefined : 1,
        opacity: 1,
        height: 6,
    },
    '& .MuiSlider-markLabel': {
        color: '#98A1C0',
    },
    '& .MuiSlider-mark': {
        size: 'lg',
    },
    '& .MuiSlider-markActive': {
        backgroundColor: '#F1DC46',
    },
}));

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AirbnbThumbComponentProps extends React.HTMLAttributes<unknown> {}

function AirbnbThumbComponent(props: AirbnbThumbComponentProps) {
    const { children, ...other } = props;
    return <SliderThumb {...other}>{children}</SliderThumb>;
}

const marks = [
    {
        value: 0,
        label: '0',
    },
    {
        value: 25,
        label: '25%',
    },
    {
        value: 50,
        label: '50%',
    },
    {
        value: 75,
        label: '75%',
    },
    {
        value: 100,
        label: '100%',
    },
];

enum RemoveLiquidityType {
    AllTokens = 'All Tokens',
    USDCOnly = 'USDC Only',
    USDTOnly = 'USDT Only',
    USDDOnly = 'USDD Only',
}
const removeLiquidityTypes = [
    RemoveLiquidityType.AllTokens,
    RemoveLiquidityType.USDCOnly,
    RemoveLiquidityType.USDTOnly,
    RemoveLiquidityType.USDDOnly,
];

export const StableMyLiquidityRow = ({
    pool,
    lpTokenBalance,
} : {
    pool: StableswapPool,
    lpTokenBalance: BigNumber,
}) => {
    const { address } = useGetAccount();
    const { hasPendingTransactions } = useGetPendingTransactions();
    const swapRedux = useAppSelector(selectSwap);

    const poolShare = lpTokenBalance
        .multipliedBy(100)
        .div(pool.lp_token_supply);

    const [modalIsOpen, setIsOpen] = useState<boolean>(false);
    const [removeLpAmount, setRemoveLpAmount] = useState<string>(ZERO_STRING);

    function closeModal() {
        setIsOpen(false);
    }

    const handleSliderChange = (value: string) => {
        onChangeAmount(convertWeiToEsdt(lpTokenBalance, DEFAULT_DECIMALS, DEFAULT_DECIMALS).multipliedBy(value).div(100).toFixed());
    };

    function onChangeAmount(value: string) {
        if (!isPositiveOrZeroBigNumber(value)) {
            toastError(ERROR_INVALID_NUMBER);
            return;
        }
        if (convertEsdtToWei(value, DEFAULT_DECIMALS).comparedTo(lpTokenBalance) > 0) {
            toastError(ERROR_NOT_ENOUGH_BALANCE);
            return;
        }

        setRemoveLpAmount(value);
    }

    function onMaxTokenAmount() {
        onChangeAmount(convertWeiToEsdt(lpTokenBalance, DEFAULT_DECIMALS, DEFAULT_DECIMALS).toFixed());
    }

    const [userTokenReserves, setUserTokenReserves] = useState<Array<BigNumber | undefined>>([undefined, undefined, undefined]);
    useEffect(() => {
        if (pool.lp_token_supply.isLessThan(0)) {
            setUserTokenReserves([undefined, undefined, undefined]);
        } else {
            setUserTokenReserves(pool.token_balances.map(
                (tokenBalance) => tokenBalance.multipliedBy(lpTokenBalance).div(pool.lp_token_supply)
            ));
        }
    }, [pool, lpTokenBalance]);

    const [receiveType, setReceiveType] = useState<RemoveLiquidityType>(RemoveLiquidityType.AllTokens);
    const [expectedRemoveAmounts, setExpectedRemoveAmounts] = useState<BigNumber[]>([new BigNumber(0), new BigNumber(0), new BigNumber(0)]);
    const [efficiency, setEfficiency] = useState<number>(0);
    useEffect(() => {
        (async () => {
            const _amounts = pool.token_balances.map((_tokenBalance) => _tokenBalance.multipliedBy(
                convertEsdtToWei(
                    removeLpAmount,
                    DEFAULT_DECIMALS,
                ),
            ).div(pool.lp_token_supply));
            let _maxSum = new BigNumber(0);
            for (let i = 0; i < pool.token_ids.length; i++) {
                _maxSum = _maxSum.plus(_amounts[i].multipliedBy(Math.pow(10, DEFAULT_DECIMALS - getTokenDecimals(pool.token_ids[i]))));
            }

            if (receiveType == RemoveLiquidityType.AllTokens) {
                setExpectedRemoveAmounts(_amounts);
                setEfficiency(100);
            } else {
                const tokenIndex = receiveType == RemoveLiquidityType.USDCOnly ? 0 : receiveType == RemoveLiquidityType.USDTOnly ? 1 : 2;
                const tokenOut = pool.token_ids[tokenIndex];
                const _amountOut = await contract.calcWithdrawOneToken(convertEsdtToWei(removeLpAmount, DEFAULT_DECIMALS), tokenOut);
                const _amountOuts = [new BigNumber(0), new BigNumber(0), new BigNumber(0)];
                _amountOuts[tokenIndex] = _amountOut ?? new BigNumber(0);
                setExpectedRemoveAmounts(_amountOuts);

                let _sum = new BigNumber(0);
                for (let i = 0; i < pool.token_ids.length; i++) {
                    _sum = _sum.plus(_amountOuts[i].multipliedBy(Math.pow(10, DEFAULT_DECIMALS - getTokenDecimals(pool.token_ids[i]))));
                }
                setEfficiency(_sum.multipliedBy(100).div(_maxSum).toNumber());
            }
        })();
    }, [receiveType, removeLpAmount]);

    const contract = new StableswapContract();
    async function onRemoveLiquidity() {
        if (!address) {
            toastError(ERROR_CONNECT_WALLET);
            return;
        }
        if (hasPendingTransactions) {
            toastError(ERROR_TRANSACTION_ONGOING);
            return;
        }

        const paymentIn = TokenTransfer.fungibleFromAmount(
            pool.lp_token_id,
            removeLpAmount,
            DEFAULT_DECIMALS
        );
        const minAmountOuts = pool.token_ids.map((_tokenId, i) => floorBN(
            applySlippage(
                expectedRemoveAmounts[i],
                -swapRedux.slippage,
            )
        ));
        if (receiveType == RemoveLiquidityType.AllTokens) {
            await contract.removeLiquidity(
                paymentIn,
                minAmountOuts,
                address
            );
        } else {
            const tokenIndex = receiveType == RemoveLiquidityType.USDCOnly ? 0 : receiveType == RemoveLiquidityType.USDTOnly ? 1 : 2;
            const tokenOut = pool.token_ids[tokenIndex];
            await contract.removeLiquidityOneToken(
                paymentIn,
                tokenOut,
                minAmountOuts[tokenIndex],
                address,
            );
        }

        onChangeAmount(ZERO_STRING);
        closeModal();
    }

    useEffect(() => {
        if (hasPendingTransactions) return;
        onChangeAmount(ZERO_STRING);
        closeModal();
    }, [hasPendingTransactions]);

    const poolName = `${createTokenTicker(pool.token_ids[0])}-${createTokenTicker(pool.token_ids[1])}-${createTokenTicker(pool.token_ids[2])}`;
    return (
        <>
            <div className="active-pool-li">
                <div className="d-flex flex-column align-items-center gap-1">
                    <div className='d-flex gap-1'>
                        {
                            pool.token_ids.map((tokenId, i) => <img
                                key={i}
                                src={getTokenLogo(tokenId)}
                                alt="logo"
                                width="32rem"
                            />)
                        }
                    </div>

                    <div
                        style={{
                            color: 'white',
                            fontSize: '1.1rem',
                            marginLeft: '10px',
                            marginRight: '10px',
                        }}
                    >
                        {poolName}
                    </div>
                </div>

                <div className="active-pool-info">
                    <span>LP</span>
                    <span>
                        {formatNumber(
                            convertWeiToEsdt(
                                lpTokenBalance,
                                DEFAULT_DECIMALS,
                            ),
                        )}
                    </span>
                </div>

                <div className="active-pool-info">
                    <span>Pool Share</span>
                    <span>{formatNumber(poolShare)}%</span>
                </div>

                <div className='active-pool-info'>
                    {
                        pool.token_ids.map((tokenId, i) => <div className="d-flex flex-column" key={i}>
                            <div className="d-flex align-items-center gap-1">
                                <img
                                    src={getTokenLogo(tokenId)}
                                    alt="logo"
                                    width="18px"
                                />
                                <span>
                                    {userTokenReserves[i] ? formatNumber(
                                        convertWeiToEsdt(
                                            userTokenReserves[i],
                                            getTokenDecimals(tokenId),
                                        ),
                                    ) : '-'}
                                </span>
                            </div>
                            
                        </div>)
                    }
                </div>

                <div className="d-flex justify-content-center">
                    <button
                        className="vesta_x_but"
                        onClick={() => setIsOpen(true)}
                        disabled={hasPendingTransactions}
                    >
                        Remove LP
                    </button>
                </div>
            </div>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                ariaHideApp={false}
            >
                <div
                    className="select-token-modal"
                    style={{ padding: '0px 10px' }}
                >
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <img
                                src={getTokenLogo(
                                    pool.lp_token_id,
                                )}
                                alt="logo"
                                width="50px"
                            />
                            <span
                                style={{
                                    color: '#F1DC46',
                                    marginLeft: '10px',
                                    fontSize: '1.1rem',
                                }}
                            >
                                {`Remove liquidity from ${poolName} Pool`}
                            </span>
                        </div>
                        <div
                            className="modal-close-but"
                            onClick={closeModal}
                        >
                            <IoClose />
                        </div>
                    </div>

                    <AddLiquidityInputToken
                        tokenAmount={removeLpAmount}
                        onChangeTokenAmount={onChangeAmount}
                        tokenId={''}
                        tokenTicker={''}
                        tokenBalance={convertWeiToEsdt(
                            lpTokenBalance,
                            DEFAULT_DECIMALS,
                            DEFAULT_DECIMALS,
                        ).toFixed()}
                        onMaxTokenAmount={onMaxTokenAmount}
                        error={''}
                        hideToken={true}
                    />

                    <div
                        className="mt-2"
                        style={{
                            padding: '10px 15px 10px 10px',
                        }}
                    >
                        <AirbnbSlider
                            components={{
                                Thumb: AirbnbThumbComponent,
                            }}
                            getAriaLabel={(index: number) =>
                                index === 0
                                    ? 'Minimum Amount'
                                    : 'Maximum Amount'
                            }
                            defaultValue={0}
                            marks={marks}
                            step={1}
                            value={convertEsdtToWei(
                                removeLpAmount,
                                DEFAULT_DECIMALS,
                            )
                                .multipliedBy(100)
                                .div(lpTokenBalance)
                                .toNumber()}
                            onChange={(
                                e: any,
                                percentage: any,
                            ) =>
                                handleSliderChange(
                                    percentage,
                                )
                            }
                            disabled={
                                hasPendingTransactions
                            }
                        />
                    </div>

                    <div
                        className="mt-4 mb-1 ms-1"
                        style={{
                            color: 'white',
                            fontSize: '1rem',
                        }}
                    >
                        You will receive:
                    </div>

                    <div className="d-flex justify-content-center my-4">
                        <div className="receive-token-but-group">
                            {
                                removeLiquidityTypes.map((type, i) => <div
                                    key={i}
                                    className={clsx({ 'receive-token-but': true, selected: receiveType === type })}
                                    onClick={() => setReceiveType(type)}
                                >
                                    {type}
                                </div>)
                            }
                        </div>
                    </div>
                    
                    <div className="swap-info">
                        {
                            pool.token_ids.map((tokenId, i) => <div key={i} className="swap-info-li">
                                <div className="d-flex align-items-center gap-2">
                                    <img
                                        src={getTokenLogo(tokenId)}
                                        alt="logo"
                                        width="22px"
                                    />
                                    <span>{tokenId}</span>
                                </div>
                                <div>
                                    {formatNumber(
                                        convertWeiToEsdt(
                                            expectedRemoveAmounts[i],
                                            getTokenDecimals(tokenId),
                                            getTokenDecimals(tokenId),
                                        )
                                    )}
                                </div>
                            </div>)
                        }
                    </div>

                    <div className="swap-info mt-3">
                        <div className="swap-info-li">
                            <div>Efficiency:</div>
                            <div>
                                {formatNumber(efficiency, 2)}%
                            </div>
                        </div>
                    </div>

                    <div
                        className="mt-4 vesta_x_but"
                        style={{
                            border: '1px solid rgba(255, 255, 255, 0.2)',
                        }}
                        onClick={onRemoveLiquidity}
                    >
                        Remove LP
                    </div>
                </div>
            </Modal>
        </>
    );
};
