import React, { useEffect, useState } from 'react';
import { useGetAccountInfo, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import { Row, Col } from 'react-bootstrap';
import {
    AURYN_TOKEN_ID,
    DEMI_METAESDT_IDS,
    DEMI_VESTED_AURYN_INDEX,
    DEMI_VESTED_EAURYN_INDEX,
    DEMI_VESTED_OURO_INDEX,
    EAURYN_TOKEN_ID,
    OURO_TOKEN_ID,
} from 'config';
import { selectNetstats } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { getAccountNftsByCollections } from 'z/elrond';
import { BIG_NUMBER_ZERO, convertWeiToEsdt, formatNumber, parseBigNumber } from 'z/utils';

export const WalletBalanceRow = () => {
    const netstatsRedux = useAppSelector(selectNetstats);
    const { address } = useGetAccountInfo();
    const { hasPendingTransactions } = useGetPendingTransactions();
    const [demiMetaBalances, setDemiMetaBalancess] = useState<string[]>([]);

    useEffect(() => {
        if (!address || hasPendingTransactions) return;

        (async () => {
            const _metas = await getAccountNftsByCollections(address, DEMI_METAESDT_IDS);

            const _balances: string[] = [];
            for (const row of _metas) {
                let sum = BIG_NUMBER_ZERO;
                for (const nft of row) {
                    sum = sum.plus(nft.balance);
                }
                _balances.push(sum.toFixed(0));
            }
            setDemiMetaBalancess(_balances);
        })();
    }, [address, hasPendingTransactions]);

    return (
        <Row className="second-card-style mt-4 gy-4 pt-0">
            <div className="text-center mb-3" style={{ color: '#F1DC46', fontSize: '1.25rem' }}>
                Your Demiourgos Snake Tokens Wallet Balance
            </div>

            <Col md={4}>
                <div className="second-card-style">
                    <div className="d-flex text-right">
                        <div className="w-50">OUROBOROS:</div>
                        <div className="w-50">
                            {formatNumber(
                                convertWeiToEsdt(
                                    parseBigNumber(netstatsRedux.walletTokensMap[OURO_TOKEN_ID]?.balance ?? 0),
                                ),
                                3,
                            )}
                        </div>
                    </div>
                    <div className="d-flex text-right">
                        <div className="w-50">Reserved OUROBOROS:</div>
                        <div className="w-50">0.000</div>
                    </div>
                    <div className="d-flex text-right">
                        <div className="w-50">Vested OUROBOROS:</div>
                        <div className="w-50">
                            {demiMetaBalances[DEMI_VESTED_OURO_INDEX]
                                ? formatNumber(convertWeiToEsdt(demiMetaBalances[DEMI_VESTED_OURO_INDEX]), 3)
                                : formatNumber(0, 3)}
                        </div>
                    </div>
                    <div className="d-flex text-right">
                        <div className="w-50">Total OUROBOROS:</div>
                        <div className="w-50">
                            {formatNumber(
                                convertWeiToEsdt(
                                    parseBigNumber(netstatsRedux.walletTokensMap[OURO_TOKEN_ID]?.balance ?? 0),
                                ).toNumber() + convertWeiToEsdt(demiMetaBalances[DEMI_VESTED_OURO_INDEX]).toNumber(),
                                3,
                            )}
                        </div>
                    </div>
                </div>
            </Col>

            <Col md={4}>
                <div className="second-card-style">
                    <div className="d-flex text-right">
                        <div className="w-50">AURYN:</div>
                        <div className="w-50">
                            {formatNumber(
                                convertWeiToEsdt(
                                    parseBigNumber(netstatsRedux.walletTokensMap[AURYN_TOKEN_ID]?.balance ?? 0),
                                ),
                                3,
                            )}
                        </div>
                    </div>
                    <div className="d-flex text-right invisible">
                        <div className="w-50">Reserved AURYN:</div>
                        <div className="w-50">0.000</div>
                    </div>
                    <div className="d-flex text-right">
                        <div className="w-50">Vested AURYN:</div>
                        <div className="w-50">
                            {demiMetaBalances[DEMI_VESTED_AURYN_INDEX]
                                ? formatNumber(convertWeiToEsdt(demiMetaBalances[DEMI_VESTED_AURYN_INDEX]), 3)
                                : formatNumber(0, 3)}
                        </div>
                    </div>
                    <div className="d-flex text-right">
                        <div className="w-50">Total AURYN:</div>
                        <div className="w-50">
                            {formatNumber(
                                convertWeiToEsdt(
                                    parseBigNumber(netstatsRedux.walletTokensMap[AURYN_TOKEN_ID]?.balance ?? 0),
                                ).toNumber() + convertWeiToEsdt(demiMetaBalances[DEMI_VESTED_AURYN_INDEX]).toNumber(),
                                3,
                            )}
                        </div>
                    </div>
                </div>
            </Col>

            <Col md={4}>
                <div className="second-card-style">
                    <div className="d-flex text-right">
                        <div className="w-50">Elite-AURYN:</div>
                        <div className="w-50">
                            {formatNumber(
                                convertWeiToEsdt(
                                    parseBigNumber(netstatsRedux.walletTokensMap[EAURYN_TOKEN_ID]?.balance ?? 0),
                                ),
                                3,
                            )}
                        </div>
                    </div>
                    <div className="d-flex text-right invisible">
                        <div className="w-50">Reserved Elite-AURYN:</div>
                        <div className="w-50">0.000</div>
                    </div>
                    <div className="d-flex text-right">
                        <div className="w-50">Vested Elite-AURYN:</div>
                        <div className="w-50">
                            {demiMetaBalances[DEMI_VESTED_EAURYN_INDEX]
                                ? formatNumber(convertWeiToEsdt(demiMetaBalances[DEMI_VESTED_EAURYN_INDEX]), 3)
                                : formatNumber(0, 3)}
                        </div>
                    </div>
                    <div className="d-flex text-right">
                        <div className="w-50">Total Elite-AURYN:</div>
                        <div className="w-50">
                            {formatNumber(
                                convertWeiToEsdt(
                                    parseBigNumber(netstatsRedux.walletTokensMap[EAURYN_TOKEN_ID]?.balance ?? 0),
                                ).toNumber() + convertWeiToEsdt(demiMetaBalances[DEMI_VESTED_EAURYN_INDEX]).toNumber(),
                                3,
                            )}
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
};
