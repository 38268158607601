import { AbiRegistry, Address, SmartContract } from '@multiversx/sdk-core/out';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import {
    SWAP_ROUTER_SC_ADDRESS,
    MVX_ACCESS_TIMEOUT,
    MVX_GATEWAY_URL,
    // MVX_API_URL,
    RAW_VESTA_MINTER_SC_ADDRESS,
    LIQUID_LOCKER_SC_ADDRESS,
    ELITE_ACCOUNT_TRACKER_SC_ADDRESS,
    SNAKE_COIL_SC_ADDRESS,
    SLIP_SC_ADDRESS,
    LIQUIDITY_VESTA_VALULT_SC_ADDRESS,
    MEME_LIQUIDITY_VESTA_VALULT_SC_ADDRESS,
    VEGLD_VAULT_SC_ADDRESS,
    FAUCET_SC_ADDRESS,
    OURO_FACTORY_SC_ADDRESS,
    IS_DEV,
    NFT_STAKING_V2_SC_ADDRESS,
    STABLE_COIN_PROTOCOL_SC_ADDRESS,
} from 'config';
import { NFT_STAKING_SC_ADDRESS } from 'config/nft-staking';
import eliteAccountTrackerAbiJson from './abi/elite-account-tracker.abi.json';
import liquidLockerAbi from './abi/liquid-locking.abi.json';
import liquidityVestaVaultAbi from './abi/liquidity-vesta-vault.abi.json';
import memeLiquidityVestaVaultAbi from './abi/meme-liquidity-vesta-vault.abi.json';
import nftStakingV2AbiJson from './abi/nft-staking-v2.abi.json';
import nftStakingAbiJson from './abi/nft-staking.abi.json';
import ouroFactoryJson from './abi/ouro-factory.abi.json';
import rawVestaMinterAbi from './abi/raw-vesta-minter.abi.json';
import snakeCoilAbiJson from './abi/snake-coil.abi.json';
import stableCoinProtocolAbiJson from './abi/stablecoin-minter.abi.json';
import vegldVaultAbi from './abi/vegld-vault.abi.json';
import farmAbiJson from './abi/vesta-lp-farm.abi.json';
import slipAbiJson from './abi/vesta-slip-sc.abi.json';
import vestaVaultAbi from './abi/vesta-vault.abi.json';
import stakingAbiJson from './abi/vesta-x-staking-v1.abi.json';
import swapPairAbiJson from './abi/vesta-x-swap-pair.abi.json';
import swapRouterAbiJson from './abi/vesta-x-swap-router.abi.json';
import faucetAbiJson from './abi/vesta_x_faucet.abi.json';

export const elrondProvider = new ProxyNetworkProvider(MVX_GATEWAY_URL, { timeout: MVX_ACCESS_TIMEOUT });
// export const elrondProvider = new ProxyNetworkProvider(MVX_API_URL, { timeout: MVX_ACCESS_TIMEOUT });

export function createSmartContract(abiJson: any, scAddress?: string): SmartContract {
    const abiRegistry = AbiRegistry.create(abiJson);
    return new SmartContract({
        address: scAddress ? new Address(scAddress) : undefined,
        abi: abiRegistry,
    });
}

export const swapRouterSmartContract = createSmartContract(swapRouterAbiJson, SWAP_ROUTER_SC_ADDRESS);
export const swapPairSmartContract = createSmartContract(swapPairAbiJson);

export const createFarmSmartContract = (address: string) => createSmartContract(farmAbiJson, address);
export const createStakingSmartContract = (address: string) => createSmartContract(stakingAbiJson, address);

export const farmAbiRegistry = AbiRegistry.create(farmAbiJson);
export const rawVestaMinterSmartContract = createSmartContract(rawVestaMinterAbi, RAW_VESTA_MINTER_SC_ADDRESS);
export const liquidLockerSmartContract = createSmartContract(liquidLockerAbi, LIQUID_LOCKER_SC_ADDRESS);
export const esdtTokenPaymentType = AbiRegistry.create(liquidLockerAbi).getStruct('EsdtTokenPayment');
export const slipSmartContract = createSmartContract(slipAbiJson, SLIP_SC_ADDRESS);
export const vestaVaultSmartContract = createSmartContract(vestaVaultAbi);
export const liquidityVestaVaultSmartContract = createSmartContract(
    liquidityVestaVaultAbi,
    LIQUIDITY_VESTA_VALULT_SC_ADDRESS,
);
export const memeLiquidityVestaVaultSmartContract = createSmartContract(
    memeLiquidityVestaVaultAbi,
    MEME_LIQUIDITY_VESTA_VALULT_SC_ADDRESS,
);
export const vegldVaultSmartContract = createSmartContract(vegldVaultAbi, VEGLD_VAULT_SC_ADDRESS);

export const eliteAccountTrackerSmartContract = createSmartContract(
    eliteAccountTrackerAbiJson,
    ELITE_ACCOUNT_TRACKER_SC_ADDRESS,
);
export const snakeCoilSmartContract = createSmartContract(snakeCoilAbiJson, SNAKE_COIL_SC_ADDRESS);
export const nftStakingSmartContract = createSmartContract(nftStakingAbiJson, NFT_STAKING_SC_ADDRESS);
export const ouroFactorySmartContract = createSmartContract(ouroFactoryJson, OURO_FACTORY_SC_ADDRESS);
export const faucetSmartContract = IS_DEV
    ? createSmartContract(faucetAbiJson, FAUCET_SC_ADDRESS)
    : createSmartContract(faucetAbiJson);

export const nftStakingV2SmartContract = createSmartContract(nftStakingV2AbiJson, NFT_STAKING_V2_SC_ADDRESS);
export const stableCoinProtocolSmartContract = createSmartContract(
    stableCoinProtocolAbiJson,
    STABLE_COIN_PROTOCOL_SC_ADDRESS,
);
