import { config } from './elrond';

export const OURO_TOKEN_ID = config.tokens.OURO.identifier;
export const OLD_AURYN_TOKEN_ID = config.tokens.OLDAURYN.identifier;
export const OLD_EAURYN_TOKEN_ID = config.tokens.OLDEAURYN.identifier;
export const AURYN_TOKEN_ID = config.tokens.AURYN.identifier;
export const EAURYN_TOKEN_ID = config.tokens.EAURYN.identifier;
export const VOURO_TOKEN_ID = config.tokens.VOURO.identifier;
export const VAURYN_TOKEN_ID = config.tokens.VAURYN.identifier;
export const VEAURYN_TOKEN_ID = config.tokens.VEAURYN.identifier;

export const RAWVST_TOKEN_ID = config.tokens.RAWVST.identifier;
export const VST_TOKEN_ID = config.tokens.VST.identifier;
export const SVST_TOKEN_ID = config.tokens.SVST.identifier;
export const FVST_TOKEN_ID = config.tokens.FVST.identifier;
export const BVST_TOKEN_ID = config.tokens.BVST.identifier;

export const GSC_TOKEN_ID = config.tokens.GSC.identifier;
export const SSC_TOKEN_ID = config.tokens.SSC.identifier;

export const USDC_TOKEN_ID = config.tokens.USDC.identifier;
export const USDD_TOKEN_ID = config.tokens.USDD.identifier;
export const WEGLD_TOKEN_ID = config.tokens.WEGLD.identifier;
export const VEGLD_TOKEN_ID = config.tokens.VEGLD.identifier;
export const ESTAR_TOKEN_ID = config.tokens.ESTAR.identifier;
export const WBTC_TOKEN_ID = config.tokens.WBTC.identifier;
export const WETH_TOKEN_ID = config.tokens.WETH.identifier;

export const JACKET_TOKEN_ID = config.tokens.JACKET.identifier;
export const PADAWAN_TOKEN_ID = config.tokens.PADAWAN.identifier;
export const RACCOON_TOKEN_ID = config.tokens.RACCOON.identifier;

export const CPA_TOKEN_ID = config.tokens.CPA.identifier;
export const KOSON_TOKEN_ID = config.tokens.KOSON.identifier;
export const KOSONPRIM_TOKEN_ID = config.tokens.KOSONPRIM.identifier;
export const SUPER_TOKEN_ID = config.tokens.SUPER.identifier;
export const XLH_TOKEN_ID = config.tokens.XLH.identifier;
export const XAPES_TOKEN_ID = config.tokens.XAPES.identifier;

export const SLIP_TOKEN_ID = config.tokens.SLIP.identifier;
export const USDD_SLIP_TOKEN_ID = config.tokens['USDD-SLIP'].identifier;
export const IGNIS_TOKEN_ID = config.tokens.IGNIS.identifier;
export const ANCIENT_TOKEN_ID = config.tokens.ANCIENT.identifier;

// LP tokens
export const VUSDDOURO_TOKEN_ID = config.tokens.VUSDDOURO.identifier;
export const VUSDCOURO_TOKEN_ID = config.tokens.VUSDCOURO.identifier;
export const OUROVST_TOKEN_ID = config.tokens.OUROVST.identifier;
export const VOUROBVST_TOKEN_ID = config.tokens.VOUROBVST.identifier;
export const VOUROSVST_TOKEN_ID = config.tokens.VOUROSVST.identifier;
export const VOUROFVST_TOKEN_ID = config.tokens.VOUROFVST.identifier;
export const VOUROXLH_TOKEN_ID = config.tokens.VOUROXLH.identifier;
export const OUROESTAR_TOKEN_ID = config.tokens.OUROESTAR.identifier;
export const VOUROKSON_TOKEN_ID = config.tokens.VOUROKSON.identifier;
export const VOUROGSC_TOKEN_ID = config.tokens.VOUROGSC.identifier;
export const VOUROSSC_TOKEN_ID = config.tokens.VOUROSSC.identifier;
export const OUROWEGLD_TOKEN_ID = config.tokens.OUROWEGLD.identifier;
export const VSUSDDOURO_TOKEN_ID = config.tokens.VSUSDDOURO.identifier;
export const VSUSDCOURO_TOKEN_ID = config.tokens.VSUSDCOURO.identifier;
export const VSOUROVST_TOKEN_ID = config.tokens.VSOUROVST.identifier;
export const VSOUROBVST_TOKEN_ID = config.tokens.VSOUROBVST.identifier;
export const VSOUROXLH_TOKEN_ID = config.tokens.VSOUROXLH.identifier;
export const VSOUROESTR_TOKEN_ID = config.tokens.VSOUROESTR.identifier;
export const VSOUROKSON_TOKEN_ID = config.tokens.VSOUROKSON.identifier;
export const VSOUROGSC_TOKEN_ID = config.tokens.VSOUROGSC.identifier;
export const VSOUROSSC_TOKEN_ID = config.tokens.VSOUROSSC.identifier;

// Meme LP tokens
export const VUSDCJAKET_TOKEN_ID = config.tokens.VUSDCJAKET.identifier;
export const VUSDCPADWN_TOKEN_ID = config.tokens.VUSDCPADWN.identifier;
export const VVEGLDJKET_TOKEN_ID = config.tokens.VVEGLDJKET.identifier;
export const VVEGLDPDWN_TOKEN_ID = config.tokens.VVEGLDPDWN.identifier;
export const VVEGLDRACCOON_TOKEN_ID = config.tokens.VVEGLDRACCOON.identifier;

export const USDT_TOKEN_ID = config.tokens.USDT.identifier;
export const BUSD_TOKEN_ID = config.tokens.BUSD.identifier;
export const WDAI_TOKEN_ID = config.tokens.WDAI.identifier;

export const DEMI_VEGLD_INDEX = 0;
export const DEMI_RAW_VESTA_INDEX = 1;
export const DEMI_VESTA_INDEX = 2;
export const DEMI_FROZEN_VESTA_INDEX = 3;
export const DEMI_OURO_INDEX = 4;

export const DEMI_SLEEPING_VESTA_INDEX = 0;
export const DEMI_AURYN_INDEX = 1;
export const DEMI_EAURYN_INDEX = 2;
export const DEMI_VESTED_OURO_INDEX = 3;
export const DEMI_VESTED_AURYN_INDEX = 4;
export const DEMI_VESTED_EAURYN_INDEX = 5;
