import React, { useEffect, useState } from 'react';
import { TokenTransfer } from '@multiversx/sdk-core/out';
import {
  useGetAccountInfo,
  useGetPendingTransactions,
} from '@multiversx/sdk-dapp/hooks';
import clsx from 'clsx';
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';
import { useSearchParams } from 'react-router-dom';
import { CustomLoading } from 'components';
import {
  FARMS,
  FARM_TOKEN_UNLOCK_EPOCHS_MAP,
  getFarmImage,
} from 'config';
import {
  selectNetstats,
  selectSwap,
} from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import {
  farmMerge,
  farmUnbond,
  farmUnstake,
  getAccountNftsByCollection,
  getTokenLogo,
} from 'z/elrond';
import { useVestaCommonHooks } from 'z/hooks';
import { FarmTokenType, StakedLpTokenType } from 'z/types';
import {
  BIG_NUMBER_ZERO,
  formatNumber,
  convertToDollarString,
  createTokenTicker,
  convertWeiToEsdt,
  decodeAttributes,
  DEFAULT_DECIMALS,
  ERROR_CONNECT_WALLET,
  ERROR_SC_DATA_NOT_LOADED,
  ERROR_TRANSACTION_ONGOING,
  toastError,
  ZERO_STRING,
} from 'z/utils';

function printLeftDays(value: number): string {
  return value > 0 ? `${value} days left` : 'Unlockable';
}

const SELECTED_FARM_INDEX_KEY = 'farmIndex';
const FARM_TOKEN_CATEGORY_COUNT = 6;
const FARM_CATEGORY_NAMES_MAP: Record<number, string> = {
  [StakedLpTokenType.Bronze]: 'Bronze Staked Liq.',
  [StakedLpTokenType.Silver]: 'Silver Staked Liq.',
  [StakedLpTokenType.Gold]: 'Gold Staked Liq.',
  [StakedLpTokenType.UnbondingBronze]: 'Bronze Unstaked Liq.',
  [StakedLpTokenType.UnbondingSilver]: 'Silver Unstaked Liq.',
  [StakedLpTokenType.UnbondingGold]: 'Gold Unstaked Liq.',
};

export const FarmLiquidity = () => {
  const { address } = useGetAccountInfo();
  const { hasPendingTransactions } = useGetPendingTransactions();
  const { getTokenPrice } = useVestaCommonHooks();

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedFarmIndex = Number(searchParams.get(SELECTED_FARM_INDEX_KEY) ?? '-1');
  function onChangeSelectedFarmIndex(value: number) {
    if (value >= 0) {
      setSearchParams({
        [SELECTED_FARM_INDEX_KEY]: value.toString(),
      });
    } else {
      searchParams.delete(SELECTED_FARM_INDEX_KEY);
      setSearchParams(searchParams);
    }
  }

  const netstatsRedux = useAppSelector(selectNetstats);
  const swapRedux = useAppSelector(selectSwap);

  const [farmTokens, setFarmTokens] = useState<FarmTokenType[][]>(new Array(FARMS.length).fill([]));
  const [farmTokenBalances, setFarmTokenBalances] = useState<string[]>(new Array(FARMS.length).fill(ZERO_STRING));
  const [selectedFarmTokenCategory, setSelectedFarmTokenCategory] = useState<StakedLpTokenType>(StakedLpTokenType.Bronze);
  const [categoryFarmTokenBalances, setCategoryFarmTokenBalances] = useState<string[]>(new Array(FARM_TOKEN_CATEGORY_COUNT).fill(ZERO_STRING));
  const [selectedFarmTokenCategoryPrice, setSelectedFarmTokenCategoryPrice] = useState<number>(0);
  const [selectedTokenIndexes, setSelectedTokenIndexes] = useState<number[]>([]);

  async function queryFarmTokenBalance() {
    const _farmTokensArray = [];
    const _farmTokenBalances = [];
    for (let farmIndex = 0; farmIndex < FARMS.length; farmIndex++) {
      const farm = FARMS[farmIndex];
      const _farmTokens: FarmTokenType[] = [];
      const metaEsdts = await getAccountNftsByCollection(address, farm.staked_lp_token);
      let totalBalance = BIG_NUMBER_ZERO;
      for (const metaEsdt of metaEsdts) {
        const decoded = decodeAttributes(metaEsdt, farm.farm_address);
        _farmTokens.push({
          collection: metaEsdt.collection,
          nonce: metaEsdt.nonce,
          identifier: metaEsdt.identifier,
          decimals: metaEsdt.decimals ?? DEFAULT_DECIMALS,
          amount: metaEsdt.balance,
          attributes: decoded,
        });
        totalBalance = totalBalance.plus(metaEsdt.balance);
      }
      
      _farmTokensArray.push(_farmTokens);
      _farmTokenBalances.push(totalBalance.toFixed(0));
    }

    setFarmTokens(_farmTokensArray);
    setFarmTokenBalances(_farmTokenBalances);
  }

  useEffect(() => {
    if (!address || hasPendingTransactions) return;
    queryFarmTokenBalance();
  }, [address, hasPendingTransactions]);

  useEffect(() => {
    if (hasPendingTransactions) return;
    setSelectedTokenIndexes([]);
  }, [hasPendingTransactions]);

  useEffect(() => {
    if (!address || hasPendingTransactions) return;
    if (selectedFarmIndex < 0) return;
    const _farmTokens = farmTokens[selectedFarmIndex];
    const _categoryFarmTokenBalances = new Array(FARM_TOKEN_CATEGORY_COUNT).fill(BIG_NUMBER_ZERO);
    for (const ft of _farmTokens) {
      _categoryFarmTokenBalances[ft.attributes.locked_lp_type_id - 1] = _categoryFarmTokenBalances[ft.attributes.locked_lp_type_id - 1].plus(ft.amount);
    }
    setCategoryFarmTokenBalances(_categoryFarmTokenBalances.map(v => v.toFixed(0)));
  }, [address, selectedFarmIndex, farmTokens]);

  useEffect(() => {
    if (selectedFarmIndex < 0 || !netstatsRedux || !swapRedux) return;
    setSelectedFarmTokenCategoryPrice(getTokenPrice(FARMS[selectedFarmIndex].pool_lp_token));
  }, [selectedFarmIndex, netstatsRedux, swapRedux]);

  async function onUnstake() {
    let error = '';
    if (!address) {
      error = ERROR_CONNECT_WALLET;
    } else if (hasPendingTransactions) {
      error = ERROR_TRANSACTION_ONGOING;
    } else if (selectedFarmTokenCategory >= StakedLpTokenType.UnbondingBronze) {
      error = 'Farm tokens are already unstaked';
    }
    const xFarmTokens = farmTokens[selectedFarmIndex]
      .filter((farmToken) => farmToken.attributes.locked_lp_type_id == selectedFarmTokenCategory)
      .filter((farmToken, index) => selectedTokenIndexes.indexOf(index) >= 0);
    if (!error && xFarmTokens.length == 0) {
      error = 'No unstakable farm token';
    }

    if (error) {
      toastError(error);
      return;
    }

    const payments: TokenTransfer[] = [];
    xFarmTokens.map((farmToken) => 
      payments.push(TokenTransfer.metaEsdtFromBigInteger(farmToken.collection, farmToken.nonce, farmToken.amount))
    );

    await farmUnstake(
      FARMS[selectedFarmIndex].farm_address,
      payments,
      address,
    );
  }

  async function onUnbond() {
    let error = '';
    if (!address) {
      error = ERROR_CONNECT_WALLET;
    } else if (hasPendingTransactions) {
      error = ERROR_TRANSACTION_ONGOING;
    } else if (!netstatsRedux.elrondStats) {
      error = ERROR_SC_DATA_NOT_LOADED;
    } else if (selectedFarmTokenCategory < StakedLpTokenType.UnbondingBronze) {
      error = 'Farm tokens should be unstaked first';
    }
    if (error) {
      toastError(error);
      return;
    }

    const xFarmTokens = farmTokens[selectedFarmIndex]
      .filter((farmToken) => farmToken.attributes.locked_lp_type_id == selectedFarmTokenCategory)
      .filter((farmToken, index) => selectedTokenIndexes.indexOf(index) >= 0);
    if (xFarmTokens.length == 0) {
      error = 'No unbondable farm token';
    }
    const unbondableCount = xFarmTokens.filter((farmToken) =>
      netstatsRedux.elrondStats && farmToken.attributes.unbonding_start_epoch + FARM_TOKEN_UNLOCK_EPOCHS_MAP[farmToken.attributes.locked_lp_type_id] <= netstatsRedux.elrondStats.epoch
    ).length;
    if (xFarmTokens.length != unbondableCount) {
      error = 'Some of the selected farm tokens are not unbondable';
    }

    if (error) {
      toastError(error);
      return;
    }

    const payments: TokenTransfer[] = [];
    xFarmTokens.map((farmToken) => 
      payments.push(TokenTransfer.metaEsdtFromBigInteger(farmToken.collection, farmToken.nonce, farmToken.amount))
    );

    await farmUnbond(
      FARMS[selectedFarmIndex].farm_address,
      payments,
      address,
    );
  }

  async function onMerge() {
    let error = '';
    if (!address) {
      error = ERROR_CONNECT_WALLET;
    } else if (hasPendingTransactions) {
      error = ERROR_TRANSACTION_ONGOING;
    } else if (!netstatsRedux.elrondStats) {
      error = ERROR_SC_DATA_NOT_LOADED;
    }
    if (error) {
      toastError(error);
      return;
    }

    const xFarmTokens = farmTokens[selectedFarmIndex]
      .filter((farmToken) => farmToken.attributes.locked_lp_type_id == selectedFarmTokenCategory)
      .filter((farmToken, index) => selectedTokenIndexes.indexOf(index) >= 0);
    if (xFarmTokens.length == 0) {
      error = 'No selected farm token';
    }
    if (error) {
      toastError(error);
      return;
    }

    const payments: TokenTransfer[] = [];
    xFarmTokens.map((farmToken) => 
      payments.push(TokenTransfer.metaEsdtFromBigInteger(farmToken.collection, farmToken.nonce, farmToken.amount))
    );

    await farmMerge(
      FARMS[selectedFarmIndex].farm_address,
      payments,
      address,
    );
  }

  if (!swapRedux || !swapRedux.pools.length) {
    return (
      <CustomLoading />
    );
  }

  return (
    <div className='row mt-4'>
      <div className='col-12'>
        <div className='d-flex justify-content-center mt-3'>
          <div className='pool-stats-box px-5'>
            <div className='pool-stats-item'>
              <span>Total Value Locked</span>
              <span>{netstatsRedux && swapRedux ? convertToDollarString(FARMS.map((farm, index) => convertWeiToEsdt(farmTokenBalances[index], DEFAULT_DECIMALS, DEFAULT_DECIMALS).multipliedBy(getTokenPrice(FARMS[index].pool_lp_token)).toNumber()).reduce((accumulator, currentValue) => accumulator + currentValue, 0)) : '0$'}</span>
            </div>
          </div>
        </div>

        {
          selectedFarmIndex < 0 && swapRedux.pools ? (
            FARMS.length > 0 ? FARMS.map((farm, index) => {
              const pool = swapRedux.pools.find((_pool) => _pool.pool_address == farm.pool_address);

              if (!pool) {
                return <CustomLoading />;
              }

              return (
                <div className={clsx(index < swapRedux.pools.length - 1 && 'portfolio-token-row', 'd-flex justify-content-between align-items-center portfolio-token-row-common')} key={`d-p-t-${index}`}>
                  <div className='d-flex align-items-center'>
                    <img
                      src={getTokenLogo(pool.first_token_id)}
                      width={'40px'}
                      height={'40px'}
                      alt={`${createTokenTicker(pool.first_token_id)} logo`}
                    />
                    <img
                      src={getTokenLogo(pool.second_token_id)}
                      width={'40px'}
                      height={'40px'}
                      alt={`${createTokenTicker(pool.second_token_id)} logo`}
                    />
                    <div className='d-flex flex-column text-white ms-3'>
                      <div>
                        {pool.lp_token_balance ? formatNumber(convertWeiToEsdt(farmTokenBalances[index], DEFAULT_DECIMALS, DEFAULT_DECIMALS)) : '0'}{' '}
                        <span className='text-secondary-color' style={{ fontSize: '.9rem' }}>{createTokenTicker(farm.staked_lp_token)}</span>
                      </div>
                      <div className='text-secondary-color' style={{ fontSize: '.9rem' }}>≈ {pool.lp_token_balance && netstatsRedux && swapRedux ? convertToDollarString(convertWeiToEsdt(farmTokenBalances[index], DEFAULT_DECIMALS, DEFAULT_DECIMALS).multipliedBy(getTokenPrice(FARMS[index].pool_lp_token))) : '0$'}</div>
                    </div>
                  </div>
  
                  <button
                    className="go-back-button"
                    onClick={() => onChangeSelectedFarmIndex(index)}
                  >
                    <BiRightArrowAlt />
                  </button>
                </div>
              );
            }) : 'No tokens'
          ) : (
            <div>
              <div className="d-flex justify-content-start">
                <button
                  className="go-back-button"
                  onClick={() => onChangeSelectedFarmIndex(-1)}
                >
                  <BiLeftArrowAlt />
                </button>
              </div>
              
              <div className='row second-card-style mt-4'>
                {
                  new Array(6).fill(0).map((v, index) => {
                    const category = index + 1;
                    return (
                      <div className='col-12 col-sm-4' key={index}>
                        <div
                          className={clsx('assets-token-box-fourth clickable mb-3', selectedFarmTokenCategory == category && 'selected')}
                          onClick={() => setSelectedFarmTokenCategory(category)}
                        >
                          <img src={getFarmImage(FARMS[selectedFarmIndex].staked_lp_token, category)} />
                          <div>
                            <div className='title-top'>{FARM_CATEGORY_NAMES_MAP[category]}</div>
                            <div>
                              {formatNumber(convertWeiToEsdt(categoryFarmTokenBalances[index], DEFAULT_DECIMALS, DEFAULT_DECIMALS))} <br />
                              ({convertToDollarString(convertWeiToEsdt(categoryFarmTokenBalances[index], DEFAULT_DECIMALS, DEFAULT_DECIMALS).multipliedBy(selectedFarmTokenCategoryPrice))})
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                }
              </div>

              <div className='row second-card-style mt-4'>
                {
                  farmTokens[selectedFarmIndex].filter((farmToken) => farmToken.attributes.locked_lp_type_id == selectedFarmTokenCategory).length > 0 ? 
                    farmTokens[selectedFarmIndex].filter((farmToken) => farmToken.attributes.locked_lp_type_id == selectedFarmTokenCategory).map((farmToken, index) => (
                      <div className='col-12 col-md-6 col-lg-3' key={index}>
                        <div
                          className={clsx('assets-token-box-third clickable mb-2', selectedTokenIndexes.indexOf(index) >= 0 && 'selected')}
                          onClick={() => setSelectedTokenIndexes(selectedTokenIndexes.indexOf(index) >= 0 ? selectedTokenIndexes.filter(v => v != index) : [...selectedTokenIndexes, index])}
                        >
                          <div>
                            <div className='title-top'>{farmToken.identifier}</div>
                            <div className='d-flex flex-column text-right'>
                              <div>{formatNumber(convertWeiToEsdt(farmToken.amount, DEFAULT_DECIMALS, DEFAULT_DECIMALS))}</div>
                              <div>({convertToDollarString(convertWeiToEsdt(farmToken.amount, DEFAULT_DECIMALS, DEFAULT_DECIMALS).multipliedBy(selectedFarmTokenCategoryPrice))})</div>
                            </div>
                          </div>
                          <div>{netstatsRedux.elrondStats && farmToken.attributes.unbonding_start_epoch ? printLeftDays(farmToken.attributes.unbonding_start_epoch  + FARM_TOKEN_UNLOCK_EPOCHS_MAP[farmToken.attributes.locked_lp_type_id] - netstatsRedux.elrondStats.epoch) : ''}</div>
                        </div>
                      </div>
                    )
                  ) : (
                    <div className='text-center my-4'>No tokens</div>
                  )
                }
                
                <div className='d-flex justify-content-center align-items-center gap-3'>
                  <button
                    className="mt-3 assets-button"
                    disabled={hasPendingTransactions || selectedTokenIndexes.length < 2}
                    onClick={onMerge}
                  >
                    Merge
                  </button>
                  {
                    selectedFarmTokenCategory <= StakedLpTokenType.Gold ? (
                      <button
                        className="mt-3 assets-button"
                        disabled={hasPendingTransactions || selectedTokenIndexes.length === 0}
                        onClick={onUnstake}
                      >
                        Unstake
                      </button>
                    ) : (
                      <button
                        className="mt-3 assets-button"
                        disabled={hasPendingTransactions || selectedTokenIndexes.length === 0}
                        onClick={onUnbond}
                      >
                        Unbond
                      </button>
                    )
                  }
                </div>
              </div>

            </div>
          )
        }
      </div>
    </div>
  );
};

