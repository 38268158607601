import React, { FC } from 'react';

interface propsInterface {
    onChange: (e: number) => void;
    value: number;
    min?: number;
    max?: number;
}

export const ResponsiveNumInput: FC<propsInterface> = ({ onChange, value, min, max }) => {
    const onSub = () => {
        if (typeof min === 'number' && value - 1 < min) {
            onChange(value);
            return;
        }
        onChange(value - 1);
    };

    const onAdd = () => {
        if (typeof max === 'number' && value + 1 > max) {
            onChange(value);
            return;
        }
        onChange(value + 1);
    };

    const handleChangeNumber = (val: number) => {
        if (typeof min === 'number' && val < min) {
            onChange(min);
            return;
        }
        if (typeof max === 'number' && val > max) {
            onChange(max);
            return;
        }
        onChange(val);
    };

    return (
        <div className="mobile-responsive-number-input">
            <div className="spin-but" onClick={onSub}>
                -
            </div>
            <input type={'number'} value={value} onChange={(e) => handleChangeNumber(Number(e.target.value))} />
            <div className="spin-but" onClick={onAdd}>
                +
            </div>
        </div>
    );
};
