import React, { FC } from 'react';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

interface propsInterface {
    url: string;
}

export const PageBack: FC<propsInterface> = ({ url }) => {
    const navigate = useNavigate();
    return (
        <>
            <div className="d-flex justify-content-start">
                <button
                    className="go-back-button"
                    onClick={() => {
                        navigate(url);
                    }}
                >
                    <BiLeftArrowAlt />
                </button>
            </div>
        </>
    );
};
