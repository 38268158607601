import React from 'react';

import { Row } from 'react-bootstrap';
import { IssueSlipToken } from '../Components/IssueSlipToken';
import { AddToken } from './AddToken';
import { ExistingToken } from './ExistingToken';
import { ManageFunds } from './ManageFunds';

export const SlipAdmin = () => {
    return (
        <div className="container" style={{ marginTop: '50px' }}>
            <div className="d-flex justify-content-center mt-4">
                <div className="vesta-first-container">
                    <div className="d-flex justify-content-center">
                        <span
                            style={{
                                color: '#F1DC46',
                                fontSize: '1.2rem',
                            }}
                        >
                            Slip Admin
                        </span>
                    </div>

                    <div className="mt-3">
                        <Row style={{ rowGap: '10px' }}>
                            <IssueSlipToken />

                            <ManageFunds />

                            <AddToken />

                            <ExistingToken />
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    );
};
