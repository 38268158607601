import { RouteType } from '@multiversx/sdk-dapp/types';
import NftStakingV2 from 'pages/NftStakingV2';
import { Vesta } from 'pages/Vesta';
import { Staking } from './pages/Staking';

export const routeNames = {
    home: '/',
    unlock: '/unlock',
    swap: '/swap',
    stableSwap: '/stable-swap',
    pools: '/pools',
    stablePools: '/stable-pools',
    createPool: '/create-pool',
    staking: '/staking',
    farms: '/farms',
    myfarms: '/my-farms',
    assets: '/assets',
    vesta: '/vesta',
    liquidLocker: '/liquid-locker',
    vaults: '/vaults',
    capitalVestaVault: '/vaults/capital-vesta',
    auxVestaVault: '/vaults/aux-vesta',
    cryptoVestaVault: '/vaults/crypto-vesta',
    liquidityVestaVault: '/vaults/liquidity-ouro',
    memeLiquidityVestaVault: '/vaults/liquidity-meme',
    vegldVault: '/vaults/vegld-vault',
    autostakePool: '/vaults/autostake-pool',
    stableLiquidityVault: '/vaults/stable-liquidity',
    vaultAdmin: '/vault-admin',
    transfer: '/transfer',
    eliteAccount: '/elite-account',
    nftStaking: '/nft-staking',
    faucet: '/faucet',
    subsidiaryStaking: '/nft-staking/subsidiary',
    stableCoin: '/stable-coin',
    nftAdmin: '/nft-admin',
    slipAdmin: '/slip-admin',
    stableCoinAdmin: '/stable-coin-admin',
};

interface RouteWithTitleType extends RouteType {
    title: string;
}

export const routes: RouteWithTitleType[] = [
    {
        path: routeNames.nftStaking,
        title: 'Nft Staking',
        component: NftStakingV2,
        authenticatedRoute: false,
    },
    {
        path: routeNames.staking,
        title: 'Staking',
        component: Staking,
        authenticatedRoute: false,
    },
    {
        path: routeNames.vesta,
        title: 'Vesta Minter',
        component: Vesta,
        authenticatedRoute: true,
    },
];

const _routesMap: Record<string, RouteWithTitleType> = {};
routes.map((item) => (_routesMap[item.path] = item));
export const routesMap = _routesMap;
