import {
    BLOODSHED_COLLECTION,
    BLOODSHED_COMMON_NONCES,
    BLOODSHED_EPIC_NONCES,
    BLOODSHED_LEGENDARY_NONCES,
    BLOODSHED_RARE_NONCES,
    NOSFERATU_COLLECTION,
    X_BUNNIES_COLLECTION,
} from 'config';

const fillPrefixString = (value: number, length: number, prefix: string): string => {
    let hexStr = value.toString();
    for (let i = hexStr.length; i < length; i++) {
        hexStr = prefix + hexStr;
    }
    return hexStr;
};

const getBloodshedImageName = (nonce: number): string => {
    if (BLOODSHED_LEGENDARY_NONCES[0] <= nonce && nonce <= BLOODSHED_LEGENDARY_NONCES[1]) {
        return `/img/nfts/bloodshed/S_Bloodshed_Legendary_${((nonce - BLOODSHED_LEGENDARY_NONCES[0]) % 8) + 1}.jpg`;
    }
    if (BLOODSHED_EPIC_NONCES[0] <= nonce && nonce <= BLOODSHED_EPIC_NONCES[1]) {
        return `/img/nfts/bloodshed/S_Bloodshed_Epic_${fillPrefixString(
            ((nonce - BLOODSHED_EPIC_NONCES[0]) % 48) + 1,
            2,
            '0',
        )}.jpg`;
    }
    if (BLOODSHED_RARE_NONCES[0] <= nonce && nonce <= BLOODSHED_RARE_NONCES[1]) {
        return `/img/nfts/bloodshed/S_Bloodshed_Rare_${fillPrefixString(
            ((nonce - BLOODSHED_RARE_NONCES[0]) % 72) + 1,
            2,
            '0',
        )}.jpg`;
    }
    if (BLOODSHED_COMMON_NONCES[0] <= nonce && nonce <= BLOODSHED_COMMON_NONCES[1]) {
        return `/img/nfts/bloodshed/S_Bloodshed_Common_${fillPrefixString(
            ((nonce - BLOODSHED_COMMON_NONCES[0]) % 144) + 1,
            3,
            '0',
        )}.jpg`;
    }
    return ``;
};

export const getNftImage = (collectionIdentifier: string, nonce: number): string => {
    switch (collectionIdentifier) {
        case NOSFERATU_COLLECTION:
            return `/img/nfts/nosferatu/S_Nosferat_${fillPrefixString(nonce, 4, '0')}.jpg`;
        case X_BUNNIES_COLLECTION:
            return `/img/nfts/x-bunnies/S_XBunny_${fillPrefixString(nonce, 4, '0')}.jpg`;
        case BLOODSHED_COLLECTION:
            return getBloodshedImageName(nonce);
    }
    return '';
};
