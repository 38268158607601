import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TokenTransfer } from '@multiversx/sdk-core/out';
import {
  useGetAccount,
  useGetPendingTransactions,
} from '@multiversx/sdk-dapp/hooks';
import Dropdown from 'react-bootstrap/Dropdown';
import { 
  InjectRewardType,
  TOKEN_INFO_MAP,
} from 'config';
import { selectNetstats } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import {
  getTokenLogo,
  vaultInjectReward,
} from 'z/elrond';
import {
  convertBigNumberToInputString,
  formatNumber,
  convertEsdtToWei,
  createTokenTicker,
  convertWeiToEsdt,
  ERROR_CONNECT_WALLET,
  ERROR_INVALID_NUMBER,
  ERROR_NOT_ENOUGH_BALANCE,
  ERROR_TRANSACTION_ONGOING,
  isPositiveBigNumber,
  isPositiveOrZeroBigNumber,
  toastError,
  ZERO_STRING,
} from 'z/utils';

export const VaultAdminCard = ({
    vaultAddress,
    rewardTokenIds,
    injectRewardType,
    gasLimit,
}: {
    vaultAddress: string;
    rewardTokenIds: string[];
    injectRewardType: InjectRewardType;
    gasLimit?: number;
}) => {
    const { address } = useGetAccount();
    const { hasPendingTransactions } = useGetPendingTransactions();

    const netstatsRedux = useAppSelector(selectNetstats);

    const [injectToken, setInjectToken] = useState<string>(rewardTokenIds[0]);
    const [injectAmount, setInjectAmount] = useState<string>(ZERO_STRING);
    const [injectAmountError, setInjectAmountError] = useState<string>('');

    const injectTokenBalance: string =
        injectToken && netstatsRedux.walletTokensMap[injectToken]
            ? netstatsRedux.walletTokensMap[injectToken].balance ?? ZERO_STRING
            : ZERO_STRING;

    function onChangeInjectAmount(value: string) {
        let error = '';
        if (!isPositiveOrZeroBigNumber(value)) {
            error = ERROR_INVALID_NUMBER;
        } else if (
            address &&
            convertEsdtToWei(value, TOKEN_INFO_MAP[injectToken].decimals).comparedTo(injectTokenBalance) > 0
        ) {
            error = ERROR_NOT_ENOUGH_BALANCE;
        }

        setInjectAmountError(error);
        setInjectAmount(value);
    }

    function onMaxInjectAmount() {
        setInjectAmount(
            convertBigNumberToInputString(
                convertWeiToEsdt(
                    injectTokenBalance,
                    TOKEN_INFO_MAP[injectToken].decimals,
                    TOKEN_INFO_MAP[injectToken].decimals,
                ),
                TOKEN_INFO_MAP[injectToken].decimals,
            ),
        );
    }

    async function onInject() {
        let error = '';
        if (!address) {
            error = ERROR_CONNECT_WALLET;
        } else if (hasPendingTransactions) {
            error = ERROR_TRANSACTION_ONGOING;
        } else if (!isPositiveBigNumber(injectAmount)) {
            error = ERROR_INVALID_NUMBER;
        } else if (
            convertEsdtToWei(injectAmount, TOKEN_INFO_MAP[injectToken].decimals).comparedTo(injectTokenBalance) > 0
        ) {
            error = ERROR_NOT_ENOUGH_BALANCE;
        }
        if (error) {
            toastError(error);
            return;
        }

        const payments = [
            TokenTransfer.fungibleFromBigInteger(
                injectToken,
                convertEsdtToWei(injectAmount, TOKEN_INFO_MAP[injectToken].decimals),
            ),
        ];
        await vaultInjectReward(vaultAddress, injectRewardType, payments, address, gasLimit);
    }

    return (
        <>
            <div className="text-center mb-2" style={{ color: '#F1DC46' }}>
                {injectRewardType} Rewards
            </div>
            <div
                className="p-3"
                style={{
                    border: '1px solid #ffffff0f',
                    borderRadius: '10px',
                }}
            >
                <div className="vesta_x_swap_input_box">
                    <div className="d-flex align-items-center">
                        <input
                            className="swap_input"
                            type="number"
                            value={injectAmount}
                            onChange={(e) => onChangeInjectAmount(e.target.value)}
                        />
                        {/* <button className="add-liquidity-selected-token-buttton">
              <img src={getTokenLogo(tokenId)} alt="logo" width="30px" />
              {tokenTicker}
            </button> */}
                        <Dropdown className="select-borrow-currency-dropdown">
                            <Dropdown.Toggle
                                id="dropdown-basic-button"
                                className="currency-item justify-content-between"
                            >
                                <div className="d-flex align-items-center">
                                    <img src={getTokenLogo(injectToken)} />
                                    <div>{createTokenTicker(injectToken)}</div>
                                    <ExpandMoreIcon className="ms-1" />
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {rewardTokenIds.map((tokenId: string, index: number) => (
                                    <Dropdown.Item
                                        className="currency-item d-flex align-items-center"
                                        onClick={() => setInjectToken(tokenId)}
                                        key={index}
                                    >
                                        <img src={getTokenLogo(tokenId)} alt={tokenId}></img>
                                        <div>{createTokenTicker(tokenId)}</div>
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                    <div className="d-flex justify-content-between mt-1">
                        <div className="input-token-error">{injectAmountError}</div>
                        <div className="add-liquidity-input-token-balance-box" onClick={onMaxInjectAmount}>
                            <div className="">Balance:&nbsp;</div>
                            <div style={{ color: 'white' }}>
                                {formatNumber(
                                    convertWeiToEsdt(
                                        injectTokenBalance,
                                        TOKEN_INFO_MAP[injectToken].decimals,
                                        TOKEN_INFO_MAP[injectToken].decimals,
                                    ),
                                    TOKEN_INFO_MAP[injectToken].decimals,
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-center align-items-center">
                    <button className="mt-3 assets-button" disabled={hasPendingTransactions} onClick={onInject}>
                        Inject
                    </button>
                </div>
            </div>
        </>
    );
};
