import React, { ChangeEvent, useEffect, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { Collapse, Tooltip } from '@mui/material';
import { parseAmount } from '@multiversx/sdk-dapp/utils';
import BigNumber from 'bignumber.js';
import clsx from 'clsx';
import { Row, Col } from 'react-bootstrap';
import { BiRightArrowAlt, BiDownArrowAlt } from 'react-icons/bi';

import { OURO_TOKEN_ID, USDD_TOKEN_ID } from 'config';
import { selectNetstats, selectSlip, selectSwap } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { addLiquidityIntoPool } from 'z/elrond';
import { SlipStateEnum, SwapPoolType } from 'z/types';
import {
    ERROR_NOT_ENOUGH_BALANCE,
    ERROR_NOT_ENOUGH_OURO_IN_SLIP,
    convertEsdtToWei,
    convertWeiToEsdt,
    formatNumber,
} from 'z/utils';
import { SlipLoadType } from '.';
import { StakeUnstakeFrLp } from './Components/StakeUnstakeFrLp';

export const SlipLoad = ({ slipLoadData }: { slipLoadData: SlipLoadType }) => {
    const swapRedux = useAppSelector(selectSwap);
    const { context, basePool, maxBaseTokenAmountThatCanBeAddedIntoSlip } = useAppSelector(selectSlip);
    const netstatsRedux = useAppSelector(selectNetstats);

    const [swapBasePool, setSwapBasePool] = useState<SwapPoolType | undefined>();
    useEffect(() => {
        setSwapBasePool(
            swapRedux.pools.find(
                ({ first_token_id, second_token_id }) =>
                    first_token_id === USDD_TOKEN_ID && second_token_id === OURO_TOKEN_ID,
            ),
        );
    }, [swapRedux, context]);

    const [accountBaseTokenAmount, setAccountBaseTokenAmount] = useState('0');
    useEffect(() => {
        setAccountBaseTokenAmount(netstatsRedux.walletTokensMap[USDD_TOKEN_ID]?.balance || '0');
    }, [netstatsRedux.walletTokensMap]);

    const [showControl, setShowControl] = useState<boolean>(false);

    function toggleShowControlButton() {
        setShowControl(!showControl);
    }

    const InfoTooltip = ({ title }: { title: string }) => {
        return (
            <Tooltip title={title} leaveDelay={200}>
                <span style={{ color: '#98A1C0' }}>
                    <InfoIcon fontSize="small" />
                </span>
            </Tooltip>
        );
    };

    const [addLiquidityAmount, setAddLiquidityAmount] = useState<string | undefined>();

    function handlerAddLiquidity() {
        if (swapBasePool && addLiquidityAmount)
            addLiquidityIntoPool(
                swapBasePool?.first_token_id,
                parseAmount(addLiquidityAmount, swapBasePool.first_token_decimals),
            );
    }

    function handlerSetBalance() {
        setAddLiquidityAmount(
            BigNumber.min(
                convertWeiToEsdt(
                    maxBaseTokenAmountThatCanBeAddedIntoSlip,
                    swapBasePool?.first_token_decimals,
                    swapBasePool?.first_token_decimals,
                ),
                convertWeiToEsdt(
                    accountBaseTokenAmount,
                    swapBasePool?.first_token_decimals,
                    swapBasePool?.first_token_decimals,
                ),
            ).toFixed(),
        );
    }

    const [amountError, setAmountError] = useState('');

    function handleChangeAmount(e: ChangeEvent<HTMLInputElement>): void {
        if (convertEsdtToWei(e.target.value, swapBasePool?.first_token_decimals).comparedTo(accountBaseTokenAmount) > 0)
            setAmountError(ERROR_NOT_ENOUGH_BALANCE);
        else if (
            convertEsdtToWei(e.target.value, swapBasePool?.first_token_decimals).comparedTo(
                maxBaseTokenAmountThatCanBeAddedIntoSlip,
            ) > 0
        )
            setAmountError(ERROR_NOT_ENOUGH_OURO_IN_SLIP);
        else setAmountError('');

        setAddLiquidityAmount(e.target.value);
    }

    return (
        <>
            <div className="slip-li-container">
                <div className="slip-li">
                    <div className="slip-li-header">
                        <img src="/img/vault/Native-OURO-USDD_200x200.svg" alt="logo" width="70px" />
                        <div
                            className={clsx(
                                'fee-badge gold-color selected',
                                basePool?.status == SlipStateEnum.Active ? 'state-active' : 'state-inactive',
                            )}
                        >
                            {basePool?.status}
                        </div>
                    </div>

                    <div className="w-100">
                        <div className="d-flex justify-content-between mb-1">
                            <div style={{ width: '33%', textAlign: 'left' }}>
                                {`OURO in $ based Pools: ${slipLoadData.ouroAmountInSwapBasedPool} OURO `}
                                <InfoTooltip title={`Amount of OURO existing in the $/OURO Pool`} />
                            </div>
                            <div style={{ width: '33%', textAlign: 'center' }}>{`${
                                swapBasePool?.first_token_ticker
                            } Capacity Left: ${formatNumber(
                                convertWeiToEsdt(
                                    maxBaseTokenAmountThatCanBeAddedIntoSlip,
                                    swapBasePool?.first_token_decimals,
                                    swapBasePool?.first_token_decimals,
                                ),
                                0,
                            )} ${swapBasePool?.first_token_ticker}`}</div>
                            <div style={{ width: '34%', textAlign: 'right' }}>
                                {`Current Slip Threshold: ${slipLoadData.slipThreashold} OURO `}
                                <InfoTooltip
                                    title={`Total SLIP Capacity in OURO, equal to 95% of the OURO Amount in the $/OURO Pool`}
                                />
                            </div>
                        </div>

                        <div className="progress w-100 slip-progress" style={{ background: '#c8bfe7' }}>
                            <div
                                className="progress-bar slip-progress-bar"
                                role="progressbar"
                                style={{
                                    width: `${slipLoadData.slipFillUpProcent}%`,
                                }}
                            >
                                {slipLoadData.slipFillUpProcent}% Filled
                            </div>
                            <div
                                className="progress-bar slip-fillup-bar"
                                style={{
                                    width: `${Math.min(100 - parseFloat(slipLoadData.slipFillUpProcent), 5)}%`,
                                }}
                            />
                        </div>

                        <div className="d-flex justify-content-between mt-1">
                            <div style={{ width: '33%', textAlign: 'left' }}>
                                {`Slip FillUp: ${slipLoadData.slipFillUp} OURO (${slipLoadData.slipFillUpProcent}%) `}
                                <InfoTooltip
                                    title={`SLIP Fillup represents how much OURO exists in all OURO based Poosl as a percent of the total OURO existing in the $/OURO Pool. If it is above 95% SLIP is offline.`}
                                />
                            </div>
                            <div style={{ width: '33%', textAlign: 'center' }}>{`${formatNumber(
                                convertWeiToEsdt(
                                    swapBasePool?.first_token_reserve,
                                    swapBasePool?.first_token_decimals,
                                    swapBasePool?.first_token_decimals,
                                ),
                                0,
                            )} / ${formatNumber(
                                BigNumber.sum(
                                    convertWeiToEsdt(
                                        swapBasePool?.first_token_reserve,
                                        swapBasePool?.first_token_decimals,
                                        swapBasePool?.first_token_decimals,
                                    ),
                                    convertWeiToEsdt(
                                        maxBaseTokenAmountThatCanBeAddedIntoSlip,
                                        swapBasePool?.first_token_decimals,
                                        swapBasePool?.first_token_decimals,
                                    ),
                                ),
                                0,
                            )} ${swapBasePool?.first_token_ticker}`}</div>
                            <div style={{ width: '34%', textAlign: 'right' }}>
                                {`Total Slip Capacity (95%) ${slipLoadData.totalSlipCapacity} OURO `}
                                <InfoTooltip
                                    title={`Current SLIP Capacity in OURO, how much OURO can be used from the SLIP Load for SLIP. Negative Values means SLIP is offline, and more OURO must be added to the $/OURO Pool`}
                                />
                            </div>
                        </div>
                    </div>

                    <div>
                        <button className="pool-collapse-button" onClick={toggleShowControlButton}>
                            {!showControl ? <BiRightArrowAlt /> : <BiDownArrowAlt />}
                        </button>
                    </div>
                </div>

                <Collapse in={showControl}>
                    <div style={{ marginTop: '30px' }}>
                        <Row style={{ rowGap: '10px' }}>
                            <Col md={9}>
                                <input
                                    className="vesta_x_input"
                                    placeholder={`USDD Amount`}
                                    type="number"
                                    value={addLiquidityAmount}
                                    onChange={handleChangeAmount}
                                />

                                <div className="d-flex justify-content-between mt-1">
                                    <div className="input-token-error">{amountError}</div>
                                    <div className="add-liquidity-input-token-balance-box" onClick={handlerSetBalance}>
                                        <div className="">Balance:&nbsp;</div>
                                        <div
                                            style={{
                                                color: 'white',
                                            }}
                                        >
                                            {formatNumber(
                                                convertWeiToEsdt(
                                                    accountBaseTokenAmount,
                                                    swapBasePool?.first_token_decimals,
                                                    swapBasePool?.first_token_decimals,
                                                ),
                                                swapBasePool?.first_token_decimals,
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col md={3}>
                                <button
                                    className="slip-but w-100"
                                    onClick={handlerAddLiquidity}
                                    disabled={basePool?.status !== SlipStateEnum.Active}
                                >
                                    Make SLIP&nbsp;
                                    <InfoTooltip title="Make SLIP using the amount of Token entered in the field to the left." />
                                </button>
                            </Col>
                        </Row>
                        <StakeUnstakeFrLp frozenLpIdentifier={basePool?.frozenLpTokenIdentifier || ''} />
                    </div>
                </Collapse>
            </div>
        </>
    );
};
