import React, { FC } from 'react';
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

const AirbnbSlider = styled(Slider)(({ theme }) => ({
  color: '#F1DC46',
  height: 3,
  padding: '13px 0',
  '& .MuiSlider-thumb': {
      height: 20,
      width: 20,
      backgroundColor: '#1B0921',
      border: '1px solid currentColor',
      boxShadow: 'none',
      '&:hover': {
        boxShadow: '0 0 0 8px rgba(249, 216, 94, 0.16)',
      },
      '& .airbnb-bar': {
        height: 9,
        width: 1,
        backgroundColor: 'currentColor',
        marginLeft: 1,
        marginRight: 1,
      },
  },
  '& .MuiSlider-track': {
    height: 6,
  },
  '& .MuiSlider-rail': {
    color: theme.palette.mode === 'dark' ? '#1B0921' : '#1B0921',
    // opacity: theme.palette.mode === 'dark' ? undefined : 1,
    opacity: 1,
    height: 6,
  },
  '& .MuiSlider-markLabel': {
    color: '#98A1C0',
  },
  '& .MuiSlider-mark': {
    size: 'lg',
  },
  '& .MuiSlider-markActive': {
    backgroundColor: '#F1DC46',
  },
}));

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AirbnbThumbComponentProps extends React.HTMLAttributes<unknown> {}

function AirbnbThumbComponent(props: AirbnbThumbComponentProps) {
  const { children, ...other } = props;
  return <SliderThumb {...other}>{children}</SliderThumb>;
}

const sliderMarks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 25,
    label: '25%',
  },
  {
    value: 50,
    label: '50%',
  },
  {
    value: 75,
    label: '75%',
  },
  {
    value: 100,
    label: '100%',
  },
];

interface propsInterface {
  onChange: (e: number) => void,
  value: number,
  min?: number,
  max?: number,
  step?: number
  disabled?: boolean,
}

export const CustomSlider2: FC<propsInterface> = ({
  onChange,
  value,
  min,
  max,
  step,
  disabled = false,
}) => {
    return (
        <>
            <AirbnbSlider
                components={{ Thumb: AirbnbThumbComponent }}
                getAriaLabel={(index: number) =>
                    index === 0 ? 'Minimum price' : 'Maximum price'
                }
                defaultValue={0}
                marks={sliderMarks}
                value={value}
                onChange={(_: any, num: any) => onChange(num)}
                min={min}
                max={max}
                step={step}
                disabled={disabled}
            />
        </>
    );
};
