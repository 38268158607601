import React, { useState, useEffect } from 'react';
import {
    useGetAccount,
    useGetLoginInfo,
    useGetNetworkConfig,
    useGetPendingTransactions,
} from '@multiversx/sdk-dapp/hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ElrondAddressLink } from 'components/Elements/ElrondAddressLink';
import { DEFAULT_TOTAL_FEE, DEFAULT_SPECIAL_FEE, FEE_DENOMINATOR, MAX_FEE } from 'config';
import { AddLiquidityInputToken } from 'pages/Pool/AddLiquidityInputToken';
import { selectSwap } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { routeNames } from 'routes';
import {
    elrondAddInitialLiquidity,
    elrondCreatePair,
    elrondIssueLpToken,
    elrondPairInitConfiguration,
    elrondSetLocalRoles,
    elrondViewPaginationSwapPools,
    getTokenBalanceFromApi,
} from 'z/elrond';
import { StateEnum, SwapPoolType, TokenBalanceType } from 'z/types';
import {
    formatNumber,
    convertWeiToEsdt,
    toastError,
    ZERO_STRING,
    ERROR_CANNOT_SELECT_BASE_TOKEN,
    ERROR_CONNECT_WALLET,
    TEXT_SELECT_TOKEN,
    parseBigNumber,
    TEXT_SELECT_POOL,
    isPositiveOrZeroBigNumber,
    ERROR_INVALID_NUMBER,
    convertEsdtToWei,
    ERROR_NOT_ENOUGH_BALANCE,
    createTokenTicker,
    isValidElrondAddress,
    ERROR_INVALID_MVX_ADDRESS,
    ERROR_INVALID_MVX_TOKEN_TICKER,
    isValidElrondTokenName,
    ERROR_INVALID_MVX_TOKEN_NAME,
    isValidElrondTokenTicker,
    ERROR_INVALID_MVX_TOKEN_DECIMALS,
    ERROR_SC_DATA_NOT_LOADED,
    ERROR_ROUTER_INACTIVE,
    DEFAULT_DECIMALS,
} from 'z/utils';
import { CreatePoolSelectToken } from './CreatePoolSelectToken';
import { CreatePoolStepper } from './CreatePoolStepper';

const createPoolSteps = [
    'Select Token',
    'Init Configuration',
    'Issue LP Token',
    'Set Roles',
    'Add Initial Liquidity',
    'All Configuration Done',
];

export const CreatePool = () => {
    const { chainID } = useGetNetworkConfig();
    const { hasPendingTransactions } = useGetPendingTransactions();
    const { address } = useGetAccount();
    const { isLoggedIn } = useGetLoginInfo();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const firstTokenId = searchParams.get('firstTokenId') ?? '';
    const secondTokenId = searchParams.get('secondTokenId') ?? '';

    const swapRedux = useAppSelector(selectSwap);

    const [currentStep, setCurrentStep] = useState<number>(0);
    const [swapPools, setSwapPools] = useState<SwapPoolType[]>([]);

    const [firstTokenBalanceInfo, setFirstTokenBalanceInfo] = useState<TokenBalanceType | undefined>();
    const [secondTokenBalanceInfo, setSecondTokenBalanceInfo] = useState<TokenBalanceType | undefined>();
    const [firstTokenAmountError, setFirstTokenAmountError] = useState<string>('');
    const [secondTokenAmountError, setSecondTokenAmountError] = useState<string>('');
    const [firstTokenAmount, setFirstTokenAmount] = useState<string>(ZERO_STRING);
    const [secondTokenAmount, setSecondTokenAmount] = useState<string>(ZERO_STRING);

    const [selectedPool, setSelectedPool] = useState<SwapPoolType | null>(null);

    const [totalFee, setTotalFee] = useState<number>(DEFAULT_TOTAL_FEE);
    const [specialFee, setSpecialFee] = useState<number>(DEFAULT_SPECIAL_FEE);
    const [initialLiquidityAddress, setInitialLiquidityAddress] = useState<string>(address);
    const [totalFeeError, setTotalFeeError] = useState<string>('');
    const [specialFeeError, setSpecialFeeError] = useState<string>('');
    const [initialLiquidityAddressError, setInitialLiquidityAddressError] = useState<string>('');

    const [lpTokenName, setLpTokenName] = useState<string>('');
    const [lpTokenTicker, setLpTokenTicker] = useState<string>('');
    const [lpTokenDecimals, setLpTokenDecimals] = useState<number>(DEFAULT_DECIMALS);
    const [lpTokenNameError, setLpTokenNameError] = useState<string>('');
    const [lpTokenTickerError, setLpTokenTickerError] = useState<string>('');
    const [lpTokenDecimalsError, setLpTokenDecimalsError] = useState<string>('');

    const exchangeRate =
        parseBigNumber(firstTokenAmount).isZero() || parseBigNumber(secondTokenAmount).isZero()
            ? parseBigNumber(0)
            : parseBigNumber(secondTokenAmount).div(firstTokenAmount);

    useEffect(() => {
        if (hasPendingTransactions) return;
        (async () => {
            const _swapPools = await elrondViewPaginationSwapPools(false);
            setSwapPools(_swapPools);
        })();
    }, [hasPendingTransactions]);

    useEffect(() => {
        if (hasPendingTransactions) return;
        if (address && firstTokenId) {
            (async () => {
                const _tokenBalanceInfo = await getTokenBalanceFromApi(address, firstTokenId);
                setFirstTokenBalanceInfo(_tokenBalanceInfo);
            })();
        } else {
            setFirstTokenBalanceInfo(undefined);
        }
    }, [address, hasPendingTransactions, firstTokenId]);

    useEffect(() => {
        if (hasPendingTransactions) return;
        if (address && secondTokenId) {
            (async () => {
                const _tokenBalanceInfo = await getTokenBalanceFromApi(address, secondTokenId);
                setSecondTokenBalanceInfo(_tokenBalanceInfo);
            })();
        } else {
            setSecondTokenBalanceInfo(undefined);
        }
    }, [address, hasPendingTransactions, secondTokenId]);

    function checkPools(_firstTokenId: string, _secondTokenId: string) {
        // check if the same pool already exists
        if (swapPools.length > 0) {
            let _selectedPool = null;
            for (const pool of swapPools) {
                if (
                    pool.first_token_id.toLowerCase() == _firstTokenId.toLowerCase() &&
                    pool.second_token_id.toLowerCase() == _secondTokenId.toLowerCase()
                ) {
                    _selectedPool = pool;
                    break;
                }
            }
            setSelectedPool(_selectedPool);
        }
    }

    function onChangeFirstTokenId(value: string) {
        setSearchParams({
            firstTokenId: value,
            secondTokenId: secondTokenId,
        });
    }
    function onChangeSecondTokenId(value: string) {
        // cannot be vEGLD (base token)
        if (value == secondTokenId) {
            toastError(ERROR_CANNOT_SELECT_BASE_TOKEN);
            return;
        }

        setSearchParams({
            firstTokenId: firstTokenId,
            secondTokenId: value,
        });
    }

    async function createPair() {
        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_WALLET);
            return;
        }
        if (!secondTokenId) {
            toastError(TEXT_SELECT_TOKEN);
            return;
        }

        await elrondCreatePair(firstTokenId, secondTokenId, chainID);
    }

    useEffect(() => {
        if (firstTokenId && secondTokenId) {
            checkPools(firstTokenId, secondTokenId);
        }
    }, [swapPools, firstTokenId, secondTokenId]);

    useEffect(() => {
        if (!isLoggedIn || !selectedPool) return;

        let step = 0;
        if (selectedPool.total_fee_percentage === 0) {
            step = 1;
        } else if (!selectedPool.lp_token_id) {
            step = 2;
        } else if (!selectedPool.lp_token_roles_are_set) {
            step = 3;
        } else if (parseBigNumber(selectedPool.lp_token_supply).isZero()) {
            step = 4;
        } else {
            step = 5;
        }

        setCurrentStep(step);
    }, [selectedPool]);

    //
    function onChangeTotalFee(value: number) {
        let error = '';
        if (value < 0) {
            error = ERROR_INVALID_NUMBER;
        } else if (value > MAX_FEE) {
            error = `Cannot exceed ${MAX_FEE}%`;
        }

        setTotalFeeError(error);
        setTotalFee(value);
    }
    function onChangeSpecialFee(value: number) {
        let error = '';
        if (value < 0) {
            error = ERROR_INVALID_NUMBER;
        } else if (value >= totalFee) {
            error = `Cannot exceed ${totalFee}%`;
        }

        setSpecialFeeError(error);
        setSpecialFee(value);
    }
    function onChangeInitialLiquidityAddress(value: string) {
        let error = '';
        if (!isValidElrondAddress(value)) {
            error = ERROR_INVALID_MVX_ADDRESS;
        }

        setInitialLiquidityAddressError(error);
        setInitialLiquidityAddress(value);
    }

    async function pairInitConfiguration() {
        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_WALLET);
            return;
        }

        await elrondPairInitConfiguration(
            firstTokenId,
            secondTokenId,
            totalFee * FEE_DENOMINATOR,
            specialFee * FEE_DENOMINATOR,
            firstTokenId,
            initialLiquidityAddress,
            [],
        );
    }

    //
    function onChangeLpTokenName(value: string) {
        let error = '';
        if (!isValidElrondTokenName(value)) {
            error = ERROR_INVALID_MVX_TOKEN_NAME;
        }

        setLpTokenNameError(error);
        setLpTokenName(value);
    }
    function onChangeLpTokenTicker(value: string) {
        let error = '';
        if (!isValidElrondTokenTicker(value)) {
            error = ERROR_INVALID_MVX_TOKEN_TICKER;
        }

        setLpTokenTickerError(error);
        setLpTokenTicker(value);
    }
    function onChangeLpTokenDecimals(value: number) {
        let error = '';
        // my custom rule ;))
        if (!(value >= 6 && value <= 18)) {
            error = ERROR_INVALID_MVX_TOKEN_DECIMALS;
        }

        setLpTokenDecimalsError(error);
        setLpTokenDecimals(value);
    }

    async function issueLpToken() {
        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_WALLET);
            return;
        }
        if (!selectedPool) {
            toastError(TEXT_SELECT_POOL);
            return;
        }

        await elrondIssueLpToken(selectedPool.pool_address, lpTokenName, lpTokenTicker, lpTokenDecimals, chainID);
    }

    //
    async function setLocalRoles() {
        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_WALLET);
            return;
        }
        if (!selectedPool) {
            toastError(TEXT_SELECT_POOL);
            return;
        }

        await elrondSetLocalRoles(selectedPool.pool_address, chainID);
    }

    //
    function onChangeFirstTokenAmount(value: string) {
        let error = '';
        if (!isLoggedIn) {
            error = ERROR_CONNECT_WALLET;
        } else if (!selectedPool) {
            error = TEXT_SELECT_POOL;
        } else if (!isPositiveOrZeroBigNumber(value)) {
            error = ERROR_INVALID_NUMBER;
        } else if (
            firstTokenBalanceInfo &&
            convertEsdtToWei(value, firstTokenBalanceInfo.decimals).comparedTo(firstTokenBalanceInfo.balance) > 0
        ) {
            error = ERROR_NOT_ENOUGH_BALANCE;
        }

        setFirstTokenAmountError(error);
        setFirstTokenAmount(value);

        return error;
    }
    function onChangeSecondTokenAmount(value: string) {
        let error = '';
        if (!isLoggedIn) {
            error = ERROR_CONNECT_WALLET;
        } else if (!selectedPool) {
            error = TEXT_SELECT_POOL;
        } else if (!isPositiveOrZeroBigNumber(value)) {
            error = ERROR_INVALID_NUMBER;
        } else if (
            secondTokenBalanceInfo &&
            convertEsdtToWei(value, secondTokenBalanceInfo.decimals).comparedTo(secondTokenBalanceInfo.balance) > 0
        ) {
            error = ERROR_NOT_ENOUGH_BALANCE;
        }

        setSecondTokenAmountError(error);
        setSecondTokenAmount(value);

        return error;
    }
    function onMaxFirstTokenAmount() {
        if (isLoggedIn && selectedPool && firstTokenBalanceInfo) {
            onChangeFirstTokenAmount(
                convertWeiToEsdt(firstTokenBalanceInfo.balance, firstTokenBalanceInfo.decimals).toFixed(),
            );
        }
    }
    function onMaxSecondTokenAmount() {
        if (isLoggedIn && selectedPool && secondTokenBalanceInfo) {
            onChangeSecondTokenAmount(
                convertWeiToEsdt(secondTokenBalanceInfo.balance, secondTokenBalanceInfo.decimals).toFixed(),
            );
        }
    }
    async function addInitialLiquidity() {
        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_WALLET);
            return;
        } else if (!selectedPool) {
            toastError(TEXT_SELECT_POOL);
            return;
        }

        await elrondAddInitialLiquidity(selectedPool, firstTokenAmount, secondTokenAmount, address, chainID);
    }

    //
    function onChangeStep() {
        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_WALLET);
            return;
        }
        if (!swapRedux.swapRouterSettings) {
            toastError(ERROR_SC_DATA_NOT_LOADED);
            return;
        } else if (swapRedux.swapRouterSettings.state == StateEnum.Inactive) {
            toastError(ERROR_ROUTER_INACTIVE);
            return;
        }

        if (currentStep === 0) {
            if (!selectedPool) {
                createPair();
            } else {
                // setCurrentStep(1);
                // onCreatePairSuccess(null);
            }
        } else if (currentStep === 1) {
            if (totalFeeError) {
                toastError(totalFeeError);
                return;
            }
            if (specialFeeError) {
                toastError(specialFeeError);
                return;
            }
            if (initialLiquidityAddressError) {
                toastError(initialLiquidityAddressError);
                return;
            }

            pairInitConfiguration();
        } else if (currentStep === 2) {
            if (lpTokenNameError) {
                toastError(lpTokenNameError);
                return;
            }
            if (lpTokenTickerError) {
                toastError(lpTokenTickerError);
                return;
            }
            if (lpTokenDecimalsError) {
                toastError(lpTokenDecimalsError);
                return;
            }

            issueLpToken();
        } else if (currentStep === 3) {
            setLocalRoles();
        } else if (currentStep === 4) {
            addInitialLiquidity();
        } else if (currentStep === 5) {
            navigate(routeNames.pools);
        }
    }

    return (
        <div className="container" style={{ marginTop: '50px' }}>
            <div
                className="text-center mt-5"
                style={{
                    fontSize: '2rem',
                    fontWeight: 300,
                    color: '#eaeaf0',
                }}
            >
                Create Pool
            </div>

            <CreatePoolStepper steps={createPoolSteps} activeStep={currentStep} />

            <div className="d-flex justify-content-center mt-5">
                <div className="vesta_x_swap_card">
                    <div className="d-flex justify-content-center mb-5">
                        <span style={{ color: '#F1DC46', fontSize: '1.2rem' }}>{createPoolSteps[currentStep]}</span>
                    </div>

                    {currentStep === 0 && (
                        <>
                            <div className="mt-4 ms-2" style={{ color: 'white', fontSize: '1rem' }}>
                                First Token
                            </div>
                            <div className="vesta_x_swap_input_box mt-1">
                                <CreatePoolSelectToken
                                    tokenId={firstTokenId}
                                    onChangeTokenId={onChangeFirstTokenId}
                                    isBaseToken={true}
                                />
                            </div>

                            <div className="mt-4 ms-2" style={{ color: 'white', fontSize: '1rem' }}>
                                Second Token
                            </div>
                            <div className="vesta_x_swap_input_box mt-1">
                                <CreatePoolSelectToken
                                    tokenId={secondTokenId}
                                    onChangeTokenId={onChangeSecondTokenId}
                                    isBaseToken={false}
                                />
                                <div className="d-flex justify-content-start mt-1">
                                    <div className="cp-input-token-error ms-3">
                                        {!!selectedPool && 'Pair with given token already exists'}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    {currentStep === 1 && (
                        <>
                            <div className="cp-label-container">
                                <span>First Token:</span> {firstTokenId}
                            </div>
                            <div className="cp-label-container">
                                <span>Second Token:</span> {secondTokenId}
                            </div>
                            <div className="cp-label-container">
                                <span>Pair Address:</span>{' '}
                                {selectedPool && <ElrondAddressLink address={selectedPool.pool_address} />}
                            </div>
                            <div className="cp-label-container">
                                <span>Fee Token:</span> {firstTokenId}
                            </div>

                            <div className="mt-4 ms-2" style={{ color: '#939da7', fontSize: '.9rem' }}>
                                Total Fee
                            </div>
                            <div className="cp-init-input-container my-1">
                                <input
                                    className="cp-init-input text-right"
                                    type="number"
                                    value={totalFee}
                                    onChange={(e) => onChangeTotalFee(Number(e.target.value))}
                                />
                                <span style={{ display: 'inline-block', color: 'white', fontSize: '1.2rem' }}>%</span>
                            </div>
                            <div className="d-flex justify-content-start mt-1">
                                <div className="cp-input-token-error ms-3">{totalFeeError}</div>
                            </div>

                            <div className="mt-2 ms-2" style={{ color: '#939da7', fontSize: '.9rem' }}>
                                Special Fee
                            </div>
                            <div className="cp-init-input-container my-1">
                                <input
                                    className="cp-init-input text-right"
                                    type="number"
                                    value={specialFee}
                                    onChange={(e) => onChangeSpecialFee(Number(e.target.value))}
                                />
                                <span style={{ display: 'inline-block', color: 'white', fontSize: '1.2rem' }}>%</span>
                            </div>
                            <div className="d-flex justify-content-start mt-1">
                                <div className="cp-input-token-error ms-3">{specialFeeError}</div>
                            </div>

                            <div className="mt-2 ms-2" style={{ color: '#939da7', fontSize: '.9rem' }}>
                                Initial Liquidity Address
                            </div>
                            <div className="cp-init-input-container my-1">
                                <input
                                    className="cp-init-input"
                                    style={{ fontSize: '.9rem' }}
                                    value={initialLiquidityAddress}
                                    onChange={(e) => onChangeInitialLiquidityAddress(e.target.value)}
                                />
                            </div>
                            <div className="d-flex justify-content-start mt-1">
                                <div className="cp-input-token-error ms-3">{initialLiquidityAddressError}</div>
                            </div>
                        </>
                    )}

                    {currentStep === 2 && (
                        <>
                            <div className="cp-label-container">
                                <span>First Token:</span> {selectedPool && selectedPool.first_token_id}
                            </div>
                            <div className="cp-label-container">
                                <span>First Token Decimals:</span> {selectedPool && selectedPool.first_token_decimals}
                            </div>

                            <div className="cp-label-container">
                                <span>Second Token:</span> {selectedPool && selectedPool.second_token_id}
                            </div>
                            <div className="cp-label-container">
                                <span>Second Token Decimals:</span> {selectedPool && selectedPool.second_token_decimals}
                            </div>

                            <div className="mt-4 ms-2" style={{ color: '#939da7', fontSize: '.9rem' }}>
                                LP Token Name
                            </div>
                            <div className="cp-init-input-container my-1">
                                <input
                                    className="cp-init-input"
                                    style={{ fontSize: '1.2rem' }}
                                    value={lpTokenName}
                                    onChange={(e) => onChangeLpTokenName(e.target.value)}
                                />
                            </div>
                            <div className="d-flex justify-content-start mt-1">
                                <div className="cp-input-token-error ms-3">{lpTokenNameError}</div>
                            </div>

                            <div className="mt-2 ms-2" style={{ color: '#939da7', fontSize: '.9rem' }}>
                                LP Token Ticker
                            </div>
                            <div className="cp-init-input-container my-1">
                                <input
                                    className="cp-init-input"
                                    style={{ fontSize: '1.2rem' }}
                                    value={lpTokenTicker}
                                    onChange={(e) => onChangeLpTokenTicker(e.target.value)}
                                />
                            </div>
                            <div className="d-flex justify-content-start mt-1">
                                <div className="cp-input-token-error ms-3">{lpTokenTickerError}</div>
                            </div>

                            <div className="mt-2 ms-2" style={{ color: '#939da7', fontSize: '.9rem' }}>
                                LP Token Decimals
                            </div>
                            <div className="cp-init-input-container my-1">
                                <input
                                    className="cp-init-input"
                                    type="number"
                                    value={lpTokenDecimals}
                                    onChange={(e) => onChangeLpTokenDecimals(Number(e.target.value))}
                                />
                            </div>
                            <div className="d-flex justify-content-start mt-1">
                                <div className="cp-input-token-error ms-3">{lpTokenDecimalsError}</div>
                            </div>
                        </>
                    )}

                    {currentStep === 3 && (
                        <>
                            <div className="cp-label-container">
                                <span>LP Token:</span> {selectedPool && selectedPool.lp_token_id}
                            </div>
                            <div className="cp-label-container">
                                <span>LP Token Decimals:</span> {selectedPool && selectedPool.lp_token_decimals}
                            </div>
                        </>
                    )}

                    {currentStep === 4 && (
                        <>
                            <div className="d-flex flex-column gap-1 mt-2">
                                <AddLiquidityInputToken
                                    tokenAmount={firstTokenAmount}
                                    onChangeTokenAmount={onChangeFirstTokenAmount}
                                    tokenId={selectedPool ? selectedPool.first_token_id : ''}
                                    tokenTicker={selectedPool ? createTokenTicker(selectedPool.first_token_id) : ''}
                                    tokenBalance={
                                        selectedPool && firstTokenBalanceInfo
                                            ? convertWeiToEsdt(
                                                  firstTokenBalanceInfo.balance,
                                                  firstTokenBalanceInfo.decimals,
                                              ).toFixed()
                                            : ZERO_STRING
                                    }
                                    onMaxTokenAmount={onMaxFirstTokenAmount}
                                    error={firstTokenAmountError}
                                />

                                <AddLiquidityInputToken
                                    tokenAmount={secondTokenAmount}
                                    onChangeTokenAmount={onChangeSecondTokenAmount}
                                    tokenId={selectedPool ? selectedPool.second_token_id : ''}
                                    tokenTicker={selectedPool ? createTokenTicker(selectedPool.second_token_id) : ''}
                                    tokenBalance={
                                        selectedPool && secondTokenBalanceInfo
                                            ? convertWeiToEsdt(
                                                  secondTokenBalanceInfo.balance,
                                                  secondTokenBalanceInfo.decimals,
                                              ).toFixed()
                                            : ZERO_STRING
                                    }
                                    onMaxTokenAmount={onMaxSecondTokenAmount}
                                    error={secondTokenAmountError}
                                    showBTokenType={true}
                                />
                            </div>

                            <div className="swap-info-box" style={{ marginTop: '3px' }}>
                                <div className="swap-info mt-3">
                                    <div className="swap-info-li">
                                        <span>Exchange Rate</span>
                                        <span>
                                            {!exchangeRate.isZero() && selectedPool && exchangeRate
                                                ? `1 ${createTokenTicker(selectedPool.first_token_id)} ≃ ${formatNumber(
                                                      exchangeRate,
                                                      6,
                                                  )} ${createTokenTicker(selectedPool.second_token_id)}`
                                                : '-'}
                                        </span>
                                    </div>

                                    <div className="swap-info-li">
                                        <span></span>
                                        <span>
                                            {!exchangeRate.isZero() && selectedPool && exchangeRate
                                                ? `${formatNumber(
                                                      parseBigNumber(1).div(exchangeRate),
                                                      6,
                                                  )} ${createTokenTicker(
                                                      selectedPool.first_token_id,
                                                  )} ≃ 1 ${createTokenTicker(selectedPool.second_token_id)}`
                                                : '-'}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    {currentStep === 5 && (
                        <>
                            <div className="mt-4 text-center" style={{ color: 'white', fontSize: '1.2rem' }}>
                                Congratulations! <br />
                                All intial configuration of the pool is done.
                            </div>
                        </>
                    )}

                    <div className="d-flex just-content-center mt-5">
                        <button
                            className="add-liquidity-button"
                            onClick={onChangeStep}
                            disabled={hasPendingTransactions}
                        >
                            {currentStep === 0 && (!!selectedPool ? 'Select Pair' : 'Create Pair')}
                            {currentStep === 1 && 'Initialize'}
                            {currentStep === 2 && 'Issue LP'}
                            {currentStep === 3 && 'Set Roles'}
                            {currentStep === 4 && 'Add Liquidity'}
                            {currentStep === 5 && 'Go To Pools'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
