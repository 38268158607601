import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import { IoClose } from 'react-icons/io5';
import Modal from 'react-modal';

import { selectSlip } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { getCollectionFromApi, getTokenLogo, unremoveSlipPool } from 'z/elrond';
import { useSlipHooks } from 'z/hooks/slip';
import { SlipPoolType } from 'z/types';

const customStyles = {
    content: {
        top: '30%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        width: '50vw',
    },
};

interface AddTokenModalParams {
    isOpen: boolean;
    closeModal(): void;
    tokenIdentifier: string;
    vegldPoolScAddress: string;
    ouroPoolScAddress: string;
}

export const AddTokenModal = ({
    isOpen,
    closeModal,
    tokenIdentifier,
    vegldPoolScAddress,
    ouroPoolScAddress,
}: AddTokenModalParams) => {
    const slipRedux = useAppSelector(selectSlip);

    const [frozenTokenTicker, setFrozenTokenTicker] = useState('');
    const [frozenTokenDisplayName, setFrozenTokenDisplayName] = useState('');
    const [slipPool, setSlipPool] = useState<SlipPoolType | undefined>();
    const [poolType, setPoolType] = useState(0);
    const [isOuroPair, setIsOuroPair] = useState(true);

    const { createSlipPool } = useSlipHooks();

    const getFrTokenDetails = async () => {
        const pool = [slipRedux.basePool, ...slipRedux.pools].find(
            (findPool) => findPool?.tokenIdentifier === tokenIdentifier,
        );
        setSlipPool(pool);
        if (pool) {
            const frTokenDetails = await getCollectionFromApi(pool.frozenLpTokenIdentifier);
            if (frTokenDetails) {
                setFrozenTokenDisplayName(frTokenDetails.name);
                setFrozenTokenTicker(frTokenDetails.ticker);
            }
        }
    };

    useEffect(() => {
        getFrTokenDetails();
    }, [slipRedux, tokenIdentifier]);

    const onCreatePool = async () => {
        if (tokenIdentifier) {
            const confirmation = confirm(`Do you want to create a new pool with this details?
    token identifier: ${tokenIdentifier}
    frozen token tiker: ${frozenTokenTicker}
    frozen token name: ${frozenTokenDisplayName}
    vegld sc address: ${vegldPoolScAddress}
    ouro sc address: ${ouroPoolScAddress}
            `);
            if (confirmation)
                await createSlipPool(
                    tokenIdentifier,
                    vegldPoolScAddress,
                    ouroPoolScAddress,
                    frozenTokenTicker,
                    frozenTokenDisplayName,
                    poolType,
                    isOuroPair,
                );
        }
    };

    return (
        <Modal isOpen={isOpen} style={customStyles} onRequestClose={closeModal}>
            <div
                style={{
                    padding: '0px 15px',
                    borderBottom: '1px solid rgba(255,255,255,0.06)',
                }}
            >
                <div className="d-flex justify-content-between align-items-center">
                    <span
                        style={{
                            color: '#F1DC46',
                            marginLeft: '10px',
                            fontSize: '1.25rem',
                        }}
                    >
                        Add token to SLIP
                    </span>

                    <div className="modal-close-but" onClick={closeModal}>
                        <IoClose />
                    </div>
                </div>
                <hr className="my-3" />
                <Row className="align-items-center gy-4">
                    <Col lg={2}>
                        <img src={getTokenLogo(tokenIdentifier)} alt="logo" width="50px" />
                    </Col>
                    <Col lg={10}>{tokenIdentifier} token detected</Col>
                    <Col lg={1}></Col>
                    <Col lg={11}>
                        {!slipPool && 'No '}Frozen LP Definition detected for {tokenIdentifier}
                    </Col>
                    <Col lg={6}>
                        <input
                            className="vesta_x_input text-right"
                            placeholder="Frozen token name"
                            value={frozenTokenDisplayName}
                            onChange={(e) => setFrozenTokenDisplayName(e.target.value)}
                        />
                    </Col>
                    <Col lg={6}>
                        <input
                            className="vesta_x_input text-right"
                            placeholder="Frozen token tiker"
                            value={frozenTokenTicker}
                            onChange={(e) => setFrozenTokenTicker(e.target.value)}
                        />
                    </Col>
                    <Col lg={12}>
                        <span style={{ marginRight: '1rem' }}>Pool type:</span>
                        <FormControlLabel
                            control={<Checkbox checked={poolType === 0} onClick={() => setPoolType(0)} />}
                            label="Bronze"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={poolType === 1} onClick={() => setPoolType(1)} />}
                            label="Silver"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={poolType === 2} onClick={() => setPoolType(2)} />}
                            label="Gold"
                        />
                    </Col>
                    <Col>
                        <Checkbox checked={isOuroPair} onClick={() => setIsOuroPair((prev) => !prev)} />
                        {`OURO swap pair should${isOuroPair ? '' : "n't"} be considered for Constraints 2.3`}
                    </Col>
                </Row>
                <div
                    style={{
                        marginTop: '15px',
                        display: 'flex',
                    }}
                >
                    {slipPool && (
                        <button
                            className="slip-but"
                            style={{
                                width: '18vw',
                                margin: 'auto',
                            }}
                            onClick={() => unremoveSlipPool(tokenIdentifier)}
                        >
                            Keep Frozen LP Definition and add {tokenIdentifier} to SLIP
                        </button>
                    )}
                    <button
                        className="slip-but"
                        style={{
                            width: '18vw',
                            margin: 'auto',
                        }}
                        onClick={onCreatePool}
                    >
                        Add {tokenIdentifier} to SLIP
                        {slipPool && ' with new Frozen Lp Definition'}
                    </button>
                </div>
            </div>
        </Modal>
    );
};
