import {
    Address,
    AddressValue,
    ArgSerializer,
    BigUIntValue,
    StringValue,
    TokenIdentifierValue,
    TokenTransfer,
    TypedValue,
    U32Value,
    U64Value,
} from '@multiversx/sdk-core/out';
import { EAURYN_TOKEN_ID, ELITE_ACCOUNT_TRACKER_SC_ADDRESS, VEAURYN_TOKEN_ID } from 'config';
import { applyPrecision } from 'z/utils';
import { elrondDappSendTransactions, mvxQuery } from './common';
import { eliteAccountTrackerSmartContract } from './provider';

export async function eatGetEliteAccountTier(targetAddress: string): Promise<number> {
    try {
        const args: TypedValue[] = [new AddressValue(new Address(targetAddress))];
        const value = await mvxQuery(eliteAccountTrackerSmartContract.methods.getEliteAccountTier(args));
        const decoded = value.toNumber();

        return decoded;
    } catch (err) {
        console.error('eatGetEliteAccountTier', err);
        return 0;
    }
}

export async function eatGetDeb(targetAddress: string): Promise<number> {
    try {
        const args: TypedValue[] = [new AddressValue(new Address(targetAddress))];
        const value = await mvxQuery(eliteAccountTrackerSmartContract.methods.getDeb(args));
        const decoded = applyPrecision(value.toNumber() / 100_000, 4);

        return decoded;
    } catch (err) {
        console.error('eatGetDeb', err);
        return 0;
    }
}

export async function eatUpdateEliteAccount(payments: TokenTransfer[], sender: string) {
    if (payments.length > 0) {
        const args: TypedValue[] = [
            new AddressValue(new Address(ELITE_ACCOUNT_TRACKER_SC_ADDRESS)),
            new U32Value(payments.length),
        ];
        payments.map((payment) => {
            args.push(new TokenIdentifierValue(payment.tokenIdentifier));
            args.push(new U64Value(payment.nonce));
            args.push(new BigUIntValue(payment.amountAsBigInteger));
        });
        args.push(new StringValue('updateEliteAccount'));

        const { argumentsString } = new ArgSerializer().valuesToString(args);
        const data = `MultiESDTNFTTransfer@${argumentsString}`;

        const tx = {
            value: 0,
            data,
            receiver: sender,
            gasLimit: 150_000_000 + 3_000_000 * payments.length,
        };

        const txName = 'Update EA';
        const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

        return { sessionId, error };
    } else {
        const data = 'updateVaults';
        const tx = {
            value: 0,
            data,
            receiver: ELITE_ACCOUNT_TRACKER_SC_ADDRESS,
            gasLimit: 50_000_000,
        };

        const txName = 'Update EA';
        const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

        return { sessionId, error };
    }
}

export const eatGetFvstMultiplier = async (tier: number): Promise<number> => {
    try {
        const args: TypedValue[] = [new U32Value(tier)];
        const value = await mvxQuery(eliteAccountTrackerSmartContract.methods.getFvstMultiplier(args));
        const decoded = value.toNumber();

        return decoded;
    } catch (err) {
        console.error('eatGetFvstMultiplier', err);
        return 0;
    }
};

export const eatGetTokenBalance = async (address: string, tokenIdentifier: string): Promise<string> => {
    try {
        let fn: string = '';
        switch (tokenIdentifier) {
            case EAURYN_TOKEN_ID:
                fn = 'getEaurynBalance';
                break;
            case VEAURYN_TOKEN_ID:
                fn = 'getVeaurynBalance';
                break;
            default:
                throw new Error('Token not defined');
        }
        const value = await mvxQuery(eliteAccountTrackerSmartContract.methods[fn]([address]));

        return value.toString();
    } catch (err) {
        console.error('eatGetTokenBalance', err);
        return '0';
    }
};
