import React, { useEffect, useState } from 'react';
import { TokenTransfer } from '@multiversx/sdk-core/out';
import {
    useGetAccount,
    useGetLoginInfo,
    useGetPendingTransactions,
} from '@multiversx/sdk-dapp/hooks';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { TbExchange } from 'react-icons/tb';
import { useSearchParams } from 'react-router-dom';
import { SlippageSetting } from 'components/SlippageSetting';
import { STABLESWAP_TOKENS } from 'config';
import { selectSwap } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import {
    getTokenBalanceFromApi,
    getTokenDecimals,
} from 'z/elrond';
import {
    StableswapContract,
    StableswapSwapResult,
} from 'z/elrond/stableswap';
import {
    applySlippage,
    formatNumber,
    convertEsdtToWei,
    convertWeiToEsdt,
    ERROR_INVALID_NUMBER,
    ERROR_NOT_ENOUGH_BALANCE,
    isPositiveOrZeroBigNumber,
    parseBigNumber,
    ERROR_CONNECT_WALLET,
    TEXT_SELECT_TOKEN,
    toastError,
    ZERO_STRING,
    isPositiveBigNumber,
    ERROR_TRANSACTION_ONGOING,
    LOADING_SWAP_PRICE_DEBOUNCE_PERIOD,
    createTokenTicker,
} from 'z/utils';
import { SwapTokenSelect } from './SwapTokenSelect';

function printFee(swapResult: StableswapSwapResult, decimals: number): string {
    return formatNumber(convertWeiToEsdt(swapResult.total_fee, decimals).toNumber()) + ' ' + createTokenTicker(swapResult.payment_out.token_identifier);
}

export const Stableswap = () => {
    const { hasPendingTransactions } = useGetPendingTransactions();
    const { address } = useGetAccount();
    const { isLoggedIn } = useGetLoginInfo();
    const [searchParams, setSearchParams] = useSearchParams();
    const firstTokenId = searchParams.get('firstToken') ?? '';
    const secondTokenId = searchParams.get('secondToken') ?? '';

    const swapRedux = useAppSelector(selectSwap);
    const [firstTokenAmount, setFirstTokenAmount] = useState<string>(ZERO_STRING);
    const [secondTokenAmount, setSecondTokenAmount] = useState<string>(ZERO_STRING);
    const [firstTokenBalance, setFirstTokenBalance] = useState<string>(ZERO_STRING);
    const [secondTokenBalance, setSecondTokenBalance] = useState<string>(ZERO_STRING);
    const [firstTokenAmountError, setFirstTokenAmountError] = useState<string>('');
    const [secondTokenAmountError, setSecondTokenAmountError] = useState<string>('');
    const [showSwapDetails, setShowSwapDetails] = useState<boolean>(false);
    const [swapResult, setSwapResult] = useState<StableswapSwapResult>();

    // query balance of input and output tokens
    useEffect(() => {
        if (hasPendingTransactions) return;
        if (address && firstTokenId) {
            (async () => {
                const _tokenBalanceInfo = await getTokenBalanceFromApi(address, firstTokenId);
                setFirstTokenBalance(_tokenBalanceInfo ? _tokenBalanceInfo.balance : ZERO_STRING);
            })();
        } else {
            setFirstTokenBalance(ZERO_STRING);
        }
    }, [address, hasPendingTransactions, firstTokenId]);

    useEffect(() => {
        if (hasPendingTransactions) return;
        if (address && secondTokenId) {
            (async () => {
                const _tokenBalanceInfo = await getTokenBalanceFromApi(address, secondTokenId);
                setSecondTokenBalance(_tokenBalanceInfo ? _tokenBalanceInfo.balance : ZERO_STRING);
            })();
        } else {
            setSecondTokenBalance(ZERO_STRING);
        }
    }, [address, hasPendingTransactions, secondTokenId]);

    const [exchangeRate, setExchangeRate] = useState<string>('');
    const [reverseExchangeRate, setReverseExchangeRate] = useState<string>('');
    const [minimumReceived, setMinimumReceived] = useState<string>(ZERO_STRING);

    //
    function resetInputs() {
        setFirstTokenAmount(ZERO_STRING);
        setSecondTokenAmount(ZERO_STRING);
        setMinimumReceived(ZERO_STRING);
        setFirstTokenAmountError('');
        setSecondTokenAmountError('');
        setSwapResult(undefined);
    }

    const contract = new StableswapContract();
    async function loadExchangeRate() {
        if (!(firstTokenId && secondTokenId)) return;

        const _swapResult = await contract.estimateSwap(
            firstTokenId,
            secondTokenId,
            convertEsdtToWei(1, getTokenDecimals(firstTokenId)),
        );

        if (_swapResult) {
            const _exchangeRate = convertWeiToEsdt(
                _swapResult.payment_out.amount,
                getTokenDecimals(secondTokenId),
                getTokenDecimals(secondTokenId),
            );
            if (_exchangeRate.isGreaterThan(0)) {
                setExchangeRate(_exchangeRate.toFixed());
                setReverseExchangeRate(parseBigNumber(1).div(_exchangeRate).toFixed());
                return;
            }
        }
        
        setExchangeRate('');
        setReverseExchangeRate('');
    }

    useEffect(() => {
        resetInputs();
        loadExchangeRate();

        const delayDebounceFn = setInterval(() => {
            loadExchangeRate();
        }, LOADING_SWAP_PRICE_DEBOUNCE_PERIOD);
    
        return () => clearInterval(delayDebounceFn);
    }, [firstTokenId, secondTokenId, hasPendingTransactions]);

    useEffect(() => {
        onActiveChangeFirstTokenAmount(undefined);
    }, [exchangeRate]);

    //
    function onChangeFirstTokenAmount(value: string) {
        let error = '';
        if (!firstTokenId) {
            error = TEXT_SELECT_TOKEN;
        } else if (!isPositiveOrZeroBigNumber(value)) {
            error = ERROR_INVALID_NUMBER;
        } else if (
            isLoggedIn
            && convertEsdtToWei(value, getTokenDecimals(firstTokenId)).comparedTo(firstTokenBalance) > 0
        ) {
            error = ERROR_NOT_ENOUGH_BALANCE;
        }

        setFirstTokenAmountError(error);
        setFirstTokenAmount(value);

        return error;
    }

    function onChangeSecondTokenAmount(value: string) {
        let error = '';
        if (!secondTokenId) {
            error = TEXT_SELECT_TOKEN;
        } else if (!isPositiveOrZeroBigNumber(value)) {
            error = ERROR_INVALID_NUMBER;
        }

        setSecondTokenAmountError(error);
        setSecondTokenAmount(value);

        return error;
    }

    //
    function onActiveChangeFirstTokenAmount(value: string | undefined) {
        if (value != null) {
            const error = onChangeFirstTokenAmount(value);

            if (!error || error == ERROR_NOT_ENOUGH_BALANCE) {
                calculateFixedIn(value);
            }
        } else {
            calculateFixedIn(firstTokenAmount);
        }
    }

    async function calculateFixedIn(amountIn: string) {
        if (!(firstTokenId && secondTokenId)) return;
        if (parseBigNumber(amountIn).isZero()) {
            setSecondTokenAmount(ZERO_STRING);
            setMinimumReceived(ZERO_STRING);
            return;
        }

        const _swapResult = await contract.estimateSwap(
            firstTokenId,
            secondTokenId,
            convertEsdtToWei(amountIn, getTokenDecimals(firstTokenId)),
        );
        setSwapResult(_swapResult);

        onChangeSecondTokenAmount(
            _swapResult ?
                convertWeiToEsdt(
                    _swapResult.payment_out.amount,
                    getTokenDecimals(secondTokenId),
                    getTokenDecimals(secondTokenId)
                ).toFixed()
                : ZERO_STRING
        );
        setMinimumReceived(
            _swapResult ?
                convertWeiToEsdt(
                    applySlippage(_swapResult.payment_out.amount, -swapRedux.slippage),
                    getTokenDecimals(secondTokenId)
                ).toFixed()
                : ZERO_STRING
        );
    }

    //
    function onChangeFirstTokenId(value: string) {
        setSearchParams({
            'firstToken': value,
            'secondToken': secondTokenId,
        });
    }

    function onChangeSecondTokenId(value: string) {
        setSearchParams({
            'firstToken': firstTokenId,
            'secondToken': value
        });
    }

    const onExchangeTokensPosition = () => {
        // both of tokens should be selected
        if (!firstTokenId || !secondTokenId) return;

        setSearchParams({
            'firstToken': secondTokenId,
            'secondToken': firstTokenId,
        });
    };

    function onMaxFirstTokenAmount() {
        if (isLoggedIn && firstTokenId && getTokenDecimals(firstTokenId)) {
            onActiveChangeFirstTokenAmount(
                convertWeiToEsdt(
                    firstTokenBalance,
                    getTokenDecimals(firstTokenId),
                    getTokenDecimals(firstTokenId),
                ).toFixed()
            );
        }
    }

    //
    async function onClickSwap() {
        let error = '';
        if (!isLoggedIn) {
            error = ERROR_CONNECT_WALLET;
        } else if (!firstTokenId || !secondTokenId) {
            error = TEXT_SELECT_TOKEN;
        } else if (!isPositiveBigNumber(firstTokenAmount)) {
            error = ERROR_INVALID_NUMBER;
        } else if (hasPendingTransactions) {
            error = ERROR_TRANSACTION_ONGOING;
        }

        if (error) {
            toastError(error);
            return;
        }
        if (firstTokenAmountError) {
            toastError(firstTokenAmountError);
            return;
        }
        if (secondTokenAmountError) {
            toastError(secondTokenAmountError);
            return;
        }

        await contract.swap(
            TokenTransfer.fungibleFromAmount(firstTokenId, firstTokenAmount, getTokenDecimals(firstTokenId)),
            secondTokenId,
            convertEsdtToWei(minimumReceived, getTokenDecimals(secondTokenId)),
            address
        );
    }

    return (
        <>
            <div className="vesta_x_swap_card" style={{ maxWidth: '450px' }}>
                <div className="d-flex justify-content-between align-items-center">
                    <span
                        style={{
                            color: '#F1DC46',
                            marginLeft: '15px',
                            fontSize: '1.25rem',
                        }}
                    >
                        Stable Swap
                    </span>

                    <SlippageSetting />
                </div>

                <div className="mt-3">
                    <div className="vesta_x_swap_input_box">
                        <div className="d-flex align-items-center">
                            <input
                                className="swap_input"
                                type="number"
                                value={firstTokenAmount}
                                onChange={(e) =>
                                    onActiveChangeFirstTokenAmount(e.target.value)
                                }
                            />
                            <SwapTokenSelect
                                tokenId={firstTokenId}
                                onChangeTokenId={onChangeFirstTokenId}
                                oppositeTokenId={secondTokenId}
                                swapAvailabletokenIds={STABLESWAP_TOKENS}
                            />
                        </div>
                        <div className="d-flex justify-content-between mt-1">
                            <div className="input-token-error">
                                {firstTokenAmountError}
                            </div>
                            <div
                                className="add-liquidity-input-token-balance-box"
                                onClick={onMaxFirstTokenAmount}
                            >
                                {
                                    firstTokenId && (<>
                                        <div className="">Balance:&nbsp;</div>
                                        <div style={{ color: 'white' }}>
                                            {
                                                formatNumber(
                                                    convertWeiToEsdt(
                                                        firstTokenBalance,
                                                        getTokenDecimals(firstTokenId),
                                                        getTokenDecimals(firstTokenId)
                                                    ),
                                                    getTokenDecimals(firstTokenId)
                                                )
                                            }
                                        </div>
                                    </>)
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="d-flex justify-content-center"
                    style={{ marginTop: '-17px', zIndex: '2' }}
                >
                    <button
                        className="exchange_but"
                        onClick={onExchangeTokensPosition}
                        disabled={hasPendingTransactions}
                    >
                        <TbExchange />
                    </button>
                </div>

                <div style={{ marginTop: '-17px' }}>
                    <div className="vesta_x_swap_input_box BToken_Input">
                        <div className="d-flex align-items-center">
                            <input
                                className="swap_input"
                                type="number"
                                value={secondTokenAmount}
                                disabled={true}
                            />
                            <SwapTokenSelect
                                tokenId={secondTokenId}
                                onChangeTokenId={onChangeSecondTokenId}
                                oppositeTokenId={firstTokenId}
                                swapAvailabletokenIds={STABLESWAP_TOKENS}
                            />
                        </div>
                        {
                            secondTokenId && (
                                <div className="d-flex justify-content-between mt-1">
                                    <div className="input-token-error">
                                        {secondTokenAmountError}
                                    </div>
                                    <span className="add-liquidity-input-token-balance-box">
                                        <div className="">Balance:&nbsp;</div>
                                        <div style={{ color: 'white' }}>
                                            {
                                                formatNumber(
                                                    convertWeiToEsdt(
                                                        secondTokenBalance,
                                                        getTokenDecimals(secondTokenId),
                                                        getTokenDecimals(secondTokenId),
                                                    ),
                                                    getTokenDecimals(secondTokenId),
                                                )
                                            }
                                        </div>
                                    </span>
                                </div>
                            )
                        }
                    </div>
                </div>

                <div className="swap-info-box" style={{ marginTop: '3px' }}>
                    <div className="swap-info mt-3">
                        <div className="swap-info-li">
                            <span>Exchange Route</span>
                            <span>
                                {!!firstTokenId && !!secondTokenId && `${firstTokenId} > ${secondTokenId}`}
                            </span>
                        </div>

                        <div className="swap-info-li">
                            <span>Exchange Rate</span>
                            <span>
                                {
                                    exchangeRate
                                        ? `1 ${createTokenTicker(firstTokenId)} ≃ ${formatNumber(exchangeRate, 6)} ${createTokenTicker(secondTokenId)}`
                                        : '-'
                                }
                            </span>
                        </div>

                        <div className="swap-info-li">
                            <span></span>
                            <span>
                                {
                                    reverseExchangeRate
                                        ? `${formatNumber(reverseExchangeRate, 6)} ${createTokenTicker(firstTokenId)} ≃ 1 ${createTokenTicker(secondTokenId)}`
                                        : '-'
                                }
                            </span>
                        </div>

                        <div className="swap-info-li">
                            <span>Slippage</span>
                            <span>{swapRedux.slippage + '%'}</span>
                        </div>

                        <div className="swap-info-li">
                            <span>Minimum Received</span>
                            <span>
                                {
                                    `${formatNumber(minimumReceived)} ${createTokenTicker(secondTokenId)}`
                                }
                            </span>
                        </div>

                        {
                            showSwapDetails && (<>
                                <div className="border-top my-2"></div>

                                <div className="swap-info-li">
                                    <span>Fee</span>
                                    <span>{swapResult ? printFee(swapResult, getTokenDecimals(swapResult.payment_out.token_identifier)) : '-'}</span>
                                </div>

                                <div className="swap-info-li">
                                    <span>Price Impact</span>
                                    <span>-</span>
                                </div>
                            </>)
                        }

                        <div
                            className="my-2 border-top pt-2 text-center cursor-pointer"
                            role="button"
                            onClick={() => {
                                setShowSwapDetails(!showSwapDetails);
                            }}
                        >
                            {
                                showSwapDetails ? (<>
                                    <span className="mr-1">Less details</span>
                                    <BiChevronUp />
                                </>) : (<>
                                    <span className="mr-1">More details</span>
                                    <BiChevronDown />
                                </>)
                            }
                        </div>
                    </div>
                </div>

                <div className="d-flex just-content-center">
                    <button
                        className="mt-3 add-liquidity-button"
                        onClick={onClickSwap}
                    >
                        Swap
                    </button>
                </div>
            </div>
        </>
    );
};
