import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { find, forEach, reduce } from 'lodash';
import { OURO_VAULT_SWAP_POOLS } from 'config/ouro-liquidity-vault';
import { RootState } from 'redux/store';
import {
    elrondViewPaginationSwapPools,
    getOuroLiquidityVaultSnapshots,
    getOuroVaultRewards,
    getOuroVaultEliteComponentPower,
    liquidityVaultViewBaseContext,
    liquidityVaultViewStatsContext,
    liquidityVaultViewUserContext,
} from 'z/elrond';
import {
    NftStakingRewards,
    OuroLiquidityVaultBaseContextType,
    OuroLiquidityVaultStatsContextType,
    OuroLiquidityVaultUserContextType,
    SwapPoolType,
    OuroLiquidityVaultElitePowersType,
    OuroLiquidityVaultUserElitePowersType,
} from 'z/types';
import { BIG_NUMBER_ZERO, convertWeiToEsdt } from 'z/utils';

interface OuroLiquidityVaultState {
    baseContext?: OuroLiquidityVaultBaseContextType;
    statsContext?: OuroLiquidityVaultStatsContextType;
    userContext?: OuroLiquidityVaultUserContextType;
    totalPremiumPower?: string;
    rewards?: NftStakingRewards;
    userElitePowers?: OuroLiquidityVaultUserElitePowersType;
    totalElitePowers?: OuroLiquidityVaultElitePowersType;
}

const initialState: OuroLiquidityVaultState = {};

export const ouroLiquidityVaultSlice = createSlice({
    name: 'ouroLiquidityVault',
    initialState,
    reducers: {
        setOuroLiquidityVaultBaseContext: (
            state: OuroLiquidityVaultState,
            action: PayloadAction<OuroLiquidityVaultBaseContextType | undefined>,
        ) => {
            state.baseContext = action.payload;
        },
        setOuroLiquidityVaultStateContext: (
            state: OuroLiquidityVaultState,
            action: PayloadAction<OuroLiquidityVaultStatsContextType | undefined>,
        ) => {
            state.statsContext = action.payload;
        },
        setOuroLiquidityVaultUserContext: (
            state: OuroLiquidityVaultState,
            action: PayloadAction<OuroLiquidityVaultUserContextType | undefined>,
        ) => {
            state.userContext = action.payload;
        },
        setOuroLiquidityTotalPremiumPower: (
            state: OuroLiquidityVaultState,
            action: PayloadAction<string | undefined>,
        ) => {
            state.totalPremiumPower = action.payload;
        },
        setOuroLiquidityTotalElitePowers: (
            state: OuroLiquidityVaultState,
            action: PayloadAction<OuroLiquidityVaultElitePowersType | undefined>,
        ) => {
            state.totalElitePowers = action.payload;
        },
        setRewards: (state: OuroLiquidityVaultState, action: PayloadAction<NftStakingRewards | undefined>) => {
            state.rewards = action.payload;
        },
        setUserElitePowers: (
            state: OuroLiquidityVaultState,
            action: PayloadAction<OuroLiquidityVaultUserElitePowersType | undefined>,
        ) => {
            state.userElitePowers = action.payload;
        },
    },
});

export const selectOuroLiquidityVault = (state: RootState) => state.ouroLiquidityVault;
export const ouroLiquidityVaultReducer = ouroLiquidityVaultSlice.reducer;

export const setOuroLiquidityVaultBaseContext = async (dispatch: Dispatch) => {
    const vaultBaseContext = await liquidityVaultViewBaseContext();
    if (vaultBaseContext) dispatch(ouroLiquidityVaultSlice.actions.setOuroLiquidityVaultBaseContext(vaultBaseContext));
};

export const setOuroLiquidityVaultStateContext = async (dispatch: Dispatch) => {
    const vaultStateContext = await liquidityVaultViewStatsContext();
    if (vaultStateContext)
        dispatch(ouroLiquidityVaultSlice.actions.setOuroLiquidityVaultStateContext(vaultStateContext));
};

export const setOuroLiquidityVaultUserContext = async (address: string, dispatch: Dispatch) => {
    const vaultUserContext = await liquidityVaultViewUserContext(address);
    if (vaultUserContext) dispatch(ouroLiquidityVaultSlice.actions.setOuroLiquidityVaultUserContext(vaultUserContext));
};

export const setOuroLiquidityVaultTotalPremiumAndElitePowers = async (dispatch: Dispatch) => {
    const vaultSnapshots = await getOuroLiquidityVaultSnapshots();
    const swapPools = await elrondViewPaginationSwapPools(false);

    const swapOuroPools: Record<string, SwapPoolType> = {};
    forEach(OURO_VAULT_SWAP_POOLS, ({ lp_token_id, swap_pool_address }) => {
        const swapPool = find(swapPools, ({ pool_address }) => pool_address === swap_pool_address);
        if (swapPool) swapOuroPools[lp_token_id] = swapPool;
    });

    const {
        totalPremiumPower,
        totalMajorGoldenPower,
        totalMajorSilverPower,
        totalMajorBronzePower,
        totalMinorGoldenPower,
        totalMinorSilverPower,
        totalMinorBronzePower,
    } = reduce(
        vaultSnapshots,
        (
            prev1,
            {
                deb,
                staked_xbunnies_nonces,
                slip_multiplier,
                standard_vesta_power,
                dex_vesta_power,
                xexchange_vesta_power,
            },
        ) => {
            const userPremiumPower = new BigNumber(standard_vesta_power).multipliedBy(deb);
            const userDexVestaPower = convertWeiToEsdt(dex_vesta_power).multipliedBy(deb);
            const userXexchangeVestaPower = convertWeiToEsdt(xexchange_vesta_power).multipliedBy(deb);

            const {
                majorGoldenPower,
                minorGoldenPower,
                majorSilverPower,
                minorSilverPower,
                majorBronzePower,
                minorBronzePower,
            } = getUserElitePowers(
                userDexVestaPower,
                userXexchangeVestaPower,
                staked_xbunnies_nonces || [],
                slip_multiplier,
            );

            return {
                totalPremiumPower: BigNumber.sum(prev1.totalPremiumPower, userPremiumPower),
                totalMajorGoldenPower: BigNumber.sum(prev1.totalMajorGoldenPower, majorGoldenPower),
                totalMajorSilverPower: BigNumber.sum(prev1.totalMajorSilverPower, majorSilverPower),
                totalMajorBronzePower: BigNumber.sum(prev1.totalMajorBronzePower, majorBronzePower),
                totalMinorGoldenPower: BigNumber.sum(prev1.totalMinorGoldenPower, minorGoldenPower),
                totalMinorSilverPower: BigNumber.sum(prev1.totalMinorSilverPower, minorSilverPower),
                totalMinorBronzePower: BigNumber.sum(prev1.totalMinorBronzePower, minorBronzePower),
            };
        },
        {
            totalPremiumPower: BIG_NUMBER_ZERO,
            totalMajorGoldenPower: BIG_NUMBER_ZERO,
            totalMajorSilverPower: BIG_NUMBER_ZERO,
            totalMajorBronzePower: BIG_NUMBER_ZERO,
            totalMinorGoldenPower: BIG_NUMBER_ZERO,
            totalMinorSilverPower: BIG_NUMBER_ZERO,
            totalMinorBronzePower: BIG_NUMBER_ZERO,
        },
    );

    dispatch(ouroLiquidityVaultSlice.actions.setOuroLiquidityTotalPremiumPower(totalPremiumPower.toString()));
    dispatch(
        ouroLiquidityVaultSlice.actions.setOuroLiquidityTotalElitePowers({
            totalMajorGoldenPower: totalMajorGoldenPower.toString(),
            totalMajorSilverPower: totalMajorSilverPower.toString(),
            totalMajorBronzePower: totalMajorBronzePower.toString(),
            totalMinorGoldenPower: totalMinorGoldenPower.toString(),
            totalMinorSilverPower: totalMinorSilverPower.toString(),
            totalMinorBronzePower: totalMinorBronzePower.toString(),
        }),
    );
};

export const setOuroVaultRewards = async (address: string, dispatch: Dispatch) => {
    const _rewards = await getOuroVaultRewards(address);
    dispatch(ouroLiquidityVaultSlice.actions.setRewards(_rewards));
};

export const setOuroVaultUserElitePowers = async (address: string, dispatch: Dispatch) => {
    const userElitePowers = await getOuroVaultEliteComponentPower(address);
    dispatch(ouroLiquidityVaultSlice.actions.setUserElitePowers(userElitePowers));
};

const getUserElitePowers = (
    userDexVestaPower: BigNumber,
    userXexchangeVestaPower: BigNumber,
    staked_xbunnies_nonces: number[],
    slip_multiplier: string,
) => {
    const xbunnies = getXbunniesComponentPower(staked_xbunnies_nonces);
    const dova = { yin: 0, yang: 0, dova: 0 };

    const majorGoldenPower = userDexVestaPower.multipliedBy(xbunnies.goldenPower).div(100);
    const minorGoldenPower = userXexchangeVestaPower.multipliedBy(xbunnies.goldenPower).div(100);
    const silverMultiplier = BigNumber.sum(
        BigNumber.sum(dova.yin || '0', dova.yang || '0').multipliedBy(0.1),
        dova.dova || '0',
        new BigNumber(slip_multiplier).minus(1000000).shiftedBy(-4),
        xbunnies.silverPower,
    );
    const majorSilverPower = userDexVestaPower.multipliedBy(BigNumber.sum(1, silverMultiplier.div(100)));
    const minorSilverPower = userXexchangeVestaPower.multipliedBy(BigNumber.sum(1, silverMultiplier.div(100)));

    const majorBronzePower = userDexVestaPower.multipliedBy(xbunnies.bronzePower).div(100);
    const minorBronzePower = userXexchangeVestaPower.multipliedBy(xbunnies.bronzePower).div(100);

    return {
        majorGoldenPower,
        minorGoldenPower,
        majorSilverPower,
        minorSilverPower,
        majorBronzePower,
        minorBronzePower,
    };
};

const getXbunniesComponentPower = (stakedXbunniesNonces: number[]) =>
    stakedXbunniesNonces.reduce(
        (
            res: {
                goldenPower: BigNumber;
                silverPower: BigNumber;
                bronzePower: BigNumber;
            },
            nonce,
        ) => {
            if (LEGENDARY_NONCES.includes(nonce)) {
                return { ...res, goldenPower: BigNumber.sum(res.goldenPower, LEGENDARY_POWER_PER_NONCE) };
            }
            if (ELITE_AURYN_NONCES.includes(nonce)) {
                return { ...res, goldenPower: BigNumber.sum(res.goldenPower, ELITE_AURYN_POWER_PER_NONCE) };
            }
            if (AURYN_NONCES.includes(nonce)) {
                return { ...res, silverPower: BigNumber.sum(res.silverPower, AURYN_POWER_PER_NONCE) };
            }
            if (OURO_NONCES.includes(nonce)) {
                return { ...res, bronzePower: BigNumber.sum(res.bronzePower, OURO_POWER_PER_NONCE) };
            }
            return res;
        },
        {
            goldenPower: new BigNumber(0),
            silverPower: new BigNumber(0),
            bronzePower: new BigNumber(0),
        },
    );

const LEGENDARY_NONCES = [388, 407, 25, 880, 274];
const LEGENDARY_POWER_PER_NONCE = 40;
const ELITE_AURYN_NONCES = [1095, 1033, 954, 873, 175];
const ELITE_AURYN_POWER_PER_NONCE = 60;
const AURYN_NONCES = [1063, 967, 923, 870, 531, 500, 460, 432, 377, 371, 273, 251, 248, 247, 171, 128, 95, 90, 85, 75];
const AURYN_POWER_PER_NONCE = 10;
const OURO_NONCES = [
    1059, 944, 932, 896, 885, 847, 811, 809, 798, 776, 767, 742, 734, 720, 672, 664, 639, 625, 623, 600, 580, 557, 552,
    514, 506, 472, 470, 429, 421, 417, 401, 390, 358, 319, 290, 280, 250, 216, 215, 214, 198, 174, 170, 143, 139, 127,
    93, 66, 57, 30,
];
const OURO_POWER_PER_NONCE = 5;
