import { FarmType, StakedLpTokenType } from '../z/types';
import { config } from './elrond';
import { findLpTokenByAddress } from './swap';
import { RAWVST_TOKEN_ID, VST_TOKEN_ID, SVST_TOKEN_ID, FVST_TOKEN_ID } from './tokens';

export const STAKE_LPS_GAS_LIMIT = 80_000_000;
export const CLAIM_REWARDS_GAS_LIMIT = 150_000_000;
export const MINT_VESTA_GAS_LIMIT = 150_000_000;

const data: FarmType[] = config.contracts['farms'] as FarmType[];

const transformFarmData = (farms: FarmType[]) => {
    return farms.map((farm, index) => ({
        ...farm,
        index: index,
        pool_lp_token: findLpTokenByAddress(farm.pool_address) ?? '',
    }));
};

export const FARMS = transformFarmData(data);

export function findFarmByAddress(searchAddress: string): FarmType | undefined {
    const foundFarm = data.find((farm) => farm.farm_address === searchAddress);
    return foundFarm;
}

export const VLP_WEIGHTS = [1, 1.6, 2.5, 0.5, 0.96, 1.75];

export const RAW_VESTA_MINTER_SC_ADDRESS = config.contracts['raw-vesta-minter'].address;
export const VESTA_MINTER_SC_ADDRESS = config.contracts['treasury'].address;
export const VESTA_TOKEN_IDS = [
    RAWVST_TOKEN_ID, // Raw VESTA
    VST_TOKEN_ID, // VESTA
    SVST_TOKEN_ID, // Sleeping VESTA
    FVST_TOKEN_ID, // Frozen VESTA
];

export const getSlipingVestaMultiplier = (vestaSleepingYears: number): number =>
    Math.pow(1.2, vestaSleepingYears - vestaSleepingYears * vestaSleepingYears * 0.01);

/*
  farm token type
    1 bronze
    2 silver
    3 gold
    4 UnbondingBronze
    5 UnbondingSilver
    6 UnbondingGold
*/

// only for unbonding token types (4, 5, 6)
export const FARM_TOKEN_UNLOCK_EPOCHS_MAP: Record<number, number> = {
    [StakedLpTokenType.UnbondingBronze]: 20,
    [StakedLpTokenType.UnbondingSilver]: 64,
    [StakedLpTokenType.UnbondingGold]: 210,
};

export const FARM_TOKEN_TYPE_NAMES_MAP: Record<number, string> = {
    [StakedLpTokenType.Bronze]: 'Bronze',
    [StakedLpTokenType.Silver]: 'Silver',
    [StakedLpTokenType.Gold]: 'Gold',
    [StakedLpTokenType.UnbondingBronze]: 'UnbondingBronze',
    [StakedLpTokenType.UnbondingSilver]: 'UnbondingSilver',
    [StakedLpTokenType.UnbondingGold]: 'UnbondingGold',
};
