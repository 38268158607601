import {
    Address,
    AddressValue,
    BigUIntValue,
    CompositeValue,
    ResultsParser,
    TokenIdentifierValue,
    TokenPayment,
    Transaction,
    TypedValue,
    VariadicValue,
} from '@multiversx/sdk-core/out';
import BigNumber from 'bignumber.js';
import { MULTI_PAIR_SWAP_GAS_LIMIT, POOL_SWAP_ORDER, SWAP_ROUTER_SC_ADDRESS, TOKEN_INFO_MAP } from 'config';
import { parseStateEnum, SwapRouterSettingsType, SwapPoolType, SwapResultType } from 'z/types';
import { createTokenTicker, DEFAULT_DECIMALS, parseElrondAddress, toastError } from 'z/utils';
import { elrondDappSendTransactions, mvxQuery, parseSwapResult } from './common';
import { swapRouterSmartContract, elrondProvider } from './provider';
import { parseFeeReceiver } from './stableswap';

export async function elrondViewRouterSettings(): Promise<SwapRouterSettingsType | null> {
    try {
        const interaction = swapRouterSmartContract.methodsExplicit.viewRouterSettings();
        const query = interaction.check().buildQuery();
        const queryResponse = await elrondProvider.queryContract(query);
        const endpointDefinition = interaction.getEndpoint();
        const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(
            queryResponse,
            endpointDefinition,
        );

        if (!firstValue || !returnCode.isSuccess()) {
            toastError(returnMessage);
            return null;
        }

        const value = firstValue.valueOf();
        const decoded: SwapRouterSettingsType = {
            state: parseStateEnum(value.state.name),
            pair_template_address: value.pair_template_address.toString(),
            pair_tokens: value.pair_tokens.map((v: any) => ({
                first_token_id: v.first_token_id.toString(),
                second_token_id: v.second_token_id.toString(),
            })),
            pair_addresses: value.pair_addresses.map((v: any) => v.toString()),
        };

        return decoded;
    } catch (err) {
        console.error(err);
    }

    return null;
}

export async function elrondViewSwapPools(isSorted = true): Promise<SwapPoolType[]> {
    try {
        const interaction = swapRouterSmartContract.methodsExplicit.viewPools();
        const query = interaction.check().buildQuery();
        const queryResponse = await elrondProvider.queryContract(query);
        const endpointDefinition = interaction.getEndpoint();
        const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(
            queryResponse,
            endpointDefinition,
        );

        if (!firstValue || !returnCode.isSuccess()) {
            toastError(returnMessage);
            return [];
        }

        const values = firstValue.valueOf();
        return parseSwapPools(values, isSorted);
    } catch (err) {
        console.error(err);
    }

    return [];
}

export async function elrondViewPaginationSwapPools(isSorted = true): Promise<SwapPoolType[]> {
    try {
        const limit = 25;
        let auxValues: any[] = [],
            values,
            from = 0;
        do {
            const interaction = swapRouterSmartContract.methods.viewPaginationPools([from, limit]);

            values = await mvxQuery(interaction);
            from += limit;
            auxValues = [...auxValues, ...values];
        } while (values.length === limit);
        return parseSwapPools(auxValues, isSorted);
    } catch (err) {
        console.error(err);
    }

    return [];
}

const parseSwapPools = (values: any, isSorted: boolean): SwapPoolType[] => {
    const decoded: SwapPoolType[] = values.map((value: any, index: number) => ({
        pool_address: parseElrondAddress(value.pool_address.toString()),
        pool_state: parseStateEnum(value.pool_state.name),

        first_token_id: value.first_token_id.toString(),
        first_token_ticker: createTokenTicker(value.first_token_id.toString()),
        first_token_decimals: TOKEN_INFO_MAP[value.first_token_id.toString()]?.decimals ?? DEFAULT_DECIMALS,

        second_token_id: value.second_token_id.toString(),
        second_token_ticker: createTokenTicker(value.second_token_id.toString()),
        second_token_decimals: TOKEN_INFO_MAP[value.second_token_id.toString()]?.decimals ?? DEFAULT_DECIMALS,

        first_token_reserve: value.first_token_reserve.toFixed(),
        second_token_reserve: value.second_token_reserve.toFixed(),

        lp_token_id: value.lp_token_id.toString(),
        lp_token_supply: value.lp_token_supply.toFixed(),
        lp_token_roles_are_set: value.lp_token_roles_are_set,
        lp_token_decimals: TOKEN_INFO_MAP[value.lp_token_id.toString()]?.decimals ?? DEFAULT_DECIMALS,

        fee_token_id: value.fee_token_id.toString(),
        total_fee_percentage: value.total_fee_percentage.toNumber(),
        special_fee_percentage: value.special_fee_percentage.toNumber(),

        fee_receivers: value.fee_receivers.map((v: any) => parseFeeReceiver(v)),

        // for frontend
        index,
    }));

    // console.log('All Swap Pools', decoded);
    if (!isSorted) return decoded;

    const sorted: SwapPoolType[] = [];

    // Sort Order:

    let poolIndex = 0;
    for (let i = 0; i < POOL_SWAP_ORDER.length; i++) {
        const firstTokenTicker = POOL_SWAP_ORDER[i][0];
        const secondTokenTicker = POOL_SWAP_ORDER[i][1];
        const targetPool = decoded.find(
            (pool) => pool.first_token_ticker == firstTokenTicker && pool.second_token_ticker == secondTokenTicker,
        );
        if (targetPool) {
            targetPool.index = poolIndex++;
            sorted.push(targetPool);
        }
    }

    return sorted;
};

export async function elrondGetEquivalent(
    pairAddresses: string[],
    tokenOuts: string[],
    amountIn: BigNumber.Value,
): Promise<string | null> {
    try {
        const args: TypedValue[] = [new BigUIntValue(amountIn)];

        const args2: TypedValue[] = [];
        for (let i = 0; i < pairAddresses.length; i++) {
            args2.push(
                CompositeValue.fromItems(
                    new AddressValue(new Address(pairAddresses[i])),
                    new TokenIdentifierValue(tokenOuts[i]),
                ),
            );
        }
        args.push(VariadicValue.fromItems(...args2));

        const interaction = swapRouterSmartContract.methodsExplicit.getEquivalent(args);
        const query = interaction.check().buildQuery();
        const queryResponse = await elrondProvider.queryContract(query);
        const endpointDefinition = interaction.getEndpoint();
        const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(
            queryResponse,
            endpointDefinition,
        );

        if (!firstValue || !returnCode.isSuccess()) {
            toastError(returnMessage);
            return null;
        }

        const value = firstValue.valueOf();

        return value.toFixed();
    } catch (err: any) {
        console.error(err);
        toastError(err.message);
    }

    return null;
}

export async function elrondGetAmountOut(
    pairAddresses: string[],
    tokenOuts: string[],
    amountIn: BigNumber.Value,
): Promise<string | null> {
    try {
        const args: TypedValue[] = [new BigUIntValue(amountIn)];

        const args2: TypedValue[] = [];
        for (let i = 0; i < pairAddresses.length; i++) {
            args2.push(
                CompositeValue.fromItems(
                    new AddressValue(new Address(pairAddresses[i])),
                    new TokenIdentifierValue(tokenOuts[i]),
                ),
            );
        }
        args.push(VariadicValue.fromItems(...args2));

        const interaction = swapRouterSmartContract.methodsExplicit.getAmountsOut(args);
        const query = interaction.check().buildQuery();
        const queryResponse = await elrondProvider.queryContract(query);
        const endpointDefinition = interaction.getEndpoint();
        const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(
            queryResponse,
            endpointDefinition,
        );

        if (!firstValue || !returnCode.isSuccess()) {
            toastError(returnMessage);

            return null;
        }

        const values = firstValue.valueOf();

        // the last element is amount_out
        return values[values.length - 1].toFixed();
    } catch (err: any) {
        console.error(err);
        toastError(err.message);
    }

    return null;
}

export async function elrondGetAmountIn(
    pairAddresses: string[],
    tokenOuts: string[],
    amountOut: BigNumber.Value,
): Promise<string | null> {
    try {
        const args: TypedValue[] = [new BigUIntValue(amountOut)];

        const args2: TypedValue[] = [];
        for (let i = 0; i < pairAddresses.length; i++) {
            args2.push(
                CompositeValue.fromItems(
                    new AddressValue(new Address(pairAddresses[i])),
                    new TokenIdentifierValue(tokenOuts[i]),
                ),
            );
        }
        args.push(VariadicValue.fromItems(...args2));

        const interaction = swapRouterSmartContract.methodsExplicit.getAmountsIn(args);
        const query = interaction.check().buildQuery();
        const queryResponse = await elrondProvider.queryContract(query);
        const endpointDefinition = interaction.getEndpoint();
        const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(
            queryResponse,
            endpointDefinition,
        );

        if (!firstValue || !returnCode.isSuccess()) {
            toastError(returnMessage);

            return null;
        }

        const values = firstValue.valueOf();

        // the first element is amount_in
        return values[0].toFixed();
    } catch (err: any) {
        console.error(err);
        toastError(err.message);
    }

    return null;
}

export async function routerEstimateSwapFixedInputs(
    pairAddresses: string[],
    tokenOuts: string[],
    amountIn: BigNumber.Value,
): Promise<SwapResultType[]> {
    try {
        const args: TypedValue[] = [new BigUIntValue(amountIn)];

        const args2: TypedValue[] = [];
        for (let i = 0; i < pairAddresses.length; i++) {
            args2.push(
                CompositeValue.fromItems(
                    new AddressValue(new Address(pairAddresses[i])),
                    new TokenIdentifierValue(tokenOuts[i]),
                ),
            );
        }
        args.push(VariadicValue.fromItems(...args2));

        const interaction = swapRouterSmartContract.methodsExplicit.estimateSwapFixedInputs(args);
        const query = interaction.check().buildQuery();
        const queryResponse = await elrondProvider.queryContract(query);
        const endpointDefinition = interaction.getEndpoint();
        const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(
            queryResponse,
            endpointDefinition,
        );

        if (!firstValue || !returnCode.isSuccess()) {
            throw Error(returnMessage);
        }

        const values = firstValue.valueOf();
        const decoded = values.map((value: any) => parseSwapResult(value));

        return decoded;
    } catch (err: any) {
        console.error(err);
        toastError(err.message);
    }

    return [];
}

export async function routerEstimateSwapFixedOutputs(
    pairAddresses: string[],
    tokenOuts: string[],
    amountOut: BigNumber.Value,
): Promise<SwapResultType[]> {
    try {
        const args: TypedValue[] = [new BigUIntValue(amountOut)];

        const args2: TypedValue[] = [];
        for (let i = 0; i < pairAddresses.length; i++) {
            args2.push(
                CompositeValue.fromItems(
                    new AddressValue(new Address(pairAddresses[i])),
                    new TokenIdentifierValue(tokenOuts[i]),
                ),
            );
        }
        args.push(VariadicValue.fromItems(...args2));

        const interaction = swapRouterSmartContract.methodsExplicit.estimateSwapFixedOutputs(args);
        const query = interaction.check().buildQuery();
        const queryResponse = await elrondProvider.queryContract(query);
        const endpointDefinition = interaction.getEndpoint();
        const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(
            queryResponse,
            endpointDefinition,
        );

        if (!firstValue || !returnCode.isSuccess()) {
            throw Error(returnMessage);
        }

        const values = firstValue.valueOf();
        const decoded = values.map((value: any) => parseSwapResult(value));

        return decoded;
    } catch (err: any) {
        console.error(err);
        toastError(err.message);
    }

    return [];
}

export async function elrondMultiPairSwapFixedInput(
    pairAddresses: string[],
    tokenOuts: string[],
    tokenIn: string,
    amountIn: BigNumber.Value,
    amountOutMin: BigNumber.Value,
    chainID: string,
) {
    const tokenPayments: TokenPayment = TokenPayment.fungibleFromBigInteger(tokenIn, amountIn);
    const args: TypedValue[] = [new BigUIntValue(amountOutMin)];
    for (let i = 0; i < pairAddresses.length; i++) {
        args.push(new AddressValue(new Address(pairAddresses[i])));
        args.push(new TokenIdentifierValue(tokenOuts[i]));
    }
    const txx: Transaction = swapRouterSmartContract.methodsExplicit
        .multiPairSwapFixedInput(args)
        .withSingleESDTTransfer(tokenPayments)
        .withChainID(chainID)
        .buildTransaction();
    const tx = {
        value: 0,
        data: txx.getData().toString(),
        receiver: SWAP_ROUTER_SC_ADDRESS,
        gasLimit: MULTI_PAIR_SWAP_GAS_LIMIT,
    };

    const txName = 'Swap';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function elrondMultiPairSwapFixedOutput(
    pairAddresses: string[],
    tokenOuts: string[],
    tokenIn: string,
    amountIn: BigNumber.Value,
    amountOut: BigNumber.Value,
    chainID: string,
) {
    const tokenPayments: TokenPayment = TokenPayment.fungibleFromBigInteger(tokenIn, amountIn);
    const args: TypedValue[] = [new BigUIntValue(amountOut)];
    for (let i = 0; i < pairAddresses.length; i++) {
        args.push(new AddressValue(new Address(pairAddresses[i])));
        args.push(new TokenIdentifierValue(tokenOuts[i]));
    }
    const txx: Transaction = swapRouterSmartContract.methodsExplicit
        .multiPairSwapFixedOutput(args)
        .withSingleESDTTransfer(tokenPayments)
        .withChainID(chainID)
        .buildTransaction();
    const tx = {
        value: 0,
        data: txx.getData().toString(),
        receiver: SWAP_ROUTER_SC_ADDRESS,
        gasLimit: MULTI_PAIR_SWAP_GAS_LIMIT,
    };

    const txName = 'Swap';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}
