import React, { useEffect, useState } from 'react';

import BigNumber from 'bignumber.js';

import { GSC_TOKEN_ID, OURO_TOKEN_ID, SLIP_SC_ADDRESS, SSC_TOKEN_ID, USDC_TOKEN_ID, USDD_TOKEN_ID } from 'config';
import { selectSwap } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { getTokenBalanceFromApi, getTokenDecimals } from 'z/elrond';
import { useSlipHooks } from 'z/hooks/slip';
import { SlipPoolType, SlipStateEnum, SwapPoolType } from 'z/types';
import { convertWeiToEsdt, formatNumber } from 'z/utils';
export { SlipAdmin } from './SlipAdmin';
import { SlipLoad } from './SlipLoad';
import { SlipRow } from './SlipRow';
import { SlipStableCoinRow } from './SlipStableCoinRow';

export interface SlipLoadType {
    ouroAmountInSwapBasedPool: string;
    totalSlipCapacity: string;
    slipThreashold: string;
    slipFillUp: string;
    slipFillUpProcent: string;
    isSlipDisabled: boolean;
}

const defaultSlipLoadData = {
    ouroAmountInSwapBasedPool: '0',
    slipThreashold: '0',
    totalSlipCapacity: '0',
    slipFillUp: '0',
    slipFillUpProcent: '0',
    isSlipDisabled: true,
};

export const Slip = () => {
    const swapRedux = useAppSelector(selectSwap);
    const { pools } = useSlipHooks();

    const [slipOuroAmount, setSlipOuroAmount] = useState('0');

    useEffect(() => {
        getTokenBalanceFromApi(SLIP_SC_ADDRESS, OURO_TOKEN_ID).then((res) => {
            if (res) {
                setSlipOuroAmount(formatNumber(convertWeiToEsdt(res.balance, 18).toNumber(), 0));
            }
        });
    }, []);

    const [slipLoadData, setSlipLoadData] = useState<SlipLoadType>(defaultSlipLoadData);

    useEffect(() => {
        const _swapUsddPool = swapRedux.pools.find(
            ({ first_token_id, second_token_id }) =>
                first_token_id === USDD_TOKEN_ID && second_token_id === OURO_TOKEN_ID,
        );
        const _swapUsdcPool = swapRedux.pools.find(
            ({ first_token_id, second_token_id }) =>
                first_token_id === USDC_TOKEN_ID && second_token_id === OURO_TOKEN_ID,
        );

        const ouroAmountInSwapBasedPool = BigNumber.sum(
            convertWeiToEsdt(_swapUsddPool?.second_token_reserve, _swapUsddPool?.second_token_decimals),
            convertWeiToEsdt(_swapUsdcPool?.second_token_reserve, _swapUsdcPool?.second_token_decimals),
        );

        const slipThreashold = ouroAmountInSwapBasedPool.multipliedBy(95).div(100);

        const fillUpAmount = swapRedux.pools
            .filter(({ first_token_id }) => first_token_id === OURO_TOKEN_ID)
            .reduce((sum, pool) => sum + parseInt(pool.first_token_reserve), 0);

        const slipFillUp = convertWeiToEsdt(fillUpAmount, getTokenDecimals(OURO_TOKEN_ID));

        const totalSlipCapacity = slipThreashold.minus(slipFillUp);

        const slipFillUpProcent = formatNumber(slipFillUp.multipliedBy(100).div(ouroAmountInSwapBasedPool), 2);

        setSlipLoadData({
            ouroAmountInSwapBasedPool: formatNumber(ouroAmountInSwapBasedPool, 0),
            slipThreashold: formatNumber(slipThreashold, 0),

            totalSlipCapacity: formatNumber(totalSlipCapacity, 0),
            slipFillUp: formatNumber(slipFillUp, 0),
            slipFillUpProcent: slipFillUpProcent,
            isSlipDisabled: totalSlipCapacity <= new BigNumber(0),
        });
    }, [swapRedux]);

    const [poolPairs, setPoolPairs] = useState<
        {
            slipPool: SlipPoolType;
            swapOuroPool: SwapPoolType | undefined;
            swapVegldPool: SwapPoolType | undefined;
        }[]
    >([]);

    useEffect(() => {
        if (pools && swapRedux.pools) {
            setPoolPairs(
                pools
                    .filter(({ status }) => status !== SlipStateEnum.Removed)
                    .map((slipPool: SlipPoolType) => {
                        const swapOuroPool = swapRedux.pools.find(
                            ({ pool_address }) => slipPool.ouroSwapPoolAddress === pool_address,
                        );

                        const swapVegldPool = swapRedux.pools.find(
                            ({ pool_address }) => slipPool.vegldSwapPoolAddress === pool_address,
                        );
                        return {
                            slipPool,
                            swapOuroPool,
                            swapVegldPool,
                        };
                    }),
            );
        }
    }, [swapRedux, pools]);

    return (
        <div className="vesta-first-container">
            <div className="d-flex justify-content-center">
                <span
                    style={{
                        color: '#F1DC46',
                        fontSize: '1.2rem',
                    }}
                >
                    SLIP - Single Sided Liquidity Incentive Program
                </span>
            </div>

            <div className="d-flex justify-content-center">
                <span
                    style={{
                        color: '#F1DC46',
                        fontSize: '1.2rem',
                    }}
                >
                    {slipOuroAmount} OURO held in the SLIP SC
                </span>
            </div>

            <div className="mt-4 d-flex flex-column gap-3">
                <SlipLoad slipLoadData={slipLoadData} />
                {poolPairs.map(({ slipPool, swapOuroPool, swapVegldPool }, id: any) => {
                    return swapOuroPool &&
                        swapOuroPool.first_token_id === OURO_TOKEN_ID &&
                        ![GSC_TOKEN_ID, SSC_TOKEN_ID].includes(swapOuroPool.second_token_id) ? (
                        <SlipRow
                            key={`p-s-${id}`}
                            slipPool={slipPool}
                            swapOuroPool={swapOuroPool}
                            swapVegldPool={swapVegldPool}
                            slipLoadData={slipLoadData}
                        />
                    ) : (
                        <SlipStableCoinRow
                            key={`p-s-${id}`}
                            slipPool={slipPool}
                            swapOuroPool={swapOuroPool}
                            slipLoadData={slipLoadData}
                        />
                    );
                })}
            </div>
        </div>
    );
};
