import { useEffect } from 'react';
import { Address, TokenTransfer } from '@multiversx/sdk-core/out';
import { useGetAccount, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import { GSC_TOKEN_ID, USDD_TOKEN_ID } from 'config';
import { selectStableCoinProtocol, setStableCoinProtocolContext } from 'redux/reducers/stablecoin-protocol';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { getTokenDecimals, stableCoinProtocolSmartContract } from 'z/elrond';
import { StableCoinProtocolContext } from 'z/types/stablecoin-protocol';
import { useCommonMvxHooks } from './common-mvx';

interface ReturnData {
    context?: StableCoinProtocolContext;
    getStableCoinProtocolContext: (targetAddress: string) => Promise<StableCoinProtocolContext>;
    sendMintUsddTransaction: (gscAmountIn: string) => Promise<void>;
    sendMintGscTransaction: (gscAmountIn: string) => Promise<void>;
    sendAdminMintStableCoinTransaction: (usddAmountOut: string) => Promise<void>;
    sendAdminMintGscTransaction: (gscAmountIn: string) => Promise<void>;
    sendAdminIncreasePreciousMetalCollateralTransaction: (gscAmountIn: string) => Promise<void>;
    sendAdminDecreasePreciousMetalCollateralTransaction: (gscAmountOut: string) => Promise<void>;
}

export const useStableCoinProtocol = (): ReturnData => {
    const { hasPendingTransactions } = useGetPendingTransactions();
    const { sendTransaction, queryContract } = useCommonMvxHooks();

    const { address } = useGetAccount();
    const dispatch = useAppDispatch();

    const { context } = useAppSelector(selectStableCoinProtocol);

    useEffect(() => {
        if (hasPendingTransactions) return;

        (async () => {
            const stableCoinProtocolContext = await getStableCoinProtocolContext(address);
            dispatch(setStableCoinProtocolContext(stableCoinProtocolContext));
        })();
    }, [hasPendingTransactions, address]);

    const getStableCoinProtocolContext = async (targetAddress: string): Promise<StableCoinProtocolContext> => {
        try {
            const addressArg = Address.isValid(targetAddress) ? targetAddress : Address.Zero().bech32();
            const interaction = stableCoinProtocolSmartContract.methods.getViewContext([addressArg]);
            const result = await queryContract(interaction);

            const finalRes: any = {};
            for (const [key, value] of Object.entries(result)) {
                finalRes[key] = (value as any).toString(10);
            }

            return finalRes;
        } catch (err) {
            console.error('getStableCoinProtocolContext', err);
            return {
                total_collateral_usd_value: '0',
                total_issued_stable_coin_usd_value: '0',
                sgsc_supply: '0',
                sssc_supply: '0',
                spsc_supply: '0',
                usdd_supply: '0',
                eurd_supply: '0',
                total_mint_stablecoin_percentage: '0',
                total_mint_precious_metal_percentage: '0',
                percentage_denomination: '0',
                collateralization_ratio: '0',
                gsc_oracle_price: '0',
                ssc_oracle_price: '0',
                psc_oracle_price: '0',
            };
        }
    };

    const sendMintUsddTransaction = async (gscAmountIn: string) => {
        const USDD_KEY = 1;
        const interaction = stableCoinProtocolSmartContract.methods
            .mintStableCoin([USDD_KEY])
            .withSingleESDTTransfer(
                TokenTransfer.fungibleFromAmount(GSC_TOKEN_ID, gscAmountIn, getTokenDecimals(GSC_TOKEN_ID)),
            )
            .withGasLimit(45_000_000);

        const txName = 'Mint Stable Coin';
        await sendTransaction(interaction, txName);
    };

    const sendMintGscTransaction = async (gscAmountIn: string) => {
        const GSC_KEY = 10;
        const interaction = stableCoinProtocolSmartContract.methods
            .mintPreciousMetal([GSC_KEY])
            .withSingleESDTTransfer(
                TokenTransfer.fungibleFromAmount(USDD_TOKEN_ID, gscAmountIn, getTokenDecimals(USDD_TOKEN_ID)),
            )
            .withGasLimit(45_000_000);

        const txName = 'Mint Precious Metal';
        await sendTransaction(interaction, txName);
    };

    const sendAdminMintStableCoinTransaction = async (usddAmountOut: string) => {
        const USDD_KEY = 1;
        const interaction = stableCoinProtocolSmartContract.methods
            .adminMintStableCoin([USDD_KEY, usddAmountOut])
            .withGasLimit(45_000_000);

        const txName = 'Mint USDD Admin Mode';
        await sendTransaction(interaction, txName);
    };

    const sendAdminMintGscTransaction = async (gscAmountIn: string) => {
        const GSC_KEY = 10;
        const interaction = stableCoinProtocolSmartContract.methods
            .mintPreciousMetalNoFee([GSC_KEY])
            .withSingleESDTTransfer(
                TokenTransfer.fungibleFromAmount(USDD_TOKEN_ID, gscAmountIn, getTokenDecimals(USDD_TOKEN_ID)),
            )
            .withGasLimit(45_000_000);

        const txName = 'Mint Precious Metal';
        await sendTransaction(interaction, txName);
    };

    const sendAdminIncreasePreciousMetalCollateralTransaction = async (gscAmountIn: string) => {
        const interaction = stableCoinProtocolSmartContract.methods
            .increasePreciousMetalCollateral([])
            .withSingleESDTTransfer(
                TokenTransfer.fungibleFromAmount(GSC_TOKEN_ID, gscAmountIn, getTokenDecimals(GSC_TOKEN_ID)),
            )
            .withGasLimit(45_000_000);

        const txName = 'Increase Precious Metal Collateral';
        await sendTransaction(interaction, txName);
    };

    const sendAdminDecreasePreciousMetalCollateralTransaction = async (gscAmountOut: string) => {
        const GSC_KEY = 10;
        const interaction = stableCoinProtocolSmartContract.methods
            .decreasePreciousMetalCollateral([GSC_KEY, gscAmountOut])
            .withGasLimit(45_000_000);

        const txName = 'Decrease Precious Metal Collateral';
        await sendTransaction(interaction, txName);
    };

    return {
        context,
        getStableCoinProtocolContext,
        sendMintUsddTransaction,
        sendMintGscTransaction,
        sendAdminMintStableCoinTransaction,
        sendAdminMintGscTransaction,
        sendAdminIncreasePreciousMetalCollateralTransaction,
        sendAdminDecreasePreciousMetalCollateralTransaction,
    };
};
