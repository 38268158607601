import React, { FC, useEffect, useState } from 'react';
import { BiRightArrowAlt } from 'react-icons/bi';
import { FaMedal } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { selectFarm } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { routeNames } from 'routes';
import { getTokenLogo } from 'z/elrond';
import { useVestaCommonHooks } from 'z/hooks';
import { FarmType, SwapPoolType } from 'z/types';
import {
    formatNumber,
    convertToDollarString,
    createTokenTicker,
    convertWeiToEsdt,
} from 'z/utils';

enum FarmTierEnum {
    Platinum,
    Gold,
    Silver,
    Bronze,
    Gray,
}

function FarmTierMedal({
    visible = true,
} : {
    visible?: boolean
}) {
    return (
        <div className='' style={{ marginTop: '0.2rem', marginBottom: '0.2rem', visibility: (visible ? 'visible' : 'hidden') }}>
            <FaMedal />
        </div>
    );
}

/* eslint-disable */
function FarmTierMedalContainer({
    farmTier
} : {
    farmTier: FarmTierEnum,
}) {
    const color = farmTier == FarmTierEnum.Platinum ? 'white'
        : farmTier == FarmTierEnum.Gold ? 'gold'
        : farmTier == FarmTierEnum.Silver ? 'silver'
        : farmTier == FarmTierEnum.Bronze ? '#CD7F32'
        : 'DimGray';
    return (
        <div className="d-flex flex-column justify-content-center" style={{ color: color }}>
            <FarmTierMedal visible={farmTier <= FarmTierEnum.Platinum} />
            <FarmTierMedal visible={farmTier <= FarmTierEnum.Gold} />
            <FarmTierMedal visible={farmTier <= FarmTierEnum.Silver} />
            <FarmTierMedal />
        </div>
    );
}
/* eslint-enable */

export const FarmRowInfo: FC<{
    pool: SwapPoolType,
    farmId: number,
    farm: FarmType,
    hideNavigate?: boolean,
}> = ({
    pool,
    farmId,
    farm,
    hideNavigate = false,
}) => {
    const navigate = useNavigate();
    const { getTokenPrice } = useVestaCommonHooks();
    
    const farmRedux = useAppSelector(selectFarm);

    const firstTokenTicker = createTokenTicker(pool.first_token_id);
    const secondTokenTicker = createTokenTicker(pool.second_token_id);
    const farmRewards = farmRedux.farmRewards[farmId] ?? undefined;

    const [poolPotency, setPoolPotency] = useState<string>('');
    useEffect(() => {
        if (!pool) return;
    
        const vegldReserve = convertWeiToEsdt(pool.first_token_reserve).toNumber();
        let potency = '';
        if (vegldReserve < 100) {
          potency = 'Dust';
        } else if (vegldReserve < 250) {
          potency = 'Dirt';
        } else if (vegldReserve < 500) {
          potency = 'Iron';
        } else if (vegldReserve < 1000) {
          potency = 'Bronze';
        } else if (vegldReserve < 2500) {
          potency = 'Silver';
        } else if (vegldReserve < 5000) {
          potency = 'Gold';
        } else if (vegldReserve < 10000) {
          potency = 'Platinum';
        } else {
          potency = 'Diamond';
        }
    
        setPoolPotency(potency);
      }, [pool]);

      return (
        <>
            <div className="active-pool-li-container">
                <div className='active-pool-li'>
                    <div className="active-pool-pair">
                        <img
                            src={getTokenLogo(pool.first_token_id)}
                            alt="logo"
                            width="50px"
                        />
                        <img
                            src={getTokenLogo(pool.second_token_id)}
                            alt="logo"
                            width="50px"
                        />

                        <div className='ms-3 d-flex flex-column justify-content-center align-items-center' style={{ color: 'white', fontSize: '1.1rem' }}>
                            <div>{firstTokenTicker}</div>
                            <div>{secondTokenTicker}</div>
                        </div>
                    </div>

                    {/* <FarmTierMedalContainer
                        farmTier={farmId == 0 ? FarmTierEnum.Platinum : farmId == 1 ? FarmTierEnum.Bronze : FarmTierEnum.Gold}
                    /> */}

                    <div className="active-pool-info">
                        <span>Tier</span>
                        <span>{farm.tier}</span>
                    </div>

                    <div className="active-pool-info">
                        <span>Potency</span>
                        <span>{poolPotency}</span>
                    </div>

                    <div className="active-pool-info">
                        <div className="d-flex align-items-center gap-1">
                            <img
                                src={getTokenLogo(pool.first_token_id)}
                                alt="logo"
                                width="18px"
                            />
                            <span style={{ fontSize: '1rem', color: '#98A1C0' }}>
                                {firstTokenTicker}
                            </span>
                        </div>
                        <span>
                            {
                                formatNumber(
                                    convertWeiToEsdt(pool.first_token_reserve, pool.first_token_decimals),
                                    0
                                )
                            }
                        </span>
                    </div>

                    <div className="active-pool-info">
                        <div className="d-flex align-items-center gap-1">
                            <img
                                src={getTokenLogo(pool.second_token_id)}
                                alt="logo"
                                width="18px"
                            />
                            <span style={{ fontSize: '1rem', color: '#98A1C0' }}>
                                {secondTokenTicker}
                            </span>
                        </div>
                        <span>
                            {
                                formatNumber(
                                    convertWeiToEsdt(pool.second_token_reserve, pool.second_token_decimals),
                                    0
                                )
                            }
                        </span>
                    </div>

                    <div className="active-pool-info">
                        <span>Active</span>
                        <span>{farmRewards ? farmRewards.is_snapshotting ? 'Snapshotting' : farmRewards.farm_weight_share_percentage > 0 ? 'Yes' : 'No' : 'No'}</span>
                    </div>

                    <div className="active-pool-info">
                        <span>{"P.(%)"}</span>
                        <span>{farmRewards? formatNumber(farmRewards.farm_weight_share_percentage, 2) + '%' : 'None'}</span>
                    </div>

                    <div className="active-pool-info">
                        <span>Liquidity</span>
                        <span>
                            {
                                convertToDollarString(
                                    convertWeiToEsdt(pool.first_token_reserve, pool.first_token_decimals)
                                        .multipliedBy(getTokenPrice(pool.first_token_id))
                                        .multipliedBy(2),
                                )
                            }
                        </span>
                    </div>

                    {
                        !hideNavigate && (
                            <div className="active-pool-info">
                                <button
                                    className="pool-collapse-button"
                                    onClick={() => {
                                        navigate(`${routeNames.farms}/${farmId}`);
                                    }}
                                >
                                    <BiRightArrowAlt />
                                </button>
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    );
};
