import React, { useState, useEffect } from 'react';
import {
    useGetAccount,
    useGetLoginInfo,
    useGetNetworkConfig,
    useGetPendingTransactions,
} from '@multiversx/sdk-dapp/hooks';
import clsx from 'clsx';
import { Oval } from 'react-loader-spinner';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { SlippageSetting } from 'components/SlippageSetting';
import { OURO_TOKEN_ID } from 'config';
import { selectSwap, updateSwapRedux } from 'redux/reducers';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { routeNames } from 'routes';
import { elrondAddLiquidity, elrondGetEquivalent, getTokenBalanceFromApi, getTokenDecimals } from 'z/elrond';
import { useVestaCommonHooks } from 'z/hooks';
import { useAdminHooks } from 'z/hooks/admin';
import { FocusedInputTokenType, SwapPoolType } from 'z/types';
import {
    applySlippage,
    formatNumber,
    convertEsdtToWei,
    createTokenTicker,
    convertWeiToEsdt,
    ERROR_INVALID_NUMBER,
    ERROR_NOT_ENOUGH_BALANCE,
    isPositiveBigNumber,
    isPositiveOrZeroBigNumber,
    parseBigNumber,
    ERROR_CONNECT_WALLET,
    TEXT_SELECT_POOL,
    toastError,
    ZERO_STRING,
    convertBigNumberToInputString,
    ERROR_TRANSACTION_ONGOING,
    LOADING_SWAP_PRICE_DEBOUNCE_PERIOD,
    convertToDollarString,
} from 'z/utils';
import { AddLiquidityInputToken } from './AddLiquidityInputToken';
import { MyLiquidityRow } from './MyLiquidityRow';
import { PoolRow } from './PoolRow';
import { SelectPool } from './SelectPool';
import { Slip } from './Slip';

enum TabRole {
    Guest = 0,
    LoggedIn = 1,
}

interface Tab {
    name: string;
    route: string;
    role: TabRole;
    absoluteIndex: number;
}

const poolTabs: Tab[] = [
    {
        name: 'Pools',
        route: '',
        role: TabRole.Guest,
        absoluteIndex: 0,
    },
    {
        name: 'Add Liquidity',
        route: 'add-liquidity',
        role: TabRole.Guest,
        absoluteIndex: 1,
    },
    {
        name: 'My Liquidity',
        route: 'my-liquidity',
        role: TabRole.LoggedIn,
        absoluteIndex: 2,
    },
    {
        name: 'SLIP',
        route: 'slip',
        role: TabRole.Guest,
        absoluteIndex: 3,
    },
];

export const Pool = () => {
    const { chainID } = useGetNetworkConfig();
    const { hasPendingTransactions } = useGetPendingTransactions();
    const { address } = useGetAccount();
    const { isLoggedIn } = useGetLoginInfo();
    const navigate = useNavigate();
    const { selectedTab } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const selectedPoolIndex = parseInt(searchParams.get('pool') ?? '-1');
    const { getTokenPrice } = useVestaCommonHooks();
    const dispatch = useAppDispatch();

    const swapRedux = useAppSelector(selectSwap);
    const { isSwapAdmin: isAdmin } = useAdminHooks();

    const [firstTokenAmount, setFirstTokenAmount] = useState<string>(ZERO_STRING);
    const [secondTokenAmount, setSecondTokenAmount] = useState<string>(ZERO_STRING);
    const [firstTokenBalance, setFirstTokenBalance] = useState<string>(ZERO_STRING);
    const [secondTokenBalance, setSecondTokenBalance] = useState<string>(ZERO_STRING);
    const [firstTokenAmountError, setFirstTokenAmountError] = useState<string>('');
    const [secondTokenAmountError, setSecondTokenAmountError] = useState<string>('');
    const [focusedInputToken, setFocusedInputToken] = useState<FocusedInputTokenType>(FocusedInputTokenType.FirstToken);

    const [tabs, setTabs] = useState<Tab[]>([]);
    useEffect(() => {
        let role = TabRole.Guest;
        if (isLoggedIn) role = TabRole.LoggedIn;

        const filteredTabs = poolTabs.filter((tabEl) => tabEl.role <= role);
        setTabs(filteredTabs);
    }, [isLoggedIn]);

    const [activeTab, setActiveTab] = useState<Tab | undefined>(undefined);
    useEffect(() => {
        for (const tab of tabs) {
            if (tab.route === selectedTab) {
                return setActiveTab(tab);
            }
        }
        setActiveTab(tabs[0]);
    }, [tabs, selectedTab]);

    const [focusTabClass, setFocusTabClass] = useState<Tab | undefined>(undefined);
    useEffect(() => {
        const classObject: any = {
            'tab-focus': true,
        };
        for (let index = 0; index < tabs.length; index++) {
            classObject[`tab-focus-left-${Math.round(100 / tabs.length) * index}`] =
                activeTab?.absoluteIndex === tabs[index].absoluteIndex;
        }
        setFocusTabClass(classObject);
    }, [activeTab, tabs]);

    function gotoTab(index: number) {
        if (hasPendingTransactions) return;
        if (activeTab?.absoluteIndex == tabs[index].absoluteIndex) return;
        navigate(routeNames.pools + (index > 0 ? '/' + tabs[index].route : ''));
        dispatch(updateSwapRedux());
    }
    function onChangeSelectedPoolIndex(value: number) {
        setSearchParams({
            pool: value.toString(),
        });
    }

    const selectedPool =
        swapRedux.pools.length > 0 && selectedPoolIndex >= 0 ? swapRedux.pools[selectedPoolIndex] : null;
    const firstTokenTicker = selectedPool ? createTokenTicker(selectedPool.first_token_id) : '';
    const secondTokenTicker = selectedPool ? createTokenTicker(selectedPool.second_token_id) : '';

    // filter LP pools where account has LP token
    const myLiquidityPools: SwapPoolType[] = swapRedux.pools.filter(
        (pool) => parseBigNumber(pool.lp_token_balance).comparedTo(0) > 0,
    );

    //
    function resetInputs() {
        setFirstTokenAmount(ZERO_STRING);
        setSecondTokenAmount(ZERO_STRING);
        setFirstTokenAmountError('');
        setSecondTokenAmountError('');
    }

    // query balance of input and output tokens
    useEffect(() => {
        if (hasPendingTransactions) return;
        if (address && selectedPool) {
            (async () => {
                const _tokenBalanceInfo = await getTokenBalanceFromApi(address, selectedPool.first_token_id);
                setFirstTokenBalance(_tokenBalanceInfo ? _tokenBalanceInfo.balance : ZERO_STRING);
            })();
            (async () => {
                const _tokenBalanceInfo = await getTokenBalanceFromApi(address, selectedPool.second_token_id);
                setSecondTokenBalance(_tokenBalanceInfo ? _tokenBalanceInfo.balance : ZERO_STRING);
            })();
        } else {
            setFirstTokenBalance(ZERO_STRING);
            setSecondTokenBalance(ZERO_STRING);
        }
    }, [address, selectedPoolIndex, hasPendingTransactions]);

    const [totalValueLocked, setTotalValueLocked] = useState<number>(0);
    const [totalVegldLocked, setTotalVegldLocked] = useState<number>(0);
    const [totalUsdcLocked, setTotalUsdcLocked] = useState<number>(0);
    const [totalOuroLocked, setTotalOuroLocked] = useState<number>(0);

    useEffect(() => {
        let _totalValueLocked = 0;
        let _totalVegldLocked = 0;
        let _totalUsdcLocked = 0;
        let _totalOuroLocked = 0;
        for (const pool of swapRedux.pools) {
            if (pool.first_token_ticker == 'VEGLD') {
                _totalVegldLocked += Math.round(
                    convertWeiToEsdt(
                        pool.first_token_reserve,
                        pool.first_token_decimals,
                        pool.first_token_decimals,
                    ).toNumber(),
                );
            } else if (pool.first_token_ticker == 'USDC') {
                _totalUsdcLocked += Math.round(
                    convertWeiToEsdt(
                        pool.first_token_reserve,
                        pool.first_token_decimals,
                        pool.first_token_decimals,
                    ).toNumber(),
                );
            } else if (pool.first_token_id == OURO_TOKEN_ID) {
                _totalOuroLocked += Math.round(
                    convertWeiToEsdt(
                        pool.first_token_reserve,
                        pool.first_token_decimals,
                        pool.first_token_decimals,
                    ).toNumber(),
                );
            }
            _totalValueLocked += Math.round(
                convertWeiToEsdt(
                    pool.first_token_reserve,
                    pool.first_token_decimals,
                    pool.first_token_decimals,
                ).toNumber() *
                    getTokenPrice(pool.first_token_id) *
                    2,
            );
        }

        setTotalValueLocked(_totalValueLocked);
        setTotalVegldLocked(_totalVegldLocked);
        setTotalUsdcLocked(_totalUsdcLocked);
        setTotalOuroLocked(_totalOuroLocked);
    }, [swapRedux.pools]);

    const [exchangeRate, setExchangeRate] = useState<string>('');
    const [reverseExchangeRate, setReverseExchangeRate] = useState<string>('');

    async function loadExchangeRate() {
        if (!selectedPool) return;

        const amountOut = await elrondGetEquivalent(
            [selectedPool.pool_address],
            [selectedPool.second_token_id],
            convertEsdtToWei(1, getTokenDecimals(selectedPool.first_token_id)),
        );
        const _exchangeRate = convertWeiToEsdt(
            amountOut,
            getTokenDecimals(selectedPool.second_token_id),
            getTokenDecimals(selectedPool.second_token_id),
        );

        if (_exchangeRate.comparedTo(0) <= 0) {
            setExchangeRate('');
            setReverseExchangeRate('');
        } else {
            setExchangeRate(_exchangeRate.toFixed());
            setReverseExchangeRate(parseBigNumber(1).div(_exchangeRate).toFixed());
        }
    }

    useEffect(() => {
        if (hasPendingTransactions) return;

        resetInputs();
        loadExchangeRate();

        const delayDebounceFn = setInterval(() => {
            loadExchangeRate();
        }, LOADING_SWAP_PRICE_DEBOUNCE_PERIOD);

        return () => clearInterval(delayDebounceFn);
    }, [selectedPoolIndex, hasPendingTransactions]);

    useEffect(() => {
        if (focusedInputToken == FocusedInputTokenType.FirstToken) {
            onActiveChangeFirstTokenAmount(undefined);
        } else {
            onActiveChangeSecondTokenAmount(undefined);
        }
    }, [exchangeRate]);

    function onChangeFirstTokenAmount(value: string) {
        let error = '';
        if (!selectedPool) {
            error = TEXT_SELECT_POOL;
        } else if (!isPositiveOrZeroBigNumber(value)) {
            error = ERROR_INVALID_NUMBER;
        } else if (
            isLoggedIn &&
            convertEsdtToWei(value, getTokenDecimals(selectedPool.first_token_id)).comparedTo(firstTokenBalance) > 0
        ) {
            error = ERROR_NOT_ENOUGH_BALANCE;
        }

        setFirstTokenAmountError(error);
        setFirstTokenAmount(value);

        return error;
    }

    function onChangeSecondTokenAmount(value: string) {
        let error = '';
        if (!selectedPool) {
            error = TEXT_SELECT_POOL;
        } else if (!isPositiveOrZeroBigNumber(value)) {
            error = ERROR_INVALID_NUMBER;
        } else if (
            isLoggedIn &&
            convertEsdtToWei(value, getTokenDecimals(selectedPool.second_token_id)).comparedTo(secondTokenBalance) > 0
        ) {
            error = ERROR_NOT_ENOUGH_BALANCE;
        }

        setSecondTokenAmountError(error);
        setSecondTokenAmount(value);

        return error;
    }

    //
    async function onActiveChangeFirstTokenAmount(value: string | undefined) {
        if (value != null) {
            onChangeFirstTokenAmount(value);
            setFocusedInputToken(FocusedInputTokenType.FirstToken);
        } else {
            value = firstTokenAmount;
        }

        if (!selectedPool) return;
        if (isPositiveBigNumber(value)) {
            const _secondTokenAmount = convertBigNumberToInputString(
                parseBigNumber(value).multipliedBy(exchangeRate),
                getTokenDecimals(selectedPool.second_token_id),
            );
            onChangeSecondTokenAmount(_secondTokenAmount);
        } else {
            onChangeSecondTokenAmount(ZERO_STRING);
        }
    }
    async function onActiveChangeSecondTokenAmount(value: string | undefined) {
        if (value != null) {
            onChangeSecondTokenAmount(value);
            setFocusedInputToken(FocusedInputTokenType.SecondToken);
        } else {
            value = secondTokenAmount;
        }

        if (!selectedPool) return;
        if (isPositiveBigNumber(value)) {
            const _firstTokenAmount = convertBigNumberToInputString(
                parseBigNumber(value).multipliedBy(reverseExchangeRate),
                getTokenDecimals(selectedPool.first_token_id),
            );
            onChangeFirstTokenAmount(_firstTokenAmount);
        } else {
            onChangeFirstTokenAmount(ZERO_STRING);
        }
    }

    function onMaxFirstTokenAmount() {
        if (isLoggedIn && selectedPool) {
            onActiveChangeFirstTokenAmount(
                convertWeiToEsdt(
                    firstTokenBalance,
                    getTokenDecimals(selectedPool.first_token_id),
                    getTokenDecimals(selectedPool.first_token_id),
                ).toFixed(),
            );
        }
    }
    function onMaxSecondTokenAmount() {
        if (isLoggedIn && selectedPool) {
            onActiveChangeSecondTokenAmount(
                convertWeiToEsdt(
                    secondTokenBalance,
                    getTokenDecimals(selectedPool.second_token_id),
                    getTokenDecimals(selectedPool.second_token_id),
                ).toFixed(),
            );
        }
    }

    async function onAddLiquidity() {
        if (!isLoggedIn) {
            toastError(ERROR_CONNECT_WALLET);
            return;
        }
        if (!selectedPool) {
            toastError(TEXT_SELECT_POOL);
            return;
        }
        if (hasPendingTransactions) {
            toastError(ERROR_TRANSACTION_ONGOING);
            return;
        }
        if (firstTokenAmountError) {
            toastError(firstTokenAmountError);
            return;
        }
        if (secondTokenAmountError) {
            toastError(secondTokenAmountError);
            return;
        }

        const _firstTokenAmount = convertEsdtToWei(firstTokenAmount, getTokenDecimals(selectedPool.first_token_id));
        const _secondTokenAmount = convertEsdtToWei(secondTokenAmount, getTokenDecimals(selectedPool.second_token_id));
        const _firstTokenAmountMin = applySlippage(_firstTokenAmount, -swapRedux.slippage);
        const _secondTokenAmountMin = applySlippage(_secondTokenAmount, -swapRedux.slippage);

        await elrondAddLiquidity(
            selectedPool.pool_address,
            selectedPool.first_token_id,
            selectedPool.second_token_id,
            _firstTokenAmount.toFixed(0),
            _secondTokenAmount.toFixed(0),
            _firstTokenAmountMin.toFixed(0),
            _secondTokenAmountMin.toFixed(0),
            address,
            chainID,
        );
    }

    return (
        <div className="container" style={{ marginTop: '50px' }}>
            <div className="d-flex justify-content-center" style={{ overflowX: 'auto' }}>
                <div className="tab-box">
                    {
                        <>
                            <div className={clsx(focusTabClass)} />
                            {tabs.map(({ name }, index) => {
                                return (
                                    <div className="tab" key={`p-t-h-${index}`} onClick={() => gotoTab(index)}>
                                        {name}
                                    </div>
                                );
                            })}
                        </>
                    }
                </div>
            </div>

            <div className="d-flex justify-content-center mt-4">
                {/* <div className="vesta_x_pool_card"> */}
                {activeTab?.absoluteIndex === 0 && (
                    <div className="vesta-first-container">
                        <div className="d-flex justify-content-center">
                            <span style={{ color: '#F1DC46', fontSize: '1.2rem' }}>{activeTab.name}</span>
                        </div>

                        <div className="d-flex justify-content-center mt-3">
                            <div className="pool-stats-box">
                                <div className="pool-stats-item">
                                    <span>Total Value Locked</span>
                                    <span>{convertToDollarString(totalValueLocked)}</span>
                                </div>
                                <div className="pool-stats-item">
                                    <span>Total Locked USDC</span>
                                    <span>{formatNumber(totalUsdcLocked, 0)} USDC</span>
                                </div>
                                <div className="pool-stats-item">
                                    <span>Total Locked OURO</span>
                                    <span>{formatNumber(totalOuroLocked, 0)} OURO</span>
                                </div>
                                <div className="pool-stats-item">
                                    <span>Total Locked vEGLD</span>
                                    <span>{formatNumber(totalVegldLocked, 0)} vEGLD</span>
                                </div>
                            </div>
                        </div>

                        <div className="mt-4 d-flex flex-column gap-3">
                            {swapRedux.pools.length > 0 &&
                                swapRedux.pools.map((pool, index) => (
                                    <PoolRow key={`p-s-p-${index}`} pool={pool} isAdmin={isAdmin} />
                                ))}
                        </div>

                        {isAdmin && (
                            <div className="d-flex just-content-center">
                                <button
                                    className="mt-4 add-liquidity-button"
                                    onClick={() => {
                                        navigate(routeNames.createPool);
                                    }}
                                >
                                    Create Pool
                                </button>
                            </div>
                        )}
                    </div>
                )}

                {activeTab?.absoluteIndex === 1 && (
                    <div className="vesta_x_pool_card" style={{ width: '900px' }}>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex justify-content-center" style={{ marginLeft: 'calc(50% - 50px)' }}>
                                <span style={{ color: '#F1DC46', fontSize: '1.2rem' }}>{activeTab.name}</span>
                            </div>

                            <SlippageSetting />
                        </div>

                        <div className="row mt-4" style={{ rowGap: '20px' }}>
                            <div className="col-md-6">
                                <span style={{ color: 'white', fontSize: '1.1rem' }}>Select Pool</span>

                                <div className="token-pair-box mt-2">
                                    <SelectPool
                                        selectedPoolIndex={selectedPoolIndex}
                                        setSelectedPoolIndex={onChangeSelectedPoolIndex}
                                        disabled={hasPendingTransactions}
                                    />
                                </div>

                                <div className="mt-4" style={{ color: 'white', fontSize: '1.1rem' }}>
                                    Deposit Amounts
                                </div>

                                <div className="d-flex flex-column gap-1 mt-2">
                                    <AddLiquidityInputToken
                                        tokenAmount={firstTokenAmount}
                                        onChangeTokenAmount={onActiveChangeFirstTokenAmount}
                                        tokenId={selectedPool ? selectedPool.first_token_id : ''}
                                        tokenTicker={selectedPool ? createTokenTicker(selectedPool.first_token_id) : ''}
                                        tokenBalance={
                                            selectedPool
                                                ? convertWeiToEsdt(
                                                      firstTokenBalance,
                                                      getTokenDecimals(selectedPool.first_token_id),
                                                      getTokenDecimals(selectedPool.first_token_id),
                                                  ).toFixed()
                                                : ZERO_STRING
                                        }
                                        onMaxTokenAmount={onMaxFirstTokenAmount}
                                        error={firstTokenAmountError}
                                        disabled={hasPendingTransactions}
                                    />

                                    <AddLiquidityInputToken
                                        tokenAmount={secondTokenAmount}
                                        onChangeTokenAmount={onActiveChangeSecondTokenAmount}
                                        tokenId={selectedPool ? selectedPool.second_token_id : ''}
                                        tokenTicker={
                                            selectedPool ? createTokenTicker(selectedPool.second_token_id) : ''
                                        }
                                        tokenBalance={
                                            selectedPool
                                                ? convertWeiToEsdt(
                                                      secondTokenBalance,
                                                      getTokenDecimals(selectedPool.second_token_id),
                                                      getTokenDecimals(selectedPool.second_token_id),
                                                  ).toFixed()
                                                : ZERO_STRING
                                        }
                                        onMaxTokenAmount={onMaxSecondTokenAmount}
                                        error={secondTokenAmountError}
                                        disabled={hasPendingTransactions}
                                    />
                                </div>
                                <div className="d-flex just-content-center">
                                    <button className="mt-4 add-liquidity-button" onClick={onAddLiquidity}>
                                        Add Liquidity
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <span style={{ color: 'white', fontSize: '1.1rem' }}>Pool Info</span>

                                <div className="swap-info mt-2">
                                    <div className="swap-info-li">
                                        <span>{selectedPool ? firstTokenTicker : '-'}</span>
                                        <span>
                                            {selectedPool
                                                ? formatNumber(
                                                      convertWeiToEsdt(
                                                          selectedPool.first_token_reserve,
                                                          selectedPool.first_token_decimals,
                                                      ),
                                                  )
                                                : '-'}
                                        </span>
                                    </div>
                                    <div className="swap-info-li">
                                        <span>{selectedPool ? secondTokenTicker : '-'}</span>
                                        <span>
                                            {selectedPool
                                                ? formatNumber(
                                                      convertWeiToEsdt(
                                                          selectedPool.second_token_reserve,
                                                          selectedPool.second_token_decimals,
                                                      ),
                                                  )
                                                : '-'}
                                        </span>
                                    </div>

                                    <div className="swap-info-li">
                                        <span>Slippage</span>
                                        <span>{swapRedux.slippage + '%'}</span>
                                    </div>

                                    <div className="swap-info-li">
                                        <span>
                                            {selectedPool ? `${firstTokenTicker} per ${secondTokenTicker}` : '-'}
                                        </span>
                                        <span>
                                            {isPositiveBigNumber(reverseExchangeRate)
                                                ? formatNumber(reverseExchangeRate, 6)
                                                : '-'}
                                        </span>
                                    </div>

                                    <div className="swap-info-li">
                                        <span>
                                            {selectedPool ? `${secondTokenTicker} per ${firstTokenTicker}` : '-'}
                                        </span>
                                        <span>
                                            {isPositiveBigNumber(exchangeRate) ? formatNumber(exchangeRate, 6) : '-'}
                                        </span>
                                    </div>

                                    <div className="swap-info-li">
                                        <span>USD Value</span>
                                        <span>
                                            {selectedPool && firstTokenAmount
                                                ? convertToDollarString(
                                                      parseBigNumber(firstTokenAmount).multipliedBy(
                                                          getTokenPrice(selectedPool.first_token_id),
                                                      ),
                                                  )
                                                : '-'}
                                        </span>
                                    </div>
                                    <div className="swap-info-li">
                                        <span>Pool Share</span>
                                        <span>
                                            {firstTokenAmount && selectedPool
                                                ? formatNumber(
                                                      parseBigNumber(firstTokenAmount)
                                                          .multipliedBy(100)
                                                          .div(
                                                              convertWeiToEsdt(
                                                                  selectedPool.first_token_reserve,
                                                                  selectedPool.first_token_decimals,
                                                              ),
                                                          ),
                                                  ) + '%'
                                                : '-'}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {activeTab?.absoluteIndex === 2 && (
                    <div className="vesta_x_pool_card" style={{ width: '900px' }}>
                        <div className="d-flex justify-content-center">
                            <span style={{ color: '#F1DC46', fontSize: '1.2rem' }}>{activeTab.name}</span>
                        </div>

                        <div className="mt-4 d-flex flex-column gap-3">
                            {swapRedux.isLoadingPools ? (
                                <div
                                    className="d-flex justify-content-center align-items-center mt-3"
                                    style={{ minHeight: '300px' }}
                                >
                                    <Oval
                                        height={80}
                                        width={80}
                                        color="#F1DC46"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                        ariaLabel="oval-loading"
                                        secondaryColor="#F1DC46"
                                        strokeWidth={2}
                                        strokeWidthSecondary={2}
                                    />
                                </div>
                            ) : myLiquidityPools.length > 0 ? (
                                myLiquidityPools.map((pool, index) => (
                                    <MyLiquidityRow key={`p-m-r-${index}`} pool={pool} />
                                ))
                            ) : (
                                <div className="my-liquidity-no-liquidity mt-3">You do not have any LP token.</div>
                            )}
                        </div>
                    </div>
                )}

                {activeTab?.absoluteIndex === 3 && <Slip />}
            </div>
        </div>
    );
};
