import React, { useEffect, useState } from 'react';
import { Info } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { TokenTransfer } from '@multiversx/sdk-core/out';
import { useGetAccount, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import { NftType } from '@multiversx/sdk-dapp/types/tokens.types';
import { EAURYN_TOKEN_ID, ELITE_ACCOUNT_TIER_NAMES, VEAURYN_TOKEN_ID } from 'config';
import { selectFarm, selectNetstats } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { eatUpdateEliteAccount, snakeCoilViewBaseContext } from 'z/elrond';
import { SnakeCoilBaseContext } from 'z/types';
import {
    BIG_NUMBER_ZERO,
    formatNumber,
    convertWeiToEsdt,
    DEFAULT_DECIMALS,
    ERROR_CONNECT_WALLET,
    ERROR_TRANSACTION_ONGOING,
    parseBigNumber,
    toastError,
    ZERO_STRING,
} from 'z/utils';

export function EliteAccount() {
    const { address } = useGetAccount();
    const { hasPendingTransactions } = useGetPendingTransactions();

    const netstatsRedux = useAppSelector(selectNetstats);
    const farmRedux = useAppSelector(selectFarm);

    const [veaurynBalance, setVeaurynBalance] = useState<string>(ZERO_STRING);
    const [walletVeauryns, setWalletVeauryns] = useState<NftType[]>([]);

    const [snakeCoilBaseContext, setSnakeCoilBaseContext] = useState<SnakeCoilBaseContext>();

    useEffect(() => {
        if (hasPendingTransactions) return;

        (async () => {
            const _snakeCoilBaseContext = await snakeCoilViewBaseContext();
            setSnakeCoilBaseContext(_snakeCoilBaseContext);
        })();
    }, [hasPendingTransactions]);

    useEffect(() => {
        if (netstatsRedux.walletMetaEsdtMap && netstatsRedux.walletMetaEsdtMap[VEAURYN_TOKEN_ID]) {
            const _metaesdts = netstatsRedux.walletMetaEsdtMap[VEAURYN_TOKEN_ID];
            setWalletVeauryns(_metaesdts);
            if (_metaesdts.length > 0) {
                let _balance = BIG_NUMBER_ZERO;
                for (const metaEsdt of _metaesdts) {
                    _balance = _balance.plus(metaEsdt.balance);
                }
                setVeaurynBalance(_balance.toFixed(0));
            }
        }
    }, [netstatsRedux]);

    async function onUpdateEliteAccount() {
        let error = '';
        if (!address) {
            error = ERROR_CONNECT_WALLET;
        } else if (hasPendingTransactions) {
            error = ERROR_TRANSACTION_ONGOING;
        }
        if (error) {
            toastError(error);
            return;
        }

        const payments: TokenTransfer[] = [];
        if (netstatsRedux.walletTokensMap[EAURYN_TOKEN_ID]) {
            const token = netstatsRedux.walletTokensMap[EAURYN_TOKEN_ID];
            if (token.balance) {
                payments.push(TokenTransfer.fungibleFromBigInteger(token.identifier, token.balance));
            }
        }
        if (walletVeauryns.length > 0) {
            walletVeauryns.map((metaEsdt) =>
                payments.push(
                    TokenTransfer.metaEsdtFromBigInteger(metaEsdt.collection, metaEsdt.nonce, metaEsdt.balance),
                ),
            );
        }
        await eatUpdateEliteAccount(payments, address);
    }

    return (
        <>
            <div className="container" style={{ marginTop: '50px' }}>
                <div className="d-flex justify-content-center mt-4">
                    <div className="vesta_x_pool_card" style={{ width: '900px' }}>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex justify-content-center" style={{ marginLeft: 'calc(50% - 50px)' }}>
                                <span style={{ color: '#F1DC46', fontSize: '1.3rem' }}>Elite Account</span>
                            </div>
                        </div>

                        <div className="row mt-4" style={{ rowGap: '20px' }}>
                            <div className="col-md-12">
                                <div className="swap-info mt-2">
                                    <div className="swap-info-li">
                                        <div className="d-flex align-items-center">
                                            <div>AURYNDEX™</div>

                                            <Tooltip
                                                arrow
                                                title="OURO/AURYN Index"
                                                className="ms-1"
                                                style={{
                                                    fontSize: '1.1rem',
                                                    color: '#98a1c0',
                                                }}
                                            >
                                                <Info />
                                            </Tooltip>
                                        </div>
                                        <span>
                                            {snakeCoilBaseContext
                                                ? formatNumber(
                                                      parseBigNumber(snakeCoilBaseContext.token_supplies[0]).div(
                                                          snakeCoilBaseContext.token_supplies[1],
                                                      ),
                                                      9,
                                                  )
                                                : '???'}
                                        </span>
                                    </div>
                                </div>

                                <div className="text-center mt-3" style={{ color: 'white', fontSize: '1.25rem' }}>
                                    Your Elite Tier {Math.max(farmRedux.eliteAccountTier - 1, 0)}{' '}
                                    {farmRedux.eliteAccountTier > 1 &&
                                        ` : ${ELITE_ACCOUNT_TIER_NAMES[farmRedux.eliteAccountTier]}`}
                                </div>
                                <div className="text-center" style={{ color: '#ffc90e', fontSize: '1.1rem' }}>
                                    DEB = {farmRedux.deb}x
                                </div>

                                <div className="swap-info mt-2">
                                    <div className="swap-info-li">
                                        <span>Elite-Auryn</span>
                                        <span>
                                            {formatNumber(
                                                convertWeiToEsdt(
                                                    netstatsRedux.walletTokensMap[EAURYN_TOKEN_ID]
                                                        ? netstatsRedux.walletTokensMap[EAURYN_TOKEN_ID].balance ??
                                                              ZERO_STRING
                                                        : ZERO_STRING,
                                                    DEFAULT_DECIMALS,
                                                    DEFAULT_DECIMALS,
                                                ),
                                            )}
                                        </span>
                                    </div>
                                    <div className="swap-info-li">
                                        <span>Vested Elite-Auryn</span>
                                        <span>{formatNumber(convertWeiToEsdt(veaurynBalance))}</span>
                                    </div>
                                    <div className="swap-info-li">
                                        <span>Total Elite-Auryn</span>
                                        <span>
                                            {formatNumber(
                                                convertWeiToEsdt(
                                                    parseBigNumber(
                                                        netstatsRedux.walletTokensMap[EAURYN_TOKEN_ID]
                                                            ? netstatsRedux.walletTokensMap[EAURYN_TOKEN_ID].balance ??
                                                                  ZERO_STRING
                                                            : ZERO_STRING,
                                                    ).plus(veaurynBalance),
                                                ),
                                            )}
                                        </span>
                                    </div>
                                </div>

                                <div className="d-flex just-content-center">
                                    <button
                                        className="mt-3 vesta-button-first"
                                        disabled={hasPendingTransactions}
                                        onClick={onUpdateEliteAccount}
                                    >
                                        Update Elite Account
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
