import React, { useState, useEffect } from 'react';
import { TokenTransfer } from '@multiversx/sdk-core/out';
import {
    useGetAccount,
    useGetPendingTransactions,
} from '@multiversx/sdk-dapp/hooks';
import clsx from 'clsx';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { SlippageSetting } from 'components/SlippageSetting';
import { STABLESWAP_LP_TOKEN, STABLESWAP_TOKENS } from 'config';
import { selectNetstats, selectSwap } from 'redux/reducers';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { routeNames } from 'routes';
import {
    getTokenBalanceFromApi,
    getTokenDecimals,
    getTokenLogo,
    StableswapContract,
    StableswapPool,
} from 'z/elrond';
import { useVestaCommonHooks } from 'z/hooks';
import { useAdminHooks } from 'z/hooks/admin';
import { BigNumber } from 'z/types';
import {
    applySlippage,
    formatNumber,
    convertEsdtToWei,
    createTokenTicker,
    convertWeiToEsdt,
    ERROR_INVALID_NUMBER,
    ERROR_NOT_ENOUGH_BALANCE,
    isPositiveOrZeroBigNumber,
    parseBigNumber,
    ERROR_CONNECT_WALLET,
    TEXT_SELECT_POOL,
    toastError,
    ZERO_STRING,
    convertToDollarString,
    DEFAULT_DECIMALS,
} from 'z/utils';
import { AddLiquidityInputToken } from './AddLiquidityInputToken';
import { StableMyLiquidityRow } from './StableMyLiquidityRow';
import { StablePoolRow } from './StablePoolRow';

function cloneAndReplace<T>(array: Array<T>, index: number, value: T): Array<T> {
    const newArray = [...array];
    newArray[index] = value;
    return newArray;
}

enum TabRole {
    Guest = 0,
    LoggedIn = 1,
}

interface Tab {
    name: string;
    route: string;
    role: TabRole;
    absoluteIndex: number;
}

const poolTabs: Tab[] = [
    {
        name: 'Pools',
        route: '',
        role: TabRole.Guest,
        absoluteIndex: 0,
    },
    {
        name: 'Add Liquidity',
        route: 'add-liquidity',
        role: TabRole.Guest,
        absoluteIndex: 1,
    },
    {
        name: 'My Liquidity',
        route: 'my-liquidity',
        role: TabRole.LoggedIn,
        absoluteIndex: 2,
    },
];

export const StablePool = () => {
    const { hasPendingTransactions } = useGetPendingTransactions();
    const { address } = useGetAccount();
    const navigate = useNavigate();
    const { selectedTab } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const selectedPoolIndex = parseInt(searchParams.get('pool') ?? '-1');
    const { getTokenPrice } = useVestaCommonHooks();
    const dispatch = useAppDispatch();

    const swapRedux = useAppSelector(selectSwap);
    const { walletTokensMap } = useAppSelector(selectNetstats);

    const [tabs, setTabs] = useState<Tab[]>([]);
    useEffect(() => {
        let role = TabRole.Guest;
        if (address) role = TabRole.LoggedIn;

        const filteredTabs = poolTabs.filter((tabEl) => tabEl.role <= role);
        setTabs(filteredTabs);
    }, [address]);

    const [activeTab, setActiveTab] = useState<Tab | undefined>(undefined);
    useEffect(() => {
        for (const tab of tabs) {
            if (tab.route === selectedTab) {
                return setActiveTab(tab);
            }
        }
        setActiveTab(tabs[0]);
    }, [tabs, selectedTab]);

    const [focusTabClass, setFocusTabClass] = useState<Tab | undefined>(undefined);
    useEffect(() => {
        const classObject: any = {
            'tab-focus': true,
        };
        for (let index = 0; index < tabs.length; index++) {
            classObject[`tab-focus-left-${Math.round(100 / tabs.length) * index}`] =
                activeTab?.absoluteIndex === tabs[index].absoluteIndex;
        }
        setFocusTabClass(classObject);
    }, [activeTab, tabs]);

    function gotoTab(index: number) {
        if (hasPendingTransactions) return;
        if (activeTab?.absoluteIndex == tabs[index].absoluteIndex) return;
        navigate(routeNames.stablePools + (index > 0 ? '/' + tabs[index].route : ''));
    }
    function onChangeSelectedPoolIndex(value: number) {
        setSearchParams({
            pool: value.toString(),
        });
    }

    const [selectedPool, setSelectedPool] = useState<StableswapPool>();
    const tokenTickers = STABLESWAP_TOKENS.map((tokenId) => createTokenTicker(tokenId));
    const [tokenAmounts, setTokenAmounts] = useState<string[]>([ZERO_STRING, ZERO_STRING, ZERO_STRING]);
    const [tokenBalances, setTokenBalances] = useState<string[]>([ZERO_STRING, ZERO_STRING, ZERO_STRING]);
    const [tokenAmountErrors, setTokenAmountErrors] = useState<string[]>(['', '', '']);
    const [expectedLiquidity, setExpectedLiquidity] = useState<BigNumber>();

    //
    function resetInputs() {
        setTokenAmounts([ZERO_STRING, ZERO_STRING, ZERO_STRING]);
        setTokenAmountErrors(['', '', '']);
        setExpectedLiquidity(undefined);
    }

    // query balance of input and output tokens
    useEffect(() => {
        if (!address) return;
        setTokenBalances([
            walletTokensMap[STABLESWAP_TOKENS[0]]?.balance ?? ZERO_STRING,
            walletTokensMap[STABLESWAP_TOKENS[1]]?.balance ?? ZERO_STRING,
            walletTokensMap[STABLESWAP_TOKENS[2]]?.balance ?? ZERO_STRING,
        ]);
    }, [address, walletTokensMap]);

    const contract = new StableswapContract();
    useEffect(() => {
        if (hasPendingTransactions) return;

        (async () => {
            const _pool = await contract.viewPoolContext();
            setSelectedPool(_pool);
        })();
    }, [hasPendingTransactions]);

    function onChangeTokenAmount(index: number, value: string) {
        let error = '';
        if (!selectedPool) {
            error = TEXT_SELECT_POOL;
        } else if (!isPositiveOrZeroBigNumber(value)) {
            error = ERROR_INVALID_NUMBER;
        } else if (
            address &&
            convertEsdtToWei(value, getTokenDecimals(STABLESWAP_TOKENS[index])).comparedTo(tokenBalances[index]) > 0
        ) {
            error = ERROR_NOT_ENOUGH_BALANCE;
        }

        setTokenAmountErrors(cloneAndReplace(tokenAmountErrors, index, error));
        const newAmounts = cloneAndReplace(tokenAmounts, index, value);
        setTokenAmounts(newAmounts);

        // compute expected liquidity
        // computation is placed here to avoid more React hooks
        contract
            .estimateAddLiquidity(newAmounts.map(
                (amount, i) => convertEsdtToWei(amount, getTokenDecimals(STABLESWAP_TOKENS[i]))
            ))
            .then((res) => setExpectedLiquidity(res));

        return error;
    }

    function onMaxTokenAmount(index: number) {
        if (address && selectedPool) {
            onChangeTokenAmount(
                index,
                convertWeiToEsdt(
                    tokenBalances[index],
                    getTokenDecimals(STABLESWAP_TOKENS[index]),
                    getTokenDecimals(STABLESWAP_TOKENS[index]),
                ).toFixed(),
            );
        }
    }

    async function onAddLiquidity() {
        if (!address) {
            toastError(ERROR_CONNECT_WALLET);
            return;
        }
        if (!selectedPool) {
            toastError(TEXT_SELECT_POOL);
            return;
        }
        const error = tokenAmountErrors.find((v) => !!v);
        if (error) {
            toastError(error);
            return;
        }
        if (!expectedLiquidity) {
            toastError('Expected liquidity is empty');
            return;
        }

        // apply slippage and remove fractional part
        const minLiquidity = new BigNumber(applySlippage(expectedLiquidity, -swapRedux.slippage).toFixed(0));
        await contract.addLiquidity(
            tokenAmounts.map((tokenAmount, i) => TokenTransfer.fungibleFromAmount(
                STABLESWAP_TOKENS[i],
                tokenAmount,
                getTokenDecimals(STABLESWAP_TOKENS[i])
            )),
            minLiquidity,
            address,
        );

        resetInputs();
    }

    const [lpTokenBalance, setLpTokenBalance] = useState<BigNumber>(new BigNumber(0));
    useEffect(() => {
        if (!address || hasPendingTransactions) return;
        getTokenBalanceFromApi(address, STABLESWAP_LP_TOKEN)
            .then((v) => setLpTokenBalance(v ? parseBigNumber(v.balance) : new BigNumber(0)))
            .catch(() => setLpTokenBalance(new BigNumber(0)));
    }, [address, hasPendingTransactions]);
    const [expectedSize, setExpectedSize] = useState<number>(0);
    const [efficiency, setEfficiency] = useState<number>(0);
    useEffect(() => {
        if (!selectedPool || !expectedLiquidity) {
            setExpectedSize(0);
        } else {
            (async () => {
                const _usdValue = await contract.computeUsdValue(selectedPool, expectedLiquidity);
                setExpectedSize(_usdValue.toNumber());

                const _sum = tokenAmounts.reduce((s, cur) => s += Number(cur), 0);
                setEfficiency(_usdValue.toNumber() * 100 / _sum);
            })();
        }
    }, [selectedPool, expectedLiquidity]);

    return (
        <div className="container" style={{ marginTop: '50px' }}>
            <div className="d-flex justify-content-center" style={{ overflowX: 'auto' }}>
                <div className="tab-box">
                    {
                        <>
                            <div className={clsx(focusTabClass)} />
                            {tabs.map(({ name }, index) => {
                                return (
                                    <div className="tab" key={`p-t-h-${index}`} onClick={() => gotoTab(index)}>
                                        {name}
                                    </div>
                                );
                            })}
                        </>
                    }
                </div>
            </div>

            <div className="d-flex justify-content-center mt-4">
            {activeTab?.absoluteIndex === 0 && (
                    <div className="vesta-first-container">
                        <div className="d-flex justify-content-center">
                            <span style={{ color: '#F1DC46', fontSize: '1.2rem' }}>{activeTab.name}</span>
                        </div>

                        <div className="mt-4 d-flex flex-column gap-3">
                            {!!selectedPool && <StablePoolRow pool={selectedPool} />}
                        </div>

                        {/* {isAdmin && (
                            <div className="d-flex just-content-center">
                                <button
                                    className="mt-4 add-liquidity-button"
                                    onClick={() => {
                                        navigate(routeNames.createPool);
                                    }}
                                >
                                    Create Pool
                                </button>
                            </div>
                        )} */}
                    </div>
                )}

                {activeTab?.absoluteIndex === 1 && (
                    <div className="vesta_x_pool_card" style={{ width: '900px' }}>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex justify-content-center" style={{ marginLeft: 'calc(50% - 50px)' }}>
                                <span style={{ color: '#F1DC46', fontSize: '1.2rem' }}>{activeTab.name}</span>
                            </div>

                            <SlippageSetting />
                        </div>

                        <div className="row mt-4" style={{ rowGap: '20px' }}>
                            <div className="col-md-6">
                                <span style={{ color: 'white', fontSize: '1.1rem' }}>Select Pool</span>

                                <div className="token-pair-box mt-2">
                                    <button className="selected-pool-buttton" disabled>
                                        <div className="d-flex justify-content-between gap-2">
                                            <div>
                                                <img
                                                    src={getTokenLogo(STABLESWAP_TOKENS[0])}
                                                    alt="logo"
                                                    width="30px"
                                                />
                                                {tokenTickers[0]}
                                            </div>
                                            +
                                            <div>
                                                <img
                                                    src={getTokenLogo(STABLESWAP_TOKENS[1])}
                                                    alt="logo"
                                                    width="30px"
                                                />
                                                {tokenTickers[1]}
                                            </div>
                                            +
                                            <div>
                                                <img
                                                    src={getTokenLogo(STABLESWAP_TOKENS[2])}
                                                    alt="logo"
                                                    width="30px"
                                                />
                                                {tokenTickers[2]}
                                            </div>
                                        </div>
                                    </button>
                                </div>

                                <div className="mt-4" style={{ color: 'white', fontSize: '1.1rem' }}>
                                    Deposit Amounts
                                </div>

                                <div className="d-flex flex-column gap-1 mt-2">
                                    {
                                        STABLESWAP_TOKENS.map((tokenId, index) => <AddLiquidityInputToken
                                            key={index}
                                            tokenAmount={tokenAmounts[index]}
                                            onChangeTokenAmount={(value) => onChangeTokenAmount(index, value)}
                                            tokenId={tokenId}
                                            tokenTicker={tokenTickers[index]}
                                            tokenBalance={
                                                convertWeiToEsdt(
                                                    tokenBalances[index],
                                                    getTokenDecimals(tokenId),
                                                    getTokenDecimals(tokenId),
                                                ).toFixed()
                                            }
                                            onMaxTokenAmount={() => onMaxTokenAmount(index)}
                                            error={tokenAmountErrors[index]}
                                        />)
                                    }
                                </div>
                                <div className="d-flex just-content-center">
                                    <button className="mt-4 add-liquidity-button" onClick={onAddLiquidity}>
                                        Add Liquidity
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div style={{ color: 'white', fontSize: '1.1rem' }}>Pool Info</div>

                                <div className="swap-info mt-2">
                                    <div className="swap-info-li">
                                        <span>TVL</span>
                                        <span>
                                            {
                                                // compute price of liquidity, assuming the first token is USDC in USDC-USDT-USDD pool and 1 USDC is $1
                                                selectedPool ? convertToDollarString(
                                                            convertWeiToEsdt(selectedPool.token_balances[0], getTokenDecimals(STABLESWAP_TOKENS[0]))
                                                                .multipliedBy(STABLESWAP_TOKENS.length),
                                                        )
                                                    : '-'
                                            }
                                        </span>
                                    </div>

                                    {
                                        STABLESWAP_TOKENS.map((tokenId, index) => <div key={index} className="swap-info-li">
                                            <span>{tokenTickers[index]}</span>
                                            <span>
                                                {selectedPool ? formatNumber(
                                                    convertWeiToEsdt(
                                                        selectedPool.token_balances[index],
                                                        getTokenDecimals(tokenId),
                                                    )
                                                ) : '-'}
                                            </span>
                                        </div>)
                                    }

                                    {/* <div className="swap-info-li">
                                        <span>
                                            {selectedPool ? `${firstTokenTicker} per ${secondTokenTicker}` : '-'}
                                        </span>
                                        <span>
                                            {isPositiveBigNumber(reverseExchangeRate)
                                                ? formatNumber(reverseExchangeRate, 6)
                                                : '-'}
                                        </span>
                                    </div>

                                    <div className="swap-info-li">
                                        <span>
                                            {selectedPool ? `${secondTokenTicker} per ${firstTokenTicker}` : '-'}
                                        </span>
                                        <span>
                                            {isPositiveBigNumber(exchangeRate) ? formatNumber(exchangeRate, 6) : '-'}
                                        </span>
                                    </div> */}

                                    {/* <div className="swap-info-li">
                                        <span>Pool Share</span>
                                        <span>
                                            {firstTokenAmount && selectedPool
                                                ? formatNumber(
                                                      parseBigNumber(firstTokenAmount)
                                                          .multipliedBy(100)
                                                          .div(
                                                              convertWeiToEsdt(
                                                                  selectedPool.first_token_reserve,
                                                                  selectedPool.first_token_decimals,
                                                              ),
                                                          ),
                                                  ) + '%'
                                                : '-'}
                                        </span>
                                    </div> */}
                                </div>

                                <div style={{ color: 'white', fontSize: '1.1rem' }} className='mt-4'>Your Position</div>

                                <div className="swap-info mt-2">
                                    <div className="swap-info-li">
                                        <span>Size</span>
                                        <span>{convertToDollarString(expectedSize)}</span>
                                    </div>

                                    <div className="swap-info-li">
                                        <span>Pool Share</span>
                                        <span>
                                            {selectedPool && expectedLiquidity
                                                ? formatNumber(
                                                    expectedLiquidity
                                                        .multipliedBy(100)
                                                        .div(expectedLiquidity.plus(selectedPool.lp_token_supply)),
                                                  ) + '%'
                                                : '-'}
                                        </span>
                                    </div>

                                    <div className="swap-info-li">
                                        <span>Efficiency</span>
                                        <span>{formatNumber(efficiency, 2) + '%'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {activeTab?.absoluteIndex === 2 && (
                    <div className="vesta_x_pool_card" style={{ width: '900px' }}>
                        <div className="d-flex justify-content-center">
                            <span style={{ color: '#F1DC46', fontSize: '1.2rem' }}>{activeTab.name}</span>
                        </div>

                        <div className="mt-4 d-flex flex-column gap-3">
                            {selectedPool && lpTokenBalance.isGreaterThan(0) ? <StableMyLiquidityRow pool={selectedPool} lpTokenBalance={lpTokenBalance} />
                                : (
                                    <div className="my-liquidity-no-liquidity mt-3">You do not have any LP token.</div>
                                )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
