import React, { Dispatch, SetStateAction } from 'react';
import Pagination from 'react-bootstrap/Pagination';

interface NftsPaginationProps {
    itemsPerPage?: number;
    itemsLength: number;
    selectedPage: number;
    setSelectedPage: Dispatch<SetStateAction<number>>;
}

const NftsPagination = ({ itemsPerPage = 5, itemsLength, selectedPage, setSelectedPage }: NftsPaginationProps) => {
    const totalPages = itemsLength / itemsPerPage;

    return (
        <Pagination className="d-flex justify-content-center">
            {Array.from({ length: Math.ceil(totalPages) }, (_, i) => i + 1).map((pageIndex) => (
                <Pagination.Item
                    key={`pagination-item-${pageIndex}`}
                    active={selectedPage === pageIndex}
                    onClick={() => setSelectedPage(pageIndex)}
                >
                    {pageIndex}
                </Pagination.Item>
            ))}
        </Pagination>
    );
};

export default NftsPagination;
