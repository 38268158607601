import BigNumber from 'bignumber.js';

import { TOKEN_INFO_MAP } from 'config';
import { NetstatsState } from 'redux/reducers';
import {
    LiquidityTokenSelectorForVault,
    MemeLiquidityVaultUserContextType,
    MemeLiquidityVestaVaultSelectedHolding,
    OuroLiquidityVaultUserContextType,
    OuroLiquidityVestaVaultSelectedHolding,
    SwapPoolType,
} from 'z/types';
import { BIG_NUMBER_ZERO, convertToDollarString, formatNumber } from './bignum';
import { convertWeiToEsdt } from './token';

export const isLiquidityNativeWalletSelectedHolding = (
    selectedHolding: OuroLiquidityVestaVaultSelectedHolding | MemeLiquidityVestaVaultSelectedHolding,
): boolean =>
    [
        OuroLiquidityVestaVaultSelectedHolding.WalletNativeOuroUSDD,
        OuroLiquidityVestaVaultSelectedHolding.WalletNativeOuroUSDC,
        OuroLiquidityVestaVaultSelectedHolding.WalletNativeOuroVST,
        OuroLiquidityVestaVaultSelectedHolding.WalletNativeOurobVST,
        OuroLiquidityVestaVaultSelectedHolding.WalletNativeOurosVST,
        OuroLiquidityVestaVaultSelectedHolding.WalletNativeOurofVST,
        OuroLiquidityVestaVaultSelectedHolding.WalletNativeOuroEstar,
        OuroLiquidityVestaVaultSelectedHolding.WalletNativeOuroKoson,
        OuroLiquidityVestaVaultSelectedHolding.WalletNativeOuroXLH,
        OuroLiquidityVestaVaultSelectedHolding.WalletNativeOuroGSC,
        OuroLiquidityVestaVaultSelectedHolding.WalletNativeOuroSSC,
        OuroLiquidityVestaVaultSelectedHolding.WalletNativeOuroWegld,

        MemeLiquidityVestaVaultSelectedHolding.WalletUsdcJaket,
        MemeLiquidityVestaVaultSelectedHolding.WalletUsdcPadwn,
    ].includes(selectedHolding);

export const isLiquiditySecuredWalletSelectedHolding = (
    selectedHolding: OuroLiquidityVestaVaultSelectedHolding | MemeLiquidityVestaVaultSelectedHolding,
): boolean =>
    [
        OuroLiquidityVestaVaultSelectedHolding.WalletSecuredOuroUSDD,
        OuroLiquidityVestaVaultSelectedHolding.WalletSecuredOuroUSDC,
        OuroLiquidityVestaVaultSelectedHolding.WalletSecuredOuroVST,
        OuroLiquidityVestaVaultSelectedHolding.WalletSecuredOurobVST,
        OuroLiquidityVestaVaultSelectedHolding.WalletSecuredOuroEstar,
        OuroLiquidityVestaVaultSelectedHolding.WalletSecuredOuroKoson,
        OuroLiquidityVestaVaultSelectedHolding.WalletSecuredOuroXLH,
        OuroLiquidityVestaVaultSelectedHolding.WalletSecuredOuroGSC,
        OuroLiquidityVestaVaultSelectedHolding.WalletSecuredOuroSSC,

        MemeLiquidityVestaVaultSelectedHolding.WalletVegldJaket,
        MemeLiquidityVestaVaultSelectedHolding.WalletVegldPadwn,
        MemeLiquidityVestaVaultSelectedHolding.WalletVegldRaccoon,
    ].includes(selectedHolding);

export const isLiquidityNativeVaultSelectedHolding = (
    selectedHolding: OuroLiquidityVestaVaultSelectedHolding | MemeLiquidityVestaVaultSelectedHolding,
): boolean =>
    [
        OuroLiquidityVestaVaultSelectedHolding.VaultNativeOuroUSDD,
        OuroLiquidityVestaVaultSelectedHolding.VaultNativeOuroUSDC,
        OuroLiquidityVestaVaultSelectedHolding.VaultNativeOuroVST,
        OuroLiquidityVestaVaultSelectedHolding.VaultNativeOurobVST,
        OuroLiquidityVestaVaultSelectedHolding.VaultNativeOurosVST,
        OuroLiquidityVestaVaultSelectedHolding.VaultNativeOurofVST,
        OuroLiquidityVestaVaultSelectedHolding.VaultNativeOuroEstar,
        OuroLiquidityVestaVaultSelectedHolding.VaultNativeOuroKoson,
        OuroLiquidityVestaVaultSelectedHolding.VaultNativeOuroXLH,
        OuroLiquidityVestaVaultSelectedHolding.VaultNativeOuroGSC,
        OuroLiquidityVestaVaultSelectedHolding.VaultNativeOuroSSC,
        OuroLiquidityVestaVaultSelectedHolding.VaultNativeOuroWegld,

        MemeLiquidityVestaVaultSelectedHolding.VaultUsdcJaket,
        MemeLiquidityVestaVaultSelectedHolding.VaultUsdcPadwn,
    ].includes(selectedHolding);

export const isLiquiditySecuredVaultSelectedHolding = (
    selectedHolding: OuroLiquidityVestaVaultSelectedHolding | MemeLiquidityVestaVaultSelectedHolding,
): boolean =>
    [
        OuroLiquidityVestaVaultSelectedHolding.VaultSecuredOuroUSDD,
        OuroLiquidityVestaVaultSelectedHolding.VaultSecuredOuroUSDC,
        OuroLiquidityVestaVaultSelectedHolding.VaultSecuredOuroVST,
        OuroLiquidityVestaVaultSelectedHolding.VaultSecuredOurobVST,
        OuroLiquidityVestaVaultSelectedHolding.VaultSecuredOuroEstar,
        OuroLiquidityVestaVaultSelectedHolding.VaultSecuredOuroKoson,
        OuroLiquidityVestaVaultSelectedHolding.VaultSecuredOuroXLH,
        OuroLiquidityVestaVaultSelectedHolding.VaultSecuredOuroGSC,
        OuroLiquidityVestaVaultSelectedHolding.VaultSecuredOuroSSC,

        MemeLiquidityVestaVaultSelectedHolding.VaultVegldJaket,
        MemeLiquidityVestaVaultSelectedHolding.VaultVegldPadwn,
        MemeLiquidityVestaVaultSelectedHolding.VaultVegldRaccoon,
    ].includes(selectedHolding);

export const printPremiumRewardAndPrice = (
    vaultUserContext: OuroLiquidityVaultUserContextType | MemeLiquidityVaultUserContextType | undefined,
    index: number,
    getTokenPrice: any,
) => {
    if (vaultUserContext && vaultUserContext.premium_reward_payments.length == 3) {
        const tokenId = vaultUserContext.premium_reward_payments[index].token_identifier;
        const decimals = TOKEN_INFO_MAP[tokenId].decimals;
        const amount = convertWeiToEsdt(vaultUserContext.premium_reward_payments[index].amount, decimals, decimals);
        if (!amount.isZero()) {
            return `${formatNumber(amount)} (${convertToDollarString(amount.multipliedBy(getTokenPrice(tokenId)))})`;
        }
    }

    return '0 (0$)';
};

export const printWalletBalanceAndPrice = (
    netstatsRedux: NetstatsState,
    tokenId: string,
    getTokenPrice: any,
): string => {
    const token = netstatsRedux.walletTokensMap[tokenId];
    if (token) {
        const tokenBalance = convertWeiToEsdt(token.balance, token.decimals, token.decimals);
        return `${formatNumber(tokenBalance)} (${convertToDollarString(
            tokenBalance.multipliedBy(getTokenPrice(tokenId)),
        )})`;
    }

    return '0 (0$)';
};

export const getTokensAmountFromLp = (lp: LiquidityTokenSelectorForVault, lp_amount: BigNumber, swapPools: SwapPoolType[]) => {
    const swapPool = swapPools.find(
        ({ first_token_id, second_token_id }) =>
            [first_token_id, second_token_id].includes(lp.first_token_id) &&
            [first_token_id, second_token_id].includes(lp.second_token_id),
    );
    if (!swapPool) return { ...lp, lp_amount, first_amount: BIG_NUMBER_ZERO, second_amount: BIG_NUMBER_ZERO };

    const first_amount = lp_amount
        .multipliedBy(
            swapPool.first_token_id == lp.first_token_id ? swapPool.first_token_reserve : swapPool.second_token_reserve,
        )
        .div(swapPool.lp_token_supply);
    const second_amount = lp_amount
        .multipliedBy(
            swapPool.first_token_id == lp.second_token_id
                ? swapPool.first_token_reserve
                : swapPool.second_token_reserve,
        )
        .div(swapPool.lp_token_supply);
    return { ...lp, lp_amount, first_amount, second_amount };
};

export const getLiquidityVaultLpDisplayList = (
    lpList: LiquidityTokenSelectorForVault[],
    vaultUserContext?: OuroLiquidityVaultUserContextType | MemeLiquidityVaultUserContextType,
    swapPools: SwapPoolType[] = [],
) =>
    lpList.map((lp) => {
        const lp_amount = new BigNumber(
            (vaultUserContext &&
                vaultUserContext.lp_token_staked_amounts.find(
                    ({ token_identifier }) => token_identifier === lp.token_id,
                )?.amount) ||
                '0',
        );
        return getTokensAmountFromLp(lp, lp_amount, swapPools);
    });

export const getLiquidityWalletLpDisplayList = (
    lpList: LiquidityTokenSelectorForVault[],
    netstatsRedux: NetstatsState,
    swapPools: SwapPoolType[] = [],
) =>
    lpList.map((lp) => {
        const lp_amount = new BigNumber(netstatsRedux.walletTokensMap[lp.token_id]?.balance || '0');
        return getTokensAmountFromLp(lp, lp_amount, swapPools);
    });