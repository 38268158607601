import React from 'react';

export const CountdownCompleted = () => (
  <div className="presale-timer-container text-center">
    Unlockable
  </div>
);

// Renderer callback with condition
export const countdownRenderer = ({
  days,
  hours,
  minutes,
  // seconds,
  completed,
} : {
  days: number,
  hours: number,
  minutes: number,
  seconds: number,
  completed: boolean,
}) => {
  if (completed) {
    return <CountdownCompleted />;
  }
  return (
    <div className="presale-timer-container text-center">
      <strong>
        <span className="presale-timer-box me-2">{String(days).padStart(2, '0')}d</span>
        <span className="presale-timer-box me-2">{String(hours).padStart(2, '0')}h</span>
        <span className="presale-timer-box me-2">{String(minutes).padStart(2, '0')}m</span>
      </strong>
    </div>
  );
};
