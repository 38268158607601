import React, { useEffect, useState } from 'react';
import { TokenTransfer } from '@multiversx/sdk-core/out';
import { useGetAccountInfo, useGetNetworkConfig, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';
import clsx from 'clsx';
import { Row, Col } from 'react-bootstrap';
import Countdown from 'react-countdown';
import { CountdownCompleted, countdownRenderer } from 'components/CountdownComponents';
import { AURYN_TOKEN_ID, EAURYN_TOKEN_ID, OURO_TOKEN_ID, VEAURYN_TOKEN_ID } from 'config';
import { selectNetstats } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { getTokenLogo } from 'z/elrond';
import { claimUncoiled, snakeUncoil } from 'z/elrond';
import { BIG_NUMBER_ZERO } from 'z/utils';

interface IAutostakeUncoilRowProps {
    index: number;
    amount?: string;
    disabled?: boolean;
    exchangeRate?: BigNumber;
    claimTimestamp?: number;
    eliteAccountTier: number;
    selectedToken: 'AURYN' | 'EAURYN';
    maxEliteAurynUncoilAmount?: BigNumber;
}

const UNCOIL_FEE_TRESHOLD = [0, 50, 100, 200, 350, 550, 800];

const UNCOIL_FEES = [
    [8, 9, 10, 11, 12, 13, 14],
    [7, 8, 9, 10, 11, 12, 13],
    [6, 7, 8, 9, 10, 11, 12],
    [5, 6, 7, 8, 9, 10, 11],
    [4, 5, 6, 7, 8, 9, 10],
    [3, 4, 5, 6, 7, 8, 9],
    [2, 3, 4, 5, 6, 7, 8],
];
const ROMAN_NUMERALS = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII'];
const AURYN_MAX_UNCOIL_DAYS = 21;
const EAURYN_UNCOIL_DAYS = [70, 70, 63, 56, 49, 42, 35, 28, 21];

const ELITE_ACCOUNT_TIER_EAURYN_HOLDINGS = [
    // tier 0 - 7
    0, 500, 1_000, 2_000, 5_000, 10_000, 20_000, 50_000,
];

export const AutostakeUncoilRow = ({
    index,
    amount,
    disabled,
    exchangeRate,
    claimTimestamp,
    eliteAccountTier,
    selectedToken,
    maxEliteAurynUncoilAmount,
}: IAutostakeUncoilRowProps) => {
    const netstatsRedux = useAppSelector(selectNetstats);
    const tokenIdIn = selectedToken === 'AURYN' ? AURYN_TOKEN_ID : EAURYN_TOKEN_ID;
    const tokenIdOut = selectedToken === 'AURYN' ? OURO_TOKEN_ID : AURYN_TOKEN_ID;

    const [tokenInMaxBalance, setTokenInMaxBalance] = useState<BigNumber>(
        new BigNumber(netstatsRedux.walletTokensMap[tokenIdIn]?.balance ?? '0'),
    );
    const [vestedEaurynBalance, setVestedEaurynBalance] = useState<BigNumber>(
        new BigNumber(netstatsRedux.walletTokensMap[VEAURYN_TOKEN_ID]?.balance ?? '0'),
    );
    const [inputTokenAmount, setInputTokenAmount] = useState('0');
    const [eliteAccountTierAfterUncoil, setEliteAccountTierAfterUncoil] = useState<number>(eliteAccountTier);
    const [ClaimCountdown, setClaimCountdown] = useState<any>();

    const { address } = useGetAccountInfo();
    const { hasPendingTransactions } = useGetPendingTransactions();
    const { chainID } = useGetNetworkConfig();

    const handleUncoil = async () => {
        if (parseFloat(inputTokenAmount) <= 0 || hasPendingTransactions) return;
        const transfer = TokenTransfer.fungibleFromAmount(
            selectedToken === 'AURYN' ? AURYN_TOKEN_ID : EAURYN_TOKEN_ID,
            new BigNumber(inputTokenAmount),
            18,
        );
        await snakeUncoil([transfer], address);
    };

    const handleClaimUncoiled = async () => {
        if (!canClaim() || hasPendingTransactions) return;

        await claimUncoiled(selectedToken, chainID);
    };

    const findFeeIndex = (swapAmount: number): number => {
        let _index = 0;
        for (let i = 0; i < UNCOIL_FEE_TRESHOLD.length; i++) {
            if (UNCOIL_FEE_TRESHOLD[i] <= swapAmount) {
                _index = i;
            } else {
                break;
            }
        }
        return _index;
    };

    function isNumber(text: string) {
        return /^-?\d*\.?\d+$/.test(text);
    }

    const handleInputSelect = (e: string) => {
        const value = e.replace(',', '.');
        const separatorCount = (value.match(/[.]/g) || []).length;

        if (separatorCount > 1) {
            return;
        }

        if (value.endsWith('.') || value.endsWith(',')) {
            setInputTokenAmount(value);
            return;
        }
        if (value === '' || value === '.' || value === ',' || !isNumber(value)) {
            setInputTokenAmount('0');
            return;
        }

        if (parseFloat(value) < 0) {
            setInputTokenAmount('0');
            return;
        }

        if (value.includes('.')) {
            const parts = value.split('.');
            if (parts[1].length > 18 || parts.length > 2) return;
        }

        const targetAmount = new BigNumber(value).shiftedBy(18);
        const maxAmount = selectedToken === 'AURYN' ? tokenInMaxBalance : maxEliteAurynUncoilAmount ?? new BigNumber(0);
        if (targetAmount.isGreaterThan(maxAmount)) {
            setInputTokenAmount(maxAmount.shiftedBy(-18).toString());
        } else {
            setInputTokenAmount(value);
        }
    };

    const canClaim = () => {
        if (claimTimestamp === undefined) {
            return false;
        }

        return Math.floor(Date.now() / 1000) >= claimTimestamp;
    };

    const getEliteAurynTierByBalance = (balance: BigNumber): number => {
        const remainingBalance = balance.shiftedBy(-18);
        for (let tier = 1; tier < ELITE_ACCOUNT_TIER_EAURYN_HOLDINGS.length; tier++) {
            const tierAmount = new BigNumber(ELITE_ACCOUNT_TIER_EAURYN_HOLDINGS[tier]);
            if (remainingBalance.isLessThan(tierAmount)) {
                return tier;
            }
        }

        return ELITE_ACCOUNT_TIER_EAURYN_HOLDINGS.length;
    };

    useEffect(() => {
        if (hasPendingTransactions) return;
        setTokenInMaxBalance(new BigNumber(netstatsRedux.walletTokensMap[tokenIdIn]?.balance ?? '0'));

        if (netstatsRedux.walletMetaEsdtMap && netstatsRedux.walletMetaEsdtMap[VEAURYN_TOKEN_ID]) {
            const veaurynBalance = netstatsRedux.walletMetaEsdtMap[VEAURYN_TOKEN_ID].reduce((sum, { balance }) => {
                return sum.plus(balance);
            }, BIG_NUMBER_ZERO);
            setVestedEaurynBalance(veaurynBalance);
        }
    }, [address, netstatsRedux.walletTokensMap[tokenIdIn], hasPendingTransactions]);

    useEffect(() => {
        if (hasPendingTransactions) return;
        setInputTokenAmount('0');
    }, [hasPendingTransactions, address, selectedToken]);

    useEffect(() => {
        if (selectedToken !== 'EAURYN') {
            return;
        }

        const balanceAfterUncoil = tokenInMaxBalance
            .plus(vestedEaurynBalance)
            .minus(new BigNumber(inputTokenAmount).shiftedBy(18));
        const newEliteTier = getEliteAurynTierByBalance(balanceAfterUncoil);
        setEliteAccountTierAfterUncoil(newEliteTier);
    }, [inputTokenAmount, vestedEaurynBalance, tokenInMaxBalance]);

    useEffect(() => {
        if (!address || !claimTimestamp || canClaim()) return;
        const CountdownWrapper =
            claimTimestamp * 1000 < Date.now()
                ? () => <CountdownCompleted />
                : () => <Countdown renderer={countdownRenderer} date={claimTimestamp * 1000} autoStart />;
        const MemoCountdown = React.memo(CountdownWrapper);
        setClaimCountdown(MemoCountdown);
    }, [address, claimTimestamp]);

    return (
        <Row
            className="second-card-style d-flex justify-content-between py-1 align-items-center"
            style={{ backgroundColor: '#1B0921', rowGap: '4px' }}
        >
            <Col lg={1}>
                <div className="d-flex">
                    <div className="coil-box">
                        <div
                            className="text-center d-flex align-items-center justify-content-center"
                            style={{ height: '40px' }}
                        >
                            {ROMAN_NUMERALS[index]}
                        </div>
                        <div className="text-center">Uncoil</div>
                    </div>
                </div>
            </Col>
            <Col lg={10}>
                <div className="coil-row-container">
                    <div className="d-flex gap-1 w-100">
                        <div className="w-100">
                            <input
                                className={clsx({ 'coil-input': true, disabled: disabled })}
                                onChange={(e) => handleInputSelect(e.target.value)}
                                value={inputTokenAmount}
                                type="text"
                                disabled={disabled}
                            />
                            <div className="text-right" style={{ fontSize: '0.8rem', marginRight: '10px' }}>
                                Uncoiling time:{' '}
                                {selectedToken === 'AURYN' && (
                                    <span style={{ color: '#F1DC46' }}>
                                        {Math.min(AURYN_MAX_UNCOIL_DAYS, AURYN_MAX_UNCOIL_DAYS - eliteAccountTier + 1)}{' '}
                                        days
                                    </span>
                                )}
                                {selectedToken === 'EAURYN' && (
                                    <span style={{ color: '#F1DC46' }}>
                                        {EAURYN_UNCOIL_DAYS[eliteAccountTierAfterUncoil]} days
                                    </span>
                                )}
                                {selectedToken === 'AURYN' && (
                                    <>
                                        ; Fees:{' '}
                                        <span style={{ color: '#F1DC46' }}>
                                            {UNCOIL_FEES[findFeeIndex(parseFloat(inputTokenAmount))][index]}‰
                                        </span>{' '}
                                        AURYN
                                    </>
                                )}
                            </div>
                        </div>
                        <div>
                            <img src={getTokenLogo(tokenIdIn)} width={'40px'} />
                        </div>
                    </div>

                    <div className="h-100">
                        <button
                            className="farm_but py-2 h-100"
                            onClick={() =>
                                handleInputSelect(
                                    selectedToken === 'AURYN'
                                        ? netstatsRedux.walletTokensMap[AURYN_TOKEN_ID]?.balance ?? '0'
                                        : maxEliteAurynUncoilAmount?.toString(10) ?? '0',
                                )
                            }
                            disabled={disabled}
                        >
                            =
                            <br />
                            MAX
                        </button>
                    </div>

                    <div className="d-flex gap-1 w-100">
                        <div>
                            <img src={getTokenLogo(tokenIdOut)} width={'40px'} />
                        </div>
                        <div className="w-100">
                            <input
                                className="coil-input text-start disabled"
                                value={
                                    amount
                                        ? new BigNumber(amount).shiftedBy(-18).toString(10)
                                        : new BigNumber(inputTokenAmount)
                                              .shiftedBy(18)
                                              .multipliedBy(exchangeRate ?? 0)
                                              .multipliedBy(
                                                  (1000 -
                                                      (selectedToken === 'AURYN'
                                                          ? UNCOIL_FEES[findFeeIndex(parseFloat(inputTokenAmount))][
                                                                index
                                                            ]
                                                          : 0)) /
                                                      1000,
                                              )
                                              .shiftedBy(-18)
                                              .toFixed(18)
                                              .replace(/(\.0*|(?<=\.\d*?)0*)$/, '')
                                }
                                type="text"
                                disabled
                            />
                            <div className="d-flex justify-content-between" style={{ fontSize: '0.8rem' }}>
                                <div>{selectedToken === 'AURYN' ? 'OUROBOROS' : 'AURYN'}</div>

                                <div>
                                    {disabled && amount && claimTimestamp && (
                                        <div className="d-flex">
                                            {canClaim() && <div>Ready to claim</div>}
                                            {!canClaim() && ClaimCountdown && (
                                                <div className="uncoil-timer">
                                                    <ClaimCountdown />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>

            <Col lg={1}>
                <div className="d-flex justify-content-end">
                    {disabled && canClaim() && (
                        <button className="coil-but" onClick={handleClaimUncoiled}>
                            <img src={getTokenLogo(tokenIdOut)} width={'40px'} />
                            Claim
                        </button>
                    )}
                    {disabled && !canClaim() && (
                        <button className="coil-but" disabled>
                            <img src={getTokenLogo(tokenIdOut)} width={'40px'} />
                            Claim
                        </button>
                    )}
                    {!disabled && (
                        <button className="coil-but" onClick={handleUncoil}>
                            <img src={getTokenLogo(tokenIdIn)} width={'40px'} />
                            Uncoil
                        </button>
                    )}
                </div>
            </Col>
        </Row>
    );
};
