import BigNumber from 'bignumber.js';
import { StateEnum, SwapOperationsType, SwapPoolType } from "z/types";
import { parseBigNumber } from './bignum';

interface SwapPath {
    poolIndex: number,
    tokenOut: string,
}

export function getPoolIndexWithToken(
    pools: SwapPoolType[],
    token: string,
): number {
    for (let i = 0; i < pools.length; i++) {
        if (pools[i].second_token_id == token) return i;
    }

    return -1;
}

// first_token_id of each pool is base token
export function getSwapOperations(
    pools: SwapPoolType[],
    tokenIn: string,
    tokenOut: string,
): SwapOperationsType | undefined {
    const pairAddresses: string[] = [];
    const tokenOuts: string[] = [];
    const poolIndexes: number[] = [];

    const allSwapRoutes: SwapPath[][][] = [];

    // length 1 path
    {
        const swapRoutes: SwapPath[][] = [];
        for (let i = 0; i < pools.length; i++) {
            const pool = pools[i];
            if (pool.pool_state != StateEnum.Active) continue;

            if (pool.first_token_id == tokenIn) {
                swapRoutes.push(new Array({ poolIndex: i, tokenOut: pool.second_token_id }));
            } else if (pool.second_token_id == tokenIn) {
                swapRoutes.push(new Array({ poolIndex: i, tokenOut: pool.first_token_id }));
            }
        }
        allSwapRoutes.push(swapRoutes);
    }

    // length 2 path
    {
        const swapRoutes: SwapPath[][] = [];
        for (let j = 0; j < allSwapRoutes[0].length; j++) {
            const oldSwapRoute = allSwapRoutes[0][j];
            for (let i = 0; i < pools.length; i++) {
                const pool = pools[i];
                if (pool.pool_state != StateEnum.Active) continue;

                if (pool.first_token_id == oldSwapRoute[oldSwapRoute.length - 1].tokenOut && pool.second_token_id != tokenIn) {
                    swapRoutes.push(oldSwapRoute.concat({ poolIndex: i, tokenOut: pool.second_token_id }));
                } else if (pool.second_token_id == oldSwapRoute[oldSwapRoute.length - 1].tokenOut && pool.first_token_id != tokenIn) {
                    swapRoutes.push(oldSwapRoute.concat({ poolIndex: i, tokenOut: pool.first_token_id }));
                }
            }
        }
        allSwapRoutes.push(swapRoutes);
    }

    // length 3 path
    {
        const swapRoutes: SwapPath[][] = [];
        for (let j = 0; j < allSwapRoutes[1].length; j++) {
            const oldSwapRoute = allSwapRoutes[1][j];
            for (let i = 0; i < pools.length; i++) {
                const pool = pools[i];
                if (pool.pool_state != StateEnum.Active) continue;
                
                if (pool.first_token_id == oldSwapRoute[oldSwapRoute.length - 1].tokenOut && pool.second_token_id != tokenIn) {
                    swapRoutes.push(oldSwapRoute.concat({ poolIndex: i, tokenOut: pool.second_token_id }));
                } else if (pool.second_token_id == oldSwapRoute[oldSwapRoute.length - 1].tokenOut && pool.first_token_id != tokenIn) {
                    swapRoutes.push(oldSwapRoute.concat({ poolIndex: i, tokenOut: pool.first_token_id }));
                }
            }
        }
        allSwapRoutes.push(swapRoutes);
    }

    for (const swapRoutes of allSwapRoutes) {
        for (const swapRoute of swapRoutes) {
            if (swapRoute[swapRoute.length - 1].tokenOut == tokenOut) {
                for (const swapPath of swapRoute) {
                    poolIndexes.push(swapPath.poolIndex);
                    pairAddresses.push(pools[swapPath.poolIndex].pool_address);
                    tokenOuts.push(swapPath.tokenOut);
                }

                return {
                    poolIndexes,
                    pairAddresses,
                    tokenOuts,
                };
            }
        }
    }

    // found no route
    return undefined;
}

export function applySlippage(amount: BigNumber.Value | null, slippage: number): BigNumber {
    return parseBigNumber(amount)
        .multipliedBy(100 + slippage)
        .div(100);
}