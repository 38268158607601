import { useGetEgldPrice } from '@multiversx/sdk-dapp/hooks';
import {
    AURYN_TOKEN_ID,
    BVST_TOKEN_ID,
    EAURYN_TOKEN_ID,
    ESTAR_TOKEN_ID,
    FVST_TOKEN_ID,
    OURO_TOKEN_ID,
    RAWVST_TOKEN_ID,
    SLIP_TOKEN_ID,
    SVST_TOKEN_ID,
    USDC_TOKEN_ID,
    USDD_SLIP_TOKEN_ID,
    USDD_TOKEN_ID,
    VAURYN_TOKEN_ID,
    VEAURYN_TOKEN_ID,
    VEGLD_TOKEN_ID,
    VOURO_TOKEN_ID,
    VST_TOKEN_ID,
    WEGLD_TOKEN_ID,
} from 'config';
import { selectNetstats, selectSwap } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { StateEnum } from 'z/types';
import { convertWeiToEsdt } from 'z/utils';

function removeDuplicates(arr: any[]) {
    return arr.filter((item, index) => arr.indexOf(item) === index);
}

export const useVestaCommonHooks = () => {
    const netstatsRedux = useAppSelector(selectNetstats);
    const swapRedux = useAppSelector(selectSwap);
    const { price: egldPrice } = useGetEgldPrice();

    function getTokenPrice(tokenIdParam: string, ...aurinIndex: number[]): number {
        let tokenId = tokenIdParam;
        if (
            tokenId == RAWVST_TOKEN_ID ||
            tokenId == SVST_TOKEN_ID ||
            tokenId == FVST_TOKEN_ID ||
            tokenId == BVST_TOKEN_ID
        ) {
            tokenId = VST_TOKEN_ID;
        } else if (
            tokenId == AURYN_TOKEN_ID ||
            tokenId == EAURYN_TOKEN_ID ||
            tokenId == VOURO_TOKEN_ID ||
            tokenId == VAURYN_TOKEN_ID ||
            tokenId == VEAURYN_TOKEN_ID
        ) {
            tokenId = OURO_TOKEN_ID;
        }

        //
        if (
            tokenId == USDC_TOKEN_ID ||
            tokenId == SLIP_TOKEN_ID ||
            tokenId == USDD_TOKEN_ID ||
            tokenId == USDD_SLIP_TOKEN_ID
        ) {
            return 1;
        }

        const _egldPrice = egldPrice ?? 0;
        if (tokenId == 'EGLD' || tokenId == WEGLD_TOKEN_ID) {
            return _egldPrice;
        }

        const vegldPrice = convertWeiToEsdt(netstatsRedux.vegldInEgldPrice).multipliedBy(_egldPrice).toNumber();
        if (tokenId == VEGLD_TOKEN_ID) {
            return vegldPrice;
        }

        //
        if (swapRedux.pools) {
            /*
        first_tokens (base_tokens):
          VEGLD
          USDC = 1$
          OURO
          ESTAR
      */

            // compute OURO, estar price first
            let ouroPrice = 0;
            for (const pool of swapRedux.pools) {
                if (pool.first_token_id == USDC_TOKEN_ID && pool.second_token_id == OURO_TOKEN_ID) {
                    const firstTokenPrice = 1;
                    ouroPrice = convertWeiToEsdt(pool.first_token_reserve, pool.first_token_decimals)
                        .div(convertWeiToEsdt(pool.second_token_reserve, pool.second_token_decimals))
                        .multipliedBy(firstTokenPrice)
                        .toNumber();
                    if (tokenIdParam === OURO_TOKEN_ID) {
                        return ouroPrice;
                    } else if (tokenId === OURO_TOKEN_ID) {
                        return ouroPrice * aurinIndex[0];
                    }
                    break;
                }
            }

            const estarOuroPool = swapRedux.pools.find(
                (pool) => pool.first_token_id == OURO_TOKEN_ID && pool.second_token_id == ESTAR_TOKEN_ID,
            );
            const estarPrice = estarOuroPool
                ? convertWeiToEsdt(estarOuroPool.first_token_reserve, estarOuroPool.first_token_decimals)
                      .div(convertWeiToEsdt(estarOuroPool.second_token_reserve, estarOuroPool.second_token_decimals))
                      .multipliedBy(ouroPrice)
                      .toNumber()
                : 0;

            // compute other tokens' price
            for (const pool of swapRedux.pools) {
                if (
                    (tokenId == pool.second_token_id || tokenId == pool.lp_token_id) &&
                    pool.first_token_id !== USDD_TOKEN_ID
                ) {
                    let firstTokenPrice = 0;
                    if (pool.first_token_id == VEGLD_TOKEN_ID) {
                        firstTokenPrice = vegldPrice;
                    } else if (pool.first_token_id == USDC_TOKEN_ID || pool.first_token_id == USDD_TOKEN_ID) {
                        firstTokenPrice = 1;
                        if (
                            tokenIdParam == AURYN_TOKEN_ID ||
                            tokenIdParam == EAURYN_TOKEN_ID ||
                            tokenIdParam == VAURYN_TOKEN_ID ||
                            tokenIdParam == VEAURYN_TOKEN_ID
                        ) {
                            if (aurinIndex.length) {
                                firstTokenPrice = aurinIndex[0];
                            } else {
                                throw new Error('Invalid Auryn index');
                            }
                        }
                    } else if (pool.first_token_id == OURO_TOKEN_ID) {
                        firstTokenPrice = ouroPrice;
                    } else if (pool.first_token_id == ESTAR_TOKEN_ID) {
                        firstTokenPrice = estarPrice;
                    } else {
                        throw Error(`getTokenPrice: Bad first_token_id (${pool.first_token_id})`);
                    }

                    if (tokenId == pool.second_token_id) {
                        const secondTokenPrice = convertWeiToEsdt(pool.first_token_reserve, pool.first_token_decimals)
                            .div(convertWeiToEsdt(pool.second_token_reserve, pool.second_token_decimals))
                            .multipliedBy(firstTokenPrice)
                            .toNumber();
                        return secondTokenPrice;
                    } else {
                        const lpTokenPrice = convertWeiToEsdt(pool.first_token_reserve, pool.first_token_decimals)
                            .multipliedBy(firstTokenPrice)
                            .div(convertWeiToEsdt(pool.lp_token_supply, pool.lp_token_decimals))
                            .multipliedBy(2)
                            .toNumber();
                        return lpTokenPrice;
                    }
                }
            }
        }

        if (!swapRedux.pools) {
            console.error(`getTokenPrice: Token info not found (${tokenId})`);
        }
        return 0;
    }

    function getSwapAvailableTokenIds(): string[] {
        if (swapRedux.pools) {
            let tokenIds: string[] = [];
            for (const pool of swapRedux.pools) {
                if (pool.pool_state == StateEnum.Active) {
                    tokenIds.push(pool.first_token_id);
                    tokenIds.push(pool.second_token_id);
                }
            }
            tokenIds = removeDuplicates(tokenIds).sort();

            return tokenIds;
        }

        return [];
    }

    return {
        getTokenPrice,
        getSwapAvailableTokenIds,
    };
};
