import { SftCollectionConfigType } from 'z/types';
import { config } from './elrond';

export const NFT_STAKING_MAX_FEEABLE_EPOCH = 25;

// NFT Collections
export const BLOODSHED_COLLECTION = config.nfts.BLOODSHED.identifier;
export const BLOODSHED_LEGENDARY_NONCES = [1, 152];
export const BLOODSHED_EPIC_NONCES = [153, 554];
export const BLOODSHED_RARE_NONCES = [555, 1157];
export const BLOODSHED_COMMON_NONCES = [1158, 8861];

export const X_BUNNIES_COLLECTION = config.nfts.X_BUNNIES.identifier;

export const NOSFERATU_COLLECTION = config.nfts.NOSFERATU.identifier;

// SFT Collections
export const SNAKE_COLLECTION = config.sfts.SNAKE.identifier;
export const SNAKE_NONCE = 1;
export const SHARE_NONCE = 2;

export const CODING_DIVISION_COLLECTION = config.sfts.CODING_DIVISION.identifier;

export const VESTADAO_COLLECTION = config.sfts.VESTAXDAO.identifier;

export const VAULT_BOOSTER_COLLECTION = config.sfts.VAULT_BOOSTER.identifier;

export const MIDAS_OG_COLLECTION = config.sfts.MIDAS_OG.identifier;

const extractDefinedCollectionIdentifiers = (data: Record<string, { identifier: string }>): string[] =>
    Object.values(data)
        .filter(({ identifier }) => identifier)
        .map((token) => token.identifier);

export const WALLET_COLLECTION_IDS: string[] = extractDefinedCollectionIdentifiers({ ...config.sfts, ...config.nfts });

const transformSftsData = (data: Record<string, SftCollectionConfigType>): Record<string, SftCollectionConfigType> => {
    const transformedData: Record<string, SftCollectionConfigType> = {};

    Object.keys(data).forEach((key) => {
        transformedData[data[key].identifier] = data[key];
    });

    return transformedData;
};

export const SFT_INFO_MAP: Record<string, SftCollectionConfigType> = transformSftsData(config.sfts);
