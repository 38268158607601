import React, { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { GSC_TOKEN_ID } from 'config';
import { getTokenSupplyFromApi } from 'z/elrond';
import { useStableCoinProtocol } from 'z/hooks/stable-coin-protocol';

const MAX_COLLATERALIZATION_THRESHOLD = 90; // 90%

export const AdminStableCoinStatistics = () => {
    const [totalGscSupply, setTotalGscSupply] = useState('0');
    const { context } = useStableCoinProtocol();

    useEffect(() => {
        getTokenSupplyFromApi(GSC_TOKEN_ID).then((supply) => {
            setTotalGscSupply(supply?.supply ?? '0');
        });
    }, []);
    return (
        <table className="table text-white w-75 p-1" style={{ fontSize: '16px' }}>
            <thead>
                <tr>
                    <th colSpan={2} style={{ fontSize: '18px', color: '#f1dc46' }}>
                        Stable Coin Statistics
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>USDD Supply</td>
                    <td>{new BigNumber(context?.usdd_supply || 0).shiftedBy(-18).toString(10)} USDD</td>
                </tr>

                <tr>
                    <td>Circulating GSC Supply</td>
                    <td>{new BigNumber(totalGscSupply || 0).toString(10)} GSC</td>
                </tr>
                <tr>
                    <td>Locked GSC Supply (SGSC)</td>
                    <td>{new BigNumber(context?.sgsc_supply || 0).shiftedBy(-18).toString(10)} sGSC</td>
                </tr>
                <tr>
                    <td>Oracle Gold Price</td>
                    <td>{new BigNumber(context?.gsc_oracle_price || 0).shiftedBy(-8).toString(10)} USD</td>
                </tr>
                <tr>
                    <td>Total Collateral Value</td>
                    <td>{new BigNumber(context?.total_collateral_usd_value || 0).shiftedBy(-18).toString(10)} USD</td>
                </tr>
                <tr>
                    <td>Stable coin backing %</td>
                    <td>
                        {new BigNumber(context?.collateralization_ratio || '0')
                            .dividedBy(context?.percentage_denomination || '1')
                            .multipliedBy(100)
                            .toFixed(10)}{' '}
                        %
                    </td>
                </tr>
                <tr style={{ color: '#f1dc46', fontWeight: 'bold' }}>
                    <td>Stable coin mint %</td>
                    <td>
                        {new BigNumber(context?.percentage_denomination || '0')
                            .dividedBy(context?.collateralization_ratio || '1')
                            .multipliedBy(100)
                            .toFixed(10)}{' '}
                        %
                    </td>
                </tr>
                <tr>
                    <td>USDD supply % vs max threshold (90%)</td>
                    <td>
                        {new BigNumber(MAX_COLLATERALIZATION_THRESHOLD)
                            .minus(
                                new BigNumber(context?.percentage_denomination || '0')
                                    .dividedBy(context?.collateralization_ratio || '1')
                                    .multipliedBy(100),
                            )
                            .toString(10)}{' '}
                        %
                    </td>
                </tr>
                <tr style={{ color: '#f1dc46', fontWeight: 'bold' }}>
                    <td>MAX USDD Mint amount in admin mode</td>
                    <td>
                        {new BigNumber(MAX_COLLATERALIZATION_THRESHOLD)
                            .dividedBy(100)
                            .minus(
                                new BigNumber(context?.percentage_denomination || '0').dividedBy(
                                    context?.collateralization_ratio || '1',
                                ),
                            )
                            .multipliedBy(new BigNumber(context?.total_collateral_usd_value || 0).shiftedBy(-18))
                            .toString(10)}{' '}
                        USDD
                    </td>
                </tr>
            </tbody>
        </table>
    );
};
