import React, { useEffect, useState } from 'react';
import { TokenTransfer } from '@multiversx/sdk-core/out';
import {
  useGetAccountInfo,
  useGetPendingTransactions,
} from '@multiversx/sdk-dapp/hooks';
import { Dropdown } from 'react-bootstrap';
import Modal from 'react-modal';
import { farmUnstake } from 'z/elrond/farm';
import {
  FarmType,
  NonFungibleTokenBalanceType,
  StakedLpTokenType,
} from 'z/types';
import {
  convertEsdtToWei,
  convertWeiToEsdt,
  DEFAULT_DECIMALS,
  ERROR_CONNECT_WALLET,
  ERROR_INVALID_NUMBER,
  ERROR_NOT_ENOUGH_BALANCE,
  formatNumber,
  isPositiveOrZeroBigNumber,
  ZERO_STRING,
} from 'z/utils';

const customStyles = {
  content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
  },
  backgroundColor: '#1B0921 !important',
};

const LATENCIES = [0, 3, 63, 210];

export const RemoveFromStakeModal = ({
  isModalOpen,
  onCloseModal,
  // farm,
  farmAddress,
  // maxLpAmount,
  // lpTokenId,
  exoticLps,
}: {
  isModalOpen: boolean;
  onCloseModal: () => void;
  farm: FarmType;
  farmAddress: string;
  maxLpAmount: string;
  lpTokenId: string;
  exoticLps: NonFungibleTokenBalanceType[];
}) => {
  const { address } = useGetAccountInfo();
  const { hasPendingTransactions } = useGetPendingTransactions();
  
  const [availableLps, ] = useState<
    NonFungibleTokenBalanceType[]
  >(
    exoticLps
      .filter(
        (lp) =>
          lp.stake_token_type !== undefined &&
          [1,2,3].includes(lp.stake_token_type) &&
          lp.balance !== '0',
      )
      .filter((lp) => lp.balance !== '0'),
  );

  const [selectedAmount, setSelectedAmount] = useState(ZERO_STRING);
  const [selectedAmountError, setSelectedAmountError] = useState('');
  const [selectedBatch, setSelectedBatch] = useState<LPInfo>();

  useEffect(() => {
    if (!hasPendingTransactions) {
      setSelectedAmount(ZERO_STRING);
      setSelectedAmountError('');
    }
  }, [hasPendingTransactions]);

  function onChangeSelectedAmount(value: string) {
    let error = '';
    if (!address) {
      error = ERROR_CONNECT_WALLET;
    } else if (!isPositiveOrZeroBigNumber(value)) {
      error = ERROR_INVALID_NUMBER;
    } else if (!selectedBatch) {
      error = 'No Batch selected';
    } else if (convertEsdtToWei(value).comparedTo(selectedBatch.maxAmount) > 0) {
      error = ERROR_NOT_ENOUGH_BALANCE;
    }
    
    setSelectedAmountError(error);
    setSelectedAmount(value);
    
    return error;
  }

  function onMaxSelectedAmount() {
    if (address && selectedBatch) {
      onChangeSelectedAmount(convertWeiToEsdt(selectedBatch.maxAmount, DEFAULT_DECIMALS, DEFAULT_DECIMALS).toFixed());
    }
  }

  const handleUnstakeLps = async () => {
    if (!selectedBatch) {
      return;
    }
    await farmUnstake(
      farmAddress,
      [TokenTransfer.metaEsdtFromBigInteger(selectedBatch.tokenId, selectedBatch.nonce, convertEsdtToWei(selectedAmount))],
      address,
    );
    closeModal();
  };

  const handleSelectBatch = (alp: NonFungibleTokenBalanceType) => {
    setSelectedAmount(ZERO_STRING);
    setSelectedAmountError('');

    const name =
      alp.stake_token_type === StakedLpTokenType.Bronze
        ? 'Bronze LP'
        : alp.stake_token_type === StakedLpTokenType.Silver
          ? 'Silver LP'
          : 'Gold LP';
    
    setSelectedBatch({
      name,
      tokenId: alp.collection,
      nonce: alp.nonce,
      maxAmount: alp.balance,
      stake_token_type: alp.stake_token_type ?? StakedLpTokenType.Bronze,
    });
  };

  function closeModal() {
    onCloseModal();
  }

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      <div className="select-token-modal px-4" style={{ color: 'white' }}>
        <div className='text-center mt-4 mb-4' style={{
            fontSize: '1.8rem',
            fontWeight: 300,
            color: '#F1DC46',
        }}>Withdraw LPs</div>
        <div style={{ minHeight: '300px', minWidth: '400px' }}>
          <div className='ms-2'>LPs available for unstaking</div>

          <Dropdown className='farm-unstake-lp-dropdown mt-2'>
            <Dropdown.Toggle
              // className="w-100 text-white btn btn-secondary"
              // style={{ backgroundColor: '#1B0028' }}
            >
              {selectedBatch
                ? `${selectedBatch.name}`
                : 'Select LP'}
            </Dropdown.Toggle>

            <Dropdown.Menu className="w-100 mt-2">
              {availableLps.map((alp, i) => (
                <Dropdown.Item
                  key={`available-lp-${i}`}
                  className="d-flex justify-content-between"
                  onClick={() =>handleSelectBatch(alp)}
                >
                  <div>{alp.ticker}</div>
                  <div>{convertWeiToEsdt(alp.balance).toLocaleString()}</div>
                </Dropdown.Item>
              ))}
              {}
            </Dropdown.Menu>
          </Dropdown>

          {selectedBatch && (
            <>
              <div className="vesta_x_swap_input_box mt-4">
              <div className="d-flex align-items-center">
                <input
                  className="swap_input"
                  type="number"
                  value={selectedAmount}
                  onChange={(e) => onChangeSelectedAmount(e.target.value)}
                />
                <button className="add-liquidity-selected-token-buttton">
                  {/* <img src={getTokenLogo(AURYN_TOKEN_ID)} alt="logo" width="30px" /> */}
                  {selectedBatch.name}
                </button>
              </div>
              <div className="d-flex justify-content-between mt-1">
                <div className="input-token-error">{selectedAmountError}</div>
                <div
                  className="add-liquidity-input-token-balance-box"
                  onClick={onMaxSelectedAmount}
                >
                  <div className="">Balance:&nbsp;</div>
                  <div style={{ color: 'white' }}>
                    {formatNumber(convertWeiToEsdt(selectedBatch.maxAmount, DEFAULT_DECIMALS, DEFAULT_DECIMALS), DEFAULT_DECIMALS)}
                  </div>
                </div>
              </div>
            </div>

            <div className='mt-2 ms-2'>Latency: {`${LATENCIES[selectedBatch.stake_token_type]} days`}</div>
            </>
          )}
        </div>

        <div className='d-flex justify-content-between w-100'>
          <button
            className="farm_card_individual_element_but text-center"
            disabled={hasPendingTransactions}
            onClick={handleUnstakeLps}
          >
            Unstake
          </button>
          <button
            className="farm_card_individual_element_but text-center"
            onClick={closeModal}
          >
            Dismiss
          </button>
        </div>
      </div>
    </Modal>
  );
};

interface LPInfo {
  name: string;
  tokenId: string;
  nonce: number;
  maxAmount: string;
  stake_token_type: StakedLpTokenType,
}
