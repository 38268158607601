import React from 'react';

import { useGetAccount, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import { selectNftStakingV2 } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { getTokenDecimals, getTokenLogo, getTokenName, nftStakingClaimRewards } from 'z/elrond';
import { useNftStakingHooks } from 'z/hooks/nft-staking';
import { ERROR_CONNECT_WALLET, ERROR_TRANSACTION_ONGOING, convertWeiToEsdt, formatNumber, toastError } from 'z/utils';
import { stakingPools, getStakingPoolUrls } from './const';
import StakingRowInfo from './StakingRowInfo';

const NftStakingV2 = () => {
    useNftStakingHooks();

    return (
        <div className="container" style={{ marginTop: '50px', color: '#98a1c0' }}>
            <div className="d-flex justify-content-center">
                <EarnedRewards />
            </div>
            <div className="d-flex justify-content-center">
                <div className="vesta-first-container mt-4" style={{ maxWidth: '1024px' }}>
                    <div className="text-center mb-3" style={{ fontSize: '18px', color: '#f1dc46' }}>
                        Staking Pools
                    </div>
                    <div className="d-flex flex-column" style={{ rowGap: '16px' }}>
                        {stakingPools.map((row, id) => (
                            <StakingRowInfo key={`staking-row-info-${id}`} data={row} url={getStakingPoolUrls()[id]} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

const EarnedRewards = () => {
    const nftStakingRedux = useAppSelector(selectNftStakingV2);
    const { address } = useGetAccount();
    const { hasPendingTransactions } = useGetPendingTransactions();

    const onClaimRewards = async (): Promise<void> => {
        const rewards = nftStakingRedux.rewards?.tokens?.map((el) => ({
            token_identifier: el.tokenId,
            token_nonce: 0,
            amount: el.amount,
        }));

        if (!address) {
            return toastError(ERROR_CONNECT_WALLET);
        } else if (hasPendingTransactions) {
            return toastError(ERROR_TRANSACTION_ONGOING);
        } else if (!rewards) {
            return toastError('No pending rewards');
        }

        await nftStakingClaimRewards(rewards, address);
    };

    return (
        <>
            <div className="active-pool-li-container" style={{ minWidth: '393px', padding: '12px 48px' }}>
                <div className="text-center mb-2" style={{ fontSize: '18px', color: '#f1dc46' }}>
                    Earned rewards
                </div>
                {nftStakingRedux.rewards && nftStakingRedux.rewards.tokens?.length ? (
                    <>
                        {nftStakingRedux.rewards.tokens.map(({ tokenId, amount }, id) => (
                            <div className="d-flex justify-content-between align-items-center" key={id}>
                                <span>{getTokenName(tokenId)}</span>
                                <div className="d-flex align-items-center">
                                    <span>{formatNumber(convertWeiToEsdt(amount, getTokenDecimals(tokenId)), 3)}</span>
                                    <img className="ms-1" src={getTokenLogo(tokenId)} alt="logo" width="30px" />
                                </div>
                            </div>
                        ))}
                        <div className="d-flex justify-content-center mt-2">
                            <button className="farm_but py-2" style={{ width: '8rem' }} onClick={onClaimRewards}>
                                Claim
                            </button>
                        </div>
                    </>
                ) : (
                    <div>You have no pending rewards to claim</div>
                )}
            </div>
        </>
    );
};

export default NftStakingV2;
