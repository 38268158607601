import React, { useEffect, useState } from 'react';
import { useGetAccount } from '@multiversx/sdk-dapp/hooks';
import clsx from 'clsx';
import { Row } from 'react-bootstrap';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import {
    AUXILIARY_VESTA_VAULT_SC_ADDRESS,
    CRYPTO_VESTA_VAULT_SC_ADDRESS,
    GSC_TOKEN_ID,
    OURO_TOKEN_ID,
    RAWVST_TOKEN_ID,
    SSC_TOKEN_ID,
    USDC_TOKEN_ID,
    VEGLD_TOKEN_ID,
    CAPITAL_VESTA_VAULT_SC_ADDRESS,
    WBTC_TOKEN_ID,
    WETH_TOKEN_ID,
    InjectRewardType,
    LIQUIDITY_VESTA_VALULT_SC_ADDRESS,
    MEME_LIQUIDITY_VESTA_VALULT_SC_ADDRESS,
    ESTAR_TOKEN_ID,
    USDT_TOKEN_ID,
    USDD_TOKEN_ID,
} from 'config';
import { CreatePoolSelectToken } from 'pages/CreatePool/CreatePoolSelectToken';
import { routeNames } from 'routes';
import { VaultContract, memeLiquidityVaultViewBaseContext, vaultViewBaseContext } from 'z/elrond';
import { VaultBaseContextType } from 'z/types';
import { ERROR_CONNECT_WALLET, toastError } from 'z/utils';
import { VaultAdminCard } from './VaultAdminCard';

const CAPITAL_REWARD_TOKEN_IDS = [[RAWVST_TOKEN_ID], [VEGLD_TOKEN_ID, USDC_TOKEN_ID, OURO_TOKEN_ID, ESTAR_TOKEN_ID, USDT_TOKEN_ID, USDD_TOKEN_ID], [GSC_TOKEN_ID]];

const AUXILIARY_REWARD_TOKEN_IDS = [[RAWVST_TOKEN_ID], [VEGLD_TOKEN_ID, USDC_TOKEN_ID, OURO_TOKEN_ID, ESTAR_TOKEN_ID, USDT_TOKEN_ID, USDD_TOKEN_ID], [SSC_TOKEN_ID]];

const CRYPTO_REWARD_TOKEN_IDS = [[RAWVST_TOKEN_ID], [WETH_TOKEN_ID], [WBTC_TOKEN_ID]];

const OURO_LIQUIDITY_REWARD_TOKEN_IDS = [[RAWVST_TOKEN_ID]];

const tabs = ['Inject Rewards', 'Add Tokens'];

export const VaultAdmin = () => {
    const navigate = useNavigate();
    const { address } = useGetAccount();

    const [memeStandardRewardsTokenIds, setMemeStandardRewardsTokenIds] = useState<string[]>([RAWVST_TOKEN_ID]);
    useEffect(() => {
        (async () => {
            const _vaultBaseContext = await memeLiquidityVaultViewBaseContext();

            setMemeStandardRewardsTokenIds([
                RAWVST_TOKEN_ID,
                ...(_vaultBaseContext?.auxiliary_standard_reward_token_ids || []),
            ]);
        })();
    }, []);

    const [tabIndex, setTabIndex] = useState<number>(0);

    const [tokenId, setTokenId] = useState<string>('');
    function onChangeTokenId(value: string) {
        setTokenId(value);
    }

    const [capitalVault, setCaptivalVault] = useState<VaultBaseContextType>();
    useEffect(() => {
        (async () => {
            const _vaultBaseContext = await vaultViewBaseContext(CAPITAL_VESTA_VAULT_SC_ADDRESS);
            setCaptivalVault(_vaultBaseContext);
        })();
    }, []);

    const [auxiliaryVault, setAuxiliaryVault] = useState<VaultBaseContextType>();
    useEffect(() => {
        (async () => {
            const _vaultBaseContext = await vaultViewBaseContext(AUXILIARY_VESTA_VAULT_SC_ADDRESS);
            setAuxiliaryVault(_vaultBaseContext);
        })();
    }, []);

    async function onAddPremiumRewardToken(
        scAddress: string,
        vaultBaseContext: any,
        _tokenId: string,
    ) {
        if (!address) {
            toastError(ERROR_CONNECT_WALLET);
            return;
        }
        if (!vaultBaseContext) {
            toastError('Vault data is not loaded.');
            return;
        }
        if (vaultBaseContext.premium_reward_token_ids.indexOf(tokenId) >= 0) {
            toastError('Same token already exists.');
            return;
        }
        
        await new VaultContract(address, scAddress)
            .addPremiumRewardTokenIds(_tokenId);
    }

    return (
        <>
            <div className="container" style={{ marginTop: '50px' }}>
                <div className="d-flex justify-content-center mt-4">
                    <div className="vesta_x_dashboard_card" style={{ minHeight: '40rem' }}>
                        <div className="d-flex justify-content-start">
                            <button
                                className="go-back-button"
                                onClick={() => {
                                    navigate(`${routeNames.vaults}`);
                                }}
                            >
                                <BiLeftArrowAlt />
                            </button>
                        </div>

                        <div className="d-flex justify-content-center mb-5">
                            <div className="tab-box">
                                <div
                                    className={clsx(
                                        'tab-focus',
                                        tabIndex === 0
                                            ? 'tab-focus-left-0'
                                            : 'tab-focus-left-50',
                                    )}
                                />
                                {tabs.map((row, index) => {
                                    return (
                                        <div
                                            className="tab"
                                            key={index}
                                            onClick={() => setTabIndex(index)}
                                        >
                                            {row}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        {
                            tabIndex == 0 && <>
                                <div className="d-flex justify-content-center align-items-center">
                                    <span style={{ color: '#F1DC46', fontSize: '1.2rem' }}>Capital Vesta.Vault™</span>
                                </div>
                                <Row className="mt-4 p-0">
                                    <div className="col-12 col-lg-4 mb-3">
                                        <VaultAdminCard
                                            vaultAddress={CAPITAL_VESTA_VAULT_SC_ADDRESS}
                                            rewardTokenIds={CAPITAL_REWARD_TOKEN_IDS[0]}
                                            injectRewardType={InjectRewardType.StandardReward}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-4 mb-3">
                                        <VaultAdminCard
                                            vaultAddress={CAPITAL_VESTA_VAULT_SC_ADDRESS}
                                            rewardTokenIds={CAPITAL_REWARD_TOKEN_IDS[1]}
                                            injectRewardType={InjectRewardType.PremiumReward}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-4 mb-3">
                                        <VaultAdminCard
                                            vaultAddress={CAPITAL_VESTA_VAULT_SC_ADDRESS}
                                            rewardTokenIds={CAPITAL_REWARD_TOKEN_IDS[2]}
                                            injectRewardType={InjectRewardType.EliteReward}
                                        />
                                    </div>
                                </Row>

                                <div className="d-flex justify-content-center align-items-center mt-4">
                                    <span style={{ color: '#F1DC46', fontSize: '1.2rem' }}>Auxiliary Vesta.Vault™</span>
                                </div>
                                <Row className="mt-4 p-0">
                                    <div className="col-12 col-lg-4 mb-3">
                                        <VaultAdminCard
                                            vaultAddress={AUXILIARY_VESTA_VAULT_SC_ADDRESS}
                                            rewardTokenIds={AUXILIARY_REWARD_TOKEN_IDS[0]}
                                            injectRewardType={InjectRewardType.StandardReward}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-4 mb-3">
                                        <VaultAdminCard
                                            vaultAddress={AUXILIARY_VESTA_VAULT_SC_ADDRESS}
                                            rewardTokenIds={AUXILIARY_REWARD_TOKEN_IDS[1]}
                                            injectRewardType={InjectRewardType.PremiumReward}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-4 mb-3">
                                        <VaultAdminCard
                                            vaultAddress={AUXILIARY_VESTA_VAULT_SC_ADDRESS}
                                            rewardTokenIds={AUXILIARY_REWARD_TOKEN_IDS[2]}
                                            injectRewardType={InjectRewardType.EliteReward}
                                        />
                                    </div>
                                </Row>

                                <div className="d-flex justify-content-center align-items-center mt-4">
                                    <span style={{ color: '#F1DC46', fontSize: '1.2rem' }}>Crypto Vesta.Vault™</span>
                                </div>
                                <Row className="mt-4 p-0">
                                    <div className="col-12 col-lg-4 mb-3">
                                        <VaultAdminCard
                                            vaultAddress={CRYPTO_VESTA_VAULT_SC_ADDRESS}
                                            rewardTokenIds={CRYPTO_REWARD_TOKEN_IDS[0]}
                                            injectRewardType={InjectRewardType.StandardReward}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-4 mb-3">
                                        <VaultAdminCard
                                            vaultAddress={CRYPTO_VESTA_VAULT_SC_ADDRESS}
                                            rewardTokenIds={CRYPTO_REWARD_TOKEN_IDS[1]}
                                            injectRewardType={InjectRewardType.PremiumReward}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-4 mb-3">
                                        <VaultAdminCard
                                            vaultAddress={CRYPTO_VESTA_VAULT_SC_ADDRESS}
                                            rewardTokenIds={CRYPTO_REWARD_TOKEN_IDS[2]}
                                            injectRewardType={InjectRewardType.EliteReward}
                                        />
                                    </div>
                                </Row>

                                <div className="d-flex justify-content-center align-items-center mt-4">
                                    <span style={{ color: '#F1DC46', fontSize: '1.2rem' }}>Liquidity Vesta.Vault™</span>
                                </div>
                                <Row className="mt-4 p-0">
                                    <div className="col-12 col-lg-4 mb-3">
                                        <VaultAdminCard
                                            vaultAddress={LIQUIDITY_VESTA_VALULT_SC_ADDRESS}
                                            rewardTokenIds={OURO_LIQUIDITY_REWARD_TOKEN_IDS[0]}
                                            injectRewardType={InjectRewardType.StandardReward}
                                        />
                                    </div>
                                    {/* <div className="col-12 col-lg-4 mb-3">
                                    <VaultAdminCard
                                        vaultAddress={LIQUIDITY_VESTA_VALULT_SC_ADDRESS}
                                        rewardTokenIds={CRYPTO_REWARD_TOKEN_IDS[1]}
                                        injectRewardType={InjectRewardType.PremiumReward}
                                    />
                                </div>
                                <div className="col-12 col-lg-4 mb-3">
                                    <VaultAdminCard
                                        vaultAddress={LIQUIDITY_VESTA_VALULT_SC_ADDRESS}
                                        rewardTokenIds={CRYPTO_REWARD_TOKEN_IDS[2]}
                                        injectRewardType={InjectRewardType.EliteReward}
                                    />
                                </div> */}
                                </Row>

                                <div className="d-flex justify-content-center align-items-center mt-4">
                                    <span style={{ color: '#F1DC46', fontSize: '1.2rem' }}>Meme Liquidity Vesta.Vault™</span>
                                </div>
                                <Row className="mt-4 p-0">
                                    <div className="col-12 col-lg-4 mb-3">
                                        <VaultAdminCard
                                            vaultAddress={MEME_LIQUIDITY_VESTA_VALULT_SC_ADDRESS}
                                            rewardTokenIds={memeStandardRewardsTokenIds}
                                            injectRewardType={InjectRewardType.StandardReward}
                                            gasLimit={100_000_000}
                                        />
                                    </div>
                                    {/* <div className="col-12 col-lg-4 mb-3">
                                    <VaultAdminCard
                                        vaultAddress={LIQUIDITY_VESTA_VALULT_SC_ADDRESS}
                                        rewardTokenIds={CRYPTO_REWARD_TOKEN_IDS[1]}
                                        injectRewardType={InjectRewardType.PremiumReward}
                                    />
                                </div>
                                <div className="col-12 col-lg-4 mb-3">
                                    <VaultAdminCard
                                        vaultAddress={LIQUIDITY_VESTA_VALULT_SC_ADDRESS}
                                        rewardTokenIds={CRYPTO_REWARD_TOKEN_IDS[2]}
                                        injectRewardType={InjectRewardType.EliteReward}
                                    />
                                </div> */}
                                </Row>
                            </>
                        }
                        {
                            tabIndex == 1 && <>
                                <div className="d-flex justify-content-center align-items-center">
                                    <span style={{ color: '#F1DC46', fontSize: '1.2rem' }}>Capital Vesta.Vault™</span>
                                </div>
                                <Row className="mt-4 p-0">
                                    <div className="col-12 col-lg-4 mb-3">
                                        <div className='d-flex flex-column align-items-center'>
                                            <div className="mt-4 mx-auto text-center" style={{ color: '#F1DC46', fontSize: '1rem' }}>
                                                Current Premium Reward Tokens
                                            </div>
                                            {
                                                capitalVault && capitalVault.premium_reward_token_ids.map((row, index) => <div key={index} className='text-md my-1'>{row}</div>)
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 mb-3">
                                        <div className="">
                                            <div className="mt-4 mx-auto text-center" style={{ color: 'white', fontSize: '1rem' }}>
                                                New Premium Reward Token
                                            </div>
                                            <div className="vesta_x_swap_input_box mt-1">
                                                <CreatePoolSelectToken
                                                    tokenId={tokenId}
                                                    onChangeTokenId={onChangeTokenId}
                                                />
                                            </div>
                                            <button className="mt-3 mx-auto assets-button" onClick={() => onAddPremiumRewardToken(CAPITAL_VESTA_VAULT_SC_ADDRESS, capitalVault, tokenId)}>
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </Row>

                                <div className="d-flex justify-content-center align-items-center">
                                    <span style={{ color: '#F1DC46', fontSize: '1.2rem' }}>Auxiliary Vesta.Vault™</span>
                                </div>
                                <Row className="mt-4 p-0">
                                    <div className="col-12 col-lg-4 mb-3">
                                        <div className='d-flex flex-column align-items-center'>
                                            <div className="mt-4 mx-auto text-center" style={{ color: '#F1DC46', fontSize: '1rem' }}>
                                                Current Premium Reward Tokens
                                            </div>
                                            {
                                                auxiliaryVault && auxiliaryVault.premium_reward_token_ids.map((row, index) => <div key={index} className='text-md my-1'>{row}</div>)
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 mb-3">
                                        <div className="">
                                            <div className="mt-4 mx-auto text-center" style={{ color: 'white', fontSize: '1rem' }}>
                                                New Premium Reward Token
                                            </div>
                                            <div className="vesta_x_swap_input_box mt-1">
                                                <CreatePoolSelectToken
                                                    tokenId={tokenId}
                                                    onChangeTokenId={onChangeTokenId}
                                                />
                                            </div>
                                            <button className="mt-3 mx-auto assets-button" onClick={() => onAddPremiumRewardToken(AUXILIARY_VESTA_VAULT_SC_ADDRESS, auxiliaryVault, tokenId)}>
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </Row>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};
