import React, { useEffect, useState } from 'react';
import { useGetAccountInfo, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';
import { forEach } from 'lodash';
import { Col, Row } from 'react-bootstrap';

import ouro_logo from 'assets/img/vault/01_Ouroboros_200x200.svg';
import auryn_logo from 'assets/img/vault/02_Auryn_200x200.svg';
import elite_auryn_logo from 'assets/img/vault/03_Elite-Auryn_200x200.svg';

import { AURYN_TOKEN_ID, EAURYN_TOKEN_ID, OURO_TOKEN_ID } from 'config';
import { selectNetstats } from 'redux/reducers';
import { selectOuroLiquidityVault } from 'redux/reducers/ouro-liquidity-vault';
import { useAppSelector } from 'redux/store';
import { liquidityVaultClaimReward, snakeCoilViewBaseContext } from 'z/elrond';
import { useVestaCommonHooks } from 'z/hooks';
import { SnakeCoilBaseContext } from 'z/types';
import {
    BIG_NUMBER_ZERO,
    DEFAULT_DECIMALS,
    ERROR_CONNECT_WALLET,
    ERROR_TRANSACTION_ONGOING,
    convertToDollarString,
    convertWeiToEsdt,
    formatNumber,
    parseBigNumber,
    printWalletBalanceAndPrice,
    toastError,
} from 'z/utils';

export const LiquidityVestaVaultEliteRewards = () => {
    const { address } = useGetAccountInfo();
    const { hasPendingTransactions } = useGetPendingTransactions();

    const netstatsRedux = useAppSelector(selectNetstats);
    const ouroLiquidityVaultRedux = useAppSelector(selectOuroLiquidityVault);

    const [ouroRewardAmount, setOuroRewardAmount] = useState<string>('0');
    const [aurynRewardAmount, setAurynRewardAmount] = useState<string>('0');
    const [eaurynRewardAmount, setEaurynRewardAmount] = useState<string>('0');

    const [majorPower, setMajorPower] = useState(BIG_NUMBER_ZERO);
    const [totalMajorPower, setTotalMajorPower] = useState(BIG_NUMBER_ZERO);
    const [minorPower, setMinorPower] = useState(BIG_NUMBER_ZERO);
    const [totalMinorPower, setTotalMinorPower] = useState(BIG_NUMBER_ZERO);

    const { getTokenPrice } = useVestaCommonHooks();

    async function onClaimRewards() {
        const rewards = ouroLiquidityVaultRedux.rewards?.tokens?.map((el) => ({
            token_identifier: el.tokenId,
            token_nonce: 0,
            amount: el.amount,
        }));

        if (!address) {
            return toastError(ERROR_CONNECT_WALLET);
        } else if (hasPendingTransactions) {
            return toastError(ERROR_TRANSACTION_ONGOING);
        } else if (!rewards) {
            return toastError('No pending rewards');
        }

        await liquidityVaultClaimReward(rewards, address);
    }

    const [aurynIndex, setAurynIndex] = useState(parseBigNumber(0));
    const [snakeCoilBaseContext, setSnakeCoilBaseContext] = useState<SnakeCoilBaseContext>();
    useEffect(() => {
        (async () => {
            const _snakeCoilBaseContext = await snakeCoilViewBaseContext();
            setSnakeCoilBaseContext(_snakeCoilBaseContext);
        })();
    }, []);

    useEffect(() => {
        if (snakeCoilBaseContext) {
            setAurynIndex(
                parseBigNumber(snakeCoilBaseContext.token_supplies[0]).div(snakeCoilBaseContext.token_supplies[1]),
            );
        }
    }, [snakeCoilBaseContext]);

    useEffect(() => {
        if (ouroLiquidityVaultRedux.rewards) {
            forEach(ouroLiquidityVaultRedux.rewards.tokens, ({ tokenId, amount }) => {
                switch (tokenId) {
                    case OURO_TOKEN_ID:
                        setOuroRewardAmount(amount);
                        break;
                    case AURYN_TOKEN_ID:
                        setAurynRewardAmount(amount);
                        break;
                    case EAURYN_TOKEN_ID:
                        setEaurynRewardAmount(amount);
                        break;
                }
            });
        }
    }, [ouroLiquidityVaultRedux.rewards]);

    useEffect(() => {
        if (ouroLiquidityVaultRedux.totalElitePowers) {
            setTotalMajorPower(
                BigNumber.sum(
                    ouroLiquidityVaultRedux.totalElitePowers.totalMajorGoldenPower,
                    ouroLiquidityVaultRedux.totalElitePowers.totalMajorSilverPower,
                    ouroLiquidityVaultRedux.totalElitePowers.totalMajorBronzePower,
                ),
            );
            setTotalMinorPower(
                BigNumber.sum(
                    ouroLiquidityVaultRedux.totalElitePowers.totalMinorGoldenPower,
                    ouroLiquidityVaultRedux.totalElitePowers.totalMinorSilverPower,
                    ouroLiquidityVaultRedux.totalElitePowers.totalMinorBronzePower,
                ),
            );
        }
    }, [ouroLiquidityVaultRedux.totalElitePowers]);

    useEffect(() => {
        if (ouroLiquidityVaultRedux.userElitePowers) {
            setMajorPower(
                BigNumber.sum(
                    ouroLiquidityVaultRedux.userElitePowers.majorGoldenPower,
                    ouroLiquidityVaultRedux.userElitePowers.majorSilverPower,
                    ouroLiquidityVaultRedux.userElitePowers.majorBronzePower,
                ),
            );
            setMinorPower(
                BigNumber.sum(
                    ouroLiquidityVaultRedux.userElitePowers.minorGoldenPower,
                    ouroLiquidityVaultRedux.userElitePowers.minorSilverPower,
                    ouroLiquidityVaultRedux.userElitePowers.minorBronzePower,
                ),
            );
        }
    }, [ouroLiquidityVaultRedux.userElitePowers]);

    return (
        <Row className="mt-4 p-0">
            <Col>
                <Row
                    className="m-0 py-3 px-1 g-2"
                    style={{
                        border: '1px solid #efe3af',
                        borderRadius: '10px',
                    }}
                >
                    <div className="col-12 text-center" style={{ color: '#efe3af' }}>
                        {`Snake Tokens (OURO, AURYN, Elite-AURYN) Rewards are governed by 2 Elite Snake Powers designated MAJOR and MINOR.`}
                    </div>
                    <div className="col-12 text-center mb-3" style={{ color: '#efe3af' }}>
                        {`Major/Minor Elite Snake Power receives 16%/6% of OURO Pie Distribution. Your share of Major/Minor Elite Snake Power is ${formatNumber(
                            majorPower.div(totalMajorPower).multipliedBy(1_000),
                            3,
                        )}‰/${formatNumber(minorPower.div(totalMinorPower).multipliedBy(1_000), 3)}‰`}
                    </div>
                    <Col md={6} lg={4} className="d-flex flex-column px-1">
                        <div
                            className="second-card-style mb-0 d-flex flex-column align-items-between gap-2 px-3"
                            style={{ backgroundColor: '#1B0921' }}
                        >
                            <div className="d-flex align-items-center">
                                <img src={ouro_logo} style={{ height: '3rem', width: 'auto' }} />
                                <div className="ms-2">
                                    {printWalletBalanceAndPrice(netstatsRedux, OURO_TOKEN_ID, getTokenPrice)}
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <img src={auryn_logo} style={{ height: '3rem', width: 'auto' }} />
                                <div className="ms-2">
                                    {printWalletBalanceAndPrice(netstatsRedux, AURYN_TOKEN_ID, (token_id: string) =>
                                        getTokenPrice(token_id, aurynIndex.toNumber()),
                                    )}
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <img src={elite_auryn_logo} style={{ height: '3rem', width: 'auto' }} />
                                <div className="ms-2">
                                    {printWalletBalanceAndPrice(netstatsRedux, EAURYN_TOKEN_ID, (token_id: string) =>
                                        getTokenPrice(token_id, aurynIndex.toNumber()),
                                    )}
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} lg={8} className="d-flex flex-column px-1" style={{ fontSize: '1rem' }}>
                        <div
                            className="second-card-style mb-0 d-flex justify-content-between align-items-center px-3 h-100"
                            style={{ backgroundColor: '#1B0921' }}
                        >
                            <div className="d-flex flex-column align-items-between gap-2">
                                <div className="d-flex align-items-center">
                                    <img src={ouro_logo} style={{ height: '3rem', width: 'auto' }} />
                                    <div className="ms-2">
                                        {`${formatNumber(
                                            convertWeiToEsdt(ouroRewardAmount, DEFAULT_DECIMALS, DEFAULT_DECIMALS),
                                            DEFAULT_DECIMALS,
                                        )} (
                                        ${convertToDollarString(
                                            convertWeiToEsdt(
                                                ouroRewardAmount,
                                                DEFAULT_DECIMALS,
                                                DEFAULT_DECIMALS,
                                            ).multipliedBy(getTokenPrice(OURO_TOKEN_ID)),
                                        )})`}
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <img src={auryn_logo} style={{ height: '3rem', width: 'auto' }} />
                                    <div className="ms-2">
                                        {`${formatNumber(
                                            convertWeiToEsdt(aurynRewardAmount, DEFAULT_DECIMALS, DEFAULT_DECIMALS),
                                            DEFAULT_DECIMALS,
                                        )} (${convertToDollarString(
                                            convertWeiToEsdt(
                                                aurynRewardAmount,
                                                DEFAULT_DECIMALS,
                                                DEFAULT_DECIMALS,
                                            ).multipliedBy(getTokenPrice(AURYN_TOKEN_ID, aurynIndex.toNumber())),
                                        )})`}
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <img src={elite_auryn_logo} style={{ height: '3rem', width: 'auto' }} />
                                    <div className="ms-2">
                                        {`${formatNumber(
                                            convertWeiToEsdt(eaurynRewardAmount, DEFAULT_DECIMALS, DEFAULT_DECIMALS),
                                            DEFAULT_DECIMALS,
                                        )} (${convertToDollarString(
                                            convertWeiToEsdt(
                                                eaurynRewardAmount,
                                                DEFAULT_DECIMALS,
                                                DEFAULT_DECIMALS,
                                            ).multipliedBy(getTokenPrice(EAURYN_TOKEN_ID, aurynIndex.toNumber())),
                                        )})`}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button
                                    className="farm_but py-2"
                                    style={{ width: '6rem' }}
                                    disabled={hasPendingTransactions}
                                    onClick={onClaimRewards}
                                >
                                    Claim
                                </button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};
