import React, { FC, useState } from 'react';
import Modal from 'react-modal';
import { StakingPoolInfoType } from 'z/types';

interface propsInterface {
    className?: string;
    data: StakingPoolInfoType[];
}

const StakingPoolInfo: FC<propsInterface> = ({ className, data }) => {
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

    // function onOpenModal() {
    //     setModalIsOpen(true);
    // }

    function onCloseModal() {
        setModalIsOpen(false);
    }

    return (
        <>
            <div className={`${className} d-flex flex-column`} style={{ rowGap: '8px' }}>
                {data.slice(0, 7).map((row, id) => {
                    return (
                        <div className="d-flex justify-content-between" key={id}>
                            <span>{row.key}:</span>
                            <span>{row.value}</span>
                        </div>
                    );
                })}
            </div>

            {/* <div className="d-flex justify-content-center mt-1" onClick={onOpenModal}>
                <a className="text-center" href="#">
                    See More
                </a>
            </div> */}

            <Modal isOpen={modalIsOpen} onRequestClose={onCloseModal} style={customStyles} ariaHideApp={false}>
                <div className="text-center" style={{ fontSize: '18px', color: '#f1dc46' }}>
                    Staking Pool Info
                </div>

                <div
                    className={`${className} d-flex flex-column`}
                    style={{ rowGap: '8px', width: '400px', padding: '16px', color: '#98A1C0' }}
                >
                    {data.map((row, id) => {
                        return (
                            <div className="d-flex justify-content-between" key={id}>
                                <span>{row.key}:</span>
                                <span>{row.value}</span>
                            </div>
                        );
                    })}
                </div>

                <div className="d-flex w-100 justify-content-center">
                    <button className="add-liquidity-button" onClick={onCloseModal}>
                        Cancel
                    </button>
                </div>
            </Modal>
        </>
    );
};

export default StakingPoolInfo;

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
