import { Loader } from '@multiversx/sdk-dapp/UI';
import React, { useState } from 'react';

const responsiveStyle = {
  maxWidth: '100%',
  maxHeight: '100%',
  width: 'auto',
  height: 'auto',
};

export const AssetResource = ({
  uri,
  isVideo,
  fileType,
}: {
  uri: string;
  isVideo?: boolean;
  fileType?: string;
}) => {
  const [renderResourceAsVideo, _] = useState<boolean>(
    isVideo !== undefined
      ? isVideo
      : fileType?.includes('video')
      ? true
      : uri.endsWith('.mp4'),
  );

  //TODO: fix this
  // the most likely reason this isn't being set is because of the conditional rendering which makes
  // the image/video tags not be rendered, thus not making the requests to fetch the resources
  // and never ending the loading state
  const [isLoading, setIsLoading] = useState(false);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      {!renderResourceAsVideo && (
        <img
          src={uri}
          style={responsiveStyle}
          className="rounded asset-resource"
          onLoad={() => setIsLoading(false)}
        />
      )}
      {renderResourceAsVideo && (
        <video
          controls={false}
          autoPlay={true}
          loop={true}
          muted={true}
          style={responsiveStyle}
          className="rounded asset-resource"
          onLoadedData={() => {
            setIsLoading(false);
          }}
          onError={() => console.log('Error loading the video')}
        >
          <source src={uri} type="video/mp4" />
        </video>
      )}
    </>
  );
};
