import {
  Address,
  AddressValue,
  ArgSerializer,
  BigUIntValue,
  Field,
  List,
  StringValue,
  Struct,
  TokenIdentifierValue,
  TokenTransfer,
  TypedValue,
  U32Value,
  U64Value,
  U8Value,
} from "@multiversx/sdk-core/out";
import BigNumber from "bignumber.js";
import {
    InjectRewardType,
    SVST_TOKEN_ID,
    VEGLD_VAULT_SC_ADDRESS,
} from 'config';
import {
    VegldVaultBaseContextType,
    VegldVaultStatsContextType,
    VegldVaultUserContextType,
    VestingTypeEnum,
} from 'z/types';
import {
    elrondDappSendTransactions,
    mvxQuery,
} from './common';
import {
    esdtTokenPaymentType,
    vegldVaultSmartContract,
} from './provider';

/* vEGLD-vault interactions */

export async function vegldVaultViewBaseContext(): Promise<VegldVaultBaseContextType | undefined> {
    try {
        const value = await mvxQuery(vegldVaultSmartContract.methods.viewBaseContext());
        const decoded = {
            state: value.state,
            sft_lock_period: value.sft_lock_period.toNumber(),
            major_reward_token_id: value.major_reward_token_id.toString(),
        };

        return decoded;
    } catch (err) {
        console.error('vvViewBaseContext', err);
        return undefined;
    }
}

export async function vegldVaultViewStatsContext(): Promise<VegldVaultStatsContextType | undefined> {
    try {
        const value = await mvxQuery(vegldVaultSmartContract.methods.viewStatsContext());
        const decoded = {
            vegld_reserve: value.vegld_reserve.toFixed(0),
            sft_reserves: value.sft_reserves.map((v: any) => v.toNumber()),
            total_major_power: value.total_major_power.toFixed(0),
            reward_rate: value.reward_rate.toFixed(0),
            major_reward_rate: value.major_reward_rate.toFixed(0),
        };

        return decoded;
    } catch (err) {
        console.error('vvViewStatsContext', err);
        return undefined;
    }
}

export async function vegldVaultViewUserContext(
    address: string,
): Promise<VegldVaultUserContextType | undefined> {
    try {
        const value = await mvxQuery(vegldVaultSmartContract.methods.viewUserContext([address]));

        const decoded = {
            vegld_staked_amount: value.vegld_staked_amount.toFixed(0),
            sft_staked_amounts: value.sft_staked_amounts.map((v: any) => v.toNumber()),

            minor_power: value.minor_power.toFixed(0),
            major_power: value.major_power.toFixed(0),

            reward_amount: value.reward_amount.toFixed(0),
            last_claimed_timestamp: value.last_claimed_timestamp.toNumber(),
            major_reward_amount: value.major_reward_amount.toFixed(0),
            user_last_stake_epoch: value.user_last_stake_epoch.toNumber(),

            deb: value.deb.toNumber() / 100_000,
            im: value.im.toNumber() / 1_000_000,
            tm: value.tm.toNumber() / 1_000_000,
            dm: value.dm.toNumber() / 1_000_000,
        };

        return decoded;
    } catch (err) {
        console.error('vvViewUserContext', err);
        return undefined;
    }
}

export async function vegldVaultUnstakeToken(
  amount: BigNumber,
) {
    const args: TypedValue[] = [
        new BigUIntValue(amount),
    ];

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `unstakeToken@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: VEGLD_VAULT_SC_ADDRESS,
        gasLimit: 100_000_000,
    };
    
    const txName = 'Unstake';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);
    
    return { sessionId, error };
}

export async function vegldVaultUnstakeTokenWithFee(
    amount: BigNumber,
    payment: TokenTransfer,
) {
    const args: TypedValue[] = [
        new TokenIdentifierValue(payment.tokenIdentifier),
        new BigUIntValue(payment.amountAsBigInteger),

        new StringValue('unstakeTokenWithFee'), // method name
        new BigUIntValue(amount),
    ];

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `ESDTTransfer@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: VEGLD_VAULT_SC_ADDRESS,
        gasLimit: 100_000_000,
    };

    const txName = 'Unstake';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function vegldVaultInjectReward(
    scAddress: string,
    injectRewardType: InjectRewardType,
    payments: TokenTransfer[],
    sender: string,
    gasLimit?: number,
) {
    const args: TypedValue[] = [new AddressValue(new Address(scAddress)), new U32Value(payments.length)];
    payments.map((payment) => {
        args.push(new TokenIdentifierValue(payment.tokenIdentifier));
        args.push(new U64Value(payment.nonce));
        args.push(new BigUIntValue(payment.amountAsBigInteger));
    });
    args.push(
        new StringValue(
            injectRewardType === InjectRewardType.StandardReward
                ? 'injectReward'
                : 'injectMajorRewards',
        ),
    );

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `MultiESDTNFTTransfer@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: sender,
        gasLimit: gasLimit || 50_000_000,
    };

    const txName = 'Inject Reward';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function vegldVaultClaimMajorReward() {
  const data = `claimMajorReward`;

  const tx = {
    value: 0,
    data,
    receiver: VEGLD_VAULT_SC_ADDRESS,
    gasLimit: 30_000_000,
  };
  
  const txName = 'Claim Major Reward';
  const { sessionId, error } = await elrondDappSendTransactions(tx, txName);
  
  return { sessionId, error };
}
