import axios from 'axios';
import { VESTA_API_URL } from 'config';
import {
    NftStakingSnapshotType,
    NftStakingRewards,
    OuroLiquidityVaultUserSnapshotType,
    OuroLiquidityVaultUserElitePowersType,
} from 'z/types';

export async function getNftStakingUserSnapshot(address: string): Promise<NftStakingSnapshotType | undefined> {
    try {
        const queryUrl = `${VESTA_API_URL}/nft-staking/score?address=${address}`;
        const { data } = await axios.get<NftStakingSnapshotType>(queryUrl);
        return data;
    } catch (err) {
        console.error('getNftStakingUserSnapshot failed:', err);
    }

    return undefined;
}

export async function getNftStakingTotalSnapshot(): Promise<NftStakingSnapshotType | undefined> {
    try {
        const queryUrl = `${VESTA_API_URL}/nft-staking/total-score`;
        const { data } = await axios.get<NftStakingSnapshotType>(queryUrl);
        return data;
    } catch (err) {
        console.error('getNftStakingTotalSnapshot failed:', err);
    }

    return undefined;
}

export async function getNftStakingProofs(address: string): Promise<string[] | undefined> {
    try {
        const queryUrl = `${VESTA_API_URL}/nft-staking/proofs?address=${address}`;
        const { data } = await axios.get<string[]>(queryUrl);

        return data;
    } catch (err) {
        console.error('getNftStakingProofs failed:', err);
    }

    return undefined;
}

export async function getNftStakingRewards(address: string): Promise<NftStakingRewards | undefined> {
    try {
        const queryUrl = `${VESTA_API_URL}/nft-staking/rewards?address=${address}`;
        const { data } = await axios.get<NftStakingRewards>(queryUrl);

        return data;
    } catch (err) {
        console.error('getNftStakingRewards failed:', err);
    }

    return undefined;
}

export async function getDeb(address: string): Promise<number | undefined> {
    try {
        const queryUrl = `${VESTA_API_URL}/deb?address=${address}`;
        const { data } = await axios.get<number>(queryUrl);

        return data;
    } catch (err) {
        console.error('getDeb failed:', err);
    }

    return undefined;
}

export async function getOuroLiquidityVaultSnapshots(): Promise<OuroLiquidityVaultUserSnapshotType[] | undefined> {
    try {
        const queryUrl = `${VESTA_API_URL}/ouro-vault/snapshots`;
        const { data } = await axios.get<OuroLiquidityVaultUserSnapshotType[]>(queryUrl);

        return data;
    } catch (err) {
        console.error('getOuroLiquidityVaultSnapshots failed:', err);
    }

    return undefined;
}

export async function getOuroVaultRewards(address: string): Promise<NftStakingRewards | undefined> {
    try {
        const queryUrl = `${VESTA_API_URL}/ouro-vault/rewards?address=${address}`;
        const { data } = await axios.get<NftStakingRewards>(queryUrl);

        return data;
    } catch (err) {
        console.error('getOuroVaultRewards failed:', err);
    }

    return undefined;
}

export async function getOuroVaultEliteComponentPower(
    address: string,
): Promise<OuroLiquidityVaultUserElitePowersType | undefined> {
    try {
        const queryUrl = `${VESTA_API_URL}/ouro-vault/elite-component-powers?address=${address}`;
        const { data } = await axios.get<OuroLiquidityVaultUserElitePowersType>(queryUrl);

        return data;
    } catch (err) {
        console.error('getOuroVaultEliteComponentPower failed:', err);
    }

    return undefined;
}

export async function getOuroVaultProofs(address: string): Promise<string[] | undefined> {
    try {
        const queryUrl = `${VESTA_API_URL}/ouro-vault/proofs?address=${address}`;
        const { data } = await axios.get<string[]>(queryUrl);

        return data;
    } catch (err) {
        console.error('getOuroVaultProofs failed:', err);
    }

    return undefined;
}