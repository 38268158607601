import React, { useEffect, useState } from 'react';
import { TokenTransfer } from '@multiversx/sdk-core/out';
import { useGetAccount, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import { NftType } from '@multiversx/sdk-dapp/types/tokens.types';
import BigNumber from 'bignumber.js';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { InfoTooltip, DebSwitch } from 'components';
import { ToggleStakeOrUnstake, ResponsiveNumInput } from 'components/Elements';
import { PageBack } from 'components/Elements';
import { DEB_PRECISION, OURO_TOKEN_ID, VST_TOKEN_ID } from 'config';
import { selectNetstats, selectNftStakingV2 } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { routeNames } from 'routes';
import { nftStakingDisableSnakeDeb, nftStakingEnableSnakeDeb, nftStakingStake, nftStakingUnstake } from 'z/elrond';
import { useNftStakingHooks } from 'z/hooks/nft-staking';
import { EsdtTokenPaymentType, StakeEsdtFeeDetails } from 'z/types';
import { StakingPoolInfoType } from 'z/types';
import { ERROR_CONNECT_WALLET, ERROR_TRANSACTION_ONGOING, formatNumber, toastError } from 'z/utils';
import { getSftExplorerUrl, getSftImage, getSftSupply } from 'z/utils/sfts';
import { SINGLE_NFT_STAKING_POOL_INFO } from './const';
import StakingPoolInfo from './StakingPoolInfo';
import { UnstakeFees } from './UnstakeFees';

const SingleCollectionManage = () => {
    const { stakingId } = useParams();
    const navigate = useNavigate();
    const { hasPendingTransactions } = useGetPendingTransactions();
    const { address } = useGetAccount();
    useNftStakingHooks();
    const nftStakingRedux = useAppSelector(selectNftStakingV2);
    const netstatsRedux = useAppSelector(selectNetstats);

    const [isStake, setIsStake] = useState<boolean>(true);
    const [nftAmount, setNftAmount] = useState<number>(1);
    const [walletSfts, setWalletSfts] = useState<NftType | undefined>();
    const [sftFeeDetails, setSftFeeDetails] = useState<StakeEsdtFeeDetails | undefined>();
    const [stakedSfts, setStakedSfts] = useState<EsdtTokenPaymentType | undefined>();

    const stakingInfo = SINGLE_NFT_STAKING_POOL_INFO[Number(stakingId)];

    useEffect(() => {
        if (stakingId == '0' || stakingId == '1') {
            // validated URL
            return;
        }
        navigate('/404');
    }, [stakingId]);

    useEffect(() => {
        if (netstatsRedux.walletNftsSftsMap && netstatsRedux.walletNftsSftsMap[stakingInfo.identifier])
            setWalletSfts(
                netstatsRedux.walletNftsSftsMap[stakingInfo.identifier].find(
                    ({ nonce }) => nonce === stakingInfo.nonce,
                ),
            );
    }, [netstatsRedux]);

    useEffect(() => {
        if (nftStakingRedux.userFeeContext && nftStakingRedux.userFeeContext.snake) {
            setSftFeeDetails(nftStakingRedux.userFeeContext.snake.find(({ nonce }) => nonce === stakingInfo.nonce));
        }
        if (nftStakingRedux.userContext && nftStakingRedux.userContext.staked_snake) {
            setStakedSfts(
                nftStakingRedux.userContext.staked_snake.find(({ token_nonce }) => token_nonce === stakingInfo.nonce),
            );
        }
    }, [nftStakingRedux]);

    const getAuxDataPoolInfo = () => {
        const totalStakedSfts =
            (nftStakingRedux.totalStakedNftsSftsMap &&
                nftStakingRedux.totalStakedNftsSftsMap[stakingInfo.identifier]?.find(
                    ({ nonce }) => nonce === stakingInfo.nonce,
                )?.balance) ||
            '0';
        const totalSupply = getSftSupply(stakingInfo.identifier, stakingInfo.nonce);

        if (stakingInfo.nonce === 1) {
            return {
                totalPoolScore: nftStakingRedux.totalSnapshot?.snake_score || '0',
                totalPersonalScore: new BigNumber(nftStakingRedux.userSnapshot?.snake_score || '0'),
                totalStakedSfts,
                totalSupply,
            };
        } else {
            return {
                totalPoolScore: nftStakingRedux.totalSnapshot?.share_score || '0',
                totalPersonalScore: new BigNumber(nftStakingRedux.userSnapshot?.share_score || '0'),
                totalStakedSfts,
                totalSupply,
            };
        }
    };

    const getPoolInfo = (): StakingPoolInfoType[] => {
        const { totalPoolScore, totalPersonalScore, totalStakedSfts, totalSupply } = getAuxDataPoolInfo();

        const poolInfo = [
            {
                key: 'Total score',
                value: formatNumber(totalPoolScore, 5),
            },
            {
                key: 'Pool load',
                value: `${formatNumber((parseInt(totalStakedSfts) * 100) / totalSupply, 3)}%`,
            },
            {
                key: 'Total sfts staked',
                value: formatNumber(totalStakedSfts, 0),
            },
            {
                key: 'Your score',
                value: formatNumber(totalPersonalScore, 5),
            },
            {
                key: 'Your reward share',
                value: `${formatNumber(totalPersonalScore.multipliedBy(1000).div(new BigNumber(totalPoolScore)), 3)}‰`,
            },
            {
                key: `Your ${stakingInfo.nonce === 1 ? 'Snake' : 'Shares'} Staked`,
                value: formatNumber(stakedSfts?.amount || '0', 0),
            },
        ];
        if (stakingInfo.nonce === 2 && nftStakingRedux.userContext) {
            const snakeSfts = nftStakingRedux.userContext.staked_snake.find(({ token_nonce }) => token_nonce === 1);
            poolInfo.push({
                key: 'Your Snakes Staked',
                value: formatNumber(snakeSfts?.amount || '0', 0),
            });
        }
        if (stakingInfo.nonce === 1) {
            poolInfo.push({
                key: 'bVST Minting Power',
                value: `${formatNumber(
                    nftStakingRedux.userContext?.snapshot?.is_snake_deb_disabled
                        ? totalPersonalScore
                              .multipliedBy(netstatsRedux?.deb ? netstatsRedux.deb - DEB_PRECISION : DEB_PRECISION)
                              .div(DEB_PRECISION)
                        : '0',
                    3,
                )}/Day`,
            });
        }
        return poolInfo;
    };

    const onStakeSfts = async (): Promise<void> => {
        if (!address) {
            return toastError(ERROR_CONNECT_WALLET);
        } else if (hasPendingTransactions) {
            return toastError(ERROR_TRANSACTION_ONGOING);
        } else if (nftAmount > parseInt(walletSfts?.balance || '0')) {
            return toastError('Not enough SFTs');
        } else if (nftAmount === 0) {
            return toastError('Not enough SFTs have been selected');
        }

        await nftStakingStake(
            [TokenTransfer.metaEsdtFromBigInteger(stakingInfo.identifier, stakingInfo.nonce, nftAmount)],
            address,
        );
    };

    const onUnstakeSfts = async () => {
        if (nftAmount > parseInt(stakedSfts?.amount || '0')) {
            return toastError('Not enough SFTs');
        } else if (!nftStakingRedux.userFeeContext?.flat_fee) {
            return toastError('Something went wrong');
        } else if (
            parseInt(netstatsRedux.walletTokensMap[OURO_TOKEN_ID]?.balance || '0') <
            parseInt(nftStakingRedux.userFeeContext?.flat_fee || '0')
        ) {
            return toastError('Not enough OURO for fee');
        } else if (
            parseInt(netstatsRedux.walletTokensMap[VST_TOKEN_ID]?.balance || '0') <
            parseInt(sftFeeDetails?.vst_amount || '0')
        ) {
            return toastError('Not enough VST for fee');
        } else if (nftAmount === 0) {
            return toastError('Not enough SFTs have been selected');
        }

        const unstakePayments: EsdtTokenPaymentType[] = [
            {
                token_identifier: stakingInfo.identifier,
                token_nonce: stakingInfo.nonce,
                amount: nftAmount.toString(),
            },
        ];
        const payments: TokenTransfer[] = [
            TokenTransfer.metaEsdtFromBigInteger(
                OURO_TOKEN_ID,
                0,
                new BigNumber(nftStakingRedux.userFeeContext?.flat_fee || '0').multipliedBy(1.01).toFixed(0),
            ),
        ];

        const vstAmount = new BigNumber(sftFeeDetails?.vst_amount || '0');
        if (vstAmount.gt(0)) {
            payments.push(
                TokenTransfer.metaEsdtFromBigInteger(
                    VST_TOKEN_ID,
                    0,
                    vstAmount.multipliedBy(nftAmount).multipliedBy(1.01).toFixed(0),
                ),
            );
        }

        await nftStakingUnstake(unstakePayments, payments, address);
    };

    return (
        <div className="container" style={{ marginTop: '50px', color: '#98a1c0' }}>
            <div className="d-flex justify-content-center">
                <div className="vesta-first-container" style={{ maxWidth: '1024px' }}>
                    <PageBack url={routeNames.nftStaking} />

                    <div className="text-center" style={{ fontSize: '18px', color: '#f1dc46' }}>
                        {stakingInfo.name} Staking
                    </div>
                    <div className="d-flex justify-content-center mt-1">
                        <a
                            className="text-center"
                            href={getSftExplorerUrl(stakingInfo.identifier, stakingInfo.nonce)}
                            target="_black"
                        >
                            Explore Collection
                        </a>
                    </div>

                    <Row style={{ marginTop: '16px', rowGap: '12px' }}>
                        <Col md={4}>
                            <div className="active-pool-li-container h-100">
                                <img
                                    src={getSftImage(stakingInfo.identifier, stakingInfo.nonce)}
                                    alt="nft avatar"
                                    width={'100%'}
                                    style={{ borderRadius: '10px' }}
                                />
                            </div>
                        </Col>

                        <Col md={8}>
                            <div className="active-pool-li-container h-100 position-relative">
                                <div className="text-center" style={{ fontSize: '18px' }}>
                                    Staking pool info
                                </div>

                                <StakingPoolInfo className="mt-4" data={getPoolInfo()} />
                                <a href="#">
                                    <InfoTooltip title="Staking pool info" />
                                </a>
                            </div>
                        </Col>

                        <Col md={4}>
                            <div className="active-pool-li-container h-100">
                                {stakingInfo.nonce === 1 && (
                                    <>
                                        <div className="text-center mb-4" style={{ fontSize: '18px' }}>
                                            DEB™ Conversion
                                        </div>

                                        <DebSwitch
                                            buttonTextPrefix="Snakes"
                                            value={!!nftStakingRedux.userContext?.snapshot.is_snake_deb_disabled}
                                            info={'Deb'}
                                            enableDeb={async (sender: string) => {
                                                await nftStakingEnableSnakeDeb(sender);
                                            }}
                                            disableDeb={async (sender: string) => {
                                                await nftStakingDisableSnakeDeb(sender);
                                            }}
                                        />
                                    </>
                                )}
                            </div>
                        </Col>

                        <Col md={8}>
                            <div className="active-pool-li-container h-100">
                                <ToggleStakeOrUnstake value={isStake} onChange={setIsStake} />

                                <div className="responsive-flex mt-4">
                                    <div className="d-flex flex-column">
                                        <img
                                            src={getSftImage(stakingInfo.identifier, stakingInfo.nonce)}
                                            alt="nft avatar"
                                            width={'150px'}
                                            style={{ borderRadius: '10px' }}
                                        />

                                        <span
                                            className=""
                                            style={{ fontWeight: 'bold', cursor: 'pointer', textAlign: 'center' }}
                                            onClick={() =>
                                                setNftAmount(
                                                    parseInt(
                                                        (isStake ? walletSfts?.balance : stakedSfts?.amount) || '0',
                                                    ),
                                                )
                                            }
                                        >
                                            {`Balance: ${(isStake ? walletSfts?.balance : stakedSfts?.amount) || '0'}`}
                                        </span>
                                    </div>
                                    <div style={{ fontSize: '38px' }}>X</div>
                                    <ResponsiveNumInput
                                        value={nftAmount}
                                        onChange={setNftAmount}
                                        min={1}
                                        max={parseInt((isStake ? walletSfts?.balance : stakedSfts?.amount) || '1')}
                                    />
                                </div>

                                <UnstakeFees
                                    isStake={isStake}
                                    flat_fee={nftStakingRedux.userFeeContext?.flat_fee || '0'}
                                    vst_amount={new BigNumber(sftFeeDetails?.vst_amount || '0')
                                        .multipliedBy(nftAmount)
                                        .toFixed()}
                                />

                                <div className="d-flex justify-content-center mt-4">
                                    <button
                                        className="farm_but py-3"
                                        style={{ width: '12rem' }}
                                        onClick={() => (isStake ? onStakeSfts() : onUnstakeSfts())}
                                        disabled={hasPendingTransactions}
                                    >
                                        {isStake ? 'Stake' : 'Unstake'}
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default SingleCollectionManage;
