import { FeeReceiver } from "z/elrond";
import { EsdtTokenPaymentType } from "./common";

export enum StateEnum {
    Inactive = 'Inactive',
    Active = 'Active',
    ActiveNoSwaps = 'ActiveNoSwaps',
}
export function parseStateEnum(val: string): StateEnum {
    return StateEnum[val as Exclude<keyof typeof StateEnum, 'parse'>];
}

export interface PairTokensType {
    first_token_id: string,
    second_token_id: string,
}

export interface SwapRouterSettingsType {
    state: StateEnum,
    pair_template_address: string,
    pair_tokens: PairTokensType[],
    pair_addresses: string[],
}

export interface SwapPoolType {
    //
    pool_address: string,
    pool_state: StateEnum,
    
    first_token_id: string,
    first_token_ticker: string,
    first_token_decimals: number,
    
    second_token_id: string,
    second_token_ticker: string,
    second_token_decimals: number,
    
    first_token_reserve: string,
    second_token_reserve: string,
    
    lp_token_id: string,
    lp_token_supply: string,
    lp_token_roles_are_set: boolean,
    lp_token_decimals: number,
    
    fee_token_id: string,
    total_fee_percentage: number,
    special_fee_percentage: number,
    
    fee_receivers: FeeReceiver[],
    
    // for frontend
    index: number,
    
    // for My Liquidity
    lp_token_balance: string,
}

export interface SwapOperationsType {
    poolIndexes: number[],
    pairAddresses: string[],
    tokenOuts: string[],
}

export interface SwapResultType {
    total_fee: string,
    special_fee: string,
    payment_in: EsdtTokenPaymentType,
    payment_out: EsdtTokenPaymentType,
    refund_amount_in: string,
}
