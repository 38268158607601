import React, { FC, useState } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse } from '@mui/material';
import Slider, { SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Modal from 'react-modal';
import { InputToken } from './InputToken';

interface propsInterface {
    className?: string;
    tokenA: any;
    tokenB: any;
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const AirbnbSlider = styled(Slider)(({ theme }) => ({
    color: '#F1DC46',
    height: 3,
    padding: '13px 0',
    '& .MuiSlider-thumb': {
        height: 20,
        width: 20,
        backgroundColor: '#1B0921',
        border: '1px solid currentColor',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: '0 0 0 8px rgba(249, 216, 94, 0.16)',
        },
        '& .airbnb-bar': {
            height: 9,
            width: 1,
            backgroundColor: 'currentColor',
            marginLeft: 1,
            marginRight: 1,
        },
    },
    '& .MuiSlider-track': {
        height: 6,
    },
    '& .MuiSlider-rail': {
        color: theme.palette.mode === 'dark' ? '#1B0921' : '#1B0921',
        // opacity: theme.palette.mode === 'dark' ? undefined : 1,
        opacity: 1,
        height: 6,
    },
    '& .MuiSlider-markLabel': {
        color: '#98A1C0',
    },
    '& .MuiSlider-markActive': {
        backgroundColor: '#F1DC46',
    },
}));

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AirbnbThumbComponentProps extends React.HTMLAttributes<unknown> { }

function AirbnbThumbComponent(props: AirbnbThumbComponentProps) {
    const { children, ...other } = props;
    return <SliderThumb {...other}>{children}</SliderThumb>;
}

const marks = [
    {
        value: 0,
        label: '0',
    },
    {
        value: 25,
        label: '25%',
    },
    {
        value: 50,
        label: '50%',
    },
    {
        value: 75,
        label: '75%',
    },
    {
        value: 100,
        label: '100%',
    },
];

export const LPFarmingCard: FC<propsInterface> = ({
    className,
    tokenA,
    tokenB,
}) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [stakeAmount, setStateAmount] = useState(0);
    const [showDetails, setShowDetails] = useState<boolean>(false);

    function closeModal() {
        setIsOpen(false);
    }

    const handleSliderChange = (value: number) => {
        setStateAmount((3000 / 100) * value);
    };

    function toggleShowDetailsButton() {
        setShowDetails(!showDetails);
    }

    return (
        <div className={className}>
            <div className="active-pool-li">
                <div className="d-flex align-items-center">
                    <img src={tokenA?.icon} alt="logo" width="50px" />
                    <img src={tokenB?.icon} alt="logo" width="50px" />
                    <div className="stake-info">
                        <span>
                            {tokenA?.symbol} - {tokenB?.symbol}
                        </span>
                        <span>$999,999,999</span>
                    </div>
                </div>

                <div className="active-pool-info" style={{ marginLeft: '10px' }}>
                    <span>Max APR / APY</span>
                    <span>25% / 28%</span>
                </div>

                <div className="active-pool-info" style={{ marginLeft: '10px' }}>
                    <span>Virtual LP</span>
                    <span>0</span>
                </div>

                <div className="active-pool-info" style={{ marginLeft: '10px' }}>
                    <span>Raw VestaX</span>
                    <span>0</span>
                </div>

                <div className="d-flex gap-2 justify-content-center">
                    <div className="vesta_x_but">Harvest all</div>
                    <div className="vesta_x_but" onClick={() => setIsOpen(true)}>
                        Stake LP
                    </div>
                </div>

                <div className="active-pool-info">
                    <button
                        className="pool-collapse-button"
                        onClick={(toggleShowDetailsButton)}
                    >
                        {showDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </button>
                </div>
            </div>


            <Collapse in={showDetails}>
                <div style={{ color: "#98A1C0" }}>
                    <hr />
                    <div className="LPfarm-card-info">
                        <div className='LP-control'>
                            <div className='spin-but'>-</div>
                            <div className='LP-info'>
                                <span>VEgld-Koson-LP</span>
                                <span>$123,456,789</span>
                            </div>
                        </div>

                        <div className='d-flex gap-2'>
                            <div className='spin-but'>+</div>
                            <div className='spin-but'>+</div>
                            <div className='spin-but'>+</div>
                        </div>

                        <div className='LP-info'>
                            <span>VirtualLP</span>
                            <span>$123,456,789</span>
                        </div>

                        <div className='LP-control'>
                            <div className='spin-but'>-</div>
                            <div className='LP-info'>
                                <span>Bronze LP</span>
                                <span>$123,456,789</span>
                            </div>
                        </div>

                        <div className='LP-control'>
                            <div className='spin-but'>-</div>
                            <div className='LP-info'>
                                <span>Silver LP</span>
                                <span>$123,456,789</span>
                            </div>
                        </div>

                        <div className='LP-control'>
                            <div className='spin-but'>-</div>
                            <div className='LP-info'>
                                <span>Golden LP</span>
                                <span>$123,456,789</span>
                            </div>
                        </div>

                    </div>

                </div>
            </Collapse>


            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                ariaHideApp={false}
            >
                <div className="select-token-modal" style={{ margin: '10px' }}>
                    <div className="d-flex align-items-center mb-3">
                        <img src={tokenA?.icon} alt="logo" width="50px" />
                        <img src={tokenB?.icon} alt="logo" width="50px" />
                        <span
                            style={{
                                color: '#F1DC46',
                                marginLeft: '10px',
                                fontSize: '1.2rem',
                            }}
                        >
                            Stake in {tokenA?.symbol}-{tokenB?.symbol} farm
                        </span>
                    </div>

                    <InputToken
                        tokenAmount={stakeAmount}
                        onChangeTokenAmount={setStateAmount}
                        token={{
                            icon: tokenA?.icon,
                            symbol: `${tokenA?.symbol}-${tokenB?.symbol}`,
                        }}
                        selectDisabled={true}
                    />

                    <div className="mt-2" style={{ padding: '10px 15px 10px 10px' }}>
                        <AirbnbSlider
                            components={{ Thumb: AirbnbThumbComponent }}
                            getAriaLabel={(index: number) =>
                                index === 0 ? 'Minimum price' : 'Maximum price'
                            }
                            defaultValue={0}
                            marks={marks}
                            value={(stakeAmount / 3000) * 100}
                            onChange={(e: any, num: any) => handleSliderChange(num)}
                        />
                    </div>

                    <div
                        className="mt-4 vesta_x_but"
                        style={{ border: '1px solid rgba(255, 255, 255, 0.2)' }}
                    >
                        Stake
                    </div>
                </div>
            </Modal>
        </div>
    );
};
