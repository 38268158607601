import React, { FC } from 'react';
import { useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { IoClose } from 'react-icons/io5';
import Modal from 'react-modal';
import { selectSwap } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { getTokenLogo } from 'z/elrond';
import { StateEnum } from 'z/types';
import {
    createTokenTicker,
    TOKEN_SEARCH_INPUT_TEXT,
} from 'z/utils';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

interface PropsType {
    selectedPoolIndex: number;
    setSelectedPoolIndex: (e: number) => void;
    disabled?: boolean;
}

export const SelectPool: FC<PropsType> = ({
    selectedPoolIndex,
    setSelectedPoolIndex,
    disabled = false,
}) => {
    const swapRedux = useAppSelector(selectSwap);

    const [searchKey, setSearchKey] = useState<string>('');
    const [modalIsOpen, setIsOpen] = React.useState(false);

    const selectedPool =
        swapRedux.pools.length > 0 && selectedPoolIndex >= 0
            ? swapRedux.pools[selectedPoolIndex]
            : null;

    function closeModal() {
        setIsOpen(false);
    }

    const onSelectPool = (poolIndex: number) => {
        setSelectedPoolIndex(poolIndex);

        setSearchKey('');
        closeModal();
    };

    const handleOpenModal = () => {
        setIsOpen(true);
    };

    return (
        <>
            {selectedPool ? (
                <button className="selected-pool-buttton" onClick={handleOpenModal} disabled={disabled}>
                    <div className="d-flex justify-content-between gap-2">
                        <div>
                            <>
                                <img
                                    src={getTokenLogo(selectedPool.first_token_id)}
                                    alt="logo"
                                    width="30px"
                                />
                                {createTokenTicker(selectedPool.first_token_id)}
                            </>
                        </div>
                        +
                        <div>
                            <>
                                <img
                                    src={getTokenLogo(selectedPool.second_token_id)}
                                    alt="logo"
                                    width="30px"
                                />
                                {createTokenTicker(selectedPool.second_token_id)}
                            </>
                        </div>
                    </div>
                    <BiChevronDown />
                </button>
            ) : (
                <button className="select-token-but" onClick={handleOpenModal}>
                    Select Pool
                    <BiChevronDown />
                </button>
            )}

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                ariaHideApp={false}
            >
                <div className="select-token-modal">
                    <div
                        style={{
                            padding: '0px 15px',
                            borderBottom: '1px solid rgba(255,255,255,0.06)',
                        }}
                    >
                        <div className="d-flex justify-content-between align-items-center">
                            <span
                                style={{
                                    color: '#F1DC46',
                                    marginLeft: '10px',
                                    fontSize: '1.25rem',
                                }}
                            >
                                Select a token
                            </span>
                            <div className="modal-close-but" onClick={closeModal}>
                                <IoClose />
                            </div>
                        </div>
                        <input
                            className="search-token mt-3 mb-3"
                            placeholder={TOKEN_SEARCH_INPUT_TEXT}
                            onChange={(e) => setSearchKey(e.target.value)}
                        />
                    </div>

                    <div style={{ padding: '0px 15px' }}>
                        <div className="mt-3 scrollbar token-list-box">
                        {swapRedux.pools.length > 0 &&
                            swapRedux.pools
                            .filter((pool) => {
                                return (
                                    (pool.first_token_id.toLowerCase().includes(searchKey.toLocaleLowerCase())
                                        || pool.second_token_id.toLowerCase().includes(searchKey.toLocaleLowerCase()))
                                    && pool.pool_state != StateEnum.Inactive
                                );
                            })
                            .map((pool, index) => {
                                return (
                                    <div
                                        className="swap-pool-row"
                                        key={`s-p-p-r-${index}`}
                                        onClick={() => onSelectPool(pool.index)}
                                    >
                                        <div className="token-li">
                                            <img
                                                src={getTokenLogo(pool.first_token_id)}
                                                alt="logo"
                                                width="40px"
                                            />
                                            <div className="d-flex flex-column">
                                                <span
                                                    style={{ color: 'white', fontSize: '1.2rem' }}
                                                >
                                                    {pool.first_token_id}
                                                </span>
                                                <span style={{ color: '#98A1C0' }}>
                                                    {createTokenTicker(
                                                        pool.first_token_id,
                                                    )}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="token-li">
                                            <img
                                                src={getTokenLogo(pool.second_token_id)}
                                                alt="logo"
                                                width="40px"
                                            />
                                            <div className="d-flex flex-column">
                                                <span
                                                    style={{ color: 'white', fontSize: '1.2rem' }}
                                                >
                                                    {pool.second_token_id}
                                                </span>
                                                <span style={{ color: '#98A1C0' }}>
                                                    {createTokenTicker(
                                                        pool.second_token_id,
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};
