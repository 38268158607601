import React, { useEffect, useState } from 'react';
import { TokenTransfer } from '@multiversx/sdk-core/out';
import { useGetAccount, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import { NftType } from '@multiversx/sdk-dapp/types/tokens.types';
import { logout } from '@multiversx/sdk-dapp/utils';
import BigNumber from 'bignumber.js';
import Modal from 'react-modal';

import { EAURYN_TOKEN_ID, VEAURYN_TOKEN_ID } from 'config';
import { selectEliteAccountTracker, selectNetstats } from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { eatUpdateEliteAccount } from 'z/elrond';
import { useEliteAccountTrackerHooks } from 'z/hooks/elite-account-tracker';
import { useWalletAssetsHooks } from 'z/hooks/wallet-assets';
import { BIG_NUMBER_ZERO, ERROR_CONNECT_WALLET, ERROR_TRANSACTION_ONGOING, toastError } from 'z/utils';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

export default () => {
    const { address } = useGetAccount();
    const { hasPendingTransactions } = useGetPendingTransactions();
    const netstatsRedux = useAppSelector(selectNetstats);
    const eliteAccountTrackerRedux = useAppSelector(selectEliteAccountTracker);
    useWalletAssetsHooks();
    useEliteAccountTrackerHooks();

    const [isOpen, setIsOpen] = useState(false);
    const [walletVeauryns, setWalletVeauryns] = useState<NftType[]>([]);

    const getVeaurynBalance = async (): Promise<BigNumber> => {
        if (netstatsRedux.walletMetaEsdtMap && netstatsRedux.walletMetaEsdtMap[VEAURYN_TOKEN_ID]) {
            setWalletVeauryns(netstatsRedux.walletMetaEsdtMap[VEAURYN_TOKEN_ID]);
            if (netstatsRedux.walletMetaEsdtMap[VEAURYN_TOKEN_ID].length > 0) {
                let _balance = BIG_NUMBER_ZERO;
                for (const metaEsdt of netstatsRedux.walletMetaEsdtMap[VEAURYN_TOKEN_ID]) {
                    _balance = _balance.plus(metaEsdt.balance);
                }
                return _balance;
            }
        }
        return BIG_NUMBER_ZERO;
    };

    const checkBalance = async () => {
        const eaurynEliteAccountBalance = new BigNumber(eliteAccountTrackerRedux.tokenBalances[EAURYN_TOKEN_ID]);
        const eaurynWalletBalance = new BigNumber(netstatsRedux.walletTokensMap[EAURYN_TOKEN_ID]?.balance || '0');

        const veaurynEliteAccountBalance = new BigNumber(eliteAccountTrackerRedux.tokenBalances[VEAURYN_TOKEN_ID]);
        const veaurynWalletBalance = await getVeaurynBalance();

        setIsOpen(
            !eaurynWalletBalance.isEqualTo(eaurynEliteAccountBalance) ||
                !veaurynWalletBalance.isEqualTo(veaurynEliteAccountBalance),
        );
    };

    useEffect(() => {
        if (address && netstatsRedux.walletMetaEsdtMap) {
            checkBalance();
        }else {
            setIsOpen(false);
        }
    }, [address, netstatsRedux, hasPendingTransactions, eliteAccountTrackerRedux]);

    async function onUpdateEliteAccount() {
        if (!address) {
            return toastError(ERROR_CONNECT_WALLET);
        } else if (hasPendingTransactions) {
            return toastError(ERROR_TRANSACTION_ONGOING);
        }

        const payments: TokenTransfer[] = [];
        if (netstatsRedux.walletTokensMap[EAURYN_TOKEN_ID]) {
            const token = netstatsRedux.walletTokensMap[EAURYN_TOKEN_ID];
            if (token.balance) {
                payments.push(TokenTransfer.fungibleFromBigInteger(token.identifier, token.balance));
            }
        }
        if (walletVeauryns.length > 0) {
            walletVeauryns.map((metaEsdt) =>
                payments.push(
                    TokenTransfer.metaEsdtFromBigInteger(metaEsdt.collection, metaEsdt.nonce, metaEsdt.balance),
                ),
            );
        }
        await eatUpdateEliteAccount(payments, address);
    }

    return (
        <Modal isOpen={isOpen} style={customStyles} ariaHideApp={false}>
            <div className="d-flex just-content-center flex-column p-5">
                <h3>Update Elite Account Required!</h3>
                <button
                    className="mt-3 vesta-button-first"
                    disabled={hasPendingTransactions}
                    onClick={onUpdateEliteAccount}
                >
                    Update Elite Account
                </button>
                <button className="mt-3 vesta-button-first" disabled={hasPendingTransactions} onClick={() => logout()}>
                    Logout
                </button>
            </div>
        </Modal>
    );
};
