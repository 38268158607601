import { OuroFactoryContextType } from 'z/types/ouro-factory';
import { mvxQuery } from './common';
import { ouroFactorySmartContract } from './provider';

export const OURO_SUPPLY_HARD_CAP = 10_000_000;
export const AUTOSTAKE_OURO_DISTRIBUTION_PERCENTAGE = 0.2; // 20%

export async function getOuroFactoryTodayEmissionAmount(): Promise<number | undefined> {
    try {
        const value = await mvxQuery(ouroFactorySmartContract.methods.getTodayEmissionAmount());

        return value.shiftedBy(-18).toNumber();
    } catch (err) {
        console.error('ouro emission amount:', err);
        return undefined;
    }
}

export async function getOuroFactoryCommonContext(): Promise<OuroFactoryContextType | undefined> {
    try {
        const value = await mvxQuery(ouroFactorySmartContract.methods.viewCommonContext());

        const decoded = {
            snake_coil_address: value.snake_coil_address.bech32(),
            snake_vesting_address: value.snake_vesting_address.bech32(),
            snake_nft_staking_address: value.snake_nft_staking_address.bech32(),
            ouro_token_id: value.ouro_token_id,
            ouro_supply: value.ouro_supply.shiftedBy(-18).toNumber(),
            distribution_addresses: value.distribution_addresses.map((da: any) => da.bech32()),
            distribution_percents: value.distribution_percents.map((dp: any) => dp.toNumber()),
            current_epoch: value.current_epoch.toNumber(),
            today_emission_amount: value.today_emission_amount.shiftedBy(-18).toNumber(),
            is_today_emission_distributed: value.is_today_emission_distributed,
        };

        return decoded;
    } catch (err) {
        console.error('ouro factory context:', err);
        return undefined;
    }
}
