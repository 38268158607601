import React, { PropsWithChildren, useEffect } from 'react';
import { BinaryCodec } from '@multiversx/sdk-core/out';
import { useGetAccount, useGetEgldPrice, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import { TokenType } from '@multiversx/sdk-dapp/types/tokens.types';
import { useLocation } from 'react-router-dom';
import {
    // EGLD_PRICE_UPDATE_PERIOD,
    FARMS,
    // PORTFOLIO_TOKEN_IDS,
    WALLET_TOKEN_IDS,
} from 'config';
import {
    setElrondStats,
    setSwapCommonConfiguration,
    setSwapPools,
    selectSwap,
    setIsLoadingPools,
    // setPortfolioTokens,
    setNonFungibleTokenStats,
    // selectFarm,
    setFarmRewards,
    setEliteAccountTier,
    setDeb,
    setVegldInEgldPrice,
    setWalletTokensMap,
} from 'redux/reducers';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { routeNames } from 'routes';
import {
    eatGetDeb,
    eatGetEliteAccountTier,
    EGLD_TOKEN_INFO,
    getVegldInEgldPrice,
    elrondViewRouterSettings,
    elrondViewPaginationSwapPools,
    farmAbiRegistry,
    getAccountTokensFromApi,
    getElrondStatsFromApi,
    getNFTBalances,
    getRewardDistributionData,
    getTokenBalancesFromApi,
} from 'z/elrond';
import {
    FarmRewardsType,
    NonFungibleTokenInfoMapType,
    SwapPoolType,
    // TokenPriceType,
} from 'z/types';

export const ReduxProvider = ({ children }: PropsWithChildren) => {
    const { hasPendingTransactions } = useGetPendingTransactions();
    const { address, balance } = useGetAccount();
    const { pathname } = useLocation();
    const { price: egldPrice } = useGetEgldPrice();

    const swapRedux = useAppSelector(selectSwap);
    // const farmRedux = useAppSelector(selectFarm);
    const dispatch = useAppDispatch();

    async function loadPools() {
        const pools = await elrondViewPaginationSwapPools();
        dispatch(setSwapPools(pools));

        // query all LP token balance
        if (address && pools.length > 0) {
            // loading start
            dispatch(setIsLoadingPools(true));

            const lpTokenIds = pools.map((pool) => pool.lp_token_id);
            const lpTokenBalanceInfos = await getTokenBalancesFromApi(address, lpTokenIds);

            if (lpTokenBalanceInfos.length !== lpTokenIds.length) {
                console.error(`ReduxProvider/query-lp-token-balance: lpTokenBalanceInfos.length !== lpTokenIds.length`);
            } else {
                const newPools: SwapPoolType[] = [];
                for (let i = 0; i < lpTokenBalanceInfos.length; i++) {
                    newPools.push({
                        ...pools[i],
                        lp_token_balance: lpTokenBalanceInfos[i].balance,
                    });
                }
                dispatch(setSwapPools(newPools));
            }

            // loading end
            dispatch(setIsLoadingPools(false));
        }
    }

    function fetchFarmData() {
        if (!address) return;

        (async () => {
            const farmTokenAttributesStruct = farmAbiRegistry.getStruct('LockedLpAttributes');
            const codec = new BinaryCodec();

            const allPoolExoticLps = FARMS.map((farm) => {
                return [farm.staked_lp_token].filter((str) => str !== undefined && str.length > 0);
            }).reduce((prev, crt) => (prev = prev.concat(crt)), []);
            const farmLpTokenBalances = await getNFTBalances(address, allPoolExoticLps);
            const newMapping: NonFungibleTokenInfoMapType = {};
            for (let i = 0; i < allPoolExoticLps.length; i++) {
                const filteredNfts = farmLpTokenBalances.filter((b) => b.collection === allPoolExoticLps[i]);
                const parsedNfts = [];
                for (let j = 0; j < filteredNfts.length; j++) {
                    const nft = filteredNfts[j];
                    const decodedAttributes = codec
                        .decodeTopLevel(Buffer.from(nft.attributes, 'base64'), farmTokenAttributesStruct)
                        .valueOf();
                    nft.stake_epoch = decodedAttributes.stake_epoch.toNumber();
                    nft.unbonding_start_epoch = decodedAttributes.unbonding_start_epoch.toNumber();
                    nft.stake_token_type = decodedAttributes.locked_lp_type_id.toNumber();
                    parsedNfts.push(nft);
                }

                newMapping[allPoolExoticLps[i]] = parsedNfts;
            }
            dispatch(setNonFungibleTokenStats(newMapping));
        })();
    }

    //
    // const triggerGetEgldPrice = () => {
    //   (async () => {
    //     const _egldPrice = await getEgldPriceFromApi();
    //     dispatch(setEgldPrice(_egldPrice));
    //   })();
    // };
    // useEffect(() => {
    //   triggerGetEgldPrice();
    //   const interval = setInterval(() => {
    //     triggerGetEgldPrice();
    //   }, EGLD_PRICE_UPDATE_PERIOD);
    //   return () => clearInterval(interval);
    // }, []);

    useEffect(() => {
        (async () => {
            const value = await getElrondStatsFromApi();
            dispatch(setElrondStats(value));
        })();

        (async () => {
            const _vegldInEgldPrice = await getVegldInEgldPrice();
            dispatch(setVegldInEgldPrice(_vegldInEgldPrice));
        })();
    }, []);

    useEffect(() => {
        if (hasPendingTransactions) return;

        (async () => {
            const decoded = await elrondViewRouterSettings();
            dispatch(setSwapCommonConfiguration(decoded));
        })();
    }, [hasPendingTransactions]);

    useEffect(() => {
        // pass only if pending tx is finished
        if (hasPendingTransactions) return;

        loadPools();
    }, [address, hasPendingTransactions, swapRedux.updateFlag]);

    // useEffect(() => {
    //   // pass only if pending tx is finished
    //   if (!address || hasPendingTransactions) return;

    //   if (pathname.search(routeNames.assets) === 0) {
    //     (async () => {
    //       // update portfolio wallet tokens
    //       const _tokens = await getAccountTokensFromApi(address, PORTFOLIO_TOKEN_IDS);
    //       const _egldToken: TokenType = {
    //         ...EGLD_TOKEN_INFO,
    //         balance,
    //         price: egldPrice,
    //       };
    //       _tokens.unshift(_egldToken);
    //       dispatch(setPortfolioTokens(_tokens));
    //     })();
    //   }
    // }, [address, pathname]);

    useEffect(() => {
        if (!address || hasPendingTransactions) return;

        if (pathname.startsWith(routeNames.farms) || pathname.startsWith(routeNames.myfarms)) {
            fetchFarmData();
        }
    }, [address, hasPendingTransactions, pathname]);

    useEffect(() => {
        if (!address || hasPendingTransactions) return;
        if (!pathname.startsWith(routeNames.farms)) return; // this hook only needs to be called once
        (async () => {
            const _farmRewards = await getRewardDistributionData();

            const newFarmRewards: FarmRewardsType[] = [];
            for (const farm of FARMS) {
                let found = false;
                for (const farmRewards of _farmRewards) {
                    if (farm.farm_address == farmRewards.farm_address) {
                        newFarmRewards.push(farmRewards);
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    console.error('getRewardDistributionData and FARMS not match');
                    newFarmRewards.push({
                        farm_address: '',
                        is_snapshotting: false,
                        farm_weight_share_percentage: 0,
                        raw_vst_estimated_amount: '0',
                    });
                }
            }
            dispatch(setFarmRewards(newFarmRewards));
        })();
    }, [pathname]);

    useEffect(() => {
        if (!address || hasPendingTransactions) return;

        (async () => {
            const _eatTier = await eatGetEliteAccountTier(address);
            dispatch(setEliteAccountTier(_eatTier));
            const _deb = await eatGetDeb(address);
            dispatch(setDeb(_deb));
        })();

        (async () => {
            const _tokens = await getAccountTokensFromApi(address, WALLET_TOKEN_IDS);
            const _egldToken: TokenType = {
                ...EGLD_TOKEN_INFO,
                balance,
                price: egldPrice,
            };
            _tokens.unshift(_egldToken);

            const _walletTokensMap: Record<string, TokenType> = {};
            for (const token of _tokens) {
                _walletTokensMap[token.identifier] = token;
            }

            dispatch(setWalletTokensMap(_walletTokensMap));
        })();
    }, [address, hasPendingTransactions]);

    return <>{children}</>;
};
