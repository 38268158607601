import React, { FC } from 'react';
import { useState } from 'react';
import { IoClose } from 'react-icons/io5';
import Modal from 'react-modal';
import {
    AURYN_TOKEN_ID,
    BVST_TOKEN_ID,
    EAURYN_TOKEN_ID,
    GSC_TOKEN_ID,
    OURO_TOKEN_ID,
    SLIP_TOKEN_ID,
    SSC_TOKEN_ID,
    USDC_TOKEN_ID,
    USDD_TOKEN_ID,
    VEGLD_TOKEN_ID,
    VST_TOKEN_ID,
} from 'config';
import { getTokenLogo } from 'z/elrond';
import { createTokenTicker, TOKEN_SEARCH_INPUT_TEXT } from 'z/utils';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

interface PropsType {
    onChangeTokenId: (e: string) => void;
    disabled?: boolean;
}

export const EsdtTokenSelect: FC<PropsType> = ({ onChangeTokenId, disabled = false }) => {
    const selectAvailableTokenIds: string[] = [
        OURO_TOKEN_ID,
        USDD_TOKEN_ID,
        AURYN_TOKEN_ID,
        EAURYN_TOKEN_ID,
        VST_TOKEN_ID,
        BVST_TOKEN_ID,
        'EGLD',
        VEGLD_TOKEN_ID,
        USDC_TOKEN_ID,
        SLIP_TOKEN_ID,
        GSC_TOKEN_ID,
        SSC_TOKEN_ID,
    ];

    const [search, setSearch] = useState<string>('');
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function closeModal() {
        setIsOpen(false);
    }

    const handleSelectToken = (tokenid: string) => {
        onChangeTokenId(tokenid);
        setSearch('');
        closeModal();
    };

    const handleOpenModal = () => {
        setIsOpen(true);
    };

    return (
        <>
            <div>
                <button className="select-token-but" onClick={handleOpenModal} disabled={disabled}>
                    Add Token
                </button>
            </div>

            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} ariaHideApp={false}>
                <div className="select-token-modal">
                    <div
                        style={{
                            padding: '0px 15px',
                            borderBottom: '1px solid rgba(255,255,255,0.06)',
                        }}
                    >
                        <div className="d-flex justify-content-between align-items-center">
                            <span
                                style={{
                                    color: '#F1DC46',
                                    marginLeft: '10px',
                                    fontSize: '1.25rem',
                                }}
                            >
                                Select a token
                            </span>

                            <div className="modal-close-but" onClick={closeModal}>
                                <IoClose />
                            </div>
                        </div>
                        <input
                            className="search-token mt-3"
                            placeholder={TOKEN_SEARCH_INPUT_TEXT}
                            onChange={(e) => setSearch(e.target.value)}
                        />

                        <div className="mt-3 d-flex flex-wrap gap-2 mb-4">
                            {selectAvailableTokenIds &&
                                selectAvailableTokenIds
                                    .filter((_row, id) => id < 3)
                                    .map((ti, index) => {
                                        return (
                                            <div
                                                className="token-tag"
                                                key={`token-tag${index}`}
                                                onClick={() => handleSelectToken(ti)}
                                            >
                                                <img src={getTokenLogo(ti)} alt="logo" width="30px" />
                                                <span style={{ color: '#98A1C0', fontSize: '1.2rem' }}>
                                                    {createTokenTicker(ti)}
                                                </span>
                                            </div>
                                        );
                                    })}
                        </div>
                    </div>

                    <div style={{ padding: '0px 15px' }}>
                        <div className="mt-3 scrollbar token-list-box">
                            {selectAvailableTokenIds
                                .filter((ti) => {
                                    return ti.toLowerCase().includes(search.toLocaleLowerCase());
                                })
                                .map((ti, index) => {
                                    return (
                                        <div className="token-li" key={index} onClick={() => handleSelectToken(ti)}>
                                            <img src={getTokenLogo(ti)} alt="logo" width="40px" />

                                            <div className="d-flex flex-column">
                                                <span style={{ color: 'white', fontSize: '1.2rem' }}>
                                                    {createTokenTicker(ti)}
                                                </span>
                                                <span style={{ color: '#98A1C0' }}>{ti}</span>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};
