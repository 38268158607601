import React from 'react';
import { TooltipLink } from 'components/Elements';
import { OURO_TOKEN_ID, VST_TOKEN_ID } from 'config';
import { getTokenDecimals } from 'z/elrond';
import { convertWeiToEsdt, formatNumber } from 'z/utils';

interface UnstakeFeesParams {
    isStake: boolean;
    flat_fee: string;
    vst_amount: string;
}

export const UnstakeFees = ({ isStake, flat_fee, vst_amount }: UnstakeFeesParams) => {
    return (
        <div className="d-flex justify-content-center mt-4">
            {isStake ? (
                <div>
                    Unstaking NFTs before 25 days may incur fees. Explore further details{' '}
                    <a
                        href="https://demiourgos-holdings-tm.gitbook.io/demiourgos-library/v/nft-staking/unstaking-fees"
                        target="blank"
                    >
                        here
                    </a>
                    .
                </div>
            ) : (
                <div className="d-flex align-items-center">
                    <TooltipLink
                        link="https://demiourgos-holdings-tm.gitbook.io/demiourgos-library/v/nft-staking/unstaking-fees"
                        tooltip="https://demiourgos-holdings-tm.gitbook.io/demiourgos-library/v/nft-staking/unstaking-fees"
                    />

                    <div className="ms-3">Unstake fee:</div>
                    <div className="ms-3">
                        <div>{`${formatNumber(
                            convertWeiToEsdt(flat_fee, getTokenDecimals(OURO_TOKEN_ID), 6),
                            6,
                        )} OURO`}</div>
                        <div>{`${formatNumber(
                            convertWeiToEsdt(vst_amount, getTokenDecimals(VST_TOKEN_ID), 6),
                            6,
                        )} VST`}</div>
                    </div>
                </div>
            )}
        </div>
    );
};
