import React, { useEffect, useState } from 'react';
import { useGetAccount, useGetIsLoggedIn, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import clsx from 'clsx';
import { Navbar as BsNavbar, NavItem, Nav, NavLink, NavDropdown } from 'react-bootstrap';
import { IoWallet, IoMenu, IoClose } from 'react-icons/io5';
import Modal from 'react-modal';
import { Link, useLocation } from 'react-router-dom';
// import vesta_x_logo from 'assets/img/vesta-x-logo.png';
import vesta_x_name_logo from 'assets/img/vesta-x-name-logo.png';
import { IS_DEV } from 'config';
import { Unlock } from 'pages/Unlock';
import { routeNames, routesMap } from 'routes';
import { useAdminHooks } from 'z/hooks/admin';
import { toastSuccess } from 'z/utils';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

export const Navbar = () => {
    const isLoggedIn = useGetIsLoggedIn();
    const { address } = useGetAccount();

    const location = useLocation();
    const { hasPendingTransactions } = useGetPendingTransactions();
    const { isAdmin } = useAdminHooks();
    const [modalIsOpen, setIsOpen] = React.useState(false);

    useEffect(() => {
        if (isLoggedIn) {
            closeModal();
        }
    }, [isLoggedIn]);

    const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    function closeModal() {
        setIsOpen(false);
    }

    function onButtonClick(e: any, isComingSoon: boolean = false) {
        if (e && hasPendingTransactions) {
            e.stopPropagation();
            e.preventDefault();
        }
        if (isComingSoon) {
            e.stopPropagation();
            e.preventDefault();
            toastSuccess('Coming Soon!');
        }
    }

    return (
        <div>
            <BsNavbar className="vesta_x_header" expand="lg" collapseOnSelect>
                <div className="container-fluid container">
                    <Link to={routeNames.home} onClick={onButtonClick}>
                        <img src={vesta_x_name_logo} className="vestax-navbar-logo" />
                    </Link>
                    <BsNavbar.Toggle aria-controls="responsive-navbar-nav">
                        <IoMenu />
                    </BsNavbar.Toggle>

                    <BsNavbar.Collapse id="responsive-navbar-nav" className="nav-menu-wrap">
                        <div className="custom-navbar-box">
                            <Nav>
                                {windowSize[0] >= 992 ? (
                                    <>
                                        <NavDropdown
                                            title="Swap"
                                            className={clsx({
                                                'vestax-navbar-dropdown': true,
                                                vestax_navbar_dropdown_focus:
                                                    routeNames.swap === location.pathname
                                                    || location.pathname.startsWith(routeNames.stableSwap)
                                                    || location.pathname.startsWith(routeNames.pools)
                                                    || location.pathname.startsWith(routeNames.stablePools)
                                            })}
                                        >
                                            <NavDropdown.Item as="div">
                                                <Link
                                                    to={routeNames.swap}
                                                    className="nav-link"
                                                    onClick={(e) => onButtonClick(e)}
                                                >
                                                    Swap
                                                </Link>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item as="div">
                                                <Link
                                                    to={routeNames.pools}
                                                    className="nav-link"
                                                    onClick={(e) => onButtonClick(e)}
                                                >
                                                    Pools
                                                </Link>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item as="div">
                                                <Link
                                                    to={routeNames.stablePools}
                                                    className="nav-link"
                                                    onClick={(e) => onButtonClick(e)}
                                                >
                                                    Stable Pools
                                                </Link>
                                            </NavDropdown.Item>
                                        </NavDropdown>

                                        <NavDropdown
                                            title="Earn"
                                            className={clsx({
                                                'vestax-navbar-dropdown': true,
                                                vestax_navbar_dropdown_focus:
                                                    location.pathname.startsWith(routeNames.vaults) ||
                                                    location.pathname.startsWith(routeNames.farms) ||
                                                    location.pathname.startsWith(routeNames.myfarms) ||
                                                    location.pathname.startsWith(routeNames.nftStaking),
                                            })}
                                        >
                                            <NavDropdown.Item as="div">
                                                <Link
                                                    to={routeNames.farms}
                                                    className="nav-link"
                                                    onClick={(e) => onButtonClick(e)}
                                                >
                                                    Farms
                                                </Link>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item as="div">
                                                <Link
                                                    to={routeNames.vaults}
                                                    className="nav-link"
                                                    onClick={(e) => onButtonClick(e)}
                                                >
                                                    Vaults
                                                </Link>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item as="div">
                                                <Link
                                                    to={routeNames.nftStaking}
                                                    className="nav-link"
                                                    onClick={(e) => onButtonClick(e)}
                                                >
                                                    Stake NFTs
                                                </Link>
                                            </NavDropdown.Item>
                                        </NavDropdown>

                                        <NavDropdown
                                            title="Assets"
                                            className={clsx({
                                                'vestax-navbar-dropdown': true,
                                                vestax_navbar_dropdown_focus:
                                                    location.pathname.startsWith(routeNames.vesta) ||
                                                    location.pathname.startsWith(routeNames.liquidLocker) ||
                                                    location.pathname.startsWith(routeNames.transfer) ||
                                                    location.pathname.startsWith(routeNames.eliteAccount) ||
                                                    location.pathname.startsWith(routeNames.faucet),
                                            })}
                                        >
                                            <NavDropdown.Item as="div">
                                                <NavLink
                                                    href="https://vegld.vestax.finance"
                                                    className="nav-link"
                                                    target="_blank"
                                                >
                                                    Mint vEGLD
                                                </NavLink>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item as="div">
                                                <Link
                                                    to={routeNames.vesta}
                                                    className="nav-link"
                                                    onClick={(e) => onButtonClick(e)}
                                                >
                                                    Mint Vesta
                                                </Link>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item as="div">
                                                <Link
                                                    to={routeNames.stableCoin}
                                                    className="nav-link"
                                                    onClick={(e) => onButtonClick(e)}
                                                >
                                                    Mint USDD
                                                </Link>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item as="div">
                                                <Link
                                                    to={routeNames.liquidLocker}
                                                    className="nav-link"
                                                    onClick={(e) => onButtonClick(e)}
                                                >
                                                    xLaunchpad
                                                </Link>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item as="div">
                                                <Link
                                                    to={routeNames.transfer}
                                                    className="nav-link"
                                                    onClick={(e) => onButtonClick(e)}
                                                >
                                                    Transfer
                                                </Link>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item as="div">
                                                <Link
                                                    to={routeNames.eliteAccount}
                                                    className="nav-link"
                                                    onClick={(e) => onButtonClick(e)}
                                                >
                                                    Elite Account
                                                </Link>
                                            </NavDropdown.Item>
                                            {IS_DEV && (
                                                <NavDropdown.Item as="div">
                                                    <Link
                                                        to={routeNames.faucet}
                                                        className="nav-link"
                                                        onClick={(e) => onButtonClick(e)}
                                                    >
                                                        Faucet
                                                    </Link>
                                                </NavDropdown.Item>
                                            )}
                                        </NavDropdown>

                                        {isAdmin && (
                                            <NavDropdown
                                                title="Admin"
                                                className={clsx({
                                                    'vestax-navbar-dropdown': true,
                                                    vestax_navbar_dropdown_focus:
                                                        location.pathname.startsWith(routeNames.nftAdmin) ||
                                                        location.pathname.startsWith(routeNames.vaultAdmin) ||
                                                        location.pathname.startsWith(routeNames.slipAdmin),
                                                })}
                                            >
                                                <NavDropdown.Item as="div">
                                                    <Link
                                                        to={routeNames.vaultAdmin}
                                                        className="nav-link"
                                                        onClick={(e) => onButtonClick(e)}
                                                    >
                                                        Vault Admin
                                                    </Link>
                                                </NavDropdown.Item>

                                                <NavDropdown.Item as="div">
                                                    <Link
                                                        to={routeNames.slipAdmin}
                                                        className="nav-link"
                                                        onClick={(e) => onButtonClick(e)}
                                                    >
                                                        Slip Admin
                                                    </Link>
                                                </NavDropdown.Item>

                                                <NavDropdown.Item as="div">
                                                    <Link
                                                        to={routeNames.nftAdmin}
                                                        className="nav-link"
                                                        onClick={(e) => onButtonClick(e)}
                                                    >
                                                        NFT Admin
                                                    </Link>
                                                </NavDropdown.Item>

                                                <NavDropdown.Item as="div">
                                                    <Link
                                                        to={routeNames.createPool}
                                                        className="nav-link"
                                                        onClick={(e) => onButtonClick(e)}
                                                    >
                                                        Create Swap Pool
                                                    </Link>
                                                </NavDropdown.Item>

                                                <NavDropdown.Item as="div">
                                                    <Link
                                                        to={routeNames.stableCoinAdmin}
                                                        className="nav-link"
                                                        onClick={(e) => onButtonClick(e)}
                                                    >
                                                        Stable Coin Admin
                                                    </Link>
                                                </NavDropdown.Item>
                                            </NavDropdown>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <Link
                                            to={routeNames.swap}
                                            className={clsx(
                                                'vestax_navbar_but',
                                                routeNames.swap == location.pathname ? 'vestax_navbar_but_focus' : '',
                                            )}
                                            onClick={onButtonClick}
                                        >
                                            Swap
                                        </Link>
                                        <Link
                                            to={routeNames.pools}
                                            className={clsx(
                                                'vestax_navbar_but',
                                                location.pathname.startsWith(routeNames.pools)
                                                    ? 'vestax_navbar_but_focus'
                                                    : '',
                                            )}
                                            onClick={onButtonClick}
                                        >
                                            Pools
                                        </Link>
                                        <Link
                                            to={routeNames.stablePools}
                                            className={clsx(
                                                'vestax_navbar_but',
                                                location.pathname.startsWith(routeNames.stablePools)
                                                    ? 'vestax_navbar_but_focus'
                                                    : '',
                                            )}
                                            onClick={onButtonClick}
                                        >
                                            Stable Pools
                                        </Link>
                                        <Link
                                            to={routeNames.farms}
                                            className={clsx(
                                                'vestax_navbar_but',
                                                location.pathname.startsWith(routeNames.farms) ||
                                                    location.pathname.startsWith(routeNames.myfarms)
                                                    ? 'vestax_navbar_but_focus'
                                                    : '',
                                            )}
                                            onClick={(e) => onButtonClick(e, false)}
                                        >
                                            Farms
                                        </Link>
                                        <Link
                                            to={routeNames.vaults}
                                            className={clsx(
                                                'vestax_navbar_but',
                                                location.pathname.startsWith(routeNames.vaults)
                                                    ? 'vestax_navbar_but_focus'
                                                    : '',
                                            )}
                                            onClick={(e) => onButtonClick(e)}
                                        >
                                            Vaults
                                        </Link>
                                        <Link
                                            to={routeNames.nftStaking}
                                            className={clsx(
                                                'vestax_navbar_but',
                                                location.pathname.startsWith(routeNames.nftStaking)
                                                    ? 'vestax_navbar_but_focus'
                                                    : '',
                                            )}
                                            onClick={(e) => onButtonClick(e)}
                                        >
                                            Stake NFTs
                                        </Link>

                                        <NavLink
                                            href="https://vegld.vestax.finance"
                                            className="vestax_navbar_but"
                                            target="_blank"
                                        >
                                            Mint vEGLD
                                        </NavLink>
                                        <Link
                                            to={routeNames.vesta}
                                            className={clsx(
                                                'vestax_navbar_but',
                                                routeNames.vesta == location.pathname ? 'vestax_navbar_but_focus' : '',
                                            )}
                                            onClick={(e) => onButtonClick(e)}
                                        >
                                            Mint Vesta
                                        </Link>
                                        <Link
                                            to={routeNames.liquidLocker}
                                            className={clsx(
                                                'vestax_navbar_but',
                                                routeNames.liquidLocker == location.pathname
                                                    ? 'vestax_navbar_but_focus'
                                                    : '',
                                            )}
                                            onClick={(e) => onButtonClick(e)}
                                        >
                                            xLaunchpad
                                        </Link>
                                        <Link
                                            to={routeNames.transfer}
                                            className={clsx(
                                                'vestax_navbar_but',
                                                location.pathname.startsWith(routeNames.transfer)
                                                    ? 'vestax_navbar_but_focus'
                                                    : '',
                                            )}
                                            onClick={(e) => onButtonClick(e)}
                                        >
                                            Transfer
                                        </Link>
                                        <Link
                                            to={routeNames.eliteAccount}
                                            className={clsx(
                                                'vestax_navbar_but',
                                                location.pathname.startsWith(routeNames.eliteAccount)
                                                    ? 'vestax_navbar_but_focus'
                                                    : '',
                                            )}
                                            onClick={(e) => onButtonClick(e)}
                                        >
                                            Elite Account
                                        </Link>
                                        {IS_DEV && (
                                            <>
                                                <Link
                                                    to={routeNames.faucet}
                                                    className={clsx(
                                                        'vestax_navbar_but',
                                                        location.pathname.startsWith(routeNames.faucet)
                                                            ? 'vestax_navbar_but_focus'
                                                            : '',
                                                    )}
                                                    onClick={(e) => onButtonClick(e)}
                                                >
                                                    Faucet
                                                </Link>
                                            </>
                                        )}
                                        {isAdmin && (
                                            <>
                                                <Link
                                                    to={routeNames.vaultAdmin}
                                                    className={clsx(
                                                        'vestax_navbar_but',
                                                        location.pathname.startsWith(routeNames.vaultAdmin)
                                                            ? 'vestax_navbar_but_focus'
                                                            : '',
                                                    )}
                                                    onClick={(e) => onButtonClick(e)}
                                                >
                                                    Vault Admin
                                                </Link>
                                                <Link
                                                    to={routeNames.slipAdmin}
                                                    className={clsx(
                                                        'vestax_navbar_but',
                                                        location.pathname.startsWith(routeNames.slipAdmin)
                                                            ? 'vestax_navbar_but_focus'
                                                            : '',
                                                    )}
                                                    onClick={(e) => onButtonClick(e)}
                                                >
                                                    Slip Admin
                                                </Link>
                                                <Link
                                                    to={routeNames.nftAdmin}
                                                    className={clsx(
                                                        'vestax_navbar_but',
                                                        location.pathname.startsWith(routeNames.nftAdmin)
                                                            ? 'vestax_navbar_but_focus'
                                                            : '',
                                                    )}
                                                    onClick={(e) => onButtonClick(e)}
                                                >
                                                    NFT Admin
                                                </Link>
                                                <Link
                                                    to={routeNames.createPool}
                                                    className={clsx(
                                                        'vestax_navbar_but',
                                                        location.pathname.startsWith(routeNames.createPool)
                                                            ? 'vestax_navbar_but_focus'
                                                            : '',
                                                    )}
                                                    onClick={(e) => onButtonClick(e)}
                                                >
                                                    Create Swap Pool
                                                </Link>
                                                <Link
                                                    to={routeNames.stableCoinAdmin}
                                                    className={clsx(
                                                        'vestax_navbar_but',
                                                        location.pathname.startsWith(routeNames.stableCoinAdmin)
                                                            ? 'vestax_navbar_but_focus'
                                                            : '',
                                                    )}
                                                    onClick={(e) => onButtonClick(e)}
                                                >
                                                    Stable Coin Admin
                                                </Link>
                                            </>
                                        )}
                                        <Link
                                            to={routeNames.stableCoin}
                                            className={clsx(
                                                'vestax_navbar_but',
                                                location.pathname.startsWith(routeNames.stableCoin)
                                                    ? 'vestax_navbar_focus'
                                                    : '',
                                            )}
                                            onClick={(e) => onButtonClick(e)}
                                        >
                                            Stable Coin
                                        </Link>
                                    </>
                                )}

                                {isLoggedIn ? (
                                    <NavItem>
                                        <Link to={routeNames.assets} style={{ textDecoration: 'none' }}>
                                            <div
                                                className="header_connect_but"
                                                // onClick={handleLogout}
                                            >
                                                <div className="wallet_connect_icon">
                                                    <IoWallet />
                                                </div>
                                                <span>
                                                    {address.substring(0, 6) +
                                                        '...' +
                                                        address.substring(address.length - 5, address.length)}
                                                </span>
                                            </div>
                                        </Link>
                                    </NavItem>
                                ) : (
                                    // <Nav.Link href={routeNames.unlock} style={{ margin: '0', padding: '0' }}>
                                    <NavItem>
                                        <div className="header_connect_but" onClick={() => setIsOpen(true)}>
                                            <div className="wallet_connect_icon">
                                                <IoWallet />
                                            </div>
                                            <span>Connect</span>
                                        </div>
                                    </NavItem>
                                    // </Nav.Link>
                                )}
                            </Nav>
                        </div>
                    </BsNavbar.Collapse>
                </div>
            </BsNavbar>

            {/* <div className="d-flex justify-content-center">
                <div className="vesta_x_logo">
                    <img src={vesta_x_logo} />
                </div>
            </div> */}

            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} ariaHideApp={false}>
                <div className="connection-modal">
                    <div className="d-flex justify-content-between align-items-center">
                        <span
                            style={{
                                color: '#F1DC46',
                                marginLeft: '10px',
                                fontSize: '1.25rem',
                            }}
                        >
                            Connect to a wallet
                        </span>

                        <div className="modal-close-but" onClick={closeModal}>
                            <IoClose />
                        </div>
                    </div>

                    <Unlock />
                </div>
            </Modal>
        </div>
    );
};
