import React, { FC } from 'react';
import clsx from 'clsx';

interface propsInterface {
    onChange: (e: boolean) => void;
    value: boolean;
}

export const ToggleStakeOrUnstake: FC<propsInterface> = ({ onChange, value }) => {
    const tabs: string[] = ['Stake', 'Unstake'];

    return (
        <div className="d-flex justify-content-center">
            <div className="tab-box">
                <div className={clsx('tab-focus', value === true ? 'tab-focus-left-0' : 'tab-focus-left-50')} />
                {tabs.map((row, index) => {
                    return (
                        <div
                            className="tab"
                            key={`p-r-t2-${index}`}
                            onClick={() => onChange(index == 0 ? true : false)}
                        >
                            {row}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
