import React, { useEffect, useState } from 'react';
import { TokenTransfer } from '@multiversx/sdk-core/out';
import { useGetAccount, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import clsx from 'clsx';
import Countdown from 'react-countdown';

import { InfoTooltip } from 'components';
import { CountdownCompleted, countdownRenderer } from 'components/CountdownComponents';
import { vaultStakeSft, vaultUnstakeSft } from 'z/elrond';
import {
    MemeLiquidityVaultBaseContextType,
    MemeLiquidityVaultUserContextType,
    OuroLiquidityVaultBaseContextType,
    OuroLiquidityVaultUserContextType,
} from 'z/types';
import {
    formatNumber,
    convertSecondsToLocalDateTime,
    ERROR_CONNECT_WALLET,
    ERROR_SC_DATA_NOT_LOADED,
    ERROR_TRANSACTION_ONGOING,
    toastError,
} from 'z/utils';

interface LiquidityVestaVaultBoostersParams {
    vaultBaseContext?: OuroLiquidityVaultBaseContextType | MemeLiquidityVaultBaseContextType;
    vaultUserContext?: OuroLiquidityVaultUserContextType | MemeLiquidityVaultUserContextType;
    stakedSftCounts: number[];
    sftBalances: number[];
    scAddress: string;
}

export const LiquidityVestaVaultBoosterSft = ({
    vaultBaseContext,
    vaultUserContext,
    stakedSftCounts,
    sftBalances,
    scAddress,
}: LiquidityVestaVaultBoostersParams) => {
    const { address } = useGetAccount();
    const { hasPendingTransactions } = useGetPendingTransactions();

    const [SftCountdown, setSftCountdown] = useState<any>();
    const [selectedSftTier, setSelectedSftTier] = useState<number>(0);
    const [targetSftCount, setTargetSftCount] = useState<number>(0);

    useEffect(() => {
        if (vaultBaseContext && vaultUserContext) {
            const unstakeTime = (vaultUserContext.last_claimed_timestamp + vaultBaseContext.sft_lock_period) * 1000;
            const CountdownWrapper =
                unstakeTime < Date.now()
                    ? () => <CountdownCompleted />
                    : () => <Countdown renderer={countdownRenderer} date={unstakeTime} autoStart />;
            const MemoCountdown = React.memo(CountdownWrapper);
            setSftCountdown(MemoCountdown);
        }
    }, [vaultBaseContext, vaultUserContext]);

    useEffect(() => {
        setTargetSftCount(stakedSftCounts[selectedSftTier]);
    }, [selectedSftTier, sftBalances, stakedSftCounts]);

    const onClickSftTier = (index: number) => {
        setSelectedSftTier(index);
    };

    const onChangeTargetSftCount = (value: number) => {
        if (value < 0) return;
        if (value > sftBalances[selectedSftTier] + stakedSftCounts[selectedSftTier]) return;

        setTargetSftCount(value);
    };

    async function onSftStakeOrUnstake() {
        let error = '';
        if (!address) {
            error = ERROR_CONNECT_WALLET;
        } else if (hasPendingTransactions) {
            error = ERROR_TRANSACTION_ONGOING;
        } else if (!vaultBaseContext || !vaultUserContext) {
            error = ERROR_SC_DATA_NOT_LOADED;
        }
        if (error) {
            toastError(error);
            return;
        }
        if (!vaultBaseContext || !vaultUserContext) return;

        const nonce = (selectedSftTier % 3) + 1;
        if (targetSftCount > stakedSftCounts[selectedSftTier]) {
            // Stake SFT
            const payments: TokenTransfer[] = [
                TokenTransfer.metaEsdtFromBigInteger(
                    vaultBaseContext.sft_token_id,
                    nonce,
                    targetSftCount - stakedSftCounts[selectedSftTier],
                ),
            ];

            await vaultStakeSft(scAddress, payments, address);
        } else if (targetSftCount < stakedSftCounts[selectedSftTier]) {
            // Unstake SFT
            const unstakeTimestamp =
                (vaultUserContext.last_claimed_timestamp + vaultBaseContext.sft_lock_period) * 1000;
            if (Date.now() < unstakeTimestamp) {
                toastError(`You can unstake after ${convertSecondsToLocalDateTime(unstakeTimestamp)}.`);
                return;
            }

            const payments: TokenTransfer[] = [
                TokenTransfer.metaEsdtFromBigInteger(
                    vaultBaseContext.sft_token_id,
                    nonce,
                    stakedSftCounts[selectedSftTier] - targetSftCount,
                ),
            ];

            await vaultUnstakeSft(scAddress, payments);
        }
    }

    return (
        <>
            <div className="text-center" style={{ color: '#F1DC46', fontSize: '1.5rem' }}>
                Boosters
            </div>

            <div className="vault-sft-staking-container">
                <div className="text-center">Vesta SFT Staking</div>
                <div className="p-1">
                    <div className="d-flex gap-2">
                        <div className={'sft-tag d-flex justify-content-center align-items-center w-20'}>Gold</div>
                        <div className={'sft-tag d-flex justify-content-center align-items-center w-20'}>Silver</div>
                        <div className={'sft-tag d-flex justify-content-center align-items-center w-20'}>Bronze</div>
                        <div
                            className="farm_card_individual_element d-flex justify-content-center align-items-center position-relative"
                            style={{ width: '40%' }}
                        >
                            {SftCountdown ? <SftCountdown /> : <CountdownCompleted />}

                            <InfoTooltip
                                title="Time until SFTs can be unstaked. Cooldown only starts after a reward claim event. When 'Unlockable' is shown, SFTs can be unstaked."
                                type={2}
                            />
                        </div>
                    </div>
                    <div className="d-flex gap-2 mt-2">
                        <div
                            className={clsx(
                                'sft-box d-flex justify-content-center align-items-center w-20 gold-color',
                                selectedSftTier == 0 && 'selected',
                            )}
                            onClick={() => onClickSftTier(0)}
                        >
                            {stakedSftCounts[0]}
                        </div>
                        <div
                            className={clsx(
                                'sft-box d-flex justify-content-center align-items-center w-20 silver-color',
                                selectedSftTier == 1 && 'selected',
                            )}
                            onClick={() => onClickSftTier(1)}
                        >
                            {stakedSftCounts[1]}
                        </div>
                        <div
                            className={clsx(
                                'sft-box d-flex justify-content-center align-items-center w-20 bronze-color',
                                selectedSftTier == 2 && 'selected',
                            )}
                            onClick={() => onClickSftTier(2)}
                        >
                            {stakedSftCounts[2]}
                        </div>
                        <div
                            className="sft-tag d-flex justify-content-center align-items-center w-20 position-relative"
                            style={{ color: '#00b050', fontWeight: '500' }}
                        >
                            IM
                            <InfoTooltip title="Individual Multiplier (Green Vesta Multiplier)" type={2} />
                        </div>
                        <div
                            className="sft-tag d-flex justify-content-center align-items-center w-20"
                            style={{ color: '#00b050', fontWeight: '500' }}
                        >
                            {formatNumber(vaultUserContext ? vaultUserContext.im : 1)}x
                        </div>
                    </div>
                </div>

                <div className="p-1">
                    <div className="d-flex gap-2">
                        <div className="w-60 sft-staking-spin-container d-flex justify-content-between align-items-center">
                            <button className="spin-but" onClick={() => onChangeTargetSftCount(targetSftCount - 1)}>
                                {' '}
                                -{' '}
                            </button>
                            <span>
                                {targetSftCount}/{stakedSftCounts[selectedSftTier] + sftBalances[selectedSftTier]}
                            </span>
                            <button className="spin-but" onClick={() => onChangeTargetSftCount(targetSftCount + 1)}>
                                {' '}
                                +{' '}
                            </button>
                        </div>

                        <div className="w-40">
                            <button className="farm_but w-100 h-100" onClick={onSftStakeOrUnstake}>
                                {targetSftCount > stakedSftCounts[selectedSftTier]
                                    ? 'Stake'
                                    : targetSftCount < stakedSftCounts[selectedSftTier]
                                    ? 'Unstake'
                                    : '-'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
