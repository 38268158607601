import React, { useEffect, useState } from 'react';

import { Row, Col } from 'react-bootstrap';
import { OURO_TOKEN_ID, USDC_TOKEN_ID, USDD_TOKEN_ID, VEGLD_TOKEN_ID } from 'config';
import { getTokenLogo, viewSwapPool } from 'z/elrond';
import { AddTokenModal } from './AddTokenModal';

export const AddToken = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tokenIdentifier, setTokenIdentifier] = useState('');
    const [vegldPoolScAddress, setVegldPoolScAddress] = useState('');
    const [vegldPoolBaseScToken, setVegldPoolBaseScToken] = useState(VEGLD_TOKEN_ID);
    const [ouroPoolScAddress, setOuroPoolScAddress] = useState('');

    const checkScAddreses = async (): Promise<string | void> => {
        if (vegldPoolScAddress && ouroPoolScAddress) {
            const { firstTokenId: vegldFirstTokenId, secondTokenId: vegldSecondTokenId } = await viewSwapPool(
                vegldPoolScAddress,
            );
            const { firstTokenId: ouroFirstTokenId, secondTokenId: ouroSecondTokenId } = await viewSwapPool(
                ouroPoolScAddress,
            );
            if (
                vegldSecondTokenId === ouroSecondTokenId &&
                ouroFirstTokenId === OURO_TOKEN_ID &&
                (vegldFirstTokenId === VEGLD_TOKEN_ID || vegldFirstTokenId === USDC_TOKEN_ID)
            ) {
                setTokenIdentifier(ouroSecondTokenId);
                return setIsModalOpen(true);
            }
            // based pool
            if (
                (ouroFirstTokenId === USDC_TOKEN_ID || ouroFirstTokenId === USDD_TOKEN_ID) &&
                ouroSecondTokenId === OURO_TOKEN_ID
            ) {
                setTokenIdentifier(ouroFirstTokenId);
                return setIsModalOpen(true);
            }
            alert('Something is wrong with sc addresses');
        }
    };

    const setBasedScToken = async () => {
        const { firstTokenId } = await viewSwapPool(vegldPoolScAddress);
        setVegldPoolBaseScToken(firstTokenId);
    };

    useEffect(() => {
        if (vegldPoolScAddress) {
            setBasedScToken();
        }
    }, [vegldPoolScAddress]);

    return (
        <Col md={12}>
            <div className="slip-admin-panel-box">
                <div
                    className="text-center"
                    style={{
                        fontSize: '14px',
                        color: '#98A1C0',
                    }}
                >
                    Token Pool
                </div>
                <hr className="my-3" />

                <Row className="align-items-center gy-4">
                    <Col lg={11}>
                        <input
                            className="vesta_x_input text-right"
                            placeholder="VEGLD vesta swap pool address"
                            value={vegldPoolScAddress}
                            onChange={(e) => setVegldPoolScAddress(e.target.value)}
                        />
                    </Col>
                    <Col lg={1}>
                        <div className="d-flex align-items-center gap-3">
                            <div>
                                <img src={getTokenLogo(vegldPoolBaseScToken)} alt="logo" width="50px" />
                            </div>
                        </div>
                    </Col>
                    <Col lg={11}>
                        <input
                            className="vesta_x_input text-right"
                            placeholder="OURO vesta swap pool address"
                            value={ouroPoolScAddress}
                            onChange={(e) => setOuroPoolScAddress(e.target.value)}
                        />
                    </Col>
                    <Col lg={1}>
                        <div className="d-flex align-items-center gap-3">
                            <div>
                                <img src={getTokenLogo(OURO_TOKEN_ID)} alt="logo" width="50px" />
                            </div>
                        </div>
                    </Col>
                </Row>

                <hr />
                <div className="d-flex justify-content-center">
                    <button className="slip-but" style={{ width: '300px' }} onClick={checkScAddreses}>
                        Add token to SLIP
                    </button>
                </div>
            </div>

            <AddTokenModal
                isOpen={isModalOpen}
                closeModal={() => {
                    setIsModalOpen(false);
                }}
                tokenIdentifier={tokenIdentifier}
                vegldPoolScAddress={vegldPoolScAddress}
                ouroPoolScAddress={ouroPoolScAddress}
            />
        </Col>
    );
};
