import React, { useEffect, useState } from 'react';
import { useGetAccount, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import BigNumber from 'bignumber.js';
import { Col, Row } from 'react-bootstrap';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

import {
    SVST_MINT_LIMITS,
    VESTADAO_COLLECTION,
    LIQUIDITY_VESTA_VALULT_SC_ADDRESS,
    VST_TOKEN_ID,
    SLIP_TOKEN_ID,
    VUSDDOURO_TOKEN_ID,
    USDD_SLIP_TOKEN_ID,
} from 'config';
import { selectFarm } from 'redux/reducers';
import { selectOuroLiquidityVault } from 'redux/reducers/ouro-liquidity-vault';
import { useAppSelector } from 'redux/store';
import { routeNames } from 'routes';
import {
    getAccountNftsByCollection,
    vaultClaimReward,
    liquidityVaultStakeToken,
    liquidityVaultUnstakeToken,
} from 'z/elrond';
import { useVestaCommonHooks } from 'z/hooks';
import { useOuroLiquidityVaultHooks } from 'z/hooks/ouro-liquidity-vault';
import {
    OuroLiquidityVaultBaseContextType,
    OuroLiquidityVaultStatsContextType,
    OuroLiquidityVaultUserContextType,
    OuroLiquidityVestaVaultSelectedHolding,
    VestingTypeEnum,
} from 'z/types';
import {
    BIG_NUMBER_ZERO,
    convertWeiToEsdt,
    DEFAULT_DECIMALS,
    ERROR_CONNECT_WALLET,
    ERROR_TRANSACTION_ONGOING,
    toastError,
    convertToDollarString,
    nativeOuroLiquidityTokenSelectorForVault,
    securedOuroLiquidityTokenSelectorForVault,
    nativeOuroLiquidityTokenSelectorForWallet,
    securedOuroLiquidityTokenSelectorForWallet,
} from 'z/utils';
import { LiquidityVestaVaultBoosterSft } from './Components/LiquidityVestaVaultBoosterSft';
import { LiquidityVestaVaultBoosterToken } from './Components/LiquidityVestaVaultBoosterToken';
import { LiquidityVestaVaultEliteRewards } from './Components/LiquidityVestaVaultEliteRewards';
import { LiquidityVestaVaultLiquidity } from './Components/LiquidityVestaVaultLiquidity';
import { LiquidityVestaVaultPremiumRewards } from './Components/LiquidityVestaVaultPremiumRewards';
import { LiquidityVestaVaultStakeUnstake } from './Components/LiquidityVestaVaultStakeUnstake';
import { OuroLiquidityVestaVaultInfo } from './Components/OuroLiquidityVestaVaultInfo';
import { VestaMinter } from './Components/VestaMinter';

const SFT_TIER_COUNT = 3;

export const LiquidityVestaVault = () => {
    const { address } = useGetAccount();
    const { hasPendingTransactions } = useGetPendingTransactions();
    const navigate = useNavigate();
    const { getTokenPrice } = useVestaCommonHooks();
    useOuroLiquidityVaultHooks();

    const farmRedux = useAppSelector(selectFarm);
    const ouroLiquidityVaultRedux = useAppSelector(selectOuroLiquidityVault);

    const [stakedSftCounts, setStakedSftCounts] = useState<number[]>(Array(SFT_TIER_COUNT).fill(0));
    const [sftBalances, setSftBalances] = useState<number[]>(Array(SFT_TIER_COUNT).fill(0));

    const [selectedHolding, setSelectedHolding] = useState<OuroLiquidityVestaVaultSelectedHolding>(
        OuroLiquidityVestaVaultSelectedHolding.VaultNativeOuroUSDD,
    );
    const [selectedTokenId, setSelectedTokenId] = useState<string>(VUSDDOURO_TOKEN_ID);

    const [mintOption, setMintOption] = useState<number>(0); // 0 for sVESTA, 1 for fVESTA
    const [mintPercent, setMintPercent] = useState<number>(0);
    const [vestaSleepingYears, setVestaSleepingYears] = useState<number>(1);

    const [vaultBaseContext, setVaultBaseContext] = useState<OuroLiquidityVaultBaseContextType>();
    const [vaultStatsContext, setVaultStatsContext] = useState<OuroLiquidityVaultStatsContextType>();
    const [vaultUserContext, setVaultUserContext] = useState<OuroLiquidityVaultUserContextType>();

    // const [claimOption, setClaimOption] = useState<number>(0);  // 0 for RAWVST, 1 for CNDVST

    const [liquidityBalance, setLiquidityBalance] = useState(BIG_NUMBER_ZERO);

    useEffect(() => {
        setVaultBaseContext(ouroLiquidityVaultRedux.baseContext);
    }, [ouroLiquidityVaultRedux.baseContext]);

    useEffect(() => {
        setVaultStatsContext(ouroLiquidityVaultRedux.statsContext);
    }, [ouroLiquidityVaultRedux.statsContext]);

    useEffect(() => {
        if (!address || hasPendingTransactions) return;

        setVaultUserContext(ouroLiquidityVaultRedux.userContext);

        if (ouroLiquidityVaultRedux.userContext) {
            setStakedSftCounts(ouroLiquidityVaultRedux.userContext.sft_staked_amounts.map((v) => Number(v)));
        }
    }, [ouroLiquidityVaultRedux.userContext]);

    useEffect(() => {
        (async () => {
            const _sfts = await getAccountNftsByCollection(address, VESTADAO_COLLECTION);
            let goldCount = 0;
            let silverCount = 0;
            let bronzeCount = 0;
            for (const sft of _sfts) {
                if (sft.nonce == 1) goldCount += Number(sft.balance);
                else if (sft.nonce == 2) silverCount += Number(sft.balance);
                else if (sft.nonce == 3) bronzeCount += Number(sft.balance);
            }
            setSftBalances([goldCount, silverCount, bronzeCount]);
        })();
    }, [address, hasPendingTransactions]);

    async function onMintVesta() {
        let error = '';
        if (!address) {
            error = ERROR_CONNECT_WALLET;
        } else if (hasPendingTransactions) {
            error = ERROR_TRANSACTION_ONGOING;
        } else if (mintOption === 1) {
            if (vestaSleepingYears > SVST_MINT_LIMITS[farmRedux.eliteAccountTier]) {
                error = `You can mint ${SVST_MINT_LIMITS[farmRedux.eliteAccountTier]} locked years sVST at most`;
            }
        }

        if (error) {
            toastError(error);
            return;
        }

        const vestingType =
            mintOption === 2
                ? VestingTypeEnum.FrozenVesta
                : mintOption === 1
                ? VestingTypeEnum.SleepingVesta
                : VestingTypeEnum.Vesta;
        const lockYears = mintOption === 1 ? vestaSleepingYears : 0;

        await vaultClaimReward(LIQUIDITY_VESTA_VALULT_SC_ADDRESS, vestingType, lockYears, mintPercent);
    }

    function onTokenSelected(token_id: string, holding_type: OuroLiquidityVestaVaultSelectedHolding) {
        setSelectedTokenId(token_id);
        setSelectedHolding(holding_type);
    }

    const getFeeProcent = () => {
        if (vaultUserContext) {
            const feeToken = vaultUserContext.lp_token_fee_procents.find(
                ({ token_identifier }) => token_identifier === selectedTokenId,
            );
            return feeToken ? feeToken.amount : '0';
        }
        return '0';
    };

    return (
        <>
            <div className="container" style={{ marginTop: '50px' }}>
                <div className="mt-4">
                    <div className="vault-container-card" style={{ minHeight: '40rem' }}>
                        <div className="d-flex justify-content-start">
                            <button
                                className="go-back-button"
                                onClick={() => {
                                    navigate(`${routeNames.vaults}`);
                                }}
                            >
                                <BiLeftArrowAlt />
                            </button>
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                            <span style={{ color: '#F1DC46', fontSize: '1.2rem' }}>OURO Liquidity.Vault™</span>
                        </div>
                        <OuroLiquidityVestaVaultInfo
                            vaultStatsContext={vaultStatsContext}
                            vaultUserContext={vaultUserContext}
                        />
                        <Row className="mt-2 mb-4 p-0 gy-4" style={{ fontSize: '1.1rem', color: '#98A1C0' }}>
                            <Col lg={12} xl={4} className="d-flex flex-column">
                                <div className="vault-sft-staking-container">
                                    <LiquidityVestaVaultBoosterSft
                                        vaultBaseContext={vaultBaseContext}
                                        vaultUserContext={vaultUserContext}
                                        stakedSftCounts={stakedSftCounts}
                                        sftBalances={sftBalances}
                                        scAddress={LIQUIDITY_VESTA_VALULT_SC_ADDRESS}
                                    />

                                    <LiquidityVestaVaultBoosterToken
                                        vaultUserContext={vaultUserContext}
                                        onTokenSelected={onTokenSelected}
                                        setLiquidityBalance={setLiquidityBalance}
                                        tokenIdentifiers={[SLIP_TOKEN_ID, USDD_SLIP_TOKEN_ID]}
                                        vaultMultiplier={new BigNumber(
                                            vaultUserContext ? vaultUserContext.slip_multiplier : '1000000',
                                        ).shiftedBy(-6)}
                                        vestaVaultSelectedHoldingVaultEnum={
                                            OuroLiquidityVestaVaultSelectedHolding.VaultSLIP
                                        }
                                        vestaVaultSelectedHoldingWalletEnum={
                                            OuroLiquidityVestaVaultSelectedHolding.WalletSLIP
                                        }
                                    />
                                </div>
                            </Col>

                            <LiquidityVestaVaultLiquidity
                                vaultUserContext={vaultUserContext}
                                selectedTokenId={selectedTokenId}
                                onTokenSelected={onTokenSelected}
                                selectedHolding={selectedHolding}
                                setLiquidityBalance={setLiquidityBalance}
                                nativeTokenSelectorForVaults={{
                                    nativeTokenSelectorForVault: nativeOuroLiquidityTokenSelectorForVault,
                                    securedTokenSelectorForVault: securedOuroLiquidityTokenSelectorForVault,
                                    nativeTokenSelectorForWallet: nativeOuroLiquidityTokenSelectorForWallet,
                                    securedTokenSelectorForWallet: securedOuroLiquidityTokenSelectorForWallet,
                                }}
                                tokenSliderTitles={{
                                    firstTokenSliderTitle: 'Native LP Tokens',
                                    secondTokenSliderTitle: 'Exotic LP Tokens',
                                }}
                                stakeToken={liquidityVaultStakeToken}
                                unstakeToken={liquidityVaultUnstakeToken}
                            />
                        </Row>

                        <LiquidityVestaVaultStakeUnstake
                            selectedTokenId={selectedTokenId}
                            selectedHolding={selectedHolding}
                            liquidityBalance={liquidityBalance}
                            fee={getFeeProcent()}
                            stakeToken={liquidityVaultStakeToken}
                            unstakeToken={liquidityVaultUnstakeToken}
                        />

                        <VestaMinter
                            mintOption={mintOption}
                            setMintOption={setMintOption}
                            mintPercent={mintPercent}
                            setMintPercent={setMintPercent}
                            vestaSleepingYears={vestaSleepingYears}
                            setVestaSleepingYears={setVestaSleepingYears}
                            vaultStatsContext={vaultStatsContext}
                            vaultUserContext={vaultUserContext}
                            setVM={(vm: number) => vaultUserContext && setVaultUserContext({ ...vaultUserContext, vm })}
                            totalValueLockeded={convertToDollarString(
                                vaultStatsContext
                                    ? convertWeiToEsdt(
                                          vaultStatsContext.total_standard_vesta_power,
                                          DEFAULT_DECIMALS,
                                          DEFAULT_DECIMALS,
                                      ).multipliedBy(getTokenPrice(VST_TOKEN_ID))
                                    : 0,
                            )}
                            onMintVesta={onMintVesta}
                        />
                        <LiquidityVestaVaultPremiumRewards />
                        <LiquidityVestaVaultEliteRewards />
                    </div>
                </div>
            </div>
        </>
    );
};
