import React, { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import clsx from 'clsx';

export const NumberInput = ({
    onChange,
    maxBalance,
}: {
    onChange: (newInputValue: string) => void;
    maxBalance?: BigNumber;
}) => {
    const [inputTokenAmount, setInputTokenAmount] = useState('0');

    useEffect(() => {
        onChange(inputTokenAmount);
    }, [inputTokenAmount]);

    function isNumber(text: string) {
        return /^-?\d*\.?\d+$/.test(text);
    }

    const handleInputSelect = (e: string) => {
        const value = e.replace(',', '.');
        const separatorCount = (value.match(/[.]/g) || []).length;

        if (separatorCount > 1) {
            return;
        }

        if (value.endsWith('.') || value.endsWith(',')) {
            setInputTokenAmount(value);
            return;
        }
        if (value === '' || value === '.' || value === ',' || !isNumber(value)) {
            setInputTokenAmount('0');
            return;
        }

        if (parseFloat(value) < 0) {
            setInputTokenAmount('0');
            return;
        }

        if (value.includes('.')) {
            const parts = value.split('.');
            if (parts[1].length > 18 || parts.length > 2) return;
        }

        const targetAmount = new BigNumber(value).shiftedBy(18);
        if (maxBalance && targetAmount.isGreaterThan(maxBalance)) {
            setInputTokenAmount(maxBalance.shiftedBy(-18).toString());
        } else {
            setInputTokenAmount(value);
        }
    };
    return (
        <input
            className={clsx({ 'coil-input': true })}
            onChange={(e) => handleInputSelect(e.target.value)}
            value={inputTokenAmount}
            type="text"
        />
    );
};
